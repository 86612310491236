import React, { useEffect, useState } from "react";

import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentLoginStore from "stores/LoginStore";
import ProfileTab from "./ProfileTab";
import UpdatePassword from "../../../../../userManagement/subFeatures/AccountLogin/UpdatePassword";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import ViewSettings from "./ViewSettings";
import { Row } from "reactstrap";

const ProfilePage = () => {
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const setLoginFields = useCurrentLoginStore((state) => state.setLoginFields);

	// Local state
	const [imageFile, setImageFile] = useState(null);

	// Define validation rules for logged in user
	const schema = yup.object().shape({
		personnelType: yup.string().required().max(50),
		firstName: yup.string().required(),
		lastName: yup.string().required(),
	});

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	// Set the logged in User's details in the profile page
	useEffect(() => {
		const locations = { ...dealerInfo.locations };
		const dealers = { ...dealerInfo.dealers };
		console.log(dealerInfo);
		setLoginFields(dealerInfo, locations, dealers, dealerInfo.profileData);
		// eslint-disable-next-line
	}, []);

	return (
		<div style={{ textAlign: "left" }} onKeyDown={formNavigationUtils}>
			<FormProvider {...methods}>
				<ProfileTab imageFile={imageFile} setImageFile={setImageFile} />
			</FormProvider>
			<Row className="mt-3" style={{ gap: "12px" }}>
				<ViewSettings />
				<UpdatePassword />
			</Row>
		</div>
	);
};

export default ProfilePage;
