import * as yup from "yup";

/**
 * Set custom error messages for Yup validation.
 * This affects the error messages returned for different validation errors.
 */
yup.setLocale({
	mixed: {
		required: "Required", // Message when a value is required but not provided
	},
	string: {
		length: "VIN must contain a combination of 17 letters and numbers", // Message when string length doesn't match
		matches: "Only number and letters allowed", // Message when string pattern doesn't match
	},
});

/**
 * Yup schema for VIN validation.
 * This schema is used to validate the format and the rules for the VIN (Vehicle Identification Number).
 * The schema ensures that the VIN is required, has a length of 17, and contains only letters and numbers.
 * @type {yup.ObjectSchema}
 */
export const validateVin = yup.object().shape({
	vin: yup
		.string() // VIN must be a string
		.required() // VIN is required
		.length(17) // VIN must have a length of 17 characters
		.matches(/^[a-zA-Z0-9]*$/), // VIN can only contain letters and numbers
});
