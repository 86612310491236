// src/services/openAIService/OpenAiService.js

/**
 * Sends a chat completion request to the Make.com webhook.
 *
 * @param {Array} messages - Array of message objects for the conversation.
 * @param {Object} options - Optional configuration overrides for the OpenAI API call.
 * @returns {Promise<Object>} The response from the Make.com webhook, containing the AI's response.
 * @throws {Error} Throws an error if the request fails.
 */
export const fetchChatCompletion = async (messages, options = {}) => {
	try {
		const response = await fetch(
			"https://hook.us2.make.com/u7uflw508jz9rvj39bh8kwbjmcwmincj", // New webhook URL
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ messages, options }),
			}
		);

		const rawResponse = await response.text();

		if (!response.ok) {
			throw new Error(`HTTP ${response.status}: ${rawResponse}`);
		}

		// Parse response as JSON
		const data = JSON.parse(rawResponse);

		return {
			content: data.data?.content || "[No response content]", // Adjust to match your response
			role: data.data?.role || "assistant",
		};
	} catch (error) {
		console.error("Error fetching chat completion:", error.message);
		throw error;
	}
};

/**
 * Chat roles supported by the OpenAI GPT API.
 */
export const ChatRole = {
	SYSTEM: "system",
	USER: "user",
	ASSISTANT: "assistant",
};

/**
 * Creates a properly formatted message object for chat API requests.
 *
 * @param {string} role - The role of the message sender (system, user, assistant).
 * @param {string} content - The content of the message.
 * @returns {Object} Formatted message object.
 */
export const createChatMessage = (role, content) => ({
	role,
	content,
});
