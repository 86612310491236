import React, { useMemo, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Table,
	CardHeader,
	Container,
} from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import useCurrentAccountStore from "account/AccountStore";
import useCurrentLoginStore from "stores/LoginStore";
import {
	EmailPdfRequestButton,
	PdfRequestButton,
	PdfEsignatureRequestButton,
} from "./FormsReportsButtons";
import ReactTable from "components/tables/ReactTable";
import FormsReportsProvider, {
	FormsReportsContext,
} from "../context/FormsReportsContext";
import { PackRequestProvider } from "../context/PackContexts";
import FormsEmailProvider from "../context/FormsEmailContext";
import { useFromFormsReports } from "stores/LocalStorageHelper";
import { retrieveFailAlert } from "utils/alertUtils";
import { PATHS } from "constants/Constants";
import useRoute from "hooks/useRoute";
import { useHistory } from "react-router-dom";

/**
 * Component for rendering a list of forms and reports.
 *
 * @param {Object} props - The component props.
 * @param {string} props.listTitle - The title of the list.
 * @param {boolean} [props.searchable=false] - Flag indicating if the list is searchable.
 * @param {string} [props.inventoryID=null] - The inventory ID.
 * @param {string} [props.listName=""] - The name of the list.
 * @param {boolean} [props.isPack=false] - Flag indicating if the forms are part of a pack.
 * @param {Array} [props.packForms] - Array of pack forms.
 * @returns {JSX.Element} The rendered component.
 */
const FormsReportsList = ({
	listTitle,
	searchable = false,
	inventoryID = null,
	listName = "",
	isPack = false,
	packForms,
	eSign = false,
}) => {
	const { dealerTempID } = useCurrentLoginStore((state) => state, shallow);
	const forms = useFromFormsReports(listName);
	const dashboardRoute = useRoute(PATHS.DASHBOARD);
	const history = useHistory();
	const { dealID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
		}),
		shallow
	);
	const { accountID } = useCurrentAccountStore(
		(state) => ({
			accountID: state?.account?.id || null,
		}),
		shallow
	);

	/**
	 * Renders a form skeleton.
	 *
	 * @param {string} title - The title of the form.
	 * @param {JSX.Element} component - The form component.
	 * @returns {JSX.Element} The rendered form skeleton.
	 */
	const renderFormSkeleton = (title, component) => (
		<tr>
			<td>
				<Row>
					<Col>{title}</Col>
					<Col xs="2">{component}</Col>
				</Row>
			</td>
		</tr>
	);

	/**
	 * Generates the view for the forms.
	 *
	 * @param {Array} forms - The array of forms.
	 * @returns {JSX.Element[]} The rendered forms view.
	 */
	const formsView = (forms) => {
		return forms.map((element) =>
			renderFormSkeleton(
				`${element.id} - ${element.title}`,
				<FormsReportsProvider>
					<PackRequestProvider>
						<RequestWrapper listName={listName}>
							<PdfRequestButton
								dealID={dealID}
								inventoryID={inventoryID}
								accountID={accountID}
								dcReport={element}
								dealerTempID={dealerTempID}
							/>
						</RequestWrapper>
					</PackRequestProvider>
				</FormsReportsProvider>
			)
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title",
				id: "id",
				cellAlign: "flex-start",
			},
			{
				Header: "",
				accessor: "actions",
				disableFilters: true,
			},
		],
		[]
	);

	/**
	 * Generates the data for the forms table.
	 *
	 * @param {Array} forms - The array of forms.
	 * @returns {Array} The generated data for the table.
	 */
	const generateData = (forms) => {
		try {
			return forms.map((element) => ({
				title: `${element.id} - ${element.title}`,
				actions: (
					<>
						<FormsReportsProvider>
							<PackRequestProvider>
								<RequestWrapper listName={listName}>
									<FormsEmailProvider>
										<EmailPdfRequestButton
											dealID={dealID}
											inventoryID={inventoryID}
											accountID={accountID}
											dcReport={element}
										/>
									</FormsEmailProvider>
								</RequestWrapper>
							</PackRequestProvider>
						</FormsReportsProvider>
						<FormsReportsProvider>
							<PackRequestProvider>
								<RequestWrapper listName={listName}>
									<PdfRequestButton
										dealID={dealID}
										inventoryID={inventoryID}
										accountID={accountID}
										dcReport={element}
										dealerTempID={dealerTempID}
									/>
								</RequestWrapper>
							</PackRequestProvider>
						</FormsReportsProvider>
						{eSign ? (
							<FormsReportsProvider>
								<PackRequestProvider>
									<RequestWrapper listName={listName}>
										<PdfEsignatureRequestButton
											dealID={dealID}
											inventoryID={inventoryID}
											accountID={accountID}
											dcReport={element}
											dealerTempID={dealerTempID}
										/>
									</RequestWrapper>
								</PackRequestProvider>
							</FormsReportsProvider>
						) : null}
					</>
				),
			}));
		} catch (error) {
			retrieveFailAlert("Forms missing, try accessing again...");
			history.push(dashboardRoute);
			return [];
		}
	};

	if (searchable) {
		return (
			<Container>
				<Row className="justify-content-center">
					<Col md={10} sm={6}>
						<Card>
							<CardHeader>
								<CardTitle tag="h3">{listTitle}</CardTitle>
							</CardHeader>
							<CardBody className="pb-4 mt-2">
								<ReactTable
									data={isPack ? generateData(packForms) : generateData(forms)}
									columns={columns}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	} else {
		return (
			<Card>
				<CardHeader>
					<CardTitle tag="h3">{listTitle}</CardTitle>
				</CardHeader>
				<Table className="mt-4">
					<tbody>
						{isPack ? (
							packForms.length > 0 ? (
								formsView(packForms)
							) : (
								<tr>
									<td>No Forms</td>
								</tr>
							)
						) : forms.length > 0 ? (
							formsView(forms)
						) : (
							<tr>
								<td>No Forms</td>
							</tr>
						)}
					</tbody>
				</Table>
			</Card>
		);
	}
};

FormsReportsList.propTypes = {
	listTitle: PropTypes.string.isRequired,
	searchable: PropTypes.bool,
	inventoryID: PropTypes.any,
	listName: PropTypes.string,
	isPack: PropTypes.bool,
	packForms: PropTypes.array,
};

export default FormsReportsList;

/**
 * Wrapper component to set the list name in the context.
 *
 * @param {Object} props - The component props.
 * @param {string} props.listName - The name of the list.
 * @param {JSX.Element} props.children - The child components.
 * @returns {JSX.Element} The rendered component.
 */
const RequestWrapper = ({ listName, children }) => {
	const { setListName } = useContext(FormsReportsContext);

	useEffect(() => {
		setListName(listName);
	}, [listName, setListName]);

	return <>{children}</>;
};

RequestWrapper.propTypes = {
	listName: PropTypes.any.isRequired,
	children: PropTypes.any.isRequired,
};
