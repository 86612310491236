import InputElement from "features/forms/components/InputElement";
import { EditableSelectorElement } from "features/forms/components/InputElement";
import React from "react";

const LookingForData = ({
	currentLead,
	handleChange,
	methods,
	yearList,
	makeList,
	modelList,
}) => {
	return (
		<>
			<EditableSelectorElement
				{...methods}
				value={currentLead?.year}
				label="Year"
				disable={false}
				name="year"
				type="number"
				onChange={(e) => handleChange("year", e)}
				options={yearList}
			/>
			<EditableSelectorElement
				{...methods}
				disable={false}
				label="Make"
				value={currentLead?.make}
				onChange={(e) => handleChange("make", e)}
				options={makeList}
				name="make"
			/>
			<EditableSelectorElement
				{...methods}
				disable={false}
				label="Model"
				value={currentLead?.model}
				onChange={(e) => handleChange("model", e)}
				options={modelList}
				name="model"
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.color}
				name="color"
				label="Color"
				type="text"
				onChange={(e) => handleChange("color", e.target.value)}
			/>
		</>
	);
};

export default LookingForData;
