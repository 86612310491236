import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, CardBody } from "reactstrap";
import BookSheet from "./print/bookSheet/BookSheet";
import AllValues from "./AllValues";
import AllValuesWrapper from "./print/allValues/AllValuesWrapper";
import KbbEquipmentSection from "./KbbEquipmentSection";
import KbbValues from "./KbbValues";
import KbbForm from "./KbbForm";
import LoadingSpinner from "components/loadingSpinner/LoadingSpinner";
import ChildrenModal from "components/modals/ChildrenModal";
import { retrieveAllSelected } from "./kbbHelperFunctions";
import { KbbContext } from "./KbbContext";
import { decodeVin, getKbbValues } from "api/KbbAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { failAlert } from "utils/alertUtils";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import "assets/css/kbb-all-values.css";
import "assets/css/kbb-book-sheet.css";

const InventoryKbbTab = ({
	inventoryID,
	vehicleKbbObj,
	showVinDecoder = false,
	containerClassName = "px-3",
}) => {
	const today = dayjs().format("MM/DD/YYYY");

	// User store
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const dealerZip = useFromUser("locations").zipCode;

	// Progress spinner state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);

	// Track if "Get Values" has been activated
	const [valuesRetrieved, setValuesRetrieved] = useState(false);

	// KBB context
	const {
		getDcKbbObj,
		setDcKbbObj,
		dcTableKbb,
		setDcTableKbb,
		setDcTableKbbEquipment,
		setDcTableKbbValues,
		condition,
		setCondition,
	} = useContext(KbbContext);

	const [showAllValues, setShowAllValues] = useState(false);
	const toggleAllValues = () => setShowAllValues(!showAllValues);

	const getKbbDetails = () => {
		setShowSubmitProgress(true);
		decodeVin(vehicleKbbObj.vin).then(
			(res) => {
				const data = res.data.content.vinResults;
				if (data && data.length > 0) {
					getInitialValues(data[0]);
				} else {
					setShowSubmitProgress(false);
				}
			},
			(err) => {
				failAlert("Failed to decode vehicle with vin: " + vehicleKbbObj.vin);
				setShowSubmitProgress(false);
			}
		);
	};

	const getInitialValues = (kbbVehicleDetails) => {
		const details = kbbVehicleDetails;
		const options = details.vehicleOptions;

		vehicleKbbObj.editYear(String(details.yearId));
		vehicleKbbObj.editMake(details.makeName);
		vehicleKbbObj.editModel(details.modelName);

		if (dcTableKbb?.kbbVehicleId !== details.vehicleId) {
			const dcEquipmentArr = options.map((obj) => ({
				carRecNum: inventoryID,
				vehicleOptionID: obj.vehicleOptionId,
				categoryGroup: obj.categoryGroup,
				equip: obj.optionName,
				equipType: obj.optionType.charAt(0).toUpperCase(),
				isSelected: obj.isTypical,
				row: obj.sortOrder,
				vehicleId: details.vehicleId,
			}));
			setDcTableKbbEquipment(dcEquipmentArr);
			setDcTableKbbValues([]);
		}

		setDcTableKbb((prev) => ({
			...prev,
			dealerID,
			locationID,
			condition,
			inventoryID,
			bookDate: dayjs().format("YYYY-MM-DD"),
			trimStyle: details.trimName,
			kbbVehicleId: details.vehicleId,
			isBooked: true,
		}));
		setValuesRetrieved(true);
		setShowSubmitProgress(false);
	};

	const loadInitialValues = () => {
		if (dcTableKbb?.kbbVehicleId) {
			setValuesRetrieved(true);
		}
	};

	const updateKbbValues = () => {
		setShowSubmitProgress(true);
		if (dcTableKbb?.kbbVehicleId) {
			const kbb = getDcKbbObj();
			const request = {
				kbb,
				valuesRequest: {
					configuration: {
						vehicleId: dcTableKbb.kbbVehicleId,
						vehicleOptionIds: retrieveAllSelected(kbb.dcTableKbbEquipment),
					},
					mileage: vehicleKbbObj.odometerIn,
					zipCode: dealerZip,
					valuationDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
				},
			};

			console.log("KBB Values Request:", request);
			getKbbValues(request).then(
				(res) => {
					setDcKbbObj(res.data.content);
					setValuesRetrieved(true); // Mark values as retrieved
					setShowSubmitProgress(false);
				},
				(err) => {
					failAlert(err.response.data.message);
					setShowSubmitProgress(false);
				}
			);
		} else {
			console.log("No KBB Vehicle ID present.");
			failAlert("Decode KBB and select a condition, then update values.");
			setShowSubmitProgress(false);
		}
	};

	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			max: "Must be characters or less",
			length: "Must be characters long",
		},
	});

	// Initialize form schema
	const schema = yup.object().shape({
		enteredVin: yup.string().required().max(50),
		kbbOdometer: yup.string().required().max(50),
		kbbCondition: yup.string().required(),
	});
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		loadInitialValues();
		// eslint-disable-next-line
	}, []);

	return (
		<div className={containerClassName}>
			<FormProvider {...methods}>
				{valuesRetrieved && (
					<Row className="mb-2">
						<Col className="pull-left">
							<BookSheet
								inventoryID={inventoryID}
								vehicleKbbObj={vehicleKbbObj}
							/>
						</Col>
						<Col className="pull-right">
							<div className="d-flex justify-content-end">
								<Button
									className="btn-primary btn-sm"
									onClick={toggleAllValues}
								>
									<i className="nc-icon nc-paper"></i> All Values
								</Button>
								<ChildrenModal
									modal={showAllValues}
									toggle={toggleAllValues}
									modalTitle="All Values"
								>
									<AllValues>
										<div className="d-flex justify-content-between mb-3">
											<AllValuesWrapper />
										</div>
									</AllValues>
								</ChildrenModal>
							</div>
						</Col>
					</Row>
				)}

				{showSubmitProgress ? (
					<LoadingSpinner />
				) : (
					<div>
						{valuesRetrieved && <KbbValues />}
						<KbbForm
							methods={methods}
							getKbbDetails={getKbbDetails}
							updateKbbValues={updateKbbValues}
							dcTableKbb={dcTableKbb}
							condition={condition}
							setCondition={setCondition}
							today={today}
							inventoryID={inventoryID}
							vehicleKbbObj={vehicleKbbObj}
							setValuesRetrieved={setValuesRetrieved}
						/>
						<br />
						{valuesRetrieved && (
							<KbbEquipmentSection inventoryID={inventoryID} />
						)}
					</div>
				)}
			</FormProvider>
		</div>
	);
};

InventoryKbbTab.propTypes = {
	inventoryID: PropTypes.string.isRequired,
	vehicleKbbObj: PropTypes.object.isRequired,
	showVinDecoder: PropTypes.bool,
};

export default InventoryKbbTab;
