import React, { useContext, useEffect } from "react";
import {
	MessageZone,
	MESSAGE_CreditApp_VARIABLES,
	MESSAGE_LEAD_VARIABLES,
	MESSAGE_Lender_VARIABLES,
	MESSAGE_TEMPLATE_VARIABLES,
	MESSAGE_ACCOUNT_VARIABLES,
	MESSAGE_CUSTOMER_VARIABLES,
} from "../constants/Constants";
import { TemplateVariableContext } from "../context/TemplateVariableContext";

const InitializeTemplateVars = ({ messageZone, children }) => {
	const { setTemplateVars } = useContext(TemplateVariableContext);

	console.log("messageZone", messageZone);
	useEffect(() => {
		if (messageZone === MessageZone.DEAL) {
			setTemplateVars(MESSAGE_TEMPLATE_VARIABLES);
		} else if (messageZone === MessageZone.CUSTOMER) {
			setTemplateVars(MESSAGE_CUSTOMER_VARIABLES);
		} else if (messageZone === MessageZone.LEAD) {
			setTemplateVars(MESSAGE_LEAD_VARIABLES);
		} else if (messageZone === MessageZone.CREDITAPP) {
			setTemplateVars(MESSAGE_CreditApp_VARIABLES);
		} else if (messageZone === MessageZone.LENDER) {
			setTemplateVars(MESSAGE_Lender_VARIABLES);
		} else if (messageZone === MessageZone.ACCOUNT) {
			setTemplateVars(MESSAGE_ACCOUNT_VARIABLES);
		} else {
			setTemplateVars(MESSAGE_TEMPLATE_VARIABLES);
		}
		// eslint-disable-next-line
	}, []);

	return <>{children}</>;
};

export default InitializeTemplateVars;
