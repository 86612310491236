import React, { useState } from "react";
import { Button } from "reactstrap";

/**
 * Component to initiate automated voice calls using VAPI assistant.
 * Handles both single and multiple phone numbers.
 *
 * @param {String} vapiAssistantId The VAPI assistant ID to use for the call
 * @param {String|String[]} phoneNumbers Single phone number or array of phone numbers
 * @param {Function} onSuccess Optional callback for successful calls
 * @param {Function} onError Optional callback for failed calls
 */
const AutomatedVoiceCall = ({
	vapiAssistantId,
	phoneNumbers,
	onSuccess,
	onError,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const createRequestBody = () => {
		// If phoneNumbers is a string (single number), convert to array
		const numbersArray = Array.isArray(phoneNumbers)
			? phoneNumbers
			: [phoneNumbers];

		// Create the request body based on number of phone numbers
		return {
			vapiAssistantId,
			phoneNumbers: numbersArray,
		};
	};

	const initiateCall = async () => {
		setIsLoading(true);

		try {
			const requestBody = createRequestBody();

			const response = await fetch(
				"https://hook.us2.make.com/ewfevwc2lwnp9k49ognp4xb7x7105ash",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(requestBody),
				}
			);

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log("Call(s) initiated successfully:", data);
			onSuccess?.(data);
		} catch (error) {
			console.error("Error initiating call(s):", error);
			onError?.(error);
		} finally {
			setIsLoading(false);
		}
	};

	// Get count of phone numbers for button text
	const phoneNumberCount = Array.isArray(phoneNumbers)
		? phoneNumbers.length
		: 1;
	const buttonText = isLoading
		? " Initiating Call..."
		: phoneNumberCount > 1
		? ` Call (${phoneNumberCount} numbers)`
		: " Call";

	return (
		<Button
			className="btn-md btn-info"
			onClick={initiateCall}
			disabled={isLoading}
		>
			<i className="nc-icon nc-volume-98" />
			{buttonText}
		</Button>
	);
};

export default AutomatedVoiceCall;
