import React from "react";
import GAPForm from "./GAPForm";
import ChildrenModal from "components/modals/ChildrenModal";
import CollisionForm from "./CollisionForm";
//import { Col, Row } from "reactstrap";
import NavBar from "components/tabs/TabsHolder";
import CreditInsurance from "./CreditInsurance";

const tabs = ["Gap Insurance", "Collision Insurance", "Credit Insurance"];
const tabComponents = [<GAPForm />, <CollisionForm />, <CreditInsurance />];

// DMV fees calculations are done here
const GAPModal = ({ modal, toggle }) => {
	// Returns input fields and a text field that shows the total of the inputs
	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Gap & Insurance"
			children={
				<>
					<NavBar
						navClass="nav-pills-primary justify-content-center nav nav-pills"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
				</>
			}
		/>
	);
};

export default GAPModal;
