import React, { useState } from "react";
import {
	Row,
	Col,
	CardTitle,
	Label,
	CardBody,
	CardHeader,
	Form,
	CardText,
	Button,
} from "reactstrap";
import NumberSelector from "features/inventory/subFeatures/selector/NumberSelector";
import FinanceTermInput from "../finance/FinanceTermInput";
import useCurrentSaleStore from "stores/SaleStore";
import RollModalBody from "./RollModalBody";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import PaymentOptionsModal from "./PaymentOptionsModal";
import ChildrenModal from "components/modals/ChildrenModal";
import OpenModal from "./OpenModal";
import formNavigationUtils from "utils/formNavigationUtils";
import NumberFormat from "react-number-format";
import useCurrentRecapStore from "stores/RecapStore";
import PrepaidFinanceCharge from "../finance/PrepaidFinanceCharge";
import shallow from "zustand/shallow";
import { confirmDeleteMessage } from "utils/alertUtils";
import BalloonModalBody from "./BalloonModalBody";
import AdjustmentAPR from "../finance/AdjustmentAPR";
import FinanceAPRInput from "../finance/FinanceAPRInput";
import FinanceDaysToPayInput from "../finance/FinanceDaysToPayInput";
import OpenModalButton from "features/deal/components/OpenModalButton";
import MenuSelling from "./MenuSelling";

/**
 * TermsTable component to display the terms and payment options.
 *
 * @returns {JSX.Element} The rendered component
 */
const TermsTable = () => {
	const [showProfit, setShowProfit] = useState(false);
	const [paymentOptions, setPaymentOptions] = useState(false);

	const toggle = () => setShowProfit(!showProfit);
	const togglePayment = () => setPaymentOptions(!paymentOptions);

	const { loanPeriod, editLoanPeriod, bankFee } = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			editLoanPeriod: state.editLoanPeriod,
			bankFee: state.bankFee,
		}),
		shallow
	);

	const { frontendProfit, backendProfit, netProfit } = useCurrentRecapStore(
		(state) => state,
		shallow
	);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const [menuSelling, setMenuSelling] = useState(false);
	const toggleSelling = () => setMenuSelling(!menuSelling);

	return (
		<>
			<CardHeader>
				<CardTitle tag="h3">Payments</CardTitle>
			</CardHeader>
			<CardBody className="pb-0" onKeyDown={formNavigationUtils}>
				<Form className="deal-terms-table">
					<Row className="mx-0">
						<Col xl="5" className="mb-2">
							<Label className="">Period</Label>
							<NumberSelector
								name="dealLoanPeriod"
								readOnly={type !== DealStatus.PENDING}
								value={loanPeriod}
								selectedOption={loanPeriod}
								handleChange={editLoanPeriod}
								selectType={"loanPeriod"}
								default={true}
							/>
						</Col>
						<Col xl="3" className="mb-2">
							<Label>Term</Label>
							<FinanceTermInput />
						</Col>
						<Col xl="3" className="mb-2">
							<FinanceAPRInput />
						</Col>
						<Col xl="5" className="mb-2">
							<Label>Days to 1st Pmt</Label>
							<FinanceDaysToPayInput />
						</Col>
					</Row>

					{bankFee > 0 && (
						<Row>
							<Col xl="4" className="mb-2">
								<Label>Adj APR (%)</Label>
								<AdjustmentAPR />
							</Col>
							<Col xl="6" className="mb-2">
								<Label>Prepaid Finance Charge</Label>
								<PrepaidFinanceCharge />
							</Col>
						</Row>
					)}

					<hr className="my-2" />

					<Row className="px-2">
						<Col xl="6">
							<BalloonButton />
						</Col>
						<Col xl="6">
							<RollButton />
						</Col>
					</Row>

					<hr className="my-2" />

					<Col className="p-0">
						<Row className="mb-3">
							<div className="row w-100 justify-content-center">
								<Button className="brn-sm" color="primary" onClick={toggle}>
									NP
								</Button>
								{showProfit && (
									<CardText
										className={
											netProfit > 0
												? "text-default px-4 pt-2"
												: "text-danger px-4 pt-2"
										}
										tag="h5"
									>
										<NumberFormat
											value={netProfit}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								)}
							</div>
							<div className="my-2 w-100" />
							<OpenModalButton
								buttonText="Payment Options"
								onClick={togglePayment}
								iconClass="nc-icon nc-refresh-69"
								buttonClass="btn btn-sm btn-link mx-auto"
							/>
							<ChildrenModal
								modalTitle="Payment Options"
								modal={paymentOptions}
								toggle={togglePayment}
								size="lg"
							>
								<PaymentOptionsModal toggle={togglePayment} />
							</ChildrenModal>
							<OpenModalButton
								buttonText="Menu Selling"
								onClick={toggleSelling}
								iconClass="nc-icon nc-refresh-69"
								className="btn btn-lg btn-link"
							/>
							<ChildrenModal
								modalTitle="Menu Selling"
								modal={menuSelling}
								toggle={toggleSelling}
								size="lg"
							>
								<MenuSelling toggle={toggleSelling} />
							</ChildrenModal>
						</Row>
					</Col>
				</Form>
			</CardBody>
		</>
	);
};

export default TermsTable;

/**
 * RollButton component to handle the roll back functionality.
 *
 * @returns {JSX.Element} The rendered component
 */
const RollButton = () => {
	const {
		rollBack,
		editPrice,
		rollPreValue,
		editRollPreValue,
		editRollBack,
		editTerm,
		editApr,
		editDown,
	} = useCurrentSaleStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const confirmRollBack = () => {
		confirmDeleteMessage("Roll deal back?").then((res) => {
			if (res === "Roll deal back?") {
				handleRollBack();
			}
		});
	};

	const handleRollBack = () => {
		console.log(rollBack);
		console.log(Number.parseInt(rollBack));
		switch (Number.parseInt(rollBack)) {
			case 10:
				editPrice(rollPreValue);
				break;
			case 20:
				editDown(rollPreValue);
				break;
			case 40:
				editApr(rollPreValue);
				break;
			case 90:
				console.log(rollPreValue);
				editTerm(rollPreValue);
				break;
			default:
				console.log("default" + rollPreValue);
				break;
		}
		editRollPreValue(0);
		editRollBack(0);
	};

	return rollBack === 0 || rollBack === null ? (
		<OpenModal
			BodyComponent={RollModalBody}
			className="btn w-100 btn-md btn-link"
			buttonText="Roll"
			size="lg"
		/>
	) : (
		<Button
			readOnly={type !== DealStatus.PENDING}
			onClick={confirmRollBack}
			className="btn w-100 btn-md btn-link"
		>
			Rollback
		</Button>
	);
};

/**
 * BalloonButton component to handle the balloon functionality.
 *
 * @returns {JSX.Element} The rendered component
 */
const BalloonButton = () => {
	const { hasBalloon, editHasBalloon, editBalloonTerm, term } =
		useCurrentSaleStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	const confirmBalloonBack = () => {
		confirmDeleteMessage("Cancel balloon?").then((res) => {
			if (res === "Cancel balloon?") {
				editHasBalloon(false);
				editBalloonTerm(term);
			}
		});
	};

	return hasBalloon === false || hasBalloon === null ? (
		<OpenModal
			BodyComponent={BalloonModalBody}
			className="btn w-100 btn-md btn-link"
			buttonText="Balloon"
			readOnly={term === 1}
		/>
	) : (
		<Button
			readOnly={type !== DealStatus.PENDING}
			onClick={confirmBalloonBack}
			className="btn w-100 btn-md btn-link"
		>
			Undo Balloon
		</Button>
	);
};
