import history from "utils/createBrowserHistory";

import { PATHS } from "constants/Constants";
import {
	sessionExpiredAlert,
	userConflictAlert,
	showSwalMessage,
} from "utils/alertUtils";

/**
 * Handles different HTTP status codes and routes the page
 * based on the error code.
 *
 * @param {error} error - an error object from axios
 *
 * @return True if general api error handled, false otherwise
 */
export const handledGeneralApiError = (error) => {
	// 401 Unauthorized
	if (error?.response?.status === 401) {
		sessionExpiredAlert();
		sessionStorage.removeItem("user");
		history.push(PATHS.LOGIN);

		return true;
	}
	// 409 Conflict, another person logged in with the same dcLogin
	else if (error?.response?.status === 409) {
		console.log(error);
		userConflictAlert();
		sessionStorage.removeItem("user");
		history.push(PATHS.LOGIN);

		return true;
	}
	// 404 Not Found
	else if (error?.response?.status === 404) {
		showSwalMessage("Page Not Found", "", "error");
		history.push(PATHS.DASHBOARD);

		return true;
	}
	// 500 Internal Exception Error
	else if (error?.response?.status === 500) {
		showSwalMessage("Server Error", "", "error");
		history.push(PATHS.DASHBOARD);

		return true;
	}

	return false;
};

/**
 * Shows sweet alert with either the message of the api error,
 * parameter message, or the message hardcoded in the alert.
 *
 * @param {Error} error - Http error object
 * @param {Function} alert - Sweet alert error function to call
 * @param {String} msg - Optional. Error message
 * @param {boolean} useGeneralMsg - Optional. True if we want to use
 * the message thats hardcoded in the alert.
 */
export const showApiError = (error, alert, msg, useGeneralMsg) => {
	const errorMessage = error?.response?.data?.message;

	// Use the message passed in to function
	if (msg != null) {
		alert(msg);
	}
	// Use API error message
	else if (useGeneralMsg !== true && errorMessage != null) {
		alert(errorMessage);
	}
	// Use sweet alert message
	else {
		alert();
	}
};
