import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import dayjs from "dayjs";

import AttachmentFileDetails from "./AttachmentFileDetails";
import AttachmentsTable from "./AttachmentsTable";
import ChildrenModal from "components/modals/ChildrenModal";
import DownloadAttachmentButton from "./DownloadAttachmentButton";
import UploadAttachmentButton from "./UploadAttachmentButton";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import {
	getDealAttachmentsList,
	getDealersAttachmentsList,
} from "api/AttachmentsAPI";

/**
 * Attachment component to display and manage attachments for a deal, inventory, or customer.
 *
 * @component
 * @param {object} props - Component props
 * @param {number} props.relatedID - ID of the related account, deal, inventory, or customer.
 * @param {string} props.type - Type of the related entity ('account', 'deal', 'inventory', or 'customer').
 * @returns {JSX.Element} The rendered component.
 */
const Attachment = ({ relatedID, type }) => {
	const [attachments, setAttachments] = useState([]);
	const [detailsData, setDetailsData] = useState({});
	const [refreshAttachments, setRefreshAttachments] = useState(null);
	const [uploadModal, setUploadModal] = useState(false);
	const [detailsModal, setDetailsModal] = useState(false);

	const toggleUploadModal = () => setUploadModal(!uploadModal);
	const toggleDetailsModal = () => setDetailsModal(!detailsModal);

	const createAndSetTableData = (data) => {
		const tableData = data.map((item) => ({
			...item,
			createdOn: item?.createdOn
				? dayjs(item.createdOn).format("MM/DD/YYYY [at] hh:mmA")
				: null,
			button: (
				<>
					<Button
						size="sm"
						block
						onClick={() => {
							setDetailsData({
								...item,
								createdOn: item?.createdOn || null,
								canEdit: true,
							});
							toggleDetailsModal();
						}}
					>
						Edit
					</Button>
					<DownloadAttachmentButton
						dcAttachmentId={item.ID}
						fileName={item.fileName}
						fileExtension={item.extension}
					/>
				</>
			),
		}));

		setAttachments(tableData);
	};

	const createAndSetDealTableData = (data) => {
		const tableData = [];

		Object.keys(data).forEach((key) =>
			data[key].forEach((item) => {
				tableData.push({
					...item,
					createdOn: item?.createdOn
						? dayjs(item.createdOn).format("MM/DD/YYYY [at] hh:mmA")
						: null,
					button: (
						<>
							<Button
								className="btn-sm"
								block
								onClick={() => {
									setDetailsData({
										...item,
										createdOn: item?.createdOn || null,
										canEdit: item.type === "deal",
									});
									toggleDetailsModal();
								}}
							>
								{item.type === "deal" ? (
									"Edit"
								) : (
									<>
										<i className="icon-big text-center nc-icon nc-paper text-default" />
										&nbsp;View
									</>
								)}
							</Button>
							<DownloadAttachmentButton
								dcAttachmentId={item.ID}
								fileName={item.fileName}
								fileExtension={item.extension}
							/>
						</>
					),
				});
			})
		);

		setAttachments(tableData);
	};

	const getAttachments = useCallback(() => {
		getDealersAttachmentsList(type, relatedID).then(
			(res) => createAndSetTableData(res.data.content),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, [type, relatedID]);

	const getDealAttachments = useCallback(() => {
		if (relatedID != null) {
			getDealAttachmentsList(relatedID).then(
				(res) => createAndSetDealTableData(res.data.content),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		// eslint-disable-next-line
	}, [relatedID]);

	useEffect(() => {
		if (type === "deal") {
			getDealAttachments();
		} else {
			getAttachments();
		}
	}, [getAttachments, getDealAttachments, type]);

	useEffect(() => {
		if (refreshAttachments !== null) {
			if (type === "deal") {
				getDealAttachments();
			} else {
				getAttachments();
			}
		}
	}, [refreshAttachments, getAttachments, getDealAttachments, type]);

	return (
		<>
			<UploadAttachmentButton
				relatedID={relatedID}
				type={type}
				setRefreshAttachments={setRefreshAttachments}
				toggle={toggleUploadModal}
			/>
			{attachments.length > 0 && (
				<AttachmentsTable data={attachments} type={type} />
			)}
			<ChildrenModal size="lg" modal={detailsModal} toggle={toggleDetailsModal}>
				<AttachmentFileDetails
					file={detailsData}
					toggleDetailsModal={toggleDetailsModal}
					setRefreshAttachments={setRefreshAttachments}
				/>
			</ChildrenModal>
		</>
	);
};

Attachment.propTypes = {
	relatedID: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
};

export default Attachment;
