import React from "react";
import { NavLink } from "react-router-dom";
import { CardFooter, Button } from "reactstrap";

function CardContentBottom({ navClass, routePath, iconText }) {
	return (
		<CardFooter>
			<div style={{minHeight: "31px"}}>
			{routePath && iconText && (
				<NavLink className={navClass} to={routePath}>
					<Button color="link" size="sm">
						{iconText} &#8594;
					</Button>
				</NavLink>
			)}
			</div>
		</CardFooter>
	);
}

export default CardContentBottom;
