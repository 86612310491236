import create from "zustand";

const useCurrentPhotoStore = create((set) => ({
	gallery: [],
	uploads: {},
	deletes: {},
	reorders: [],

	editGallery: (draftGallery) => set(() => ({ gallery: draftGallery })),
	editUploads: (draftUploads) => set(() => ({ uploads: draftUploads })),
	editDeletes: (draftDeletes) => set(() => ({ deletes: draftDeletes })),
	editReorders: (draftReorders) => set(() => ({ reorders: draftReorders })),

	reset: () =>
		set({
			gallery: [],
			uploads: {},
			deletes: {},
			reorders: [],
		}),
}));

export default useCurrentPhotoStore;
