import React from "react";
import { Table, Button, CardBody, Card } from "reactstrap";
import useCurrentDealStore from "../../../../../../stores/DealStore";
import {
	confirmDeleteAlert,
	deleteSuccessAlert,
	deleteFailAlert,
} from "../../../../../../utils/alertUtils";
import { CurrencyDisplayElement } from "../../../../components/DisplayElement";
import { DealStatus } from "constants/Constants";
import { deleteAftermarket } from "api/DealAPI";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";

const AftermarketTable = (props) => {
	const { toggle, setCurrentIndex } = props;
	const { aftermarket, editAftermarket, type, calculate } = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			type: state.deal.type,
			editAftermarket: state.editAftermarket,
			calculate: state.calculate,
		}),
		shallow
	);

	const aftermarketHead = [
		"#",
		"Description",
		// "Qty",
		// "Unit Price",
		// "Unit Cost",
		"Vendor",
		"Taxable",
		"Total Price",
		"Total Cost",
		"Actions",
	];
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeAftermarket, currentID, currentIndex);
	};

	const removeAftermarket = (ID, index) => {
		console.log("Removing aftermarket with ID: " + ID);
		if (ID) {
			deleteAftermarket(ID).then(
				(response) => {
					console.log(response);
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert, null, true);
					}
				}
			);
		}
		console.log(index);
		let obj = [];
		if (ID !== null) {
			obj = aftermarket.filter((item) => item.ID !== ID);
		} else {
			obj = aftermarket.filter((item) => item.currentIndex !== index);
		}
		editAftermarket(obj);
		setCurrentIndex(obj.length);
		calculate();
		deleteSuccessAlert();
	};

	const tableHead = aftermarketHead.map((key) => <th key={key}>{key}</th>);
	const rows = aftermarket.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.description}</td>
			{/* <td>{key.qty}</td>
			<td>{<CurrencyDisplayElement val={key.price} />}</td>
			<td>{<CurrencyDisplayElement val={key.cost} />}</td> */}
			<td>{key.vendor}</td>
			<td>{key.isTaxable ? "Yes" : "No"}</td>

			<td>{<CurrencyDisplayElement val={key.price * key.qty} />}</td>
			<td>{<CurrencyDisplayElement val={key.cost * key.qty} />}</td>
			<td className="text-right">
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="primary"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleModal(key.ID, key.currentIndex);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));
	return (
		<>
			{rows.length > 0 && (
				<Card>
					<CardBody>
						<div style={{ overflowX: "auto" }}>
							<Table striped hover>
								<thead>
									<tr>{tableHead}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default AftermarketTable;
