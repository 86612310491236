import React, { useState } from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const SellerFeesBreakdown = () => {
	const {
		dealerSmog,
		docFee,
		stateSmog,
		totalSellerFees,
	} = useCurrentSaleStore((state) => state, shallow);

	const { miscSellerFees } = useCurrentDealStore((state) => state, shallow);

	const [totalCost, setTotalCost] = useState(0);

	const rows = [
		{ label: "Dealer Smog", value: dealerSmog },
		{ label: "Doc Fee", value: docFee },
		{ label: "State Smog", value: stateSmog },
	];

	const AllMiscFees = () => {
		let tempCost = 0;
		const fees = miscSellerFees.map((key) => {
			tempCost += key.cost;
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		setTotalCost(tempCost);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
								<Col>
									<Label>N/A</Label>
								</Col>
							</Row>
						))}
					</>
					<hr />
					<AllMiscFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalSellerFees} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default SellerFeesBreakdown;
