import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "components/tables/ReactTable";

const AttachmentsTable = ({ data, type }) => {
	const dealColumns = [
		{
			Header: "File Name",
			accessor: "fileName",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "Category",
			accessor: "category",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "Upload Date",
			accessor: "createdOn",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "Type",
			accessor: (row) =>
				row.type.charAt(0).toUpperCase() + row.type.substring(1),
			style: { whiteSpace: "unset" },
		},
		{
			Header: "",
			accessor: "button",
			disableFilters: true,
		},
	];
	const columns = [
		{
			Header: "File Name",
			accessor: "fileName",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "Category",
			accessor: "category",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "Upload Date",
			accessor: "createdOn",
			style: { whiteSpace: "unset" },
		},
		{
			Header: "",
			accessor: "button",
			disableFilters: true,
		},
	];

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardBody>
						<ReactTable
							data={data}
							columns={type === "deal" ? dealColumns : columns}
						/>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default AttachmentsTable;
