import React from "react";
import {
	Card,
	CardBody,
	CardTitle,
	CardText,
	CardImg,
	Button,
	Row,
	Col,
} from "reactstrap";
import Camera from "assets/img/placeholder.png";
import "assets/css/deal-card-styles.css";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

/**
 * Component for displaying a deal card, used in mobile view.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.deal - The deal data.
 * @param {Function} props.onView - The function to handle viewing the deal.
 * @returns {JSX.Element} The rendered component.
 */
const DealCard = ({ deal, onView }) => {
	/**
	 * Handles clicking on the card to view the deal.
	 */
	const handleCardClick = () => {
		onView(deal);
	};

	/**
	 * Handles clicking the view button.
	 *
	 * @param {Event} e - The click event.
	 */
	const handleButtonClick = (e) => {
		e.stopPropagation();
		onView(deal);
	};

	// Extract the thumbnail source
	const thumbnailSrc = deal.thumbnail?.props?.children?.props?.src || Camera;

	return (
		<Card
			onClick={handleCardClick}
			style={{
				width: "100%",
				borderRadius: "8px",
				cursor: "pointer",
			}}
		>
			<CardBody>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "1rem",
					}}
				>
					<Button className="h6 btn-round btn-md btn btn-outline-secondary disabled">
						<strong>#</strong>
						{deal.dealNumber}
					</Button>
					<Button className="h6 btn-round btn-md btn btn-outline-secondary disabled">
						{deal.loanDate ? dayjs(deal.loanDate).format("MM/DD/YYYY") : "N/A"}
					</Button>
				</div>
				<Row>
					<Col xs="4" className="p-0">
						<CardImg
							src={thumbnailSrc}
							alt="Deal Image"
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = Camera;
							}}
							style={{
								height: "4.5rem",
								objectFit: "cover",
								marginBottom: "1rem",
							}}
						/>
						<CardText
							style={{
								fontSize: "1rem",
								textAlign: "center",
								textDecoration: "underline",
							}}
						>
							{deal.saleType}
						</CardText>
					</Col>
					<Col xs="8" className="deal-card-content">
						<CardTitle tag="p" className="m-0 deal-card-title">
							{deal.fullName}
						</CardTitle>
						<CardText
							style={{ fontSize: "1rem", marginBottom: "0", minHeight: "50px" }}
						>
							{deal.vehicle}
						</CardText>
						<CardText style={{ fontSize: "1em" }}>{deal.vin}</CardText>
						<hr className="my-2" />
						<CardText>
							{deal.term} payments of{" "}
							<NumberFormat
								value={deal.payment}
								displayType={"text"}
								thousandSeparator
								prefix={"$"}
							/>{" "}
							{deal.loanPeriod}
						</CardText>
					</Col>
				</Row>
				<hr className="mt-2" />
				<div className="pb-0 mt-2 text-center">
					<Button
						className="btn-sm"
						color="primary"
						onClick={handleButtonClick}
					>
						<i className="nc-icon nc-paper" />
						&nbsp;View
					</Button>
				</div>
			</CardBody>
		</Card>
	);
};

export default DealCard;
