import React, { useState } from "react";
import { Form } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import InputElement from "../../../../../forms/components/InputElement";
import ZipCityTable from "../../../../../../components/tables/ZipCityTable";

import {
	stateOptions,
	CountryCodes,
} from "../../../../../../constants/Constants";
import {
	PhoneInputElement,
	SelectorElement,
	ZipInputElement,
} from "../../../../../forms/components/InputElement";

import { useFormContext } from "react-hook-form";

const DigitzsBusinessInformation = ({
	businessInfo,
	businessAddress,
	setBusinessInfo,
	setBusinessAddress,
	setCurrentForm,
	nextForm,
	previousForm,
	disabled,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: businessAddress.city,
				state: businessAddress.state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setBusinessAddress.setBusinessCity(address.city);
		setBusinessAddress.setBusinessState(address.state);
	};

	// Handler for the next button click. Sets the fields to the business information
	// objects and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!disabled && !isValid) {
			return;
		}

		setCurrentForm(nextForm);
	};

	const methods = useFormContext();

	return (
		<div>
			<Form onKeyDown={formNavigationUtils} className="row">
				<h3 className="col-12 section-title mb-3">Business Information</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={businessInfo.businessName}
					onChange={(e) => setBusinessInfo.setBusinessName(e.target.value)}
					name="businessName"
					label="Business Name"
					type="text"
					placeholder="Legal name of the business"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={businessInfo.ein}
					onChange={(e) => setBusinessInfo.setEin(e.target.value)}
					name="ein"
					label="EIN"
					type="number"
					placeholder="Employee Identification Number"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={businessInfo.url}
					onChange={(e) => setBusinessInfo.setBusinessWebsite(e.target.value)}
					name="businessWebsite"
					label="Website"
					type="text"
					placeholder="Business Website URL"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={businessInfo.email}
					onChange={(e) => setBusinessInfo.setBusinessEmail(e.target.value)}
					name="businessEmail"
					label="Email"
					type="text"
					placeholder="Business email address"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={businessInfo.phone}
					onChange={setBusinessInfo.setBusinessPhone}
					name="businessPhone"
					label="Phone"
					placeholder="Business Phone Number"
				/>
				<h3 className="col-12 form-section section-title">Business Address</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={businessAddress.line1}
					onChange={(e) => setBusinessAddress.setBusinessStreet(e.target.value)}
					name="businessStreet"
					label="Street"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					value={businessAddress.line2}
					onChange={(e) => setBusinessAddress.setBusinessUnit(e.target.value)}
					name="businessUnit"
					label="Apt/Unit/Bld/Suite"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={businessAddress.city}
					onChange={(e) => setBusinessAddress.setBusinessCity(e.target.value)}
					name="businessCity"
					label="City"
					type="text"
				/>
				<ZipInputElement
					{...methods}
					disabled={disabled}
					value={businessAddress.zip}
					onChange={(val) => setBusinessAddress.setBusinessZip(val)}
					openZipModal={handleZipChange}
					name="businessZip"
					label="Zip"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					value={businessAddress.state}
					onChange={(e) => setBusinessAddress.setBusinessState(e.target.value)}
					label="State"
					name="businessState"
					options={stateOptions}
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					name="businessCountry"
					label="Country"
					value={businessAddress.country}
					onChange={(e) =>
						setBusinessAddress.setBusinessCountry(e.target.value)
					}
					options={CountryCodes}
				/>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: businessAddress.city,
							state: businessAddress.state,
							zip: businessAddress.zip,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</Form>
			{nextForm != null && (
				<div className="col-12">
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button className="next-with-previous" onClick={handleNext}>
						Next
					</button>
				</div>
			)}
		</div>
	);
};

export default DigitzsBusinessInformation;
