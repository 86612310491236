import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "constants/Constants";
// import { LOCAL8081 } from "constants/Constants";
// import { LOCAL } from "constants/Constants";

import swal from "sweetalert2";

export const displayPdf = (response) => {
	console.log(response);
	const file = new Blob([response.data], {
		type: "application/pdf",
	});
	const fileURL = URL.createObjectURL(file);
	window.open(fileURL);
};

export const displayError = async (err) => {
	console.log(err.response);
	const file = new Blob([err.response.data], {
		type: "application/json",
	});
	return await file.text().then((resp) => {
		const message = JSON.parse(resp);
		swal.fire("Error!", message.message, "error");
	});
};

export const formLoading = (doc) => {
	swal.fire({
		title: "Generating " + doc + "...",
		showConfirmButton: false,
		willOpen() {
			swal.showLoading();
		},
		didClose() {
			swal.hideLoading();
		},
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
	});
};

/**
 * Converts the array buffer to a Blob and then creates it's object URL so
 * that it can be set to the <a/> tag and downloaded.
 *
 * @param {ArrayBuffer} fileArr File as an array of bytes, should be
 *                      response.data
 * @param {String}      fileName what to name the file in downloads including
 *                      the file extension
 *
 */
export const createFileAndDownload = (fileArr, fileName) => {
	const url = window.URL.createObjectURL(new Blob([fileArr]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
};

export const createPdfAndDownload = (fileArr, fileName) => {
	const url = window.URL.createObjectURL(
		new Blob([fileArr.data], {
			type: "application/pdf",
		})
	);
	console.log(fileName);
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", fileName);
	document.body.appendChild(link);
	link.click();
};

/*********************************************************************************************** */
/*FORMS
/*********************************************************************************************** */

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/forms-reports/",
});

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/",
// });

const formsBufferInstance = createAxiosInstance({
	baseURL: userBaseUrl + "/forms-reports/",
	responseType: "arraybuffer",
});

// const localFormsBufferInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/",
// 	responseType: "arraybuffer",
// });

/**
 * request body for generating a form, inventoryForm, or report dynamically
 *
 * {dealID, inventoryID, dcReportID, params}
 *
 * Where params are the parameters described by dcReport.paramaters
 * @param {pdfRequest} body
 * @returns
 */
export const getPdf = async (body) => {
	return await formsBufferInstance.post("forms/pdf", body);
};

/**
 * Submit a single form for docuseal esignature
 *
 * Generates a form by either dealID or inventoryID and dcReportID, emailing the
 * buyer, optional cobuyer and the dealer for electronically signing the document
 *
 * @param {Integer} packID
 * @returns
 */
export const pdfEsignature = async (body) => {
	return await instance.post(`forms/pdf/esignature`, body);
};

/**
 * This form does not get loaded up dynamically and in actuality is a Jasper Report. Since it is
 * a very special case im explicitly declaring for simplicity
 *
 * @param {Integer} inventoryID
 * @returns
 */
export const getKbbBooksheet = async (inventoryID) => {
	return await formsBufferInstance.get(
		`forms/inv/KBB-Booksheet/${inventoryID}`
	);
};

/* Queries from the API*/
export const getAllStandardForms = async (p) => {
	return await instance.get(
		`dcReports/all-standard-forms/${p.dealerID}/${p.locationID}`
	);
};

export const getAllStateForms = async (p) => {
	return await instance.get(
		`dcReports/all-state-forms/${p.state}/${p.dealerID}/${p.locationID}`
	);
};

/**
 *
 * @param {Object} p contains the parameters dealerId and locationID
 */
export const getAllCustomForms = async (p) => {
	return await instance.get(
		`dcReports/all-custom-forms/${p.dealerID}/${p.locationID}`
	);
};
/**
 *
 * @param {Object} p contains the parameters dealerId and locationID
 */
export const getAllOkiForms = async (p) => {
	return await instance.get(
		`dcReports/all-oki-forms/${p.dealerID}/${p.locationID}`
	);
};

// get Inventory form
export const getAllInventoryForms = async (p) => {
	return await instance.get(
		`dcReports/all-inventory-forms/${p.dealerID}/${p.locationID}`
	);
};
// get Trade form
export const getAllTradeForms = async (p) => {
	return await instance.get(
		`dcReports/all-trade-forms/${p.dealerID}/${p.locationID}`
	);
};
// Save  form
export const saveForm = async (body) => {
	return await instance.post(`dcReports/save`, body);
};

// Save  form
export const saveDefaults = async (body) => {
	return await instance.post(`dcReports/default/save`, body);
};

/*********************************************************************************************** */
/*REPORTS
/*********************************************************************************************** */
/* Functions for Reports */

// TODO
// no idea why the functions dont match the endpoint names
export const getReports = async (endpoint, params) => {
	const inst = createAxiosInstance({
		baseURL: endpoint,
		responseType: "arraybuffer",
	});

	return await inst.post("", params);
};

/* Queries from the API*/
export const getAllInventoryReports = async (p) => {
	return await instance.get(
		`dcReports/all-inventory-reports/${p.dealerID}/${p.locationID}`
	);
};

export const getAllManagementReports = async (p) => {
	return await instance.get(
		`dcReports/all-management-reports/${p.dealerID}/${p.locationID}`
	);
};

export const getAllSalesReports = async (p) => {
	return await instance.get(
		`dcReports/all-sales-reports/${p.dealerID}/${p.locationID}`
	);
};
/**
 * @param {Object} p contains the parameters dealerId and locationID
 */
export const getAllCustomReports = async (p) => {
	return await instance.get(
		`dcReports/all-custom-reports/${p.dealerID}/${p.locationID}`
	);
};

/*********************************************************************************************** */
/* PACK * P = dcPack, R = dcReports, RP = dcReportsPack
/*********************************************************************************************** */
/* Functions for Pack */

/**
 * Retrieves all forms that the dealer can add to a pack.
 *
 * This includes, standard, their state forms, and any custom forms they have had added to the system
 *
 * @param {Object} p contains the parameters dealerId and locationID
 */
export const getAllForms = async (p) => {
	return await instance.get(`dcReports/all-list/${p.dealerID}/${p.locationID}`);
};

/**
 * Retrieves all of the dealer's P records
 *
 * @param {Object} p contains the parameters dealerId and locationId
 * @returns
 */
export const getPackList = async (p) => {
	return await instance.get(
		`dcReports/pack/list/${p.dealerID}/${p.locationID}`
	);
};

/**
 * Retrieves a pack by id.
 *
 * The object includes the P entry and the list of R records (forms) in the pack
 *
 * @param {Integer} packID
 * @returns
 */
export const getPack = async (p) => {
	return await instance.get(
		`dcReports/pack/${p.id}/${p.dealerID}/${p.locationID}`
	);
};

/**
 * Saves a pack to the database.
 *
 * This endpoint can be used to save a new pack or update an existing pack. When saving a new pack,
 * the P.ID will be generated like our other tables. It should be noted that P.packName must be
 * unique for the dealer, saving will fail if the dealer tries to save a new pack with an existing
 * P.packName, regardless of ID
 *
 * @param {Object} packObj
 * @returns {Object} packObj
 */
export const savePack = async (packObj) => {
	return await instance.post(`dcReports/pack/save`, packObj);
};

/**
 * Deletes a pack by id.
 *
 * First deletes the RP records then the P record itself
 *
 * @param {Integer} packID
 * @returns
 */
export const deletePack = async (packID) => {
	return await instance.get(`dcReports/pack/delete/${packID}`);
};

/**
 * Zip a pack for download
 *
 * Generates all forms in a pack from dealID and packID and zips them
 *
 * @param {Integer} packID
 * @returns
 */
export const zipPack = async (body) => {
	return await formsBufferInstance.post(`forms/pack/zip/`, body);
};

/**
 * Merges a pack for printing
 *
 * Generates all forms in a pack from dealID and packID and merges them into a single pdf for printing
 *
 * @param {Integer} packID
 * @returns
 */
export const mergePack = async (body) => {
	return await formsBufferInstance.post(`forms/pack/merge/`, body);
};

/**
 * Zip a pack and requested attachments for download
 *
 * Generates all forms in a pack from dealID and packID and zips them with the attachments selected by the dealer
 *
 * @param {Integer} packID
 * @returns
 */
export const zipLenderPack = async (body) => {
	return await formsBufferInstance.post(`forms/lender-packet`, body);
};

/**
 * submit pack for docuseal esignature
 *
 * Generates all forms in a pack from dealID and packID, merges them into
 * a single pdf, and emails them to buyer, optional cobuyer, and dealer
 * for electronically signing the document
 *
 * @param {Integer} packID
 * @returns
 */
export const packEsignature = async (body) => {
	return await instance.post(`forms/pack/esignature`, body);
};

export const getQuickQuote = async (body) => {
	return await formsBufferInstance.post("forms/QuickQuote", body);
};

// get BHPH form
export const getAllBHPHForms = async (p) => {
	return await instance.get(`dcReports/all-BHPH-forms`);
};
// get BHPH Reports
export const getAllBHPHReports = async (p) => {
	return await instance.get(`dcReports/all-BHPH-reports`);
};
