import React from "react";
import { Button, Row } from "reactstrap";
import siriusXMImage from "assets/img/Inventory-Advertising/siriusXM.png";

export const SiriusXMButton = () => {
	const commitData = () => {
		window.open(
			"https://care.siriusxm.com/vinlookup_view.action;",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Row>
				<img style={{maxWidth:"150px"}} src={siriusXMImage} alt="SiriusXM" />
				<Button
				onClick={commitData}
				alt="SiriusXM"
				className="btn-md btn-primary ml-3"
			>
				Check Status
			</Button>
		</Row>
	);
};
export default SiriusXMButton;
