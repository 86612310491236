import React from "react";

import useBuildCustomer from "../../../hooks/useBuildCustomer";
import useCurrentCustomerStore from "../../../stores/CustomerStore";

import { saveCustomer } from "../../../api/CustomerAPI";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import {
	buildCurrentAddress,
	buildMailingAddress,
	hasValidFields,
} from "./validAddressUtil";
import {
	failAlert,
	retrieveFailAlert,
	saveFailAlertMessage,
	saveSuccessAlert,
} from "../../../utils/alertUtils";

import { useFormContext } from "react-hook-form";
import { showApiError } from "../../../utils/errorRoutingUtils";
import { DealStatus } from "constants/Constants";

const UpdateDealCustomerButton = ({
	editCustomer,
	editAddress,
	editMailAddress,
	editRecNum,
	toggle,
	type,
}) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Get customer details object
	const customer = useBuildCustomer();

	// Deal store
	// Customer store
	const {
		storeCurrentAddress,
		storeMailingAddress,
		insurance,
	} = useCurrentCustomerStore((state) => ({
		storeCurrentAddress: state.currentAddress,
		storeMailingAddress: state.mailingAddress,
		insurance: state.insurance,
	}));

	// Updates customer's info to the database
	const save = (data) => {
		const message = "All required or all empty";

		const currentAddress = buildCurrentAddress(
			data,
			dealerID,
			locationID,
			storeCurrentAddress.ID
		);

		const mailingAddress = buildMailingAddress(
			data,
			dealerID,
			locationID,
			storeMailingAddress.ID
		);

		const validCurrentAddress = hasValidFields(currentAddress);
		const validMailingAddress = hasValidFields(mailingAddress);

		if (!validCurrentAddress || !validMailingAddress) {
			if (!validCurrentAddress) {
				methods.setError("currentCity", { message });
				methods.setError("currentState", { message });
				methods.setError("currentZip", { message });
				//methods.setError("currentCounty", { message });
			}

			if (!validMailingAddress) {
				methods.setError("mailCity", { message });
				methods.setError("mailState", { message });
				methods.setError("mailZip", { message });
				//methods.setError("mailCounty", { message });
			}

			return;
		}

		const saveData = {
			customer,
			insurance,
			currentAddress,
			mailingAddress,
		};

		let getZip = methods.getValues("currentZip");
		if (saveData.customer.ID != null) {
			saveCustomer(saveData).then(
				(res) => {
					if (getZip.length > 0) {
						updateInfo(res);
						saveSuccessAlert();
					} else {
						failAlert("Zip required in Address History Tab.");
						methods.setError("currentZip", {
							type: "Required",
							message: "Zip Required",
						});
					}
				},
				(err) => {
					console.log(err);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			saveFailAlertMessage(
				"Something went wrong, please reload the page and try again."
			);
		}
	};

	// Updates the store information
	const updateInfo = (res) => {
		const customerInfo = res.data.content.customer;
		console.log(customerInfo);

		editRecNum(customerInfo.ID);
		editCustomer(customerInfo);
		editAddress(res.data.content.currentAddress);
		editMailAddress(res.data.content.mailingAddress);
		saveSuccessAlert();
		toggle();
	};

	// Get form context
	const methods = useFormContext();

	return (
		<>
			{type === DealStatus.PENDING && (
				<div
					className="btn btn-success float-button  hi-z-index"
					onClick={methods.handleSubmit(save)}
				>
					<i className="nc-icon nc-check-2" /> Save
				</div>
			)}
		</>
	);
};

export default UpdateDealCustomerButton;
