import React from "react";
import VendorSettingTab from "./VendorSettingTab";

const VendorPage = () => {
	return (
		<div className="content">
			<VendorSettingTab />
		</div>
	);
};

export default VendorPage;
