import Dashboard from "features/dashboard/components/Dashboard";
import InventoryAddPage from "features/inventory/components/InventoryAddPage";
import InventoryListPage from "features/inventory/components/InventoryListPage";
import CustomerDetailPage from "features/crm/subFeatures/contactDetailPage/CustomerDetailPage";
import CustomerListPage from "features/crm/subFeatures/contactListPage/CustomerListPage";
import CustomerAddPage from "features/crm/components/CustomerAddPage";
import DealListPage from "features/deal/components/DealListPage";
import DevLogin from "features/userManagement/components/DevLogin.js";
import InventoryDetailsPage from "features/inventory/components/InventoryDetailPage";
import { PATHS } from "constants/Constants";
import DealAddPage from "features/deal/components/DealAddPage";
import DealDetailPage from "features/deal/components/DealDetailPage";
import QuickQuoteDeal from "features/deal/subFeatures/quickQuote/QuickQuoteDeal";
import BuyersGuideView from "features/forms/components/stateForms/ca/inventory/BuyersGuideView";

const routes = [
	{
		path: PATHS.DASHBOARD,
		name: "Dashboard",
		icon: "nc-icon nc-layout-11",
		component: Dashboard,
		layout: "/dev",
	},
	{
		path: PATHS.LOGIN,
		name: "Login",
		icon: "nc-icon nc-simple-add",
		component: DevLogin,
		layout: "/dev",
		invisible: true,
	},
	{
		collapse: true,
		name: "Inventory",
		icon: "nc-icon nc-delivery-fast",
		state: "inventoryCollapse",
		views: [
			{
				path: PATHS.INVENTORY_LIST,
				name: "View Inventory",
				icon: "nc-icon nc-bullet-list-67",
				component: InventoryListPage,
				layout: "/dev",
			},
			{
				path: PATHS.INVENTORY_ADD,
				name: "Add New Inventory",
				icon: "nc-icon nc-simple-add",
				component: InventoryAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.INVENTORY_DETAIL,
				name: "Inventory Details",
				component: InventoryDetailsPage,
				layout: "/dev",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Contacts",
		icon: "nc-icon nc-single-02",
		state: "contactCollapse",
		views: [
			{
				path: PATHS.CUSTOMER_LIST,
				name: "View Customers",
				icon: "nc-icon nc-bullet-list-67",
				component: CustomerListPage,
				layout: "/dev",
			},
			{
				path: PATHS.LEAD_ADD,
				name: "Add Lead",
				icon: "nc-icon nc-simple-add",
				component: CustomerAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.CUSTOMER_DETAIL,
				name: "Customer Detail",
				component: CustomerDetailPage,
				layout: "/dev",
				invisible: true,
			},
			{
				path: PATHS.LEAD_DETAIL,
				name: "Lead Detail",
				component: CustomerDetailPage,
				layout: "/dev",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Deals",
		icon: "nc-icon nc-briefcase-24",
		state: "dealCollapse",
		views: [
			{
				path: PATHS.DEAL_LIST,
				name: "View Deals",
				icon: "nc-icon nc-bullet-list-67",
				component: DealListPage,
				layout: "/dev",
			},
			{
				path: PATHS.DEAL_ADD,
				name: "New Deal",
				icon: "nc-icon nc-simple-add",
				component: DealAddPage,
				layout: "/dev",
			},
			{
				path: PATHS.QuickQuote,
				name: "Quick Quote",
				icon: "nc-icon nc-bullet-list-67",
				component: QuickQuoteDeal,
				layout: "/dev",
			},
			{
				path: PATHS.DEAL_DETAIL,
				name: "Deal Detail",
				component: DealDetailPage,
				layout: "",
				invisible: true,
			},
		],
	},
	{
		collapse: true,
		name: "Reports",
		icon: "nc-icon nc-refresh-69",
		state: "reportsCollapse",
		invisible: "true",
		views: [],
	},
	//{
	// collapse: true,
	// name: "Communication",
	// icon: "fa fa-comments-o",
	// state: "communicationCollapse",
	// views: [
	// {
	// 	path: PATHS.MESSAGE_TEMPLATES,
	// 	name: "Templates",
	// 	icon: "nc-icon nc-bullet-list-67",
	// 	component: MessageTemplates,
	// 	layout: "",
	// },
	// ],
	//},
	{
		collapse: true,
		name: "Forms",
		icon: "nc-icon nc-refresh-69",
		state: "formsCollapse",
		invisible: "true",
		views: [
			{
				path: PATHS.BUYERS_GUIDE,
				name: "Buyers Guide",
				icon: "nc-icon nc-bullet-list-67",
				component: BuyersGuideView,
				layout: "/dev",
			},
		],
	},
];

export default routes;
