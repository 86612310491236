import create from "zustand";
import { getNotesArray } from "../features/notes/NoteFunctions";
import produce from "immer";

const useCurrentAccountStore = create((set) => ({
	account: {},
	buyer: {},
	coBuyer: {},
	vehicle: {},
	county: {},
	amortizations: [],
	payments: [],
	pickupPmts: [],
	adjustments: [],
	lateFees: [],

	buyerAddress: {},
	buyerMailAddress: {},
	coBuyerAddress: {},
	coBuyerMailAddress: {},
	isLoading: true,
	salesPerson: {},
	lenders: [],
	salesPeople: [],
	lender: {},
	editAccount: (obj) => set(() => ({ account: obj })),
	editBuyer: (obj) => set(() => ({ buyer: obj })),
	editCoBuyer: (obj) => set(() => ({ coBuyer: obj })),
	editVehicle: (obj) => set(() => ({ vehicle: obj })),
	editCounty: (obj) => set(() => ({ county: obj })),
	editAdjustments: (list) => set(() => ({ adjustments: list })),
	editLateFees: (list) => set(() => ({ lateFees: list })),

	editAmortizations: (list) => set(() => ({ amortizations: list })),
	editPayments: (list) => set(() => ({ payments: list })),
	editPickupPmts: (list) => set(() => ({ pickupPmts: list })),
	editLenders: (list) => set(() => ({ lenders: list })),
	editLender: (obj) => {
		console.log("lender is changing");
		set(() => ({ lender: obj }));
	},
	editBuyerAddress: (obj) => set(() => ({ buyerAddress: obj })),
	editBuyerMailAddress: (obj) => set(() => ({ buyerMailAddress: obj })),
	editCoBuyerAddress: (obj) => set(() => ({ coBuyerAddress: obj })),
	editCoBuyerMailAddress: (obj) => set(() => ({ coBuyerMailAddress: obj })),
	editIsLoading: (e) => set(() => ({ isLoading: e })),
	editContractInfo: (obj) => set(() => ({ contractInfo: obj })),
	editSalesPerson: (obj) => set(() => ({ salesPerson: obj })),
	editAccountNotes: (e) =>
		set(
			produce((state) => {
				state.account.notes = e;
			})
		),

	setAccountFields: (res) => {
		console.log(res);
		set(() => ({
			...res,
			account: {
				...res.account,
				notes: getNotesArray(res.account.notes),
			},
		}));
	},

	reset: () => {
		console.log("a reset");
		set({
			account: {},
			buyer: {},
			coBuyer: {},
			vehicle: {},
			county: {},
			amortizations: [],
			adjustments: [],
			lateFees: [],
			payments: [],
			pickupPmts: [],
			buyerAddress: {},
			buyerMailAddress: {},
			coBuyerAddress: {},
			coBuyerMailAddress: {},
			isLoading: true,
			salesPerson: {},
			lenders: [],
			salesPeople: [],
			lender: {},
		});
	},
}));

export default useCurrentAccountStore;
