import React, { useState, useEffect } from "react";
import { Button, Form } from "reactstrap";

import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import useCurrentCustomerStore from "../../../../../stores/CustomerStore";
import useCurrentDealStore from "../../../../../stores/DealStore";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import InputElement from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";
import ZipCityTable from "../../../../../components/tables/ZipCityTable";

import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { saveEmployeeHistory } from "../../../../../api/CustomerAPI";
import {
	stateOptions,
	DealStatus,
	IncomeType,
	EmployerType,
} from "../../../../../constants/Constants";
import { useFromUser } from "../../../../../stores/LocalStorageHelper";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../../../../utils/alertUtils";
import {
	SelectorElement,
	CurrencyInputElement,
	PhoneInputElement,
	ZipInputElement,
} from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";
import dayjs from "dayjs";

const CustomerEmploymentHistoryForm = ({
	currentIndex,
	setCurrentIndex,
	toggle,
	isDeal,
}) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Deal store
	const { type, editBuyerEmploymentHistory } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editBuyerEmploymentHistory: state.editBuyerEmploymentHistory,
		}),
		shallow
	);

	// Customer store
	const { custRecNum, empHist, editEmpHist } = useCurrentCustomerStore(
		(state) => ({
			custRecNum: state.ID,
			empHist: state.empHist,
			editEmpHist: state.editEmpHist,
		}),
		shallow
	);

	// Local state
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [company, setCompany] = useState("");
	const [position, setPosition] = useState("");
	const [salary, setSalary] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [contact, setContact] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [months, setMonths] = useState("");
	const [years, setYears] = useState("");
	const [salaryType, setSalaryType] = useState("");
	const [employmentStatus, setEmploymentStatus] = useState("");
	const [isCurrent, setIsCurrent] = useState("");

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipBlur = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city,
				state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setCity(address.city);
		setState(address.state);
	};

	// Clear local states
	const clearFields = () => {
		setCompany("");
		setPosition("");
		setSalaryType("");
		setEmploymentStatus("");
		setSalary("");
		setStreet("");
		setCity("");
		setState("");
		setZip("");
		setContact("");
		setPhone("");
		setEmail("");
		setFromDate("");
		setToDate("");
		setMonths("");
		setYears("");
		setCurrentIndex(empHist.length);
	};

	const getID = () => {
		console.log("Address ID: " + typeof empHist.ID);
		if (empHist[currentIndex] === undefined) {
			return null;
		} else if (empHist[currentIndex].ID === undefined) {
			return null;
		} else {
			return empHist[currentIndex].ID;
		}
	};

	// Returns an updated store object with the new address
	const empList = (hist, id) => {
		let obj = empHist;

		hist.ID = id;
		obj[currentIndex] = hist;

		return obj;
	};

	// Calls api to add the address to database or update if it exists
	// If updating an sold customer's address, just add the new obj to the store address history list
	const addEmployee = () => {
		const ID = getID();
		const newEmployee = {
			ID,
			company,
			position,
			salaryType,
			employmentStatus,
			salary,
			street,
			city,
			state,
			zip,
			contact,
			phone,
			email,
			fromDate,
			toDate,
			custRecNum,
			dealerID,
			locationID,
			months,
			years,
			isCurrent,
		};

		console.log(newEmployee);
		console.log("Current index is: " + currentIndex);

		saveEmployeeHistory(newEmployee).then(
			(response) => {
				console.log(response);
				if (currentIndex != null) {
					editEmpHist(empList(newEmployee, response.data.content.ID));
				}
				if (isDeal) {
					editBuyerEmploymentHistory(
						empList(newEmployee, response.data.content.ID)
					);
				}
				clearFields();
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Get validation context
	const methods = useFormContext();

	//get time different between from and to date
	const findYearsAndMonth = () => {
		let findMonth = 0;
		if (toDate !== null && toDate !== "") {
			findMonth = dayjs(toDate).diff(fromDate, "month");
		} else {
			findMonth = dayjs(dayjs()).diff(fromDate, "month");
		}
		let getMonth = findMonth % 12;
		let getYear = Math.floor(findMonth / 12);
		setMonths(getMonth || 0);
		setYears(getYear || 0);
	};

	useEffect(() => {
		if (employmentStatus === "current") setIsCurrent(true);
		else setIsCurrent(false);
	}, [employmentStatus]);

	useEffect(() => {
		setCompany(empHist[currentIndex]?.company || "");
		setPosition(empHist[currentIndex]?.position || "");
		setSalary(empHist[currentIndex]?.salary || "");
		setStreet(empHist[currentIndex]?.street || "");
		setCity(empHist[currentIndex]?.city || "");
		setState(empHist[currentIndex]?.state || "");
		setZip(empHist[currentIndex]?.zip || "");
		setContact(empHist[currentIndex]?.contact || "");
		setPhone(empHist[currentIndex]?.phone || "");
		setEmail(empHist[currentIndex]?.email || "");
		setFromDate(empHist[currentIndex]?.fromDate || "");
		setToDate(empHist[currentIndex]?.toDate || "");
		setSalaryType(empHist[currentIndex]?.salaryType || "");
		setEmploymentStatus(empHist[currentIndex]?.employmentStatus || "");
		setMonths(empHist[currentIndex]?.months || "");
		setYears(empHist[currentIndex]?.years || "");
		return clearFields;

		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Employment History"
				inputComponents={[
					<InputElement
						{...methods}
						value={company}
						onChange={(e) => {
							setCompany(e.target.value);
						}}
						name="addEmploymentCompany"
						label="Company"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={position}
						onChange={(e) => {
							setPosition(e.target.value);
						}}
						name="addEmploymentPosition"
						label="Position"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						value={salaryType}
						onChange={(e) => {
							setSalaryType(e.target.value);
						}}
						name="addEmploymentSalaryType"
						label="Income Type"
						options={IncomeType}
					/>,
					<SelectorElement
						{...methods}
						value={employmentStatus}
						onChange={(e) => {
							setEmploymentStatus(e.target.value);
						}}
						name="addEmploymentType"
						label="Employment Type"
						options={EmployerType}
					/>,
					<CurrencyInputElement
						value={salary}
						onChange={(e) => {
							setSalary(e);
						}}
						name="addEmploymentSalary"
						label="Gross Monthly Income"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={street}
						onChange={(e) => setStreet(e.target.value)}
						name="addEmploymentStreet"
						label="Street Address"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						name="addEmploymentCity"
						label="City"
						type="text"
					/>,
					<ZipInputElement
						{...methods}
						value={zip}
						onChange={setZip}
						openZipModal={handleZipBlur}
						name="addEmploymentZip"
						label="Zip"
					/>,
					<InputElement
						{...methods}
						value={contact}
						onChange={(e) => setContact(e.target.value)}
						name="addEmploymentContact"
						label="Contact"
						type="text"
					/>,
					<PhoneInputElement
						{...methods}
						value={phone}
						onChange={setPhone}
						name="addEmploymentPhone"
						label="Phone"
					/>,
					<InputElement
						{...methods}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						name="addEmploymentEmail"
						label="Email"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={fromDate}
						onBlur={findYearsAndMonth}
						onChange={(e) => setFromDate(e.target.value)}
						name="addEmploymentFromDate"
						label="From Date"
						type="date"
					/>,
					<InputElement
						{...methods}
						value={toDate}
						onBlur={findYearsAndMonth}
						onChange={(e) => setToDate(e.target.value)}
						name="addEmploymentToDate"
						label="To Date"
						type="date"
					/>,

					<InputElement
						{...methods}
						readOnly={true}
						value={years}
						name="year"
						label="Years"
						type="number"
					/>,
					<InputElement
						{...methods}
						readOnly={true}
						value={months}
						name="month"
						label="Months"
						type="number"
					/>,
					<SelectorElement
						{...methods}
						name="addEmploymentState"
						label="State"
						value={state}
						onChange={(e) => setState(e.target.value)}
						options={stateOptions}
					/>,
				]}
				buttons={
					<div className="d-flex justify-content-center">
						<Button
							readOnly={type !== DealStatus.PENDING}
							color="primary"
							onClick={methods.handleSubmit(addEmployee)}
						>
							Save
						</Button>
					</div>
				}
			/>
			<ChildrenModal
				modal={cityModal}
				toggle={toggleCityModal}
				modalTitle="Cities"
			>
				<ZipCityTable
					data={addressData}
					toggle={toggleCityModal}
					address={{
						city,
						state,
						zip,
					}}
					setAddress={setAddressFields}
				/>
			</ChildrenModal>
		</Form>
	);
};
export default CustomerEmploymentHistoryForm;
