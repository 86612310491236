import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, CardText, Col, Row } from "reactstrap";
import shallow from "zustand/shallow";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentRecapStore from "stores/RecapStore";
import { CurrencyInput } from "features/forms/components/InputElement";
import { LenderFeeForm } from "./LenderFeeForm";

/**
 * LenderFeeCalculator component to display and manage lender fees.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
const LenderFeeCalculator = () => {
	const { totalLenderFee } = useCurrentRecapStore((state) => state, shallow);
	const [open, setOpen] = useState(false);

	/**
	 * Toggles the state of the modal.
	 */
	const toggle = () => {
		setOpen(!open);
	};

	return (
		<Row>
			<Col md="6">
				<CardText>
					<Button
						className="active btn-round btn-md btn btn-outline-primary w-100 my-0"
						onClick={toggle}
					>
						Lender Fee (-)
					</Button>
				</CardText>
			</Col>
			<Col md="6">
				<CurrencyInput
					value={totalLenderFee}
					readOnly
					disableValidation
					wholeRow
				/>
			</Col>
			<ChildrenModal
				modal={open}
				toggle={toggle}
				size="lg"
				modalTitle="Lender Fees"
				children={<LenderFeeForm />}
			/>
		</Row>
	);
};

LenderFeeCalculator.propTypes = {
	totalLenderFee: PropTypes.number.isRequired,
};

export default LenderFeeCalculator;
