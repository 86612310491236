import React from "react";
import { withRouter } from "react-router-dom";

import LoginForm from "./LoginForm";

const DevLogin = () => {
	return <LoginForm />;
};

export default withRouter(DevLogin);
