import React, { useEffect, useState } from "react";
import { Button, Table } from "reactstrap";

import useCurrentDealStore from "../../../../../stores/DealStore";
import useCurrentSaleStore from "../../../../../stores/SaleStore";
import useBuildVehicle from "hooks/useBuildVehicle";
import useRoute from "hooks/useRoute";
import Loading from "../../../../../components/loadingSpinner/Loading";

import { getBuildDealTemplate, getDealTemplatesNew } from "api/DealAPI";
import { getCustomersCurrentAddress } from "../../../../../api/CustomerAPI";
import { getSettings } from "api/SettingsAPI";
import { retrieveFailAlert } from "../../../../../utils/alertUtils";
import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/Constants";

import dayjs from "dayjs";
import shallow from "zustand/shallow";
import useSetDeal from "../../../../deal/hooks/useSetDeal";
import { buildNewDeal } from "api/DealAPI";

const DealTemplateModal = ({ newVehicle, customer, coCustomer }) => {
	// Date objects
	const today = dayjs().format("YYYY-MM-DD");
	const dealNum = dayjs().format("MMDDYY-HHmmss");

	// Grab information from local storage
	const dcLoginID = useFromUser("ID");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");

	// Routes
	const history = useHistory();
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);
	const dashboardRoute = useRoute(PATHS.DASHBOARD);

	// Template modal state
	const [templates, setTemplates] = useState([]); // List of dealers templates

	// Toggles the template modal

	const getVehicle = useBuildVehicle();

	// Table headers
	const headers = ["Template Name", ""];

	// Table rows
	const rows = templates.map((obj) => (
		<tr key={obj.ID}>
			<td>{obj.name}</td>
			<td className="d-flex justify-content-center">
				<Button className="btn-md" onClick={() => selectTemplate(obj)}>
					Select
				</Button>
			</td>
		</tr>
	));

	// Sale store
	const {
		price,
		editDealNumber,
		editLoanDate,
		editFirstDueDate,
		editFinalDueDate,
		editPrice,
		editDown,
		editTaxRate,
	} = useCurrentSaleStore((state) => state, shallow);

	// Deal store,
	const {
		editCoBuyer,
		isLoading,
		editIsLoading,
		editDealSettings,
		editVehicle,
		editCarRecNum,
		editBuyerRecNum,
		editBuyer,
		editBuyerAddress,
		editCoBuyerRecNum,
		editContractInfo,
		editSalesmanRecNum,
		editSalesPeople,
		editLenders,
		editTradeVendors,
		editWarrantyVendors,
		editSellerFeeTypes,
		editDmvFeeTypes,
		editGapCompanies,
		editCreatedBy,
		editModifiedBy,
		editModifiedById,
		editCreatedById,
		editCreatedOn,
		editModifiedOn,
		editCredit700Lenders,
		editCountyRecNum,
	} = useCurrentDealStore((state) => state, shallow);

	const setDeal = useSetDeal();

	const initializeNewDeal = () => {
		Promise.all([
			getSettings(dealerID, locationID),
			buildNewDeal(locationID),
		]).then(
			(res) => {
				console.log(res[0].data.content);
				const settingsRes = res[0].data.content;
				const newDealRes = res[1].data.content;

				editSalesPeople(newDealRes.salesPeople);
				editLenders(newDealRes.lenders);
				editTradeVendors(newDealRes.tradeVendors);
				editWarrantyVendors(newDealRes.warrantyVendors);
				editSellerFeeTypes(newDealRes.sellerFeeTypes);
				editDmvFeeTypes(newDealRes.dmvFeeTypes);
				editGapCompanies(newDealRes.gapCompanies);
				editCredit700Lenders(newDealRes.credit700Lenders);

				editDealSettings(settingsRes);
				editDealNumber(dealNum);
				editLoanDate(today);
				editFirstDueDate(today);
				editFinalDueDate(today);
				editIsLoading(false);
				editSalesmanRecNum(dcLoginID);
				editCountyRecNum(settingsRes.taxDefaults.countyRecNum);
				editTaxRate(settingsRes.taxDefaults.taxRate);
				initProps();
				history.push(dealDetailPage);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(dashboardRoute);
				}
			}
		);
	};

	// Initialize the stores with the selected template information and then
	// route to the deal details page
	const selectTemplate = (template) => {
		console.log(template);
		Promise.all([
			getSettings(dealerID, locationID),
			getBuildDealTemplate(template.dealID),
		]).then(
			(res) => {
				const { buyer, coBuyer, ...newDeal } = res[1].data.content;
				console.log(res[0].data.content);
				console.log(res[1].data.content);
				// doing this to conform to setDeal rules
				let deal = {};
				let data = {};
				let content = {};

				deal.data = data;
				deal.data.content = content;
				deal.data.content = newDeal;
				console.log(newDeal);
				setDeal(deal.data.content);
				editDealSettings(res[0].data.content);
				editDealNumber(dealNum);
				editLoanDate(today);
				editFirstDueDate(today);
				editFinalDueDate(today);
				editContractInfo(newDeal.contractInfo);
				editSalesmanRecNum(dcLoginID);
				editCreatedBy(lastName + ", " + firstName);
				editModifiedBy(lastName + ", " + firstName);
				editModifiedById(dcLoginID);
				editCreatedById(dcLoginID);
				editCreatedOn(today);
				editModifiedOn(today);
				editCountyRecNum(res[0].data.content.taxDefaults.countyRecNum);
				editTaxRate(res[0].data.content.taxDefaults.taxRate);
				//get saleType value from template
				const saleType = newDeal.deal.sale.saleType;
				// Wholesale
				if (saleType === 1) {
					console.log("Test");
					const taxDefaults = {
						additionalTax: 0,
						aftmktTaxRate: 0,
						businessTaxRate: 0,
						capTaxOnReduction: "0",
						countyRecNum: null,
						dealerSmogTaxRate: 0,
						dmvTaxRate: 0,
						docFeeTaxRate: 0,
						gapTaxRate: 0,
						leaseTaxInterest: 0,
						leaseTaxResidual: 0,
						localFixedAmt: 0,
						localTaxMaximum: 0,
						localTaxRate: 0,
						luxuryTaxFloor: 0,
						luxuryTaxNewOnly: 0,
						luxuryTaxRate: 0,
						servContractTaxRate: 0,
						stateTaxAgency: "",
						taxCreditFixAmount: 0,
						taxCreditRate: 0,
						maxTaxCredit: 0,
						taxRate: 0,
						taxRegion: 2,
						useLocalFixed: 0,
						vitRate: 0,
					};

					const settings = {
						...res[0].data.content,
						taxDefaults,
					};
					console.log(settings);
					//update the deal settings obj and save the deal
					editDealSettings(settings);
				}

				initProps(saleType);
				history.push(dealDetailPage);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const initProps = (saleType) => {
		const vehicleStore = getVehicle();
		//this vehicle comes from list page and another one comes
		// from detail page
		console.log(newVehicle);
		if (newVehicle) {
			editVehicle(newVehicle);
			editCarRecNum(newVehicle.inventoryID);
			//if saleType is wholesale set price to wholesale price
			if (saleType === 1) {
				editPrice(newVehicle?.wholesalePrice || 0);
			} else {
				editPrice(newVehicle?.askingPrice || 0);
			}
			console.log(newVehicle?.downPmt);
			editDown(newVehicle?.downPmt);
		} else if (vehicleStore.inventoryID !== null) {
			editVehicle(vehicleStore);
			editCarRecNum(vehicleStore.inventoryID);
			if (saleType === 1) {
				editPrice(vehicleStore?.wholesalePrice || 0);
			} else {
				editPrice(vehicleStore?.askingPrice || 0);
			}
			console.log(newVehicle?.downPmt);
			editDown(newVehicle?.downPmt);
		} else {
			editVehicle({});
			editCarRecNum(null);
			editPrice(0.0);
		}
		//this customer prop from list page and detail page
		//else by default set to null
		console.log(customer);
		if (customer) {
			editBuyerRecNum(customer.ID);
			editBuyer(customer);
			//	console.log(customer.ID);

			getCustomersCurrentAddress(customer.ID).then(
				(res) => {
					console.log(res.data.content);
					editBuyerAddress(res.data.content);
				},
				(err) => {
					console.log(err);
				}
			);

			console.log(customer);
		} else {
			editBuyerRecNum(null);
		}

		///Cobuyer
		console.log(coCustomer);
		if (coCustomer) {
			editCoBuyerRecNum(coCustomer.ID);
			editCoBuyer(coCustomer);
			//	console.log(customer.ID);

			getCustomersCurrentAddress(coCustomer.ID).then(
				(res) => {
					console.log(res.data.content);
					//editCoBuyerAddress(coCustomer.data.content);
				},
				(err) => {
					console.log(err);
				}
			);

			console.log(coCustomer);
		} else {
			editCoBuyerRecNum(null);
		}
		///
		//editCoBuyer({});
		editIsLoading(false);
	};

	// 0 Template: Route to deal details and initialize deal store
	// 1 Template: Route to deal details and initialize stores with that template information
	// 1+ Template: Route to deal details and initialize stores with selected template information
	const handleTemplateResponse = (res) => {
		const data = res.data.content;

		console.log(data);

		if (data.length === 0) {
			initializeNewDeal();
		} else if (data.length === 1) {
			selectTemplate(data[0]);
		} else {
			editIsLoading(false);
			setTemplates(data);
		}
	};
	// Resets store information and gets the list of all the dealer's template
	// upon initial render

	useEffect(() => {
		console.log(price);
		getDealTemplatesNew(dealerID, locationID).then(
			(res) => handleTemplateResponse(res),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		// eslint-disable-next-line
	}, []);

	return (
		// Only show the modal if there is more than 1 template. Otherwise, the API calls take
		// car of the routing to detail page with the appropriate store values initialized
		<div>
			{(isLoading && <Loading />) || (
				<Table striped hover>
					<thead>
						<tr>
							{headers.map((key, i) => (
								<th key={i}>{key}</th>
							))}
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			)}
		</div>
	);
};

export default DealTemplateModal;
