/* 
  Because the react-select component requires specific data to populate,
  the following functions convert data to and from the requirements of
  the component. convertTO() should be called when data brought in from the db
  convertFrom() should be called when saving to the database. The store will only 
  ever have data in react-select format
*/

// this may come in many forms, but ideally the data is a comma separated string
// call this when getting data from the database
export const convertToReactSelect = (data) => {
	// just return the data as is if there's nothing there
	if (data === "" || data === null) {
		return null;
	}
	// this converts the comma separated string to the proper format
	else {
		const reactSelectOptions = data.split(",").map((key) => {
			return { value: key, label: key };
		});
		return reactSelectOptions;
	}
};

// this function expects an object in the format
// { value: x, label: x }
// call this when saving to the database
export const convertFromReactSelect = (reactSelectData) => {
	if (reactSelectData === null || reactSelectData === "") {
		return "";
	} else {
		const strOptions = reactSelectData.map((key) => {
			return key.value;
		});
		return strOptions.join(",");
	}
};
