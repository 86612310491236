import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardBody,
	CardHeader,
	Collapse,
	Col,
	Row,
	FormGroup,
	Modal,
	Form,
	Button,
} from "reactstrap";
import {
	getCustomEquipmentList,
	saveToCustomEquipmentList
} from "api/InventoryAPI";
import { useFormContext } from "react-hook-form";
import InputElement from "../../../forms/components/InputElement";
import useCurrentVehicleStore from "stores/InventoryStore";
import shallow from "zustand/shallow";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";

/**
 * InventoryCustomEquipment component - Manages and displays custom equipment with checkboxes.
 * 
 * @param {function} changeHandler - Function to handle changes to selected equipment.
 * @param {string} selectedEquip - Comma-separated string of selected equipment values.
 * @param {boolean} disabled - Flag to indicate if checkboxes should be disabled.
 * @returns {JSX.Element} A responsive grid of custom equipment checkboxes.
 */
const InventoryCustomEquipment = ({
	changeHandler,
	selectedEquip,
	disabled,
}) => {
	const { customEquipList, editCustomEquipList } = useCurrentVehicleStore(
		(state) => state,
		shallow
	);

	const [newEquipDescription, setNewEquipDescription] = useState("");
	const [newEquipItemCode, setNewEquipItemCode] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	const methods = useFormContext();

	useEffect(() => {
		getCustomEquipList();
		// eslint-disable-next-line
	}, []);

	if (!customEquipList) {
		return null; // or return <div>No options available</div> or similar
	}

	let d = disabled;
	if (customEquipList === "" || customEquipList === null) {
		d = true;
	}

	// Convert selectedEquip to an array
	const selectedEquipArray = selectedEquip ? selectedEquip.split(",") : [];
	const handleCheckboxChange = (event, value) => {
		const newSelectedEquipArray = event.target.checked
			? [...selectedEquipArray, value]
			: selectedEquipArray.filter((item) => item !== value);
		changeHandler(newSelectedEquipArray.join(","));
	};
	const columnSize = Math.ceil(customEquipList.length / 4); // Adjusting for 4 columns in total
	const columns = [];
	for (let i = 0; i < customEquipList.length; i += columnSize) {
		columns.push(customEquipList.slice(i, i + columnSize));
	}

	const handleAddEquipment = () => {
		// Validation to prevent adding blank entries
		if (!newEquipDescription.trim() || !newEquipItemCode.trim()) {
			alert("Please provide both description and item code for the new equipment.");
			return;
		}

		// Construct the request body
		const body = {
			dealerID,
			locationID,
			isHidden: false,
			description: newEquipDescription,
			itemCode: newEquipItemCode,
			specialCode: "C",
		};

		// Call your API function
		saveToCustomEquipmentList(body)
			.then((response) => {
				// Clear the input fields on success
				setNewEquipDescription("");
				setNewEquipItemCode("");
				// Update the customEquipList state directly
				editCustomEquipList([...customEquipList, { label: body.description, value: body.description }]);
			})
			.catch((error) => {
				// Handle any errors here
				console.error(error);
			});
	};

	const getCustomEquipList = () => {
		const customEquipList = [];
		getCustomEquipmentList(locationID, false).then(
			(res) => {
				const resp = res.data.content;
				for (const key in resp) {
					customEquipList.push({
						label: resp[key].description,
						value: resp[key].description,
					});
				}
				editCustomEquipList(customEquipList); // Moved here to ensure state update
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	const toggle = () => setIsOpen(!isOpen);

	return (
		<Card>
			<CardHeader onClick={toggle} className="cursor-pointer d-flex py-2" style={{ justifyContent: "space-between" }}>
				<h3>Custom Equipment</h3>
				<Button className="btn-link btn-sm">
					<i className={`nc-icon ${isOpen ? "nc-minimal-up" : "nc-minimal-down"}`} />
				</Button>
			</CardHeader>
			<Collapse isOpen={isOpen}>
				<CardBody className="pt-0">
					<Row>
						{columns.map((column, columnIndex) => (
							<Col key={`column-${columnIndex}`} xs="12" sm="6" md="3">
								{column.map((option, index) => (
									<div key={index} className="checkbox-item p-2">
										<input
											type="checkbox"
											id={`${option.value}-${columnIndex}-${index}`}
											value={option.value}
											disabled={d}
											checked={selectedEquipArray.includes(option.value)}
											onChange={(e) => handleCheckboxChange(e, option.value)}
										/>
										<label style={{fontSize: "14px"
										}} htmlFor={`${option.value}-${columnIndex}-${index}`}>
											&nbsp;{option.label}
										</label>
									</div>
								))}
							</Col>
						))}
					</Row>
					<div className="row mx-0 mt-2">
						<Button className="btn btn-primary btn-sm" onClick={() => setModalIsOpen(true)}>
							<i className="nc-icon nc-simple-add"></i> Add More Equipment
						</Button>
						<div className="col-md-6 text-right"></div>
					</div>
					<Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(!modalIsOpen)}>
						<CardBody>
							<Col>
								<FormGroup>
									<Form>
										<div>
											<InputElement
												{...methods}
												value={newEquipDescription}
												name="newEquipDescription"
												label="Description"
												type="text"
												wholeRow="true"
												onChange={(e) => setNewEquipDescription(e.target.value)}
											/>
										</div>
										<div>
											<InputElement
												{...methods}
												value={newEquipItemCode}
												name="newEquipItemCode"
												label="Item Code"
												type="text"
												wholeRow="true"
												onChange={(e) => setNewEquipItemCode(e.target.value)}
											/>
										</div>
										<Button
											className="btn btn-md btn-primary"
											onClick={() => {
												handleAddEquipment();
												if (newEquipDescription.trim() && newEquipItemCode.trim()) {
													setModalIsOpen(false);
												}
											}}
										>
											<i className="nc-icon nc-add"></i>
											<span className=""> Add Equipment</span>
										</Button>
										<Button
											className="btn btn-md btn-danger"
											onClick={() => setModalIsOpen(false)}
										>
											<i className="nc-icon nc-close"></i>
											<span className=""> Close</span>
										</Button>

									</Form>
								</FormGroup>
							</Col>
						</CardBody>
					</Modal>
				</CardBody>
			</Collapse>
		</Card>
	);
};

InventoryCustomEquipment.propTypes = {
	changeHandler: PropTypes.func,
	selectedEquip: PropTypes.string,
	disabled: PropTypes.bool,
};

export default InventoryCustomEquipment;