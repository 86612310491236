import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Button, CardHeader, CardTitle } from "reactstrap";
import FilterColumn from "utils/filterColumn";
import ReactTable from "components/tables/ReactTable";
import { supabase } from "../../../supabaseClient";
import DealTermsModal from "./DealTermsModal";
import { confirmAndDeleteApplication } from "../helperFunctions/deleteApplication";

const statusMap = {
	1: "Sent",
	2: "Conditional",
	3: "Rejected",
	4: "Approved",
};

/**
 * Renders the LoansTable component.
 * @param {string} dealId - The ID of the deal for which to fetch loan applications.
 * @returns {JSX.Element} The LoansTable component.
 */
const LoansTable = ({ dealId }) => {
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [selectedDeal, setSelectedDeal] = useState(null);

	const toggleModal = () => setModal(!modal);

	// Fetch data from Supabase
	useEffect(() => {
		const fetchData = async () => {
			const { data: applications, error } = await supabase
				.from("submitted_apps")
				.select(
					`
					id, 
					dealer_id, 
					deal_id, 
					lender_id, 
					status, 
					start_date, 
					deals (
						deal_id,
						term,
						end_date,
						apr,
						monthly_payment,
						down_payment,
						inventory (vehicle), 
						contacts (name)
					),
					lenders (
						name
					)
				`
				)
				.eq("deal_id", dealId);

			if (error) {
				console.error("Error fetching applications:", error);
			} else {
				console.log("Fetched data:", applications); // Log fetched data
				setData(applications);
			}
		};

		fetchData();
	}, [dealId]);

	// Function to update state after successful deletion
	const handleDeleteSuccess = useCallback((deletedRowId) => {
		setData((prevData) => prevData.filter((row) => row.id !== deletedRowId));
	}, []);

	// Function to get the status button color based on status value
	const getStatusButtonColor = (status) => {
		switch (statusMap[status]) {
			case "Sent":
				return "primary";
			case "Conditional":
				return "warning";
			case "Rejected":
				return "danger";
			case "Approved":
				return "success";
			default:
				return "secondary";
		}
	};

	// Function to open the modal and show terms
	const showTerms = (deal) => {
		console.log("Selected deal:", deal); // Debugging log
		setSelectedDeal(deal);
		toggleModal();
	};

	// Cell renderers
	const statusCell = ({ row }) => (
		<Button
			color={getStatusButtonColor(row.original.status)}
			size="md"
			className="w-100"
		>
			{statusMap[row.original.status]}
		</Button>
	);

	const startDateCell = ({ row }) => <span>{row.original.start_date}</span>;
	const lenderCell = ({ row }) => <span>{row.original.lenders.name}</span>;
	const vehicleCell = ({ row }) => (
		<span>{row.original.deals.inventory.vehicle}</span>
	);
	const customerCell = ({ row }) => (
		<span>{row.original.deals.contacts.name}</span>
	);

	const actionCell = ({ row }) => (
		<div className="ml-auto">
			<Button
				color="info"
				size="sm"
				onClick={() => showTerms(row.original.deals)}
				className="mr-2"
			>
				Terms
			</Button>
			<Button
				color="danger"
				size="sm"
				onClick={() =>
					confirmAndDeleteApplication(row.original.id, handleDeleteSuccess)
				}
				className="mr-2"
			>
				Withdraw
			</Button>
		</div>
	);

	return (
		<>
			{data.length > 0 && (
				<div className="px-3">
					<Card className="mt-2">
						<CardHeader>
							<CardTitle tag="h3">Submitted Applications</CardTitle>
						</CardHeader>
						<CardBody>
							<ReactTable
								data={data}
								columns={[
									{
										Header: "Status",
										accessor: "status",
										Cell: statusCell,
										disableFilters: true,
									},
									{
										Header: "Date",
										accessor: "start_date",
										Cell: startDateCell,
										Filter: FilterColumn,
										filter: "includes",
									},
									{
										Header: "Lender",
										accessor: "lenders.name",
										Cell: lenderCell,
										Filter: FilterColumn,
										filter: "includes",
									},
									{
										Header: "Vehicle",
										accessor: "deals.inventory.vehicle",
										Cell: vehicleCell,
										Filter: FilterColumn,
										filter: "includes",
									},
									{
										Header: "Customer",
										accessor: "deals.contacts.name",
										Cell: customerCell,
										Filter: FilterColumn,
										filter: "includes",
									},
									{
										Header: "Actions",
										accessor: "id",
										Cell: actionCell,
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
					<DealTermsModal
						isOpen={modal}
						toggle={toggleModal}
						selectedDeal={selectedDeal}
					/>
				</div>
			)}
		</>
	);
};

export default LoansTable;
