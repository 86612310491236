import React from "react";
import { Button } from "reactstrap";

export const scrollTop = () => {
	// specified this id in admin.js on top div
	document
		.getElementById("main-wrapper")
		.scrollTo({ top: 0, behavior: "smooth" });
};

const ScrollToTop = () => {
	return (
		<div>
			<Button
				onClick={scrollTop}
				style={{
					width: "9%",
					position: "fixed",
					right: 0,
					bottom: 40,
					marginRight: "40px",
				}}
			>
				Back to top
			</Button>
		</div>
	);
};

export default ScrollToTop;