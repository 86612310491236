import React, { useState } from "react";
import { Button } from "reactstrap";

import useIsOwnerOrAdmin from "../useIsOwnerOrAdmin";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import CompanyEmployeesTab from "./CompanyEmployeesTab";
import CompanyEmployeesTable from "./CompanyEmployeesTable";
import SaveCompanyEmployee from "./SaveCompanyEmployee";
import UserRoleAndPrivileges from "../UserRoleAndPrivileges";

import { CompanyEmployeesProvider } from "./CompanyEmployeesContext";

import * as yup from "yup";
import { setLocale } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";

const CompanyEmployeesPage = () => {
	const isOwnerOrAdmin = useIsOwnerOrAdmin();

	// Local state
	const [modal, setModal] = useState(false);
	const [addUser, setAddUser] = useState(true);

	// Toggles modal containing the employee information
	const toggle = () => setModal(!modal);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be at least ${min} characters long",
		},
	});

	// Validation rules for employee fields in the employee tab
	const schema = yup.object().shape({
		employeePersonnelType: yup.string().required().max(50),
		employeeFirstName: yup.string().required(),
		employeeLastName: yup.string().required(),
		employeeRole: yup.string().required(),
		employeeUsername: yup
			.string()
			.matches(/^[A-Za-z0-9]{6,22}$/, {
				message: "Must contain between 6-22 numbers and letters",
			})
			.nullable(true),
		employeePassword: yup
			.string()
			.matches(
				/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_+=(){}[~`!@#$%^&*()\-_+=(){}[\]:;<>,.?\\/|'"]).{8,22}/g,
				{
					message:
						"Must contain between 6-22 numbers, special characters and letters",
				}
			)
			.nullable(true),

		confirmEmployeePassword: yup
			.string()
			.oneOf(
				[yup.ref("employeePassword"), null],
				"Must be the same as password"
			),
	});

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			{isOwnerOrAdmin && (
				<CompanyEmployeesProvider>
					<Button
						className="btn btn-primary btn-sm"
						onClick={() => {
							setAddUser(true);
							toggle();
						}}
					>
						<i className="nc-icon nc-simple-add" />
						Add Employee
					</Button>
					<ChildrenModal
						modal={modal}
						toggle={toggle}
						modalTitle={addUser ? "Add New Employee" : "Edit Employee"}
					>
						<CompanyEmployeesTab />
						<UserRoleAndPrivileges />
						<SaveCompanyEmployee toggle={toggle} />
					</ChildrenModal>
					<div className="mt-2">
						<CompanyEmployeesTable toggle={toggle} setAddUser={setAddUser} />
					</div>
				</CompanyEmployeesProvider>
			)}
		</FormProvider>
	);
};

export default CompanyEmployeesPage;
