import React from "react";
import NavBar from "components/tabs/TabsHolder";
import ProfitSettings from "features/settings/subFeatures/settingsTabs/dealDefault/ProfitSettings";
import CommissionSettings from "features/settings/subFeatures/settingsTabs/dealDefault/CommissionSetting";
import TaxDefaults from "features/settings/subFeatures/settingsTabs/dealDefault/TaxDefaults";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import { Col } from "reactstrap";

/**
 * Tax, profit, and commission settings for the deal detail page.
 * This tab modifies the settings for a particular deal.
 *
 * @component
 */
const DealPageDealSettings = () => {
	// Define the tabs
	const tabs = ["Tax", "Profit", "Commission"];

	// Extract state and state update functions from the store
	const {
		profitSettings,
		editProfitSettings,
		editProfitSettingsValue,
		commissionSettings,
		editCommissionSettings,
		editCommissionSettingsValue,
		taxDefaults,
		editTaxDefaults,
		editTaxDefaultsValue,
	} = useCurrentDealStore(
		(state) => ({
			profitSettings: state.deal.dealSettings.profitSettings,
			editProfitSettings: state.editProfitSettings,
			editProfitSettingsValue: state.editProfitSettingsValue,
			commissionSettings: state.deal.dealSettings.commissionSettings,
			editCommissionSettings: state.editCommissionSettings,
			editCommissionSettingsValue: state.editCommissionSettingsValue,
			taxDefaults: state.deal.dealSettings.taxDefaults,
			editTaxDefaults: state.editTaxDefaults,
			editTaxDefaultsValue: state.editTaxDefaultsValue,
		}),
		shallow
	);

	// Define the components for each tab
	const tabComponents = [
		<TaxDefaults
			isDeal={false}
			taxDefaults={taxDefaults}
			editTaxDefaults={editTaxDefaults}
			editTaxDefaultsValue={editTaxDefaultsValue}
		/>,
		<ProfitSettings
			isDeal={true}
			profitSettings={profitSettings}
			editProfitSettings={editProfitSettings}
			editProfitSettingsValue={editProfitSettingsValue}
		/>,
		<CommissionSettings
			isDeal={true}
			commissionSettings={commissionSettings}
			editCommissionSettings={editCommissionSettings}
			editCommissionSettingsValue={editCommissionSettingsValue}
		/>,
	];

	return (
		<Col>
			<NavBar
				navClass="nav-pills-primary justify-content-center nav nav-pills"
				tabs={tabs}
				tabComponents={tabComponents}
			/>
		</Col>
	);
};

export default DealPageDealSettings;
