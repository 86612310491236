import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Row,
} from "reactstrap";
import InventoryTable from "../../../../components/tables/InventoryTable";
import Loading from "components/loadingSpinner/Loading";
import useCurrentVehicleStore from "../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import useRoute from "../../../../hooks/useRoute";
import { PATHS } from "../../../../constants/Constants";
import history from "utils/createBrowserHistory";
import { useLocation } from "react-router-dom";
import { useFromUser } from "../../../../stores/LocalStorageHelper";
import { retrieveFailAlert } from "utils/alertUtils";
import dayjs from "dayjs";
import {
	getVehicleDetails,
	getVehicleListBySearch,
	removeVehicle,
} from "../../../../api/InventoryAPI";
import {
	successAlertCustom,
	confirmDeleteMessage,
	deleteFailAlert,
} from "utils/alertUtils";
import { showApiError } from "../../../../utils/errorRoutingUtils";
import Camera from "assets/img/placeholder.png";
import useCurrentDealStore from "../../../../stores/DealStore";
import InputElement from "../../../forms/components/InputElement";
import useDateRangeStore from "../../../../stores/DateRangeStore";
import PageLayout from "../../../../components/layouts/PageLayout";
import DealTemplateModal from "../../../settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import ChildrenModal from "../../../../components/modals/ChildrenModal";

const InventorySearchList = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { fromDate, toDate } = useDateRangeStore();

	const initialFromDate = dayjs(fromDate).format("YYYY-MM-DD");
	const initialToDate = dayjs(toDate).format("YYYY-MM-DD");
	const [selectedOption, setSelectedOption] = useState("dateRange");

	const [fromDT, setFromDT] = useState(initialFromDate);
	const [toDT, setToDT] = useState(initialToDate);
	// const [isDateChange, setIsDateChange] = useState(false);
	const [stockNo, setStockNo] = useState("");
	const [vin, setVin] = useState("");
	const [license, setLicense] = useState("");

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	// Vehicle store
	const { editThumbnail, editInventoryID } = useCurrentVehicleStore(
		(state) => ({
			editThumbnail: state.editThumbnail,
			editInventoryID: state.editInventoryID,
		}),
		shallow
	);
	//Deal Store
	const { editCarRecNum } = useCurrentDealStore(
		(state) => ({
			editCarRecNum: state.editCarRecNum,
		}),
		shallow
	);

	const [vehicle, setVehicle] = useState({});
	const [filter, setFilter] = useState("");
	//const [dateRangeError, setDateRangeError] = useState("");

	const inventoryDetail = useRoute(PATHS.INVENTORY_DETAIL);
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(false);
	const location = useLocation();
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const newRoute = useRoute(PATHS.INVENTORY_LIST);
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);
	const handleRowClick = (rowData) => {
		// Assuming rowData contains the fields 'inventoryID' and 'thumbnailURL'
		accessDetailsHandler(rowData.inventoryID, rowData.thumbnailURL);
	};
	// Opens the inventory details page
	const accessDetailsHandler = (inventoryID, thumbnail) => {
		editThumbnail(thumbnail);
		editInventoryID(inventoryID);

		const params = new URLSearchParams(location.search);
		const queryParamState = params.get("state");
		const queryParamCode = params.get("code");

		if (queryParamCode != null && queryParamState != null) {
			history.push(
				`${inventoryDetail}?code=${queryParamCode}&state=${queryParamState}`,
				{ ID: inventoryID }
			);
		} else {
			history.push(inventoryDetail, { ID: inventoryID });
		}
	};

	const getListData = (filter) => {
		console.log("ID: " + dealerID + " " + locationID);
		if (dealerID !== "" && locationID !== "") {
			getVehicleListBySearch(locationID, filter).then(
				(response) => {
					console.log("new list");
					console.log(response);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
						history.push(homeRoute);
					}
				}
			);
		}
	};
	const getVehicleData = (inventoryID) => {
		getVehicleDetails(inventoryID).then(
			(res) => {
				console.log(res.data.content);
				setVehicle(res.data.content);
				editCarRecNum(res.data.content.inventoryID);
				editThumbnail(res.data.content.thumbnail);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(homeRoute);
				}
			}
		);
	};

	const updateSearch = () => {
		if (selectedOption === "dateRange")
			setFilter(
				` ((dcTableVehicle.dateIn >= CONVERT(DATETIME, '${fromDT}', 102)) AND  (dcTableVehicle.dateIn <= CONVERT(DATETIME, '${toDT}', 102))  ) `
			);
		if (selectedOption === "stockNo")
			setFilter(` dcTableVehicle.stockNo like '%${stockNo}%' `);

		if (selectedOption === "vin")
			setFilter(` dcTableVehicle.vin like '%${vin}%' `);
		if (selectedOption === "license")
			setFilter(` dcTableVehicle.license like '%${license}%' `);

		setLoadingList(true);
	};

	// Formats the JSON response object
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			container.thumbnail = (
				<img
					className="zoom"
					alt="vehicle"
					src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = Camera;
					}}
				/>
			);
			container.thumbnailURL = content[key].thumbnail;

			container.inventoryID = content[key].inventoryID;
			container.license = content[key].license;
			container.stockAndVin =
				dayjs(content[key].dateIn).format("YYYY/MM/DD") +
				" " +
				content[key].stockNo;
			container.stockNo = content[key].stockNo;
			container.vehicle =
				content[key].year + " " + content[key].make + " " + content[key].model;
			container.vehicleSearch =
				content[key].make +
				" " +
				content[key].model +
				" " +
				content[key].year +
				content[key].vin +
				content[key].license;
			container.askingPrice =
				"$" +
				parseFloat(content[key].price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
			container.vin = content[key].vin;
			container.status = content[key].vehicleStatus;

			const today = dayjs();
			const start = dayjs(content[key].dateIn);
			const end =
				content[key]?.dateOut === null ||
				content[key]?.dateOut === "" ||
				content[key]?.dateOut <= content[key]?.dateIn
					? today
					: dayjs(content[key].dateOut);
			const diff = end.diff(start, "day");
			container.age = diff;
			container.start = start.format("MM/DD/YYYY");
			container.end = "";
			container.end =
				content[key]?.dateOut === null ? "" : end.format("MM/DD/YYYY");
			container.Actions = (
				<Row>
					<div
						className="zoom btn-sm btn btn-primary"
						onClick={(e) => {
							e.stopPropagation();
							accessDetailsHandler(
								container.inventoryID,
								container.thumbnailURL
							);
						}}
					>
						<i className="icon-big text-center nc-icon nc-paper text-default" />
						&nbsp;View
					</div>
					{filter !== "" &&
						(content[key].vehicleStatus === "Pending" ||
							content[key].vehicleStatus === "Available") && (
							<div
								className="zoom btn-sm btn btn-primary"
								onClick={(e) => {
									e.stopPropagation();
									getVehicleData(container.inventoryID, container.thumbnailURL);
								}}
								readOnly={
									content[key].vehicleStatus !== "Pending" &&
									content[key].vehicleStatus !== "Available"
								}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								<span> Deal</span>{" "}
							</div>
						)}
					{filter !== "" &&
						(container.status === "Deleted" ||
							container.status === "deleted" ||
							container.status === "DELETED") && (
							<div
								className="btn btn-danger btn-sm mt-2" //"zoom btn-sm btn btn-link btn-danger"
								onClick={(e) => {
									e.stopPropagation();
									RemoveVehicle(container.inventoryID);
									//getVehicleData(container.inventoryID, container.thumbnailURL);
								}}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								<span> Remove </span>{" "}
							</div>
						)}
				</Row>
			);
			return container;
		});
		return container;
	};
	const RemoveVehicle = (inventoryID) => {
		let answer = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			answer = res;
			if (answer === "Yes, Delete it") {
				removeVehicle(inventoryID).then(
					(response) => {
						history.push(newRoute);

						successAlertCustom("Your vehicle removed from your inventory!");
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, deleteFailAlert);
						}
					}
				);
			}
		});
	};

	const handleFromDateBlur = () => {
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (diffDays > 730 || diffDays < 0) {
			toDate.setFullYear(fromDate.getFullYear() + 2); // Set toDate to 2 years after fromDate
			setToDT(toDate.toISOString().split("T")[0]);
		}
	};

	const handleToDateBlur = (e) => {
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays > 730 || diffDays < 0) {
			fromDate.setFullYear(toDate.getFullYear() - 2); // Set fromDate to 2 years before toDate
			setFromDT(fromDate.toISOString().split("T")[0]);
		}
	};

	useEffect(() => {
		if (loadingList) getListData(filter);
		// eslint-disable-next-line
	}, [loadingList]);

	return (
		<PageLayout>
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Quick Inventory Search</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="dateRange"
													value="dateRange"
													checked={selectedOption === "dateRange"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={fromDT}
													onBlur={handleFromDateBlur}
													onChange={(e) => setFromDT(e.target.value)}
													name="fromDate"
													label="From Date"
													type="date"
													colSize="col-sm-1.5"
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={toDT}
													onBlur={handleToDateBlur}
													onChange={(e) => setToDT(e.target.value)}
													name="toDate"
													label="To Date"
													type="date"
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
										<div style={{ color: "green" }}>
											The date range cannot exceed 2 years.
										</div>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="stockNo"
													value="stockNo"
													checked={selectedOption === "stockNo"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={stockNo}
													name="stockNo"
													label="Stock Number"
													type="text"
													onChange={(e) => setStockNo(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="vin"
													value="vin"
													checked={selectedOption === "vin"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={vin}
													name="vin"
													label="Vin Number"
													type="text"
													onChange={(e) => setVin(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="license"
													value="license"
													checked={selectedOption === "license"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={license}
													name="license"
													label="License Plate"
													type="text"
													onChange={(e) => setLicense(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
							</Row>
							<div className="text-center mt-3">
								<Button
									color="primary"
									onClick={updateSearch}
									//									disabled={isDateChange}
								>
									Search
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<div>
				{loadingList && <Loading />}
				{!loadingList && (
					<InventoryTable data={data} onRowClick={handleRowClick} />
				)}
				<ChildrenModal
					modalTitle="Deal Templates"
					modal={templateModal}
					toggle={toggleTemplate}
				>
					<DealTemplateModal newVehicle={vehicle} />
				</ChildrenModal>
			</div>
		</PageLayout>
	);
};

export default InventorySearchList;
