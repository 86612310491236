import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";
import ReactTable from "components/tables/ReactTable";
import FilterColumn from "utils/filterColumn";
import { OnlineLeadStatus } from "constants/Constants";

const LeadTable = ({ data, setMessageCustomers, onRowClick }) => {
	console.log(data);
	const Cell = (props) => (
		<>
			<span style={{ fontWeight: "bold" }}>
				<>
					{props.row.original.lastName} {props.row.original.firstName}
				</>
				<>{props.row.original.origin}</>
				<div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
				{props.row.original.phone && (
					<>
						P:{" "}
						<NumberFormat
							value={props.row.original.phone}
							format="+1 (###) ###-####"
							isNumericString={true}
							displayType="text"
						/>
					</>
				)}
				{props.row.original.cellPhone && (
					<>
						C:{" "}
						<NumberFormat
							value={props.row.original.cellPhone}
							format="+1 (###) ###-####"
							isNumericString={true}
							displayType="text"
						/>
					</>
				)}
				{/* <div>Date Generated : {props.row.original.dateGenerated}</div> */}
			</span>
		</>
	);
	const targetVehicleCell = (props) => (
		<>
			<span style={{ fontWeight: "bold" }}>
				<>{props.row.original.lookingFor}</>
				{/* <div>
					{props.row.original.stockNumber != null && (
						<div>Stock#: {props.row.original.stockNumber}</div>
					)}
				</div>
				<div>
					{props.row.original.vin != null && (
						<div>VIN: {props.row.original.vin}</div>
					)}
				</div> */}
			</span>
		</>
	);
	const vehicleCell = (props) => (
		<>
			<span style={{ fontWeight: "bold" }}>
				<>{props.row.original.vehicleDetails}</>
				<>
					{props.row.original.stockNumber != null && (
						<div>Stock#: {props.row.original.stockNumber}</div>
					)}
				</>
				<>
					{props.row.original.vin != null && (
						<div>VIN: {props.row.original.vin}</div>
					)}
				</>
			</span>
		</>
	);

	const statusCell = (props) => (
		<>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.status + " - " + props.row.original.source}
			</span>
		</>
	);
	const DateCell = (props) => (
		<>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.dateGenerated}
			</span>
		</>
	);

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardBody>
						<ReactTable
							className="-striped -highlight primary-pagination"
							checkboxColumn={setMessageCustomers != null}
							setSelectedRows={setMessageCustomers}
							data={data}
							onRowClick={onRowClick}
							columns={[
								{
									Header: "Photo",
									accessor: "thumbnail",
									disableFilters: true,
									className: "vehicle-photo",
								},
								{
									id: "leadDetails",
									Header: "Lead",
									accessor: "leadDetails",
									style: { whiteSpace: "unset" },
									Cell: Cell,
								},

								{
									id: "targetDetails",
									Header: "Target Vehicle",
									accessor: "vehicleDetails",
									style: { whiteSpace: "unset" },
									className: "vehicle-details",

									Cell: targetVehicleCell,
								},
								{
									id: "vehicleDetails",
									Header: "From Inventory",
									accessor: "vehicleDetails",
									style: { whiteSpace: "unset" },
									className: "vehicle-details",

									Cell: vehicleCell,
								},

								{
									id: "dateGenerated",
									Header: "Generated",
									accessor: "dateGenerated",

									Cell: DateCell,
								},
								{
									id: "status",
									Header: "status",
									accessor: "status",
									Filter: ({ column }) => (
										<FilterColumn
											column={column}
											customOptions={OnlineLeadStatus.map((obj) => obj.label)}
										/>
									),
									filter: "includes",

									Cell: statusCell,
								},

								// {
								// 	Header: "LookingFor",
								// 	accessor: "lookingFor",
								// 	style: { whiteSpace: "unset" },
								// 	Cell: lookinCell,
								// 	className: "vehicle-details",
								// },
								{
									Header: "Actions",
									accessor: "Actions",
									disableFilters: true,
								},
							]}
						/>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default LeadTable;
