import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Row } from "reactstrap";
import { FaWindowMinimize, FaWindowClose } from "react-icons/fa";

const ChildrenModal = ({
	modal,
	toggle,
	modalTitle,
	bodyClass,
	children,
	onClosed,
	size = "xl",
	hideX = false,
}) => {
	const [isMinimized, setIsMinimized] = useState(false);

	const handleToggle = (e) => {
		if (e) {
			e.stopPropagation();
		}
		toggle();
	};

	const handleMinimize = (e) => {
		if (e) {
			e.stopPropagation();
		}
		setIsMinimized(!isMinimized);
	};

	return (
		<Modal
			toggle={handleToggle}
			isOpen={modal}
			size={size}
			backdrop="static"
			onClosed={onClosed}
			className={isMinimized ? "modal-minimized" : ""}
			onClick={(e) => e.stopPropagation()}
		>
			{!hideX && (
				<div className="modal-header justify-content-between">
					<Row>
						<h5 className="modal-title">{modalTitle}</h5>
						<div className="modal-header-buttons ml-auto">
							<button
								type="button"
								className="btn btn-link"
								aria-label="Minimize"
								onClick={handleMinimize}
							>
								<FaWindowMinimize size={16} />
							</button>
							<button
								type="button"
								className="btn btn-link"
								aria-label="Close"
								onClick={handleToggle}
							>
								<FaWindowClose size={16} />
							</button>
						</div>
					</Row>
				</div>
			)}
			{!isMinimized && <ModalBody className={bodyClass}>{children}</ModalBody>}
		</Modal>
	);
};

ChildrenModal.propTypes = {
	modal: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	modalTitle: PropTypes.string,
	bodyClass: PropTypes.string,
	children: PropTypes.node.isRequired,
	onClosed: PropTypes.func,
	size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
	hideX: PropTypes.bool,
};

export default ChildrenModal;
