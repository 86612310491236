import React, { useState, useEffect } from "react";
import { getFlooringRatio } from "api/InventoryAPI";
import {
	Card,
	Label,
	Input,
	CardBody,
	Row,
	Col,
	CardHeader,
	FormGroup,
} from "reactstrap";
import NumberFormat from "react-number-format";
import Loading from "components/loadingSpinner/Loading";

/**
 * Component to display flooring ratio information in a responsive layout.
 * @returns {JSX.Element} The FlooringRatioTable component.
 */
const FlooringRatioTable = () => {
	const [show, toggleShow] = useState(true);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	/**
	 * Currency display component.
	 * @param {Object} props - The component props.
	 * @param {number} props.value - The value to display.
	 * @param {string} [props.className] - Additional class names.
	 * @returns {JSX.Element} The CurrencyDisplay component.
	 */
	const CurrencyDisplay = ({ value, className = "" }) => (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			className={className}
			customInput={Input}
			disabled
		/>
	);

	/**
	 * Fetches flooring ratio data from the API.
	 */
	const getData = () => {
		setLoading(true); // Start loading
		getFlooringRatio(dealerID, locationID).then(
			(res) => {
				setData(res.data.content);
				setLoading(false); // Stop loading
				if (res.data.content.length === 0) toggleShow(false);
			},
			(err) => {
				setLoading(false); // Stop loading in case of error
				console.error("Failed to fetch flooring ratio data", err);
			}
		);
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const flexData = data.map((obj, index) => (
		<Row key={index} className="mb-3 flex-row">
			<Col xs="12" sm="6" md="4" lg="2">
				<FormGroup>
					<Label for={`flooringComp-${index}`}>Flooring Company</Label>
					<Input
						disabled
						type="text"
						name="flooringComp"
						id={`flooringComp-${index}`}
						value={obj.flooringComp}
					/>
				</FormGroup>
			</Col>
			<Col xs="12" sm="6" md="4" lg="2">
				<FormGroup>
					<Label for={`numOfVehicle-${index}`}># of Vehicles</Label>
					<Input
						disabled
						type="text"
						name="numOfVehicle"
						id={`numOfVehicle-${index}`}
						value={obj.numOfVehicle}
					/>
				</FormGroup>
			</Col>
			<Col xs="12" sm="6" md="4" lg="2">
				<FormGroup>
					<Label for={`lineOfCredit-${index}`}>Credit Line</Label>
					<CurrencyDisplay value={obj.lineOfCredit} />
				</FormGroup>
			</Col>
			<Col xs="12" sm="6" md="4" lg="2">
				<FormGroup>
					<Label for={`totalFlooringAmt-${index}`}>Total Floored</Label>
					<CurrencyDisplay value={obj.totalFlooringAmt} />
				</FormGroup>
			</Col>
			<Col xs="12" sm="6" md="4" lg="2">
				<FormGroup>
					<Label for={`availableCredit-${index}`}>Available Credit</Label>
					<CurrencyDisplay value={obj.lineOfCredit - obj.totalFlooringAmt} />
				</FormGroup>
			</Col>
		</Row>
	));

	return (
		<>
			{show && (
				<div className="px-2">
					<Row className="justify-content-center">
						<Col xs="12">
							<Card>
								<CardHeader>
									<h3>
										<span aria-label="fire emoji" role="img">
											🔥{" "}
										</span>{" "}
										Flooring Overview
									</h3>
								</CardHeader>
								<CardBody className="pb-0">
									{loading ? (
										<Loading containerStyle={{ height: "auto" }} />
									) : (
										flexData
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};

export default FlooringRatioTable;
