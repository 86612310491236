import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	Container,
	Input,
	Row,
} from "reactstrap";
import {
	ChatRole,
	createChatMessage,
	fetchChatCompletion,
} from "services/openAIService/OpenAiService";

const ChatInterface = () => {
	const [messages, setMessages] = useState([]);
	const [inputMessage, setInputMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const messagesEndRef = useRef(null);

	const examplePrompts = [
		{
			icon: "🚗",
			text: "Description for a 2019 Toyota Camry",
			color: "primary",
		},
		{
			icon: "💰",
			text: "Handle customer objection about price",
			color: "success",
		},
		{
			icon: "📧",
			text: "Follow-up email template for test drive",
			color: "info",
		},
	];

	const systemMessage = createChatMessage(
		ChatRole.SYSTEM,
		"You are DealerGPT, an AI assistant for dealership staff. You help with vehicle descriptions, handling customer objections, and creating follow-up communications. Provide concise, professional responses focused on automotive sales and customer service."
	);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]);

	const handleSend = async () => {
		if (!inputMessage.trim()) return;

		const userMessage = createChatMessage(ChatRole.USER, inputMessage);

		setMessages((prev) => [
			...prev,
			{
				...userMessage,
				timestamp: new Date().toISOString(),
			},
		]);

		setInputMessage("");
		setIsLoading(true);

		try {
			const chatMessages = [
				systemMessage,
				...messages.map(({ role, content }) => ({ role, content })),
				userMessage,
			];

			const response = await fetchChatCompletion(chatMessages, {
				temperature: 0.7,
				max_tokens: 1000,
			});

			setMessages((prev) => [
				...prev,
				{
					role: ChatRole.ASSISTANT,
					content: response.content,
					timestamp: new Date().toISOString(),
				},
			]);
		} catch (error) {
			setMessages((prev) => [
				...prev,
				{
					role: ChatRole.SYSTEM,
					content: `Error: ${error.message}. Please try again.`,
					timestamp: new Date().toISOString(),
				},
			]);
		} finally {
			setIsLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSend();
		}
	};

	const MessageComponent = ({ message }) => {
		const isUser = message.role === ChatRole.USER;

		const messageStyle = {
			backgroundColor: isUser ? "#0066cc" : "#f8f9fa",
			color: isUser ? "white" : "#212529",
			borderRadius: "15px",
			padding: "12px 18px",
			maxWidth: "80%",
			marginBottom: "20px",
			boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
			position: "relative",
		};

		const avatarStyle = {
			width: "28px",
			height: "28px",
			borderRadius: "50%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "16px",
			marginRight: isUser ? "0" : "12px",
			marginLeft: isUser ? "12px" : "0",
			backgroundColor: isUser ? "#DFE8F1" : "#e9ecef",
			color: isUser ? "#000000" : "#0066cc",
			flexShrink: 0,
		};

		const timestampStyle = {
			fontSize: "0.75rem",
			color: isUser ? "rgba(255,255,255,0.8)" : "#6c757d",
			marginTop: "4px",
		};

		const messageContainerStyle = {
			display: "flex",
			alignItems: "flex-start",
			justifyContent: isUser ? "flex-end" : "flex-start",
			marginBottom: "20px",
		};

		return (
			<div style={messageContainerStyle}>
				{!isUser && <div style={avatarStyle}>🤖</div>}
				<div style={messageStyle}>
					<div style={{ marginBottom: "6px", whiteSpace: "pre-wrap" }}>
						{message.content}
					</div>
					<div style={timestampStyle}>
						{new Date(message.timestamp).toLocaleTimeString()}
					</div>
				</div>
				{isUser && <div style={avatarStyle}>👤</div>}
			</div>
		);
	};

	const headerStyle = {
		background: "linear-gradient(135deg, #0066cc 0%, #0052a3 100%)",
		borderRadius: "8px 8px 0 0",
		padding: "1.5rem",
		marginBottom: 0,
	};

	const containerStyle = {
		maxWidth: "900px",
		margin: "2rem auto",
		height: "90vh",
	};

	const cardStyle = {
		height: "100%",
		border: "none",
		boxShadow: "0 4px 24px rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
	};

	const chatContentStyle = {
		flex: 1,
		overflowY: "auto",
		display: "flex",
		flexDirection: "column",
		padding: "1.5rem",
	};

	const inputWrapperStyle = {
		padding: "1rem",
		backgroundColor: "#f8f9fa",
		borderTop: "1px solid #dee2e6",
	};

	const inputStyle = {
		borderRadius: "8px",
		padding: "0.75rem",
		fontSize: "1rem",
		border: "1px solid #ced4da",
		transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
	};

	const sendButtonStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "0.75rem 1.5rem",
		fontSize: "1.2rem",
	};

	return (
		<Container fluid style={containerStyle}>
			<Card style={cardStyle}>
				<CardHeader style={headerStyle}>
					<CardTitle
						tag="h3"
						className="text-center text-white mb-2"
						style={{ fontWeight: "600" }}
					>
						DealerGPT
					</CardTitle>
					<p className="text-center text-white mb-0" style={{ opacity: "0.9" }}>
						Your AI Assistant for Dealership Operations
					</p>
				</CardHeader>

				<CardBody className="d-flex flex-column p-0">
					<div style={chatContentStyle}>
						{messages.length === 0 ? (
							<div className="text-center">
								<h4 style={{ color: "#495057", marginBottom: "2rem" }}>
									How can I assist you today?
								</h4>
								<Row>
									{examplePrompts.map((prompt, index) => (
										<Col key={index} md={4} className="mb-3">
											<Button
												color={prompt.color}
												onClick={() => setInputMessage(prompt.text)}
												className="w-100 p-4 d-flex flex-column align-items-center"
												style={{
													transition: "transform 0.2s ease",
													height: "100%",
												}}
												onMouseEnter={(e) =>
													(e.currentTarget.style.transform = "scale(1.02)")
												}
												onMouseLeave={(e) =>
													(e.currentTarget.style.transform = "scale(1)")
												}
											>
												<div className="mb-3" style={{ fontSize: "24px" }}>
													{prompt.icon}
												</div>
												<span style={{ fontSize: "0.9rem" }}>
													{prompt.text}
												</span>
											</Button>
										</Col>
									))}
								</Row>
							</div>
						) : (
							<>
								{messages.map((message, index) => (
									<MessageComponent key={index} message={message} />
								))}
								{isLoading && (
									<div className="d-flex justify-content-center my-4">
										<div className="typing-indicator">
											<span></span>
											<span></span>
											<span></span>
										</div>
									</div>
								)}
							</>
						)}
						<div ref={messagesEndRef} />
					</div>

					<div style={inputWrapperStyle}>
						<div className="d-flex" style={{ gap: "12px" }}>
							<Input
								type="text"
								value={inputMessage}
								onChange={(e) => setInputMessage(e.target.value)}
								onKeyPress={handleKeyPress}
								placeholder="Type your message here..."
								style={inputStyle}
							/>
							<Button
								color="primary"
								onClick={handleSend}
								disabled={isLoading || !inputMessage.trim()}
								style={sendButtonStyle}
							>
								➤
							</Button>
						</div>
						<small className="text-muted d-block text-center mt-2">
							Press Enter to send, Shift + Enter for new line
						</small>
					</div>
				</CardBody>
			</Card>

			<style>
				{`
          .typing-indicator {
            display: flex;
            gap: 4px;
          }
          
          .typing-indicator span {
            width: 8px;
            height: 8px;
            background-color: #0066cc;
            border-radius: 50%;
            animation: bounce 1.4s infinite ease-in-out;
          }
          
          .typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
          .typing-indicator span:nth-child(2) { animation-delay: -0.16s; }
          
          @keyframes bounce {
            0%, 80%, 100% { transform: translateY(0); }
            40% { transform: translateY(-6px); }
          }
          
          /* Custom scrollbar */
          ::-webkit-scrollbar {
            width: 8px;
          }
          
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb {
            background: #c1c1c1;
            border-radius: 4px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background: #a8a8a8;
          }

          .btn:focus {
            box-shadow: none;
          }
          
          .form-control:focus {
            box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.25);
          }
        `}
			</style>
		</Container>
	);
};

export default ChatInterface;
