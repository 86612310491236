import create from "zustand";
const useCurrentDealerLinkStore = create((set) => ({
	lotVantageAPIToken: "",
	googleBaseUser: "",
	googleBasePwd: "",
	fairfaxKey: "",
	fairfaxUser: "",
	carfaxUser: "",
	carfaxPwd: "",
	carfaxAllowPurchase: 0,
	autoCheckSID: "",
	cbccustomerID: "",
	cbcuserID: "",
	cbcpassword: "",

	//700 credit
	credit700Pwd: "",
	credit700User: "",
	credit700PreUser: "",
	credit700PrePwd: "",
	uacdealerID: "",
	pepperPayEmail: null,
	isCreditSignup: false,

	editLotVantageAPIToken: (val) => set(() => ({ lotVantageAPIToken: val })),
	editGoogleBaseUser: (val) => set(() => ({ googleBaseUser: val })),
	editGoogleBasePwd: (val) => set(() => ({ googleBasePwd: val })),
	editFairfaxKey: (val) => set(() => ({ fairfaxKey: val })),
	editFairfaxUser: (val) => set(() => ({ fairfaxUser: val })),
	editCarfaxUser: (val) => set(() => ({ carfaxUser: val })),
	editCarfaxPwd: (val) => set(() => ({ carfaxPwd: val })),
	editCarfaxAllowPurchase: (val) => set(() => ({ carfaxAllowPurchase: val })),
	editAutoCheckSID: (val) => set(() => ({ autoCheckSID: val })),
	editCbccustomerID: (val) => set(() => ({ cbccustomerID: val })),
	editCbcuserID: (val) => set(() => ({ cbcuserID: val })),
	editCbcpassword: (val) => set(() => ({ cbcpassword: val })),
	editCredit700Pwd: (val) => set(() => ({ credit700Pwd: val })),
	editCredit700User: (val) => set(() => ({ credit700User: val })),
	editCredit700PreUser: (val) => set(() => ({ credit700PreUser: val })),
	editCredit700PrePwd: (val) => set(() => ({ credit700PrePwd: val })),
	editUacdealerID: (val) => set(() => ({ uacdealerID: val })),
	editPepperPayEmail: (val) => set(() => ({ pepperPayEmail: val })),
	editIsCreditSignup: (val) => set(() => ({ isCreditSignup: val })),

	setDealerLinkFields: (res) => set(() => ({ ...res })),

	resetDealerLinkFields: () =>
		set({
			lotVantageAPIToken: "",
			googleBaseUser: "",
			googleBasePwd: "",
			fairfaxKey: "",
			fairfaxUser: "",
			carfaxUser: "",
			carfaxPwd: "",
			carfaxAllowPurchase: 0,
			autoCheckSID: "",
			cbccustomerID: "",
			cbcuserID: "",
			cbcpassword: "",
			credit700Pwd: "",
			credit700User: "",
			credit700PreUser: "",
			credit700PrePwd: "",
			uacdealerID: "",
			pepperPayEmail: null,
		}),
}));
export default useCurrentDealerLinkStore;
