import React from "react";

import useCurrentVehicleStore from "stores/InventoryStore";
import useCurrentPhotoStore from "stores/PhotoStore";

import { useFromUser } from "stores/LocalStorageHelper";
import { deleteImages } from "api/InventoryAPI";
import {
	getDeletedIndexes,
	createPhotosObj,
	getThumbnailURL,
} from "./helperFunctions";
import {
	failAlert,
	confirmDeleteMessage,
} from "../../../../../utils/alertUtils";
import { loading } from "../../../../../utils/alertUtils";
import Swal from "sweetalert2";

/**
 * Component that has the feature to let users delete a set of photos.
 * On delete button click, makes API call to FTP server to delete images
 *
 */
const DeletePhotos = () => {
	// User store
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Vehicle store
	const { vin, editThumbnail } = useCurrentVehicleStore();

	// Photo store
	const {
		deletes,
		editGallery,
		editDeletes,
		editReorders,
	} = useCurrentPhotoStore();

	const deleteArray = getDeletedIndexes(deletes);

	// Calls the delete images API to delete the selected images
	const deleteSelectedImages = (dealerID, locationID, vin) => {
		console.log(deletes);
		loading("Deleting...");
		if (dealerID != null && locationID != null && vin != null) {
			let deleteIndexes = getDeletedIndexes(deletes);
			console.log(deleteIndexes);
			if (deleteIndexes.length > 0) {
				deleteImages(dealerID, locationID, vin, deleteIndexes).then(
					(res) => {
						let imgArr = res.data.content;
						let imgObj = createPhotosObj(imgArr);

						editThumbnail(getThumbnailURL(imgArr));
						editDeletes({});
						editGallery(imgObj);
						editReorders(imgObj);
						Swal.close();
					},
					(error) => {
						console.log(error);
						Swal.close();
						failAlert(`Failed to delete images for ${vin}.`);
					}
				);
			} else {
				failAlert(`No images selected to delete for ${vin}.`);
			}
		} else {
			failAlert(`Could not find any images to delete for ${vin}.`);
		}
	};

	const handleDelete = () => {
		let answer = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			answer = res;
			if (answer === "Yes, Delete it") {
				deleteSelectedImages(dealerID, locationID, vin);
			}
		});
	};

	return (
		<div
			className="btn btn-md btn-danger"
			color="danger"
			onClick={() => handleDelete()}
			disabled={deleteArray.length === 0}
		>
			<i className="nc-icon nc-simple-remove"></i> Delete Photos
		</div>
	);
};

export default DeletePhotos;
