import create from "zustand";

const useCurrentRecapStore = create((set) => ({
	ID: null,
	isRecapped: false,
	recapDate: "",
	carProfit: 0,
	reconProfit: 0,
	aftmktProfit: 0,
	totalTradeAllowance: 0,
	totalTradeACV: 0,
	totalTradeProfit: 0,
	totalTradePayoff: 0,
	totalDown: 0,
	vsiCost: 0,
	miscCost: 0,
	miscProfit: 0,
	gapInsCost: 0,
	gapInsProfit: 0,
	warrantyCost: 0,
	warrantyProfit: 0,
	//percent
	interestCost: 0,
	interestProfit: 0,
	interestNetProfit: 0,
	additionalProfit: 0,
	// 100% is default in 1.0
	dlrParticipation: 1,
	reserve: 0,
	frontendProfit: 0,
	backendProfit: 0,
	lenderFee: 0,
	incentive: 0,
	finderReward: 0,
	fiComm: 0,
	salesComm: 0,
	totalComm: 0,
	profitComm: 0,
	commGross: 0,
	grossProfit: 0,
	netProfit: 0,
	isLoading: true,
	fiGross: 0,
	salesGross: 0,
	totalGross: 0,
	collisionTotCost: 0,
	collisionTotProfit: 0,
	creditLifeCost: 0,
	disabCost: 0,
	credLifeProfit: 0,
	disabProfit: 0,

	// not actually used in DcTableRecap, state lives here so it can be global
	recon: 0,
	official: 0,
	additional: 0,
	gpBeforeFees: 0,
	gpBeforeReserve: 0,
	gpBeforeComm: 0,
	receivable: 0,
	reservePercent: 0,
	lenderPaid: "",
	netCheckToDealer: 0,
	totalLenderFee: 0,

	editID: (val) => set(() => ({ ID: val })),
	editIsRecapped: (val) => set(() => ({ isRecapped: val })),
	editRecapDate: (val) => set(() => ({ recapDate: val })),
	editCarProfit: (val) => set(() => ({ carProfit: val })),
	editReconProfit: (val) => set(() => ({ reconProfit: val })),
	editAftmktProfit: (val) => set(() => ({ aftmktProfit: val })),
	editTotalTradeAllowance: (val) => set(() => ({ totalTradeAllowance: val })),
	editTotalTradeACV: (val) => set(() => ({ totalTradeACV: val })),
	editTotalTradeProfit: (val) => set(() => ({ totalTradeProfit: val })),
	editTotalTradePayoff: (val) => set(() => ({ totalTradePayoff: val })),
	editTotalDown: (val) => set(() => ({ totalDown: val })),
	editVsiCost: (val) => set(() => ({ vsiCost: val })),
	editMiscCost: (val) => set(() => ({ miscCost: val })),
	editMiscProfit: (val) => set(() => ({ miscProfit: val })),
	editGapInsCost: (val) => set(() => ({ gapInsCost: val })),
	editGapInsProfit: (val) => set(() => ({ gapInsProfit: val })),
	editWarrantyCost: (val) => set(() => ({ warrantyCost: val })),
	editWarrantyProfit: (val) => set(() => ({ warrantyProfit: val })),
	editInterestCost: (val) => set(() => ({ interestCost: val })),
	editInterestProfit: (val) => set(() => ({ interestProfit: val })),
	editInterestNetProfit: (val) => set(() => ({ interestNetProfit: val })),
	editAdditionalProfit: (val) => set(() => ({ additionalProfit: val })),
	editDlrParticipation: (val) => set(() => ({ dlrParticipation: val })),
	editReserve: (val) => set(() => ({ reserve: val })),
	editFrontendProfit: (val) => set(() => ({ frontendProfit: val })),
	editBackendProfit: (val) => set(() => ({ backendProfit: val })),
	editLenderFee: (val) => set(() => ({ lenderFee: val })),
	editIncentive: (val) => set(() => ({ incentive: val })),
	editFinderReward: (val) => set(() => ({ finderReward: val })),
	editFiComm: (val) => set(() => ({ fiComm: val })),
	editSalesComm: (val) => set(() => ({ salesComm: val })),
	editTotalComm: (val) => set(() => ({ totalComm: val })),
	editProfitComm: (val) => set(() => ({ profitComm: val })),
	editCommGross: (val) => set(() => ({ commGross: val })),
	editGrossProfit: (val) => set(() => ({ grossProfit: val })),
	editNetProfit: (val) => set(() => ({ netProfit: val })),
	editIsLoading: (val) => set(() => ({ isLoading: val })),
	editFiGross: (val) => set(() => ({ fiGross: val })),
	editSalesGross: (val) => set(() => ({ salesGross: val })),
	editTotalGross: (val) => set(() => ({ totalGross: val })),
	editCollisionTotCost: (val) => set(() => ({ collisionTotCost: val })),
	editCollisionTotProfit: (val) => set(() => ({ collisionTotProfit: val })),

	editCreditLifeCost: (val) => set(() => ({ creditLifeCost: val })),
	editDisabCost: (val) => set(() => ({ disabCost: val })),
	editCredLifeProfit: (val) => set(() => ({ credLifeProfit: val })),
	editDisabProfit: (val) => set(() => ({ disabProfit: val })),

	editRecon: (val) => set(() => ({ recon: val })),
	editOfficial: (val) => set(() => ({ official: val })),
	editAdditional: (val) => set(() => ({ additional: val })),
	editGpBeforeFees: (val) => set(() => ({ gpBeforeFees: val })),
	editGpBeforeReserve: (val) => set(() => ({ gpBeforeReserve: val })),
	editGpBeforeComm: (val) => set(() => ({ gpBeforeComm: val })),
	editReceivable: (val) => set(() => ({ receivable: val })),
	editReservePercent: (val) => set(() => ({ reservePercent: val })),
	editLenderPaid: (val) => set(() => ({ lenderPaid: val })),
	editNetCheckToDealer: (val) => set(() => ({ netCheckToDealer: val })),
	editTotalLenderFee: (val) => set(() => ({ totalLenderFee: val })),

	setRecapFields: (res) =>
		set((state) => ({
			...res,
		})),

	reset: () =>
		set({
			ID: null,
			isRecapped: false,
			recapDate: "",
			carProfit: 0,
			reconProfit: 0,
			aftmktProfit: 0,
			totalTradeAllowance: 0,
			totalTradeACV: 0,
			totalTradeProfit: 0,
			totalTradePayoff: 0,
			totalDown: 0,
			vsiCost: 0,
			miscCost: 0,
			miscProfit: 0,
			gapInsCost: 0,
			gapInsProfit: 0,
			warrantyCost: 0,
			warrantyProfit: 0,
			interestCost: 0,
			interestProfit: 0,
			interestNetProfit: 0,
			additionalProfit: 0,
			dlrParticipation: 0.0,
			reserve: 0,
			frontendProfit: 0,
			backendProfit: 0,
			lenderFee: 0,
			incentive: 0,
			finderReward: 0,
			fiComm: 0,
			salesComm: 0,
			totalComm: 0,
			profitComm: 0,
			isLoading: true,
			commGross: 0,
			grossProfit: 0,
			netProfit: 0,
			fiGross: 0,
			salesGross: 0,
			totalGross: 0,
			collisionTotCost: 0,
			collisionTotProfit: 0,
			creditLifeCost: 0,
			disabCost: 0,
			credLifeProfit: 0,
			disabProfit: 0,

			recon: 0,
			official: 0,
			additional: 0,
			gpBeforeFees: 0,
			gpBeforeReserve: 0,
			gpBeforeComm: 0,
			receivable: 0,
			reservePercent: 0,
			lenderPaid: "",
			netCheckToDealer: 0,
			totalLenderFee: 0,
		}),
}));

export default useCurrentRecapStore;
