import React, { useState } from "react";

import useCurrentCustomerStore from "../../../../../stores/CustomerStore";
import BusinessDetails from "./BusinessDetails";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import CurrentAddress from "../addressTab/CurrentAddress";
import CustomerDealList from "../../contactListPage/CustomerDealList";
import CustomerLandlordForm from "./CustomerLandLordForm";
import DeleteCustomerButton from "../../../helperFunctions/DeleteCustomerButton";
import UpdateCustomerButton from "../../../helperFunctions/UpdateCustomerButton";
import ZipCityTable from "../../../../../components/tables/ZipCityTable";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";

import shallow from "zustand/shallow";

const BusinessDetailsTab = () => {
	// Local states
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [customerListModal, setCustomerListModal] = useState(false);

	// Opens or closes the deal list modal
	const toggleCustomerListModal = () =>
		setCustomerListModal(!customerListModal);

	// Customer store
	const {
		currentAddress,
		editCurrentAddress,
		originalID,
	} = useCurrentCustomerStore(
		(state) => ({
			currentAddress: state.currentAddress,
			editCurrentAddress: state.editCurrentAddress,
			originalID: state.originalID,
		}),
		shallow
	);

	// Opens and closes the current zip city modal
	const toggleCityModal = () => setCityModal(!cityModal);

	// Show modal containing cities with the 5 digit zip entered for current address
	const handleZipChange = (zip, address) => {
		setAddressData({ zip, address });
		toggleCityModal();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Define rules for each input field
	// If phone validation needed: regex -> ^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}|^\+1\s\(\s{3}\)\s_{3}-_{4}
	const schema = yup.object().shape({
		contact: yup.string().max(50),
		lastName: yup.string().required().max(100),
		currentStreet: yup.string().max(50),
		currentCity: yup.string().max(50),
		mailStreet: yup.string().max(50),
		mailCity: yup.string().max(50),
		ssn: yup.string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
			message: "Must be 9 digits or empty",
			excludeEmptyString: true,
		}),
		email: yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
			message: "Invalid Email",
			excludeEmptyString: true,
		}),
		cellPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		extraPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		workPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	return (
		<>
			{/* <div className="col pb-3">
				<div className="row mx-0">
					<div className="col-md-6">
						<Row className="px-0 mx-0">
							<CustomerStartDealButton />

							<div
								className="btn btn-primary btn-md mb-0"
								onClick={toggleCustomerListModal}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								View<span className="noMobile"> Deals</span>
							</div>
						</Row>
					</div>
					<div className="col-md-6 text-right">
						<CustomerSaveNoteButton />
					</div>
				</div>
			</div> */}
			<FormProvider {...methods}>
				<BusinessDetails />
				<div id="currentAddress ">
					<CurrentAddress getCities={handleZipChange} />
					<ChildrenModal
						modal={cityModal}
						toggle={toggleCityModal}
						modalTitle="Cities"
					>
						<ZipCityTable
							data={addressData}
							toggle={toggleCityModal}
							address={currentAddress}
							setAddress={editCurrentAddress}
						/>
					</ChildrenModal>
				</div>
				<CustomerLandlordForm />
				<UpdateCustomerButton />
			</FormProvider>
			<DeleteCustomerButton redirect={true} />
			<ChildrenModal
				modal={customerListModal}
				toggle={toggleCustomerListModal}
				modalTitle="Customer Deals"
				children={
					<CustomerDealList
						toggle={toggleCustomerListModal}
						originalID={originalID}
					/>
				}
			/>
		</>
	);
};

export default BusinessDetailsTab;
