import React, { useState } from "react";
import { Form } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import ZipCityTable from "../../../../../../components/tables/ZipCityTable";

import { stateOptions } from "../../../../../../constants/Constants";

import { useFormContext } from "react-hook-form";
import InputElement, {
	PhoneInputElement,
	SelectInput,
	ZipInputElement,
	CheckBoxElement,
	CurrencyInputElement,
	IntegerInputElement,
	PercentInputPepperPay,
} from "../../../../../forms/components/InputElement";

const Questionnaire = ({
	questionnaire,
	addressProductHeld,
	fulfillmentHouseProvide,
	percentageSalesFrom,
	termsAndConditions,
	setQuestionnaire,
	setAddressProductHeld,
	setFulfillmentHouseProvide,
	setPercentageSalesFrom,
	setTermsAndConditions,
	setCurrentForm,
	nextForm,
	previousForm,
	disabled,
}) => {
	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}

		setCurrentForm(nextForm);
	};

	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const toggleCityModal = () => setCityModal(!cityModal);
	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: addressProductHeld.phCity,
				state: addressProductHeld.phState,
			},
		});
		toggleCityModal();
	};
	const setAddressFields = (address) => {
		setAddressProductHeld.setPhCity(address.city);
		setAddressProductHeld.setPhState(address.state);
		setAddressProductHeld.setPhZipCode(address.zip);
	};

	const methods = useFormContext();

	return (
		<div>
			<Form className="row" onKeyDown={formNavigationUtils}>
				<h3 className="col-12 section-title mb-3">Questionnaire</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.advertise}
					onChange={(e) => setQuestionnaire?.setAdvertise(e.target.value)}
					name="questionnaire.advertise"
					label="Marketing Strategies"
					type="text"
					labelClass="required"
				/>
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={questionnaire.businessPercentageSell}
					onChange={(e) => setQuestionnaire?.setBusinessPercentageSell(e)}
					name="questionnaire.businessPercentageSell"
					label="Percentage of Sales B2B"
					labelClass="required"
				/>
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={questionnaire.publicPercentageSell}
					onChange={(e) => setQuestionnaire?.setPublicPercentageSell(e)}
					name="questionnaire.publicPercentageSell"
					label="Percentage of Sales B2C"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.currentCardBrandProcessor}
					onChange={(e) =>
						setQuestionnaire?.setCurrentCardBrandProcessor(e.target.value)
					}
					name="questionnaire.currentCardBrandProcessor"
					label="Current Card Brand Processor"
					type="text"
					labelClass="required"
				/>
				<SelectInput
					{...methods}
					disabled={disabled}
					name="questionnaire.customerCharged"
					label="When is customer charged?"
					value={questionnaire.customerCharged}
					onChange={(e) => setQuestionnaire?.setCustomerCharged(e.value)}
					options={[
						{ value: "Time of order", label: "Time of order" },
						{ value: "Upon shipment", label: "Upon shipment" },
					]}
					labelClass="required"
				/>
				{/* <CurrencyInputElement
					{...methods}
					disabled={disabled}
					value={questionnaire.customerCharged}
					name="customerCharged"
					label="Customer Charged"
					type="number"
					onChange={(e) => setQuestionnaire?.setCustomerCharged(e.target.value)}
					labelClass="required"
				/> */}
				<SelectInput
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.deliverMerchandiseCustomer}
					onChange={(e) =>
						setQuestionnaire?.setDeliverMerchandiseCustomer(e.value)
					}
					options={[
						{ value: "1-7 ", label: "1-7 " },
						{ value: "8-14", label: "8-14" },
						{ value: "14+", label: "14+" },
					]}
					name="questionnaire.deliverMerchandiseCustomer"
					label="Delivery Time of Merchandise"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.describeProductService}
					onChange={(e) =>
						setQuestionnaire?.setDescribeProductService(e.target.value)
					}
					name="questionnaire.describeProductService"
					label="Describe Product/Service"
					type="text"
					labelClass="required"
				/>
				<IntegerInputElement
					{...methods}
					disabled={disabled}
					value={questionnaire.manyChargebacksPreviousYear}
					onChange={(e) => setQuestionnaire?.setManyChargebacksPreviousYear(e)}
					name="questionnaire.manyChargebacksPreviousYear"
					label="How many chargebacks did you have for the previous year?"
					type="number"
					labelClass="required"
					colSize="col-sm-3"
				/>
				<CurrencyInputElement
					{...methods}
					disabled={disabled}
					value={questionnaire.dollarAmountChargebacks}
					name="questionnaire.dollarAmountChargebacks"
					label="Total Dollar Amount for those Chargebacks"
					type="number"
					onChange={(e) => setQuestionnaire?.setDollarAmountChargebacks(e)}
					labelClass="required"
				/>
				<h5 className="col-12 mb-3">Inventory</h5>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={questionnaire.productStoreAboveAddress}
					onChange={() =>
						setQuestionnaire?.setProductStoreAboveAddress(
							!questionnaire.productStoreAboveAddress
						)
					}
					name="questionnaire.productStoreAboveAddress"
					label="Product stored at Trade or Legal Address"
					labelClass="pl-4"
				/>
				<CheckBoxElement
					{...methods}
					readOnly={disabled || questionnaire.productStoreAboveAddress}
					checked={questionnaire.ownProductInventory}
					onChange={() =>
						setQuestionnaire?.setOwnProductInventory(
							!questionnaire.ownProductInventory
						)
					}
					name="questionnaire.ownProductInventory"
					label="Inventory is Owned"
					labelClass="pl-4 required"
				/>
				<InputElement
					{...methods}
					readOnly={disabled || questionnaire.productStoreAboveAddress}
					colSize="col-lg-4"
					value={addressProductHeld.phStreet}
					onChange={(e) => setAddressProductHeld?.setPhStreet(e.target.value)}
					name="questionnaire.addressProductHeld.street"
					label="Inventory Street Address"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					readOnly={disabled || questionnaire.productStoreAboveAddress}
					colSize="col-lg-3"
					value={addressProductHeld.phCity}
					onChange={(e) => setAddressProductHeld?.setPhCity(e.target.value)}
					name="questionnaire.addressProductHeld.city"
					label="City"
					type="text"
				/>

				<ZipInputElement
					{...methods}
					readOnly={disabled || questionnaire.productStoreAboveAddress}
					value={addressProductHeld.phZipCode}
					onChange={(val) => setAddressProductHeld?.setPhZipCode(val)}
					openZipModal={handleZipChange}
					name="questionnaire.addressProductHeld.zipCode"
					label="Zip"
					labelClass="required"
				/>
				<SelectInput
					{...methods}
					disabled={disabled}
					name="questionnaire.addressProductHeld.state"
					label="State"
					value={addressProductHeld.phState}
					onChange={(e) => setAddressProductHeld?.setPhState(e.value)}
					options={stateOptions}
					labelClass="required"
				/>
				<h5 className="col-12 mb-3"> </h5>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={questionnaire.fulfillmentHouse}
					onChange={() =>
						setQuestionnaire?.setFulfillmentHouse(
							!questionnaire.fulfillmentHouse
						)
					}
					name="questionnaire.fulfillmentHouse"
					label="Another company is involved in the fulfilling and shipping of merchandise"
					labelClass="pl-4 required"
				/>
				<InputElement
					{...methods}
					readOnly={disabled || !questionnaire.fulfillmentHouse}
					colSize="col-lg-4"
					value={fulfillmentHouseProvide.fulfillCompanyName}
					onChange={(e) =>
						setFulfillmentHouseProvide?.setFulfillCompanyName(e.target.value)
					}
					name="questionnaire.fulfillmentHouseProvide.companyName"
					label="Company Name"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					readOnly={disabled || !questionnaire.fulfillmentHouse}
					colSize="col-lg-4"
					value={fulfillmentHouseProvide.fulfillAddress}
					onChange={(e) =>
						setFulfillmentHouseProvide?.setFulfillAddress(e.target.value)
					}
					name="questionnaire.fulfillmentHouseProvide.address"
					label="Fulfillment Company Address"
					type="text"
					labelClass="required"
				/>
				<PhoneInputElement
					{...methods}
					readOnly={disabled || !questionnaire.fulfillmentHouse}
					colSize="col-lg-3"
					value={fulfillmentHouseProvide.fulfillTelephone}
					//					onChange={setFulfillmentHouseProvide.}
					onChange={(e) => setFulfillmentHouseProvide?.setFulfillTelephone(e)}
					name="questionnaire.fulfillmentHouseProvide.telephone"
					label="Fulfill Telephone"
					labelClass="required"
				/>
				{/* <h5 className="col-12 mb-3">Percentage Sales From</h5> */}
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={percentageSalesFrom.cardPresent}
					//					onChange={}
					onChange={(e) => setPercentageSalesFrom?.setCardPresent(e)}
					name="questionnaire.percentageSalesFrom.cardPresent"
					label="Card Present"
					labelClass="required"
				/>
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={percentageSalesFrom.internet}
					//					onChange={setPercentageSalesFrom?.setInternet}
					onChange={(e) => setPercentageSalesFrom?.setInternet(e)}
					name="questionnaire.percentageSalesFrom.internet"
					label="Internet"
					labelClass="required"
				/>
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={percentageSalesFrom.mail}
					//					onChange={setPercentageSalesFrom?.setMail}
					onChange={(e) => setPercentageSalesFrom?.setMail(e)}
					name="questionnaire.percentageSalesFrom.mail"
					label="Mail"
					labelClass="required"
				/>
				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={percentageSalesFrom.telephone}
					//					onChange={setPercentageSalesFrom?.setTelephone}
					onChange={(e) => setPercentageSalesFrom?.setTelephone(e)}
					name="questionnaire.percentageSalesFrom.telephone"
					label="Telephone"
					labelClass="required"
				/>
				{/* <PercentInputElement
					{...methods}
					disabled={disabled}
					value={questionnaire.retailLocation}
					//onChange={setQuestionnaire?.setRetailLocation}
					onChange={(e) => setQuestionnaire?.setRetailLocation(e)}
					name="retailLocation"
					label="RetailLocation"
					labelClass="required"
				/> */}
				<SelectInput
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.sell}
					onChange={(e) => setQuestionnaire?.setSell(e.value)}
					options={[
						{ value: "Nationally", label: "Nationally" },
						{ value: "Locally", label: "Locally" },
					]}
					name="questionnaire.sell"
					label="Target Market"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.sellProductService}
					onChange={(e) =>
						setQuestionnaire?.setSellProductService(e.target.value)
					}
					name="questionnaire.sellProductService"
					label="Do you sell a service or product?"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.webSite}
					onChange={(e) => setQuestionnaire?.setWebSite(e.target.value)}
					name="questionnaire.webSite"
					label="Website"
					type="text"
					labelClass="required required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={questionnaire.serverLocation}
					onChange={(e) => setQuestionnaire?.setServerLocation(e.target.value)}
					name="questionnaire.serverLocation"
					label="Server Location"
					type="text"
				/>
				<h5 className="col-12 mb-3">Terms And Conditions</h5>
				<h5 className="col-12 mb-3">
					Please choose the options that apply and provide refund and shipping
					policy details.
				</h5>

				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.corporationNameMatchApplicationName}
					onChange={() =>
						setTermsAndConditions?.setCorporationNameMatchApplicationName(
							!termsAndConditions.corporationNameMatchApplicationName
						)
					}
					name="questionnaire.termsAndConditions.corporationNameMatchApplicationName"
					label="Corporation Name Match Application Name"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.currencyDollar}
					onChange={() =>
						setTermsAndConditions?.setCurrencyDollar(
							!termsAndConditions.currencyDollar
						)
					}
					name="questionnaire.termsAndConditions.currencyDollar"
					label="USD Currency"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.dataShare}
					onChange={() =>
						setTermsAndConditions?.setDataShare(!termsAndConditions.dataShare)
					}
					name="questionnaire.termsAndConditions.dataShare"
					label="Data Share"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.membershipAnyType}
					onChange={() =>
						setTermsAndConditions?.setMembershipAnyType(
							!termsAndConditions.membershipAnyType
						)
					}
					name="questionnaire.termsAndConditions.membershipAnyType"
					label="Membership Any Type"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.negativeOptionBilling}
					onChange={() =>
						setTermsAndConditions?.setNegativeOptionBilling(
							!termsAndConditions.negativeOptionBilling
						)
					}
					name="questionnaire.termsAndConditions.negativeOptionBilling"
					label="Negative Option Billing"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.privacyPolicy}
					onChange={() =>
						setTermsAndConditions?.setPrivacyPolicy(
							!termsAndConditions.privacyPolicy
						)
					}
					name="questionnaire.termsAndConditions.privacyPolicy"
					label="Privacy Policy"
					labelClass="pl-4 required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.purchasePage}
					onChange={() =>
						setTermsAndConditions?.setPurchasePage(
							!termsAndConditions.purchasePage
						)
					}
					name="questionnaire.termsAndConditions.purchasePage"
					label="Purchase page lists individual charges"
					labelClass="pl-4 required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={termsAndConditions.refundPolicy}
					onChange={(e) =>
						setTermsAndConditions?.setRefundPolicy(e.target.value)
					}
					name="questionnaire.termsAndConditions.refundPolicy"
					label="Refund Policy"
					type="text"
					labelClass="required"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={termsAndConditions.secureProcessing}
					onChange={() =>
						setTermsAndConditions?.setSecureProcessing(
							!termsAndConditions.secureProcessing
						)
					}
					name="questionnaire.termsAndConditions.secureProcessing"
					label="Secure Processing"
					labelClass="pl-4 required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={termsAndConditions.shippingPolicy}
					onChange={(e) =>
						setTermsAndConditions?.setShippingPolicy(e.target.value)
					}
					name="questionnaire.termsAndConditions.shippingPolicy"
					label="Shipping Policy"
					type="text"
					labelClass="required"
				/>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							state: addressProductHeld.phState,
							zip: addressProductHeld.phZipCode,
							city: addressProductHeld.phCity,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</Form>
			{!disabled && (
				<div className="col-12">
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button className="next-with-previous" onClick={handleNext}>
						Next
					</button>
				</div>
			)}
		</div>
	);
};
export default Questionnaire;
