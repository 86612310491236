import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import shallow from "zustand/shallow";

import useCurrentAccountStore from "./AccountStore";

//import NumberFormat from "react-number-format";
import Camera from "assets/img/placeholder.png";
import { useFromUser } from "stores/LocalStorageHelper";
import { FTP_IMAGE_URL } from "constants/Constants";
import InventoryListModal from "../features/deal/subFeatures/inventory/InventoryListModal";
import OpenModalButton from "../features/deal/components/OpenModalButton";

const AccountInventoryHeader = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const {
		account,
		// stockNum,
		// vin,
		// year,
		// make,
		// model,
		// subModel,
		// price,
		// newUsed,
		// licensePlate,
		// odometer,
		// mileageOut,
		// color,
		// trimColor,
		// type,
		// title,
		// tagExpires,
		// tag,
		// picPath,

		// hasGPS,
		// gps_ID,
		// gpsType,

		// hasGPS2,
		// gps_ID2,
		// gpsType2,
	} = useCurrentAccountStore(
		(state) => state,
		//{
		// stockNum: state.account.stockNum,
		// vin: state.account.vin,
		// year: state.account.year,
		// make: state.account.make,
		// model: state.account.model,
		// subModel: state.account.subModel,
		// price: state.account.price,
		// newUsed: state.account.newUsed,
		// licensePlate: state.account.licensePlate,
		// odometer: state.account.odometer,
		// mileageOut: state.account.mileageOut,
		// color: state.account.color,
		// trimColor: state.account.trimColor,
		// type: state.account.type,
		// title: state.account.title,
		// tagExpires: state.account.tagExpires,
		// tag: state.account.tag,
		// picPath: state.account.picPath,

		// hasGPS: state.account.hasGPS,
		// gps_ID: state.account.gps_ID,
		// gpsType: state.account.gpsType,

		// hasGPS2: state.account.hasGPS2,
		// gps_ID2: state.account.gps_ID2,
		// gpsType2: state.account.gpsType2,
		//}
		shallow
	);
	const getThumbnail = () => {
		if (account.vin !== "") {
			return (
				FTP_IMAGE_URL +
				"/" +
				dealerID +
				"/" +
				locationID +
				"/" +
				account.vin +
				"-0.jpg"
			);
		} else {
			return "";
		}
	};

	return (
		<div>
			<div className="deal-vehicle">
				<div className="mb-2 text-center">
					<h3 className="mb-3">
						{account.year + " " + account.make + " " + account.model}
					</h3>
					<img
						className="dealImg mb-2 zoom"
						src={`${getThumbnail()}?lastmod=${Date.now()}`}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						alt="Car"
					/>
					<Row className="text-center">
						<Col sm="12" md="6" lg="6" xl="6">
							<Label>
								Stock
								<Input disabled value={account.stockNum} />
							</Label>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<Label>
								VIN
								<Input disabled value={account.vin} />
							</Label>
						</Col>
					</Row>
				</div>
				<Col md="12" className="text-center mt-half">
					<div className="mx-auto">
						<OpenModalButton
							iconClass="nc-icon nc-align-center"
							buttonClass="btn-sm btn-primary"
							//							onClick={toggleEdit}
							buttonText="View"
						/>
					</div>
				</Col>
			</div>
		</div>
	);
};

export default AccountInventoryHeader;
