import React, { useState } from "react";
import { Form } from "reactstrap";

import ChildrenModal from "components/modals/ChildrenModal";
import ZipCityTable from "components/tables/ZipCityTable";
import useCurrentCustomerStore from "stores/CustomerStore";
import formNavigationUtils from "utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";

import { stateOptions } from "constants/Constants";

import InputElement, {
	CurrencyInputElement,
	PhoneInputElement,
	SelectorElement,
	ZipInputElement,
} from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";
import { CheckBoxElement } from "../../../../forms/components/InputElement";

const CustomerInsurance = () => {
	// Customer store
	const { insurance, editInsuranceValue } = useCurrentCustomerStore(
		(state) => state,
		shallow
	);

	// Local states
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipBlur = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: insurance.city,
				state: insurance.state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		editInsuranceValue("city", address.city);
		editInsuranceValue("state", address.state);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<>
			<Form onKeyDown={formNavigationUtils}>
				<InputWrapper
					formTitle="Insurance & Agent"
					inputComponents={[
						<InputElement
							{...methods}
							value={insurance.insCompany}
							onChange={(e) => {
								editInsuranceValue("insCompany", e.target.value);
							}}
							name="insuranceCompany"
							label="Company"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={insurance.policyNum}
							onChange={(e) => {
								editInsuranceValue("policyNum", e.target.value);
							}}
							name="insurancePolicyNum"
							label="Policy Number"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={insurance.fromDate}
							onChange={(e) => editInsuranceValue("fromDate", e.target.value)}
							name="insuranceStartDate"
							label="Start Date"
							type="date"
						/>,
						<InputElement
							{...methods}
							value={insurance.toDate}
							onChange={(e) => editInsuranceValue("toDate", e.target.value)}
							name="insuranceEndDate"
							label="End Date"
							type="date"
						/>,
						<InputElement
							{...methods}
							value={insurance.agentName}
							onChange={(e) => editInsuranceValue("agentName", e.target.value)}
							name="agentName"
							label="Agent Name"
							type="text"
							maxLength={30}
						/>,
						<PhoneInputElement
							{...methods}
							value={insurance.pager}
							onChange={(val) => editInsuranceValue("pager", val)}
							name="insuranceAgentCellNum"
							label="Agent Cell Number"
						/>,
						<PhoneInputElement
							{...methods}
							value={insurance.phone1}
							onChange={(val) => editInsuranceValue("phone1", val)}
							name="agentAgentPhoneNum1"
							label="Agent Phone Number1"
						/>,
						<InputElement
							{...methods}
							value={insurance.ext1}
							onChange={(e) => editInsuranceValue("ext1", e.target.value)}
							name="agentPhoneExt1"
							label="Extension"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={insurance.phone2}
							onChange={(val) => editInsuranceValue("phone2", val)}
							name="agentAgentPhoneNum2"
							label="Agent Phone Number2"
						/>,
						<InputElement
							{...methods}
							value={insurance.ext2}
							onChange={(e) => editInsuranceValue("ext2", e.target.value)}
							name="agentPhoneExt2"
							label="Extension"
							type="text"
						/>,
						<PhoneInputElement
							{...methods}
							value={insurance.fax}
							onChange={(val) => editInsuranceValue("fax", val)}
							name="agentFaxNum"
							label="Agent Fax "
						/>,
						<InputElement
							{...methods}
							value={insurance.street}
							onChange={(e) => editInsuranceValue("street", e.target.value)}
							name="insuranceAgentAddress"
							label="Agent Address"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={insurance.city}
							onChange={(e) => editInsuranceValue("city", e.target.value)}
							name="insuranceAgentCity"
							label="Agent City"
							type="text"
						/>,
						<SelectorElement
							{...methods}
							name="insuranceState"
							label="State"
							value={insurance.state}
							onChange={(e) => editInsuranceValue("state", e.target.value)}
							options={stateOptions}
						/>,
						<ZipInputElement
							{...methods}
							value={insurance.zip}
							onChange={(val) => editInsuranceValue("zip", val)}
							openZipModal={handleZipBlur}
							name="insuranceAgentZip"
							label="Agent Zip Code"
						/>,
					]}
				/>
				<br />
				<InputWrapper
					formTitle="Coverage"
					inputComponents={[
						<CheckBoxElement
							{...methods}
							checked={insurance.fire}
							onChange={() => editInsuranceValue("fire", !insurance.fire)}
							name="fire"
							label="Fire"
							labelClass="pl-4 required"
						/>,
						<CheckBoxElement
							{...methods}
							checked={insurance.theft}
							onChange={() => editInsuranceValue("theft", !insurance.theft)}
							name="theft"
							label="Theft"
							labelClass="pl-4 required"
						/>,
						<CheckBoxElement
							{...methods}
							checked={insurance.comprehensive}
							onChange={() =>
								editInsuranceValue("comprehensive", !insurance.comprehensive)
							}
							name="comprehensive"
							label="Comprehensive"
							labelClass="pl-4 required"
						/>,
						<CurrencyInputElement
							value={insurance.compDeductible}
							onChange={(val) => {
								editInsuranceValue("compDeductible", val);
							}}
							name="compDeductible"
							label="Deductible Comprehensive"
							type="text"
						/>,
						<CheckBoxElement
							{...methods}
							checked={insurance.collision}
							onChange={() =>
								editInsuranceValue("collision", !insurance.collision)
							}
							name="collision"
							label="Collision"
							labelClass="pl-4 required"
						/>,
						<CurrencyInputElement
							value={insurance.deductible}
							onChange={(val) => {
								editInsuranceValue("deductible", val);
							}}
							name="collisionDeductible"
							label="Deductible Collision"
							type="text"
						/>,
					]}
				/>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: insurance.city,
							zip: insurance.zip,
							state: insurance.state,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</Form>
		</>
	);
};

export default CustomerInsurance;
