import React, { useEffect } from "react";
import { Button, Form } from "reactstrap";

import formNavigationUtils from "utils/formNavigationUtils";
import InputElement from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import NewUserPassword from "../../../../../userManagement/subFeatures/new-user/NewUserPassword";
import SingleImageUpload from "../../../../../inventory/subFeatures/image/SingleImageUpload";

import { commissionType as commissionOptions } from "constants/Constants";
import { stateOptions } from "constants/Constants";
import { personnelType as personnelOptions } from "constants/Constants";
import { useCompanyEmployeeData } from "./CompanyEmployeesContext";
import { useFormContext } from "react-hook-form";
import { CurrencyInputElement } from "features/forms/components/InputElement";
import { PercentInputElement } from "features/forms/components/InputElement";
import { PhoneInputElement } from "features/forms/components/InputElement";
import { SelectorElement } from "features/forms/components/InputElement";
import { showApiError } from "../../../../../../utils/errorRoutingUtils";
import {
	retrieveFailAlert,
	successAlertCustom,
} from "../../../../../../utils/alertUtils";
import { resetPassword } from "../../../../../../api/LoginAPI";
import Swal from "sweetalert2";

const CompanyEmployeesTab = () => {
	// Context Data
	const {
		imageFile,
		setImageFile,
		imageData,
		setImageData,
		userID,
		newUserUsername,
		setNewUserUsername,
		newUserPassword,
		setNewUserPassword,
		newUserConfirmPass,
		setNewUserConfirmPass,
		firstName,
		setFirstName,
		middleName,
		setMiddleName,
		lastName,
		setLastName,
		title,
		setTitle,
		driversLicense,
		setDriversLicense,
		salesLicense,
		setSalesLicense,
		email,
		setEmail,
		phone,
		setPhone,
		cellPhone,
		setCellPhone,
		street,
		setStreet,
		city,
		setCity,
		zip,
		setZip,
		state,
		setState,
		commissionPercent,
		setCommissionPercent,
		commissionMinimum,
		setCommissionMinimum,
		commissionFixedAmount,
		setCommissionFixedAmount,
		commissionType,
		setCommissionType,
		personnelType,
		setPersonnelType,
		resetFields,
	} = useCompanyEmployeeData();

	const confirmResetPassword = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",

			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, reset",
		}).then((result) => {
			if (result.isConfirmed) {
				confirmReset();
			}
		});
	};

	const confirmReset = () => {
		const data = {
			username: newUserUsername,
			email,
		};

		resetPassword(data).then(
			(res) => {
				successAlertCustom(
					"Successfully reset password. Have user check email."
				);
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	useEffect(() => {
		return () => resetFields();
		// eslint-disable-next-line
	}, []);

	const methods = useFormContext();

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Employee Information"
				inputComponents={[
					<SingleImageUpload
						containerWidth="w-100 mb-5"
						minWidth={200}
						minHeight={150}
						maxWidth={800}
						maxHeight={750}
						file={imageFile}
						setFile={setImageFile}
						disablePhotoChange={false}
						picData={imageData}
						setPicData={setImageData}
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						value={personnelType}
						name="employeePersonnelType"
						label="Personnel Type"
						onChange={(e) => setPersonnelType(e.target.value)}
						options={personnelOptions}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						name="employeeTitle"
						label="Title"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={newUserUsername}
						onChange={(e) => setNewUserUsername(e.target.value)}
						name="employeeUsername"
						label="Employee Username"
					/>,
					<>
						{userID === null && (
							<>
								<NewUserPassword
									{...methods}
									colSize="col-lg-4"
									value={newUserPassword}
									onChange={(e) => setNewUserPassword(e.target.value)}
									name="employeePassword"
									label="Employee Password"
								/>
								<NewUserPassword
									{...methods}
									colSize="col-lg-4"
									value={newUserConfirmPass}
									onChange={(e) => setNewUserConfirmPass(e.target.value)}
									name="confirmEmployeePassword"
									label="Confirm Password"
								/>
								<div className="col-12">
									<br />
									<br />
								</div>
							</>
						)}
					</>,
					<div className="d-flex col-sm-3 justify-content-end align-items-center">
						{userID !== null && (
							<>
								<Button color="primary" onClick={confirmResetPassword}>
									Reset Password
								</Button>
							</>
						)}
					</div>,
					<div className="col-12" />,
					<InputElement
						{...methods}
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						name="employeeFirstName"
						label="First Name"
					/>,
					<InputElement
						{...methods}
						value={middleName}
						onChange={(e) => setMiddleName(e.target.value)}
						name="employeeMiddleName"
						label="Middle Name"
					/>,
					<InputElement
						{...methods}
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
						name="employeeLastName"
						label="Last Name"
					/>,
					<InputElement
						{...methods}
						value={driversLicense}
						onChange={(e) => setDriversLicense(e.target.value)}
						name="employeeDriversLicense"
						label="Drivers License"
					/>,
					<InputElement
						{...methods}
						value={salesLicense}
						onChange={(e) => setSalesLicense(e.target.value)}
						name="employeeSalesLicense"
						label="Sales License"
					/>,
					<InputElement
						{...methods}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						name="employeeEmail"
						label="Email"
					/>,
					<PhoneInputElement
						{...methods}
						value={phone}
						onChange={setPhone}
						name="employeeHomePhone"
						label="Home Phone"
					/>,
					<PhoneInputElement
						{...methods}
						value={cellPhone}
						onChange={setCellPhone}
						name="employeeCellPhone"
						label="Cell Phone"
					/>,
					<div className="col-12">
						<br />
						<br />
					</div>,
					<InputElement
						{...methods}
						value={street}
						onChange={(e) => setStreet(e.target.value)}
						name="employeeStreet"
						label="Street Address"
					/>,
					<InputElement
						{...methods}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						name="employeeCity"
						label="City"
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-2"
						value={zip}
						onChange={(e) => setZip(e.target.value)}
						name="employeeZip"
						label="Zip"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-sm-2"
						value={state}
						label="State"
						name="employeeState"
						onChange={(e) => setState(e.target.value)}
						options={stateOptions}
					/>,
					<div className="col-12">
						<br />
						<br />
						<h3>Commission</h3>
						<br />
					</div>,
					<SelectorElement
						{...methods}
						value={commissionType}
						name="employeeCommissionType"
						label="Commission Type"
						onChange={(e) => setCommissionType(e.target.value)}
						options={commissionOptions}
					/>,
					<PercentInputElement
						{...methods}
						value={commissionPercent}
						onChange={setCommissionPercent}
						name="employeeCommissionPercent"
						label="Commission Percent"
					/>,
					<CurrencyInputElement
						value={commissionFixedAmount}
						onChange={setCommissionFixedAmount}
						name="employeeFixedCommission"
						label="Fixed Commission"
					/>,
					<CurrencyInputElement
						value={commissionMinimum}
						onChange={setCommissionMinimum}
						name="employeeMinimumCommission"
						label="Minimum Commission"
					/>,
				]}
			/>
		</Form>
	);
};

export default CompanyEmployeesTab;
