import React, { useState } from "react";
import { Input, Row, Col } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import AftermarketModal from "../modals/aftermarket/AftermarketModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

/**
 * Aftermarket component to display and edit aftermarket details.
 *
 * @returns {JSX.Element} The rendered component.
 */
const Aftermarket = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { aftmkt } = useCurrentSaleStore(
		(state) => ({
			aftmkt: state.aftmkt,
		}),
		shallow
	);

	/**
	 * Toggles the aftermarket modal.
	 */
	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Row>
			{isModalOpen && (
				<AftermarketModal modal={isModalOpen} toggle={toggleModal} />
			)}
			<Col xs={6} className="px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Aftermarket"
					toggle={toggleModal}
				/>
			</Col>
			<Col xs={6} className="pr-0 pl-2">
				<NumberFormat
					value={aftmkt}
					thousandSeparator
					decimalScale={2}
					fixedDecimalScale
					prefix="$"
					displayType="input"
					customInput={Input}
					readOnly
					onClick={toggleModal}
				/>
			</Col>
		</Row>
	);
};

export default Aftermarket;
