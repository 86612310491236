import React from "react";

const DigitzsProgressBar = ({ currentForm, setCurrentForm }) => {
	const tabNames = [
		"Personal Information",
		"Bank Information",
		"Business Information",
		"Agreement",
		"Summary",
	];

	const handleClick = (tab) => {
		if (tab > currentForm) {
			return;
		}

		setCurrentForm(tab);
	};

	return (
		<ul>
			{tabNames.map((name, i) => (
				<li
					key={i}
					onClick={() => handleClick(i)}
					className={`${
						(currentForm === i && "active") || (currentForm > i && "complete")
					}`}
				>
					{name}
				</li>
			))}
		</ul>
	);
};

export default DigitzsProgressBar;
