import React, { useState } from "react";
import { Button } from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";
import useCurrentDealStore from "stores/DealStore";
import ChildrenModal from "components/modals/ChildrenModal";
import CustomerReference from "./CustomerReference";
import CustomerReferenceTable from "./CustomerReferenceTable";

import { DealStatus } from "constants/Constants";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import shallow from "zustand/shallow";

const CustomerReferencesTab = () => {
	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Customer store
	const { references } = useCurrentCustomerStore(
		(state) => ({
			references: state.references,
		}),
		shallow
	);
	// Local state
	const [currentIndex, setCurrentIndex] = useState(references.length);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
		},
	});

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};

		Object.keys(obj).map((key) => {
			if (key.includes("known")) {
				return (schemaObj = { ...schemaObj, [key]: yup.string().max(2) });
			} else if (key.includes("city") || key.includes("relationship"))
				return (schemaObj = {
					...schemaObj,
					[key]: yup.string().matches(/^[A-Za-z]+$/, {
						message: "Only letters are allowed",
						excludeEmptyString: true,
					}),
				});
			else if (key.includes("homePhone"))
				return (schemaObj = {
					...schemaObj,
					[key]: yup
						.string()
						.max(50)
						.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
							message: "Invalid Phone Number",
							excludeEmptyString: true,
						}),
				});
			if (key.includes("firstName") || key.includes("lastName")) {
				return (schemaObj = {
					...schemaObj,
					[key]: yup
						.string()
						.required()
						.max(50)
						.matches(/^[A-Za-z]+$/, {
							message: "Must be a name",
						}),
				});
			}

			return (schemaObj = { ...schemaObj, [key]: yup.mixed() });
		});

		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = yup.lazy((obj) => yup.object(getSchemaObj(obj)));

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<div className="col mb-2">
				<Button
					className="btn btn-primary btn-sm mx-3"
					readOnly={type !== DealStatus.PENDING}
					onClick={toggle}
				>
					<i className="nc-icon nc-simple-add"></i> Add Reference
				</Button>
				<div className="col-md-6 text-right"></div>
			</div>
			<FormProvider {...methods}>
				<div className="col">
					<ChildrenModal
						modal={modal}
						toggle={toggle}
						modalTitle={"Add Reference"}
						children={
							<CustomerReference
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggle}
							/>
						}
					/>
					<CustomerReferenceTable
						readOnly={type !== DealStatus.PENDING}
						setCurrentIndex={setCurrentIndex}
						toggle={toggle}
					/>
				</div>
			</FormProvider>
		</>
	);
};

export default CustomerReferencesTab;
