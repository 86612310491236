import React, { useState } from "react";
import { Progress } from "reactstrap";
import useCurrentPhotoStore from "stores/PhotoStore";
import useCurrentVehicleStore from "stores/InventoryStore";
import { getImageList, uploadImages } from "api/InventoryAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { failAlert } from "../../../../../utils/alertUtils";
import { createPhotosObj, getThumbnailURL } from "./helperFunctions";
import MultiImageUploader from "../../image/multi/MultiImageUploader";
import { useMultiImageData } from "../../image/multi/MultiImageContext";

/**
 * Component that allows users to upload images one at a time to prevent connection timeouts.
 */
const UploadPhotos = ({ newVehicle }) => {
	const MAX_UPLOADS = 100;

	// User store
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Progress state for handling upload progress
	const [progress, setProgress] = useState(0);

	// Vehicle store
	const { vin, editThumbnail } = useCurrentVehicleStore();

	// Photo store
	const { gallery, editUploads, editGallery, editReorders } =
		useCurrentPhotoStore();

	// Multi image context
	const { imageSources, setImageSources, setFileUploads } = useMultiImageData();

	// Function to reset the upload state
	const resetUploadState = () => {
		setImageSources({});
		setFileUploads({});
	};

	// Function to validate image files
	const validateImage = async (blobUrl) => {
		try {
			const response = await fetch(blobUrl);
			const blob = await response.blob();
			const img = new Image();
			img.src = URL.createObjectURL(blob);
			return new Promise((resolve, reject) => {
				img.onload = () => resolve(blob);
				img.onerror = () => reject(new Error("Invalid image"));
			});
		} catch (error) {
			throw new Error("Invalid image");
		}
	};

	/**
	 * Uploads images one at a time to the server
	 */
	const upload = async () => {
		const imageFilesToArray = Object.entries(imageSources);

		if (isOverMaxUploads(imageFilesToArray.length)) {
			return;
		}

		let uploadedCount = 0;
		const totalImages = imageFilesToArray.length;

		for (const [filename, blobUrl] of imageFilesToArray) {
			try {
				console.log(`Starting upload for ${filename}`);
				const blob = await validateImage(blobUrl);
				const singleImageFormData = new FormData();
				singleImageFormData.append(filename, blob, filename);

				await uploadImages(
					dealerID,
					locationID,
					vin,
					singleImageFormData,
					(progress) => {
						console.log(`Upload progress for ${filename}: ${progress}%`);
						setProgress(
							Math.round(((uploadedCount + progress / 100) / totalImages) * 100)
						);
					}
				);

				uploadedCount++;
				console.log(
					`Successfully uploaded ${filename}. ${uploadedCount}/${totalImages} complete.`
				);
			} catch (error) {
				console.error(`Failed to upload ${filename}:`, error);
				failAlert(`Failed to upload ${filename}. Please try again.`);
				setProgress(0);
				resetUploadState();
				return;
			}
		}

		console.log(
			"All images uploaded successfully. Fetching updated image list."
		);
		const res = await getImageList(dealerID, locationID, vin);
		let imgArr = res.data.content;
		let photoObj = createPhotosObj(imgArr);

		editUploads({});
		editThumbnail(getThumbnailURL(imgArr));
		editGallery(photoObj);
		editReorders(photoObj);
		resetUploadState();
		setProgress(0);
		console.log("Upload process completed.");
	};

	// Verifies that user is not over max number of allowed uploads. Else continues
	// to upload photos
	const isOverMaxUploads = (uploadSize) => {
		if (gallery.length + uploadSize > MAX_UPLOADS) {
			failAlert(
				`A maximum of ${MAX_UPLOADS} images are allowed. You cannot upload any more images.`
			);
			return true;
		}
		return false;
	};

	return (
		<div>
			<div className="mt-3">
				<div className="d-flex justify-content-center align-items-center">
					{progress !== 0 && (
						<div className="text-center mb-5 w-75">
							<Progress striped max="100" value={progress}>
								<span className="progress-value">{progress}%</span>
							</Progress>
						</div>
					)}
				</div>
				<MultiImageUploader
					totalFiles={gallery.length}
					maxFiles={MAX_UPLOADS}
					handleUpload={upload}
					hideUploadButton={newVehicle}
				/>
			</div>
		</div>
	);
};

export default UploadPhotos;
