import React, { useState, useEffect, useContext } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	Table,
	CardHeader,
	Button,
	Input,
	CardText,
} from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";
import { getPackList, deletePack, getPack, getAllForms } from "api/FormsAPI";
import {
	confirmDeleteAlert,
	deleteFailAlert,
	miniUpdateSuccessAlert,
} from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import ChildrenModal from "components/modals/ChildrenModal";
import FormsReportsList from "./FormsReportsList";
import { PackEditContext, PackRequestProvider } from "../context/PackContexts";
import CheckBoxReactTable from "./CheckBoxReactTable";
import Loading from "components/loadingSpinner/Loading";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import {
	EmailPackRequestButton,
	PackRequestButton,
} from "./FormsReportsButtons";
import FormsEmailProvider from "../context/FormsEmailContext";

const PacksList = () => {
	const { packsList, setPacksList, setPack, setCheckedFormsObj } = useContext(
		PackEditContext
	);
	const { ID } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
		}),
		shallow
	);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };

	const [openPackModal, setOpenPackModal] = useState(false);
	const [editMode, setEditMode] = useState(false);

	const toggle = () => {
		setOpenPackModal(!openPackModal);
	};

	const getData = () => {
		getPackList(params).then((res) => {
			setPacksList(res.data.content.packs);
		});
	};

	const removePack = (ID) => {
		console.log("Removing pack with ID: " + ID);
		if (ID) {
			deletePack(ID).then(
				(response) => {
					console.log(response);
					miniUpdateSuccessAlert();
				},
				(err) => {
					console.error(err);
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
		const newPacksList = packsList.filter((p) => p.pack.id !== ID);
		setPacksList(newPacksList);
	};

	const createNewPack = () => {
		const newPack = {
			id: null,
			packName: "New Pack",
			dealerID: dealerID,
			locationID: locationID,
		};
		setCheckedFormsObj({});
		editPack(newPack, true);
	};

	const editPack = (dcPack, edit) => {
		setPack(dcPack);
		setEditMode(edit);
		toggle();
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const packsView = () =>
		packsList.map((element, index) => {
			// TODO dynamically make pop up dialogs for each form that has params
			let paramsList = {};
			element.forms.forEach((form) => {
				paramsList[form.id] = null;
			});
			return (
				<tr key={index}>
					<td>
						<PackRequestProvider>
							<Row className="flex-wrap align-items-center">
								<Col className="col-sm-12 col-md-6 text-left">
									<CardText tag="h5">{element.pack.packName}</CardText>
								</Col>
								<Col className="col-md-6 col-sm-12">
									<Row
										className="d-flex align-items-center flex-wrap justify-content-end"
										style={{ gap: "12px" }}
									>
										<FormsEmailProvider>
											<EmailPackRequestButton
												btnSize="sm"
												packObj={element}
												dealID={ID}
												className="btn-sm"
											/>
										</FormsEmailProvider>
										<PackRequestButton
											btnSize="sm"
											packObj={element}
											dealID={ID}
											zip
											className="btn-sm"
											color="primary"
										/>
										<PackRequestButton
											btnSize="sm"
											packObj={element}
											dealID={ID}
											merge
											className="btn-sm"
											color="info"
										/>
										<PackRequestButton
											btnSize="sm"
											packObj={element}
											dealID={ID}
											eSign
											className="btn-sm"
											color="success"
										/>
										<Button
											btnSize="sm"
											color="primary"
											onClick={() => {
												editPack(element.pack, false);
											}}
											className="btn-sm"
										>
											<i className="nc-icon nc-paper" /> View
										</Button>
										<Button
											color="danger"
											onClick={() => {
												confirmDeleteAlert(removePack, element.pack.id);
											}}
											className="btn-sm"
										>
											<i className="nc-icon nc-simple-remove" /> Delete
										</Button>
									</Row>
								</Col>
							</Row>
						</PackRequestProvider>
					</td>
				</tr>
			);
		});

	return (
		<Row>
			<Col>
				<Button
					size="sm"
					className="mb-2"
					color="primary"
					onClick={() => {
						createNewPack();
					}}
				>
					<i className="nc-icon nc-simple-add" /> New Pack
				</Button>
			</Col>
			<Card className="w-100">
				<CardHeader>
					<CardTitle tag="h3">Form Packs</CardTitle>
				</CardHeader>
				<CardBody>
					<Table className="mt-4">
						<tbody>{packsView()}</tbody>
					</Table>
				</CardBody>
				<ChildrenModal
					modal={openPackModal}
					toggle={toggle}
					modalTitle={"Form Packs"}
					size="lg"
					children={<PackModal editMode={editMode} />}
				/>
			</Card>
		</Row>
	);
};

export default PacksList;

const PackModal = ({ editMode = false }) => {
	const { pack, forms, setForms, setCheckedFormsObj } = useContext(
		PackEditContext
	);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// see if i can change this on the fly from `pack`
	const [isEditMode, setIsEditMode] = useState(editMode);
	const [isLoading, setIsLoading] = useState(true);

	const params = { dealerID, locationID, id: pack.id };
	const toggleEdit = () => {
		setIsEditMode(!isEditMode);
	};

	const getData = () => {
		getPack(params).then(
			(res) => {
				setForms(res.data.content.forms);
				setCheckedIds(res.data.content.forms);
				setIsLoading(false);
			},
			(err) => {
				// this should only happen with a new pack
				console.log(err);
				setForms([]);
				setCheckedIds([]);
				setIsLoading(false);
			}
		);
	};

	const setCheckedIds = (forms) => {
		const obj = forms.reduce((accumulator, value) => {
			return { ...accumulator, [value.id]: true };
		}, {});
		console.log(obj);
		setCheckedFormsObj(obj);
	};

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setCheckedIds(forms);
		// eslint-disable-next-line
	}, [forms]);

	const EditButton = () => {
		return (
			<Button
				size="sm"
				color="primary"
				onClick={() => {
					toggleEdit();
				}}
			>
				Edit
			</Button>
		);
	};

	if (isLoading) {
		return <Loading />;
	} else {
		if (isEditMode) {
			return (
				<>
					<EditPack toggleEdit={toggleEdit} />
				</>
			);
		} else {
			return (
				<>
					<EditButton />
					<PackDetails />
				</>
			);
		}
	}
};

const EditPack = ({ toggleEdit }) => {
	const { pack, setPack } = useContext(PackEditContext);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };
	const [data, setData] = useState([]);

	const getData = () => {
		getAllForms(params).then((res) => {
			setData(res.data.content.forms);
		});
	};
	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);
	const columns = React.useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title",
				id: "id",
			},
		],
		[]
	);

	return (
		<>
			<span onFocus={(e) => e.target.select()}>
				<Input
					type="text"
					value={pack.packName}
					onChange={(e) => {
						const packName = e.target.value;
						setPack((prevState) => {
							return { ...prevState, packName };
						});
					}}
				></Input>
			</span>
			<CheckBoxReactTable
				columns={columns}
				data={data}
				toggleEdit={toggleEdit}
			/>
		</>
	);
};

const PackDetails = () => {
	const { forms, pack } = useContext(PackEditContext);
	const { carRecNum } = useCurrentDealStore(
		(state) => ({
			carRecNum: state.deal.carRecNum,
		}),
		shallow
	);
	// later provide zip and features all buttons
	return (
		<FormsReportsList
			isPack={true}
			listTitle={pack.packName}
			packForms={forms}
			inventoryID={carRecNum}
		/>
	);
};
