import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";

/**
 * ReviewRow component to display a row of labeled data, with optional formatting.
 *
 * @component
 * @param {object} props - The component props
 * @param {string} props.label - The label for the row
 * @param {string|number} props.value - The value to display
 * @param {boolean} [props.isCurrency=false] - Whether to format the value as currency
 * @param {boolean} [props.isPercent=false] - Whether to format the value as a percentage
 * @param {boolean} [props.right=true] - Whether to align the value text to the right
 * @param {string} [props.colSize="6"] - The column size for the label
 * @param {boolean} [props.isPhone=false] - Whether to format the value as a phone number
 * @returns {JSX.Element} The rendered row
 */
const ReviewRow = ({
	label,
	value,
	isCurrency = false,
	isPercent = false,
	right = true,
	colSize = "6",
	isPhone = false,
}) => (
	<Row>
		<Col xs={colSize}>{label}</Col>
		<Col className={right ? "text-right" : ""}>
			{isCurrency ? (
				<NumberFormat
					value={value}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					displayType="text"
				/>
			) : isPercent ? (
				<NumberFormat
					value={value * 100}
					decimalScale={2}
					fixedDecimalScale={true}
					suffix={"%"}
					isNumericString={true}
					displayType="text"
				/>
			) : isPhone ? (
				<NumberFormat
					value={value}
					format="### ###-####"
					isNumericString={true}
					displayType="text"
				/>
			) : (
				value
			)}
		</Col>
	</Row>
);

ReviewRow.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	isCurrency: PropTypes.bool,
	isPercent: PropTypes.bool,
	right: PropTypes.bool,
	colSize: PropTypes.string,
	isPhone: PropTypes.bool,
};

export default ReviewRow;
