import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { useForm, FormProvider, Controller } from "react-hook-form";
import useCurrentDealStore from "stores/DealStore";
import { useFromUser } from "stores/LocalStorageHelper";
import { saveSuccessAlert } from "utils/alertUtils";
import { DealStatus, isTaxableOption } from "constants/Constants";
import shallow from "zustand/shallow";
import CreatableSelect from "react-select/creatable";
import {
	CurrencyInputElement,
	SelectorElement,
} from "features/forms/components/InputElement";
import InputElement from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import PropTypes from "prop-types";

/**
 * SellerFeesEditForm component to handle adding and editing seller fees.
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentIndex - The current index of the seller fee being edited.
 * @param {function} props.setCurrentIndex - Function to set the current index.
 * @param {function} props.toggle - Function to toggle the form visibility.
 * @param {Array} props.optionList - List of options for the seller fee type.
 */
export const SellerFeesEditForm = ({
	currentIndex,
	setCurrentIndex,
	toggle,
	optionList,
}) => {
	const {
		miscSellerFees,
		editMiscSellerFees,
		dealRecNum,
		type,
		companies,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			miscSellerFees: state.miscSellerFees,
			editMiscSellerFees: state.editMiscSellerFees,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			companies: state.sellerFeeCompanies,
			calculate: state.calculate,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState(0);
	const [company, setCompany] = useState("");

	const [isTaxable, setIsTaxable] = useState(false);
	const [cost, setCost] = useState(0);
	const [sellerFeeType, setSellerFeeType] = useState("");
	const [buttonText, setButtonText] = useState("Add");

	const changeText = (text) => setButtonText(text);

	const clearFields = () => {
		changeText("Add");
		setDescription("");
		setAmount(0);
		setCompany("");
		setIsTaxable(false);
		setCost(0);
		setSellerFeeType("OTHER");
	};

	const addMiscFee = () => {
		const getID = () => {
			if (miscSellerFees[currentIndex] === undefined) {
				return null;
			} else if (miscSellerFees[currentIndex].ID === undefined) {
				return null;
			} else {
				return miscSellerFees[currentIndex].ID;
			}
		};
		const ID = getID();
		const newMiscFee = {
			type: sellerFeeType || "OTHER",
			description,
			amount: parseFloat(amount),
			company,
			cost: parseFloat(cost),
			dealerID,
			locationID,
			ID,
			dealRecNum,
			isTaxable,
		};
		console.log(newMiscFee);
		console.log("Current index is: " + currentIndex);

		const miscList = () => {
			let obj = [...miscSellerFees]; // Create a copy of miscSellerFees
			obj[currentIndex] = newMiscFee;
			return obj;
		};
		const newMiscFeeObj = miscList();
		editMiscSellerFees(newMiscFeeObj);
		clearFields();
		saveSuccessAlert();
		calculate();
		toggle();
	};

	const methods = useForm();

	useEffect(() => {
		if (currentIndex < miscSellerFees.length) {
			setSellerFeeType(miscSellerFees[currentIndex]?.type || "OTHER");
			setAmount(miscSellerFees[currentIndex]?.amount || 0);
			setCompany(miscSellerFees[currentIndex]?.company || "");
			setCost(miscSellerFees[currentIndex]?.cost || 0);
			setDescription(miscSellerFees[currentIndex]?.description || "");
			setIsTaxable(miscSellerFees[currentIndex]?.isTaxable || false);
			changeText("Save");
		} else {
			clearFields();
		}
	}, [currentIndex]);

	const taxableHandler = (value) => {
		setIsTaxable(value === "true");
	};

	return (
		<FormProvider {...methods}>
			<Form onSubmit={methods.handleSubmit(addMiscFee)}>
				<InputWrapper
					formTitle="Seller Fee"
					inputComponents={[
						<SelectorElement
							key="SellerFeeType"
							{...methods}
							name="SellerFeeType"
							label="Seller Fee Type"
							value={sellerFeeType}
							onChange={(e) => setSellerFeeType(e.target.value)}
							options={optionList}
							labelClass=" required"
							colSize="col-6"
						/>,
						<Controller
							key="SellerFeeCompanies"
							control={methods.control}
							name="SellerFeeCompanies"
							defaultValue={company || ""}
							render={({ field }) => (
								<FormGroup className="col-md-9">
									<Label>Company</Label>
									<CreatableSelect
										{...field}
										value={{ value: company, label: company }}
										options={companies.map((value) => ({
											value: value,
											label: value,
										}))}
										onChange={(e) => {
											setCompany(e.value);
											field.onChange(e.value);
										}}
									/>
								</FormGroup>
							)}
						/>,
						<SelectorElement
							key="SellerFeeTaxable"
							readOnly={type !== DealStatus.PENDING}
							{...methods}
							name="SellerFeeTaxable"
							label="Taxable"
							value={isTaxable}
							onChange={(e) => taxableHandler(e.target.value)}
							options={isTaxableOption}
						/>,
						<InputElement
							key="sellerFeeDescription"
							readOnly={type !== DealStatus.PENDING}
							{...methods}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							name="sellerFeeDescription"
							label="Description"
							type="text"
							colSize="col-sm-12"
						/>,
						<CurrencyInputElement
							key="sellerFeeCost"
							readOnly={type !== DealStatus.PENDING}
							value={cost}
							onChange={setCost}
							name="sellerFeeCost"
							label="Cost"
							type="number"
						/>,
						<CurrencyInputElement
							key="sellerFeeAmount"
							readOnly={type !== DealStatus.PENDING}
							value={amount}
							onChange={setAmount}
							name="sellerFeeAmount"
							label="Amount"
							type="number"
						/>,
					]}
					buttons={
						<div className="d-flex justify-content-center mt-2">
							<Button color="primary" type="submit">
								{buttonText === "Save" ? (
									<i className="nc-icon nc-check-2"></i>
								) : (
									<i className="nc-icon nc-simple-add"></i>
								)}
								{buttonText}
							</Button>
							<div className="btn btn-link btn-md" onClick={clearFields}>
								Reset
							</div>
						</div>
					}
				/>
			</Form>
		</FormProvider>
	);
};

SellerFeesEditForm.propTypes = {
	currentIndex: PropTypes.number.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	optionList: PropTypes.array.isRequired,
};

export default SellerFeesEditForm;
