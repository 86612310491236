import React from "react";
import { Label, Input } from "reactstrap";

/**
 * Component to create a dropdown selector with the passed in
 * options and pass the selected option to the parent component.
 *
 * @param {option, getInput, label}
 */
const SelectorFormat = ({ options, getInput, label }) => {
	const optionsList = options.map((option) => (
		<option key={option} value={option}>
			{option}
		</option>
	));

	return (
		<div>
			<Label for="select">{label}</Label>
			<Input type="select" name="select" onChange={getInput}>
				{optionsList}
			</Input>
		</div>
	);
};

export default SelectorFormat;
