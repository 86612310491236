import create from "zustand";

const useCurrentLoginStore = create((set) => ({
	ID: null,
	firstName: "",
	lastName: "",
	email: "",
	dealerLoginID: null,
	companyName: "",
	middleName: "",
	title: "",
	commissionPercent: 0,
	commissionFixedAmount: 0,
	commissionType: 0,
	commissionMinimum: 0,
	personnelType: null,
	driversLicense: "",
	cellPhone: "",
	phone: "",
	salesLicense: "",
	street: "",
	city: "",
	zip: "",
	state: "",
	active: null,
	activeDate: null,
	inactiveDate: null,
	lastLoginDate: null,
	profilePicture: null,
	username: "",
	userPrivileges: {},
	messagingNumber: "",
	locationAddress: "",
	locationUnit: "",
	locationCity: "",
	locationCountry: "",
	locationDateInactive: null,
	locationDealerNum: null,
	locationExpirationDate: null,
	locationFax: "",
	locationPhone: "",
	locationState: "",
	locationUpdatedBy: null,
	locationUpdatedDate: null,
	locationZipCode: "",
	locationID: null,
	locationCellphone: "",
	locationFirstName: "",
	locationLastName: "",
	locationEmail: "",
	locationCloudUserCount: 0,
	dealerID: null,
	dealerCompanyName: "",
	dealerSignUpDate: null,
	dealerActive: null,
	dealerUpdatedBy: null,
	dealerUpdatedDate: null,
	dealerResellerLoginID: null,
	dealerWebsite: "",
	dealerDateInactive: null,
	dealerLogoUrl: "",
	dealerDeactiveReason: "",
	dealerDealerZip: "",
	dealerDealerType: null,
	dealerBillable: null,
	dealerCcOnFile: null,
	dealerAutomatedBilling: null,
	dealerDealerToDealerOnly: null,
	taxID: "",
	dealerNum: "",
	keyless: 0,
	dc: 0, //DMS
	cr: 0, //Credit Reports
	qb: 0, //QuickBook
	ls: 0, //Leasing
	nls: 0, //RTO
	fn: 0, //BHPH
	ps: 0, //Parts & Service
	psTimeClock: 0, // Time Clock
	psBarCode: 0, // BarCode
	dealerTempID: null,
	editDealerTempID: (val) => set(() => ({ dealerTempID: val })),
	editID: (val) => set(() => ({ ID: val })),
	editFirstName: (val) => set(() => ({ firstName: val })),
	editLastName: (val) => set(() => ({ lastName: val })),
	editEmail: (val) => set(() => ({ email: val })),
	editDealerLoginID: (val) => set(() => ({ dealerLoginID: val })),
	editCompanyName: (val) => set(() => ({ companyName: val })),
	editMiddleName: (val) => set(() => ({ middleName: val })),
	editTitle: (val) => set(() => ({ title: val })),
	editCommissionPercent: (val) => set(() => ({ commissionPercent: val })),
	editCommissionFixedAmount: (val) =>
		set(() => ({ commissionFixedAmount: val })),
	editCommissionType: (val) => set(() => ({ commissionType: val })),
	editCommissionMinimum: (val) => set(() => ({ commissionMinimum: val })),
	editPersonnelType: (val) => set(() => ({ personnelType: val })),
	editDriversLicense: (val) => set(() => ({ driversLicense: val })),
	editCellPhone: (val) => set(() => ({ cellPhone: val })),
	editPhone: (val) => set(() => ({ phone: val })),
	editSalesLicense: (val) => set(() => ({ salesLicense: val })),
	editStreet: (val) => set(() => ({ street: val })),
	editCity: (val) => set(() => ({ city: val })),
	editZip: (val) => set(() => ({ zip: val })),
	editState: (val) => set(() => ({ state: val })),
	editActive: (val) => set(() => ({ active: val })),
	editActiveDate: (val) => set(() => ({ activeDate: val })),
	editInactiveDate: (val) => set(() => ({ inactiveDate: val })),
	editLastLoginDate: (val) => set(() => ({ lastLoginDate: val })),
	editMessagingNumber: (val) => set(() => ({ messagingNumber: val })),
	editProfilePicture: (val) => set(() => ({ profilePicture: val })),
	editUsername: (val) => set(() => ({ username: val })),
	editUserPrivileges: (val) => set(() => ({ userPrivileges: val })),
	editLocationAddress: (val) => set(() => ({ locationAddress: val })),
	editLocationUnit: (val) => set(() => ({ locationUnit: val })),
	editLocationCity: (val) => set(() => ({ locationCity: val })),
	editLocationCountry: (val) => set(() => ({ locationCountry: val })),
	editLocationDateInactive: (val) => set(() => ({ locationDateInactive: val })),
	editLocationDealerNum: (val) => set(() => ({ locationDealerNum: val })),
	editLocationExpirationDate: (val) =>
		set(() => ({ locationExpirationDate: val })),
	editLocationFax: (val) => set(() => ({ locationFax: val })),
	editLocationPhone: (val) => set(() => ({ locationPhone: val })),
	editLocationState: (val) => set(() => ({ locationState: val })),
	editLocationUpdatedBy: (val) => set(() => ({ locationUpdatedBy: val })),
	editLocationUpdatedDate: (val) => set(() => ({ locationUpdatedDate: val })),
	editLocationZipCode: (val) => set(() => ({ locationZipCode: val })),
	editLocationID: (val) => set(() => ({ locationID: val })),
	editLocationCellphone: (val) => set(() => ({ locationCellphone: val })),
	editLocationFirstName: (val) => set(() => ({ locationFirstName: val })),
	editLocationLastName: (val) => set(() => ({ locationLastName: val })),
	editLocationEmail: (val) => set(() => ({ locationEmail: val })),
	editLocationCloudUserCount: (val) =>
		set(() => ({ locationCloudUserCount: val })),
	editDealerID: (val) => set(() => ({ dealerID: val })),
	editDealerCompanyName: (val) => set(() => ({ dealerCompanyName: val })),
	editDealerSignUpDate: (val) => set(() => ({ dealerSignUpDate: val })),
	editDealerActive: (val) => set(() => ({ dealerActive: val })),
	editDealerUpdatedBy: (val) => set(() => ({ dealerUpdatedBy: val })),
	editDealerUpdatedDate: (val) => set(() => ({ dealerUpdatedDate: val })),
	editDealerResellerLoginID: (val) =>
		set(() => ({ dealerResellerLoginID: val })),
	editDealerWebsite: (val) => set(() => ({ dealerWebsite: val })),
	editDealerDateInactive: (val) => set(() => ({ dealerDateInactive: val })),
	editDealerLogoUrl: (val) => set(() => ({ dealerLogoUrl: val })),
	editDealerDeactiveReason: (val) => set(() => ({ dealerDeactiveReason: val })),
	editDealerDealerZip: (val) => set(() => ({ dealerDealerZip: val })),
	editDealerDealerType: (val) => set(() => ({ dealerDealerType: val })),
	editDealerBillable: (val) => set(() => ({ dealerBillable: val })),
	editDealerCcOnFile: (val) => set(() => ({ dealerCcOnFile: val })),
	editDealerAutomatedBilling: (val) =>
		set(() => ({ dealerAutomatedBilling: val })),
	editDealerDealerToDealerOnly: (val) =>
		set(() => ({ dealerDealerToDealerOnly: val })),
	editTaxID: (val) => set(() => ({ taxID: val })),
	editDealerNum: (val) => set(() => ({ dealerNum: val })),

	setLoginFields: (login, locations, dealers, profileData) =>
		set(() => ({
			ID: login.ID,
			firstName: login.firstName,
			lastName: login.lastName,
			email: login.email,
			dealerLoginID: login.dealerLoginID,
			companyName: login.companyName,
			middleName: login.middleName,
			title: login.title,
			commissionPercent: login.commissionPercent,
			commissionFixedAmount: login.commissionFixedAmount,
			commissionType: login.commissionType,
			commissionMinimum: login.commissionMinimum,
			personnelType: login.personnelType,
			driversLicense: login.driversLicense,
			cellPhone: login.cellPhone,
			phone: login.phone,
			salesLicense: login.salesLicense,
			street: login.street,
			city: login.city,
			zip: login.zip,
			state: login.state,
			active: login.active,
			activeDate: login.activeDate,
			inactiveDate: login.inactiveDate,
			lastLoginDate: login.lastLoginDate,
			profilePicture: profileData,
			username: login.username,
			userPrivileges: JSON.parse(login.userPrivileges) || {},
			locationAddress: locations.address,
			locationUnit: locations.unit,
			locationCity: locations.city,
			locationCountry: locations.country,
			locationDateInactive: locations.dateInactive,
			locationDealerNum: locations.dealerNum,
			locationExpirationDate: locations.expirationDate,
			locationFax: locations.fax,
			locationPhone: locations.phone,
			locationState: locations.state,
			locationUpdatedBy: locations.updatedBy,
			locationUpdatedDate: locations.updatedDate,
			locationZipCode: locations.zipCode,
			locationID: locations.ID,
			locationCellphone: locations.cellphone,
			locationFirstName: locations.firstName,
			locationLastName: locations.lastName,
			locationEmail: locations.email,
			locationCloudUserCount: locations.cloudUserCount,
			dealerID: dealers.ID,
			dealerCompanyName: dealers.companyName,
			dealerSignUpDate: dealers.signUpDate,
			dealerActive: dealers.active,
			dealerUpdatedBy: dealers.updatedBy,
			dealerUpdatedDate: dealers.updatedDate,
			dealerResellerLoginID: dealers.resellerLoginID,
			dealerWebsite: dealers.website,
			dealerDateInactive: dealers.dateInactive,
			dealerLogoUrl: dealers.logoUrl,
			dealerDeactiveReason: dealers.deactiveReason,
			dealerDealerZip: dealers.dealerZip,
			dealerDealerType: dealers.dealerType,
			dealerBillable: dealers.billable,
			dealerCcOnFile: dealers.ccOnFile,
			dealerAutomatedBilling: dealers.automatedBilling,
			dealerDealerToDealerOnly: dealers.dealerToDealerOnly,
			taxID: locations.taxID,
			dealerNum: locations.dealerNum,
			dc: locations.dc,
			cr: locations.cr,
			qb: locations.qb,
			ls: locations.ls,
			nls: locations.nls,
			fn: locations.fn,
			ps: locations.ps,
			psTimeClock: locations.psTimeClock,
			psBarCode: locations.psBarCode,
			keyless: locations.keyless,
		})),

	reset: () =>
		set({
			ID: null,
			firstName: "",
			lastName: "",
			email: "",
			dealerLoginID: null,
			companyName: "",
			middleName: "",
			title: "",
			commissionPercent: 0,
			commissionFixedAmount: 0,
			commissionType: 0,
			commissionMinimum: 0,
			personnelType: null,
			driversLicense: "",
			cellPhone: "",
			phone: "",
			salesLicense: "",
			street: "",
			city: "",
			zip: "",
			state: "",
			active: null,
			activeDate: null,
			inactiveDate: null,
			lastLoginDate: null,
			profilePicture: null,
			username: "",
			userPrivileges: {},
			messagingNumber: "",
			locationAddress: "",
			locationUnit: "",
			locationCity: "",
			locationCountry: "",
			locationDateInactive: null,
			locationDealerNum: null,
			locationExpirationDate: null,
			locationFax: "",
			locationPhone: "",
			locationState: "",
			locationUpdatedBy: null,
			locationUpdatedDate: null,
			locationZipCode: "",
			locationID: null,
			locationCellphone: "",
			locationFirstName: "",
			locationLastName: "",
			locationEmail: "",
			locationCloudUserCount: 0,
			dealerID: null,
			dealerCompanyName: "",
			dealerSignUpDate: null,
			dealerActive: null,
			dealerUpdatedBy: null,
			dealerUpdatedDate: null,
			dealerResellerLoginID: null,
			dealerWebsite: "",
			dealerDateInactive: null,
			dealerLogoUrl: "",
			dealerDeactiveReason: "",
			dealerDealerZip: "",
			dealerDealerType: null,
			dealerBillable: null,
			dealerCcOnFile: null,
			dealerAutomatedBilling: null,
			dealerDealerToDealerOnly: null,
			taxID: "",
			dealerNum: "",
			dc: 0, //DMS
			cr: 0, //Credit Reports
			qb: 0, //QuickBook
			ls: 0, //Leasing
			nls: 0,
			fn: 0,
			ps: 0,
			psTimeClock: 0,
			psBarCode: 0,
			keyless: 0,
		}),
}));

export default useCurrentLoginStore;
