import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getInventoryStatusByDateRenge } from "api/DealAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import useDateRangeStore from "stores/DateRangeStore";
import PageHeader from "components/header/PageHeader";

/**
 * Formats a card status string by replacing commas with " & ".
 *
 * @param {string} status - The status string to format.
 * @returns {string} - The formatted status string.
 */
const formatCardStatus = (status) => {
	if (typeof status !== "string") {
		return status;
	}
	return status.replace(/,/g, " & ");
};

/**
 * Component for displaying inventory header with status filters.
 *
 * @param {Object} props - Component props
 * @param {Array<string>} props.statusList - List of inventory statuses
 * @param {Function} props.setStatusList - Function to set the status list
 * @returns {JSX.Element} The rendered component.
 */
const InventoryHeader = ({ statusList, setStatusList }) => {
	const { fromDate, toDate } = useDateRangeStore();
	const [inventoryCounts, setInventoryCounts] = useState({
		available: 0,
		pending: 0,
		sold: 0,
		soldNoDeal: 0,
		deleted: 0,
		trade: 0,
		hold: 0,
		appraisal: 0,
	});
	const [status, setStatus] = useState("Available & Pending");
	const locationID = useFromUser("locationID");

	/**
	 * Handle click event on a card to set the status.
	 *
	 * @param {string} cardStatus - The status of the card clicked.
	 * @returns {Function} The click event handler.
	 */
	const handleCardClick = (cardStatus) => (e) => {
		e.preventDefault();
		setStatusList(cardStatus);
		setStatus(cardStatus);
	};

	/**
	 * Format data and update state based on content received.
	 *
	 * @param {Array} content - The content to format.
	 */
	const dataFormat = (content) => {
		const counts = {
			available: 0,
			pending: 0,
			sold: 0,
			soldNoDeal: 0,
			deleted: 0,
			trade: 0,
			hold: 0,
			appraisal: 0,
		};

		content.forEach((item) => {
			const key = item.typeDesc.toLowerCase();
			if (Object.prototype.hasOwnProperty.call(counts, key)) {
				counts[key] = item.numOfType;
			}
		});

		setInventoryCounts(counts);
	};

	/**
	 * Fetch the inventory status by date range based on location ID.
	 */
	const getInvListData = () => {
		if (locationID) {
			getInventoryStatusByDateRenge(locationID, fromDate, toDate).then(
				(response) => {
					dataFormat(response.data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		getInvListData();
		setStatus("Available,Pending");
		// eslint-disable-next-line
	}, [fromDate, toDate]);

	const formattedCardStatus = formatCardStatus(status);

	return (
		<PageHeader
			titlePrefix="Inventory"
			title={formattedCardStatus}
			cards={[
				{
					title: "Available/Pending",
					count: inventoryCounts.available + inventoryCounts.pending,
					iconClass: "nc-delivery-fast text-success",
					iconText: " Add New",
					routePath: "/inventory-add",
					isActive: status === "Available,Pending",
					onCardClick: handleCardClick("Available,Pending"),
				},
				{
					title: "Sold",
					count: inventoryCounts.sold + inventoryCounts.soldNoDeal,
					iconText: " Add New",
					routePath: "/inventory-add",
					iconClass: "nc-delivery-fast text-info",
					isActive: status === "Sold,Sold Without Deal",
					onCardClick: handleCardClick("Sold,Sold Without Deal"),
				},
				{
					title: "Trade/Hold/Delete",
					count:
						inventoryCounts.trade +
						inventoryCounts.hold +
						inventoryCounts.deleted,
					iconClass: "nc-delivery-fast text-warning",
					iconText: " Add New",
					routePath: "/inventory-add",
					isActive: status === "Trade,Hold,Deleted",
					onCardClick: handleCardClick("Trade,Hold,Deleted"),
				},
				{
					title: "Appraisal",
					count: inventoryCounts.appraisal,
					iconText: " Add New",
					routePath: "/inventory-add",
					iconClass: "nc-delivery-fast text-danger",
					isActive: status === "Appraisal",
					onCardClick: handleCardClick("Appraisal"),
				},
			]}
			onCardClick={handleCardClick}
		/>
	);
};

InventoryHeader.propTypes = {
	statusList: PropTypes.string.isRequired,
	setStatusList: PropTypes.func.isRequired,
};

export default InventoryHeader;
