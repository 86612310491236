import React, { useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";

import NumberFormat from "react-number-format";

import { getTwilioRecentMessages } from "../../../api/TwilioApi";
import { saveFailAlert } from "../../../utils/alertUtils";
import { showApiError } from "../../../utils/errorRoutingUtils";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import "assets/css/recent-message-log.css";
import Twilio from "../../../services/twilioService/Twilio";
import { TemplateVariableProvider } from "../../../context/TemplateVariableContext";
import InitializeTemplateVars from "../../../utils/initializeTemplateVars";
import { MessageZone } from "../../../constants/Constants";

dayjs.extend(isBetween);

const MessageHistory = () => {
	// Local storage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const dealerNumber = user?.messagingNumber;
	const dealerID = user?.dealerID;
	const locationID = user?.locationID;

	// Local state
	const [messages, setMessages] = useState([]);
	const [textModal, setTextModal] = useState(false);

	// Changes the format of a date depending on the time/day difference
	const getDisplayDate = (now, sentDate) => {
		const sent = dayjs(sentDate);
		const displaySent = sent.isBetween(
			now.subtract(24, "hour"),
			now,
			"second",
			"[]"
		)
			? sent.format("h:mm A") // Message is within the last 24hrs
			: sent.isBetween(now.subtract(7, "day"), now, "second", "[]")
			? sent.format("dddd h:mm A") // Message is within this week
			: sent.isBetween(now.subtract(1, "year"), now, "second", "[]")
			? sent.format("MMM D") // Message is within this year
			: sent.format("MMM YYYY"); // Message is last year or older

		return displaySent;
	};

	// Get the recent messages and create list elements with them
	const handleRecentMessages = (messages) => {
		const now = dayjs();
		const data = messages.map((msg) => {
			console.log("msg", msg);
			const sent = getDisplayDate(now, msg.insertedOn);
			const senderNumber =
				msg.toPhoneNumber === dealerNumber?.trim()
					? msg.fromPhoneNumber
					: msg.toPhoneNumber;

			return (
				<li
					key={msg.ID}
					onClick={() => {
						// setNumber(senderNumber);
						toggleTextModal();
					}}
				>
					<div className="recent-message-log-icon">
						<span>
							<i className="nc-icon nc-single-02" />
						</span>
					</div>
					<div className="recent-message-log-content">
						<div>
							{((msg.name === null || msg.name.trim() === "") && (
								<NumberFormat
									value={senderNumber}
									format="+1 (###) ###-####"
									isNumericString={true}
									displayType="text"
								/>
							)) || <p>{msg.name}</p>}
							<p>{msg.body}</p>
						</div>
						{msg.unRead && (
							<div
								style={{
									color: "red",
									fontWeight: "bold",
									marginTop: "5px",
								}}
							>
								<span role="img" aria-label="new-message">
									🔔
								</span>
								<span>{msg.unRead}</span>
							</div>
						)}

						<div>
							<p>{sent}</p>
						</div>
						<div>
							<span>
								<TemplateVariableProvider>
									<InitializeTemplateVars messageZone={MessageZone.CUSTOMER} />
									<Twilio sendToNumber={senderNumber} customerID={msg.ID} />
								</TemplateVariableProvider>
							</span>
						</div>
					</div>
				</li>
			);
		});

		setMessages(data);
	};
	// Toggles the modal for texting
	const toggleTextModal = () => setTextModal(!textModal);

	useEffect(() => {
		console.log("Getting recent messages");
		getTwilioRecentMessages(dealerID, locationID, dealerNumber).then(
			(res) => handleRecentMessages(res.data.content.messages),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="content mx-0 px-3 d-flex justify-content-center">
				<Col>
					{messages.length > 0 && (
						<Card>
							<CardBody>
								<ul className="recent-message-log">{messages}</ul>
							</CardBody>
						</Card>
					)}
					{messages.length < 1 && (
						<div
							className="d-flex flex-column justify-content-center align-items-center"
							style={{ height: "80vh" }}
						>
							<h3 className="text-center">No recent message records found</h3>
						</div>
					)}
				</Col>
			</div>
		</>
	);
};
export default MessageHistory;
