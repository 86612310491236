import React from "react";

const OpenTotalModal = ({ label, toggle, btnClass }) => {
	// Icon that opens a modal
	return (
		<div className={btnClass} onClick={toggle}>
			{label}
		</div>
		
	);
};

export default OpenTotalModal;
