/**
 * Validate and set file uploads
 * @param {File} file - The uploaded file
 * @param {number} maxFileSize - Maximum allowed file size in MBs
 * @param {string[]} acceptArray - An array of acceptable file extensions
 * @param {string} accept - A string of acceptable MIME types
 * @param {React.Dispatch<React.SetStateAction>} setUploads - State setter function for setting uploads
 * @param {React.Dispatch<React.SetStateAction>} setUploadErrors - State setter function for setting upload errors
 */
export const fileLoaded = (
	file,
	maxFileSize,
	acceptArray,
	accept,
	setUploads,
	setUploadErrors
) => {
	const { name: fileName, size } = file;
	const extension = fileName.slice(fileName.lastIndexOf("."));

	if (!acceptArray.includes(extension.toLowerCase())) {
		setUploadErrors((prev) => [
			...prev,
			`${fileName} must be one of these types: ${accept}`,
		]);
	} else if (size / 1048576 > maxFileSize) {
		setUploadErrors((prev) => [
			...prev,
			`${fileName} cannot be bigger than ${maxFileSize}MB`,
		]);
	} else {
		setUploads((prev) => [...prev, file]);
	}
};

/**
 * Handle file read errors
 * @param {ErrorEvent} e - The error event
 * @param {File} file - The uploaded file that caused the error
 * @param {React.Dispatch<React.SetStateAction>} setUploadErrors - State setter function for setting upload errors
 */
export const fileError = (e, file, setUploadErrors) => {
	const errorName = e?.target?.error?.name || "Unexpected error";
	const errorMessage =
		errorName === "NotReadableError"
			? `${file.name} could not be read, please try again`
			: errorName;

	setUploadErrors((prev) => [...prev, errorMessage]);
};
