import React, { useEffect, useState } from "react";
import MoonIcon from "../../assets/img/moon-icon.svg";
import SunIcon from "../../assets/img/sun-icon.svg";

/**
 * A toggle button for switching between dark and light modes.
 */
const DarkModeToggle = () => {
    const getInitialMode = () => {
        const savedMode = localStorage.getItem('darkMode');
        if (savedMode !== null) {
            return JSON.parse(savedMode);
        } else {
            const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
            return systemPreference;
        }
    };

    const [darkMode, setDarkMode] = useState(getInitialMode);

    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
        document.body.classList.toggle("dark", darkMode);
    }, [darkMode]);

    const toggleDarkMode = () => setDarkMode(!darkMode);

    return (
        <div role="button" className="toggle" onClick={toggleDarkMode}>
            <img
                alt={darkMode ? "Moon Icon" : "Sun Icon"}
                src={darkMode ? MoonIcon : SunIcon}
                style={{ height: "32px" }}
            />
        </div>
    );
};

export default DarkModeToggle;
