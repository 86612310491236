import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import ChildrenModal from "components/modals/ChildrenModal";

/**
 * ListItemModal component to display integration options.
 * @param {Object} props - The component props.
 * @param {boolean} props.modal - Modal state.
 * @param {function} props.toggle - Function to toggle the modal.
 * @param {string} props.modalListTitle - The title of the modal list item.
 * @param {string} props.modalTitle - The title of the modal.
 * @param {string} [props.cardClassName] - Additional class names for the card.
 * @param {React.ReactNode} props.children - The children components.
 * @param {boolean} [props.disabled] - Whether the modal is disabled.
 * @param {string} [props.imageLogo] - The logo image URL.
 * @param {string} [props.integrationInfo] - The integration information text.
 * @returns {JSX.Element} The ListItemModal component.
 */
const ListItemModal = ({
	modal,
	toggle,
	modalListTitle,
	modalTitle,
	cardClassName = "",
	children,
	disabled = false,
	imageLogo = "",
	integrationInfo = "Here is some default description text.",
}) => {
	return (
		!disabled && (
			<Card className={cardClassName}>
				<CardBody>
					<div className="mb-3" target="_blank" onClick={toggle}>
						{imageLogo && <img alt={modalListTitle} src={imageLogo} className="miniLogo" />}
						{integrationInfo}
					</div>
					<ChildrenModal toggle={toggle} modal={modal} children={children} modalTitle={modalTitle} />
					<Button className="btn-sm" onClick={toggle}>Sign Up</Button>
				</CardBody>
			</Card>
		)
	);
};

ListItemModal.propTypes = {
	modal: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	modalListTitle: PropTypes.string.isRequired,
	modalTitle: PropTypes.string.isRequired,
	cardClassName: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	imageLogo: PropTypes.string,
	integrationInfo: PropTypes.string,
};

export default ListItemModal;
