import ChildrenModal from "components/modals/ChildrenModal";
import React, { useState } from "react";

const OpenModal = ({
	BodyComponent,
	buttonText,
	size,
	modalTitle,
	className,
	iconClass,
	disabled,
}) => {
	const [open, setOpen] = useState(false);

	const toggleOpen = () => {
		setOpen(!open);
	};
	return (
		<>
			<div className={className} onClick={toggleOpen} disabled={disabled}>
				<i className={iconClass} /> {buttonText}
			</div>
			<ChildrenModal
				toggle={toggleOpen}
				modal={open}
				size={size}
				children={<BodyComponent toggle={toggleOpen} />}
				modalTitle={modalTitle}
			/>
		</>
	);
};

export default OpenModal;
