import InputElement from "features/forms/components/InputElement";
import InventorySlelectList from "features/inventory/components/InventorySelectList";
import React from "react";
import { FormGroup } from "reactstrap";

const VehicleData = ({ leadVehicle, setIncID }) => {
	return (
		<>
			<InputElement
				disableValidation={true}
				value={leadVehicle.stockNo}
				readOnly={true}
				name="stockNumber"
				label="StockNumber"
				type="text"
			/>
			<InputElement
				disableValidation={true}
				value={leadVehicle.vin}
				readOnly={true}
				name="Vin"
				label="Vin"
				type="text"
			/>
			<InputElement
				disableValidation={true}
				value={leadVehicle.year}
				readOnly={true}
				name="year"
				label="Year"
				type="text"
			/>
			<InputElement
				disableValidation={true}
				value={leadVehicle?.make}
				readOnly={true}
				name="make"
				label="Make"
				type="text"
			/>
			<InputElement
				disableValidation={true}
				value={leadVehicle?.model}
				readOnly={true}
				name="model"
				label="Model"
				type="text"
			/>
			<InputElement
				disableValidation={true}
				value={leadVehicle.color}
				readOnly={true}
				name="color"
				label="Color"
				type="text"
			/>
			<FormGroup className={"col-sm-3"}></FormGroup>
			<FormGroup className={"col-sm-3"}>
				<InventorySlelectList editCarRecNum={setIncID} />
			</FormGroup>
		</>
	);
};

export default VehicleData;
