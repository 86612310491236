import { useState, useEffect } from "react";

const useIsOwnerOrAdmin = () => {
	const user = JSON.parse(sessionStorage.getItem("user"));
	const [isOwnerOrAdmin, setIsOwnerOrAdmin] = useState(null);

	useEffect(() => {
		try {
			const role = JSON.parse(user.userPrivileges)?.role;

			if (role === "owner" || role === "admin") {
				setIsOwnerOrAdmin(true);
			} else {
				setIsOwnerOrAdmin(false);
			}
		} catch (e) {
			console.log(e);
			console.log("Failed to parse user privilege");
		}
	}, [user]);

	return isOwnerOrAdmin;
};

export default useIsOwnerOrAdmin;
