import React from "react";
import ForgottenPasswordForm from "./ForgottenPasswordForm";
import * as yup from "yup";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

const ForgottenPassword = () => {
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape(
		{
			username: yup.string().when("email", {
				is: (email) => !email,
				then: yup.string().max(50).required(),
			}),
			email: yup.string().when("username", {
				is: (username) => !username,
				then: yup.string().email("Must be a valid email").max(255).required(),
			}),
		},
		["username", "email"]
	);

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<ForgottenPasswordForm />
		</FormProvider>
	);
};

export default ForgottenPassword;
