import React, { createContext, useState } from "react";

export const WizardContext = createContext();

const WizardProvider = ({ children }) => {
	const [activeTab, setActiveTab] = useState(0);
	const [length, setLength] = useState(0);

	// Toggles between different tabs
	const switchTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const previous = () => {
		setActiveTab(activeTab - 1);
	};

	const next = () => {
		setActiveTab(activeTab + 1);
	};

	const context = {
		activeTab,
		setActiveTab,
		switchTab,
		previous,
		next,
		length,
		setLength,
	};

	return (
		<WizardContext.Provider value={context}>{children}</WizardContext.Provider>
	);
};

export default WizardProvider;
