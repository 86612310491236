import React from "react";
import PropTypes from "prop-types";
import { CardBody, CardTitle } from "reactstrap";

/**
 * CardContent displays content within a card, including an icon and titles.
 *
 * @param {Object} props - Component props
 * @param {string} props.title1 - The primary title text
 * @param {string} props.title2 - The secondary title text
 * @param {string} props.iconClass - CSS class for the icon
 * @param {Function} props.onClick - Handler for click events
 */
function CardContent({ title1, title2, iconClass, onClick }) {
	return (
		<CardBody className="d-flex justify-content-between" onClick={onClick}>
			<div>
				<i className={iconClass} aria-hidden="true" />
			</div>
			<div>
				<div className="numbers">
					<p className="card-category">{title1}</p>
					<CardTitle tag="p">{title2}</CardTitle>
				</div>
			</div>
		</CardBody>
	);
}

CardContent.propTypes = {
	title1: PropTypes.string.isRequired,
	title2: PropTypes.any.isRequired,
	iconClass: PropTypes.string,
	onClick: PropTypes.func,
};

export default CardContent;
