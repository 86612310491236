import useBuildCustomer from "hooks/useBuildCustomer";
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { saveCustomer, getAllCustomerInfo } from "api/CustomerAPI";
import {
	deleteSuccessAlert,
	confirmDeleteMessage,
	retrieveFailAlert,
	deleteFailAlert,
} from "../../../utils/alertUtils";
import useRoute from "hooks/useRoute";
import { PATHS } from "constants/Constants";
import { useHistory } from "react-router-dom";
import { getCustomerDeals } from "api/DealAPI";
import { showApiError } from "utils/errorRoutingUtils";

const DeleteCustomerButton = ({ redirect = false }) => {
	console.log(redirect);
	const customer = useBuildCustomer();
	const [currentAddress, setCurrentAddress] = useState({});
	const [mailingAddress, setMailingAddress] = useState({});
	const newRoute = useRoute(PATHS.CUSTOMER_LIST);
	const history = useHistory();
	const [hasLength, setHasLength] = useState(false);

	const callConfirmDelete = () => {
		let confirmDelete = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			confirmDelete = res;
			if (confirmDelete === "Yes, Delete it") {
				deleteCustomerByStatus();
				if (redirect) {
					history.push(newRoute);
				}
			}
		});
	};

	const deleteCustomerByStatus = () => {
		getAllCustomerInfo(customer.ID).then(
			(res) => {
				console.log(res);
				setCurrentAddress(res.data.content.currentAddress);
				setMailingAddress(res.data.content.mailingAddress);
			},
			(error) => {
				console.log(error);
				retrieveFailAlert();
			}
		);
		customer.status = "Deleted";
		const customerInfo = {
			customer,
			currentAddress,
			mailingAddress,
		};

		saveCustomer(customerInfo).then(
			(res) => {
				console.log(res.data.content);
				deleteSuccessAlert();
			},
			(error) => {
				console.log(error);
				deleteFailAlert();
			}
		);
	};

	useEffect(() => {
		getCustomerDeals(customer.ID).then(
			(res) => {
				console.log(res.data.content);
				const deal = res.data.content.deals;
				if (deal.length > 0) {
					setHasLength(true);
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="col text-center pt-2">
			<hr />
			<Button
				className="btn btn-danger btn-md mt-2"
				onClick={callConfirmDelete}
				readOnly={hasLength}
			>
				<i className="nc-icon nc-simple-remove"></i> Delete
			</Button>
		</div>
	);
};

export default DeleteCustomerButton;
