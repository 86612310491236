import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Form, Label } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import {
	PercentInput,
	CurrencyInput,
} from "features/forms/components/InputElement";

/**
 * ReserveCalculatorModal component to display and manage the reserve calculations.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered component
 */
const ReserveCalculatorModal = () => {
	const {
		reserve,
		reservePercent,
		editReserve,
		editReservePercent,
	} = useCurrentRecapStore((state) => state, shallow);

	const { amtFinanced, editReserveFlag } = useCurrentSaleStore(
		(state) => state,
		shallow
	);

	/**
	 * Handles reserve value changes.
	 * @param {number} val - The new reserve value.
	 */
	const reserveHandler = (val) => {
		editReserveFlag(false);
		editReservePercent(0.0);
		editReserve(val);
	};

	/**
	 * Handles reserve percentage changes.
	 * @param {number} val - The new reserve percentage value.
	 */
	const reservePercentHandler = (val) => {
		editReserveFlag(true);
		editReservePercent(val);
	};

	return (
		<Form>
			<Row>
				<Col md="6">
					<Label>Amount Financed</Label>
				</Col>
				<CurrencyInput
					value={amtFinanced}
					readOnly
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<Label>Reserve %</Label>
				</Col>
				<PercentInput
					value={reservePercent}
					onChange={reservePercentHandler}
					disableValidation
					wholeRow
				/>
				<Col md="6">
					<Label>Reserve Amount</Label>
				</Col>
				<CurrencyInput
					value={reserve}
					onChange={reserveHandler}
					readOnly
					disableValidation
					wholeRow
				/>
			</Row>
		</Form>
	);
};

ReserveCalculatorModal.propTypes = {
	reserve: PropTypes.number.isRequired,
	reservePercent: PropTypes.number.isRequired,
	editReserve: PropTypes.func.isRequired,
	editReservePercent: PropTypes.func.isRequired,
	amtFinanced: PropTypes.number.isRequired,
	editReserveFlag: PropTypes.func.isRequired,
};

export default ReserveCalculatorModal;
