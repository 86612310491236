import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import GAPModal from "../modals/gap/GAPModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const InsuranceAndGAP = () => {
	const [openInsAndGap, setOpenInsAndGap] = useState(false);
	const {
		gap,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore(
		(state) => ({
			gap: state.gap,
			collisionTotPremium: state.collisionTotPremium,
			lifePremium: state?.lifePremium || 0,
			disabilityPremium: state?.disabilityPremium || 0,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setOpenInsAndGap(!openInsAndGap);
	};
	console.log(gap, collisionTotPremium, lifePremium, disabilityPremium);
	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			{openInsAndGap ? (
				<GAPModal modal={openInsAndGap} toggle={toggle} />
			) : null}
			<div className="col-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="GAP/Ins"
					toggle={toggle}
				/>
			</div>
			<div className="col-6 pr-0  moneyIn pl-2">
				<span onClick={toggle}>
					<NumberFormat
						value={
							gap +
							(collisionTotPremium === null || collisionTotPremium === undefined
								? 0
								: collisionTotPremium) +
							(lifePremium === null || lifePremium === undefined
								? 0
								: lifePremium) +
							(disabilityPremium === null || disabilityPremium === undefined
								? 0
								: disabilityPremium)
						}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						readOnly={true}
						customInput={Input}
						
					/>
				</span>
			</div>
		</Row>
	);
};

export default InsuranceAndGAP;
