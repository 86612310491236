import React, { useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	ListGroupItem,
	Collapse,
	Col,
	Row,
	Button,
} from "reactstrap";

/**
 * InventoryStandardEquipment component - Displays the standard equipment and specifications of the vehicle.
 * 
 * @param {object} props - Component properties.
 * @returns {JSX.Element} A responsive grid of standard equipment and specifications.
 */
const InventoryStandardEquipment = (props) => {
	let standardObj = "";
	try {
		standardObj = JSON.parse(props.standardEquip);
	} catch (e) {
		console.log(e.message);
	}
	let standard = "";
	let standardEnd = "";

	// This iterates through the JSON object to display ListGroupItems
	// for standard equipment
	if (standardObj !== null && standardObj !== "") {
		let halfSize = Math.ceil(standardObj.length / 2);
		standard = Object.keys(standardObj.slice(0, halfSize)).map((key) => (
			<div key={standardObj[key].Key}>
				<h6>{standardObj[key].Key}</h6>
				<ListGroup>
					{Object.keys(standardObj[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{standardObj[key].Value[key2].Name +
								" " +
								standardObj[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));
		let standEnd = standardObj.slice(halfSize, standardObj.length);
		standardEnd = Object.keys(standEnd).map((key) => (
			<div key={standEnd[key].Key}>
				<h6>{standEnd[key].Key}</h6>
				<ListGroup>
					{Object.keys(standEnd[key].Value).map((key2, i) => (
						<ListGroupItem key={i}>
							{standEnd[key].Value[key2].Name +
								" " +
								standEnd[key].Value[key2].Value}
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		));
	}

	const [open, setOpen] = useState(false);

	const handleCollapse = () => {
		setOpen(!open);
	};

	return (
		<Card>
			<CardHeader
				style={{ justifyContent: "space-between" }}
				onClick={handleCollapse}
				className="cursor-pointer d-flex py-2"
			>
				<h3>Standard Equipment</h3>
				<Button className="btn-link btn-sm">
					<i className={`nc-icon ${open ? "nc-minimal-up" : "nc-minimal-down"}`} />
				</Button>
			</CardHeader>
			<Collapse role="tabpanel" isOpen={open}>
				<CardBody>
					<Row>
						<Col xs="12" md="6">
							{standard}
						</Col>
						<Col xs="12" md="6">
							{standardEnd}
						</Col>
					</Row>
				</CardBody>
			</Collapse>
		</Card>
	);
};

export default InventoryStandardEquipment;
