import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
	displayPdf,
	displayError,
	formLoading,
	pdfEsignature,
	packEsignature,
} from "api/FormsAPI";
import { FormOrReportRequest } from "./RequestModal";
import swal from "sweetalert2";
import {
	createFileAndDownload,
	//createPdfAndDownload,
	getPdf,
	mergePack,
	zipLenderPack,
	zipPack,
} from "../../../api/FormsAPI";
import DynamicModal from "../../../components/modals/DynamicModal";
import SendEmailInWizard from "../../../services/mailgunService/SendEmailInWizard";
import {
	failAlert,
	retrieveFailAlert,
	saveSuccessAlert,
} from "../../../utils/alertUtils";
import { sendMailgunEmail } from "../../../api/DealerIntegrationApi";
import { showApiError } from "../../../utils/errorRoutingUtils";
import WizardProvider from "../context/WizardContext";
import FormsReportsProvider, {
	FormsReportsContext,
} from "../context/FormsReportsContext";
import { PackRequestContext } from "../context/PackContexts";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { useFromUser } from "stores/LocalStorageHelper";
import { FormsEmailContext } from "../context/FormsEmailContext";

export const PdfRequestButton = ({
	dealID,
	inventoryID,
	accountID,
	dcReport,
	dealerTempID,
	btnSize = "md",
}) => {
	const [openRequestModal, setOpenRequestModal] = useState(false);
	const { pdfRequest, setPdfRequest } = useContext(FormsReportsContext);
	// Function to open the modal
	const toggle = () => {
		setOpenRequestModal(!openRequestModal);
	};
	// double parsing again
	const param = JSON.parse(dcReport.parameters)?.parameters || [];
	// console.log(dcReport);
	// console.log(param);
	useEffect(() => {
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dealID,
				inventoryID,
				accountID,
				dcReportID: dcReport.id,
				dcReport,
				dealerTempID,
			};
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{openRequestModal ? (
				<FormOrReportRequest
					modal={openRequestModal}
					toggle={toggle}
					parameters={dcReport.parameters}
					dcReport={dcReport}
					dealerTempID={dealerTempID}
				/>
			) : null}

			{param.length > 0 ? (
				<Button
					color="primary"
					size={btnSize}
					onClick={() => {
						// toggle the modal for getting parameters for the pdf
						toggle();
					}}
				>
					<i className="nc-icon nc-paper" />
					&nbsp;View
				</Button>
			) : (
				<Button
					color="primary"
					size={btnSize}
					onClick={() => {
						//const fileName = dcReport.title + "-" + id + ".pdf";
						formLoading("PDF");
						pdfRequest.dealerTempID = dealerTempID;
						getPdf(pdfRequest).then(
							(response) => {
								//createPdfAndDownload(response, fileName);
								displayPdf(response);
								swal.close();
							},
							(err) => {
								displayError(err);
							}
						);
					}}
				>
					<i className="nc-icon nc-paper" />
					&nbsp;View
				</Button>
			)}
		</>
	);
};

export const EmailPdfRequestButton = ({
	dealID,
	inventoryID,
	accountID,
	dcReport,
	btnSize = "md",
}) => {
	const [openModal, setOpenModal] = useState(false);
	// console.log(dcReport);
	const paramsToRender = JSON.parse(dcReport.parameters)?.parameters || [];
	const { pdfRequest, setPdfRequest } = useContext(FormsReportsContext);
	const { emailRequest, setEmailRequest } = useContext(FormsEmailContext);

	const toggle = () => {
		setOpenModal(!openModal);
	};

	// console.log(emailRequest);
	// Calls mailgun api to send the email
	const submit = () => {
		const email = {
			...emailRequest,
			pdfRequest,
		};
		formLoading("Email");
		// console.log(email);
		email.dealID = dealID;
		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	useEffect(() => {
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dealID,
				inventoryID,
				accountID,
				dcReportID: dcReport.id,
				dcReport,
			};
		});
		setEmailRequest((prevState) => {
			return { ...prevState, fileName: dcReport.title };
		});
		// eslint-disable-next-line
	}, []);

	let components = [];
	if (paramsToRender.length > 0) {
		components = [
			<FormOrReportRequest
				parameters={dcReport.parameters}
				isWizard={true}
				dcReport={dcReport}
			/>,
			<SendEmailInWizard submit={submit} />,
		];
	} else {
		components = [<SendEmailInWizard submit={submit} />];
	}
	//console.log(components);
	return (
		<>
			<Button size={btnSize} className="btn btn-info" onClick={toggle}>
				<i className="nc-icon nc-send" /> Email
			</Button>
			<WizardProvider>
				<DynamicModal
					components={components}
					toggle={toggle}
					modal={openModal}
				/>
			</WizardProvider>
		</>
	);
};

export const PdfEsignatureRequestButton = ({
	dealID,
	inventoryID,
	accountID,
	dcReport,
	dealerTempID,
	btnSize = "md",
}) => {
	const [openRequestModal, setOpenRequestModal] = useState(false);
	const { pdfRequest, setPdfRequest } = useContext(FormsReportsContext);
	// Function to open the modal
	const toggle = () => {
		setOpenRequestModal(!openRequestModal);
	};
	// double parsing again
	const param = JSON.parse(dcReport.parameters)?.parameters || [];
	// console.log(dcReport);
	// console.log(param);
	useEffect(() => {
		console.log(dealerTempID);
		setPdfRequest((prevState) => {
			return {
				...prevState,
				dealID,
				inventoryID,
				accountID,
				dcReportID: dcReport.id,
				dcReport,
				dealerTempID,
			};
		});
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{openRequestModal ? (
				<FormOrReportRequest
					modal={openRequestModal}
					toggle={toggle}
					parameters={dcReport.parameters}
					dcReport={dcReport}
					dealerTempID={dealerTempID}
				/>
			) : null}

			{param.length > 0 ? (
				<Button
					color="success"
					size={btnSize}
					onClick={() => {
						// toggle the modal for getting parameters for the pdf
						toggle();
					}}
				>
					<i className="nc-icon nc-briefcase-24" /> eSign
				</Button>
			) : (
				<Button
					color="success"
					size={btnSize}
					onClick={() => {
						//const fileName = dcReport.title + "-" + id + ".pdf";
						formLoading("eSignature");
						pdfRequest.dealerTempID = dealerTempID;
						pdfEsignature(pdfRequest).then(
							(response) => {
								swal.close();
								saveSuccessAlert(response.data.message);
							},
							(err) => {
								if (!err.isGeneralError) {
									console.log(err);
									showApiError(err, retrieveFailAlert);
								}
							}
						);
					}}
				>
					<i className="nc-icon nc-briefcase-24" /> eSign
				</Button>
			)}
		</>
	);
};

export const PackRequestButton = ({
	packObj,
	dealID,
	merge = false,
	zip = false,
	attach = false,
	eSign = false,
	color = "primary",
	btnSize = "md",
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [paramsToRender, setParamsToRender] = useState({});
	const [dcReports, setDcReports] = useState({});
	const { lenderPackRequest, setLenderPackRequest } = useContext(
		PackRequestContext
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const { inventoryID } = useCurrentDealStore(
		(state) => ({
			inventoryID: state.deal.carRecNum,
		}),
		shallow
	);

	const toggle = () => {
		setOpenModal(!openModal);
	};
	// console.log(packObj);
	// Calls mailgun api to send the email
	const submit = () => {
		if (merge) {
			formLoading("Form");
			mergePack(lenderPackRequest).then(
				(response) => {
					displayPdf(response);
					swal.close();
				},
				(err) => {
					swal.close();
					if (!err.isGeneralError) {
						console.log(err);
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else if (zip) {
			formLoading("Zip");
			zipPack(lenderPackRequest).then(
				(response) => {
					const fileName = packObj.pack.packName + "-" + dealID + ".zip";
					createFileAndDownload(response.data, fileName);
					swal.close();
				},
				(err) => {
					swal.close();
					if (!err.isGeneralError) {
						console.log(err);
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else if (attach) {
			formLoading("Zip");
			zipLenderPack(lenderPackRequest).then(
				(response) => {
					const fileName = "LenderPack-" + dealID + ".zip";
					createFileAndDownload(response.data, fileName);
					swal.close();
					toggle();
				},
				(err) => {
					swal.close();
					if (!err.isGeneralError) {
						console.log(err);
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else if (eSign) {
			formLoading("eSign");
			packEsignature(lenderPackRequest).then(
				(response) => {
					swal.close();
					saveSuccessAlert("Successfully submitted eSignature pack");
					toggle();
				},
				(err) => {
					swal.close();
					if (!err.isGeneralError) {
						console.log(err);
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		toggle();
	};

	useEffect(() => {
		let paramsList = {};
		setParamsToRender({});
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
			const formParameters = JSON.parse(form.parameters)?.parameters || [];
			if (formParameters.length > 0) {
				setParamsToRender((prevState) => {
					return { ...prevState, [form.id]: formParameters };
				});
				setDcReports((prevState) => {
					return { ...prevState, [form.id]: form };
				});
			}
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				packID: packObj.pack.id,
				dealID,
				inventoryID,
				paramsList,
			};
		});

		// eslint-disable-next-line
	}, [packObj]);

	// console.log(paramsToRender);
	if (Object.keys(paramsToRender).length > 0) {
		let components = [];
		// dynamically create requests and put them in the components array to be displayed
		Object.keys(paramsToRender).forEach((id, index, arr) => {
			const parameters = { parameters: paramsToRender[id] };
			if (index === arr.length - 1) {
				components.push(
					<FormsReportsProvider>
						<FormOrReportRequest
							parameters={JSON.stringify(parameters)}
							dcReport={dcReports[id]}
							isWizard={true}
							submit={submit}
						/>
					</FormsReportsProvider>
				);
			} else {
				components.push(
					<FormsReportsProvider>
						<FormOrReportRequest
							parameters={JSON.stringify(parameters)}
							dcReport={dcReports[id]}
							isWizard={true}
						/>
					</FormsReportsProvider>
				);
			}
		});

		return (
			<>
				<Button color={color} size={btnSize} onClick={toggle}>
					{merge ? (
						<>
							<i className="nc-icon nc-image" /> Print
						</>
					) : (
						<></>
					)}
					{zip ? (
						<>
							<i className="nc-icon nc-box" /> Zip
						</>
					) : (
						<></>
					)}
					{eSign ? (
						<>
							<i className="nc-icon nc-briefcase-24" /> eSign
						</>
					) : (
						<></>
					)}
				</Button>
				<WizardProvider>
					<DynamicModal
						modalTitle="Print Wizard"
						components={components}
						toggle={toggle}
						modal={openModal}
					/>
				</WizardProvider>
			</>
		);
	} else {
		return (
			<Button color={color} size={btnSize} onClick={submit}>
				{merge ? (
					<>
						<i className="nc-icon nc-image" /> Print
					</>
				) : (
					<></>
				)}
				{zip ? (
					<>
						<i className="nc-icon nc-box" /> Zip
					</>
				) : (
					<></>
				)}
				{eSign ? (
					<>
						<i className="nc-icon nc-briefcase-24" /> eSign
					</>
				) : (
					<></>
				)}
			</Button>
		);
	}
};

export const EmailPackRequestButton = ({ dealID, packObj, btnSize = "md" }) => {
	const [openModal, setOpenModal] = useState(false);
	const [paramsToRender, setParamsToRender] = useState({});
	const [dcReports, setDcReports] = useState({});
	const { lenderPackRequest, setLenderPackRequest } = useContext(
		PackRequestContext
	);
	const { emailRequest, setEmailRequest } = useContext(FormsEmailContext);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const toggle = () => {
		setOpenModal(!openModal);
	};
	// console.log(packObj);
	// Calls mailgun api to send the email
	const submit = () => {
		const email = {
			...emailRequest,
			lenderPackRequest,
			dealID,
		};
		formLoading("Email");
		// console.log(email);

		sendMailgunEmail(email).then(
			(res) => {
				swal.close();
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				swal.close();
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	useEffect(() => {
		let paramsList = {};
		setParamsToRender({});
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
			const formParameters = JSON.parse(form.parameters)?.parameters || [];
			if (formParameters.length > 0) {
				setParamsToRender((prevState) => {
					return { ...prevState, [form.id]: formParameters };
				});
				setDcReports((prevState) => {
					return { ...prevState, [form.id]: form };
				});
			}
		});
		setLenderPackRequest((prevState) => {
			return {
				...prevState,
				packID: packObj.pack.id,
				dealID,
				//paramsList: { ...prevState.paramsList },
				paramsList,
			};
		});
		setEmailRequest((prevState) => {
			return { ...prevState, fileName: packObj.pack.packName + "-" + dealID };
		});

		// eslint-disable-next-line
	}, [packObj]);

	// console.log(paramsToRender);
	let components = [];
	if (Object.keys(paramsToRender).length > 0) {
		// dynamically create requests and put them in the components array to be displayed
		for (const id in paramsToRender) {
			const parameters = { parameters: paramsToRender[id] };
			components.push(
				<FormsReportsProvider>
					<FormOrReportRequest
						parameters={JSON.stringify(parameters)}
						dcReport={dcReports[id]}
						isWizard={true}
					/>
				</FormsReportsProvider>
			);
		}
	}

	components.push(<SendEmailInWizard submit={submit} />);

	return (
		<>
			<Button size={btnSize} className="btn-info" onClick={toggle}>
				<i className="nc-icon nc-send" /> Emails
			</Button>
			<WizardProvider>
				<DynamicModal
					modalTitle="Email Wizard"
					components={components}
					toggle={toggle}
					modal={openModal}
				/>
			</WizardProvider>
		</>
	);
};
