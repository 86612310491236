import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Form, Row } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentSaleStore from "stores/SaleStore";
import LabelAndInput from "../../LabelAndInput";
import useCurrentDealStore from "stores/DealStore";
import ChildrenModal from "components/modals/ChildrenModal";
import formNavigationUtils from "utils/formNavigationUtils";
import SellerFeesTable from "./SellerFeesTable";
import { DealStatus } from "constants/Constants";

// Seller fees calculations are done here
const SellerFeesModal = ({ modal, toggle }) => {
	// Sale store
	const {
		dealerSmog,
		docFee,
		stateSmog,
		editDealerSmog,
		editDocFee,
		editStateSmog,
	} = useCurrentSaleStore(
		(state) => ({
			dealerSmog: state.dealerSmog,
			docFee: state.docFee,
			stateSmog: state.stateSmog,
			totalSellerFees: state.totalSellerFees,
			editDealerSmog: state.editDealerSmog,
			editDocFee: state.editDocFee,
			editStateSmog: state.editStateSmog,
		}),
		shallow
	);

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// All the editable input fields, labels, and their edit functions
	let fields = [
		{
			name: "sellerDocFee",
			value: docFee,
			edit: editDocFee,
			label: "Doc Fee",
		},
		{
			name: "sellerDealerSmog",
			value: dealerSmog,
			edit: editDealerSmog,
			label: "Dealer Smog",
		},
		{
			name: "sellerStateSmog",
			value: stateSmog,
			edit: editStateSmog,
			label: "Smog Cert",
		},
	];

	// Returns input fields and a text field that shows the total of the inputs
	return (
		<ChildrenModal
			toggle={toggle}
			modal={modal}
			size="lg"
			modalTitle="Seller Fees"
			children={
				<Form onKeyDown={formNavigationUtils}>
					<Card>
						<CardHeader>
							<CardTitle tag="h3">Manage Fees</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								{fields.map((obj) => (
									<div className="col-md-6 mb-2">
										<LabelAndInput
											name={obj.name}
											readOnly={type !== DealStatus.PENDING}
											value={obj.value}
											label={obj.label}
											onChange={obj.edit}
											buttonLabel={true}
											btnClass="btn btn-md btn-primary disabled w-100"
										/>
									</div>
								))}
							</Row>
						</CardBody>
					</Card>
					<SellerFeesTable />
				</Form>
			}
		/>
	);
};

export default SellerFeesModal;
