import React, { useMemo } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";
import FormatCurrency from "utils/formatCurrency";
import { Table } from "reactstrap";

/**
 * Generates the table columns for the "Total Commissions" section.
 */
const useColumns = () => {
	return useMemo(
		() => [
			{ Header: "", accessor: "name", width: "218px" },
			{
				Header: "Sale",
				accessor: "sale",
				Cell: ({ value }) => <FormatCurrency value={value} />,
				width: "218px",
			},
			{
				Header: "F&I",
				accessor: "fi",
				Cell: ({ value }) => <FormatCurrency value={value} />,
				width: "200px",
			},
			{
				Header: "Commissionables",
				accessor: "commissionables",
				Cell: ({ value }) => <FormatCurrency value={value} />,
				width: "200px",
			},
			{
				Header: "Deal Profit",
				accessor: "dealProfit",
				Cell: ({ value }) => <FormatCurrency value={value} />,
				width: "200px",
			},
		],
		[]
	);
};

/**
 * A table component that displays total commissions data.
 *
 * @param {object} props - Component props
 * @param {Array<object>} props.data - Array of data for total commissions table
 * @returns {JSX.Element} Table displaying total commissions data
 */
const TotalCommissionsTable = ({ data }) => {
	const columns = useColumns();

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data,
		});

	return (
		<div className="px-3" style={{ overflowX: "auto", width: "100%" }}>
			<Table
				{...getTableProps()}
				className="table table-hover table-bordered"
				style={{ minWidth: "1100px", tableLayout: "fixed" }}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps({
										style: { width: column.width },
									})}
								>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps({
											style: { width: cell.column.width },
										})}
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</Table>
		</div>
	);
};

TotalCommissionsTable.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			sale: PropTypes.number.isRequired,
			fi: PropTypes.number.isRequired,
			commissionables: PropTypes.number.isRequired,
			dealProfit: PropTypes.number.isRequired,
		})
	).isRequired,
};

export default TotalCommissionsTable;
