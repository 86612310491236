import React from "react";
import DealCard from "./DealCard";
import "assets/css/deal-card-list.css";
import SortableCardList from "components/SortableCardList";

/**
 * Sort options for the DealCardList component.
 */
const SORT_OPTIONS = [
	{ key: "fullName", order: "asc", label: "Customer Name Ascending" },
	{ key: "fullName", order: "desc", label: "Customer Name Descending" },
	{ key: "loanDate", order: "asc", label: "Loan Date Ascending" },
	{ key: "loanDate", order: "desc", label: "Loan Date Descending" },
	{ key: "dealNumber", order: "asc", label: "Deal Number Ascending" },
	{ key: "dealNumber", order: "desc", label: "Deal Number Descending" },
];

/**
 * DealCardList Component
 * @param {Object} props - Component properties
 * @param {Array} props.data - The data to display in the card list
 * @param {Function} props.onView - Callback function when a deal card is viewed
 * @param {boolean} props.focusSearch - Whether to focus on the search input on mount
 */
const DealCardList = ({ data, onView, focusSearch }) => {
	/**
	 * Renders a deal card.
	 * @param {Object} deal - The deal object to render
	 * @returns {JSX.Element} The rendered deal card
	 */
	const renderCard = (deal) => (
		<div key={deal.dealID} className="deal-card-wrapper">
			<DealCard deal={deal} onView={() => onView(deal.dealID)} />
		</div>
	);

	return (
		<SortableCardList
			data={data}
			sortOptions={SORT_OPTIONS}
			renderCard={renderCard}
			searchFields={["fullName", "dealNumber", "vehicleDetails"]}
			focusSearch={focusSearch}
			containerClassName="inv-card-container"
		/>
	);
};

export default React.memo(DealCardList);
