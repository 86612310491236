import React, { useState } from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const AftermarketBreakdown = () => {
	const { aftermarket } = useCurrentDealStore((state) => state, shallow);
	const [totalCost, setTotalCost] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const AfterMarketFees = () => {
		let tempTotalCost = 0;
		let tempTotalPrice = 0;
		const fees = aftermarket.map((key) => {
			tempTotalPrice += key.price * key.qty;
			tempTotalCost += key.cost * key.qty;
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.price * key.qty} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost * key.qty} />
					</Col>
				</Row>
			);
		});
		setTotalCost(tempTotalCost);
		setTotalPrice(tempTotalPrice);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<AfterMarketFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalPrice} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default AftermarketBreakdown;
