import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col } from "reactstrap";
import RouteOneLogo from "assets/img/routeone-logo.png";
import { useFromPartnerLink } from "../../stores/LocalStorageHelper";
import LenderModal from "../../components/modals/LenderModal";
import GenericLenderForm from "../../components/genericLenderForm/GenericLenderForm";
import { submitRouteOne } from "api/DealerIntegrationApi";

/**
 * RouteOne component handles the display and submission of RouteOne applications.
 *
 * @component
 * @param {boolean} resubmit - Indicates if the application is being resubmitted.
 * @returns {JSX.Element} The rendered component.
 */
const RouteOne = ({ resubmit }) => {
	const isRouteOneSignup = useFromPartnerLink("routeOneID");

	// Modal toggles
	const [toggleApplication, setToggleApplication] = useState(false);

	// Toggles the modal to open or close
	const toggleApplicationModal = () => setToggleApplication(!toggleApplication);

	// Button to open the application modal or redirect to sign-up page
	const applicationButton = () => (
		<Button
			size="md"
			className="w-100 btn-primary"
			onClick={() => {
				if (isRouteOneSignup) {
					toggleApplicationModal();
				} else {
					window.open(
						"https://routeone.com/LP/about/new-dealer",
						"_blank",
						"noopener,noreferrer"
					);
				}
			}}
		>
			{isRouteOneSignup ? "Submit" : "Sign Up"}
		</Button>
	);

	return (
		<Card>
			<CardBody>
				<img alt="RouteOne logo" className="lenderImg" src={RouteOneLogo} />
				<Col>
					<LenderModal
						ButtonComponent={applicationButton}
						modalSize="xl"
						modalTitle="RouteOne Application"
						toggle={toggleApplication}
						toggleModal={toggleApplicationModal}
					>
						<GenericLenderForm
							toggleApplicationModal={toggleApplicationModal}
							setIsAppSubmitted={() => {}}
							submitToLender={submitRouteOne}
						/>
					</LenderModal>
				</Col>
			</CardBody>
		</Card>
	);
};

RouteOne.propTypes = {
	resubmit: PropTypes.bool,
};

export default RouteOne;
