import React from "react";
import shallow from "zustand/shallow";
import useCurrentVehicleStore from "stores/InventoryStore";
import DetailPageHeader from "components/DetailPageHeader";

const InventoryTitle = () => {
  const { year, make, model } = useCurrentVehicleStore(
    (state) => ({
      stock: state.stockNo,
      vin: state.vin,
      year: state.year,
      make: state.make,
      model: state.model,
    }),
    shallow
  );

  const mainTitle = `${year} ${make} ${model}`;

  return <DetailPageHeader mainTitle={mainTitle} />;
};

export default InventoryTitle;
