const useCarfaxUrl = () => {
	const userString = sessionStorage.getItem("user");
	let user = JSON.parse(userString);
	const carfaxState = user["cfState"];

	const clientId = encodeURIComponent("13Q0IA2IIW6e6JfgrpI9mossgbjbesKC");
	const callBackUrl = encodeURIComponent(
		// for local testing only
		// "http://localhost:3000/inventory-list"
		"https://dealerclick.app/inventory-list"
	);
	const audience = encodeURIComponent("https://connect.carfax.com");
	const scope = encodeURIComponent("offline_access");
	const resType = encodeURIComponent("code");

	// prettier-ignore
	const carfaxUrl =
		"https://auth.carfax.com/authorize?" + 
		"client_id=" + clientId + 
		"&redirect_uri=" + callBackUrl + 
		"&state=" + carfaxState + 
		"&response_type=" + resType + 
		"&audience=" + audience + 
		"&scope=" + scope;
	console.log(carfaxUrl);

	return carfaxUrl;
};

export default useCarfaxUrl;
