import React from "react";

import AllValuesContent from "./AllValuesContent";

const PrintAllValues = React.forwardRef((_props, ref) => {
	return (
		<div ref={ref} className="all-values-margin">
			<AllValuesContent />
		</div>
	);
});

export default PrintAllValues;
