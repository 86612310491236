import React, { createContext, useState } from "react";

// Context
export const KbbContext = createContext();

// Provider to pass all the data to children components
export const KbbValuesProvider = (props) => {
	// Local state
	const [condition, setCondition] = useState("Good");
	const [dcTableKbb, setDcTableKbb] = useState({});
	const [dcTableKbbEquipment, setDcTableKbbEquipment] = useState([]);
	const [dcTableKbbValues, setDcTableKbbValues] = useState([]);
	const [dcTableKbbOptionsPrices, setDcTableKbbOptionsPrices] = useState([]);

	const setDcKbbObj = (kbb) => {
		setDcTableKbb(kbb.dcTableKbb);
		setDcTableKbbEquipment(kbb.dcTableKbbEquipment);
		setDcTableKbbValues(kbb.dcTableKbbValues);
		setDcTableKbbOptionsPrices(kbb.dcTableKbbOptionsPrices);
	};
	const getDcKbbObj = () => {
		return {
			dcTableKbb,
			dcTableKbbEquipment,
			dcTableKbbValues,
			dcTableKbbOptionsPrices,
		};
	};

	const reset = () => {
		setDcTableKbb({});
		setDcTableKbbEquipment([]);
		setDcTableKbbValues([]);
		setDcTableKbbOptionsPrices([]);
	};

	return (
		<KbbContext.Provider
			value={{
				condition,
				setCondition,

				dcTableKbbEquipment,
				setDcTableKbbEquipment,
				dcTableKbbValues,
				setDcTableKbbValues,
				dcTableKbb,
				setDcTableKbb,
				dcTableKbbOptionsPrices,
				setDcTableKbbOptionsPrices,
				setDcKbbObj,
				getDcKbbObj,
				reset,
			}}
		>
			{props.children}
		</KbbContext.Provider>
	);
};
