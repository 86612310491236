import React from "react";
// import { useHistory } from "react-router-dom";
// import { TemplateVariableProvider } from "context/TemplateVariableContext";
// import InitializeTemplateVars from "utils/initializeTemplateVars";
import CustomerListData from "./CustomerListData";
// import { MessageZon } from "../../../../constants/Constants";

/**
 * CustomerListPage is a component that renders the customer list.
 * It supports different modes like 'isDeal' and uses context providers for template variables.
 */
const CustomerListPage = ({
	isDeal = false,
	toggle,
	editCustomer,
	editAddress,
	editMailAddress,
	editRecNum,
}) => {
	// const history = useHistory();

	return (
		<>
			{/* TemplateVariableProvider and InitializeTemplateVars are used to set up context for customer data */}
			{/* <TemplateVariableProvider>
				<InitializeTemplateVars messageZone={MessageZone.CUSTOMER}> */}
			{/* CustomerListData component is responsible for fetching and displaying customer data */}
			<CustomerListData
				isDeal={isDeal}
				toggle={toggle}
				editCustomer={editCustomer}
				editAddress={editAddress}
				editMailAddress={editMailAddress}
				editRecNum={editRecNum}
				//TODO need to edit history when clicking on cards in list data header
				//initStatus={history.location.state?.status}
			/>
			{/* </InitializeTemplateVars>
			</TemplateVariableProvider> */}
		</>
	);
};

export default CustomerListPage;
