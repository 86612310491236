import React, { useState, useCallback, useEffect } from "react";
import useCurrentDealStore from "stores/DealStore";
import { useFromUser } from "stores/LocalStorageHelper";

import { Button, Form, Label, FormGroup } from "reactstrap";
import InputElement, {
	IntegerInputElement,
	CurrencyInputElement,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import Select from "react-select";
import dayjs from "dayjs";
import { getVehicleDetails } from "api/InventoryAPI";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";

const ServiceContractForm = (props) => {
	const { currentIndex, setCurrentIndex, toggle, vendorOption } = props;
	const {
		warrantyList,
		editWarrantyList,
		carRecNum,
		dealRecNum,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			warrantyList: state.warranty,
			editWarrantyList: state.editWarranty,
			carRecNum: state.deal.carRecNum,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// state for vendor selector
	const [amount, setamount] = useState(0.0);
	const [cost, setcost] = useState(0.0);
	const [coRecNum, setcoRecNum] = useState(0);
	const [term, setterm] = useState(0);
	const [miles, setmiles] = useState(0);
	const [deductible, setdeductible] = useState(0.0);
	const [contractNo, setcontractNo] = useState("");
	const [expMiles, setexpMiles] = useState(0);
	const [expires, setexpires] = useState("");
	const [description, setdescription] = useState("");

	const [currentMiles, setcurrentMiles] = useState(0);

	const clearFields = () => {
		console.log(warrantyList);
		setamount(0.0);
		setcost(0.0);
		setcoRecNum(0);
		setterm(0);
		setmiles(0);
		setdeductible(0.0);
		setcontractNo("");
		setexpMiles(0);
		setexpires("");
		setdescription("");
		setCurrentIndex(warrantyList.length);
	};

	const addServCon = () => {
		console.log(warrantyList);
		const getID = () => {
			if (warrantyList[currentIndex] === undefined) {
				return null;
			} else if (warrantyList[currentIndex].ID === undefined) {
				return null;
			} else {
				return warrantyList[currentIndex].ID;
			}
		};
		const ID = getID();
		// this needs to match template
		const newWarranty = {
			amount: parseFloat(amount),
			term: parseInt(term),
			miles: parseInt(miles),
			deductible: parseFloat(deductible),
			contractNo,
			expMiles: parseInt(expMiles),
			expires,
			cost: parseFloat(cost),
			coRecNum: parseInt(coRecNum),
			description,
			currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
		};
		const warrList = () => {
			let newObj = [...warrantyList];
			newObj[currentIndex] = newWarranty;
			return newObj;
		};
		const newWarrantyObj = warrList();
		editWarrantyList(newWarrantyObj);
		clearFields();
		calculate();
		toggle();
	};

	useEffect(() => {
		setamount(warrantyList[currentIndex]?.amount || "");
		setterm(warrantyList[currentIndex]?.term || "");
		setmiles(warrantyList[currentIndex]?.miles || "");
		setdeductible(warrantyList[currentIndex]?.deductible || "");
		setcontractNo(warrantyList[currentIndex]?.contractNo || "");
		setexpMiles(warrantyList[currentIndex]?.expMiles || "");
		setexpires(warrantyList[currentIndex]?.expires || "");
		setcost(warrantyList[currentIndex]?.cost || "");
		setcoRecNum(warrantyList[currentIndex]?.coRecNum || "");
		setdescription(warrantyList[currentIndex]?.description || "");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	const calcExpires = useCallback(() => {
		const today = dayjs().add(term, "month").format("YYYY-MM-DD");
		setexpires(today);
	}, [term, setexpires]);

	const getMiles = () => {
		if (carRecNum != null) {
			getVehicleDetails(carRecNum).then(
				(res) => {
					console.log(res);
					setcurrentMiles(res.data.content.odometerIn);
				},
				(error) => {
					console.log(error);
					setcurrentMiles(0);
				}
			);
		}
	};

	// TODO we shouldnt have to do this
	useEffect(() => {
		getMiles();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		calcExpires();
	}, [term, setexpires, calcExpires]);

	useEffect(() => {
		setexpMiles(parseInt(miles) + parseInt(currentMiles));
	}, [miles, currentMiles]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is MM/DD/YYYY",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		amount: yup.string().required().max(50),
		cost: yup.string().required().max(50),
		term: yup.string().required(),
		miles: yup.string().required(),
		deductible: yup.string().required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Service Contract"
						inputComponents={[
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={amount}
								onChange={setamount}
								name="amount"
								label="Amount"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={cost}
								onChange={setcost}
								name="cost"
								label="Cost"
								type="number"
							/>,
							<FormGroup className="col-md-9">
								<Label>Company</Label>
								<Select
									className="mb-3"
									isDisabled={type !== DealStatus.PENDING}
									value={{
										value: coRecNum,
										label: vendorOption.filter((e) => e.value === coRecNum)[0]
											?.label,
									}}
									options={vendorOption}
									onChange={(e) => {
										setcoRecNum(e.value);
									}}
								/>
							</FormGroup>,
							<IntegerInputElement
								readOnly={type !== DealStatus.PENDING}
								value={term}
								onChange={setterm}
								name="term"
								label="Term"
								type="number"
							/>,
							<IntegerInputElement
								readOnly={type !== DealStatus.PENDING}
								value={miles}
								onChange={setmiles}
								name="miles"
								label="Miles"
								type="number"
							/>,
							<CurrencyInputElement
								readOnly={type !== DealStatus.PENDING}
								value={deductible}
								onChange={setdeductible}
								name="deductible"
								label="Deductible"
								type="number"
							/>,
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={contractNo}
								onChange={(e) => {
									setcontractNo(e.target.value);
								}}
								name="contractNo"
								label="Contract #"
								type="text"
							/>,
							<IntegerInputElement
								readOnly={type !== DealStatus.PENDING}
								value={expMiles}
								onChange={setexpMiles}
								name="expMiles"
								label="Miles"
								type="number"
							/>,
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={description}
								onChange={(e) => {
									setdescription(e.target.value);
								}}
								name="description"
								label="Description"
								type="text"
								colSize="col-12"
							/>,
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={expires}
								onChange={(e) => {
									setexpires(e.target.value);
								}}
								name="expires"
								label="Exp Date"
								type="date"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="success"
									size=""
									onClick={methods.handleSubmit(addServCon)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default ServiceContractForm;
