import React from "react";
import PropTypes from "prop-types";

/**
 * Loading component to display a loading spinner.
 *
 * @param {Object} props - Component props
 * @param {string} props.containerClassName - Additional class names for the container
 * @param {Object} props.containerStyle - Additional styles for the container
 * @param {string} props.spinnerClassName - Additional class names for the spinner
 * @param {Object} props.spinnerStyle - Additional styles for the spinner
 * @returns {JSX.Element} The rendered component
 */
const Loading = ({
	containerClassName = "",
	containerStyle = {},
	spinnerClassName = "text-warning",
	spinnerStyle = {},
}) => {
	return (
		<div
			className={`text-center ${containerClassName}`}
			style={{
				width: "100%",
				height: "250px",
				display: "flex",
				paddingBottom: "50px",
				...containerStyle,
			}}
		>
			<div
				className={`spinner-grow ${spinnerClassName} m-auto`}
				style={spinnerStyle}
				role="status"
			></div>
		</div>
	);
};

Loading.propTypes = {
	containerClassName: PropTypes.string,
	containerStyle: PropTypes.object,
	spinnerClassName: PropTypes.string,
	spinnerStyle: PropTypes.object,
};

export default Loading;
