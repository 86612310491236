import React from "react";

import { HorizontalInputElement } from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const DigitzsAgreement = ({
	personalInfo,
	check,
	agreementInfo,
	setAgreementInfo,
	setCurrentForm,
	nextForm,
	previousForm,
}) => {
	const methods = useFormContext();

	// If no errors, goes to the next page
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}

		let fullName = String(
			personalInfo.firstName + " " + personalInfo.lastName
		).trim();

		if (fullName !== String(agreementInfo.signature).trim()) {
			let isValidName =
				personalInfo.firstName !== "" &&
				personalInfo.lastName !== "" &&
				personalInfo.firstName != null &&
				personalInfo.lastName != null;

			if (isValidName) {
				methods.setError("signature", {
					message: "Must match first and last name: " + fullName,
				});
			} else {
				methods.setError("signature", {
					message: "Names are empty",
				});
			}

			return;
		}

		setCurrentForm(nextForm);
	};

	return (
		<div className="row">
			<div className="d-flex justify-content-center col-12 mb-3">
				<h3>Digitzs Agreement</h3>
			</div>
			<iframe
				className="col-12"
				src="https://ma.digitzs.com"
				title="Digitzs Agreement"
			/>
			<div className="col-12 my-3">
				<input
					defaultChecked={check}
					ref={methods.register}
					className="mr-2"
					value={check}
					onChange={() => setAgreementInfo.setChecked(!check)}
					type="checkbox"
					id="agreementBox"
					name="agreementBox"
					invalid={methods.errors["agreementBox"] ? 1 : 0}
				/>
				<label htmlFor="agreementBox">
					I have read this Agreement and agree to the terms and conditions.
				</label>
				<ErrorMessage
					name="agreementBox"
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			</div>
			<div className="mt-3 col-12">
				<HorizontalInputElement
					{...methods}
					value={agreementInfo.signature}
					type="text"
					onChange={(e) => setAgreementInfo.setSignature(e.target.value)}
					name="signature"
					label="Signature"
					placeholder="Enter full name"
				/>
			</div>
			<button
				className="previous-button"
				onClick={() => setCurrentForm(previousForm)}
			>
				Previous
			</button>
			<button className="next-with-previous" onClick={handleNext}>
				Next
			</button>
		</div>
	);
};

export default DigitzsAgreement;
