import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardBody,
	CardTitle,
	CardText,
	CardImg,
	Button,
	Row,
	Col,
} from "reactstrap";
import Camera from "assets/img/placeholder.png";
import "assets/css/inv-card-styles.css";

/**
 * InventoryCard component to display a single vehicle's details in a card format.
 *
 * @param {Object} props - Component props
 * @param {Object} props.vehicle - The vehicle data to display
 * @param {Function} props.onEdit - The function to call when the card is clicked for editing
 * @param {Function} props.onDeal - The function to call when the "Deal" button is clicked
 * @returns {JSX.Element} The rendered component
 */
const InventoryCard = ({ vehicle, onEdit, onDeal }) => {
	/**
	 * Handles the card click to trigger the onEdit function.
	 */
	const handleCardClick = () => {
		onEdit(vehicle);
	};

	/**
	 * Handles the button click to trigger the specified action.
	 *
	 * @param {Event} e - The click event
	 * @param {Function} action - The action to trigger
	 */
	const handleButtonClick = (e, action) => {
		e.stopPropagation();
		action(vehicle);
	};

	return (
		<Card
			onClick={handleCardClick}
			style={{
				width: "100%",
				borderRadius: "8px",
				cursor: "pointer",
			}}
		>
			<CardBody>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "1rem",
					}}
				>
					<Button
						className="h6 btn-round btn-md btn btn-outline-secondary disabled"
						style={{ marginBottom: 0 }}
					>
						<strong>#</strong>
						{vehicle.stockNo}
					</Button>
					<Button
						className="h6 btn-round btn-md btn btn-outline-secondary disabled"
						style={{ marginBottom: 0 }}
					>
						{vehicle.vin}
					</Button>
				</div>
				<Row>
					<Col xs="4" sm="4" md="4" className="p-0">
						<CardImg
							top
							src={vehicle.thumbnailURL || Camera}
							alt="Vehicle Image"
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = Camera;
							}}
							style={{
								height: "4.5rem",
								objectFit: "cover",
								marginBottom: "1rem",
							}}
						/>
					</Col>
					<Col xs="8" sm="8" md="8" style={{ minHeight: "100px" }}>
						<CardTitle tag="h4" className="m-0 inv-card-title">
							{vehicle.vehicle}
						</CardTitle>
						<CardText style={{ fontSize: "1rem" }}>
							{vehicle.askingPrice === "$NaN" ? "N/A" : vehicle.askingPrice}
						</CardText>
						{vehicle.trim && (
							<CardText>
								<strong>Trim:</strong> {vehicle.trim}
							</CardText>
						)}
					</Col>
				</Row>
				<hr className="mt-2" />
				<div className="pb-0 mt-2">
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							className="btn-sm"
							color="primary"
							onClick={(e) => handleButtonClick(e, onEdit)}
						>
							<i className="nc-icon nc-paper" />
							&nbsp;View
						</Button>
						{(vehicle.status === "Available" ||
							vehicle.status === "Pending") && (
							<Button
								className="btn-sm ml-1"
								color="success"
								onClick={(e) => handleButtonClick(e, onDeal)}
							>
								<i className="nc-icon nc-briefcase-24"></i>&nbsp;Deal
							</Button>
						)}
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

InventoryCard.propTypes = {
	vehicle: PropTypes.shape({
		stockNo: PropTypes.string.isRequired,
		vin: PropTypes.string.isRequired,
		thumbnailURL: PropTypes.string,
		vehicle: PropTypes.string.isRequired,
		askingPrice: PropTypes.string,
		trim: PropTypes.string,
		status: PropTypes.string.isRequired,
	}).isRequired,
	onEdit: PropTypes.func.isRequired,
	onDeal: PropTypes.func.isRequired,
};

export default InventoryCard;
