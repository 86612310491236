import React from "react";
import { Form } from "reactstrap";

import { vehicleStatus } from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import InputElement, {
	CheckBoxElement,
} from "../../../../forms/components/InputElement";
import { useFormContext } from "react-hook-form";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";

const InventoryGpsInformation = () => {
	// Vehicle store
	const {
		status,
		hasGPS,
		gps_ID,
		gpsCompany,
		gpsWebsite,
		gps_ID2,
		gpsCompany2,
		gpsWebsite2,
		hasGPS2,
		editHasGPS,
		editGps_ID,
		editGpsCompany,
		edirGpsWebsite,
		editGps_ID2,
		editGpsCompany2,
		editGpsWebsite2,
		editHasGPS2,
	} = useCurrentVehicleStore(
		(state) => ({
			status: state.status,
			hasGPS: state.hasGPS,
			gps_ID: state.gps_ID,
			gpsCompany: state.gpsCompany,
			gpsWebsite: state.gpsWebsite,
			gps_ID2: state.gps_ID2,
			gpsCompany2: state.gpsCompany2,
			gpsWebsite2: state.gpsWebsite2,
			hasGPS2: state.hasGPS2,
			editHasGPS: state.editHasGPS,
			editGps_ID: state.editGps_ID,
			editGpsCompany: state.editGpsCompany,
			edirGpsWebsite: state.editGpsWebsite,
			editGps_ID2: state.editGps_ID2,
			editGpsCompany2: state.editGpsCompany2,
			editGpsWebsite2: state.editGpsWebsite2,
			editHasGPS2: state.editHasGPS2,
		}),
		shallow
	);

	const methods = useFormContext();
	return (
		<>
			<div>
				<Form onKeyDown={formNavigationUtils}>
					<InputWrapper
						formTitle="GPS Information"
						inputComponents={[
							<>
								<h4 className="col-12 m-0">
									Primary GPS:
								</h4>
								<CheckBoxElement
									{...methods}
									disabled={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									checked={hasGPS}
									onChange={() => editHasGPS(!hasGPS)}
									name="hasGps"
									label="Installed and Active"
									labelClass="pl-4 required"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS
									}
									value={gps_ID}
									onChange={(e) => editGps_ID(e.target.value)}
									name="gps_ID"
									label="GPS ID"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS
									}
									value={gpsCompany}
									onChange={(e) => editGpsCompany(e.target.value)}
									name="gpsCompany"
									label="Company"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS
									}
									value={gpsWebsite}
									onChange={(e) => edirGpsWebsite(e.target.value)}
									name="gpsWebSite"
									label="WebSite"
									type="text"
								/>
								<h4 className="col-12 m-0">
									Secondary GPS:
								</h4>
								<CheckBoxElement
									{...methods}
									disabled={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									checked={hasGPS2}
									onChange={() => editHasGPS2(!hasGPS2)}
									name="hasGps2"
									label="Installed and Active"
									labelClass="pl-4 required"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS2
									}
									value={gps_ID2}
									onChange={(e) => editGps_ID2(e.target.value)}
									name="gps_ID2"
									label="GPS ID"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS2
									}
									value={gpsCompany2}
									onChange={(e) => editGpsCompany2(e.target.value)}
									name="gpsCompany2"
									label="Company"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED ||
										!hasGPS2
									}
									value={gpsWebsite2}
									onChange={(e) => editGpsWebsite2(e.target.value)}
									name="gpsWebSite2"
									label="WebSite"
									type="text"
								/>
							</>
						]}
					/>
				</Form>
			</div>
		</>
	);
};

export default InventoryGpsInformation;
