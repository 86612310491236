import React, { useEffect, useState } from "react";
import {
	Input,
	Row,
	Card,
	CardHeader,
	Label,
	CardText,
	CardBody,
} from "reactstrap";
import NumberFormat from "react-number-format";
import { DealStatus } from "constants/Constants";
import useCurrentVehicleStore from "stores/InventoryStore";
import shallow from "zustand/shallow";

const InventoryTotalCostDisplay = () => {
	const packID = "costDisplayPack";

	const [packPercent, setPackPercent] = useState(0);
	const [packUsed, setPackUsed] = useState(0);
	const inventorySettings = JSON.parse(
		sessionStorage.getItem("inventoryDefaults")
	);

	const invDefault = () => {
		setPackPercent(inventorySettings.packPercent);
		setPackUsed(inventorySettings.packUsed);
	};

	// Vehicle store
	const {
		status,
		totalRecon,
		totalCost,
		flooring,
		totalOtherCost,
		pack,
		cost,
		editPack,
		editCost,
		editTotalCost,
	} = useCurrentVehicleStore(
		(state) => ({
			status: state.status,
			totalRecon: state.totalRecon,
			totalCost: state.totalCost,
			flooring: state.flooring,
			totalOtherCost: state.totalOtherCost,
			pack: state.pack,
			cost: state.cost,
			editPack: state.editPack,
			editCost: state.editCost,
			editTotalCost: state.editTotalCost,
		}),
		shallow
	);
	useEffect(() => {
		editTotalCost(
			parseFloat(cost) +
				parseFloat(pack) +
				totalOtherCost +
				totalRecon +
				flooring
		);

		// eslint-disable-next-line
	}, [pack, cost]);
	useEffect(() => {
		invDefault();
		// eslint-disable-next-line
	}, []);

	console.log(flooring);
	console.log(
		parseFloat(cost),
		parseFloat(pack),
		totalOtherCost,
		totalRecon,
		flooring
	);
	console.log(totalCost);

	return (
		<Card className="mt-2">
			<CardHeader>
				<h3>Costs</h3>
			</CardHeader>
			<CardBody className="py-0 my-0">
				<div className="d-flex align-items-center justify-content-center">
					<span style={{ fontSize: "1.4em" }}>Total:&nbsp;</span>
					<NumberFormat
						className="text-danger h3"
						value={totalCost}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
						customInput={Input}
						readOnly
					/>
				</div>
				<hr className="mx-auto mb-3 w-25" />
				<Row style={{ justifyContent: "center", gap: "6px" }}>
					<div>
						<Label>Vehicle Cost</Label>
						<NumberFormat
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									const element = document.getElementById(packID);

									e.preventDefault();
									element.focus();
									element.select();
								}
							}}
							readOnly={
								status === DealStatus.SOLD || status === DealStatus.DELETED
							}
							value={cost}
							name="cost"
							className="form-control"
							style={{ width: "110px" }}
							thousandSeparator={true}
							decimalScale={2}
							prefix={"$"}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							onValueChange={(values) => {
								if (values.value === "") {
									editCost(0);
								} else {
									editCost(values.value);
								}
								editPack(packUsed + values.value * packPercent);
							}}
						/>
					</div>
					<span role="img" aria-label="plus sign emoji" className="plus-sign">
						➕
					</span>
					<div>
						<Label>Pack</Label>
						<NumberFormat
							readOnly={
								status === DealStatus.SOLD || status === DealStatus.DELETED
							}
							id={packID}
							className="form-control "
							value={pack}
							name="pack"
							style={{ width: "110px" }}
							thousandSeparator={true}
							decimalScale={2}
							prefix={"$"}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							onValueChange={(values) => {
								if (values.value === "") {
									editPack(0);
								} else {
									editPack(values.value);
								}
							}}
						/>
					</div>
					<span role="img" aria-label="plus sign emoji" className="plus-sign">
						➕
					</span>
					<div>
						<Label>Total flooring</Label>
						<CardText>
							<NumberFormat
								className="form-control  "
								value={flooring}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								displayType="text"
								customInput={Input}
								readOnly
							/>
						</CardText>
					</div>
					<span role="img" aria-label="plus sign emoji" className="plus-sign">
						➕
					</span>
					<div>
						<Label>Other Costs</Label>
						<CardText>
							<NumberFormat
								className="form-control  "
								value={totalOtherCost}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								displayType="text"
								customInput={Input}
								readOnly
							/>
						</CardText>
					</div>
					<span role="img" aria-label="plus sign emoji" className="plus-sign">
						➕
					</span>
					<div>
						<Label>Total Recon</Label>
						<CardText>
							<NumberFormat
								className="form-control  "
								value={totalRecon}
								thousandSeparator={true}
								decimalScale={2}
								fixedDecimalScale={true}
								prefix={"$"}
								isNumericString={true}
								displayType="text"
								customInput={Input}
								readOnly
							/>
						</CardText>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};

export default InventoryTotalCostDisplay;
