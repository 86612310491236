import React from "react";

import { useMultiImageData } from "./MultiImageContext";

// Component containing error messages for file(s) that couldn't be
// selected to be uploaded
const MultiImageErrors = () => {
	// Multi image context
	const { uploadErrors } = useMultiImageData();

	return (
		<>
			{Object.keys(uploadErrors).length > 0 && (
				<div className="multi-image-errors col-12">
					<div>
						<p className="multi-image-error-title">
							Some Files Could Not Be Added:
						</p>
						<ul className="mt-3 multi-image-error-message">
							{Object.entries(uploadErrors).map((err) => {
								const errorFor = err[0];
								const errors = err[1];

								// Markup for files with 1 error
								if (errors.length === 1) {
									return (
										<li key={errorFor}>
											<span className="multi-image-bold-text">
												{errorFor}:{" "}
											</span>
											{errors}
										</li>
									);
								}

								// Markup for files with more than 1 error
								if (errors.length > 1) {
									return (
										<li key={errorFor}>
											<span className="multi-image-bold-text">{errorFor}</span>
											<ul>
												{errors.map((er, i) => (
													<li key={`${errorFor}_${i}`}>{er}</li>
												))}
											</ul>
										</li>
									);
								}

								return null;
							})}
						</ul>
					</div>
				</div>
			)}
		</>
	);
};

export default MultiImageErrors;
