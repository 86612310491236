import React, { useRef } from "react";
import { Button } from "reactstrap";

import PrintAllValues from "./PrintAllValues";

import { useReactToPrint } from "react-to-print";

const AllValuesWrapper = () => {
	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: "KBB Book Sheet",
	});

	return (
		<div>
			<div style={{ display: "none" }}>
				<PrintAllValues ref={componentRef} />
			</div>
			<Button className="btn btn-md btn-link" onClick={handlePrint}>
				<i className="nc-icon nc-paper"></i> Print
			</Button>
		</div>
	);
};

export default AllValuesWrapper;
