import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";
import { KbbContext } from "./KbbContext";
import NumberFormat from "react-number-format";
import kbbLogo from "assets/img/KbbLogo.png";

/**
 * ValueCard component to display a card with a title and value.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the value card.
 * @param {number|string} props.value - The value to be displayed in the card.
 * @returns {JSX.Element} The rendered ValueCard component.
 */
const ValueCard = ({ title, value }) => (
  <Col style={{ padding: "0", width: "fit-content" }}>
    <Card className="mt-0 py-3 text-center">
      <h3>{title}</h3>
      <hr className="mb-2 mx-auto w-75" />
      <Col className="ml-auto mr-auto col-md-8 text-center d-flex d-flex justify-content-center">
        <img
          src={kbbLogo}
          alt="KBB Logo"
          className="img-fluid"
          style={{ width: "50px" }}
        />
        <NumberFormat
          className="h3 text-center text-success"
          value={value}
          thousandSeparator={true}
          decimalScale={0}
          fixedDecimalScale={true}
          prefix={"$"}
          isNumericString={true}
          displayType="text"
        />
      </Col>
    </Card>
  </Col>
);

ValueCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

/**
 * KbbValues component to display KBB lending and typical listing values.
 * 
 * @returns {JSX.Element} The rendered KbbValues component.
 */
const KbbValues = () => {
  // KBB context to get the table KBB values
  const { dcTableKbbValues } = useContext(KbbContext);

  // Retrieve lending and typical listing values from the context
  const lending =
    dcTableKbbValues.find((obj) => obj.priceTypeId === 1)?.configuredValue || "";
  const typicalListing =
    dcTableKbbValues.find((obj) => obj.priceTypeId === 2)?.configuredValue || "";

  return (
    <Row className="mb-3" style={{ gap: "12px" }}>
      <ValueCard title="Lending Value" value={lending} />
      <ValueCard title="Listing Price" value={typicalListing} />
    </Row>
  );
};

export default KbbValues;
