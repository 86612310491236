import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, CardText, Col, Row } from "reactstrap";
import shallow from "zustand/shallow";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentRecapStore from "stores/RecapStore";
import { CurrencyInput } from "features/forms/components/InputElement";
import ReserveCalculatorModal from "./ReserveCalculatorModal";

/**
 * ReserveCalculator component to display and manage the dealer reserve.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @param {object} props - Component props
 * @param {boolean} [props.disabled=false] - Flag to disable the input
 * @returns {JSX.Element} The rendered component
 */
const ReserveCalculator = ({ disabled = false }) => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	const { reserve, editReserve } = useCurrentRecapStore(
		(state) => ({
			reserve: state.reserve,
			editReserve: state.editReserve,
		}),
		shallow
	);

	return (
		<Row>
			<Col md="6">
				<CardText>
					<Button
						className="active px-1 btn-round btn-md btn btn-outline-secondary w-100 my-0"
						onClick={toggle}
					>
						Dealer Reserve
					</Button>
				</CardText>
				<ChildrenModal
					modal={open}
					toggle={toggle}
					size="s"
					modalTitle="Dealer Reserve"
					children={<ReserveCalculatorModal />}
				/>
			</Col>
			<Col md="6">
				<CurrencyInput
					disabled={disabled}
					readOnly={disabled}
					value={reserve}
					onChange={editReserve}
					disableValidation
					wholeRow
				/>
			</Col>
		</Row>
	);
};

ReserveCalculator.propTypes = {
	disabled: PropTypes.bool,
};

export default ReserveCalculator;
