import React from "react";
import PropTypes from "prop-types";
import { Card, Col } from "reactstrap";
import CardContent from "components/card/CardContent";
import CardContentBottom from "components/card/CardContentBottom";
import "assets/css/page-header.css";

/**
 * PageHeader component to display a header with a title and cards.
 *
 * @param {Object} props - Component props
 * @param {string} props.titlePrefix - The prefix for the title
 * @param {string} props.title - The title to display
 * @param {Array} props.cards - The array of card data to display
 * @returns {JSX.Element} The rendered component
 */
const PageHeader = ({ titlePrefix, title, cards }) => {
	/**
	 * Renders a single card.
	 *
	 * @param {Object} cardData - The data for the card
	 * @returns {JSX.Element} The rendered card
	 */
	const renderCard = (cardData) => (
		<Card
			onClick={cardData.onCardClick}
			className={`card-stats ${cardData.isActive ? "card-stats-active" : ""}`}
			style={{ minWidth: "200px", maxWidth: "500px" }}
		>
			<CardContent
				iconClass={`icon-big text-center nc-icon ${cardData.iconClass}`}
				title1={cardData.title}
				title2={cardData.count}
				check={cardData.isActive}
				onClick={cardData.onCardClick}
			/>
			<CardContentBottom
				routePath={cardData.routePath}
				iconClass="nc-icon nc-minimal-down"
				iconText={cardData.iconText}
			/>
		</Card>
	);

	return (
		<Col>
			{title && (
				<h3 className="text-center py-3">
					{titlePrefix} <span className="noMobile">|</span>{" "}
					<span style={{ fontSize: "20px" }} className="noMobile">
						{title}
					</span>
				</h3>
			)}
			<div className="header-card-container">
				{cards.map((card, index) => (
					<div key={index} className="header-card-wrapper">
						{renderCard(card)}
					</div>
				))}
			</div>
			<hr className="my-3 mx-auto w-75" />
		</Col>
	);
};

PageHeader.propTypes = {
	titlePrefix: PropTypes.string,
	title: PropTypes.string.isRequired,
	cards: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			count: PropTypes.number,
			iconClass: PropTypes.string,
			iconText: PropTypes.string,
			routePath: PropTypes.string,
			isActive: PropTypes.bool,
			onCardClick: PropTypes.func,
		})
	).isRequired,
};

export default PageHeader;
