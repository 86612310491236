import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

import ReactTable from "./ReactTable";

import NumberFormat from "react-number-format";
import FilterColumn from "../../utils/filterColumn";
const ContactTable = ({ data, setMessageCustomers, onRowClick }) => {
	const phoneCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.homePhone != null && (
					<a
						href={"tel:" + props.row.original.homePhone}
						onClick={(e) => e.stopPropagation()}
					>
						<NumberFormat
							value={props.row.original.homePhone}
							format="+1 (###) ###-####"
							isNumericString={true}
							displayType="text"
						/>
					</a>
				)}
			</span>
		</div>
	);

	const emailCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<a
					onClick={(e) => e.stopPropagation()}
					href={"mailto:" + props.row.original.email}
				>
					{props.row.original.email}
				</a>
			</span>
		</div>
	);
	const nameCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.customerType === 1 ? (
					<span style={{ color: "blue" }}>{props.row.original.name} </span>
				) : (
					props.row.original.name
				)}
			</span>
		</div>
	);
	const licenseCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.license}</span>
		</div>
	);
	const typeStatusCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.typeStatus}
			</span>
		</div>
	);

	return (
		<Row>
			<Col md="12">
				<Card>
					<CardBody>
						<ReactTable
							onRowClick={onRowClick}
							checkboxColumn={setMessageCustomers != null}
							setSelectedRows={setMessageCustomers}
							data={data}
							columns={[
								{
									Header: "Name",
									accessor: "name",
									className: "customer-name",
									Cell: nameCell,
								},
								{
									Header: "Phone",
									accessor: "homePhone",
									Cell: phoneCell,
									className: "customer-phone",
								},
								{
									Header: "Email",
									accessor: "email",
									Cell: emailCell,
									className: "customer-email",
								},
								{
									Header: "License",
									accessor: "license",
									className: "customer-license",
									Cell: licenseCell,
								},
								{
									Header: "type",
									accessor: "typeStatus",
									Filter: FilterColumn,
									filter: "includes",
									Cell: typeStatusCell,
								},
								{
									Header: "Actions",
									accessor: "Actions",
									disableFilters: true,
								},
							]}
							/*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
							className="-striped -highlight primary-pagination"
						/>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default ContactTable;
