import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, Input, Label, Button } from "reactstrap";
import DealInventoryHeader from "../../inventory/DealInventoryHeader";
import Sale from "../../sale/Sale";
import Summary from "../../summary/Summary";
import TermsTable from "../../paymentOptions/TermsTable";
import { DealStatus, SaleType } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";
import DealSalesmanDropDown from "../../dealDetails/DealSalesmanDropdown";
import LenderDetail from "../../dealDetails/LenderDetail";
import DealBuyerSwitchButton from "features/deal/components/DealBuyerSwitchButton";

/**
 * Component for displaying the content of a deal, including buyer info, deal details, and related sections.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
export const DealContent = () => {
	// State from deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			carRecNum: state.deal.carRecNum,
			type: state.deal.type,
		}),
		shallow
	);

	// State from sale store
	const {
		loanDate,
		editLoanDate,
		dealNumber,
		editDealNumber,
		saleType,
	} = useCurrentSaleStore(
		(state) => ({
			loanDate: state.loanDate,
			editLoanDate: state.editLoanDate,
			dealNumber: state.dealNumber,
			editDealNumber: state.editDealNumber,
			saleType: state.saleType,
		}),
		shallow
	);

	const typeOfSale = SaleType[saleType]?.label;

	return (
		<>
			<Row className="d-flex align-items-start">
				<Col lg="4" className="text-center mb-2-sm">
					<Card className="h-100 card-plain mt-0">
						<div className="p-0 d-flex flex-column">
							<DealBuyerSwitchButton saleType={saleType} />
						</div>
					</Card>
				</Col>

				<Col lg="4">
					<Card className="h-100 card-plain mt-0 mb-2-sm">
						<div className="p-0 d-flex flex-column">
							<DealInventoryHeader />
						</div>
					</Card>
				</Col>

				<Col lg="4">
					<Card>
						<CardBody className="pb-2">
							<Col className="mb-3 w-100">
								<Button
									disabled
									type="button"
									style={{ color: "black" }}
									className="btn-round btn-md btn w-100 btn-outline-secondary my-0"
								>
									{typeOfSale} {type}
								</Button>
							</Col>
							<Row
								className="mx-0 pt-0"
								style={{ marginBottom: "2px" }}
								onKeyDown={formNavigationUtils}
							>
								<Col xl="6">
									<Label>Deal Number</Label>
									<Input
										readOnly={type !== DealStatus.PENDING}
										type="text"
										name="dealNumber"
										value={dealNumber}
										onChange={(e) => editDealNumber(e.target.value)}
									/>
								</Col>
								<Col xl="6">
									<Label>Date of Sale</Label>
									<Input
										readOnly={type !== DealStatus.PENDING}
										type="date"
										name="dealLoanDate"
										value={loanDate}
										onChange={(e) => editLoanDate(e.target.value)}
									/>
								</Col>
								<Col xl="12" className="mt-2 pt-2">
									<LenderDetail />
								</Col>
								<Col xl="12">
									<DealSalesmanDropDown />
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<hr className="my-3 mx-3" />

			<Row>
				<Col lg="4">
					<Card>
						<Sale />
					</Card>
				</Col>
				<Col lg="4">
					<Card>
						<TermsTable />
					</Card>
				</Col>
				<Col lg="4">
					<Card>
						<Summary />
					</Card>
				</Col>
			</Row>
		</>
	);
};

/**
 * PropTypes for the DealContent component.
 */
DealContent.propTypes = {
	saleType: PropTypes.oneOf(Object.keys(SaleType)).isRequired,
};

export default DealContent;
