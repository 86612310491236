import React, { useRef, useState, useEffect } from "react";
import { BsRecordCircle } from "react-icons/bs";
import { Button } from "reactstrap";

/**
 * ScreenRecorder component allows users to record their screen.
 * It provides a button to start/stop the recording and saves the recorded video.
 */
function ScreenRecorder() {
	const videoRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const [recording, setRecording] = useState(false);
	const [chunks, setChunks] = useState([]);
	const [isIconVisible, setIconVisible] = useState(true);

	useEffect(() => {
		let intervalId;

		if (recording) {
			intervalId = setInterval(() => {
				setIconVisible((prevVisible) => !prevVisible);
			}, 500); // Adjust the interval duration (in milliseconds) to control the flickering speed
		} else {
			setIconVisible(true);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [recording]);

	const handleToggleRecording = async () => {
		if (!recording) {
			try {
				const stream = await navigator.mediaDevices.getDisplayMedia({
					video: {
						chromeMediaSource: "screen",
					},
				});
				console.log(stream);
				videoRef.current.srcObject = stream;
				mediaRecorderRef.current = new MediaRecorder(stream);
				mediaRecorderRef.current.addEventListener(
					"dataavailable",
					handleDataAvailable
				);
				mediaRecorderRef.current.addEventListener("stop", handleStop);
				mediaRecorderRef.current.start();
				setRecording(true);
			} catch (error) {
				console.error("Error starting screen recording:", error);
			}
		} else {
			stopRecording();
		}
	};

	const handleDataAvailable = (event) => {
		setChunks((prevChunks) => [...prevChunks, event.data]);
	};

	const handleStop = () => {
		const blob = new Blob(chunks, { type: "video/webm" });
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "screen_recording.webm";
		a.click();
		setRecording(false);
		setChunks([]);
	};

	const stopRecording = () => {
		if (
			mediaRecorderRef.current &&
			mediaRecorderRef.current.state !== "inactive"
		) {
			mediaRecorderRef.current.stop();
			setChunks([]);
		}
	};

	return (
		<div
			style={{
				width: "auto",
				display: "block",
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<video ref={videoRef} style={{ display: "none" }} />
				<Button className="btn-sm btn-link px-2" onClick={handleToggleRecording}>
					{recording ? (
						<>
							<BsRecordCircle
								color="red"
								style={{ visibility: isIconVisible ? "visible" : "hidden" }}
							/>
							Stop Recording
						</>
					) : (
						<>
							<i style={{ visibility: isIconVisible ? "visible" : "hidden" }} className="nc-icon nc-button-play" />
							&nbsp;Start Recording
						</>
					)}
				</Button>
			</div>
		</div>
	);
}

export default ScreenRecorder;
