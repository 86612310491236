import React from "react";
import { Card } from "reactstrap";
import Camera from "assets/img/placeholder.png";

const ProfilePicture = ({ dealerID, locationID, lead }) => {
	return (
		<>
			<div className="col-sm-4 text-center px-0">
				<div className="col-sm">
					<Card className="pt-4 d-flex justify-content-center align-items-center">
						<>
							<img
								className="pb-3 px-3"
								src={`https://Dealernetwork.com/images/PersonalImages/${dealerID}/${locationID}/${lead?.leadID}.jpg`}
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = Camera;
								}}
								alt="customer-profile"
							/>
						</>
					</Card>
				</div>
			</div>
		</>
	);
};

export default ProfilePicture;
