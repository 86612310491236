import React, { useState } from "react";
import { Col, Row, CardText, Button } from "reactstrap";

import shallow from "zustand/shallow";
import NumberFormat from "react-number-format";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import useCurrentAccountStore from "./AccountStore";

import {
	confirmDeleteAlert,
	deleteFailAlert,
	deleteSuccessAlert,
} from "../utils/alertUtils";
import { deleteAdjustment } from "./AccountAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import AccountAdjustmentForm from "./AccountAdjustmentForm";
import AccountHook from "./AccountHook";
import { useEffect } from "react";
import {
	CurrencyDisplayElement,
	DateDisplayElement,
} from "../features/deal/components/DisplayElement";
import ChildrenModal from "../components/modals/ChildrenModal";
import ReactTable from "../components/tables/ReactTable";

const AccountAdjustmentsTable = () => {
	const [isUpdated, setIsUpdated] = useState(false);
	const save = AccountHook();
	const { account, adjustments, editAdjustments } = useCurrentAccountStore(
		(state) => ({
			adjustments: state.adjustments,
			editAdjustments: state.editAdjustments,
			account: state.account,
		}),
		shallow
	);
	console.log(account);
	console.log(adjustments);

	const [currentIndex, setCurrentIndex] = useState(adjustments.length);

	const [showAdd, setShowAdd] = useState(false);
	const toggle = () => setShowAdd(!showAdd);
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeAdjustment, currentID, currentIndex);
	};

	const removeAdjustment = (ID, index) => {
		console.log("Removing Adjustment fee with ID: " + ID);
		if (ID) {
			deleteAdjustment(ID).then(
				(response) => {
					console.log(response);
					let obj = [];
					if (ID !== null) {
						obj = adjustments.filter((item) => item.id !== ID);
					} else {
						obj = adjustments.filter((item) => item.currentIndex !== index);
					}
					editAdjustments(obj);
					setCurrentIndex(obj.length);
					setIsUpdated(true);
					deleteSuccessAlert();
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};
	// const actionCell = ({ value }) => (
	// 	<div className="d-flex justify-content-center">
	// 		<div
	// 			className="btn btn-md btn-primary"
	// 			onClick={() => {
	// 				setCurrentIndex(value.index);
	// 				toggle();
	// 			}}
	// 		>
	// 			<i className="nc-icon nc-align-center"></i>
	// 			<span className="noMobile">Edit</span>
	// 		</div>
	// 		<div
	// 			className="btn btn-md btn-link btn-link-danger text-danger ml-2"
	// 			onClick={() => {
	// 				console.log(value.index);
	// 				toggleModal(value.index);
	// 			}}
	// 		>
	// 			X
	// 		</div>
	// 	</div>
	// );
	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				accessor: (row, index) => index + 1,
				style: { width: "20px" },
			},
			{
				Header: "Description",
				accessor: "description",
			},
			{
				Header: "Date Due",
				accessor: "date",
				Cell: ({ value }) => <DateDisplayElement val={value} />,
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: "RO#",
				accessor: "RONumber",
			},
			{
				Header: "PurchasedFrom",
				accessor: "purchasedFrom",
			},
			{
				Header: "Method",
				accessor: "method",
			},
			{
				Header: "Actions",
				accessor: (row, index) => ({ ...row, index }), // Include the entire row and index
				disableFilters: true,
				Cell: ({ value }) => (
					<div className="d-flex justify-content-center">
						{account.status === 0 && (
							<div
								className="btn btn-md btn-primary"
								onClick={() => {
									setCurrentIndex(value.index);
									toggle();
								}}
							>
								<i className="nc-icon nc-align-center"></i>
								<span className="noMobile">Edit</span>
							</div>
						)}
						{account.status === 0 && (
							<div
								className="btn btn-md btn-link btn-link-danger text-danger ml-2"
								onClick={() => {
									console.log(value.index);
									toggleModal(value.id, value.index);
								}}
							>
								X
							</div>
						)}
					</div>
				),
			},
		],
		// eslint-disable-next-line
		[]
	);
	useEffect(() => {
		if (isUpdated) {
			save();
			setIsUpdated(false);
		}
		// eslint-disable-next-line
	}, [isUpdated]);

	return (
		<>
			<ChildrenModal modal={showAdd} toggle={toggle} modalTitle="Adjustment">
				<AccountAdjustmentForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</ChildrenModal>
			<Card>
				<CardBody>
					<div>
						<ReactTable data={adjustments} columns={columns} />
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<Row>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								Adjustments
							</CardText>
						</Col>
						<Col sm="6">
							<CardText tag="h3" className="text-center">
								<NumberFormat
									value={account.debitCreditTotal}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									readOnly={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</CardBody>
			</Card>
			<Row>
				<Col>
					{account.status === 0 && (
						<Button
							className="btn btn-primary btn-md"
							onClick={() => {
								setCurrentIndex(adjustments.length);
								toggle();
							}}
						>
							<i className="nc-icon nc-simple-add" />
							Add
						</Button>
					)}
				</Col>
			</Row>
		</>
	);
};
export default AccountAdjustmentsTable;
