import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl, devBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/attachments/",
});

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/attachments/",
// });

const devInstance = createAxiosInstance({
	baseURL: devBaseUrl + "/integrations/attachments/",
});

const bufferInstance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/attachments/",
	responseType: "arraybuffer",
});

// Returns the actual file from Ftp server as a byte array
export const getDealersAttachment = async (id) => {
	return await bufferInstance.get(`file/${id}`);
};

// Returns the file names and DB objects, if they exist, for all attachments of type deal, inventory, and customer
// for a deal
export const getDealAttachmentsList = async (dealID) => {
	return await instance.get(`files/deal/${dealID}`);
};

// Returns the file names and DB objects, if they exist, for all attachments
export const getDealersAttachmentsList = async (type, relatedID) => {
	return await instance.get(`files/${type}/${relatedID}`);
};

// Uploads a list of attachment files
export const uploadDealerAttachments = async (
	dealerID,
	locationID,
	type,
	relatedID,
	fileInfo
) => {
	return await instance.post(
		`upload/${dealerID}/${locationID}/${type}/${relatedID}`,
		fileInfo
	);
};

// Uploads a list of attachment files from external source (the customer themself)
export const uploadExternalCustomerAttachments = async (
	uploadCode,
	customerID,
	fileInfo
) => {
	return await devInstance.post(
		`external-upload/${uploadCode}/${customerID}`,
		fileInfo
	);
};

// Deletes a list of attachment files
export const deleteDealerAttachments = async (dcAttachmentId) => {
	return await instance.post(`delete/${dcAttachmentId}`);
};

// Updates the database DB for an attachment
export const updateAttachmentInfo = async (updatedInfo) => {
	return await instance.post(`file/info/save`, updatedInfo);
};

// Uploads a customer's profile photo
export const uploadCustomerPhoto = async (
	dealerID,
	locationID,
	customerID,
	file
) => {
	return await bufferInstance.post(
		`profile-picture/upload/${dealerID}/${locationID}/${customerID}`,
		file
	);
};

// Retrieves a customer's profile photo
export const getCustomerPhoto = async (dealerID, locationID, customerID) => {
	return await bufferInstance.get(
		`profile-picture/${dealerID}/${locationID}/${customerID}`
	);
};
// transfer lead Attachment to the customer
export const transferLeadAttachmentToCustomer = async (
	dealerID,
	locationID,
	customerID,
	leadID
) => {
	const body = {
		dealerID,
		locationID,
		customerID,
		leadID,
	};
	console.log(body);
	return await bufferInstance.post(`transfer-lead`, body);
};
