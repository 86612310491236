import React, { useState, useEffect } from "react";
import { Button, Col, Input, Table } from "reactstrap";
import { showApiError } from "utils/errorRoutingUtils";
import {
	saveFailAlert,
	retrieveFailAlert,
	saveSuccessAlert,
} from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	getInventoryFeed,
	getVehicleListisAdvertised,
	updateIsAdvertised,
	updateSendFeed,
} from "api/InventoryAPI";
import Swal from "sweetalert2";

const InventoryAdvertisingModal = ({ toggle, sendVariable }) => {
	const locationID = useFromUser("locationID");
	const [data, setData] = useState([]);
	//	const [loadingList, setLoadingList] = useState(true);
	const [sendFeed, setSendFeed] = useState([]);
	const [checkedAll, setCheckedAll] = useState(false);
	const getSendFeed = (content) => {
		const Items = content.map((obj) => {
			const sendValue = obj[sendVariable];
			const ID = obj.inventoryID;
			return { sendValue, ID };
		});
		return Items;
	};

	function manageFeed() {
		const Items = sendFeed;
		const listVehicleFeed = { sendVariable, Items };
		console.log(listVehicleFeed);
		return new Promise((resolve) => {
			//setLoadingList(true);
			formLoading();
			if (sendVariable === "isAdvertised") {
				updateIsAdvertised(locationID, listVehicleFeed).then(
					() => {
						resolve();
						Swal.close();
						saveSuccessAlert();
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, saveFailAlert);
						}
						Swal.close();
					}
				);
			} else {
				updateSendFeed(locationID, listVehicleFeed).then(
					() => {
						resolve();
						Swal.close();
						saveSuccessAlert();
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, saveFailAlert);
						}
						Swal.close();
					}
				);
			}
		});
	}

	const selectAll = (value) => {
		setCheckedAll(value);
		setSendFeed(() => {
			const newState = [...sendFeed];
			for (let i = 0; i < newState.length; i++) {
				if (value === true) {
					newState[i].sendValue = 1;
				} else if (value === false) {
					newState[i].sendValue = 0;
				}
			}
			return newState;
		});
	};

	// Headers
	const headers = [
		"#",
		"Stock",
		"Year",
		"Make",
		"Model",
		"Vin",
		"Asking Price",
		"Select",
	];
	//  table headers
	// const head = headers.map((key) => <th key={key}>{key}</th>);
	const head = headers.map((key) =>
		key === "Select" ? (
			<th key={key}>
				<label className="m-0 pr-2">Check All </label>
				<input
					type="checkbox"
					className="position-inherit"
					defaultChecked={checkedAll}
					onChange={(e) => {
						selectAll(e.target.checked);
					}}
				/>
			</th>
		) : (
			<th key={key}>{key}</th>
		)
	);

	// row items
	const rows = data.map((key, index) => (
		<tr key={index}>
			<td>{index + 1}</td>
			<td>{key.stockNo}</td>
			<td>{key.year}</td>
			<td>{key.make}</td>
			<td>{key.model}</td>
			<td>{key.vin}</td>
			<td>
				{new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(key.askingPrice)}
			</td>
			<td>
				<Input
					type="checkbox"
					onChange={(e) => {
						let tempVal = e.target.checked ? 1 : 0;
						console.log(tempVal);
						setSendFeed((prev) => {
							let newState = [...prev];
							newState[index].sendValue = tempVal;
							console.log(newState);
							return newState;
						});
					}}
					checked={sendFeed[index].sendValue}
					style={{ width: "20px", height: "20px" }}
				/>
			</td>
		</tr>
	));

	const getListData = () => {
		if (locationID !== "") {
			if (sendVariable === "isAdvertised") {
				getVehicleListisAdvertised(locationID).then(
					(response) => {
						const Items = getSendFeed(response.data.content);
						setSendFeed(Items);
						setData(response.data.content);
						//setLoadingList(false);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, retrieveFailAlert);
						}
						//setLoadingList(false);
					}
				);
			} else {
				getInventoryFeed(locationID).then(
					(response) => {
						const Items = getSendFeed(response.data.content);
						setSendFeed(Items);
						setData(response.data.content);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, retrieveFailAlert);
						}
					}
				);
			}
		}
	};

	const formLoading = () => {
		return Swal.fire({
			title: "Saving feed list...",
			showConfirmButton: false,
			willOpen() {
				Swal.showLoading();
			},
			didClose() {
				Swal.hideLoading();
			},
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
		});
	};

	useEffect(() => {
		getListData();
		// eslint-disable-next-line
	}, []);

	return (
		<Col>
			<Col>
				<Button
					className="btn-sm mb-2"
					color="success"
					onClick={() => {
						manageFeed().then(() => {
							toggle();
						});
						//toggle();
					}}
				>
					<i className="nc-icon nc-check-2"></i>&nbsp;Save
				</Button>
			</Col>
			<div style={{ overflowX: "auto" }}>
				<Table striped hover>
					<thead>
						<tr>{head}</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</div>
			{/* <Table striped hover responsive>
				<thead>
					<tr>
						{head}
						<th>
							<label className="m-0 pr-2">Check All </label>
							<input
								type="checkbox"
								className="position-inherit"
								defaultChecked={checkedAll}
								onChange={(e) => {
									selectAll(e.target.checked);
								}}
							/>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table> */}
		</Col>
	);
};

export default InventoryAdvertisingModal;
