import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./file-uploader.css";
import { fileLoaded, fileError } from "utils/fileUploaderUtils";
import { Button } from "reactstrap";

/**
 * FileUploader component for uploading files with validation.
 *
 * @component
 * @param {object} props - Component props
 * @param {number} props.maxFileSize - Maximum file size allowed for upload
 * @param {function} props.setUploads - Function to update the uploaded files
 * @param {string} props.accept - Accepted file types as a comma-separated string
 * @param {Array<string>} props.acceptArray - Accepted file types as an array of strings
 * @param {string} [props.iconClass] - Optional CSS class for the upload icon
 * @param {boolean} [props.hideIconText=false] - Flag to hide the icon text
 * @param {boolean} [props.single=false] - Flag to allow only single file upload
 * @returns {JSX.Element} The rendered component
 */
const FileUploader = ({
	maxFileSize,
	setUploads,
	accept,
	acceptArray,
	iconClass,
	hideIconText = false,
	single = false,
}) => {
	const fileInputRef = useRef(null);
	const [uploadErrors, setUploadErrors] = useState([]);

	/**
	 * Opens the file input dialog.
	 */
	const updateInput = () => {
		fileInputRef.current.click();
		setUploadErrors([]);
	};

	/**
	 * Handles file upload and validation.
	 *
	 * @param {Event} e - The change event triggered when files are selected
	 */
	const uploadFile = (e) => {
		e.preventDefault();
		Array.from(e.target.files).forEach((currentFile) => {
			const reader = new FileReader();
			reader.onloadend = () =>
				fileLoaded(
					currentFile,
					maxFileSize,
					acceptArray,
					accept,
					setUploads,
					setUploadErrors
				);
			reader.onerror = (err) => fileError(err, currentFile, setUploadErrors);
			reader.readAsDataURL(currentFile);
		});
	};

	return (
		<>
			<div role="button" className="file-uploader-upload" onClick={updateInput}>
				{!hideIconText && (
					<Button className="mr-2" size="link" color="primary">
						Select Files
					</Button>
				)}
				<input
					ref={fileInputRef}
					type="file"
					onChange={uploadFile}
					multiple={!single}
					accept={accept}
				/>
			</div>
			{uploadErrors.length > 0 && (
				<div className="col-12 file-uploader-list">
					<p className="file-uploader-list-title">
						Some Files Could Not Be Uploaded:
					</p>
					<ul>
						{uploadErrors.map((err, i) => (
							<li key={`${err}_${i}`}>{err}</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

FileUploader.propTypes = {
	maxFileSize: PropTypes.number.isRequired,
	setUploads: PropTypes.func.isRequired,
	accept: PropTypes.string.isRequired,
	acceptArray: PropTypes.arrayOf(PropTypes.string).isRequired,
	iconClass: PropTypes.string,
	hideIconText: PropTypes.bool,
	single: PropTypes.bool,
};

export default FileUploader;
