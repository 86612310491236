import React from "react";

import useCurrentPhotoStore from "stores/PhotoStore";
import Lightbox from "react-awesome-lightbox";

import "react-awesome-lightbox/build/style.css";

/**
 * Button when clicked shows the images in the gallery as a slideshow and
 * users navigate through and zoom in to images.
 */
const ImageCarousel = ({ showSlide, setShowSlide, startIndex }) => {
	// Photo store
	const { gallery } = useCurrentPhotoStore();

	// Create the images object to be used for the Lightbox library
	const images = gallery.map((photo, index) => ({
		url: photo.src,
		title: `${index + 1} of ${gallery.length}`,
	}));

	return (
		<div>
			{showSlide && (
				<Lightbox
					startIndex={startIndex}
					onClose={() => setShowSlide(false)}
					images={images}
				/>
			)}
		</div>
	);
};

export default ImageCarousel;
