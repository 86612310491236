import React from "react";
import {
	Form,
	FormGroup,
	Label,
	Row,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
} from "reactstrap";
import useCurrentDealStore from "../../../../../stores/DealStore";
import Select from "react-select";
import InputElement from "features/forms/components/InputElement";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";

const ShippingForm = () => {
	// Deal Store
	const {
		shippingRecNum,
		editShippingRecNum,
		deliveredDate,
		editDeliveredDate,
		type,
		shippingCompanies,
	} = useCurrentDealStore(
		(state) => ({
			shippingRecNum: state.deal.shippingRecNum,
			editShippingRecNum: state.editShippingRecNum,
			deliveredDate: state.deal.deliveredDate,
			editDeliveredDate: state.editDeliveredDate,
			shippingCompanies: state.shippingCompanies,
			type: state.deal.type,
		}),
		shallow
	);

	const vendorList = [];
	for (const key in shippingCompanies) {
		vendorList.push({
			label: shippingCompanies[key].name,
			value: shippingCompanies[key].ID,
			vendor: shippingCompanies[key].obj,
		});
	}

	// Define form validation parameters
	const methods = useForm();
	console.log(shippingRecNum);
	return (
		<Card className="m-4">
			<CardHeader>
				<CardTitle tag="h3">Shipping Company</CardTitle>
			</CardHeader>
			<CardBody className="my-2">
				<FormProvider {...methods}>
					<Form>
						<Row>
							<FormGroup className={"col-md-2"}>
								<Label>Company</Label>
								<Select
									className="mb-3"
									isDisabled={type !== DealStatus.PENDING}
									value={{
										value: shippingRecNum,
										label: vendorList.filter(
											(e) => e.value === shippingRecNum
										)[0]?.label,
									}}
									options={vendorList}
									onChange={(e) => {
										editShippingRecNum(e.value);
									}}
								/>
							</FormGroup>
							<InputElement
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								value={deliveredDate}
								onChange={(e) => {
									editDeliveredDate(e.target.value);
									console.log(e.target.value);
								}}
								name="deliveredDate"
								label="Delivery Date"
								type="Date"
								colSize="col-md-2"
							/>
						</Row>
					</Form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default ShippingForm;
