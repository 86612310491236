import React, { useState } from "react";
import { Row, Col, Card, CardHeader, CardBody, Collapse, Button } from "reactstrap";
import PropTypes from "prop-types";

/**
 * InventoryOptionalEquipment component - Manages and displays optional equipment with checkboxes.
 * 
 * @param {function} changeHandler - Function to handle changes to selected equipment.
 * @param {Array} optionalEquip - Array of available optional equipment.
 * @param {string} selectedEquip - Comma-separated string of selected equipment values.
 * @param {boolean} disabled - Flag to indicate if checkboxes should be disabled.
 * @returns {JSX.Element} A responsive grid of optional equipment checkboxes.
 */
const InventoryOptionalEquipment = ({
	changeHandler,
	optionalEquip,
	selectedEquip,
	disabled,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	if (!optionalEquip) {
		return null; // or return <div>No options available</div> or similar
	}

	let d = disabled;
	if (optionalEquip === "" || optionalEquip === null) {
		d = true;
	}

	// Convert selectedEquip to an array
	const selectedEquipArray = selectedEquip ? selectedEquip.split(",") : [];

	// Create Sets for optionalEquip and selectedEquipArray
	let optionalEquipSet = new Set(optionalEquip.map((item) => item.value));
	let selectedEquipSet = new Set(selectedEquipArray);

	// Combine Sets
	let combinedEquipSet = new Set([...optionalEquipSet, ...selectedEquipSet]);

	// Now, we need to build our new optionalEquip array from the combined set.
	optionalEquip = Array.from(combinedEquipSet).map((value) => {
		const existingEquipment = optionalEquip.find(
			(item) => item.value === value
		);
		return existingEquipment ? existingEquipment : { value, label: value };
	});

	const handleCheckboxChange = (event, value) => {
		const newSelectedEquipArray = event.target.checked
			? [...selectedEquipArray, value]
			: selectedEquipArray.filter((item) => item !== value);
		changeHandler(newSelectedEquipArray.join(","));
	};

	const columnSize = Math.ceil(optionalEquip.length / 4); // Ensure we round up for proper column allocation
	const columns = [];
	for (let i = 0; i < optionalEquip.length; i += columnSize) {
		columns.push(optionalEquip.slice(i, i + columnSize));
	}

	return (
		<Card>
			<CardHeader
				style={{ justifyContent: "space-between" }}
				onClick={toggle}
				className="cursor-pointer d-flex py-2"
			>
				<h3>Optional Equipment</h3>
				<Button className="btn-link btn-sm">
					<i className={`nc-icon ${isOpen ? "nc-minimal-up" : "nc-minimal-down"}`} />
				</Button>
			</CardHeader>
			<Collapse role="tabpanel" isOpen={isOpen}>
				<CardBody className="pt-0">
					<Row>
						{columns.map((column, columnIndex) => (
							<Col key={`column-${columnIndex}`} xs="12" sm="6" md="3">
								{column.map((option, index) => (
									<div key={index} className="checkbox-item my-4">
										<div>
										<input
											type="checkbox"
											id={`${option.value}-${columnIndex}-${index}`}
											value={option.value}
											disabled={d}
											checked={selectedEquipArray.includes(option.value)}
											onChange={(e) => handleCheckboxChange(e, option.value)}
										/>
										</div>
										<div className="ml-2" htmlFor={`${option.value}-${columnIndex}-${index}`}>
											{option.label}
										</div>
									</div>
								))}
							</Col>
						))}
					</Row>
				</CardBody>
			</Collapse>
		</Card>
	);
};

InventoryOptionalEquipment.propTypes = {
	changeHandler: PropTypes.func.isRequired,
	optionalEquip: PropTypes.array,
	selectedEquip: PropTypes.string,
	disabled: PropTypes.bool,
};

export default InventoryOptionalEquipment;
