// KbbForm.js
import React from "react";
import { Form, Button, Col, Row } from "reactstrap";

import InputWrapper from "features/forms/components/InputWrapper";
import InputElement from "features/forms/components/InputElement";
import {
	IntegerInput,
	SelectorElement,
} from "features/forms/components/InputElement";
import { kbbConditionOptions } from "constants/Constants";
import formNavigationUtils from "utils/formNavigationUtils";
import { useFormContext } from "react-hook-form";

const KbbForm = ({
	getKbbDetails,
	updateKbbValues,
	dcTableKbb,
	condition,
	setCondition,
	today,
	vehicleKbbObj,
}) => {
	const isVehicleDecoded = Boolean(dcTableKbb?.kbbVehicleId);
	const methods = useFormContext();
	return (
		<Form onKeyDown={formNavigationUtils}>
			<Row className="align-items-center"></Row>

			<InputWrapper
				formTitle="KBB Vehicle Details"
				inputComponents={[
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={vehicleKbbObj.vin || ""}
						name="enteredVin"
						label="VIN"
						type="text"
						onChange={(e) => vehicleKbbObj.editVin(e.target.value)}
					/>,
					<IntegerInput
						{...methods}
						colSize="col-lg-3"
						value={vehicleKbbObj.odometerIn}
						name="kbbOdometer"
						label="Odometer"
						type="text"
						onChange={vehicleKbbObj.editOdometerIn}
					/>,
					<Col className="pt-1 d-flex align-items-center justify-content-start">
						<Button
							className="btn-primary btn-sm"
							type="submit"
							onClick={methods.handleSubmit(getKbbDetails)}
						>
							<i className="nc-icon nc-refresh-69" /> Decode
						</Button>
						{isVehicleDecoded && (
							<Button
								className="btn-primary btn-sm"
								color="success"
								onClick={methods.handleSubmit(updateKbbValues)}
							>
								Get Values
							</Button>
						)}
					</Col>,
					<div className="noDesktop pb-3 w-100"></div>,
					<Col md="12" className="d-flex justify-content-center mb-1.5" />,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={vehicleKbbObj.year}
						name="kbbYear"
						label="Year"
						type="text"
						onChange={(e) => vehicleKbbObj.editYear(e.target.value)}
						readOnly
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={vehicleKbbObj.make}
						name="kbbMake"
						label="Make"
						type="text"
						onChange={(e) => vehicleKbbObj.editMake(e.target.value)}
						readOnly
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={vehicleKbbObj.model}
						name="kbbModel"
						label="Model"
						type="text"
						onChange={(e) => vehicleKbbObj.editModel(e.target.value)}
						readOnly
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						disabled
						value={dcTableKbb?.kbbVehicleId}
						name="kbbID"
						label="KBB Vehicle ID"
						type="text"
					/>,
					<IntegerInput
						{...methods}
						colSize="col-lg-3"
						disabled
						value={dcTableKbb?.kbbmileageZero}
						name="kbbCutoffMileage"
						label="Typical Mileage"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="kbbCondition"
						label="Condition"
						colSize="col-lg-3"
						value={condition}
						onChange={(e) => setCondition(e.target.value)}
						options={kbbConditionOptions}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						disabled
						value={dcTableKbb?.bookDate || today}
						name="kbbValuationDate"
						label="Valuation Date"
						type="date"
					/>,
				]}
			/>
		</Form>
	);
};

export default KbbForm;
