import React, { useState } from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const OfficialFeesBreakdown = () => {
	const {
		titleFee,
		filingFee,
		smogExemption,
		weightFee,
		tireFee,
		bpa,
		tax,
		license,
		registration,
	} = useCurrentSaleStore((state) => state, shallow);

	const { dmvFeeTypes, miscDMVFees } = useCurrentDealStore(
		(state) => state,
		shallow
	);

	const { official } = useCurrentRecapStore((state) => state, shallow);
	const [totalCost, setTotalCost] = useState(0);
	const optionList = [];
	for (const key in dmvFeeTypes) {
		optionList.push({
			label: dmvFeeTypes[key].description,
			value: dmvFeeTypes[key].type,
		});
	}

	const rows = [
		{ label: "License", value: license },
		{ label: "Registration", value: registration },
		{ label: "Title Fee", value: titleFee },
		{ label: "Filing Fee", value: filingFee },
		{ label: "Smog Exemption", value: smogExemption },
		{ label: "Weight Fee", value: weightFee },
		{ label: "Tire Fee", value: tireFee },
		{ label: "BPA", value: bpa },
		{ label: "Tax", value: tax },
	];

	const DmvFees = () => {
		let total = 0;
		const fees = miscDMVFees.map((key) => {
			total += key.cost;
			return (
				<Row>
					<Col>
						<Label>
							{optionList.filter((row) => row.value === key.type)[0]?.label}
						</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		setTotalCost(total);
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
								<Col>N/A</Col>
							</Row>
						))}
					</>
					<hr />
					<DmvFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={official} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default OfficialFeesBreakdown;
