import React from "react";
import { Card, CardBody, CardImg, CardText, CardTitle } from "reactstrap";
import PropTypes from "prop-types";

/**
 * ArticleCard component to display individual articles.
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the article.
 * @param {string} props.description - The description of the article.
 * @param {string} props.link - The link to the full article.
 * @param {string} props.image - The image URL for the article.
 * @returns {JSX.Element} The ArticleCard component.
 */
const ArticleCard = ({ title, description, link, image }) => {
    return (
        <Card className="article-card mx-2 mb-3">
            <CardImg top width="100%" src={image} alt={title} />
            <CardBody>
                <CardTitle tag="h5">{title}</CardTitle>
                <CardText>{description}</CardText>
                <a href={link} target="_blank" rel="noopener noreferrer" className="text-primary">
                    Read More
                </a>
            </CardBody>
        </Card>
    );
};

ArticleCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};

export default ArticleCard;
