import React from "react";
import { Table, Button, Card, CardBody } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import {
	confirmDeleteAlert,
	deleteSuccessAlert,
	deleteFailAlert,
} from "utils/alertUtils";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";
import dayjs from "dayjs";
import { DealStatus } from "constants/Constants";
import { deleteWarranty } from "api/DealAPI";
import shallow from "zustand/shallow";
import { showApiError } from "utils/errorRoutingUtils";

/**
 * ServiceContractTable component displays a table of service contracts.
 *
 * @param {Object} props - The component props
 * @param {Function} props.toggle - Function to toggle the modal
 * @param {Function} props.setCurrentIndex - Function to set the current index
 * @param {Array} props.vendorOption - List of vendor options
 *
 * @returns {JSX.Element}
 */
const ServiceContractTable = ({ toggle, setCurrentIndex, vendorOption }) => {
	const { warranty, type, editWarrantyList, warrantyList, calculate } =
		useCurrentDealStore(
			(state) => ({
				warranty: state.warranty,
				type: state.deal.type,
				editWarrantyList: state.editWarranty,
				warrantyList: state.warranty,
				calculate: state.calculate,
			}),
			shallow
		);

	const serviceContractHead = [
		"#",
		"Company",
		"Amount",
		"Term",
		"Expires",
		"Actions",
	];

	/**
	 * Toggles the confirmation modal for deleting a warranty.
	 *
	 * @param {number} currentID - The ID of the current warranty
	 * @param {number} currentIndex - The index of the current warranty
	 */
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeWarranty, currentID, currentIndex);
	};

	/**
	 * Removes a warranty by ID or index.
	 *
	 * @param {number} ID - The ID of the warranty to remove
	 * @param {number} index - The index of the warranty to remove
	 */
	const removeWarranty = async (ID, index) => {
		try {
			if (ID) {
				await deleteWarranty(ID);
			}
			const updatedWarrantyList = ID
				? warrantyList.filter((item) => item.ID !== ID)
				: warrantyList.filter((item) => item.currentIndex !== index);
			editWarrantyList(updatedWarrantyList);
			setCurrentIndex(updatedWarrantyList.length);
			calculate();
			deleteSuccessAlert();
		} catch (err) {
			console.error(err);
			showApiError(err, deleteFailAlert);
		}
	};

	/**
	 * Renders the table head.
	 *
	 * @returns {JSX.Element}
	 */
	const renderTableHead = () => (
		<thead>
			<tr>
				{serviceContractHead.map((key, idx) => (
					<th key={idx}>{key}</th>
				))}
			</tr>
		</thead>
	);

	/**
	 * Renders the table rows.
	 *
	 * @returns {JSX.Element}
	 */
	const renderTableRows = () =>
		warranty.map((contract, index) => (
			<tr key={index}>
				<th scope="row">{index + 1}</th>
				<td>
					{vendorOption.find((vendor) => vendor.value === contract.coRecNum)
						?.label || "N/A"}
				</td>
				<td>
					<CurrencyDisplayElement val={contract.amount} />
				</td>
				<td>{contract.term}</td>
				<td>{dayjs(contract.expires).format("MM/DD/YYYY")}</td>
				<td className="text-center">
					<Button
						disabled={type !== DealStatus.PENDING}
						className="btn-sm"
						color="primary"
						onClick={() => {
							setCurrentIndex(index);
							toggle();
						}}
					>
						Edit
					</Button>
					<Button
						disabled={type !== DealStatus.PENDING}
						className="btn-sm ml-2"
						color="danger"
						onClick={() => toggleModal(contract.ID, contract.currentIndex)}
					>
						Delete
					</Button>
				</td>
			</tr>
		));

	return (
		<>
			{warranty.length > 0 && (
				<Card>
					<CardBody>
						<div
							className="d-flex justify-content-between align-items-center"
							style={{ overflowX: "auto" }}
						>
							<Table striped hover>
								{renderTableHead()}
								<tbody>{renderTableRows()}</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default ServiceContractTable;
