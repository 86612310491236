import { setLocale, object, string } from "yup";

// Define custom error messages for different error types
setLocale({
	mixed: {
		required: "Required",
	},
	string: {
		// eslint-disable-next-line
		max: "Must be ${max} characters or less",

		// eslint-disable-next-line
		min: "Must be at least 1",
	},
});

// Validation schema for Digitzs
const digitzsPaymentValidation = object().shape({
	digitzsCardHolder: string().required().max(50),
	digitzsCardNumber: string().required().max(30),
	digitzsCardExpires: string()
		.required()
		.matches(/^[0-9]{2}\/[0-9]{2}$/, "Must be MM/YY"),
	digitzsCardCode: string().required().max(10),
	digitzsBillingStreet: string().required().max(70),
	digitzsBillingCity: string().required().max(50),
	digitzsBillingState: string().required(),
	digitzsBillingZip: string().required().max(30),
});

export default digitzsPaymentValidation;
