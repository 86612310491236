import React from "react";
import PropTypes from "prop-types";
import { Form } from "reactstrap";
import { useFormContext } from "react-hook-form";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
	PhoneInputElement,
} from "features/forms/components/InputElement";
import { stateOptions, IncomeType, EmployerType } from "constants/Constants";
import InputWrapper from "features/forms/components/InputWrapper";

/**
 * UACApplicantIncomes component handles the input fields for applicant incomes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.prefix - The prefix for the input names.
 * @param {number} props.index - The index of the employment entry.
 * @param {Object} props.employment - The employment data.
 * @param {function} props.onInputChange - Function to handle input changes.
 * @returns {JSX.Element} The rendered component.
 */
const UACApplicantIncomes = ({ prefix, index, employment, onInputChange }) => {
	// Handles the input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		onInputChange(name, value, index);
	};

	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={`${prefix}Employment_${index}`}>
			<InputWrapper
				formTitle={`${prefix} Employment #${index + 1}`}
				inputComponents={[
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						name={`salaryType_${prefix}${index}`}
						label="Income Type"
						value={employment.salaryType}
						onChange={(e) => {
							const name = `salaryType_${prefix}${index}`;
							const val = e.target.value;
							onInputChange(name, val, index);
						}}
						options={IncomeType}
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						name={`employmentStatus_${prefix}${index}`}
						label="Employment Type"
						value={employment.employmentStatus}
						onChange={(e) => {
							const name = `employmentStatus_${prefix}${index}`;
							const val = e.target.value;
							onInputChange(name, val, index);
						}}
						options={EmployerType}
					/>,
					<CurrencyInputElement
						{...methods}
						colSize="col-lg-3"
						name={`salary_${prefix}${index}`}
						label="Monthly Income"
						value={employment.salary}
						onChange={(e) => {
							const name = `salary_${prefix}${index}`;
							onInputChange(name, e.target.value, index);
						}}
						type="number"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.company}
						onChange={handleInputChange}
						name={`company_${prefix}${index}`}
						label="Company or Employer Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.position}
						onChange={handleInputChange}
						name={`position_${prefix}${index}`}
						label="Job Title"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.years}
						onChange={handleInputChange}
						name={`years_${prefix}${index}`}
						label="Employment Year(s)"
						type="number"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.months}
						onChange={handleInputChange}
						name={`months_${prefix}${index}`}
						label="Employment Month(s)"
						type="number"
					/>,
					<PhoneInputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.phone}
						onChange={(e) => {
							const name = `phone_${prefix}${index}`;
							onInputChange(name, e.target.value, index);
						}}
						name={`phone_${prefix}${index}`}
						label="Business Phone Number"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.street}
						onChange={handleInputChange}
						name={`street_${prefix}${index}`}
						label="Company Street Address"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.city}
						onChange={handleInputChange}
						name={`city_${prefix}${index}`}
						label="Company City"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						name={`state_${prefix}${index}`}
						label="Company State"
						disable={false}
						value={employment.state}
						onChange={handleInputChange}
						options={stateOptions}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={employment.zip}
						onChange={handleInputChange}
						name={`zip_${prefix}${index}`}
						label="Company Zip"
						type="text"
					/>,
				]}
			/>
		</Form>
	);
};

UACApplicantIncomes.propTypes = {
	prefix: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	employment: PropTypes.shape({
		salaryType: PropTypes.string,
		employmentStatus: PropTypes.string,
		salary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		company: PropTypes.string,
		position: PropTypes.string,
		years: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		months: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		phone: PropTypes.string,
		street: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.string,
		zip: PropTypes.string,
	}).isRequired,
	onInputChange: PropTypes.func.isRequired,
};

export default UACApplicantIncomes;
