import dayjs from "dayjs";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import swal from "sweetalert2";
import shallow from "zustand/shallow";

import {
	getKbbBooksheet,
	formLoading,
	displayPdf,
	displayError,
	createPdfAndDownload,
} from "../../../../../../../api/FormsAPI";
import { saveVehicle } from "../../../../../../../api/InventoryAPI";
import { getKbbValues } from "../../../../../../../api/KbbAPI";
import {
	failAlert,
	saveFailAlert,
	twoOptionsAlert,
} from "../../../../../../../utils/alertUtils";
import { showApiError } from "../../../../../../../utils/errorRoutingUtils";
import useBuildVehicle from "../../../../../../../hooks/useBuildVehicle";
import useCurrentVehicleStore from "../../../../../../../stores/InventoryStore";
import { useFromUser } from "../../../../../../../stores/LocalStorageHelper";
import { KbbContext } from "../../KbbContext";
import { retrieveAllSelected } from "../../kbbHelperFunctions";

const BookSheet = ({ inventoryID, vehicleKbbObj }) => {
	// Vehicle obj
	const getVehicle = useBuildVehicle();
	// KBB context
	const { getDcKbbObj, setDcKbbObj, dcTableKbb } = useContext(KbbContext);
	const dealerZip = useFromUser("locations").zipCode;

	const { setInventoryFields } = useCurrentVehicleStore(
		(state) => state,
		shallow
	);

	const getBooksheet = () => {
		if (inventoryID === null) {
			twoOptionsAlert(
				" Would you like to add this vehicle to your list of appraisals?",
				"Yes",
				"No"
			).then((res) => {
				// swal.close();
				// //failAlert("Vehicle must be saved in order to features booksheet");
				// failAlert(" Would you like to add this vehicle to your list of appraisals?")
				if (res === "No") {
					//failAlert("Vehicle must be saved in order to features booksheet");
					return;
				} else {
					getForm();
				}
			});
		} else {
			getForm();
		}
	};

	const getForm = async () => {
		formLoading("Form");
		// update values
		const updateKbbValues = async () => {
			if (dcTableKbb?.kbbVehicleId) {
				const kbb = getDcKbbObj();
				const request = {
					kbb,
					valuesRequest: {
						configuration: {
							vehicleId: dcTableKbb.kbbVehicleId,
							vehicleOptionIds: retrieveAllSelected(kbb.dcTableKbbEquipment),
						},
						mileage: vehicleKbbObj.odometerIn,
						zipCode: dealerZip,
						valuationDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
					},
				};
				const res = await getKbbValues(request).then((res) => {
					setDcKbbObj(res.data.content);
					return res.data.content;
				});
				return res;
			} else {
				failAlert("Decode KBB and select a condition, then update values.");
				return false;
			}
		};
		// save vehicle/values
		const saveData = async (kbb) => {
			const inventoryVehicle = getVehicle();
			console.log(inventoryVehicle);

			if (
				(inventoryVehicle.stockNo == null || inventoryVehicle.stockNo === "") &&
				inventoryVehicle.vin != null &&
				inventoryVehicle.vin.length > 5
			) {
				inventoryVehicle.stockNo = inventoryVehicle.vin.substring(
					inventoryVehicle.vin.length - 6,
					inventoryVehicle.vin.length
				);
				console.log(inventoryVehicle.stockNo);
			}
			console.log(inventoryVehicle);

			// const kbb = getDcKbbObj();
			const request = {
				vehicle: inventoryVehicle,
				kbb: kbb,
			};
			const res = await saveVehicle(request).then(
				(response) => {
					console.log(response);
					setDcKbbObj(response.data.content.kbb);
					setInventoryFields(response.data.content.vehicle);
					return response.data.content;
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
			return res;
		};

		const res1 = await updateKbbValues();
		console.log(res1);
		if (res1 === false) {
			return;
		}
		const res2 = await saveData(res1);
		console.log(res2);

		// get booksheet
		getKbbBooksheet(res2.vehicle.inventoryID).then(
			(response) => {
				createPdfAndDownload(
					response,
					vehicleKbbObj.year +
						"-" +
						vehicleKbbObj.make +
						"-" +
						vehicleKbbObj.model +
						"-" +
						vehicleKbbObj.vin +
						"-Kbb-Booksheet.pdf"
				);
				displayPdf(response);
				console.log(response);
				swal.close();
			},
			(err) => {
				displayError(err);
			}
		);
	};

	return (
		<>
			<Button className="btn-primary btn btn-sm" onClick={getBooksheet}>
				<i className="nc-icon nc-paper"></i> Print Book Sheet
			</Button>
		</>
	);
};

export default BookSheet;
