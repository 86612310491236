import React, { useState, useCallback, useEffect, useContext } from "react";
import { Button, Card, CardBody, Col, Row, Label, Table } from "reactstrap";
import shallow from "zustand/shallow";
import lendersLogo from "assets/img/lenders-logo.png";
import { getPackList } from "api/FormsAPI";
import useCurrentDealStore from "stores/DealStore";
import ChildrenModal from "components/modals/ChildrenModal";
import { getDealAttachmentsList } from "api/AttachmentsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import Select from "react-select";
import swal from "sweetalert2";
import {
	EmailPackRequestButton,
	PackRequestButton,
} from "features/forms/components/FormsReportsButtons";
import { PackRequestContext } from "features/forms/context/PackContexts";
import { ATTACHMENT_CATEGORY } from "constants/Constants";
import FormsEmailProvider from "features/forms/context/FormsEmailContext";

const LenderPacket = () => {
	const { dealID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
		}),
		shallow
	);

	const [modalActive, setModalActive] = useState(false);
	const [attachments, setAttachments] = useState([]);
	const [packs, setPacks] = useState([]);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const params = { dealerID, locationID };

	const toggle = () => {
		setModalActive(!modalActive);
	};

	const createAndSetDealTableData = (obj) => {
		let tableData = [];

		Object.keys(obj).forEach((key) =>
			Object.keys(obj[key]).forEach((innerKey) => {
				let selected = false;
				if (obj[key][innerKey].category === ATTACHMENT_CATEGORY[1].value) {
					selected = true;
				}
				const fileDetails = {
					...obj[key][innerKey],
					selected,
				};
				tableData.push({
					...fileDetails,
				});
			})
		);

		setAttachments(tableData);
	};

	const setPacksDropdown = (arr) => {
		const options = arr.map((obj) => {
			return { value: obj.pack.id, label: obj.pack.packName, pack: obj };
		});
		setPacks(options);
	};

	// Gets all the attachment files information for a deal
	const getLists = useCallback(() => {
		dealID != null &&
			Promise.all([getPackList(params), getDealAttachmentsList(dealID)]).then(
				(res) => {
					setPacksDropdown(res[0].data.content.packs);
					createAndSetDealTableData(res[1].data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		getLists();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Card>
				<CardBody>
					<img alt="Lender Packet" className="lenderImg" src={lendersLogo} />
					<Col>
						<Button
							size="md"
							className="w-100 btn-primary"
							onClick={() => {
								if (packs.length > 0) {
									toggle();
								} else {
									swal.fire(
										"Error!",
										"Create a pack in the forms tab to use this feature",
										"error"
									);
								}
							}}
						>
							Lender Package
						</Button>
					</Col>
					<ChildrenModal
						modal={modalActive}
						toggle={toggle}
						modalTitle={"Pack & Attachments"}
						size="lg"
					>
						<AttachmentsCheckBoxTable
							data={attachments}
							packOptions={packs}
							toggle={toggle}
						/>
					</ChildrenModal>
				</CardBody>
			</Card>
		</>
	);
};

export default LenderPacket;

const AttachmentsCheckBoxTable = ({ data, packOptions, toggle }) => {
	const { dealID, inventoryID } = useCurrentDealStore(
		(state) => ({
			dealID: state.deal.ID,
			inventoryID: state.deal.carRecNum,
		}),
		shallow
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const createParamsList = (packObj) => {
		let paramsList = {};
		packObj.forms.forEach((form) => {
			paramsList[form.id] = { dealerID, locationID };
		});
		return paramsList;
	};

	const [list, setList] = useState(data);
	const [checkAll, setCheckAll] = useState(false);
	const [packSelect, setPackSelect] = useState(packOptions[0]);

	const { setLenderPackRequest } = useContext(PackRequestContext);

	useEffect(() => {
		setLenderPackRequest(() => {
			const paramsList = createParamsList(packOptions[0].pack);
			const ids = getSelectedIDs();
			return {
				dealID: dealID,
				packID: packOptions[0].value,
				attachmentIDs: ids,
				inventoryID,
				paramsList,
			};
		});
		// eslint-disable-next-line
	}, []);

	// Select/ UnSelect Table rows
	const onMasterCheck = (e) => {
		let tempList = list;
		// Check/ UnCheck All Items
		// this might not apply to attachemnts though i might be able to append this data to each obj
		tempList.map((user) => (user.selected = e.target.checked));

		//Update State
		setList(tempList);
		setCheckAll(e.target.checked);
	};

	const getSelectedIDs = () => {
		const selected = list.filter((e) => e.selected);
		const ids = selected.map((e) => {
			return e.ID;
		});
		setLenderPackRequest((prevState) => {
			return { ...prevState, attachmentIDs: ids };
		});
		return ids;
	};

	return (
		<div className="row">
			<div className="col-md-12">
				<Row className="mb-3">
					<Label className="h3">Form Packs</Label>
					<Select
						className="mb-3 w-100"
						onChange={(option) => {
							const paramsList = createParamsList(option.pack);
							setLenderPackRequest((prevState) => ({
								...prevState,
								packID: option.value,
								paramsList,
							}));
							setPackSelect(option);
						}}
						value={packSelect}
						options={packOptions}
					/>
				</Row>
				<div style={{ overflowX: "auto" }}>
					<Table>
						<thead>
							<tr>
								<th>#</th>
								<th>Form</th>
							</tr>
						</thead>
						<tbody>
							{packSelect &&
								packSelect.pack.forms.map((obj, index) => (
									<tr key={obj.id}>
										<td>{index + 1}</td>
										<td>{obj.title}</td>
									</tr>
								))}
						</tbody>
					</Table>
				</div>
				{list.length > 0 ? (
					<div style={{ overflowX: "auto" }}>
						<Table>
							<thead>
								<tr>
									<th scope="col">
										<input
											type="checkbox"
											checked={checkAll}
											id="mastercheck"
											onChange={onMasterCheck}
										/>
									</th>
									<th scope="col">File</th>
									<th scope="col">Category</th>
									<th scope="col">Description</th>
								</tr>
							</thead>
							<tbody>
								{list.map((obj, index) => (
									<tr
										key={obj.ID}
										onClick={() => {
											const tempVal = !obj.selected;
											setList((currentList) => {
												const newState = [...currentList];
												newState[index].selected = tempVal;
												return newState;
											});
										}}
									>
										<th scope="row">
											<input
												type="checkbox"
												checked={obj.selected}
												id={`rowcheck${obj.ID}`}
												onChange={(e) => {
													const tempVal = e.target.checked;
													setList((currentList) => {
														const newState = [...currentList];
														newState[index].selected = tempVal;
														return newState;
													});
												}}
											/>
										</th>
										<td>{obj.fileName}</td>
										<td>{obj.category}</td>
										<td>{obj.description}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				) : (
					<div className="mb-5 text-center">
						<h4>
							<span role="img" aria-label="paperclip emoji">
								📎{" "}
							</span>
							No Attachments
						</h4>
						<p>Add an attachment to your deal to send it to a lender.</p>
					</div>
				)}
				<Row className="justify-content-center">
					<span onClick={getSelectedIDs}>
						<FormsEmailProvider>
							<EmailPackRequestButton
								packObj={packSelect.pack}
								dealID={dealID}
							/>
						</FormsEmailProvider>
					</span>
					<span onClick={getSelectedIDs}>
						<PackRequestButton
							packObj={packSelect.pack}
							dealID={dealID}
							attach
						/>
					</span>
				</Row>
			</div>
		</div>
	);
};
