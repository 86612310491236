import React, { useContext } from "react";
import { Table, Button, Col, CardTitle } from "reactstrap";
import { Card, CardHeader, CardBody } from "reactstrap";

import useCurrentDealStore from "stores/DealStore";
import NumberFormat from "react-number-format";
import { deleteTrades } from "api/TradeAPI";
import { showApiError } from "utils/errorRoutingUtils";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteTradeAlert,
} from "utils/alertUtils";
import shallow from "zustand/shallow";
import { TradeContext } from "./TradeContext";

const TradeTable = () => {
	const { trades, editTrades, calculate } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
			editTrades: state.editTrades,
			calculate: state.calculate,
		}),
		shallow
	);
	const { toggleTradeModal, setCurrentIndex, editIsLoading } =
		useContext(TradeContext);
	// Modal
	const toggleDeleteModal = (currentID, currentIndex) => {
		confirmDeleteTradeAlert(removeTrade, currentID, currentIndex);
	};

	const removeTrade = (ID, index) => {
		console.log(index);
		if (ID) {
			deleteTrades(ID).then(
				(res) => {
					console.log(res);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
		let obj = [];
		if (ID) {
			obj = trades.filter((item) => item.ID !== ID);
		} else {
			obj = trades.filter((item) => item.currentIndex !== index);
		}

		editTrades(obj);
		setCurrentIndex(obj.length);
		calculate();
		deleteSuccessAlert();
	};

	const tableData = trades.map((obj, index) => (
		<>
			<tr key={index}>
				<th scope="row">{index + 1}</th>
				<td>
					{" "}
					<NumberFormat
						value={obj.allowance}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				</td>
				<td>
					{" "}
					<NumberFormat
						value={obj.payoff}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				</td>
				<td>
					{" "}
					<NumberFormat
						value={obj.acv}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				</td>
				<td>{obj?.car?.year + " " + obj?.car?.make + " " + obj?.car?.model}</td>
				<td>{obj?.car?.stockNo}</td>
				<td>{obj?.car?.vin}</td>
				<td style={{ alignContent: "right" }}>
					<Button
						color="success"
						onClick={() => {
							setCurrentIndex(index);
							editIsLoading(true);
							toggleTradeModal();
						}}
					>
						Edit
					</Button>

					<Button
						color="danger"
						onClick={() => {
							console.log(obj.currentIndex);
							toggleDeleteModal(obj.ID, index);
						}}
					>
						Remove
					</Button>
				</td>
			</tr>
		</>
	));

	return (
		<>
			{tableData.length > 0 ? (
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Trade-Ins</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Table striped bordered hover responsive>
								<thead>
									<tr>
										<th>#</th>
										<th>Allowance</th>
										<th>Payoff</th>
										<th>Acv</th>
										<th>Car</th>
										<th>Stock</th>
										<th>VIN</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>{tableData}</tbody>
							</Table>
						</Col>
					</CardBody>
				</Card>
			) : (
				<h5 className="text-center my-3">No trades for this deal.</h5>
			)}
		</>
	);
};

export default TradeTable;
