import React from "react";
import { Button } from "reactstrap";

import useBuildLoginInfo from "../../../../hooks/useBuildLoginInfo";

import { createNewDcLogin } from "../../../../../../api/LoginAPI";
import {
	saveSuccessAlert,
	failAlert,
	saveFailAlert,
} from "../../../../../../utils/alertUtils";
import { saveUserDetails } from "api/LoginAPI";
import { showApiError } from "../../../../../../utils/errorRoutingUtils";
import { useCompanyEmployeeData } from "./CompanyEmployeesContext";

import { useFormContext } from "react-hook-form";

const SaveCompanyEmployee = ({ toggle }) => {
	const buildLoginInfo = useBuildLoginInfo();

	// Employee context
	const {
		userID,
		imageFile,
		imageData,
		users,
		setNewUserPassword,
		setNewUserConfirmPass,
		setUsers,
		getNewUserFields,
	} = useCompanyEmployeeData();

	// Checks whether to call for saving an existing employee or creating a new one
	const submitForm = () => {
		if (userID) {
			setNewUserPassword(null);
			setNewUserConfirmPass(null);
			saveEmployee();
		} else {
			createNewEmployee();
		}
	};

	// Saves an existing employee information
	const saveEmployee = () => {
		var formData = new FormData();
		const loginInfo = buildLoginInfo();
		formData.append("image", imageFile);
		formData.append(
			"user",
			new Blob(
				[
					JSON.stringify({
						...getNewUserFields(),
						dealerID: loginInfo.dcLogin.dealerID,
						locationID: loginInfo.dcLogin.locationID,
					}),
				],
				{
					type: "application/json",
				}
			)
		);
		formData.append("profileData", imageData != null ? "" : null);

		saveUserDetails(formData).then(
			(res) => {
				console.log(res);
				const updatedUserList = users.map((user) => {
					if (user.ID === userID) {
						return res.data.content.user;
					} else {
						return user;
					}
				});

				setUsers(updatedUserList);
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Creates a new employee
	const createNewEmployee = () => {
		var formData = new FormData();
		const loginInfo = buildLoginInfo();
		formData.append("image", imageFile);
		formData.append(
			"user",
			new Blob(
				[
					JSON.stringify({
						...loginInfo.dcLogin,
					}),
				],
				{
					type: "application/json",
				}
			)
		);
		formData.append(
			"newUser",
			new Blob([JSON.stringify({ ...getNewUserFields() })], {
				type: "application/json",
			})
		);

		createNewDcLogin(formData).then(
			(res) => {
				console.log(res);
				setUsers([...users, res.data.content]);
				toggle();
				saveSuccessAlert(res.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<div className="d-flex justify-content-center align-items-center">
			<Button onClick={methods.handleSubmit(submitForm)}>
				{userID ? "Save Employee" : "Create Employee Account"}
			</Button>
		</div>
	);
};

export default SaveCompanyEmployee;
