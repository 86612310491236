import React from "react";
import PropTypes from "prop-types";
import { Table, Button, CardBody, Card } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";
import dayjs from "dayjs";
import { DealStatus } from "constants/Constants";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
	saveFailAlert,
} from "utils/alertUtils";
import { deletePickupPayment } from "api/DealAPI";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";
import { saveDeal } from "api/DealAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { displayError, displayPdf, formLoading, getPdf } from "api/FormsAPI";
import Swal from "sweetalert2";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import useSetDeal from "features/deal/hooks/useSetDeal";

/**
 * DownPaymentTable component renders a table of down payments with options to edit or delete each payment.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.toggle - Function to toggle the edit modal visibility.
 * @param {Function} props.setCurrentIndex - Function to set the current index for editing.
 * @returns {JSX.Element} The rendered DownPaymentTable component.
 */
const DownPaymentTable = ({ toggle, setCurrentIndex }) => {
	const { pickupPmts, type, editPickupPmts, calculate } = useCurrentDealStore(
		(state) => ({
			pickupPmts: state.pickupPmts,
			type: state.deal.type,
			editPickupPmts: state.editPickupPmts,
			calculate: state.calculate,
		}),
		shallow
	);

	const pickupPmtsHead = [
		"#",
		"Date Due",
		"Amount",
		"Date Paid",
		"Amount Paid",
		"Receipt #",
		"Actions",
	];

	/**
	 * Handles the confirmation and deletion of a pickup payment.
	 *
	 * @param {string} currentID - The ID of the payment to delete.
	 * @param {number} currentIndex - The index of the payment to delete.
	 */
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removePickup, currentID, currentIndex);
	};

	/**
	 * Deletes a pickup payment and updates the state.
	 *
	 * @param {string} ID - The ID of the payment to delete.
	 * @param {number} index - The index of the payment to delete.
	 */
	const removePickup = (ID, index) => {
		if (ID) {
			deletePickupPayment(ID).then(
				(response) => {
					deleteSuccessAlert();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}

		const updatedPickupPmts = pickupPmts.filter(
			(item) => item.currentIndex !== index
		);
		editPickupPmts(updatedPickupPmts);
		calculate();
		setCurrentIndex(updatedPickupPmts.length);
	};

	const tableHead = pickupPmtsHead.map((key, index) => (
		<th key={index}>{key}</th>
	));

	const rows = pickupPmts.map((payment, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{dayjs(payment.dateDue).format("MM/DD/YYYY")}</td>
			<td>
				<CurrencyDisplayElement val={payment.amount} />
			</td>
			<td>{dayjs(payment.datePaid).format("MM/DD/YYYY")}</td>
			<td>
				<CurrencyDisplayElement val={payment.amountPaid} />
			</td>
			<td>{payment.receiptNo}</td>
			<td className="text-center">
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="primary"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>
				<Button
					readOnly={type !== DealStatus.PENDING}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleModal(payment.ID, payment.currentIndex);
					}}
				>
					X
				</Button>
				<ReceiptButton paymentId={payment.ID} />
			</td>
		</tr>
	));

	return (
		<>
			{rows.length > 0 && (
				<Card className="my-3">
					<CardBody className="py-0">
						<div style={{ overflowX: "auto" }}>
							<Table striped bordered hover>
								<thead>
									<tr>{tableHead}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</div>
					</CardBody>
				</Card>
			)}
		</>
	);
};

DownPaymentTable.propTypes = {
	toggle: PropTypes.func.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
};

export default DownPaymentTable;

/**
 * ReceiptButton component renders a button to print a receipt for a specific payment.
 *
 * @param {Object} props - The component props.
 * @param {string} props.paymentId - The ID of the payment for which to print the receipt.
 * @returns {JSX.Element} The rendered ReceiptButton component.
 */
const ReceiptButton = ({ paymentId }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	/**
	 * Handles the printing of a receipt for the specified payment.
	 */
	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID,
			inventoryID: null,
			dcReportID: 313, // ID for pickup payment receipt in dcReport
			params: { dealerID, locationID, paymentId },
		};

		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						displayPdf(response);
						Swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<Button className="btn-sm" onClick={handlePrint}>
			Print
		</Button>
	);
};

ReceiptButton.propTypes = {
	paymentId: PropTypes.string.isRequired,
};
