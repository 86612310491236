/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch, Redirect } from "react-router-dom";

import DefaultNavbar from "../navBar/DefaultNavbar";
import Sidebar from "../sidebar/Sidebar";

import devRoutes from "routes/DevRoutes";
import Footer from "../footer/Footer";

//import { useIdleTimer } from "react-idle-timer";
//import { idleAlert } from "components/s/dc/alerts/SweetAlerts";

const Dev = (props) => {
	const backgroundColor = "black";
	const activeColor = "info";

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			return (
				<Route
					exact
					strict
					path={prop.layout + prop.path}
					component={prop.component}
					key={key}
				/>
			);
		});
	};

	const handleMiniClick = () => {
		document.body.classList.toggle("sidebar-mini");
	};

	// idleTimer code
	//15 minutes
	//const timeout = 1000 * 60 * 10;
	// const timeout = 3000;
	// const handleOnActive = () => {
	// 	reset();
	// };
	// const handleOnIdle = () => {
	// 	idleAlert();
	// };

	// const { reset } = useIdleTimer({
	// 	// timeout,
	// 	// onActive: handleOnActive,
	// 	// onAction: handleOnActive,
	// 	// onIdle: handleOnIdle,
	// 	// eventsThrottle: 1000,
	// });
	// const [maxNavBarWidth, setMaxNavBarWidth] = useState(1600);
	const [currentWindowWidth, setCurrentWindowWidth] = useState(
		window.innerWidth
	);

	useEffect(() => {
		if (props.history.action === "PUSH") {
			document
				.getElementById("main-wrapper")
				.scrollTo({ top: 0, behavior: "auto" });
		}
	});

	// Collapses the navbar if window is at or below a certain width
	const handleResize = () => {
		if (window.innerWidth !== 991) {
			if (window.innerWidth <= 1920) {
				document.body.classList.add("sidebar-mini");
			} else {
				document.body.classList.remove("sidebar-mini");
			}

			setCurrentWindowWidth(window.innerWidth);
		}
	};

	// When the window resizes, check and see if we should collapse the navbar
	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, [currentWindowWidth]);

	// Check to see if we need to collapse the navbar
	useEffect(() => handleResize(), []);

	return (
		<div id="main-wrapper" className="wrapper">
			<Sidebar
				{...props}
				routes={devRoutes}
				bgColor={backgroundColor}
				activeColor={activeColor}
			/>
			<div className="main-panel">
				{" "}
				<DefaultNavbar handleMiniClick={handleMiniClick} />
				<Switch>
					{getRoutes(devRoutes)}
					<Redirect to="/dev/dashboard" />
				</Switch>
				{
					// we don't want the Footer to be rendered on full screen maps page
					props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
						<Footer fluid />
					)
				}
			</div>
		</div>
	);
};

export default Dev;
