import React from "react";
import { Card, CardBody, CardTitle, Button, CardImg } from "reactstrap";
import aulImage from "assets/img/aulLogo.png";

/**
 * Component for rendering an AUL sign-up button.
 */
const AULButton = () => {
	/**
	 * Opens a link to the AUL sign-up PDF in a new tab.
	 */
	const commitData = () => {
		window.open(
			"https://dealerclick.com/aul.pdf",
			"_blank",
			"noopener,noreferrer"
		);
	};

	return (
		<Card
			className="text-center mt-4 shadow-sm"
			style={{ maxWidth: "18rem", margin: "auto" }}
		>
			<CardBody>
				<div className="mb-3">
					<CardImg
						top
						width="100%"
						src={aulImage}
						alt="AUL Logo"
						style={{ maxWidth: "100px", margin: "auto" }}
					/>
				</div>
				{/* <CardTitle tag="h5" className="mb-3">
					AUL Sign-Up
				</CardTitle> */}
				<Button size="sm" color="primary" onClick={commitData}>
					Sign Up
				</Button>
			</CardBody>
		</Card>
	);
};

export default AULButton;
