import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, TabContent, TabPane, Nav, Card } from "reactstrap";
import classnames from "classnames";
import { useBuildAccount } from "./useBuildAccount";
import { AccountContent } from "./AccountContent";
import AccountTitle from "./AccountTitle";
import AccountPayments from "./AccountPayments";
import AccountAmortizationTable from "./AccountAmortizationTable";
import AccountAdjustmentsTable from "./AccountAdjustmentsTable";
import AccountLatesTable from "./AccountLatesTable";
import useIsOwnerOrAdmin from "../features/settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import AccountDeferredTable from "./AccountDeferredTable";
import Tabs from "../components/tabs/Tabs";
import AccountFormsTab from "./AccountFormsTab";
import AccountSettings from "./AccountSetting";

const IN_DEVELOPMENT = process.env.NODE_ENV === "development";

/**
 * Component for displaying account-related tabs and their content.
 */
const AccountTabs = () => {
	const getAcct = useBuildAccount();
	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const [openForm, setOpenForm] = useState(false);
	const toggleOpenForm = () => setOpenForm(!openForm);
	const [tabComponents, setTabComponents] = useState([]);
	const tabs = [
		"Desking",
		"Amortizations",
		"Adjustments",
		"Lates",
		"Deferred(Pickup)",
		"Payments",
		"Reports",
		"Settings",
	];

	useEffect(() => {
		setTabComponents([
			<AccountContent />,
			<AccountAmortizationTable />,
			<AccountAdjustmentsTable />,
			<div className="pt-8 col-md-12 d-flex justify-content-center">
				<Card>
					<AccountLatesTable />
				</Card>
			</div>,
			<AccountDeferredTable />,
			<AccountPayments />,
			<AccountFormsTab />,
			<AccountSettings />,
		]);
	}, [isOwnerOrAdmin]);

	return (
		<div>
			<AcctTabsHolder
				tabs={tabs}
				tabComponents={tabComponents}
				modalIndex={3}
				toggleOpen={toggleOpenForm}
				open={openForm}
			/>
			{IN_DEVELOPMENT && (
				<Col sm="1">
					<button
						style={{
							position: "fixed",
							top: "4rem",
							right: "2rem",
							zIndex: "9999",
						}}
						className="btn-sm"
						onClick={() => {
							const acct = getAcct();
							console.log(acct);
						}}
					>
						JSON Acct
					</button>
				</Col>
			)}
		</div>
	);
};

/**
 * Tab holder component for the AccountTabs.
 *
 * @param {Object} props - Component props
 * @param {Array} props.tabs - Array of tab names
 * @param {Array} props.tabComponents - Array of tab content components
 * @param {number} props.modalIndex - Index of the tab that triggers a modal
 * @param {Function} props.toggleOpen - Function to toggle the modal
 */
const AcctTabsHolder = ({ tabs, tabComponents, modalIndex, toggleOpen }) => {
	const [activeTab, setActiveTab] = useState(0);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const navTabs = tabs.map((tab, index) => (
		<Tabs
			id={index}
			key={index}
			tabName={tab}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => {
				if (index === modalIndex) {
					toggleOpen();
					toggle(index);
				} else {
					toggle(index);
				}
			}}
		/>
	));

	const navTabContents = tabComponents.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));

	return (
		<>
			<AccountTitle />
			<div className="w-100">
				<Nav
					className="nav-pills nav-pill-bar nav nav-pills-primary justify-content-center"
					role="tablist"
				>
					{navTabs}
				</Nav>
				<TabContent activeTab={activeTab}>{navTabContents}</TabContent>
			</div>
		</>
	);
};

AcctTabsHolder.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
	tabComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
	modalIndex: PropTypes.number.isRequired,
	toggleOpen: PropTypes.func.isRequired,
};

export default AccountTabs;
