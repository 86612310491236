// DealTermsModal.js
import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

/**
 * DealTermsModal component displays the terms of a selected deal.
 *
 * @param {Object} props - The component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {function} props.toggle - Function to toggle the modal
 * @param {Object} props.selectedDeal - The selected deal object
 * @returns {JSX.Element} The DealTermsModal component
 */
const DealTermsModal = ({ isOpen, toggle, selectedDeal }) => {
	return (
		<Modal isOpen={isOpen} toggle={toggle}>
			<ModalHeader style={{ border: "none" }} toggle={toggle}>
				Deal Terms
			</ModalHeader>
			<ModalBody>
				{selectedDeal ? (
					<>
						{selectedDeal.term && <p>Term: {selectedDeal.term} months</p>}
						{selectedDeal.apr && <p>APR: {selectedDeal.apr}%</p>}
						{selectedDeal.monthly_payment && (
							<p>Monthly Payment: ${selectedDeal.monthly_payment}</p>
						)}
						{selectedDeal.down_payment && (
							<p>Down Payment: ${selectedDeal.down_payment}</p>
						)}
						{selectedDeal.end_date && <p>End Date: {selectedDeal.end_date}</p>}
					</>
				) : (
					<p>No deal selected</p>
				)}
			</ModalBody>
			<ModalFooter
				className="justify-content-center d-flex"
				style={{ border: "none" }}
			>
				<Button color="primary" size="sm" onClick={toggle}>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};

DealTermsModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	selectedDeal: PropTypes.shape({
		term: PropTypes.number,
		apr: PropTypes.number,
		monthly_payment: PropTypes.number,
		down_payment: PropTypes.number,
		end_date: PropTypes.string,
	}),
};

export default DealTermsModal;
