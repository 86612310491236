import React from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const ServiceContractBreakdown = () => {
	const { warranty } = useCurrentSaleStore((state) => state, shallow);
	const { warrantyList } = useCurrentDealStore(
		(state) => ({
			warrantyList: state.warranty,
		}),
		shallow
	);
	const { warrantyCost } = useCurrentRecapStore((state) => state, shallow);

	const AllServConFees = () => {
		const fees = warrantyList.map((key) => {
			return (
				<Row>
					<Col>
						<Label>{key.description}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.cost} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<AllServConFees />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={warranty} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={warrantyCost} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default ServiceContractBreakdown;
