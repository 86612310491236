import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import DMVModal from "../modals/dmv/DMVModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const DMVFee = () => {
	const [openDMVModal, setOpenDMVModal] = useState(false);
	const { totalDMVFees } = useCurrentSaleStore(
		(state) => ({
			totalDMVFees: state.totalDMVFees,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setOpenDMVModal(!openDMVModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			<DMVModal modal={openDMVModal} toggle={toggle} />
			<div className="col-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="DMV Fees"
					toggle={toggle}
				/>
			</div>
			<div className="col-6 pr-0 text-center pl-2">
				<span onClick={toggle}>
					<NumberFormat
						value={totalDMVFees}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						readOnly={true}
						customInput={Input}
					/>
				</span>
			</div>
		</Row>
	);
};

export default DMVFee;
