import React, { useState, useContext, useEffect } from "react";
import shallow from "zustand/shallow";
import { saveVehicle } from "../../../api/InventoryAPI";
import { saveFailAlert, saveSuccessAlert } from "../../../utils/alertUtils";
import { showApiError } from "../../../utils/errorRoutingUtils";
import useBuildVehicle from "../../../hooks/useBuildVehicle";
import useCurrentVehicleStore from "../../../stores/InventoryStore";
import InventoryKbbTab from "../subFeatures/inventoryTabs/valuesTab/InventoryKbbTab";
import {
	KbbContext,
	KbbValuesProvider,
} from "../subFeatures/inventoryTabs/valuesTab/KbbContext";
import { vehicleStatus } from "../../../constants/Constants";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import MMRForm from "../subFeatures/inventoryTabs/valuesTab/MMRForm";
import { Col, Container, Row } from "reactstrap";
import { MmrContext, MMRValuesProvider } from "../../../context/MMRContext";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import InventoryValuesTab from "../subFeatures/inventoryTabs/valuesTab/InventoryValuesTab";
import InventoryHistoryTab from "../subFeatures/inventoryTabs/historyTab/InventoryHistoryTab";

const isAppraisalPage = true;

const InventoryAppraisalPage = () => {
	return (
		<AppraisalWrapper>
			<AppraisalContent />
		</AppraisalWrapper>
	);
};

export default InventoryAppraisalPage;

const AppraisalWrapper = ({ children }) => {
	return (
		<KbbValuesProvider>
			<MMRValuesProvider>{children}</MMRValuesProvider>
		</KbbValuesProvider>
	);
};

const AppraisalContent = () => {
	// Progress spinner state
	const [showSubmitProgress, setShowSubmitProgress] = useState(false);
	// kbb vehicle values
	const {
		reset,
		inventoryID,
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	} = useCurrentVehicleStore((state) => state, shallow);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};
	const locations = useFromUser("locations");

	const { getDcKbbObj, setCondition, reset: resetKbb } = useContext(KbbContext);
	const { vehicleMMRValues, setVehicleMMRValues } = useContext(MmrContext);

	const mmrValues = {
		id: null,
		inventoryID: inventoryID,
		dealerID: locations.dealerID,
		locationID: locations.ID,
		adjWhAbv: 0,
		adjWhAvg: 0,
		adjWhBlw: 0,
		adjRtAbv: 0,
		adjRtAvg: 0,
		adjRtBlw: 0,
		whAbv: 0,
		whAvg: 0,
		whBlw: 0,
		rtAbv: 0,
		rtAvg: 0,
		rtBlw: 0,
		avgOdometer: 0,
		avgGrade: 0,
		reqDate: null,
		retDate: null,
	};

	useEffect(() => {
		reset();
		resetKbb();
		setVehicleMMRValues(mmrValues);
		return () => {
			reset();
			resetKbb();
		};
		// eslint-disable-next-line
	}, []);
	// Vehicle obj
	const getVehicle = useBuildVehicle();

	// Saves a vehicle without any images (can be new or existing)
	const saveData = () => {
		setShowSubmitProgress(true);
		const inventoryVehicle = getVehicle();
		console.log(inventoryVehicle);
		inventoryVehicle.stockNo = vin.slice(11);
		inventoryVehicle.status = vehicleStatus.APPRAISAL;
		const kbb = getDcKbbObj();

		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
			mmr: vehicleMMRValues,
		};
		saveVehicle(request).then(
			(response) => {
				setCondition("Good");
				reset();
				resetKbb();
				saveSuccessAlert();
				setShowSubmitProgress(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
					setShowSubmitProgress(false);
				}
			}
		);
	};
	return (
		<div className="content">
			{(showSubmitProgress && <LoadingSpinner />) || (
				<>
					<InventoryHistoryTab />,
					<InventoryValuesTab
						inventoryID={inventoryID}
						vehicleKbbObj={vehicleKbbObj}
						showVinDecoder={isAppraisalPage}
					/>
					<div className="btn btn-success float-button" onClick={saveData}>
						<i className="nc-icon nc-check-2"></i>&nbsp;Save
					</div>
				</>
			)}
		</div>
	);
};
