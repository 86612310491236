import React, {
	useState,
	useMemo,
	useEffect,
	useRef,
	useCallback,
} from "react";
import {
	Input,
	FormGroup,
	Label,
	Col,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";

/**
 * SortableCardList Component
 * @param {Object} props - Component properties
 * @param {Array} props.data - The data to display in the card list
 * @param {Array} props.sortOptions - Options for sorting the data
 * @param {Function} props.renderCard - Function to render each card
 * @param {Array} props.searchFields - Fields to search in the data
 * @param {boolean} props.focusSearch - Whether to focus on the search input on mount
 * @param {string} props.containerClassName - Class name for the container
 */
const SortableCardList = ({
	data,
	sortOptions,
	renderCard,
	searchFields,
	focusSearch,
	containerClassName,
}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [sortIndex, setSortIndex] = useState(0);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const searchInputRef = useRef(null);

	const { key: sortKey, order: sortOrder } = sortOptions[sortIndex];

	// Filter and sort data based on search term and selected sort option
	const filteredData = useMemo(() => {
		return data
			.filter((item) =>
				searchFields.some((field) =>
					item[field].toLowerCase().includes(searchTerm.toLowerCase())
				)
			)
			.sort((a, b) => {
				const valueA = a[sortKey];
				const valueB = b[sortKey];
				return sortOrder === "asc"
					? valueA.localeCompare(valueB)
					: valueB.localeCompare(valueA);
			});
	}, [data, searchTerm, sortKey, sortOrder, searchFields]);

	// Handle search input change
	const handleSearchChange = useCallback((event) => {
		setSearchTerm(event.target.value);
	}, []);

	// Handle sort option change
	const handleSortChange = useCallback((index) => {
		setSortIndex(index);
		setDropdownOpen(false);
	}, []);

	// Toggle dropdown open state
	const toggleDropdown = useCallback(() => {
		setDropdownOpen((prevState) => !prevState);
	}, []);

	// Focus search input on mount if focusSearch is true
	useEffect(() => {
		if (focusSearch && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [focusSearch]);

	return (
		<Col>
			<div className="flex-wrap d-flex justify-content-center grid-gap-4 mt-2">
				<div className="p-1">
					<FormGroup className="mb-1">
						<div className="d-flex align-items-center">
							<Label className="d-contents fs-5" htmlFor="searchInput">
								<i className="nc-icon nc-zoom-split noMobile searchIcon" />
							</Label>
							<Input
								id="searchInput"
								type="text"
								value={searchTerm}
								onChange={handleSearchChange}
								placeholder="Search..."
								innerRef={searchInputRef}
								className="border border-light"
							/>
						</div>
					</FormGroup>
				</div>
				<div className="p-1">
					<ButtonDropdown
						className="btn-primary br-6"
						isOpen={dropdownOpen}
						toggle={toggleDropdown}
					>
						<DropdownToggle caret>Sort</DropdownToggle>
						<DropdownMenu>
							{sortOptions.map((option, index) => (
								<DropdownItem
									key={`${option.key}-${option.order}`}
									onClick={() => handleSortChange(index)}
								>
									{option.label}
								</DropdownItem>
							))}
						</DropdownMenu>
					</ButtonDropdown>
				</div>
			</div>
			<hr className="my-2" />
			<div className={containerClassName}>
				{filteredData.map((item) => renderCard(item))}
			</div>
		</Col>
	);
};

export default React.memo(SortableCardList);
