import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getNumberOfDealsByDateRange } from "api/DealAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import PageHeader from "components/header/PageHeader";
import useDateRangeStore from "stores/DateRangeStore";

/**
 * Formats a card status string by replacing commas with " & ".
 *
 * @param {string} status - The status string to format.
 * @returns {string} - The formatted status string.
 */
const formatCardStatus = (status) => {
	if (typeof status !== "string") {
		return status;
	}
	return status.replace(/,/g, " & ");
};

/**
 * Component for displaying deal header with status filters.
 *
 * @param {Object} props - Component props
 * @param {Array<string>} props.statusList - List of deal statuses
 * @param {Function} props.setStatusList - Function to set the status list
 * @returns {JSX.Element} The rendered component.
 */
const DealHeader = ({ statusList, setStatusList }) => {
	const { fromDate, toDate } = useDateRangeStore();
	//eslint-disable-next-line
	const [totalDeal, setTotalDeal] = useState([]);
	const locationID = useFromUser("locationID");
	const [pending, setPending] = useState(0);
	const [sold, setSold] = useState(0);
	const [deleted, setDeleted] = useState(0);
	const [funded, setFunded] = useState(0);
	const [repo, setRepo] = useState(0);
	const [status, setStatus] = useState("Pending");

	/**
	 * Handle click event on a card to set the status.
	 *
	 * @param {string} cardStatus - The status of the card clicked.
	 * @returns {Function} The click event handler.
	 */
	const handleCardClick = (cardStatus) => (e) => {
		e.preventDefault();
		setStatusList(cardStatus);
		setStatus(cardStatus);
	};

	/**
	 * Format data and update state based on content received.
	 *
	 * @param {object} content - The content to format.
	 * @param {Function} setHook - The state setter function.
	 * @returns {JSX.Element[]} The formatted content as JSX elements.
	 */
	const dataFormat = (content, setHook) => {
		let total = 0;

		setPending(0);
		setDeleted(0);
		setSold(0);
		setFunded(0);

		const container = Object.keys(content).map((key) => {
			total += content[key].numOfType;
			switch (content[key].typeDesc) {
				case "Pending":
					setPending(content[key].numOfType);
					break;
				case "Deleted":
					setDeleted(content[key].numOfType);
					break;
				case "Sold":
					setSold(content[key].numOfType);
					break;
				case "Funded":
					setFunded(content[key].numOfType);
					break;
				case "Repo":
					setRepo(content[key].numOfType);
					break;
				default:
					break;
			}

			return (
				<div key={key}>
					{content[key].typeDesc} : {content[key].numOfType}
				</div>
			);
		});
		setHook(total);
		return container;
	};

	/**
	 * Fetch the list data based on location ID.
	 *
	 * @param {string} LocationID - The location ID to fetch data for.
	 */
	const getListData = (LocationID) => {
		if (locationID) {
			getNumberOfDealsByDateRange(LocationID, fromDate, toDate).then(
				(response) => {
					dataFormat(response.data.content, setTotalDeal);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		getListData(locationID);
		// eslint-disable-next-line
	}, [fromDate, toDate]);

	const formattedCardStatus = formatCardStatus(status);

	return (
		<PageHeader
			titlePrefix="Deals"
			title={formattedCardStatus}
			cards={[
				{
					title: "Pending",
					count: pending,
					iconClass: "nc-icon nc-briefcase-24 text-warning",
					iconText: " Add New",
					routePath: "/deal-add",
					onCardClick: handleCardClick("Pending"),
					isActive: status === "Pending",
				},
				{
					title: "Sold",
					count: sold,
					iconClass: "nc-icon nc-briefcase-24 text-success",
					iconText: " Add New",
					routePath: "/deal-add",
					onCardClick: handleCardClick("Sold"),
					isActive: status === "Sold",
				},
				{
					title: "Funded",
					count: funded,
					iconClass: "nc-icon nc-briefcase-24 text-info",
					iconText: " Add New",
					routePath: "/deal-add",
					onCardClick: handleCardClick("Funded"),
					isActive: status === "Funded",
				},
				{
					title: "Deleted & Repo",
					count: deleted + repo,
					iconClass: "nc-icon nc-briefcase-24 text-danger",
					iconText: " Add New",
					routePath: "/deal-add",
					onCardClick: handleCardClick("Deleted,Repo"),
					isActive: status === "Deleted,Repo",
				},
			]}
		/>
	);
};

DealHeader.propTypes = {
	statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
	setStatusList: PropTypes.func.isRequired,
};

export default DealHeader;
