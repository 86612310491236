import React, { useState } from "react";
import ChildrenModal from "components/modals/ChildrenModal";
import CustomerDealDetails from "../CustomerDealDetails";
import CustomerListPage from "features/crm/subFeatures/contactListPage/CustomerListPage";
import ContactAddPage from "features/crm/components/CustomerAddPage";
import { failAlert } from "utils/alertUtils";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import OpenModalButton from "features/deal/components/OpenModalButton";

const CoBuyerModal = () => {
	// Local state
	const [addModal, setAddModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [newModal, setNewModal] = useState(false);

	// Deal store
	const {
		buyer,
		coBuyerRecNum,
		type,
		editCoBuyer,
		editCoBuyerRecNum,
		editCoBuyerAddress,
		editCoBuyerMailAddress,
	} = useCurrentDealStore(
		(state) => ({
			buyer: state.buyer,
			coBuyer: state.coBuyer,
			coBuyerRecNum: state.deal.coBuyerRecNum,
			type: state.deal.type,
			editCoBuyer: state.editCoBuyer,
			editCoBuyerRecNum: state.editCoBuyerRecNum,
			editCoBuyerAddress: state.editCoBuyerAddress,
			editCoBuyerMailAddress: state.editCoBuyerMailAddress,
		}),
		shallow
	);

	// Modal toggle functions
	const toggleAdd = () => {
		if (!buyer.ID) {
			failAlert("Must add Buyer before entering Co-Buyer");
		} else {
			setAddModal(!addModal);
		}
	};

	const toggleEdit = () => {
		setEditModal(!editModal);
	};
	const toggleNew = () => {
		if (!buyer.ID) {
			failAlert("Must add Buyer before entering Co-Buyer");
		} else {
			setNewModal(!newModal);
		}
	};

	const toggleDelete = () => {
		editCoBuyerRecNum(null);
		editCoBuyer({});
	};

	// Update deal store with new customer's info
	const updateNewCustomer = (data) => {
		editCoBuyerRecNum(data.customer.ID);
		editCoBuyer(data.customer);
		editCoBuyerAddress(data.currentAddress);
		editCoBuyerMailAddress(data.mailingAddress);
		toggleNew();
	};

	return (
		<div style={{ paddingTop: "4px" }}>
			{(type === DealStatus.PENDING && coBuyerRecNum == null && (
				<div className="mx-auto">
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="link"
						onClick={toggleNew}
						buttonText="Add New"
						iconClass="nc-icon nc-simple-add"
					/>
					<OpenModalButton
						buttonClass="btn-sm"
						buttonColor="primary"
						onClick={toggleAdd}
						buttonText="Select"
						icon={true}
						iconClass="nc-icon nc-tap-01"
					/>
				</div>
			)) ||
				(coBuyerRecNum !== null && (
					<>
						<div className="mx-auto">
							<OpenModalButton
								buttonClass="btn-sm"
								buttonColor="primary"
								onClick={toggleEdit}
								buttonText="View"
								iconClass="nc-icon nc-paper"
							/>
							{type === DealStatus.PENDING && (
								<>
									<OpenModalButton
										buttonClass="btn-link-danger btn-sm"
										onClick={toggleDelete}
										iconClass="nc-icon nc-simple-remove"
										buttonText="Remove"
									/>
								</>
							)}
						</div>
					</>
				))}
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				modalTitle="Add New Contact"
				children={
					<ContactAddPage
						updateDeal={updateNewCustomer}
						isDeal={true}
						isBuyer={false}
					/>
				}
			/>
			<ChildrenModal
				modal={addModal}
				toggle={toggleAdd}
				modalTitle="Select Contact"
				children={
					<CustomerListPage
						isDeal={true}
						toggle={toggleAdd}
						editCustomer={editCoBuyer}
						editAddress={editCoBuyerAddress}
						editMailAddress={editCoBuyerMailAddress}
						editRecNum={editCoBuyerRecNum}
					/>
				}
			/>
			<ChildrenModal
				modal={editModal}
				toggle={toggleEdit}
				modalTitle="Edit Current Contact"
				children={
					<CustomerDealDetails
						ID={coBuyerRecNum}
						editCustomer={editCoBuyer}
						editAddress={editCoBuyerAddress}
						editMailAddress={editCoBuyerMailAddress}
						editRecNum={editCoBuyerRecNum}
						toggle={toggleEdit}
						cloneCustomer={false}
						type={type}
					/>
				}
			/>
			{type !== DealStatus.PENDING && coBuyerRecNum == null && (
				<p className="d-flex justify-content-center required">
					No Co-buyer was added to this deal
				</p>
			)}
		</div>
	);
};

export default CoBuyerModal;
