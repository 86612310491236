// DealListPage.js
import React, { useState } from "react";
import { TemplateVariableProvider } from "context/TemplateVariableContext";
import InitializeTemplateVars from "utils/initializeTemplateVars";
import DealListData from "./DealListData"; // Verify this import path is correct
import DealHeader from "./DealHeader";
import { MessageZone } from "../../../constants/Constants";

/**
 * DealListPage is the main component for displaying the list of deals.
 * It uses DealListData to fetch and display the data.
 */
const DealListPage = () => {
	// State to manage the status filter of the deal list
	const [statusList, setStatusList] = useState("Pending");

	return (
		<div className="content">
			{/* TemplateVariableProvider and InitializeTemplateVars provide necessary context and initialization for the deal list */}
			<TemplateVariableProvider>
				<InitializeTemplateVars messageZone={MessageZone.DEAL}>
					{/* DealHeader allows for controlling the status filter of the deal list */}
					<DealHeader setStatusList={setStatusList} />
					{/* DealListData component fetches and displays the deal data based on the selected status */}
					<DealListData statusList={statusList} />
				</InitializeTemplateVars>
			</TemplateVariableProvider>
		</div>
	);
};

export default DealListPage;
