import React, { useEffect, useState, useRef } from "react";
import {
	Input,
	Button,
	FormGroup,
	Form,
	Modal,
	ModalBody,
	ModalHeader,
} from "reactstrap";
import { decodeVINv2 } from "api/VINDecoderAPI";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { getNewStockNo } from "api/InventoryAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";
import { failAlert } from "utils/alertUtils";
import useCurrentVehicleStore from "stores/InventoryStore";
import { validateVin } from "../helperFunctions/validateVin";
import { useVinScanner } from "../hooks/useVinScanner";
import Loading from "components/loadingSpinner/Loading";
import ScanButton from "./ScanButton";
import { ErrorMessage } from "@hookform/error-message";
import { setLocale } from "yup";

/**
 * VinDecoder component to take a user-entered VIN and decode it.
 *
 * @param {Object} props - Component props
 * @param {boolean} props.useGlobalVin - Flag to use global VIN
 * @param {Function} props.updateStore - Function to update the store
 * @param {Function} props.toggle - Function to toggle the component
 * @returns {JSX.Element} The rendered component
 */
const VinDecoder = ({ useGlobalVin, updateStore, toggle }) => {
	const { vin: scannedVin, handleTakePhoto } = useVinScanner();
	const isMounted = useRef(true);
	const [isLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (scannedVin && isMounted.current) {
			setEnteredVin(scannedVin);
			setShowModal(false); // Hide modal when VIN is set
		}
	}, [scannedVin]);

	const {
		vin,
		editStockNo,
		editIsVinLoading,
		editStyleList,
		setDecodedFields,
	} = useCurrentVehicleStore();
	const locationID = useFromUser("locationID");

	const [enteredVin, setEnteredVin] = useState(useGlobalVin ? vin : "");

	const inventorySettings = JSON.parse(
		sessionStorage.getItem("inventoryDefaults")
	);

	const setNewStockNo = (vin) => {
		if (inventorySettings.isRunningStockNo) {
			getNewStockNo(locationID).then(
				(res) => {
					editStockNo(res.data.content);
				},
				(err) => {
					console.error(err);
				}
			);
		} else {
			editStockNo(vin.substring(vin.length - 6));
		}
	};

	const getData = async () => {
		try {
			await validateVin.validate({ vin: enteredVin });
			editIsVinLoading(true);
			decodeVINv2(enteredVin).then(
				(res) => {
					const content = res.data.content;
					createStyleList(content);
					setStore(content[0]);
					editIsVinLoading(false);
					if (toggle) {
						toggle();
					}
				},
				(err) => {
					console.error("Error decoding VIN:", err);
					if (!err.isGeneralError) {
						showApiError(err, failAlert);
					}
					editIsVinLoading(false);
				}
			);
		} catch (error) {
			console.error("Invalid VIN:", error.message);
			editIsVinLoading(false);
		}
	};

	const createStyleList = (styles) => {
		const styleList = styles.map((style) => ({
			label: style.subModel,
			value: style.subModel,
			data: style,
		}));
		editStyleList(styleList);
	};

	const setStore = (style) => {
		if (updateStore) {
			setNewStockNo(style.vin);
		}
		setDecodedFields(style);
	};

	const handleInputChange = (e) => {
		setEnteredVin(e.target.value.toUpperCase());
	};

	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			length: "VIN must contain a combination of 17 letters and numbers",
			matches: "Only number and letters allowed",
		},
	});

	const schema = yup.object().shape({
		vin: yup
			.string()
			.required()
			.length(17)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<Form
				onSubmit={(e) => e.preventDefault()}
				className="d-flex justify-content-center align-items-center"
			>
				<div
					id="vinDecoder"
					style={{
						minWidth: "200px",
						display: "flex",
						alignItems: "center",
						minHeight: "60px",
					}}
				>
					<FormGroup className="mb-0 pt-0">
						<Input
							maxLength="17"
							innerRef={register}
							placeholder="Enter VIN"
							value={enteredVin}
							onChange={handleInputChange}
							invalid={!!errors.vin}
							name="vin"
							disabled={isLoading}
						/>
						<ErrorMessage
							errors={errors}
							name="vin"
							render={({ message }) => <p className="error_text">{message}</p>}
						/>
					</FormGroup>
					{enteredVin.length === 0 && (
						<ScanButton
							vin={scannedVin}
							handleTakePhoto={handleTakePhoto}
							setShowModal={setShowModal}
						/>
					)}
					{enteredVin.length > 0 && (
						<Button
							onClick={handleSubmit(getData)}
							className="btn-primary btn btn-md ml-2"
							disabled={isLoading}
						>
							Decode
						</Button>
					)}
				</div>
			</Form>

			<Modal isOpen={showModal} centered>
				<ModalHeader>Scanning VIN...</ModalHeader>
				<ModalBody
					className="d-flex justify-content-center align-items-center flex-column"
					style={{ height: "100px", width: "200px" }}
				>
					<Loading />
					<p>Thinking...</p>
				</ModalBody>
			</Modal>
		</>
	);
};

export default VinDecoder;
