import React, { useState, createContext } from "react";

export const TemplateVariableContext = createContext();

export const TemplateVariableProvider = ({ children }) => {
	const [templateVars, setTemplateVars] = useState(null);
	const [requestDocs, setRequestDocs] = useState(false);
	const context = {
		templateVars,
		setTemplateVars,
		requestDocs,
		setRequestDocs,
	};

	return (
		<TemplateVariableContext.Provider value={context}>
			{children}
		</TemplateVariableContext.Provider>
	);
};
