import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'reactstrap';
import { FaTable, FaThLarge } from 'react-icons/fa';

const ViewToggle = ({ view, onToggle }) => {
    return (
        <Col className='mt-3 toggleContainer'>
            <div className="mx-3">
                <Button
                    className={`toggle-button btn-sm ${view === 'table' ? 'btn-active' : 'btn-link'}`}
                    onClick={() => onToggle('table')}
                >
                    <FaTable />&nbsp; Table View
                </Button>
                &nbsp;|&nbsp;
                <Button
                    className={`toggle-button ml-0 btn-sm ${view === 'card' ? 'btn-active' : 'btn-link'}`}
                    onClick={() => onToggle('card')}
                >
                    <FaThLarge />&nbsp; Card View
                </Button>
            </div>
        </Col>
    );
};

ViewToggle.propTypes = {
    view: PropTypes.string.isRequired,
    onToggle: PropTypes.func.isRequired
};

export default ViewToggle;
