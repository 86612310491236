import React, { useEffect, useState } from "react";
import {
	getAllStandardForms,
	getAllStateForms,
	getAllCustomForms,
	getAllInventoryForms,
	saveForm,
} from "api/FormsAPI";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	Label,
	Table,
	Row,
	Col,
} from "reactstrap";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "../../../utils/alertUtils";
import Select from "react-select";
import ChildrenModal from "../../../components/modals/ChildrenModal";
import formNavigationUtils from "utils/formNavigationUtils";
import { stateOptions } from "constants/Constants";
import InputElement, { SelectorElement } from "./InputElement";
import { useFormContext } from "react-hook-form";
import InputWrapper from "./InputWrapper";

const FormGen = () => {
	const [list, setList] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [listTitle, setListTitle] = useState("Standard Forms");
	const [listItem, setListItem] = useState("StandardForms");

	const [currentState, setCurrentState] = useState("ST");

	const [showAdd, setShowAdd] = useState(false);
	const toggleAdd = () => setShowAdd(!showAdd);

	// Get validation context
	const methods = useFormContext();

	const formList = [
		{ value: "StateForms", label: "State Forms", index: 8 },
		{ value: "StandardForms", label: "Standard Forms", index: 8 },
		{ value: "InventoryForms", label: "Inventory Forms", index: 8 },
		{ value: "CustomForms", label: "Custom Forms", index: 8 },

		{ value: "InventoryReports", label: "Inventory Reports", index: 5 },
		{ value: "ManagementReports", label: "Management Reports", index: 6 },
		{ value: "SalesReports", label: "Sales Reports", index: 7 },
		{ value: "CustomRports", label: "Custom Reports", index: 10 },
	];
	const formType = {
		1: "auction",
		5: "inventory",
		6: "management",
		7: "sales",
		8: "forms",
		9: "lease",
		10: "Customer Tracking",
		11: "PDF",
		12: "OKI",
	};

	const [id, setId] = useState(null);
	const [category_ID, setCategory_ID] = useState(8);
	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
	//	const [state, setState] = useState("ST");
	const [parameters, setParameters] = useState("");
	const [locationID, setLocationID] = useState("6730");
	const [dealerID, setDealerID] = useState("4519");
	const [classPath, setClassPath] = useState("");
	const [className, setClassName] = useState("");
	const [fileName, setFileName] = useState("");
	const clearFields = () => {
		setId(null);
		setListTitle(listTitle);
		setListItem(listItem);
		setCategory_ID(category_ID);
		setTitle("");
		setUrl("//");
		setCurrentState(currentState);
		setParameters("");
		setLocationID(locationID);
		setDealerID(dealerID);
		setClassName("");
		setFileName("");
	};

	const headers = [
		"id",
		"category_ID",
		"state",
		"title",
		"fileName",
		"locationID",
		"dealerID",
	];
	const newForm = () => {
		clearFields();
		toggleAdd();
	};
	const selectForm = (item) => {
		setId(item.id);
		setCategory_ID(item.category_ID);
		setTitle(item.title);
		setUrl(item.url);
		setCurrentState(item.state);
		setParameters(item.parameters);
		setLocationID(item.locationID);
		setDealerID(item.dealerID);
		//setClassName(item.className);
		console.log(generateClassPath());
		setClassName(String(item.className).replace(generateClassPath(item), ""));
		setFileName(item.fileName);
		toggleAdd();
	};
	const generateClassPath = () => {
		console.log(category_ID);
		console.log(formType[category_ID]);
		let xPath = "com.dealerclickApi.FormReports.";
		xPath = xPath + formType[category_ID] + ".";
		console.log(currentState);
		if (currentState.toUpperCase() === "ST") {
			xPath = xPath + "standard.";
		} else {
			xPath = xPath + "state." + currentState.toLowerCase() + ".";
		}
		console.log(xPath);
		setClassPath(xPath);
		return xPath;
	};
	const saveData = () => {
		console.log(classPath);
		const body = {
			category_ID: category_ID,
			title: title,
			url: url,
			sortOrder: 0,
			parameters: null,
			state: currentState,
			className: generateClassPath() + className, //"com.dealerclickApi.FormReports.forms.state.al.ALLaw553Generator",
			fileName: fileName,
			dealerID: dealerID,
			locationID: locationID,
			id: id,
		};
		console.log(className);
		console.log(body);

		saveForm(body);
		toggleAdd();
	};
	useEffect(() => {
		console.log(id);
		console.log(listItem);
		console.log(currentState);
		console.log(dealerID);
		console.log(locationID);
		console.log(currentState);

		if (listItem === "StateForms") {
			console.log(category_ID);
			//setCategory_ID(8);
			getAllStateForms({ state: currentState, dealerID, locationID }).then(
				(res) => {
					setList(res.data.content);
					setIsLoaded(true);
				},
				(err) => {
					setIsLoaded(true);
					//				setCities([]);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		if (listItem === "InventoryForms") {
			console.log(category_ID);
			//setCategory_ID(5);
			getAllInventoryForms({ dealerID, locationID }).then(
				(res) => {
					setList(res.data.content);
					setIsLoaded(true);
				},
				(err) => {
					setIsLoaded(true);
					//				setCities([]);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		if (listItem === "CustomForms") {
			console.log(category_ID);
			//setCategory_ID(8);
			console.log(dealerID, locationID);
			getAllCustomForms({ dealerID, locationID }).then(
				(res) => {
					setList(res.data.content);
					setIsLoaded(true);
				},
				(err) => {
					setIsLoaded(true);
					//				setCities([]);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
		if (listItem === "StandardForms") {
			console.log(category_ID);
			//setCategory_ID(8);
			getAllStandardForms({ dealerID, locationID }).then(
				(res) => {
					setList(res.data.content);
					setIsLoaded(true);
				},
				(err) => {
					setIsLoaded(true);
					//				setCities([]);

					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}

		// eslint-disable-next-line
	}, [listItem, currentState, dealerID, locationID, showAdd]);

	useEffect(
		() => {
			// setLocationID("6730");
			// setDealerID("4519");
			// setCurrentState("ST");
			generateClassPath();
			console.log(title);
		},

		// eslint-disable-next-line
		[]
	);

	return (
		//<div>test</div>
		// (
		// 	<div className="p-3 justify-content-center align-items-center forms-table">
		// 		<NavBar
		// 			navClass="nav-pills nav-pills-primary justify-content-center nav"
		// 			tabs={tabs}
		// 			tabComponents={tabComponents}
		// 		/>
		// 	</div>
		// ),
		isLoaded && (
			<div>
				<ChildrenModal
					modal={showAdd}
					toggle={toggleAdd}
					modalTitle={listTitle}
				>
					<Form onKeyDown={formNavigationUtils}>
						<InputWrapper
							formTitle={listTitle}
							inputComponents={[
								<InputElement
									{...methods}
									value={id} //{{ id } ? "New" : { id }}
									name="id"
									label="ID"
									type="text"
									disableValidation
									disabled
								/>,
								<SelectorElement
									name="formList"
									label="Form Category"
									value={listItem}
									options={formList}
									disableValidation
									disabled
								/>,

								<InputElement
									{...methods}
									value={title}
									onChange={(e) => {
										setTitle(e.target.value);
									}}
									name="title"
									label="Title"
									type="text"
									disableValidation
								/>,
								<InputElement
									value={fileName}
									onChange={(e) => {
										setFileName(e.target.value);
									}}
									name="fileName"
									label="File Name"
									type="text"
									disableValidation
								/>,
								<SelectorElement
									name="state"
									label="State"
									value={currentState}
									onChange={(e) => setCurrentState(e.target.value)}
									options={[...stateOptions, { value: "ST", label: "ST" }]}
									disableValidation
								/>,
								category_ID !== 8 &&
								((
									<InputElement
										value={url}
										onChange={(e) => {
											setUrl(e.target.value);
										}}
										name="url"
										label="URL"
										type="text"
										disableValidation
									/>
								),
									(
										<InputElement
											value={parameters}
											onChange={(e) => {
												setParameters(e.target.value);
											}}
											name="parameters"
											label="Parameters"
											type="text"
											disableValidation
										/>
									)),
								<InputElement
									value={className}
									onChange={(e) => {
										setClassName(e.target.value);
									}}
									name="className"
									label="Class Name"
									type="text"
									disableValidation
								/>,

								<>
									{listItem === "CustomForms" && (
										<>
											<Col>
												<Label>Dealer ID</Label>
												<input
													type="text"
													className="form-control  "
													id="dealerID"
													placeholder="Dealer ID"
													value={dealerID}
													onChange={(e) => setDealerID(e.target.value)}
												/>
											</Col>
											<Col>
												<Label>Location ID</Label>
												<input
													type="text"
													className="form-control  "
													id="locationID"
													placeholder="Location ID"
													value={locationID}
													onChange={(e) => setLocationID(e.target.value)}
												/>
											</Col>
										</>
									)}
								</>,
							]}
							buttons={
								<div className="w-100 text-center">
									<Button
										color="primary"
										onClick={() => {
											saveData();
										}}
									>
										<i className="nc-icon nc-simple-add" /> Save
									</Button>
								</div>
							}
						/>
					</Form>
				</ChildrenModal>

				<Card>
					<CardHeader></CardHeader>

					<CardBody className="pb-4">
						<Row>
							<Col>
								<div>
									<Label>Form Category</Label>
									<Select
										className="mb-3"
										noOptionsMessage={() => "No Category  available"}
										value={{
											value: listItem,
											label: listTitle,
										}}
										options={formList}
										onChange={(e) => {
											setListTitle(e.label);
											setListItem(e.value);
											setCategory_ID(e.index);
											setCurrentState(e.value);
										}}
									/>
								</div>
							</Col>
							{listItem === "StateForms" && (
								<Col>
									<div>
										<Label>State</Label>
										<Select
											className="mb-3"
											noOptionsMessage={() => "No vendors available"}
											value={{
												value: currentState,
												label: currentState,
											}}
											options={stateOptions}
											onChange={(e) => {
												setCurrentState(e.value);
											}}
										/>
									</div>
								</Col>
							)}
							{listItem === "StandardForms" && (
								<Col>
									<div>
										<Label>State</Label>
										<Select
											className="mb-3"
											noOptionsMessage={() => "No vendors available"}
											value={{
												value: "ST",
												label: "ST",
											}}
											options={stateOptions}
											disabled
										/>
									</div>
								</Col>
							)}

							{listItem === "CustomForms" && (
								<>
									<Col>
										<Label>Dealer ID</Label>
										<input
											type="text"
											className="form-control  "
											id="dealerID"
											placeholder="Dealer ID"
											value={dealerID}
											onChange={(e) => setDealerID(e.target.value)}
										/>
									</Col>
									<Col>
										<Label>Location ID</Label>
										<input
											type="text"
											className="form-control  "
											id="locationID"
											placeholder="Location ID"
											value={locationID}
											onChange={(e) => setLocationID(e.target.value)}
										/>
									</Col>
								</>
							)}
							{
								<Col>
									<Table>
										<Row>
											<Col>
												<Button
													className="btn btn-primary btn-md"
													onClick={() => newForm()}
												>
													<i className="nc-icon nc-simple-add" />
													Add Form
												</Button>
											</Col>
											<Col></Col>
											<Col></Col>
											<Col></Col>
											<Col></Col>
										</Row>
									</Table>
								</Col>
							}
						</Row>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">{listTitle}</CardTitle>
					</CardHeader>
					<CardBody className="pb-4">
						<Table>
							<thead>
								<tr>
									{headers.map((header, i) => (
										<th key={i}>{header}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{list.map((item, index) => (
									<tr key={item.id}>
										<td>{item.id}</td>
										<td>{item.category_ID}</td>
										<td>{item.state}</td>
										<td>{item.title}</td>
										<td>{item.fileName}</td>
										<td hidden>{item.url}</td>
										<td hidden>{item.parameters}</td>
										<td>{item.className}</td>

										<td>{item.locationID}</td>
										<td>{item.dealerID}</td>
										<td className="d-flex justify-content-center">
											<Button
												className="btn-md"
												onClick={() => selectForm(item)}
											>
												Edit
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			</div>
		)
	);
};

export default FormGen;
