import PageHeader from "components/header/PageHeader";
import React from "react";

/**
 * Formats a card status string by replacing commas with " & ".
 *
 * @param {string} status - The status string to format.
 * @returns {string} - The formatted status string.
 */
const formatCardStatus = (status) => {
	if (typeof status !== "string") {
		return status;
	}
	return status.replace(/,/g, " & ");
};

const CustomerHeader = ({
	leadCount,
	creditAppCount,
	customerCount,
	lenderCount,
	status,
	setStatus,
}) => {
	// Function to handle card clicks for each status
	const handleCardClick = (cardStatus) => (e) => {
		e.preventDefault(); // Prevent default navigation
		setStatus(cardStatus);
	};

	// const pageTitle = (
	// 	<span>
	// 		Contacts <span className="noMobile">- {status}</span>
	// 	</span>
	// );

	const formattedCardStatus = formatCardStatus(status);

	return (
		<PageHeader
			titlePrefix="Contacts"
			title={formattedCardStatus}
			cards={[
				{
					title: "Active Leads",
					count: leadCount,
					iconClass: "icon-warning nc-icon nc-single-02 text-success",
					iconText: "Add New",
					routePath: "/lead-add",
					onCardClick: handleCardClick("Lead"),
					isActive: status === "Lead",
				},
				{
					title: "Active Credit Apps",
					count: creditAppCount,
					iconClass: "icon-warning nc-icon nc-single-02 text-warning",
					iconText: "Open Deals",
					routePath: "/deal-list",
					onCardClick: handleCardClick("CreditApp"),
					isActive: status === "CreditApp",
				},
				{
					title: "Customers",
					count: customerCount,
					iconClass: "icon-danger nc-icon nc-single-02 text-primary",
					iconText: "Sold List",
					routePath: "/customer-list",
					onCardClick: handleCardClick("Customer"),
					isActive: status === "Customer",
				},
				{
					title: "Lenders",
					count: lenderCount,
					iconClass: "icon-danger nc-icon nc-single-02 text-danger",
					iconText: "Sold List",
					routePath: "/customer-list",
					onCardClick: handleCardClick("Lender"),
					isActive: status === "Lender",
				},
			]}
		/>
	);
};

export default CustomerHeader;
