import React, { useContext } from "react";

import useCurrentVehicleStore from "stores/InventoryStore";
import useRoute from "hooks/useRoute";
import useCurrentDealStore from "stores/DealStore";
import useBuildVehicle from "hooks/useBuildVehicle";

import { saveFailAlert } from "utils/alertUtils";
import { saveNewVehicleWithImages, saveVehicle } from "api/InventoryAPI";
import { saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMultiImageData } from "../subFeatures/image/multi/MultiImageContext";
import { PATHS } from "constants/Constants";

import shallow from "zustand/shallow";
import { KbbContext } from "../subFeatures/inventoryTabs/valuesTab/KbbContext";
import { useSetInventory } from "../hooks/useSaveInventory";
import { getMMRValues } from "../../../api/MMR_API";
import { MmrContext } from "../../../context/MMRContext";

export const InventorySaveButton = ({ toggle = false }) => {
	// Vehicle obj
	const getVehicle = useBuildVehicle();
	const setVehicle = useSetInventory();

	// Deal store
	const { vehicle, editVehicle } = useCurrentDealStore(
		(state) => ({ vehicle: state.vehicle, editVehicle: state.editVehicle }),
		shallow
	);

	const { getDcKbbObj } = useContext(KbbContext);
	const { vehicleMMRValues } = useContext(MmrContext);

	// Saves a vehicle without any images (can be new or existing)
	const saveData = () => {
		const inventoryVehicle = getVehicle();
		console.log(inventoryVehicle);
		const kbb = getDcKbbObj();
		const mmr = vehicleMMRValues;
		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
			mmr: mmr,
		};
		saveVehicle(request).then(
			(response) => {
				console.log(response);
				setVehicle(response);
				if (vehicle.inventoryID != null) {
					const v = response.data.content.vehicle;
					editVehicle({ ...v });
				}

				saveSuccessAlert();
				if (toggle) {
					toggle();
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();

	return (
		<>
			<div
				className="btn btn-success float-button"
				onClick={methods.handleSubmit(
					() => {
						saveData();
					},
					(err) => {
						console.log(err);
					}
				)}
			>
				<i className="nc-icon nc-check-2"></i>&nbsp;Save
			</div>
		</>
	);
};

export default InventorySaveButton;

export const NewInventorySaveButton = () => {
	// Multi image context
	const { imageKeys, imageUploads } = useMultiImageData(); // Assuming imageUploads contains the original images
	// Vehicle obj
	const getVehicle = useBuildVehicle();
	const history = useHistory();
	const newRoute = useRoute(PATHS.INVENTORY_DETAIL);
	// Vehicle store
	const { editInventoryID } = useCurrentVehicleStore((state) => state, shallow);

	// Adds the images and vehicle save object to a FormData and then calls API to save
	// new vehicle with image using the FormData obj
	const saveVehicleWithImage = () => {
		const formData = new FormData();
		const inventoryVehicle = getVehicle();

		// Add original image files to payload
		if (imageKeys.length > 0 && imageUploads) {
			imageKeys.forEach((key) => {
				if (imageUploads[key]) {
					formData.append("imageFiles", imageUploads[key]);
				}
			});
		}

		formData.append(
			"vehicle",
			new Blob([JSON.stringify(inventoryVehicle)], {
				type: "application/json",
			})
		);

		saveNewVehicleWithImages(formData).then(
			(response) => {
				console.log(response);
				editInventoryID(response.data.content.vehicle.inventoryID);
				history.push(newRoute, {
					ID: response.data.content.vehicle.inventoryID,
				});
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const methods = useFormContext();
	return (
		<>
			<div
				className="btn btn-success float-button"
				onClick={methods.handleSubmit(saveVehicleWithImage)}
			>
				<i className="nc-icon nc-check-2"></i>&nbsp;Save
			</div>
		</>
	);
};
