import React, { useEffect } from "react";
import { Card, CardBody, Row } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import useCurrentPhotoStore from "stores/PhotoStore";
import useCurrentVehicleStore from "stores/InventoryStore";
import { MultiImageProvider } from "features/inventory/subFeatures/image/multi/MultiImageContext";
import { getAllVendorSortedByLocationID } from "api/VendorsAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import dayjs from "dayjs";
import VinDecoder from "features/vinDecoder/components/VinDecoder";
import InventoryDetailsTab from "../subFeatures/inventoryTabs/detailsTab/InventoryDetailsTab";
import UploadPhotos from "../subFeatures/inventoryTabs/photosTab/UploadPhotos";
import { KbbValuesProvider } from "../subFeatures/inventoryTabs/valuesTab/KbbContext";
import { NewInventorySaveButton } from "../helperFunctions/InventorySaveButton";

const InventoryAddPage = () => {
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const today = dayjs().format("YYYY-MM-DD");
	const inventorySettings = JSON.parse(
		sessionStorage.getItem("inventoryDefaults")
	);
	const {
		reset,
		editType,
		editIsLoadingFalse: dataIsLoaded,
		editVendorsList,
		editReconVendorsList,
		editFlooringVendorsList,
		editAllVendorList,
		editTradeLenderList,
		editModifiedOn,
		editModifiedByID,
		editOpenedBy,
	} = useCurrentVehicleStore((state) => state, shallow);

	// Photo store
	const { resetPhotos } = useCurrentPhotoStore(
		(state) => ({ resetPhotos: state.reset }),
		shallow
	);

	// Gets all the inventory related details
	const getInvDetails = () => {
		const allVendors = getAllVendorSortedByLocationID(locationID);

		Promise.all([allVendors]).then(
			(res) => updateStores(res),
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					//history.push(newRoute);
				}
			}
		);
	};

	// Updates relevant stores/context values with API call
	const updateStores = (res) => {
		const allVendors = res[0].data.content;
		// Edit vendors arrays
		editAllVendorList(allVendors);
		editVendorsList(allVendors.vendors);
		editReconVendorsList(allVendors.reconSupplier);
		editFlooringVendorsList(allVendors.flooringVendor);
		editTradeLenderList(allVendors.lenders);

		//edit more information
		editModifiedOn(today);
		editModifiedByID(dcLoginID);
		editOpenedBy(lastName + ", " + firstName);
		editType(inventorySettings.vehicleType);

		dataIsLoaded();
	};

	useEffect(() => {
		getInvDetails();
		return () => {
			reset();
			resetPhotos();
		};
		// eslint-disable-next-line
	}, []);

	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be ${min} characters or more",
			// eslint-disable-next-line
			length: "Must be ${length} numbers long",
			matches: "Only numbers and letters are allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		// model: yup.object().shape({
		// 	label: yup.string().required(),
		// 	value: yup.string().required(),
		// }),
		model: yup.string().required().max(50),
		make: yup.string().required().max(50),
		vin: yup.string().required(),
		dateIn: yup.string().required().length(10, "Required format is YYYY/MM/DD"),
		year: yup
			.date()
			.max(new Date().getFullYear() + 1)
			.min(new Date("January 01, 1900 23:15:00").getFullYear())
			.nullable()
			.transform((curr, orig) => (orig?.length === 4 ? curr : null))
			.required("Required (YYYY)"),
		stockNo: yup.string().required().min(1).max(12),
		// .matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<div>
			<Row className="mx-auto content">
				<h3 className="mt-2 mx-auto">
					Add&nbsp;|&nbsp;<span style={{ fontSize: "20px" }}>
						Inventory</span>
				</h3>
			</Row>
			<div className="mx-auto">
				<Card className="card-plain m-0">
					<CardBody className="pt-0">
						<VinDecoder
							header="Decode VIN"
							useGlobalVin={false}
							updateStore={true}
							textInputSize="add"
						/>
					</CardBody>
				</Card>
			</div>
			<MultiImageProvider>
				<KbbValuesProvider>
					<FormProvider {...methods}>
						<InventoryDetailsTab hideDelete="true" />
						<UploadPhotos newVehicle={true} />
						<NewInventorySaveButton />
					</FormProvider>
				</KbbValuesProvider>
			</MultiImageProvider>
		</div>
	);
};

export default InventoryAddPage;
