import React from "react";
import { Modal, ModalBody } from "reactstrap";

const LenderModal = ({
	ButtonComponent,
	modalTitle,
	modalSize,
	toggle,
	toggleModal,
	children,
}) => {
	return (
		<>
			<ButtonComponent />
			<Modal
				toggle={toggleModal}
				isOpen={toggle}
				size={modalSize}
				backdrop="static"
			>
				<div className="modal-header justify-content-center">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={toggleModal}
					>
						<span aria-hidden="true">×</span>
					</button>
					<h5 className="modal-title">{modalTitle}</h5>
				</div>
				<ModalBody>
					{React.cloneElement(children, { toggleModal: toggleModal })}
				</ModalBody>
			</Modal>
		</>
	);
};

export default LenderModal;
