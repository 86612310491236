import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl, devBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/settings/",
});
const instanceDev = createAxiosInstance({
	baseURL: devBaseUrl + "/settings/",
});

export const getSettings = async (dealerID, locationID) => {
	return await instance.get(`${dealerID}/${locationID}`);
};

// TODO get the correct api from Angelo
export const saveSettings = async (body) => {
	return await instance.post(`save`, body);
};

export const getDealerTempList = async (locationID) => {
	return await instanceDev.get(`dealerTemp/list/${locationID}`);
	//return await localInstance.get(`list/${locationID}`);
};
export const saveDealerTempInfo = async (body) => {
	return await instanceDev.post(`dealerTemp/save`, body);
	//return await localInstance.post(`save`, body);
};
export const getDealerTempInfo = async (ID) => {
	return await instanceDev.get(`dealerTemp/${ID}`);
	//  return await localInstance.get(`dealerTemp/${ID}`);
};
export const deleteDealerTempInfo = async (ID) => {
	return await instanceDev.get(`dealerTemp/delete/${ID}`);
	// return await localInstance.get(`/dealerTemp/delete/${ID}`);
};
export const getDealershipInfo = async (locationID) => {
	return await instanceDev.get(`dealerTemp/data/${locationID}`);
	//return await localInstance.get(`dealerTemp/data/${locationID}`);
};
