/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { Label, Input } from "reactstrap";
import {
	titleStatusOptions,
	conditionOptions,
	vehicleTypeOptions,
	stateOptions,
	LoanPeriod,
	SaleType,
	DealType,
	vehicleCostType,
	kbbConditionOptions,
	genderType,
	generationType,
	militaryType,
	commType,
	sellerFeeTypeList,
	INS_TYPE_AH,
	INS_TYPE,
} from "constants/Constants";
/**
 * @summary Selector used to build simple dropdowns with predefined values
 * @param string selectType used to generate the type of options to display
 * @param string selectLabel used to print the label for the selector
 * @param func handleChange for onChange
 * @param object selectedOption for state of selector
 */
/*
  Prop selectType will be passed in, conditionals below will determine what options should be generated
  selectType can be [condition, vehicleType, state], selectLabel will fill in the label for the selector
  We can add more if more selectors need to be made

  Maybe in the future these option lists can be passed in as well? They need to be stored somewhere
  and while context is not yet implemented this seems a good a place as any
*/
const Selector = (props) => {
	let options;

	if (props.selectType === "condition") {
		options = conditionOptions;
	} else if (props.selectType === "vehicleType") {
		options = vehicleTypeOptions;
	} else if (props.selectType === "state") {
		options = stateOptions;
	} else if (props.selectType === "titleStatus") {
		options = titleStatusOptions;
	} else if (props.selectType === "loanPeriod") {
		options = LoanPeriod;
	} else if (props.selectType === "saleType") {
		options = SaleType;
	} else if (props.selectType === "dealType") {
		options = DealType;
	} else if (props.selectType === "costType") {
		options = vehicleCostType;
	} else if (props.selectType === "kbbCondition") {
		options = kbbConditionOptions;
	} else if (props.selectType === "genderType") {
		options = genderType;
	} else if (props.selectType === "generationType") {
		options = generationType;
	} else if (props.selectType === "militaryType") {
		options = militaryType;
	} else if (props.selectType === "commType") {
		options = commType;
	} else if (props.selectType === "sellerFeeTypeList") {
		options = sellerFeeTypeList;
	} else if (props.selectType === "INS_TYPE_AH") {
		options = INS_TYPE_AH;
	} else if (props.selectType === "INS_TYPE") {
		options = INS_TYPE;
	}

	if (props.default === true) {
		return (
			<Input
				name={props.name}
				readOnly={props.disabled}
				type="select"
				value={props.selectedOption}
				onChange={(event) => {
					props.handleChange(event.target.value);
				}}
			>
				{options.map((option) => (
					<option key={option.value + "." + option.label} value={option.value}>
						{option.label}
					</option>
				))}
			</Input>
		);
	} else {
		return (
			<div>
				<Label className={props.labelClass}>{props.selectLabel}</Label>
				<Input
					name={props.name}
					readOnly={props.disabled}
					type="select"
					value={props.selectedOption}
					onChange={(event) => {
						props.handleChange(event.target.value);
					}}
				>
					{options.map((option, index) => (
						<option key={`${option} ${index}`} value={option.value}>
							{option.label}
						</option>
					))}
				</Input>
			</div>
		);
	}
};

export default Selector;
