import React, { useEffect, useState } from "react";

import "assets/css/password-checklist.css";

const PasswordChecklist = ({ password }) => {
	const [lengthMatch, setLengthMatch] = useState(false);
	const [numberMatch, setNumberMatch] = useState(false);
	const [upperMatch, setUpperMatch] = useState(false);
	const [lowerMatch, setLowerMatch] = useState(false);
	const [specialMatch, setSpecialMatch] = useState(false);

	useEffect(() => {
		const len = password?.length >= 8 && password?.length <= 22;
		const spec =
			password?.match(/[~`!@#$%^&*()\-_+=(){}[\]:;<>,.?\\/|'"]+/) || false;
		const num = password?.match(/[0-9]+/) || false;
		const low = password?.match(/[a-z]+/) || false;
		const up = password?.match(/[A-Z]+/) || false;

		setLengthMatch(len);
		setSpecialMatch(spec);
		setNumberMatch(num);
		setUpperMatch(up);
		setLowerMatch(low);
	}, [password]);

	return (
		<ul className="password-checklist">
			<li className={`${lengthMatch ? "match" : ""}`}>8-22 characters long</li>
			<li className={`${numberMatch ? "match" : ""}`}>At least 1 Number</li>
			<li className={`${upperMatch ? "match" : ""}`}>
				At least 1 Uppercase Letter
			</li>
			<li className={`${lowerMatch ? "match" : ""}`}>
				At least 1 Lowercase Letter
			</li>
			<li className={`${specialMatch ? "match" : ""}`}>
				At least 1 Special Character ex: *.!@$%^
			</li>
		</ul>
	);
};

export default PasswordChecklist;
