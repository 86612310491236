import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import shallow from "zustand/shallow";
import ReserveCalculator from "./ReserveCalculator";
import LenderFeeCalculator from "./LenderFeeCalculator";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import SummaryRow from "./SummaryRow";

/**
 * RecapSummaryCard component to display a summary of the deal's financial breakdown.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered RecapSummaryCard component
 */
const RecapSummaryCard = () => {
	const {
		frontendProfit,
		backendProfit,
		incentive,
		finderReward,
		editIncentive,
		editFinderReward,
		gpBeforeFees,
		gpBeforeReserve,
		gpBeforeComm,
		receivable,
		netProfit,
		commGross,
	} = useCurrentRecapStore((state) => state, shallow);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	return (
		<Card>
			<CardHeader>
				<h3>Deal Summary</h3>
			</CardHeader>
			<CardBody>
				<SummaryRow
					disabled
					value={frontendProfit}
					label="Frontend Gross Profit"
				/>
				<SummaryRow
					disabled
					value={backendProfit}
					label="Backend Gross Profit"
				/>
				<hr className="mb-2" />
				<SummaryRow disabled value={gpBeforeFees} label="GP before Fees" />
				<hr className="mb-3" />
				<LenderFeeCalculator />
				<hr className="mb-2" />
				<SummaryRow
					disabled={type !== DealStatus.PENDING}
					value={incentive}
					onChange={editIncentive}
					label="Dealer Incentive"
				/>
				<SummaryRow
					disabled={type !== DealStatus.PENDING}
					value={finderReward}
					onChange={editFinderReward}
					label="Finder Reward"
				/>
				<SummaryRow
					disabled
					value={gpBeforeReserve}
					label="GP before Reserve"
				/>
				<hr className="mb-3" />
				<ReserveCalculator disabled={type !== DealStatus.PENDING} />
				<hr className="mb-2" />
				<SummaryRow disabled value={gpBeforeComm} label="GP before Comm." />
				<SummaryRow disabled value={commGross} label="Total Commission" />
				<SummaryRow disabled value={netProfit} label="Net Profit" />
				<SummaryRow disabled value={receivable} label="Receivable" />
			</CardBody>
		</Card>
	);
};

RecapSummaryCard.propTypes = {
	frontendProfit: PropTypes.number.isRequired,
	backendProfit: PropTypes.number.isRequired,
	incentive: PropTypes.number.isRequired,
	finderReward: PropTypes.number.isRequired,
	editIncentive: PropTypes.func.isRequired,
	editFinderReward: PropTypes.func.isRequired,
	gpBeforeFees: PropTypes.number.isRequired,
	gpBeforeReserve: PropTypes.number.isRequired,
	gpBeforeComm: PropTypes.number.isRequired,
	receivable: PropTypes.number.isRequired,
	netProfit: PropTypes.number.isRequired,
	commGross: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
};

export default RecapSummaryCard;
