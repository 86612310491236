import React, { useState } from "react";
import { Form } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import ZipCityTable from "../../../../../../components/tables/ZipCityTable";

import { stateOptions, jobTitles } from "../../../../../../constants/Constants";

import { useFormContext } from "react-hook-form";
import InputElement, {
	PhoneInputElement,
	SelectorElement,
	SsnInputElement,
	ZipInputElement,
	CheckBoxElement,
	PercentInputPepperPay,
} from "../../../../../forms/components/InputElement";

const OwnersInformation = ({
	ownersInfo,
	setOwnersInfo,
	driverLicenseInfo,
	addressInfo,
	setAddressInfo,
	setDriverLicenseInfo,
	owner2,
	setCurrentForm,
	nextForm,
	previousForm,
	disabled,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	const [owner2AddressModal, setOwner2AddressModal] = useState(false);
	const [owner2AddressData, setOwner2AddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);
	const toggleOwner2AddressModal = () =>
		setOwner2AddressModal(!owner2AddressModal);

	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: addressInfo.city,
				state: addressInfo.state,
			},
		});
		toggleCityModal();
	};

	const handleO2ZipChange = (fieldZip) => {
		setOwner2AddressData({
			zip: fieldZip,
			address: {
				city: owner2.o2City,
				state: owner2.o2State,
			},
		});
		toggleOwner2AddressModal();
	};

	const setAddressFields = (address) => {
		setAddressInfo.setCity(address.city);
		setAddressInfo.setState(address.state);
		setAddressInfo.setZip(address.zip);
	};

	const setOwner2AddressFields = (address) => {
		owner2.setO2City(address.city);
		owner2.setO2State(address.state);
		owner2.setO2Zip(address.zip);
	};

	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}

		setCurrentForm(nextForm);
	};

	const methods = useFormContext();
	console.log(ownersInfo);
	return (
		<div>
			<Form className="row" onKeyDown={formNavigationUtils}>
				<h3 className="col-12 mb-3">Owner Information</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.lastName}
					onChange={(e) => setOwnersInfo?.setLastName(e.target.value)}
					name="owners[0].lastName"
					label="Last Name"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.firstName}
					onChange={(e) => setOwnersInfo?.setFirstName(e.target.value)}
					name="owners[0].firstName"
					label="First Name"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.middleName}
					onChange={(e) => setOwnersInfo?.setMiddleName(e.target.value)}
					name="owners[0].middleName"
					label="Middle Name"
					type="text"
				/>
				<SsnInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					name="owners[0].ssn"
					label="Social Security Number"
					value={ownersInfo.ssn}
					onChange={(e) => setOwnersInfo?.setSsn(e)}
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.email}
					onChange={(e) => setOwnersInfo?.setEmail(e.target.value)}
					name="owners[0].email"
					label="Email"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.dateOfBirth}
					onChange={(e) => setOwnersInfo?.setDateOfBirth(e.target.value)}
					name="owners[0].dateOfBirth"
					label="Date of Birth"
					type="date"
					labelClass="required"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.homePhone}
					onChange={(e) => setOwnersInfo?.setHomePhone(e)}
					name="owners[0].homePhone"
					label="Home Contact Number"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={ownersInfo.mobilePhone}
					onChange={(e) => setOwnersInfo?.setMobilePhone(e)}
					name="owners[0].mobilePhone"
					label="Mobile Phone"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="owners[0].title"
					label="Owner Title"
					value={ownersInfo.title}
					onChange={(e) => setOwnersInfo?.setTitle(e.target.value)}
					options={jobTitles}
					labelClass="required"
				/>

				<PercentInputPepperPay
					{...methods}
					disabled={disabled}
					value={ownersInfo.ownershipPercentage}
					onChange={(e) => setOwnersInfo?.setOwnershipPercentage(e)}
					name="owners[0].ownershipPercentage"
					label="Ownership Percentage"
					labelClass="required"
				/>

				<h5 className="col-12 form-section  my-3">Driver License Info</h5>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="owners[0].driverLicense.state"
					label="Driver License State"
					value={driverLicenseInfo.dlState}
					onChange={(e) => setDriverLicenseInfo?.setDlState(e.target.value)}
					options={stateOptions}
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={driverLicenseInfo.dlNumber}
					onChange={(e) => setDriverLicenseInfo?.setDlNumber(e.target.value)}
					name="owners[0].driverLicense.number"
					label="Driver License Number"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={driverLicenseInfo.dlExpiration}
					onChange={(e) =>
						setDriverLicenseInfo?.setDlExpiration(e.target.value)
					}
					name="owners[0].driverLicense.expiration"
					label="Driver License Expiration"
					type="date"
					labelClass="required"
				/>
				<h5 className="col-12 form-section my-3">Address Info</h5>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={addressInfo.address1}
					onChange={(e) => setAddressInfo?.setAddress1(e.target.value)}
					name="owners[0].address.address1"
					label="Address"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={addressInfo.address2}
					onChange={(e) => setAddressInfo?.setAddress2(e.target.value)}
					name="owners[0].address.address2"
					label="Suite / Floor / etc."
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={addressInfo.city}
					onChange={(e) => setAddressInfo?.setCity(e.target.value)}
					name="owners[0].address.city"
					label="City"
					type="text"
				/>
				<ZipInputElement
					{...methods}
					disabled={disabled}
					value={addressInfo.zip}
					onChange={(val) => setAddressInfo?.setZip(val)}
					openZipModal={handleZipChange}
					name="owners[0].address.zip"
					label="Zip"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="owners[0].address.state"
					label="State"
					value={addressInfo.state}
					onChange={(e) => setAddressInfo?.setState(e.target.value)}
					options={stateOptions}
					labelClass="required"
				/>
				<h5 className="col-12 form-section my-3"> </h5>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={ownersInfo.isControlProng}
					onChange={() =>
						setOwnersInfo?.setIsControlProng(!ownersInfo.isControlProng)
					}
					name="owners[0].isControlProng"
					label="Controlling Manager"
					labelClass="pl-4"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={ownersInfo.isSigner}
					onChange={() => setOwnersInfo?.setIsSigner(!ownersInfo.isSigner)}
					name="owners[0].isSigner"
					label="Authorized Signer"
					labelClass="pl-4"
				/>
				<CheckBoxElement
					{...methods}
					disabled={disabled}
					checked={owner2.hasSecondOwner}
					onChange={() => owner2?.setHasSecondOwner(!owner2.hasSecondOwner)}
					name="hasSecondOwner"
					label="Is there another Owner?"
					labelClass="pl-4"
				/>
				{owner2.hasSecondOwner && (
					<>
						<h3 className="col-12 mb-3">Other Owner Information</h3>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2LastName}
							onChange={(e) => owner2?.setO2LastName(e.target.value)}
							name="owners[1].lastName"
							label="Last Name"
							type="text"
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2FirstName}
							onChange={(e) => owner2?.setO2FirstName(e.target.value)}
							name="owners[1].firstName"
							label="First Name"
							type="text"
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2MiddleName}
							onChange={(e) => owner2?.setO2MiddleName(e.target.value)}
							name="owners[1].middleName"
							label="Middle Name"
							type="text"
						/>
						<SsnInputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							name="owners[1].ssn"
							label="Social Security Number"
							value={owner2.o2Ssn}
							onChange={(e) => owner2?.setO2Ssn(e)}
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2Email}
							onChange={(e) => owner2?.setO2Email(e.target.value)}
							name="owners[1].email"
							label="Email"
							type="text"
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2DateOfBirth}
							onChange={(e) => owner2?.setO2DateOfBirth(e.target.value)}
							name="owners[1].dateOfBirth"
							label="Date of Birth"
							type="date"
							labelClass="required"
						/>
						<PhoneInputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2HomePhone}
							onChange={(e) => owner2?.setO2HomePhone(e)}
							name="owners[1].homePhone"
							label="Home Contact Number"
						/>
						<PhoneInputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2MobilePhone}
							onChange={(e) => owner2?.setO2MobilePhone(e)}
							name="owners[1].mobilePhone"
							label="Mobile Phone"
							labelClass="required"
						/>
						<SelectorElement
							{...methods}
							disabled={disabled}
							name="owners[1].title"
							label="Owner Title"
							value={owner2.o2Title}
							onChange={(e) => owner2?.setO2Title(e.target.value)}
							options={jobTitles}
							labelClass="required"
						/>

						<PercentInputPepperPay
							{...methods}
							disabled={disabled}
							value={owner2.o2OwnershipPercentage}
							onChange={(e) => owner2?.setO2OwnershipPercentage(e)}
							name="owners[1].ownershipPercentage"
							label="Ownership Percentage"
							labelClass="required"
						/>

						<h5 className="col-12 form-section  my-3">Driver License Info</h5>
						<SelectorElement
							{...methods}
							disabled={disabled}
							name="owners[1].driverLicense.state"
							label="Driver License State"
							value={owner2.o2DlState}
							onChange={(e) => owner2?.setO2DlState(e.target.value)}
							options={stateOptions}
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2DlNumber}
							onChange={(e) => owner2?.setO2DlNumber(e.target.value)}
							name="owners[1].driverLicense.number"
							label="Driver License Number"
							type="text"
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2DlExpiration}
							onChange={(e) => owner2?.setO2DlExpiration(e.target.value)}
							name="owners[1].driverLicense.expiration"
							label="Driver License Expiration"
							type="date"
							labelClass="required"
						/>
						<h5 className="col-12 form-section my-3">Address Info</h5>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-4"
							value={owner2.o2Address1}
							onChange={(e) => owner2?.setO2Address1(e.target.value)}
							name="owners[1].address.address1"
							label="Address"
							type="text"
							labelClass="required"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-4"
							value={owner2.o2Address2}
							onChange={(e) => owner2?.setO2Address2(e.target.value)}
							name="owners[1].address.address2"
							label="Suite / Floor / etc."
							type="text"
						/>
						<InputElement
							{...methods}
							disabled={disabled}
							colSize="col-lg-3"
							value={owner2.o2City}
							onChange={(e) => owner2?.setO2City(e.target.value)}
							name="owners[1].address.city"
							label="City"
							type="text"
						/>
						<ZipInputElement
							{...methods}
							disabled={disabled}
							value={owner2.o2Zip}
							onChange={(val) => owner2?.setO2Zip(val)}
							openZipModal={handleO2ZipChange}
							name="owners[1].address.zip"
							label="Zip"
							labelClass="required"
						/>
						<SelectorElement
							{...methods}
							disabled={disabled}
							name="owners[1].address.state"
							label="State"
							value={owner2.o2State}
							onChange={(e) => owner2?.setO2State(e.target.value)}
							options={stateOptions}
							labelClass="required"
						/>
						<h5 className="col-12 form-section my-3"> </h5>
						<CheckBoxElement
							{...methods}
							disabled={disabled}
							checked={owner2.o2IsControlProng}
							onChange={() =>
								owner2?.setO2IsControlProng(!owner2.o2IsControlProng)
							}
							name="owners[1].isControlProng"
							label="Controlling Manager"
							labelClass="pl-4"
						/>
						<CheckBoxElement
							{...methods}
							disabled={disabled}
							checked={owner2.o2IsSigner}
							onChange={() => owner2?.setO2IsSigner(!owner2.o2IsSigner)}
							name="owners[1].isSigner"
							label="Authorized Signer"
							labelClass="pl-4"
						/>
					</>
				)}
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: addressInfo.city,
							state: addressInfo.state,
							zip: addressInfo.zip,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
				<ChildrenModal
					modal={owner2AddressModal}
					toggle={toggleOwner2AddressModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={owner2AddressData}
						toggle={toggleOwner2AddressModal}
						address={{
							city: owner2.o2City,
							state: owner2.o2State,
							zip: owner2.o2Zip,
						}}
						setAddress={setOwner2AddressFields}
					/>
				</ChildrenModal>
			</Form>
			{!disabled && (
				<div className="col-12">
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button className="next-with-previous" onClick={handleNext}>
						Next
					</button>
				</div>
			)}
		</div>
	);
};

export default OwnersInformation;
