import React from "react";

const PreviewMessage = ({ message }) => {
	return (
		<>
			{message.length > 0 && (
				<pre className="text-messages-preview-text">{message}</pre>
			)}
		</>
	);
};

export default PreviewMessage;
