// import dayjs from "dayjs";
// export function calculateDateRange(rangeType) {
// 	let startDate;
// 	let endDate;
// 	const inputDate = dayjs().format("MM/DD/YYYY");
// 	const format = "MM/DD/YYYY";
// 	switch (rangeType) {
// 		case "Today":
// 			startDate = inputDate;
// 			endDate = inputDate;
// 			break;
// 		case "This Week":
// 			startDate = dayjs(inputDate).startOf("week");
// 			endDate = dayjs(inputDate).endOf("week");
// 			break;
// 		case "This Month":
// 			startDate = dayjs(inputDate).startOf("month");
// 			endDate = dayjs(inputDate).endOf("month");
// 			break;
// 		case "This Year":
// 			startDate = dayjs(inputDate).startOf("year");
// 			endDate = dayjs(inputDate).endOf("year");
// 			break;
// 		case "Last 180 Days":
// 			startDate = dayjs(inputDate).subtract(180, "day");
// 			endDate = dayjs(inputDate);
// 			break;
// 		case "Last Week":
// 			startDate = dayjs(inputDate).subtract(1, "week").startOf("week");
// 			endDate = dayjs(inputDate).subtract(1, "week").endOf("week");
// 			break;
// 		case "Last Month":
// 			startDate = dayjs(inputDate).subtract(1, "month").startOf("month");
// 			endDate = dayjs(inputDate).subtract(1, "month").endOf("month");
// 			break;
// 		case "Last Year":
// 			startDate = dayjs(inputDate).subtract(1, "year").startOf("year");
// 			endDate = dayjs(inputDate).subtract(1, "year").endOf("year");
// 			break;
// 		case "Last Two Years":
// 			startDate = dayjs(inputDate).subtract(2, "year").startOf("year");
// 			endDate = dayjs(inputDate).endOf("year");
// 			break;
// 		case "Last 4 Years":
// 			startDate = dayjs(inputDate).subtract(4, "year").startOf("year");
// 			endDate = dayjs(inputDate).endOf("year");
// 			break;
// 		default:
// 			startDate = endDate = inputDate;
// 	}

// 	return [dayjs(startDate).format(format), dayjs(endDate).format(format)];
// }
import dayjs from "dayjs";

export function calculateDateRange(rangeType) {
	let startDate;
	let endDate;
	const today = dayjs(); // Assumes today's date for the calculation
	const format = "MM/DD/YYYY";

	switch (rangeType) {
		case "Today":
			startDate = today;
			endDate = today;
			break;
		case "This Week":
			startDate = today.startOf("week");
			endDate = today.endOf("week");
			break;
		case "This Month":
			startDate = today.startOf("month");
			endDate = today.endOf("month");
			break;
		case "This Year":
			startDate = today.startOf("year");
			endDate = today.endOf("year");
			break;
		case "Last 180 Days":
			startDate = today.subtract(180, "day");
			endDate = today;
			break;
		case "Last Week":
			startDate = today.subtract(1, "week").startOf("week");
			endDate = today.subtract(1, "week").endOf("week");
			break;
		case "Last Month":
			startDate = today.subtract(1, "month").startOf("month");
			endDate = today.subtract(1, "month").endOf("month");
			break;
		case "Last Year":
			startDate = today.subtract(1, "year").startOf("year");
			endDate = today.subtract(1, "year").endOf("year");
			break;
		case "Last Two Years":
			// Adjusted to reflect the last two full calendar years ending in 2024
			startDate = dayjs().subtract(1, "year").startOf("year"); // Start from 01/01/2023
			endDate = dayjs().endOf("year"); // End at 12/31/2024
			break;
		case "Last 4 Years":
			startDate = dayjs().subtract(3, "year").startOf("year");
			endDate = dayjs().endOf("year");
			break;
		default:
			startDate = endDate = today;
	}

	return [startDate.format(format), endDate.format(format)];
}
