import React from "react";
import { Button } from "reactstrap";

import { useFromUser } from "../stores/LocalStorageHelper";
import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	getPdf,
} from "../api/FormsAPI";
import Swal from "sweetalert2";

const ReceiptButton = ({ paymentId, accountID }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// const getAccount = useBuildAccount();
	// const setAccount = useSetAccount();
	const username = useFromUser("username");

	const handlePrint = () => {
		const body = {
			dcReportID: 512,
			params: { dealerID, locationID, paymentID: paymentId, cashier: username },
		};

		getPdf(body).then(
			(response) => {
				// const fileName = "ST_DEAL_REVIEW_" + dealID + ".pdf";
				// createFileAndDownload(response, fileName);
				displayPdf(response);
				Swal.close();
			},
			(err) => {
				displayError(err);
			}
		);

		// formLoading("PDF");
		// saveAccount(acct).then(
		// 	(res) => {
		// 		setAccount(res);
		// 		//saveSuccessAlert();
		// 		body.accountID = res.data.content.account.ID;
		// 		getPdf(body).then(
		// 			(response) => {
		// 				// const fileName = "ST_DEAL_REVIEW_" + dealID + ".pdf";
		// 				// createFileAndDownload(response, fileName);
		// 				displayPdf(response);
		// 				Swal.close();
		// 			},
		// 			(err) => {
		// 				displayError(err);
		// 			}
		// 		);
		// 	},
		// 	(err) => {
		// 		if (!err.isGeneralError) {
		// 			showApiError(err, saveFailAlert);
		// 		}
		// 	}
		// );
	};

	return (
		<Button
			onClick={() => {
				handlePrint();
			}}
		>
			Print
		</Button>
	);
};
export default ReceiptButton;
