import React from "react";
import { Form } from "reactstrap";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
	IntegerInputElement,
} from "../../../../forms/components/InputElement";
import { vehicleStatus } from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";

const SemiTruck = () => {
	// Vehicle store
	const vehicle = useCurrentVehicleStore((state) => state, shallow);

	// hours: 0,
	// engineManufacturer: "",
	// engineSerialNumber: "",
	// engineDisplacement: "",
	// transmissionManufacturer: "",
	// transmissionType: "",
	// numberOfSpeeds: 0,
	// ratio: "",
	// drive: "",
	// suspension: "",
	// frontAxleWeight: 0,
	// rearAxleWeight: 0,
	// wheels: "",
	// wheelbase: "",
	// fifthWheelType: "",
	// brakeSystemType: "",

	// Define custom error messages for different error types
	const methods = useFormContext();

	return (
		<Form className="pt-2" onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Semi Truck"
				inputComponents={[
					<IntegerInputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.hours}
						name="hours"
						label="Operating Hours"
						onChange={(e) => {
							vehicle.editHours(e);
						}}
					/>,
					<InputElement
						key="engineManufacturer"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Engine Manufacturer"
						value={vehicle.engineManufacturer}
						type="text"
						onChange={(e) => vehicle.editEngineManufacturer(e.target.value)}
						name="engineManufacturer"
					/>,
					<InputElement
						key="engineSerialNumber"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Engine Serial Number"
						value={vehicle.engineSerialNumber}
						type="text"
						onChange={(e) => vehicle.editEngineSerialNumber(e.target.value)}
						name="engineSerialNumber"
					/>,
					<InputElement
						key="engineDisplacement"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Engine Displacement"
						value={vehicle.engineDisplacement}
						type="text"
						onChange={(e) => vehicle.editEngineDisplacement(e.target.value)}
						name="engineDisplacement"
					/>,
					<InputElement
						key="transmissionManufacturer"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Transmission Manufacturer"
						value={vehicle.transmissionManufacturer}
						type="text"
						onChange={(e) =>
							vehicle.editTransmissionManufacturer(e.target.value)
						}
						name="transmissionManufacturer"
					/>,
					<InputElement
						key="transmissionType"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Transmission Type"
						value={vehicle.transmissionType}
						type="text"
						onChange={(e) => vehicle.editTransmissionType(e.target.value)}
						name="transmissionType"
					/>,
					<IntegerInputElement
						key="numberOfSpeeds"
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.numberOfSpeeds}
						name="numberOfSpeeds"
						label="Number of Speeds"
						onChange={(e) => {
							vehicle.editNumberOfSpeeds(e);
						}}
					/>,
					<InputElement
						key="ratio"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Ratio"
						value={vehicle.ratio}
						type="text"
						onChange={(e) => vehicle.editRatio(e.target.value)}
						name="ratio"
					/>,
					<InputElement
						key="drive"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Drive"
						value={vehicle.drive}
						type="text"
						onChange={(e) => vehicle.editDrive(e.target.value)}
						name="drive"
					/>,
					<InputElement
						key="suspension"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Suspension"
						value={vehicle.suspension}
						type="text"
						onChange={(e) => vehicle.editSuspension(e.target.value)}
						name="suspension"
					/>,
					<IntegerInputElement
						key="frontAxleWeight"
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.frontAxleWeight}
						name="frontAxleWeight"
						label="Front Axle Weight"
						onChange={(e) => {
							vehicle.editFrontAxleWeight(e);
						}}
					/>,
					<IntegerInputElement
						key="rearAxleWeight"
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.rearAxleWeight}
						name="rearAxleWeight"
						label="Rear Axle Weight"
						onChange={(e) => {
							vehicle.editRearAxleWeight(e);
						}}
					/>,
					<InputElement
						key="wheels"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Wheels"
						value={vehicle.wheels}
						type="text"
						onChange={(e) => vehicle.editWheels(e.target.value)}
						name="wheels"
					/>,
					<InputElement
						key="wheelbase"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Wheel Base"
						value={vehicle.wheelbase}
						type="text"
						onChange={(e) => vehicle.editWheelbase(e.target.value)}
						name="wheelbase"
					/>,
					<InputElement
						key="fifthWheelType"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="5th Wheel Type"
						value={vehicle.fifthWheelType}
						type="text"
						onChange={(e) => vehicle.editFifthWheelType(e.target.value)}
						name="fifthWheelType"
					/>,
					<InputElement
						key="brakeSystemType"
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Brake System Type"
						value={vehicle.brakeSystemType}
						type="text"
						onChange={(e) => vehicle.editBrakeSystemType(e.target.value)}
						name="brakeSystemType"
					/>,
				]}
			/>
		</Form>
	);
};

export default SemiTruck;
