import React from "react";
import { useMultiImageData } from "./MultiImageContext";

// Component containing the button which calls a callback function
// for what to do with the images that are ready to be uploaded
const MultiImageUploadButton = ({ handleUpload, hideUploadButton }) => {
	// Multi image context
	const { imageKeys } = useMultiImageData();

	const upload = () => {
		handleUpload();
	};

	return (
		<>
			{imageKeys.length > 0 && !hideUploadButton && (
				<div className="d-flex justify-content-center align-items-center col-12">
					<button className="multi-image-upload-button" onClick={upload}>
						Upload
					</button>
				</div>
			)}
		</>
	);
};

export default MultiImageUploadButton;
