import React, { useEffect, useState } from "react";
import useCurrentDealStore from "../../../../stores/DealStore";
import shallow from "zustand/shallow";
import useCurrentSaleStore from "../../../../stores/SaleStore";
import { Button, Col, Input, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import { DealStatus } from "../../../../constants/Constants";
import { calculatePayment } from "../../helperFunctions/SaleFunctions";
import {
	getDealTemplate,
	getMenuSellingDealTempList,
} from "../../../../api/DealAPI";
import { showApiError } from "../../../../utils/errorRoutingUtils";
import { retrieveFailAlert } from "../../../../utils/alertUtils";
import { useFromUser } from "../../../../stores/LocalStorageHelper";

const MenuSelling = ({ toggle }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [templates, setTemplates] = useState([]); // List of templates retrieved from API
	const [plans, setPlans] = useState({}); // Dynamic plans

	const dealObj = useCurrentDealStore((state) => state, shallow);
	const salesObj = useCurrentSaleStore((state) => state, shallow);
	const taxDefaults = dealObj.deal?.dealSettings?.taxDefaults;

	const payment = (newSale) => {
		// Calculate the difference in GAP, warranty, and aftermarket costs
		let prop =
			parseFloat(newSale.gap || 0) +
			parseFloat(newSale.warranty || 0) +
			parseFloat(newSale.aftmkt || 0) -
			(parseFloat(salesObj.gap || 0) +
				parseFloat(salesObj.warranty || 0) +
				parseFloat(salesObj.aftmkt || 0));

		// Calculate the initial taxable amount
		let taxableAmount_ =
			(taxDefaults.servContractTaxRate === -1
				? parseFloat(salesObj.warranty || 0)
				: 0) +
			(taxDefaults.gapTaxRate === -1 ? parseFloat(salesObj.gap || 0) : 0) +
			(taxDefaults.aftMktTaxRate === -1
				? parseFloat(salesObj.aftmkt || 0) -
				  parseFloat(salesObj.aftmktNonTaxable || 0)
				: 0);

		taxableAmount_ = Math.max(taxableAmount_, 0); // Ensure taxableAmount_ is not negative

		// Calculate the initial tax
		let tax_ =
			(taxDefaults.servContractTaxRate > 0
				? parseFloat(salesObj.warranty || 0) *
				  parseFloat(dealObj.servContractTaxRate)
				: 0) +
			(taxDefaults.gapTaxRate > 0
				? parseFloat(salesObj.gap || 0) * parseFloat(taxDefaults.gapTaxRate)
				: 0) +
			(taxDefaults.aftMktTaxRate > 0
				? (parseFloat(salesObj.aftmkt || 0) -
						parseFloat(salesObj.aftmktNonTaxable || 0)) *
				  parseFloat(taxDefaults.aftMktTaxRate)
				: 0);

		// Adjust prop for initial tax and taxable amount
		prop = prop - tax_ - taxableAmount_ * parseFloat(salesObj.taxRate);

		// Recalculate taxable amount for newSale
		taxableAmount_ =
			(taxDefaults.servContractTaxRate === -1
				? parseFloat(newSale.warranty || 0)
				: 0) +
			(taxDefaults.gapTaxRate === -1 ? parseFloat(newSale.gap || 0) : 0) +
			(taxDefaults.aftMktTaxRate === -1
				? parseFloat(newSale.aftmkt || 0) -
				  parseFloat(newSale.aftmktNonTaxable || 0)
				: 0);

		taxableAmount_ = Math.max(taxableAmount_, 0); // Ensure taxableAmount_ is not negative
		console.log("Gap tax rate", taxDefaults.gapTaxRate);

		// Recalculate tax for newSale
		tax_ =
			(taxDefaults.servContractTaxRate > 0
				? parseFloat(newSale.warranty || 0) *
				  parseFloat(taxDefaults.servContractTaxRate)
				: 0) +
			(dealObj.gapTaxRate > 0
				? parseFloat(newSale.gap || 0) * parseFloat(dealObj.gapTaxRate)
				: 0) +
			(taxDefaults.aftMktTaxRate > 0
				? (parseFloat(newSale.aftmkt || 0) -
						parseFloat(newSale.aftmktNonTaxable || 0)) *
				  parseFloat(taxDefaults.aftMktTaxRate)
				: 0);

		// Adjust prop for new tax and taxable amount
		prop = prop + tax_ + taxableAmount_ * parseFloat(salesObj.taxRate);
		console.log("prop", prop);
		console.log("amtFinanced", salesObj.amtFinanced + prop);
		// Calculate the payment using the adjusted prop
		return calculatePayment(
			salesObj.apr,
			salesObj.loanPeriod,
			salesObj.term,
			salesObj.amtFinanced + prop,
			salesObj.loanDate,
			salesObj.firstDueDate,
			salesObj.bankFee
		);
	};
	// Function to create dynamic plans based on API data
	const createDynamicPlans = async (templates) => {
		const newPlans = {};

		for (const template of templates) {
			let newDeal = {};
			let newSale = {};
			const dealID = template.dealID;

			try {
				const res = await getDealTemplate(dealID);
				newDeal = res.data.content;
				newSale = res.data.content.deal.sale;
				console.log(newDeal);
				console.log(newSale);
			} catch (err) {
				// Handle error here if needed
				console.error("Failed to fetch deal template", err);
				continue; // Skip to the next template if this one fails
			}

			const dealOptions = [];

			// Populate `dealOptions` based on the deal object
			console.log("gap", newSale.gap);
			if (newSale.gap > 0) {
				dealOptions.push(
					"GAP(" +
						new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(newSale.gap) +
						")"
				);
			}

			console.log("warranty", newSale.warranty);
			if (newSale.warranty > 0) {
				dealOptions.push(
					"Warranty(" +
						new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(newSale.warranty) +
						")"
				);
			}
			console.log("aftmkt", newSale.aftmkt);
			if (newSale.aftmkt > 0) {
				dealOptions.push(
					"Aftmkt(" +
						new Intl.NumberFormat("en-US", {
							style: "currency",
							currency: "USD",
						}).format(newSale.aftmkt) +
						")"
				);
			}

			newPlans[template.name.toLowerCase()] = {
				options: dealOptions,
				payment: new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(payment(newSale)),
				dealData: newDeal, // Storing the deal data for use when selecting a plan
			};
		}

		setPlans(newPlans);
	};

	const updateMainDeal = (selectedPlan) => {
		const planDeal = plans[selectedPlan].dealData;

		// Create a copy of the sale object
		const updatedSale = {
			...salesObj,
			warranty: planDeal.deal.sale.warranty,
			gap: planDeal.deal.sale.gap,
			gapTerm: planDeal.deal.sale.gapTerm,
			aftmkt: planDeal.deal.sale.aftmkt,
		};

		// Update the main deal's sale and warranty data
		const updatedDealObj = {
			...dealObj,
			deal: {
				...dealObj.deal,
				insGapRecNum: planDeal.deal.insGapRecNum,
				insGapName: planDeal.deal.insGapName,
				//		gapInsCost: planDeal.deal.recap.gapInsCost,
				//				sale: updatedSale,
			},
		};

		// Update dealRecNum in each warranty object to match the main deal's ID
		const updatedWarrantys = planDeal.warranty.map((warranty) => ({
			...warranty,
			ID: null,
			dealRecNum: dealObj.id, // Update the dealRecNum to match the main deal's ID
		}));

		// Assign the updated warranties to the main deal
		updatedDealObj.warranty = updatedWarrantys;

		const updateAftmkt = planDeal.aftermarket.map((aftermarket) => ({
			...aftermarket,
			ID: null,
			dealRecNum: dealObj.id, // Update the dealRecNum to match the main deal's ID
		}));
		updatedDealObj.aftermarket = updateAftmkt;

		// Trigger a state update or API call to save the changes
		useCurrentDealStore.setState(updatedDealObj);
		useCurrentSaleStore.setState(updatedSale);
	};
	useEffect(() => {
		getMenuSellingDealTempList(dealerID, locationID).then(
			(res) => {
				setTemplates(res.data.content);
				createDynamicPlans(res.data.content);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, [dealerID, locationID]);

	return (
		<div>
			<Row>
				<Col sm="6">
					<Row>
						<Col sm="3">
							<Label className="bold">Customer: </Label>
						</Col>
						<Col sm="8">
							<Input
								disabled
								className="bold"
								value={dealObj.buyer.firstName + " " + dealObj.buyer.lastName}
							></Input>
						</Col>
					</Row>
					<Row>
						<Col sm="3">
							<Label className="bold">Vehicle: </Label>
						</Col>
						<Col sm="8">
							<Input
								disabled
								className="bold"
								value={
									dealObj.vehicle.year +
									" " +
									dealObj.vehicle.make +
									" " +
									dealObj.vehicle.model
								}
							></Input>
						</Col>
					</Row>
					<Row>
						<Col sm="3">
							<Label className="bold">StockNum: </Label>
						</Col>
						<Col sm="8">
							<Input
								disabled
								className="bold"
								value={dealObj.vehicle.stockNo}
							></Input>
						</Col>
					</Row>
				</Col>
				<Col sm="6">
					<Row>
						<Col sm="2">
							<Label className="bold">Price </Label>
						</Col>
						<Col sm="4">
							<NumberFormat
								name="price"
								disabled
								value={salesObj.price}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								isNumericString={true}
								className="bold"
							/>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Label className="bold">Down </Label>
						</Col>
						<Col sm="4">
							<NumberFormat
								name="price"
								disabled
								value={salesObj.down}
								decimalScale={2}
								prefix={"$"}
								fixedDecimalScale={true}
								isNumericString={true}
								className="bold"
							/>
						</Col>
					</Row>
					<Row>
						<Col sm="2">
							<Label className="bold">APR(%)</Label>
						</Col>

						<Col sm="4">
							<NumberFormat
								disabled
								name="apr"
								value={salesObj.apr * 100}
								decimalScale={4}
								fixedDecimalScale={true}
								isNumericString={true}
								readOnly={true}
								className="bold"
							/>
						</Col>
					</Row>

					<Row>
						<Col sm="2">
							<Label className="bold"> Term </Label>
						</Col>

						<Col sm="4">
							<NumberFormat
								disabled
								className="bold"
								type="number"
								name="term"
								value={salesObj.term}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<h3>Plans</h3>
			<div
				style={{
					display: "flex",
					justifyContent: "space-around",
					marginBottom: "20px",
				}}
			>
				{Object.keys(plans).map((plan) => (
					<div
						key={plan}
						style={{
							flex: 1,
							border: "1px solid black",
							padding: "10px",
							margin: "5px",
						}}
					>
						<h3>{plan.charAt(0).toUpperCase() + plan.slice(1)}</h3>
						<ul>
							{plans[plan].options.map((option, index) => (
								<li key={index}>{option}</li>
							))}
						</ul>
						<p>
							<strong>Payment:</strong> {plans[plan].payment}
						</p>
						<Button
							readOnly={dealObj.type !== DealStatus.PENDING}
							size="sm"
							onClick={() => {
								updateMainDeal(plan);
								toggle(); // Call the toggle function to close the modal or perform further actions
							}}
						>
							<i className="nc-icon nc-tap-01" /> Select
						</Button>
					</div>
				))}
			</div>
		</div>
	);
};

export default MenuSelling;
