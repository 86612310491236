import React, { useState } from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const TradeBreakdown = () => {
	const { trades } = useCurrentDealStore((state) => state, shallow);
	const [totalAcv, setTotalAcv] = useState(0);
	const [allowance, setAllowance] = useState(0);
	const [payoff, setPayoff] = useState(0);

	const Trades = () => {
		let tempAcv = 0;
		let tempAllowance = 0;
		let tempPayoff = 0;
		const fees = trades.map((key) => {
			tempAcv += key.acv;
			tempAllowance += key.allowance;
			tempPayoff += key.payoff;
			return (
				<Row>
					<Col>
						<Label>
							{key?.car?.year + " " + key?.car?.make + " " + key?.car?.model}
						</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.acv} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.allowance} />
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.payoff} />
					</Col>
				</Row>
			);
		});

		setTotalAcv(tempAcv);
		setAllowance(tempAllowance);
		setPayoff(tempPayoff);

		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Car</Label>
						</Col>
						<Col>
							<Label className="h6">ACV</Label>
						</Col>
						<Col>
							<Label className="h6">Allowance</Label>
						</Col>
						<Col>
							<Label className="h6">Payoff</Label>
						</Col>
					</Row>
					<hr />
					<Trades />
					<hr />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalAcv} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={allowance} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={payoff} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default TradeBreakdown;
