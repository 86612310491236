import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";

import useCurrentLoginStore from "stores/LoginStore";
import InputElement, {
	CurrencyInput,
	PercentInput,
} from "../../../../../forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";

import { saveCustomTax } from "api/DealAPI";
import { saveFailAlert } from "../../../../../../utils/alertUtils";
import { showApiError } from "../../../../../../utils/errorRoutingUtils";
import { stateOptions } from "constants/Constants";
import {
	twoOptionsAlert,
	successAlertCustom,
} from "../../../../../../utils/alertUtils";
import { useFormContext } from "react-hook-form";
import { SelectorElement } from "features/forms/components/InputElement";

import dayjs from "dayjs";
import NumberFormat from "react-number-format";

const CountyTaxDetails = ({ details, toggleDetails, toggleTable }) => {
	// Disable fields for non custom taxes
	const disabled = details?.isCustom === true ? false : true;

	// Login store
	const { dealerID, locationID } = useCurrentLoginStore((state) => ({
		dealerID: state.dealerID,
		locationID: state.locationID,
	}));

	// Local state
	const [printAs, setPrintAs] = useState(details?.name || "");
	const [showAs, setShowAs] = useState(details?.showAs || "");
	const [state, setState] = useState(details?.state || "");
	const [city, setCity] = useState(details?.city || "");
	const [zip, setZip] = useState(details?.zip || "");
	const [code, setCode] = useState(details?.code || "");
	const [isDefault, setIsDefault] = useState(details?.isDefault || false);
	const [isHidden, setIsHidden] = useState(false);
	const [isSplit, setIsSplit] = useState(details?.isTaxSplit || false);
	const [minimumTax, setMinimumTax] = useState(details?.minimumTax || 0);
	const [maximumTax, setMaximumTax] = useState(details?.maximumTax || 0);
	const [taxRate1, setTaxRate1] = useState(details?.userTaxRate1 || 0);
	const [taxRate2, setTaxRate2] = useState(details?.userTaxRate2 || 0);
	const [taxRate3, setTaxRate3] = useState(details?.userTaxRate3 || 0);
	const [taxRate4, setTaxRate4] = useState(details?.userTaxRate4 || 0);
	const [taxRate5, setTaxRate5] = useState(details?.userTaxRate5 || 0);
	const [taxRate6, setTaxRate6] = useState(details?.userTaxRate6 || 0);
	const [tax1, setTax1] = useState(details?.userTaxName1 || "");
	const [tax2, setTax2] = useState(details?.userTaxName2 || "");
	const [tax3, setTax3] = useState(details?.userTaxName3 || "");
	const [tax4, setTax4] = useState(details?.userTaxName4 || "");
	const [tax5, setTax5] = useState(details?.userTaxName5 || "");
	const [tax6, setTax6] = useState(details?.userTaxName6 || "");
	const [total, setTotal] = useState(details?.taxRate || 0);

	const [rateBelow1, setRateBelow1] = useState(details?.userRateBelow1 || 0);
	const [rateBelow2, setRateBelow2] = useState(details?.userRateBelow2 || 0);
	const [rateBelow3, setRateBelow3] = useState(details?.userRateBelow3 || 0);
	const [rateBelow4, setRateBelow4] = useState(details?.userRateBelow4 || 0);
	const [rateBelow5, setRateBelow5] = useState(details?.userRateBelow5 || 0);
	const [rateBelow6, setRateBelow6] = useState(details?.userRateBelow6 || 0);

	const [border1, setBorder1] = useState(details?.userBorder1 || 0);
	const [border2, setBorder2] = useState(details?.userBorder2 || 0);
	const [border3, setBorder3] = useState(details?.userBorder3 || 0);
	const [border4, setBorder4] = useState(details?.userBorder4 || 0);
	const [border5, setBorder5] = useState(details?.userBorder5 || 0);
	const [border6, setBorder6] = useState(details?.userBorder6 || 0);

	// Returns the object we save to DB
	const getTaxData = () => ({
		recNum: details?.recNum || null,
		state,
		city,
		zip,
		code,
		name: printAs,
		isDefault,
		isHidden,
		isTaxSplit: isSplit,
		minimumTax,
		maximumTax,
		showAs,
		startDate: details?.startDate || dayjs(dayjs()).format("YYYY-MM-DD"),
		taxRate: total,
		userTaxRate1: taxRate1,
		userTaxRate2: taxRate2,
		userTaxRate3: taxRate3,
		userTaxRate4: taxRate4,
		userTaxRate5: taxRate5,
		userTaxRate6: taxRate6,
		userTaxName1: tax1,
		userTaxName2: tax2,
		userTaxName3: tax3,
		userTaxName4: tax4,
		userTaxName5: tax5,
		userTaxName6: tax6,
		useTaxRate1: 0.0,
		useTaxRate2: 0.0,
		useTaxRate3: 0.0,
		useTaxRate4: 0.0,
		useTaxRate5: 0.0,
		useTaxRate6: 0.0,
		userTradeCredit1: 0.0,
		userTradeCredit2: 0.0,
		userTradeCredit3: 0.0,
		userTradeCredit4: 0.0,
		userTradeCredit5: 0.0,
		userTradeCredit6: 0.0,
		userBorder1: border1,
		userBorder2: border2,
		userBorder3: border3,
		userBorder4: border4,
		userBorder5: border5,
		userBorder6: border6,
		userRateBelow1: rateBelow1,
		userRateBelow2: rateBelow2,
		userRateBelow3: rateBelow3,
		userRateBelow4: rateBelow4,
		userRateBelow5: rateBelow5,
		userRateBelow6: rateBelow6,
		isCustom: details?.isCustom || null,
		dealerID: details?.dealerID || dealerID,
		locationID: details?.locationID || locationID,
		endDate: "1900-01-01",
	});

	// Saves existing county tax or creates a new one
	const saveTax = () => {
		const data = getTaxData();

		saveCustomTax(data).then(
			(res) => {
				toggleDetails();
				toggleTable();
				successAlertCustom(res.data.message);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Deletes a county tax by marking it hidden
	const deleteTax = async () => {
		const didConfirm = await twoOptionsAlert(
			"Are you sure you want to delete this? This process is irreversible.",
			"Yes",
			"No"
		);

		if (didConfirm === "Yes") {
			setIsHidden(true);

			const data = { ...getTaxData(), isHidden: true };

			saveCustomTax(data).then(
				() => {
					toggleDetails();
					toggleTable();
					successAlertCustom("Successfully deleted custom tax");
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};

	const methods = useFormContext();

	// Update total
	useEffect(() => {
		setTotal(taxRate1 + taxRate2 + taxRate3 + taxRate4 + taxRate5 + taxRate6);
	}, [taxRate1, taxRate2, taxRate3, taxRate4, taxRate5, taxRate6]);
	useEffect(() => {
		setTotal(details?.taxRate || 0);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<InputWrapper
				inputComponents={[
					<InputElement
						{...methods}
						disabled={disabled}
						value={printAs}
						name="printAs"
						label="Print County As"
						onChange={(e) => setPrintAs(e.target.value)}
					/>,
					<InputElement
						{...methods}
						disabled={disabled}
						value={showAs}
						name="showAs"
						label="Show On Screen"
						onChange={(e) => setShowAs(e.target.value)}
					/>,
					<InputElement
						{...methods}
						disabled={disabled}
						value={code}
						name="code"
						label="Code"
						onChange={(e) => setCode(e.target.value)}
					/>,
					<InputElement
						{...methods}
						disabled={disabled}
						value={city}
						name="city"
						label="City"
						onChange={(e) => setCity(e.target.value)}
					/>,
					<SelectorElement
						{...methods}
						disabled={disabled}
						value={state}
						name="state"
						label="State"
						onChange={(e) => setState(e.target.value)}
						options={stateOptions}
					/>,
					<InputElement
						{...methods}
						disabled={disabled}
						value={zip}
						name="zip"
						label="Zip"
						onChange={(e) =>
							setZip(e.target.value.replace(/[^\d]$/, "").substr(0, 5))
						}
					/>,
					<FormGroup className="col-sm-4">
						<Label>Sales Tax Rate</Label>
						<NumberFormat
							readOnly={isSplit === true ? true : disabled}
							value={total * 100}
							name="salesTaxRate"
							className="form-control"
							innerRef={methods.register}
							invalid={methods.errors["salesTaxRate"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTotal(0);
								} else {
									setTotal(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-4"
						value={minimumTax}
						onChange={setMinimumTax}
						label="Minimum Sales Tax"
						disableValidation
					/>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-4"
						value={maximumTax}
						onChange={setMaximumTax}
						label="maximum Sales Tax"
						disableValidation
					/>,

					// <FormGroup className="col-sm-4">
					// 	<Label>Minimum Sales Tax</Label>
					// 	<NumberFormat
					// 		disabled={disabled}
					// 		value={minimumTax * 100}
					// 		name="minimumTax"
					// 		className="form-control"
					// 		innerRef={methods.register}
					// 		invalid={methods.errors["minimumTax"] ? true : false}
					// 		decimalScale={2}
					// 		fixedDecimalScale={true}
					// 		onFocus={(e) => e.target.select()}
					// 		isNumericString={true}
					// 		customInput={Input}
					// 		suffix="%"
					// 		onValueChange={(values) => {
					// 			if (
					// 				values.value === "" ||
					// 				values.floatValue > 100 ||
					// 				values.floatValue == null ||
					// 				values.floatValue < 0
					// 			) {
					// 				setMinimumTax(0);
					// 			} else {
					// 				setMinimumTax(values.floatValue / 100);
					// 			}
					// 		}}
					// 	/>
					// </FormGroup>,
					// <FormGroup className="col-sm-4">
					// 	<Label>Maximum Sales Tax</Label>
					// 	<NumberFormat
					// 		disabled={disabled}
					// 		value={maximumTax * 100}
					// 		name="maximumTax"
					// 		className="form-control"
					// 		innerRef={methods.register}
					// 		invalid={methods.errors["maximumTax"] ? true : false}
					// 		decimalScale={2}
					// 		fixedDecimalScale={true}
					// 		onFocus={(e) => e.target.select()}
					// 		isNumericString={true}
					// 		customInput={Input}
					// 		suffix="%"
					// 		onValueChange={(values) => {
					// 			if (
					// 				values.value === "" ||
					// 				values.floatValue > 100 ||
					// 				values.floatValue == null ||
					// 				values.floatValue < 0
					// 			) {
					// 				setMaximumTax(0);
					// 			} else {
					// 				setMaximumTax(values.floatValue / 100);
					// 			}
					// 		}}
					// 	/>
					// </FormGroup>,
					<FormGroup className="col-sm-12 ml-3 mt-3">
						<Input
							disabled={disabled}
							checked={isDefault}
							name="isDefault"
							onChange={() => setIsDefault(!isDefault)}
							type="checkbox"
						/>
						<Label>Set As Default Tax</Label>
					</FormGroup>,
				]}
			/>
			<InputWrapper
				formTitle="Breakdown of Taxes and Fees"
				inputComponents={[
					<FormGroup className="col-sm-12 ml-3 mb-5">
						<Input
							disabled={disabled}
							checked={isSplit}
							name="taxSplit"
							onChange={() => setIsSplit(!isSplit)}
							type="checkbox"
						/>
						<Label>Use Breakdown Chart (Below)</Label>
					</FormGroup>,
					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax1}
						name="tax1"
						label="Tax/Fee Name 1"
						onChange={(e) => setTax1(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 1</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate1 * 100}
							name="taxRate1"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate1"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate1(0);
								} else {
									setTaxRate1(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border1}
						onChange={setBorder1}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow1}
						colSize="col-sm-3"
						onChange={setRateBelow1}
						name="rateBelow1"
						label="Rate Below 1"
						disableValidation
					/>,

					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax2}
						name="tax2"
						label="Tax/Fee Name 2"
						onChange={(e) => setTax2(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 2</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate2 * 100}
							name="taxRate2"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate2"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate2(0);
								} else {
									setTaxRate2(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border2}
						onChange={setBorder2}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow2}
						colSize="col-sm-3"
						onChange={setRateBelow2}
						name="rateBelow2"
						label="Rate Below 2"
						disableValidation
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax3}
						name="tax3"
						label="Tax/Fee Name 3"
						onChange={(e) => setTax3(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 3</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate3 * 100}
							name="taxRate3"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate3"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate3(0);
								} else {
									setTaxRate3(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,

					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border3}
						onChange={setBorder3}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow3}
						colSize="col-sm-3"
						onChange={setRateBelow3}
						name="rateBelow3"
						label="Rate Below 3"
						disableValidation
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax4}
						name="tax4"
						label="Tax/Fee Name 4"
						onChange={(e) => setTax4(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 4</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate4 * 100}
							name="taxRate4"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate4"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate4(0);
								} else {
									setTaxRate4(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border4}
						onChange={setBorder4}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow4}
						colSize="col-sm-3"
						onChange={setRateBelow4}
						name="rateBelow4"
						label="Rate Below 4"
						disableValidation
					/>,

					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax5}
						name="tax5"
						label="Tax/Fee Name 5"
						onChange={(e) => setTax5(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 5</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate5 * 100}
							name="taxRate5"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate5"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate5(0);
								} else {
									setTaxRate5(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,

					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border5}
						onChange={setBorder5}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow5}
						colSize="col-sm-3"
						onChange={setRateBelow5}
						name="rateBelow5"
						label="Rate Below 5"
						disableValidation
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-3"
						disabled={disabled}
						value={tax6}
						name="tax6"
						label="Tax/Fee Name 6"
						onChange={(e) => setTax6(e.target.value)}
					/>,
					<FormGroup className="col-sm-3">
						<Label>Tax Rate 6</Label>
						<NumberFormat
							disabled={disabled}
							value={taxRate6 * 100}
							name="taxRate6"
							className="form-control  "
							innerRef={methods.register}
							invalid={methods.errors["taxRate6"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							onFocus={(e) => e.target.select()}
							isNumericString={true}
							customInput={Input}
							suffix="%"
							onValueChange={(values) => {
								if (
									values.value === "" ||
									values.floatValue > 100 ||
									values.floatValue == null ||
									values.floatValue < 0
								) {
									setTaxRate6(0);
								} else {
									setTaxRate6(values.floatValue / 100);
								}
							}}
						/>
					</FormGroup>,
					<CurrencyInput
						disabled={disabled}
						colSize="col-sm-3"
						value={border6}
						onChange={setBorder6}
						label="Break Point"
						disableValidation
					/>,
					<PercentInput
						disabled={disabled}
						value={rateBelow6}
						colSize="col-sm-3"
						onChange={setRateBelow6}
						name="rateBelow6"
						label="Rate Below 6"
						disableValidation
					/>,
					<FormGroup className="col-12 d-flex justify-content-center align-items-center mt-3">
						<Label className="col-2">Total</Label>
						<NumberFormat
							readOnly={true}
							value={total * 100}
							name="total"
							className="form-control  h6 col-3"
							innerRef={methods.register}
							invalid={methods.errors["total"] ? true : false}
							decimalScale={4}
							fixedDecimalScale={true}
							isNumericString={true}
							customInput={Input}
							suffix="%"
						/>
					</FormGroup>,
				]}
			/>
			{!disabled && (
				<>
					{details?.recNum == null && (
						<div className="d-flex justify-content-center align-items-center">
							<Button color="primary" onClick={methods.handleSubmit(saveTax)}>
								Save
							</Button>
						</div>
					)}
					{details?.recNum != null && (
						<div className="d-flex justify-content-between">
							<Button color="primary" onClick={methods.handleSubmit(saveTax)}>
								Save
							</Button>
							<Button color="danger" onClick={deleteTax}>
								Delete
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default CountyTaxDetails;
