import React from "react";
import PropTypes from "prop-types";

/**
 * PageLayout - A layout component that wraps its children within a div with a 'content' class.
 *
 * @param {object} props - The props of the component.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the layout.
 * @returns {JSX.Element} A div wrapping the children with a 'content' class.
 */
const PageLayout = ({ children }) => {
	return <div className="content">{children}</div>;
};

PageLayout.propTypes = {
	children: PropTypes.node,
};

export default PageLayout;
