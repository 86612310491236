import React from "react";

// Define the OFAC component
const OFAC = () => {
	// Function to open the OFAC search URL in a new tab securely
	const openInNewTab = () => {
		const newWindow = window.open(
			"https://sanctionssearch.ofac.treas.gov/",
			"_blank",
			"noopener,noreferrer"
		);
		if (newWindow) newWindow.opener = null;
	};

	return (
		<button
			onClick={openInNewTab}
			style={{
				fontSize: "20px",
				fontWeight: "bold",
				fontStyle: "italic",
				cursor: "pointer",
				background: "transparent", // Makes the button background transparent
				border: "none", // Removes the border
				color: "blue", // Sets the text color to blue
				outline: "none", // This line removes the focus border
			}}
		>
			OFAC Search
		</button>
	);
};

export default OFAC;
