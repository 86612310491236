import React from "react";
import { Card, CardTitle, CardBody, CardHeader } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import ReviewRow from "./ReviewRow";

/**
 * ReviewCash component to display the total price breakdown of a sale.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewCash component
 */
const ReviewCash = () => {
	const {
		price,
		aftmkt,
		docFee,
		dealerSmog,
		tax,
		warranty,
		totalDMVFees,
		onePay,
		stateSmog,
		gap,
		totalSellerFees,
		collisionTotPremium,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			aftmkt: state.aftmkt,
			docFee: state.docFee,
			dealerSmog: state.dealerSmog,
			tax: state.tax,
			warranty: state.warranty,
			totalDMVFees: state.totalDMVFees,
			onePay: state.onePay,
			stateSmog: state.stateSmog,
			totalSellerFees: state.totalSellerFees,
			gap: state.gap,
			collisionTotPremium: state.collisionTotPremium,
			lifePremium: state.lifePremium,
			disabilityPremium: state.disabilityPremium,
		}),
		shallow
	);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => ({
			cancellationAmt: state?.contractInfo?.cancellationAmt,
			cancellationAccepted: state?.contractInfo?.cancellationAccepted,
		}),
		shallow
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Total Price</CardTitle>
			</CardHeader>
			<CardBody>
				<ReviewRow isCurrency={true} label="Price" value={price} />
				<ReviewRow isCurrency={true} label="DMV Fees" value={totalDMVFees} />
				<ReviewRow isCurrency={true} label="Aftermarket" value={aftmkt} />
				<ReviewRow isCurrency={true} label="Dealer Smog" value={dealerSmog} />
				<ReviewRow isCurrency={true} label="Smog Cert" value={stateSmog} />
				<ReviewRow isCurrency={true} label="Doc Fees" value={docFee} />
				<ReviewRow
					isCurrency={true}
					label="Seller Fees"
					value={totalSellerFees - (stateSmog + docFee + dealerSmog)}
				/>
				<ReviewRow
					colSize="7"
					fontSize="65%"
					isCurrency={true}
					label="Service Contract"
					value={warranty}
				/>
				<ReviewRow
					isCurrency={true}
					label="Insurance"
					value={gap + collisionTotPremium + lifePremium + disabilityPremium}
				/>
				<ReviewRow
					isCurrency={true}
					label="Cancellation Fee"
					value={cancellationAccepted ? cancellationAmt : 0}
					fontSize="63%"
				/>
				<ReviewRow isCurrency={true} label="Tax" value={tax} />
				<hr className="my-2" />
				<h6>
					<ReviewRow isCurrency={true} label="Total Price" value={onePay} />
				</h6>
			</CardBody>
		</Card>
	);
};

export default ReviewCash;
