import classNames from "classnames";
import React, { useState } from "react";
import { CardBody, Card } from "reactstrap";
import BuyerHeader from "../subFeatures/customer/buyer/BuyerHeader";
import CoBuyerHeader from "../subFeatures/customer/coBuyer/CoBuyerHeader";

const DealBuyerSwitchButton = ({ saleType }) => {
	console.log(saleType);
	const [switchBuyer, setSwitchBuyer] = useState(0);
	return (
		<div>
			<Card style={{ minHeight: "100px" }}>
				<CardBody>
					<div role="group" className="btn-group mb-2">
						{saleType === 1 || saleType === 2 ? (
							<button
								type="button"
								style={{ minWidth: "120px" }}
								className={classNames(
									"btn-round btn-sm btn btn-outline-secondary",
									{
										active: switchBuyer === 0,
									}
								)}
								onClick={() => setSwitchBuyer(0)}
							>
								Company
							</button>
						) : (
							<>
								<button
									type="button"
									style={{ minWidth: "120px" }}
									className={classNames(
										"btn-round btn-sm btn btn-outline-secondary",
										{
											active: switchBuyer === 0,
										}
									)}
									onClick={() => setSwitchBuyer(0)}
								>
									Buyer
								</button>
								<button
									type="button"
									style={{ minWidth: "120px" }}
									className={classNames(
										"btn-round btn-sm btn btn-outline-secondary",
										{
											active: switchBuyer === 1,
										}
									)}
									onClick={() => setSwitchBuyer(1)}
								>
									CoBuyer
								</button>
							</>
						)}
					</div>
					{switchBuyer === 0 ? <BuyerHeader /> : <CoBuyerHeader />}
				</CardBody>
			</Card>
		</div>
	);
};

export default DealBuyerSwitchButton;
