import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, FormGroup, Form } from "reactstrap";
import useCurrentVehicleStore from "stores/InventoryStore";
import { decodeVINv2 } from "api/VINDecoderAPI";
import { failAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getNewStockNo } from "api/InventoryAPI";
import { useFromUser } from "stores/LocalStorageHelper.js";

/**
 * Component to take a user entered VIN and decode it
 */
const VINDecoderForm = ({ useGlobalVin, updateStore, toggle }) => {
	const {
		vin,
		setDecodedFields,
		editStockNo,
		editIsVinLoading,
		editStyleList,
	} = useCurrentVehicleStore();
	const locationID = useFromUser("locationID");
	const inventorySettings = JSON.parse(sessionStorage.getItem("inventoryDefaults"));

	// Local states
	const [enteredVin, setEnteredVin] = useState(useGlobalVin === true ? vin : "");
	const [showScanButton, setShowScanButton] = useState(!enteredVin);

	const setNewStocNo = (vin) => {
		if (inventorySettings.isRunningStockNo) {
			getNewStockNo(locationID).then(
				(res) => {
					editStockNo(res.data.content);
				},
				(err) => {
					console.error(err);
				}
			);
		} else {
			editStockNo(vin.substring(vin.length - 6));
		}
	};

	// API call
	const getData = () => {
		editIsVinLoading(true);
		decodeVINv2(enteredVin).then(
			(res) => {
				let content = res.data.content;
				createStyleList(content);
				setStore(content[0]);
				editIsVinLoading(false);
				if (toggle) {
					toggle();
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	const createStyleList = (styles) => {
		let styleList = styles.map((style) => ({
			label: style.subModel,
			value: style.subModel,
			data: style,
		}));
		editStyleList(styleList);
	};

	const setStore = (style) => {
		if (updateStore) {
			setNewStocNo(style.vin);
		}
		setDecodedFields(style);
	};

	const handleInputChange = (e) => {
		setEnteredVin(e.target.value.toUpperCase());
		setShowScanButton(!e.target.value);
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			length: "VIN must contain a combination of 17 letters and numbers",
			matches: "Only number and letters allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		vin: yup
			.string()
			.required()
			.length(17)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setShowScanButton(!enteredVin);
	}, [enteredVin]);

	return (
		<Form onSubmit={(e) => e.preventDefault()}>
			<Col id="vinDecoder" className="px-0">
				<Row className="mx-0 align-items-center justify-content-center">
					<FormGroup>
						<Input
							maxLength="17"
							innerRef={register}
							placeholder="Enter VIN"
							value={enteredVin}
							onChange={handleInputChange}
							invalid={errors.vin ? true : false}
							name="vin"
						/>
						<ErrorMessage
							errors={errors}
							name="vin"
							render={({ message }) => (
								<p className="error_text">{message}</p>
							)}
						/>
					</FormGroup>
					<>
						{showScanButton ? (
							<FormGroup className="ml-2">
								<Button
									className="btn-primary btn btn-md"
								>
									Scan
								</Button>
							</FormGroup>
						) : (
							<FormGroup className="ml-2">

								<Button
									onClick={handleSubmit(getData)}
									className="btn-primary btn btn-md"
								>
									Decode
								</Button>
							</FormGroup>
						)}
					</>
				</Row>
			</Col>
		</Form>
	);
};

export default VINDecoderForm;
