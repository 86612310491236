import React from "react";
import { Form } from "reactstrap";

import useCurrentLoginStore from "../../../../../../stores/LoginStore";
import useIsOwnerOrAdmin from "../useIsOwnerOrAdmin";
import InputElement from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import SaveProfile from "./SaveProfile";

import { commissionType as commissionOptions } from "constants/Constants";
import { personnelType as personnelOptions } from "constants/Constants";
import { stateOptions } from "constants/Constants";
import { CurrencyInputElement } from "features/forms/components/InputElement";
import { PercentInputElement } from "features/forms/components/InputElement";
import { PhoneInputElement } from "../../../../../forms/components/InputElement";
import { SelectorElement } from "features/forms/components/InputElement";

import shallow from "zustand/shallow";

import { useFormContext } from "react-hook-form";

const ProfileTab = ({ imageFile }) => {
	const isOwnerOrAdmin = useIsOwnerOrAdmin();

	// Login store
	const {
		firstName,
		lastName,
		middleName,
		driversLicense,
		salesLicense,
		title,
		email,
		phone,
		cellPhone,
		street,
		city,
		zip,
		state,
		commissionPercent,
		commissionFixedAmount,
		commissionType,
		commissionMinimum,
		personnelType,
		username,
		editFirstName,
		editLastName,
		editMiddleName,
		editDriversLicense,
		editSalesLicense,
		editTitle,
		editEmail,
		editPhone,
		editCellPhone,
		editStreet,
		editCity,
		editZip,
		editState,
		editCommissionPercent,
		editCommissionFixedAmount,
		editCommissionType,
		editCommissionMinimum,
		editPersonnelType,
		editUsername,
	} = useCurrentLoginStore((state) => state, shallow);

	const methods = useFormContext();

	return (
		<Form>
			<InputWrapper
				footer={<SaveProfile imageFile={imageFile} />}
				formTitle="⚙️ Profile Settings"
				inputComponents={[
					<SelectorElement
						{...methods}
						readOnly={isOwnerOrAdmin}
						value={personnelType}
						name="personnelType"
						label="Personnel Type"
						onChange={(e) => editPersonnelType(e.target.value)}
						options={personnelOptions}
					/>,
					<InputElement
						{...methods}
						readOnly={isOwnerOrAdmin}
						value={title}
						onChange={(e) => editTitle(e.target.value)}
						name="title"
						label="Title"
					/>,
					<InputElement
						{...methods}
						value={username}
						onChange={(e) => editUsername(e.target.value)}
						name="username"
						label="Username"
					/>,
					<div className="col-12" />,
					<InputElement
						{...methods}
						value={firstName}
						onChange={(e) => editFirstName(e.target.value)}
						name="firstName"
						label="First Name"
					/>,
					<InputElement
						{...methods}
						value={middleName}
						onChange={(e) => editMiddleName(e.target.value)}
						name="middleName"
						label="Middle Name"
					/>,
					<InputElement
						{...methods}
						value={lastName}
						onChange={(e) => editLastName(e.target.value)}
						name="lastName"
						label="Last Name"
					/>,
					<InputElement
						{...methods}
						value={driversLicense}
						onChange={(e) => editDriversLicense(e.target.value)}
						name="driversLicense"
						label="Drivers License"
					/>,
					<InputElement
						{...methods}
						value={salesLicense}
						onChange={(e) => editSalesLicense(e.target.value)}
						name="salesLicense"
						label="Sales License"
					/>,
					<InputElement
						{...methods}
						value={email}
						onChange={(e) => editEmail(e.target.value)}
						name="email"
						label="Email"
					/>,
					<PhoneInputElement
						{...methods}
						value={phone}
						onChange={editPhone}
						name="homePhone"
						label="Home Phone"
					/>,
					<PhoneInputElement
						{...methods}
						value={cellPhone}
						onChange={editCellPhone}
						name="cellPhone"
						label="Cell Phone"
					/>,
					<div className="col-12">
						<h4>Address</h4>
					</div>,
					<InputElement
						{...methods}
						value={street}
						onChange={(e) => editStreet(e.target.value)}
						name="street"
						label="Street Address"
					/>,
					<InputElement
						{...methods}
						value={city}
						onChange={(e) => editCity(e.target.value)}
						name="city"
						label="City"
					/>,
					<InputElement
						{...methods}
						colSize="col-sm-2"
						value={zip}
						onChange={(e) => editZip(e.target.value)}
						name="zip"
						label="Zip"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-sm-2"
						value={state}
						label="State"
						name="state"
						onChange={(e) => editState(e.target.value)}
						options={stateOptions}
					/>,
					<div className="col-12">
						<h4>Commission</h4>
					</div>,

					<SelectorElement
						{...methods}
						readOnly={isOwnerOrAdmin}
						value={commissionType}
						name="commissionType"
						label="Commission Type"
						onChange={(e) => editCommissionType(e.target.value)}
						options={commissionOptions}
					/>,
					<PercentInputElement
						{...methods}
						colSize="col-md-2"
						readOnly={isOwnerOrAdmin}
						value={commissionPercent}
						onChange={editCommissionPercent}
						name="commissionPercent"
						label="Commission Percent"
					/>,
					<CurrencyInputElement
						readOnly={isOwnerOrAdmin}
						colSize="col-md-2"
						value={commissionFixedAmount}
						onChange={editCommissionFixedAmount}
						name="fixedCommission"
						label="Fixed Commission"
					/>,
					<CurrencyInputElement
						readOnly={isOwnerOrAdmin}
						colSize="col-md-2"
						value={commissionMinimum}
						onChange={editCommissionMinimum}
						name="minimumCommission"
						label="Minimum Commission"
					/>,
				]}
			/>
		</Form>
	);
};

export default ProfileTab;
