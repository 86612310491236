import React from "react";
import { Button } from "reactstrap";
import { deleteLeads } from "api/LeadApi";
import { confirmDeleteMessage } from "../../../utils/alertUtils";

const DeleteLeadButton = ({ IDs, toggle }) => {
	const callConfirmDelete = () => {
		let confirmDelete = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			confirmDelete = res;
			if (confirmDelete === "Yes, Delete it") {
				console.log(IDs);
				deleteLeads(IDs);
				toggle();
			}
		});
	};

	return (
		<div className="col text-center pt-2">
			<hr />
			<Button
				className="btn btn-danger btn-md mt-2"
				onClick={callConfirmDelete}
			>
				<i className="nc-icon nc-simple-remove"></i> Delete Contact
			</Button>
		</div>
	);
};

export default DeleteLeadButton;
