import React, { useState } from "react";
import { Col, Row, Form, Label } from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import tabOnEnter from "../utils/formNavigationUtils";
import InputElement, {
	CurrencyInput,
	IntegerInput,
	PercentInput,
	SelectorElement,
} from "../features/forms/components/InputElement";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { LoanPeriod, SaleType } from "../constants/Constants";
import Select from "react-select";
import { vendorTypesOBJ } from "constants/Constants";
import { failAlert } from "../utils/alertUtils";
import { saveVendor } from "api/VendorsAPI";
import { useEffect } from "react";
import AddVendorModal from "../components/modals/AddVendorModal";

const AccountSummary = () => {
	const schema = yup.object().shape({});
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	const { account } = useCurrentAccountStore((state) => state, shallow);
	console.log(account);

	const [lenderList, setLenderList] = useState([]);
	const { lenders, editLenders, editAccount, editLender } =
		useCurrentAccountStore((state) => state, shallow);
	console.log(account);
	const getLendersList = () => {
		console.log(lenders);
		let list = [];
		for (const key in lenders) {
			list.push({
				label: lenders[key].name,
				value: lenders[key].ID,
				lender: lenders[key],
			});
		}
		list.push({ label: "None", value: null, lender: null });
		setLenderList(list);
	};
	const selectLender = (option) => {
		const value = option.value;

		console.log(option);
		if (value === "ADD") {
			toggleLenderModal();
			console.log(value);
		} else {
			editAccount({ ...account, lenderRecNum: option.value });
			editLender(option.lender);
		}
	};
	// Saves the new lender and add it to the lender list in deal store
	const saveNewLender = (lender) => {
		if (lender != null) {
			const isHidden = false;
			//in order to save add isHidden
			const buildLender = { ...lender, isHidden };
			saveVendor(buildLender).then(
				(res) => {
					const newLender = {
						value: res.data.content.ID,
						label: res.data.content.name,
						lender: res.data.content,
					};
					console.log(newLender);

					editLenders([...lenders, res.data.content]);
					setLenderList([...lenderList, newLender]);
					editAccount({ ...account, lenderRecNum: newLender.value });
				},
				(e) => {
					console.log(e);
					failAlert("Failed to save new lender");
				}
			);
		}
	};
	//Function to open the modal
	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);

	useEffect(() => {
		getLendersList();
		// eslint-disable-next-line
	}, []);

	return (
		<>
			{/* <Col className="pb-3  px-0">
				<button
					type="button"
					className="active px-1 btn-round btn-md btn btn-outline-secondary w-100 my-0"
				>
					{SaleType[account.saleType]?.label}
				</button>
			</Col> */}

			{/* <Col className="pb-3 px-0 d-flex align-items-center justify-content-center">
				<div>
					<label></label>
					<h4>
						{account.saleType === "0"
							? "FINANCE"
							: SaleType[account.saleType]?.label}
					</h4>
				</div>
			</Col> */}
			<Form>
				<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
					<div className="col-xl-6 mb-2">
						<InputElement
							{...methods}
							disableValidation
							readOnly={true}
							type="text"
							name="AccountNumber"
							label="Account #"
							value={account.accountNumber}
							colSize="col-xl-12"
							// onChange={(e) =>
							// 	editAccount({ ...account, accountNumber: e.target.value })
							// }
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<InputElement
							{...methods}
							readOnly={true}
							disableValidation
							value={account.loanDate}
							name="dealLoanDateate"
							label="Loan Date"
							type="date"
							colSize="col-xl-12"
							// onChange={(e) =>
							// 	editAccount({ ...account, loanDate: e.target.value })
							// }
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<InputElement
							{...methods}
							disableValidation
							readOnly={true}
							type="date"
							name="firstDueDate"
							label="First Due Date"
							value={account.firstDueDate}
							// onChange={(e) =>
							// 	editAccount({ ...account, firstDueDate: e.target.value })
							// }
							colSize="col-xl-12"
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<InputElement
							{...methods}
							disableValidation
							readOnly={true}
							type="date"
							name="finalDueDate"
							label="Final Due Date"
							value={account.finalDueDate}
							// onChange={(e) =>
							// 	editAccount({ ...account, firstDueDate: e.target.value })
							// }
							colSize="col-xl-12"
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<CurrencyInput
							wholeRow={false}
							disableValidation
							readOnly={true}
							type="currency"
							name="regularPayment"
							label={
								(account.term > 1 ? account.term - 1 : 1) + " Payments of  "
							}
							value={account.regularPayment}
							colSize="col-xl-12"
							labelClass="size='sm'"
							// onChange={(e) =>
							// 	editAccount({ ...account, regularPayment: e.target.value })
							// }
						/>
					</div>

					{account.taxType === 1 ? (
						<div className="col-xl-6 mb-2">
							<CurrencyInput
								wholeRow={false}
								readOnly={true}
								label="With monthly Tax "
								type="currency"
								name="monthlyTax"
								value={account.monthlyTax}
								disableValidation
								colSize="col-xl-12"
								labelClass="size='sm'"
								// onChange={(e) =>
								// 	editAccount({ ...account, monthlyTax: e.target.value })
								// }
							/>
						</div>
					) : (
						""
					)}

					<div className="col-xl-6 mb-2">
						<CurrencyInput
							wholeRow={false}
							value={account.finalPayment}
							readOnly={true}
							name="finalPayment"
							type="Currency"
							label="Final Payment"
							disableValidation
							colSize="col-xl-12"
							labelClass="size='sm'"
							// onChange={(e) =>
							// 	editAccount({ ...account, finalPayment: e.target.value })
							// }
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<CurrencyInput
							wholeRow={false}
							disableValidation
							label="Amount Finance"
							readOnly={true}
							type="currency"
							name="amtFinanced"
							value={account.amtFinanced}
							colSize="col-xl-12"
							labelClass="size='sm'"
							// onChange={(e) =>
							// 	editAccount({ ...account, amtFinanced: e.target.value })
							// }
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<CurrencyInput
							wholeRow={false}
							disableValidation
							label="Finance Charge"
							colSize="col-xl-12"
							labelClass="size='sm'"
							readOnly={true}
							type="currency"
							name="financeCharge"
							value={account.financeCharge}
							// onChange={(e) =>
							// 	editAccount({ ...account, financeCharge: e.target.value })
							// }
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<SelectorElement
							disableValidation
							colSize="col-xl-12"
							readOnly={true}
							name="loanPeriod"
							label="Type of Deal"
							value={account.period}
							options={LoanPeriod}
							onChange={(e) => {}}
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<IntegerInput
							disableValidation
							readOnly={true}
							value={account.term}
							//									onChange={(e) => setGracePeriod(e)}
							name="term"
							label="Term"
							type="number"
							colSize="col-xl-12"
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<PercentInput
							label={
								"APR (%)" +
								"(" +
								(account.dailyIntRate * account.principleRemaining).toFixed(4) +
								" DailyInt)"
							}
							readOnly={true}
							name="financeAPR"
							value={account.apr / 100}
							disableValidation
							colSize="col-xl-12"
						/>
					</div>
					<div className="col-xl-6 mb-2">
						<CurrencyInput
							wholeRow={false}
							disableValidation
							label="Down Payment"
							colSize="col-xl-12"
							labelClass="size='sm'"
							readOnly={true}
							type="currency"
							name="downPayment"
							value={account.downPmt}
							// onChange={(e) =>
							// 	editAccount({ ...account, financeCharge: e.target.value })
							// }
						/>
					</div>

					<div className="col-xl-12 mb-2">
						<div>
							<Label>Lender</Label>
							<Select
								noOptionsMessage={() => "No Lenders available"}
								value={{
									value: account.loanSourceRecNum,
									label:
										lenderList.filter(
											(element) => element.value === account.loanSourceRecNum
										)[0]?.label || "None",
								}}
								options={[
									...lenderList,
									{ value: "ADD", label: "Add new lender" },
								]}
								onChange={(e) => selectLender(e)}
							/>
							<AddVendorModal
								title="Add Lender"
								vendorType={vendorTypesOBJ.LENDER.value}
								vendorLabel={vendorTypesOBJ.LENDER.label}
								addVendor={saveNewLender}
								toggle={toggleLenderModal}
								modal={openAddLenderModal}
							/>
						</div>
					</div>
				</Row>
			</Form>
		</>
	);
};

export default AccountSummary;
