import React, { useContext, useEffect } from "react";
import { NavLink, NavItem, TabContent, TabPane, Nav, Button } from "reactstrap";
import classnames from "classnames";
import ChildrenModal from "./ChildrenModal";
import { WizardContext } from "../../features/forms/context/WizardContext";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";

/**
 * TabItem component renders a single navigation item for the tabs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.tabName - The name of the tab.
 * @param {function} props.onClick - Function to handle click event.
 * @param {string} props.setActive - Class name to set active tab.
 * @returns {JSX.Element} The rendered TabItem component.
 */
const TabItem = ({ tabName, onClick, setActive }) => {
	return (
		<NavItem>
			<NavLink
				data-toggle="tab"
				role="tablist"
				className={setActive}
				onClick={onClick}
			>
				{tabName}
			</NavLink>
		</NavItem>
	);
};

TabItem.propTypes = {
	tabName: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	setActive: PropTypes.string.isRequired,
};

/**
 * DynamicModal component renders a modal with dynamic tabs.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.components - Array of components to render in tabs.
 * @param {function} props.toggle - Function to toggle the modal.
 * @param {boolean} props.modal - Boolean indicating if the modal is open.
 * @returns {JSX.Element} The rendered DynamicModal component.
 */
const DynamicModal = ({ modalTitle, components = [], toggle, modal }) => {
	const { activeTab, switchTab, setLength } = useContext(WizardContext);

	// All the nav tabs we need
	const navTabs = components.map((_, index) => (
		<TabItem
			id={index}
			key={index}
			tabName={(index + 1).toString()}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => {
				if (index < activeTab) {
					switchTab(index);
				}
			}}
		/>
	));

	// Content for each tab
	const navTabContents = components.map((component, index) => (
		<TabPane className="mt-3" id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));

	useEffect(() => {
		setLength(navTabContents.length);
		// eslint-disable-next-line
	}, [components]);

	return (
		<ChildrenModal
			toggle={toggle}
			size="lg"
			modalTitle={modalTitle}
			modal={modal}
			onClosed={() => switchTab(0)}
		>
			<div className="w-100">
				<Nav
					className="nav-pills nav-pill-bar nav nav-pills-primary justify-content-center"
					role="tablist"
				>
					{navTabs}
				</Nav>
				<TabContent activeTab={activeTab}>{navTabContents}</TabContent>
			</div>
		</ChildrenModal>
	);
};

DynamicModal.propTypes = {
	components: PropTypes.arrayOf(PropTypes.node).isRequired,
	toggle: PropTypes.func.isRequired,
	modal: PropTypes.bool.isRequired,
};

export default DynamicModal;

/**
 * WizardButtons component renders navigation buttons for the wizard form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.submit - Function to handle form submission.
 * @returns {JSX.Element} The rendered WizardButtons component.
 */
export const WizardButtons = ({ submit }) => {
	const { activeTab, previous, next, length } = useContext(WizardContext);
	const methods = useFormContext();

	return (
		<div className="d-flex justify-content-center my-3">
			<Button
				className="btn-primary btn-md"
				disabled={activeTab === 0}
				onClick={previous}
			>
				<i className="nc-icon nc-minimal-left" /> Previous
			</Button>
			{activeTab !== length - 1 && (
				<Button
					className="btn-primary btn-md"
					onClick={methods.handleSubmit(next)}
				>
					Next <i className="nc-icon nc-minimal-right" />
				</Button>
			)}
			{activeTab === length - 1 && (
				<Button
					className="btn-success btn-md"
					onClick={methods.handleSubmit(submit)}
				>
					<i className="nc-icon nc-check-2" /> Submit
				</Button>
			)}
		</div>
	);
};

WizardButtons.propTypes = {
	submit: PropTypes.func.isRequired,
};
