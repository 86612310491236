import React from "react";
import shallow from "zustand/shallow";
import useCurrentVehicleStore from "stores/InventoryStore";
import FormsReportsList from "../../../../forms/components/FormsReportsList";

const InventoryFormsTab = () => {
	const { invID } = useCurrentVehicleStore(
		(state) => ({
			invID: state.inventoryID,
		}),
		shallow
	);
	return (
		<FormsReportsList
			listTitle="Inventory Forms"
			listName={"inventoryForms"}
			searchable={true}
			inventoryID={invID}
		/>
	);
};

export default InventoryFormsTab;
