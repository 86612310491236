import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";

import { TemplateVariableContext } from "../../context/TemplateVariableContext";
import { TextAreaInput } from "../forms/components/InputElement";

export const MessageTemplateVariablesAndInput = ({
	body,
	updateBody,
	field,
}) => {
	const { templateVars } = useContext(TemplateVariableContext);

	// State for tracking which groups are expanded
	const [expandedGroups, setExpandedGroups] = useState({});

	// Toggle the expanded/collapsed state of a group
	const toggleGroup = (groupName) => {
		setExpandedGroups((prev) => ({
			...prev,
			[groupName]: !prev[groupName], // Flip the boolean state for the groupName
		}));
	};

	// Appends the template variable at the current cursor position or selection in the textarea
	const appendTemplateVariable = (templateVariable) => {
		let txtarea = document.getElementsByName("messageBody")[0];
		let start = txtarea.selectionStart;
		let end = txtarea.selectionEnd;
		let finText =
			txtarea.value.substring(0, start) +
			templateVariable +
			txtarea.value.substring(end);
		updateBody(finText, field);
		txtarea.focus();
		txtarea.setSelectionRange(start, start + templateVariable.length);
	};

	const methods = useFormContext();

	// Dynamically group variables by their prefix
	const groupByDynamicPrefix = (vars) => {
		const groups = {};

		Object.entries(vars).forEach(([key, value]) => {
			const prefix = key.split("_")[0]; // Get the prefix before the first underscore
			if (!groups[prefix]) {
				groups[prefix] = []; // Initialize the group if it doesn't exist
			}
			groups[prefix].push([key, value]);
		});

		return groups;
	};

	// Generate groups from templateVars
	const groupedVariables = templateVars
		? groupByDynamicPrefix(templateVars)
		: {};

	let index = 0;
	// Function to render a group
	const renderGroup = (group, groupName) => (
		<div key={index++} className="col-lg-12">
			<h6
				onClick={() => toggleGroup(groupName)}
				style={{ cursor: "pointer", textDecoration: "underline" }}
			>
				{groupName.replace("<", "").toUpperCase()} INFORMATION
			</h6>
			{expandedGroups[groupName] && (
				<ul style={{ paddingLeft: "20px", listStyleType: "none" }}>
					{/* Inline styles for indentation and no list style */}
					{group.map(([key, value]) => (
						<li
							key={key}
							onClick={() => appendTemplateVariable(key)}
							style={{ padding: "5px 0" }}
						>
							{/* Inline styles for spacing */}
							{value}
						</li>
					))}
				</ul>
			)}
		</div>
	);

	return (
		<>
			<TextAreaInput
				{...methods.register("messageBody")}
				colSize="col-lg-12"
				inputClass="text-messages-body"
				placeholder="Type a message..."
				value={body}
				onChange={(e) => updateBody(e.target.value, field)}
				name="message4Body"
				disableValidation
			/>
			<div className="message-template-container">
				{Object.entries(groupedVariables).map(([groupName, group]) =>
					renderGroup(group, groupName)
				)}
			</div>
		</>
	);
};
