import React, { useState } from "react";

import useBuildCustomer from "../../../hooks/useBuildCustomer";
import useCurrentDealStore from "../../../stores/DealStore";
import ChildrenModal from "../../../components/modals/ChildrenModal";
import DealTemplateModal from "../../settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";

import { failAlert, retrieveFailAlert } from "../../../utils/alertUtils";
import { showApiError } from "../../../utils/errorRoutingUtils";
import { CustomerStatus } from "../../../constants/Constants";
import {
	getCustomersCurrentAddress,
	getAllCustomerInfo,
	updateSoldCustomer,
} from "../../../api/CustomerAPI";

import shallow from "zustand/shallow";

export const CustomerStartDealButton = () => {
	const customer = useBuildCustomer();
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);

	// Deal store
	const { editBuyerRecNum, editBuyer, editBuyerAddress } = useCurrentDealStore(
		(state) => ({
			editBuyerRecNum: state.editBuyerRecNum,
			editBuyer: state.editBuyer,
			editBuyerAddress: state.editBuyerAddress,
		}),
		shallow
	);

	// Returns true if selected customer is in a sold deal
	const shouldCloneCustomer = (status) => status === CustomerStatus.SOLD;

	// Calls api to get all current customer info and then api to clone the info as new entries
	const getNewCustomer = async (currentID) => {
		try {
			const allInfo = await getAllCustomerInfo(currentID);
			const newCustomerInfo = await updateSoldCustomer(allInfo.data.content);

			return newCustomerInfo.data.content.customer;
		} catch (err) {
			if (!err.isGeneralError) {
				showApiError(err, retrieveFailAlert);
			}
		}
	};

	// Gets a customers current address
	const getAddress = (customer) => {
		if (customer.ID != null) {
			getCustomersCurrentAddress(customer.ID).then(
				(res) => {
					editBuyerRecNum(customer.ID);
					editBuyer(customer);
					editBuyerAddress(res.data.content);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			failAlert("Failed to add customer to deal. Please try again later.");
		}
	};
	// Handle the add customer to deal routing
	const handleDeal = async (customer) => {
		let currentCustomer = { ...customer };

		if (shouldCloneCustomer(customer.customerStatus)) {
			currentCustomer = await getNewCustomer(customer.ID);
		}

		editBuyerRecNum(customer.ID);
		getAddress(currentCustomer);
		toggleTemplate();
	};
	return (
		<div
			className="btn btn-primary-dark btn-md onMobileScreen"
			onClick={() => handleDeal(customer)}
		>
			Start Deal
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal customer={customer} />
			</ChildrenModal>
		</div>
	);
};
