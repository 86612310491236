import React, { useContext, useEffect } from "react";
import shallow from "zustand/shallow";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import useCurrentDealStore from "stores/DealStore";
import useCurrentVehicleStore from "stores/InventoryStore";
import InventoryDetailsTab from "features/inventory/subFeatures/inventoryTabs/detailsTab/InventoryDetailsTab";
import InventoryCostsTab from "features/inventory/subFeatures/inventoryTabs/costsTab/InventoryCostsTab";
import InventoryFormsTab from "features/inventory/subFeatures/inventoryTabs/formsTab/InventoryFormsTab";
import InventoryHistoryTab from "features/inventory/subFeatures/inventoryTabs/historyTab/InventoryHistoryTab";
import InventoryKbbTab from "features/inventory/subFeatures/inventoryTabs/valuesTab/InventoryKbbTab";
import Loading from "components/loadingSpinner/Loading.js";
import NavBar from "components/tabs/TabsHolder";
import InventoryPhotosTab from "features/inventory/subFeatures/inventoryTabs/photosTab/InventoryPhotosTab";
import Attachment from "features/attachments/Attachment";

import { getThumbnailURL } from "features/inventory/subFeatures/inventoryTabs/photosTab/helperFunctions";
import { getAllInvDetails } from "api/InventoryAPI";
import { getAllVendorSortedByLocationID } from "api/VendorsAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	KbbContext,
	KbbValuesProvider,
} from "features/inventory/subFeatures/inventoryTabs/valuesTab/KbbContext";
import dayjs from "dayjs";
import useCurrentPhotoStore from "stores/PhotoStore";
import InventoryTitle from "features/inventory/subFeatures/inventoryTabs/titleTab/InventoryTitle";
import InventorySaveButton from "features/inventory/helperFunctions/InventorySaveButton";

const InventoryDetails = ({ isDeal, toggle }) => {
	// Get dealer information
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const today = dayjs().format("YYYY-MM-DD");

	// Deal store
	const inventoryID = useCurrentDealStore(
		(state) => state?.vehicle?.inventoryID
	);

	// values needed for kbb
	const {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	} = useCurrentVehicleStore((state) => state, shallow);

	// Vehicle store
	const {
		isLoading,
		setInventoryFields,
		editIsLoadingFalse,
		reset,
		editVendorsList,
		editReconVendorsList,
		editFlooringVendorsList,
		editCostHist,
		editCostDescList,
		editDepartmentsList,
		editAllVendorList,
		editLocationsList,
		editBuyersList,
		editTradeLenderList,
		editThumbnail,
		editModifiedOn,
		editModifiedByID,
		editOpenedBy,
		// different
		editInventoryID,
	} = useCurrentVehicleStore((state) => state, shallow);

	const { setDcKbbObj } = useContext(KbbContext);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};

	// Photo store
	const { resetImages } = useCurrentPhotoStore(
		(state) => ({
			resetImages: state.reset,
		}),
		shallow
	);

	// Gets all the inventory related details
	const getInvDetails = () => {
		if (inventoryID != null) {
			const allInfo = getAllInvDetails(dealerID, locationID, inventoryID);
			const allVendors = getAllVendorSortedByLocationID(locationID);

			Promise.all([allInfo, allVendors]).then(
				(res) => updateStores(res),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			retrieveFailAlert();
		}
	};

	// Updates relevant stores/context values with API call
	const updateStores = (res) => {
		const invInfo = res[0].data.content;
		const allVendors = res[1].data.content;
		// Edit vehicle details tab
		setInventoryFields(invInfo.vehicle);
		setDcKbbObj(invInfo.kbb);
		// Edit costs tab
		editCostHist(invInfo.reconditioning.reconditioningList);
		editCostDescList(invInfo.reconditioning.description);
		editDepartmentsList(invInfo.reconditioning.department);
		editLocationsList(invInfo.vehicleLocations);
		editBuyersList(invInfo.vehicleBuyers);
		// Edit vendors arrays
		editAllVendorList(allVendors);
		editVendorsList(allVendors.vendors);
		editReconVendorsList(allVendors.reconSupplier);
		editFlooringVendorsList(allVendors.flooringVendor);
		editTradeLenderList(allVendors.lenders);

		editThumbnail(getThumbnailURL(invInfo.images));

		//edit more information
		editModifiedOn(today);
		editModifiedByID(dcLoginID);
		editOpenedBy(lastName + ", " + firstName);
		editIsLoadingFalse();
	};

	useEffect(() => {
		editInventoryID(inventoryID);
		getInvDetails();

		return () => {
			reset();
			resetImages();
		};
		// eslint-disable-next-line
	}, []);

	const tabs = [
		"Details",
		"Photos",
		"Costs",
		"Book Values",
		// "Flooring",
		"Forms",
		"History",
		"Attachments",
	];

	const tabComponents = [
		<InventoryDetailsTab isDeal={isDeal} toggle={toggle} />,
		<InventoryPhotosTab />,
		<InventoryCostsTab />,
		<InventoryKbbTab inventoryID={inventoryID} vehicleKbbObj={vehicleKbbObj} />,
		// <InventoryFlooringTab />,
		<InventoryFormsTab />,
		<InventoryHistoryTab />,
		<Attachment relatedID={inventoryID} type="inventory" />,
	];

	return (
		<div>
			{(isLoading && <Loading />) || (
				<>
					<InventoryTitle />
					<NavBar
						navClass="nav-pills-primary justify-content-center nav nav-pills"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
					<InventorySaveButton />
				</>
			)}
		</div>
	);
};

const Providers = ({ children }) => {
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be ${min} characters or more",
			// eslint-disable-next-line
			length: "Must be ${length} numbers long",
			matches: "Only numbers and letters are allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		model: yup.string().required().max(50),
		make: yup.string().required().max(50),
		vin: yup.string().required(),
		dateIn: yup.string().required().length(10, "Required format is YYYY/MM/DD"),
		year: yup
			.date()
			.max(new Date().getFullYear() + 1)
			.min(new Date("January 01, 1900 00:00:00").getFullYear())
			.nullable()
			.transform((curr, orig) => (orig?.length === 4 ? curr : null))
			.required("Required (YYYY)"),
		stockNo: yup.string().required().min(1).max(12),
		// .matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<KbbValuesProvider>
			<FormProvider {...methods}>{children}</FormProvider>
		</KbbValuesProvider>
	);
};

const InventoryDetailsModal = ({ isDeal, toggle }) => {
	return (
		<Providers>
			<InventoryDetails isDeal={isDeal} toggle={toggle} />
		</Providers>
	);
};
export default InventoryDetailsModal;
