import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/kbb/",
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/kbb/",
// });

export const decodeVin = async (vin) => {
	return await instance.get(`decodeVin/${vin}`);
};
export const getKbbValues = async (body) => {
	return await instance.post(`getKbbValues`, body);
};
