import React, { memo, useMemo, useState, useContext, useCallback } from "react";
import {
	Container,
	Row,
	Col,
	FormGroup,
	Input,
	Button,
	Card,
	CardBody,
} from "reactstrap";
import {
	useTable,
	useFilters,
	useSortBy,
	usePagination,
	useRowSelect,
	useFlexLayout,
} from "react-table";
import classnames from "classnames";
import matchSorter from "match-sorter";
import Select from "react-select";
import { savePack } from "api/FormsAPI";
import { PackEditContext } from "../context/PackContexts";
import { saveFailAlert } from "utils/alertUtils";

/**
 * Renders a checkbox with indeterminate state.
 * @param {Object} props - The properties passed to the component.
 */
const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return <input type="checkbox" ref={resolvedRef} {...rest} />;
	}
);

/**
 * CheckBoxReactTable component for rendering data in a table with checkboxes and other features.
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.columns - The column definitions.
 * @param {Array} props.data - The data to be displayed.
 * @param {Function} props.toggleEdit - Function to toggle edit mode.
 */
const CheckBoxReactTable = memo(
	({ columns, data, toggleEdit }) => {
		const {
			checkedFormsObj,
			pack,
			setPack,
			setForms,
			packsList,
			setPacksList,
		} = useContext(PackEditContext);
		const [numberOfRows, setNumberOfRows] = useState({
			value: 100,
			label: "100 rows",
		});
		const [pageSelect, setPageSelect] = useState({ value: 0, label: "Page 1" });

		const fuzzyTextFilterFn = useCallback((rows, id, filterValue) => {
			return matchSorter(rows, filterValue, {
				keys: [(row) => row.values[id]],
			});
		}, []);

		fuzzyTextFilterFn.autoRemove = (val) => !val;

		const filterGreaterThan = (rows, id, filterValue) => {
			return rows.filter((row) => row.values[id] >= filterValue);
		};

		filterGreaterThan.autoRemove = (val) => typeof val !== "number";

		const DefaultFilterColumn = ({
			column: { filterValue, preFilteredRows, setFilter },
		}) => {
			const count = preFilteredRows.length;

			return (
				<FormGroup>
					<Input
						value={filterValue || ""}
						placeholder={`Search ${count} records...`}
						type="text"
						onChange={(e) => setFilter(e.target.value || undefined)}
					/>
				</FormGroup>
			);
		};

		const filterTypes = useMemo(
			() => ({
				fuzzyText: fuzzyTextFilterFn,
				text: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];
						return rowValue !== undefined
							? String(rowValue)
									.toLowerCase()
									.startsWith(String(filterValue).toLowerCase())
							: true;
					});
				},
			}),
			[fuzzyTextFilterFn]
		);

		const defaultColumn = useMemo(
			() => ({
				Filter: DefaultFilterColumn,
			}),
			[]
		);

		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
			nextPage,
			pageOptions,
			previousPage,
			canPreviousPage,
			canNextPage,
			setPageSize,
			gotoPage,
			selectedFlatRows,
			setAllFilters,
			state: { filters },
		} = useTable(
			{
				columns,
				data,
				defaultColumn,
				filterTypes,
				initialState: {
					pageSize: 100,
					pageIndex: 0,
					selectedRowIds: checkedFormsObj,
				},
				getRowId: (row, relativeIndex) => row.id || relativeIndex,
			},
			useFilters,
			useSortBy,
			usePagination,
			useRowSelect,
			useFlexLayout,
			(hooks) => {
				hooks.allColumns.push((columns) => [
					{
						id: "selection",
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						),
						Cell: ({ row }) => (
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						),
						width: 20,
						minWidth: 10,
						maxWidth: 20,
					},
					...columns,
				]);
			}
		);

		const reset = () => {
			setAllFilters([]);
		};

		const getCheckedItems = () => {
			return selectedFlatRows.map((d) => d.original);
		};

		const saveData = useCallback(
			(packObj) => {
				if (packObj.forms.length <= 20 && packObj.forms.length >= 2) {
					savePack(packObj).then(
						(res) => {
							const packObj = res.data.content;
							const savedPack = packObj.pack;
							setPack(savedPack);
							setForms(packObj.forms);
							if (!packsList.find((o) => o.pack.id === savedPack.id)) {
								setPacksList((prevState) => [...prevState, packObj]);
							} else {
								setPacksList((prevState) =>
									prevState.map((obj) =>
										obj.pack.id === savedPack.id ? packObj : obj
									)
								);
							}
							toggleEdit();
						},
						(err) => {
							saveFailAlert(err.response.data.message);
						}
					);
				} else {
					saveFailAlert("Forms must be between 2 and 20 per pack.");
				}
			},
			// eslint-disable-next-line
			[pack, packsList, setForms, setPack, setPacksList, toggleEdit]
		);

		const saveAllowed = selectedFlatRows.length >= 2;

		const pageSelectData = Array.from(
			{ length: pageOptions.length },
			(_, key) => ({
				value: key,
				label: "Page " + (key + 1),
			})
		);

		const numberOfRowsData = [5, 10, 20, 25, 50, 100].map((prop) => ({
			value: prop,
			label: prop + " rows",
		}));

		return (
			<>
				<Button
					className="my-2"
					size="md"
					color="primary"
					onClick={() =>
						filters.length === 0
							? saveData({ pack, forms: getCheckedItems() })
							: reset()
					}
					disabled={!saveAllowed || filters.length !== 0}
				>
					{filters.length === 0 ? (
						<>
							<i className="nc-icon nc-simple-add"></i> Add
						</>
					) : (
						<>
							<i className="nc-icon "></i> Clear Filter
						</>
					)}
				</Button>
				<Card className="py-2 mt-2">
					<CardBody>
						<div className="ReactTable -striped -highlight forms">
							<div className="pagination-top">
								<div className="-pagination">
									<div className="-previous">
										<button
											type="button"
											onClick={previousPage}
											disabled={!canPreviousPage}
											className="-btn"
										>
											<i className="fa fa-angle-double-left text-center" />
										</button>
									</div>
									<div className="-center">
										<Container>
											<Row className="justify-content-center">
												<Col>
													<Select
														className="react-select primary d-block"
														classNamePrefix="react-select"
														name="pageSelect"
														value={pageSelect}
														onChange={(value) => {
															gotoPage(value.value);
															setPageSelect(value);
														}}
														options={pageSelectData}
														placeholder="Choose Page"
													/>
												</Col>
												<Col>
													<Select
														className="react-select primary"
														classNamePrefix="react-select"
														name="numberOfRows"
														value={numberOfRows}
														onChange={(value) => {
															setPageSize(value.value);
															setNumberOfRows(value);
														}}
														options={numberOfRowsData}
														placeholder="Choose Rows"
													/>
												</Col>
											</Row>
										</Container>
									</div>
									<div className="-next">
										<button
											type="button"
											onClick={nextPage}
											disabled={!canNextPage}
											className="-btn"
										>
											<i className="fa fa-angle-double-right text-center" />
										</button>
									</div>
								</div>
							</div>
							<table {...getTableProps()} className="rt-table">
								<thead className={"rt-thead -header"}>
									{headerGroups.map((headerGroup) => (
										<tr
											{...headerGroup.getHeaderGroupProps()}
											className="rt-tr"
										>
											{headerGroup.headers.map((column) => (
												<th
													key={column.id}
													className={classnames(
														"rt-th-check rt-resizable-header",
														{
															"-cursor-pointer": column.canFilter,
															"-sort-asc":
																column.isSorted && !column.isSortedDesc,
															"-sort-desc":
																column.isSorted && column.isSortedDesc,
														}
													)}
												>
													<div
														className="rt-resizable-header-content"
														{...column.getHeaderProps(
															column.getSortByToggleProps()
														)}
													>
														{column.render("Header")}
													</div>
													{column.canFilter ? column.render("Filter") : null}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className="rt-tbody">
									{page.map((row, i) => {
										prepareRow(row);
										return (
											<tr
												{...row.getRowProps()}
												className={classnames(
													"rt-tr",
													{ " -odd": i % 2 === 0 },
													{ " -even": i % 2 === 1 }
												)}
											>
												{row.cells.map((cell) => (
													<td
														{...cell.getCellProps([
															{
																style: cell.column.style,
																className: `${
																	cell.column.className != null
																		? cell.column.className + " "
																		: ""
																}rt-td-check singleCell`,
															},
														])}
													>
														{cell.render("Cell")}
													</td>
												))}
											</tr>
										);
									})}
								</tbody>
							</table>
							<div className="pagination-bottom" />
						</div>
					</CardBody>
				</Card>
			</>
		);
	},
	(prevProps, nextProps) =>
		JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
);

export default CheckBoxReactTable;
