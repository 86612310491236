// import React from "react";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";

export const useBuildAccount = () => {
	const acctObj = useCurrentAccountStore((state) => state, shallow);

	const getAcct = () => {
		const n = [...acctObj.account.notes];
		acctObj.account.notes = n.length > 0 ? JSON.stringify(n) : "[]";
		return acctObj;
	};

	return getAcct;
};

export default useBuildAccount;
