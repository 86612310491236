import React, { createContext, useState } from "react";
import { useFromUser } from "../stores/LocalStorageHelper";
import useCurrentVehicleStore from "../stores/InventoryStore";
import shallow from "zustand/shallow";

// Context
const MmrContext = createContext({});

// Provider to pass all the data to child components
const MMRValuesProvider = (props) => {
	const { inventoryID } = useCurrentVehicleStore((state) => state, shallow);
	// Local state
	const locations = useFromUser("locations");
	const [vehicleMMRValues, setVehicleMMRValues] = useState({
		inventoryID: inventoryID,
		dealerID: locations.dealerID,
		locationID: locations.ID,
		adjWhAbv: 0,
		adjWhAvg: 0,
		adjWhBlw: 0,
		adjRtAbv: 0,
		adjRtAvg: 0,
		adjRtBlw: 0,
		whAbv: 0,
		whAvg: 0,
		whBlw: 0,
		rtAbv: 0,
		rtAvg: 0,
		rtBlw: 0,
		avgOdometer: 0,
		avgGrade: 0,
		reqDate: null,
		retDate: null,
	});

	const setDefaultMMR = () => {
		setVehicleMMRValues({
			inventoryID: null,
			dealerID: locations.dealerID,
			locationID: locations.locationID,
			adjWhAbv: 0,
			adjWhAvg: 0,
			adjWhBlw: 0,
			adjRtAbv: 0,
			adjRtAvg: 0,
			adjRtBlw: 0,
			whAbv: 0,
			whAvg: 0,
			whBlw: 0,
			rtAbv: 0,
			rtAvg: 0,
			rtBlw: 0,
			avgOdometer: 0,
			avgGrade: 0,
			reqDate: null,
			retDate: null,
		});
	};

	return (
		<MmrContext.Provider
			value={{
				vehicleMMRValues,
				setVehicleMMRValues,
				setDefaultMMR,
			}}
		>
			{props.children}
		</MmrContext.Provider>
	);
};

export { MmrContext, MMRValuesProvider };
