import React, { useState, useEffect } from "react";
import { Button, Label, Card, CardBody, Form } from "reactstrap";
import { getVehicleSettings, saveVehicleSettings } from "api/InventoryAPI";
import {
	retrieveFailAlert,
	saveSuccessAlert,
} from "../../../../../utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import formNavigationUtils from "utils/formNavigationUtils";
import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { saveFailAlert } from "../../../../../utils/alertUtils";
import InputElement, {
	CheckBoxElement,
	CurrencyInput,
	IntegerInput,
	PercentInput,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import ManageMakeModel from "./ManageMakeModel";
import { vehicleTypeOptions } from "../../../../../constants/Constants";
/**
 *
 */
const InventorySettings = () => {
	const [inventorySettings, setInventorySettings] = useState({});

	//const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Hooks for inventory Defaults store values
	const [isRunningStockNo, setIsRunningStockNo] = useState(
		inventorySettings?.isRunningStockNo
	);
	const [firstStockNo, setFirstStockNo] = useState(
		inventorySettings?.firstStockNo?.toString()
	);
	const [runningStockNoLength, setRunningStockNoLength] = useState(
		inventorySettings?.runningStockNoLength?.toString()
	);
	const [packPercent, setPackPercent] = useState(
		inventorySettings?.packPercent
	);
	const [packUsed, setPackUsed] = useState(inventorySettings?.packUsed);

	const [vehicleType, setVehicleType] = useState(
		inventorySettings?.vehicleType
	);

	// const inventoryDefault = {
	// 	dealerID,
	// 	locationID,
	// 	isRunningStockNo,
	// 	firstStockNo,
	// 	runningStockNoLength,
	// 	packPercent,
	// 	packUsed,
	// 	vehicleType,
	// };
	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const save = () => {
		inventorySettings.isRunningStockNo = isRunningStockNo;
		inventorySettings.firstStockNo = firstStockNo;
		inventorySettings.runningStockNoLength = runningStockNoLength;
		inventorySettings.packPercent = packPercent;
		inventorySettings.packUsed = packUsed;
		inventorySettings.vehicleType = vehicleType;
		saveVehicleSettings(inventorySettings).then(
			(response) => {
				setInventorySettings(inventorySettings);
				sessionStorage.setItem(
					"inventoryDefaults",
					JSON.stringify(inventorySettings)
				);
				saveSuccessAlert();
				console.log(
					"Inventory Settings saved with ID: " + response.data.content.recnum
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const getData = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				console.log(res);
				setInventorySettings(res.data.content);
				setIsRunningStockNo(res.data.content.isRunningStockNo);
				setFirstStockNo(res.data.content.firstStockNo);
				setRunningStockNoLength(res.data.content.runningStockNoLength);
				setPackPercent(res.data.content.packPercent);
				// setPackNew(res.data.content.packNew);
				setPackUsed(res.data.content.packUsed);
				setVehicleType(res.data.content.vehicleType);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	useEffect(() => {
		console.log(firstStockNo);
		//setRunningStockNoLength(firstStockNo);
		// eslint-disable-next-line
	}, [firstStockNo]);

	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth < 768);
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div
			onKeyDown={formNavigationUtils}
			style={{ margin: "0 auto", maxWidth: "1200px" }}
		>
			<Form>
				<Button
					color="success"
					onClick={() => {
						save();
					}}
				>
					<i className="nc-icon nc-cloud-download-93"></i> Save
				</Button>

				<Card>
					<CardBody>
						<div
							style={{
								display: "flex",
								flexDirection: isMobile ? "column" : "row",
								justifyContent: "space-between",
							}}
						>
							<div style={{ flex: 1, padding: "10px" }}>
								<h3>Auto Stock Number</h3>
								<div style={{ marginBottom: "10px" }}>
									<CheckBoxElement
										disableValidation
										checked={isRunningStockNo}
										name="isRunningStockNo"
										onChange={() => setIsRunningStockNo(!isRunningStockNo)}
										label="Is Running StockNo?"
										labelClass="pl-4"
									/>
								</div>
								<div style={{ marginBottom: "10px" }}>
									<InputElement
										readOnly={!isRunningStockNo}
										disableValidation
										colSize="col-lg-6" // Maintaining your colSize
										value={firstStockNo}
										onChange={(e) => {
											console.log(e.target.value.length);
											setRunningStockNoLength(e.target.value.length);
											setFirstStockNo(e.target.value);
										}}
										name="firstStockNo"
										label="First Stock Number"
										type="text"
									/>
									<IntegerInput
										readOnly={true}
										disableValidation
										colSize="col-lg-4" // Maintaining your colSize
										value={runningStockNoLength}
										onChange={(e) => setRunningStockNoLength(e)}
										name="runningStockNoLength"
										label="Length"
										type="number"
									/>
								</div>
							</div>

							<div style={{ flex: 1, padding: "10px" }}>
								<h3>Pack setting</h3>
								<div>
									<Label> Pack = </Label>
									<PercentInput
										colSize="col-lg-6" // Maintaining your colSize
										label="Percentage"
										value={packPercent}
										onChange={setPackPercent}
										disableValidation
									/>

									<Label> + </Label>
									<CurrencyInput
										colSize="col-lg-6" // Maintaining your colSize
										value={packUsed}
										onChange={setPackUsed}
										name="packUsed"
										label="Fixed Amount"
										type="Currency"
										disableValidation
									/>
								</div>
							</div>

							<div style={{ flex: 1, padding: "10px" }}>
								<h3>Other Defaults</h3>
								<SelectorElement
									name="type"
									label="Vehicle Type"
									colSize="col-lg-6" // Maintaining your colSize
									value={vehicleType}
									onChange={(e) => setVehicleType(e.target.value)}
									options={vehicleTypeOptions}
									disableValidation
								/>
							</div>
						</div>
					</CardBody>
				</Card>
			</Form>
			<ManageMakeModel />
		</div>
	);
};

export default InventorySettings;
