import create from "zustand";

import { convertToReactSelect } from "../features/inventory/subFeatures/selector/helperFunction/SelectorFormat.js";
import { getNotesArray } from "../features/notes/NoteFunctions.js";
//import { date } from "yup";
import dayjs from "dayjs";
import { version } from "../version.js";

const useCurrentVehicleStore = create((set) => ({
	inventoryID: null,
	stockNo: "",
	vin: "",
	year: "",
	make: "",
	model: "",
	trim: "",
	odometerIn: "",
	color: "",
	intColor: "",
	condition: "",
	transmission: "",
	doors: "",
	engine: "",
	mPGHwy: "",
	mPGCity: "",
	weight: "",
	tag: "",
	description: "",
	longDescription: "",
	titleNo: "",
	customEquip: "",
	selectedEquip: "",
	optionalEquip: "",
	type: "",
	titleDateOfIssue: "",
	standardEquip: "",
	specificationEquip: "",
	isLoading: true,
	isVinLoading: false,
	thumbnail: "",
	askingPrice: 0,
	onlinePrice: "",
	dateIn: dayjs().format("YYYY-MM-DD"),
	cost: 0,
	pack: 0,
	holdBack: 0,
	incentive: 0,
	totalRecon: 0,
	totalOtherCost: 0,
	totalCost: 0,
	downPmt: 0,
	msrp: 0,
	weightFee: 0,
	isDraft: false,
	datePaid: "",
	titleBrand: "Clean",
	paymentMethod: "",
	checkNo: 0,
	newUsed: "Used",
	license: "",
	expireDate: "",
	location: "",
	buyer: "",
	vendorName: "",
	notes: [],
	reportOfSale: "",
	subModel: "",
	status: "Available",
	numCylinders: "",
	odometerOut: "",
	driveTrain: "",
	fuelType: "",

	wholesalePrice: 0,
	actualMileageFlag: 0,
	newTitleReceivedDate: "",
	newTitleNumber: "",
	titleInDate: "",
	titleState: "",
	newTitleState: "",
	shippedDate: dayjs().format("YYYY-MM-DD"),
	auctionDate: dayjs().format("YYYY-MM-DD"),
	smogCheckDate: dayjs().format("YYYY-MM-DD"),
	soldDate: dayjs().format("YYYY-MM-DD"),
	dcVersion: "v" + version,

	createdOn: dayjs().format("YYYY-MM-DD"),
	createdByID: "",
	modifiedOn: dayjs().format("YYYY-MM-DD"),
	modifiedByID: "",
	openedBy: "",
	isSmogCheck: 0,
	kbbVehicleId: null,

	flooringComp: "",
	isFloored: false,
	flooringInt: 0,
	useFlooring: 0,
	flooringAmt: 0,
	flooringValue: 0,
	flooringAdminFee: 0,
	flooringCheckNo: "",
	flooring: 0,
	flooringPaidOn: dayjs().format("YYYY-MM-DD"),
	dateFloored: dayjs().format("YYYY-MM-DD"),
	flooringStopDate: dayjs().format("YYYY-MM-DD"),

	videoURL: "",
	isAdvertised: true,
	acv: 0,
	allowance: 0,
	isTradeIn: false,
	payoff: 0,
	category: 0,
	subCategory: 0,

	tradeLenderRecNum: null,
	isFetTaxable: false,

	///////
	rvType: "",
	rvChassis: "",
	rvLength: "",
	rvEngine: "",
	rvSlideOuts: "",
	rvFuelType: "",
	rvSleepCount: "",
	rvGvrWeight: "",
	rvDryWeight: "",
	rvCaption: "",
	trailerMake: "",
	trailerModel: "",
	trailerYear: "",
	trailerIsNew: false,
	trailerStatus: "",
	trailerVin: "",
	trailerLicense: "",
	trailerAxle: "",
	trailerPrice: 0,
	height: "",
	length: "",
	tireSize: "",
	tireType: "",
	tireStatus: "",
	tireFee: 0,
	pullWeight: "",
	engine2: "",
	engine2Make: "",
	engine2Year: "",
	engine2Status: "",
	engine2Price: 0,
	engineBrake: 0,
	horsePower: "",
	vin2: "",
	diffRatio: "",
	tankCount: 0,
	tankCapacity: 0.0,
	axelFrontMake: "",
	axelRearMake: "",
	axelCount: 0,
	axelType: "",
	hiRoof: "",
	sleeper: 0,
	sleeperSize: "",
	sleeperBeds: "",
	cabStyle: "",
	permitNumber: "",
	permitExpires: "",
	permitIssuedOn: "",
	colorHex: "",
	intColorHex: "",
	isInspection: false,
	tempPlate: "",
	reminders: "",
	onlineBuyNowPrice: "",
	wholesaleBook: 0,
	imageCount: 0,
	cargurusPrice: 0,
	warranty: 0,

	hasGPS: false,
	gps_ID: "",
	gpsCompany: "",
	gpsWebsite: "",
	gps_ID2: "",
	gpsCompany2: "",
	gpsWebsite2: "",
	hasGPS2: false,

	electronicFilingDate: "",
	shipTransportCompany: "",
	shipStateOfTitle: "",
	shipDelivered: false,
	shipDeliveredDate: "",
	shipBillOfLadingRec: 0,
	shipBillOfLadingDate: "",

	hours: 0,
	engineManufacturer: "",
	engineSerialNumber: "",
	engineDisplacement: "",
	transmissionManufacturer: "",
	transmissionType: "",
	numberOfSpeeds: 0,
	ratio: "",
	drive: "",
	suspension: "",
	frontAxleWeight: 0,
	rearAxleWeight: 0,
	wheels: "",
	wheelbase: "",
	fifthWheelType: "",
	brakeSystemType: "",

	tradeLenderList: [],

	buyersList: [],
	locationList: [],
	costHist: [],
	costDescList: [],
	departmentsList: [],
	vendorRecNum: null,
	allVendorList: [],
	vendorsList: [],
	reconVendorsList: [],
	flooringVendorsList: [],
	styleList: [],
	colorList: [],
	intColorList: [],
	makeList: [],
	modelList: [],
	customEquipList: [],

	editDriveTrain: (draftDriveTrain) => set({ driveTrain: draftDriveTrain }),
	editFuelType: (draftFuelType) => set({ fuelType: draftFuelType }),
	editInventoryID: (draftInventoryID) => set({ inventoryID: draftInventoryID }),
	editStockNo: (draftStockNo) => set({ stockNo: draftStockNo }),
	editVin: (draftVin) => set({ vin: draftVin }),
	editYear: (draftYear) => set({ year: draftYear }),
	editMake: (draftMake) => set({ make: draftMake }),
	editModel: (draftModel) => set({ model: draftModel }),
	editTrim: (draftTrim) => set({ trim: draftTrim }),
	editOdometerIn: (draftOdometerIn) => set({ odometerIn: draftOdometerIn }),
	editColor: (draftColor) => set({ color: draftColor }),
	editIntColor: (draftIntColor) => set({ intColor: draftIntColor }),
	editCondition: (draftCondition) => set({ condition: draftCondition }),
	editTransmission: (draftTransmission) =>
		set({ transmission: draftTransmission }),
	editDoors: (draftDoors) => set({ doors: draftDoors }),
	editEngine: (draftEngine) => set({ engine: draftEngine }),
	editMpgHwy: (draftMpgHwy) => set({ mPGHwy: draftMpgHwy }),
	editMpgCity: (draftMpgCity) => set({ mPGCity: draftMpgCity }),
	editWeight: (draftWeight) => set({ weight: draftWeight }),

	editTag: (draftTag) => set({ tag: draftTag }),
	editDescription: (draftDescription) => set({ description: draftDescription }),
	editLongDescription: (draftLongDescription) =>
		set({ longDescription: draftLongDescription }),

	editTitleNo: (draftTitleNo) => set({ titleNo: draftTitleNo }),

	editCustomEquip: (draftCustomEquip) => set({ customEquip: draftCustomEquip }),
	editSelectedEquip: (draftSelectedEquip) =>
		set({ selectedEquip: draftSelectedEquip }),
	editOptionalEquip: (draftOptionalEquip) =>
		set({ optionalEquip: draftOptionalEquip }),
	editType: (draftType) => set({ type: draftType }),
	editTitleDateOfIssue: (draftTitleDateOfIssue) =>
		set({ titleDateOfIssue: draftTitleDateOfIssue }),
	editStandardEquip: (draftStandardEquip) =>
		set({ standardEquip: draftStandardEquip }),
	editSpecificationEquip: (draftSpecificationEquip) =>
		set({ specificationEquip: draftSpecificationEquip }),
	editIsLoadingTrue: () => set({ isLoading: true }),
	editIsLoadingFalse: () => set({ isLoading: false }),
	editIsVinLoading: (draftIsLoading) => set({ isVinLoading: draftIsLoading }),
	editThumbnail: (draftThumbnail) => set({ thumbnail: draftThumbnail }),
	editAskingPrice: (draftAskingPrice) => set({ askingPrice: draftAskingPrice }),
	editOnlinePrice: (draftOnlinePrice) => set({ onlinePrice: draftOnlinePrice }),

	editDateIn: (dateIn) => set({ dateIn: dateIn }),

	editCost: (cost) => set({ cost: cost }),
	editAcv: (acv) => set({ acv: acv }),
	editAllowance: (allowance) => set({ allowance: allowance }),
	editPayoff: (payoff) => set({ payoff: payoff }),
	editIsTradeIn: (isTradeIn) => set({ isTradeIn: isTradeIn }),
	tradeLienHolderList: [],

	editPack: (pack) => set({ pack: pack }),
	editHoldBack: (holdBack) => set({ holdBack: holdBack }),
	editIncentive: (incentive) => set({ incentive: incentive }),
	editTotalRecon: (totalRecon) => set({ totalRecon: totalRecon }),
	editTotalOtherCost: (totalOtherCost) =>
		set({ totalOtherCost: totalOtherCost }),

	editTotalCost: (totalCost) => set({ totalCost: totalCost }),

	editCostHist: (draftCostHist) => set({ costHist: draftCostHist }),
	editAllVendorList: (draftAllVendorList) =>
		set({ allVendorList: draftAllVendorList }),
	editVendorsList: (draftVendorsList) => set({ vendorsList: draftVendorsList }),
	editReconVendorsList: (draftReconVendorsList) =>
		set({ reconVendorsList: draftReconVendorsList }),
	editFlooringVendorsList: (draftFlooringVendorsList) =>
		set({ flooringVendorsList: draftFlooringVendorsList }),

	editLocationsList: (draftLocationsList) =>
		set({ locationList: draftLocationsList }),
	editBuyersList: (draftBuyersList) => set({ buyersList: draftBuyersList }),
	editCostDescList: (draftCostDescList) =>
		set({ costDescList: draftCostDescList }),
	editDepartmentsList: (draftDepartmentsList) =>
		set({ departmentsList: draftDepartmentsList }),
	editDownPmt: (draftDownPayment) => set({ downPmt: draftDownPayment }),
	editMsrp: (draftMsrp) => set({ msrp: draftMsrp }),
	editWeightFee: (draftWeightFee) => set({ weightFee: draftWeightFee }),
	editIsDraft: (draftIsDraft) => set({ isDraft: draftIsDraft }),
	editDatePaid: (draftDatePaid) => set({ datePaid: draftDatePaid }),
	editTitleBrand: (draftTitleBrand) => set({ titleBrand: draftTitleBrand }),
	editPaymentMethod: (draftPaymentMethod) =>
		set({ paymentMethod: draftPaymentMethod }),
	editCheckNo: (draftCheckNo) => set({ checkNo: draftCheckNo }),
	editNewUsed: (draftNewUsed) => set({ newUsed: draftNewUsed }),
	editLicense: (draftLicense) => set({ license: draftLicense }),
	editExpireDate: (draftLicenseExpires) =>
		set({ expireDate: draftLicenseExpires }),
	editLocation: (draftLocation) => set({ location: draftLocation }),
	editBuyer: (draftBuyer) => set({ buyer: draftBuyer }),
	editNotes: (draftNotes) => set({ notes: draftNotes }),
	editVendorRecNum: (draftVendorRecNum) =>
		set({ vendorRecNum: draftVendorRecNum }),
	editVendorName: (draftVendorName) => set({ vendorName: draftVendorName }),
	editReportOfSale: (draftReportOfSale) =>
		set({ reportOfSale: draftReportOfSale }),
	editSubModel: (val) => set({ subModel: val }),
	editStyleList: (val) => set({ styleList: val }),
	editColorList: (val) => set({ colorList: val }),
	editIntColorList: (val) => set({ intColorList: val }),
	editStatus: (val) => set({ status: val }),
	editNumCylinders: (val) => set({ numCylinders: val }),
	editOdometerOut: (draftOdometerOut) => set({ odometerOut: draftOdometerOut }),

	editCustomEquipList: (val) => set({ customEquipList: val }),
	editMakeList: (val) => set({ makeList: val }),
	editModelList: (val) => set({ modelList: val }),
	editWholesalePrice: (val) => set({ wholesalePrice: val }),
	editActualMileageFlag: (val) => set({ actualMileageFlag: val }),
	editNewTitleReceivedDate: (val) => set({ newTitleReceivedDate: val }),
	editNewTitleNumber: (val) => set({ newTitleNumber: val }),
	editTitleInDate: (val) => set({ titleInDate: val }),
	editTitleState: (val) => set({ titleState: val }),
	editNewTitleState: (val) => set({ newTitleState: val }),

	editSmogCheckDate: (val) => set({ smogCheckDate: val }),
	editSoldDate: (val) => set({ soldDate: val }),
	editShippedDate: (val) => set({ shippedDate: val }),
	editAuctionDate: (val) => set({ auctionDate: val }),
	editDCVersion: (val) => set({ dCVersion: val }),
	editCreatedOn: (val) => set({ createdOn: val }),
	editCreatedByID: (val) => set({ createdByID: val }),
	editModifiedOn: (val) => set({ modifiedOn: val }),
	editModifiedByID: (val) => set({ modifiedByID: val }),
	editOpenedBy: (val) => set({ openedBy: val }),
	editIsSmogCheck: (val) => set({ isSmogCheck: val }),
	editKbbVehicleId: (val) => set({ kbbVehicleId: val }),

	editFlooringComp: (val) => set({ flooringComp: val }),
	editIsFloored: (val) => set({ isFloored: val }),
	editFlooringInt: (val) => set({ flooringInt: val }),
	editUseFlooring: (val) => set({ useFlooring: val }),
	editFlooringAmt: (val) => set({ flooringAmt: val }),
	editFlooringValue: (val) => set({ flooringValue: val }),
	editFlooringAdminFee: (val) => set({ flooringAdminFee: val }),
	editFlooringCheckNo: (val) => set({ flooringCheckNo: val }),
	editTotalFlooring: (val) => set({ flooring: val }),
	editFlooringPaidOn: (val) => set({ flooringPaidOn: val }),
	editDateFloored: (val) => set({ dateFloored: val }),
	editFlooringStopDate: (val) => set({ flooringStopDate: val }),

	editHasGPS: (val) => set({ hasGPS: val }),
	editGps_ID: (val) => set({ gps_ID: val }),
	editGpsCompany: (val) => set({ gpsCompany: val }),
	editGpsWebsite: (val) => set({ gpsWebsite: val }),
	editGps_ID2: (val) => set({ gps_ID2: val }),
	editGpsCompany2: (val) => set({ gpsCompany2: val }),
	editGpsWebsite2: (val) => set({ gpsWebsite2: val }),
	editHasGPS2: (val) => set({ hasGPS2: val }),
	editVideoURL: (val) => set({ videoURL: val }),
	editIsAdvertised: (val) => set({ isAdvertised: val }),
	editCategory: (val) => set({ category: val }),
	editSubCategory: (val) => set({ subCategory: val }),

	editTradeLenderRecNum: (draftTradeLenderRecNum) =>
		set({ tradeLenderRecNum: draftTradeLenderRecNum }),

	//'editTradeLenderRecNum: (val) => set({ tradeLenderRecNum: val }),
	//editTradeLenderList: (val) => set({ tradeLenderList: val }),
	editTradeLenderList: (draftTradeLenderList) =>
		set({ tradeLenderList: draftTradeLenderList }),
	//////////////////
	editRvType: (val) => set({ rvType: val }),
	editRvChassis: (val) => set({ rvChassis: val }),
	editRvLength: (val) => set({ rvLength: val }),
	editRvEngine: (val) => set({ rvEngine: val }),
	editRvSlideOuts: (val) => set({ rvSlideOuts: val }),
	editRvFuelType: (val) => set({ rvFuelType: val }),
	editRvSleepCount: (val) => set({ rvSleepCount: val }),
	editRvGvrWeight: (val) => set({ rvGvrWeight: val }),
	editRvDryWeight: (val) => set({ rvDryWeight: val }),
	editRvCaption: (val) => set({ rvCaption: val }),
	editTrailerMake: (val) => set({ trailerMake: val }),
	editTrailerModel: (val) => set({ trailerModel: val }),
	editTrailerYear: (val) => set({ trailerYear: val }),
	editTrailerIsNew: (val) => set({ trailerIsNew: val }),
	editTrailerStatus: (val) => set({ trailerStatus: val }),
	editTrailerVin: (val) => set({ trailerVin: val }),
	editTrailerLicense: (val) => set({ trailerLicense: val }),
	editTrailerAxle: (val) => set({ trailerAxle: val }),
	editTrailerPrice: (val) => set({ trailerPrice: val }),
	editHeight: (val) => set({ height: val }),
	editLength: (val) => set({ length: val }),
	editTireSize: (val) => set({ tireSize: val }),
	editTireType: (val) => set({ tireType: val }),
	editTireStatus: (val) => set({ tireStatus: val }),
	editTireFee: (val) => set({ tireFee: val }),
	editPullWeight: (val) => set({ pullWeight: val }),
	editEngine2: (val) => set({ engine2: val }),
	editEngine2Make: (val) => set({ engine2Make: val }),
	editEngine2Year: (val) => set({ engine2Year: val }),
	editEngine2Status: (val) => set({ engine2Status: val }),
	editEngine2Price: (val) => set({ engine2Price: val }),
	editEngineBrake: (val) => set({ engineBrake: val }),
	editHorsePower: (val) => set({ horsePower: val }),
	editVin2: (val) => set({ vin2: val }),
	editDiffRatio: (val) => set({ diffRatio: val }),
	editTankCount: (val) => set({ tankCount: val }),
	editTankCapacity: (val) => set({ tankCapacity: val }),
	editAxelFrontMake: (val) => set({ axelFrontMake: val }),
	editAxelRearMake: (val) => set({ axelRearMake: val }),
	editAxelCount: (val) => set({ axelCount: val }),
	editAxelType: (val) => set({ axelType: val }),
	editHiRoof: (val) => set({ hiRoof: val }),
	editSleeper: (val) => set({ sleeper: val }),
	editSleeperSize: (val) => set({ sleeperSize: val }),
	editSleeperBeds: (val) => set({ sleeperBeds: val }),
	editCabStyle: (val) => set({ cabStyle: val }),
	editPermitNumber: (val) => set({ permitNumber: val }),
	editPermitExpires: (val) => set({ permitExpires: val }),
	editPermitIssuedOn: (val) => set({ permitIssuedOn: val }),
	editColorHex: (val) => set({ colorHex: val }),
	editIntColorHex: (val) => set({ intColorHex: val }),
	editIsInspection: (val) => set({ isInspection: val }),
	editTempPlate: (val) => set({ tempPlate: val }),

	editReminders: (val) => set({ reminders: val }),
	editOnlineBuyNowPrice: (val) => set({ onlineBuyNowPrice: val }),
	editWholesaleBook: (val) => set({ wholesaleBook: val }),
	editImageCount: (val) => set({ imageCount: val }),
	editCargurusPrice: (val) => set({ cargurusPrice: val }),
	editWarranty: (val) => set({ warranty: val }),
	editElectronicFilingDate: (val) => set({ electronicFilingDate: val }),
	editShipTransportCompany: (val) => set({ shipTransportCompany: val }),
	editShipStateOfTitle: (val) => set({ shipStateOfTitle: val }),
	editShipDelivered: (val) => set({ shipDelivered: val }),
	editShipDeliveredDate: (val) => set({ shipDeliveredDate: val }),
	editShipBillOfLadingRec: (val) => set({ shipBillOfLadingRec: val }),
	editShipBillOfLadingDate: (val) => set({ shipBillOfLadingDate: val }),
	editIsFetTaxable: (val) => set({ isFetTaxable: val }),

	editHours: (val) => set({ hours: val }),
	editEngineManufacturer: (val) => set({ engineManufacturer: val }),
	editEngineSerialNumber: (val) => set({ engineSerialNumber: val }),
	editEngineDisplacement: (val) => set({ engineDisplacement: val }),
	editTransmissionManufacturer: (val) => set({ transmissionManufacturer: val }),
	editTransmissionType: (val) => set({ transmissionType: val }),
	editNumberOfSpeeds: (val) => set({ numberOfSpeeds: val }),
	editRatio: (val) => set({ ratio: val }),
	editDrive: (val) => set({ drive: val }),
	editSuspension: (val) => set({ suspension: val }),
	editFrontAxleWeight: (val) => set({ frontAxleWeight: val }),
	editRearAxleWeight: (val) => set({ rearAxleWeight: val }),
	editWheels: (val) => set({ wheels: val }),
	editWheelbase: (val) => set({ wheelbase: val }),
	editFifthWheelType: (val) => set({ fifthWheelType: val }),
	editBrakeSystemType: (val) => set({ brakeSystemType: val }),

	setInventoryFields: (vehicle) => {
		set({
			...vehicle,
			optionalEquip: convertToReactSelect(vehicle.optionalEquip),
			notes: getNotesArray(vehicle.notes),
			year: vehicle.year.substring(0, 4),
			category: vehicle.category === null ? 0 : vehicle.category,
			subCategory: vehicle.subCategory === null ? 0 : vehicle.subCategory,
		});
	},

	setDecodedFields: (style) => {
		set({
			selectedEquip: "",
			vin: style.vin,
			type: style.type,
			year: style.year,
			make: style.make,
			model: style.model,
			subModel: style.subModel,
			trim: style.trim !== "Base" ? style.trim : "",
			doors: style.doors,
			engine: style.engine,
			transmission: style.transmission,
			specificationEquip: style.specificationEquip,
			standardEquip: style.standardEquip,
			optionalEquip: convertToReactSelect(style.optionalEquip),
			colorList: style.colorList,
			intColorList: style.intColorList,
			mPGHwy: style.mPGHwy,
			mPGCity: style.mPGCity,
			numCylinders: style.numCylinders,
			fuelType: style.fuelType,
			weight: style.weight,
			driveTrain: style.driveTrain,
		});
	},

	reset: () =>
		set({
			inventoryID: null,
			stockNo: "",
			vin: "",
			year: "",
			make: "",
			model: "",
			trim: "",
			odometerIn: "",
			color: "",
			intColor: "",
			condition: "",
			transmission: "",
			doors: "",
			engine: "",
			mPGHwy: "",
			mPGCity: "",
			weight: "",
			Tag: "",
			description: "",
			longDescription: "",
			titleNo: "",
			customEquip: "",
			selectedEquip: "",
			optionalEquip: "",
			type: "",
			titleDateOfIssue: "",
			standardEquip: "",
			specificationEquip: "",
			isLoading: true,
			thumbnail: "",
			askingPrice: 0,
			onlinePrice: 0,
			dateIn: dayjs().format("YYYY-MM-DD"),
			cost: 0,
			acv: 0,
			allowance: 0,
			payoff: 0,
			pack: 0,
			holdBack: 0,
			incentive: 0,
			totalRecon: 0,
			totalOtherCost: 0,
			totalCost: 0,
			downPmt: 0,
			msrp: 0,
			weightFee: 0,
			isDraft: false,
			datePaid: "",
			paymentMethod: "",
			titleBrand: "Clean",
			checkNo: 0,
			costHist: [],
			costDescList: [],
			departmentsList: [],
			allVendorList: [],
			vendorsList: [],
			reconVendorsList: [],
			flooringVendorsList: [],
			buyersList: [],
			locationsList: [],
			vendorName: "",
			vendorRecNum: null,
			newUsed: "Used",
			license: "",
			expireDate: "",
			location: "",
			buyer: "",
			notes: [],
			reportOfSale: "",
			subModel: "",
			styleList: [],
			colorList: [],
			intColorList: [],
			status: "Available",
			numCylinders: "",
			odometerOut: "",
			customEquipList: [],
			makeList: [],
			modelList: [],
			driveTrain: "",
			fuelType: "",
			wholesalePrice: 0,
			actualMileageFlag: "",
			newTitleReceivedDate: "",
			newTitleNumber: "",
			titleInDate: "",
			titleState: "",
			newTitleState: "",
			shippedDate: dayjs().format("YYYY-MM-DD"),
			auctionDate: dayjs().format("YYYY-MM-DD"),
			smogCheckDate: dayjs().format("YYYY-MM-DD"),
			soldDate: dayjs().format("YYYY-MM-DD"),
			dCVersion: "",
			createdOn: dayjs().format("YYYY-MM-DD"),
			createdByID: "",
			modifiedOn: dayjs().format("YYYY-MM-DD"),
			modifiedByID: "",
			openedBy: "",
			isSmogCheck: 0,
			kbbVehicleId: null,
			isFetTaxable: false,

			flooringComp: "",
			isFloored: false,
			flooringInt: 0,
			useFlooring: 0,
			flooringAmt: 0,
			flooringValue: 0,
			flooringAdminFee: 0,
			flooringCheckNo: "",
			flooring: 0,
			flooringPaidOn: dayjs().format("YYYY-MM-DD"),
			dateFloored: dayjs().format("YYYY-MM-DD"),
			flooringStopDate: dayjs().format("YYYY-MM-DD"),
			tradeLenderRecNum: null,
			tradeLenderList: [],
			videoURL: "",
			isAdvertised: true,
			category: 0,
			subCategory: 0,

			///////
			rvType: "",
			rvChassis: "",
			rvLength: "",
			rvEngine: "",
			rvSlideOuts: "",
			rvFuelType: "",
			rvSleepCount: "",
			rvGvrWeight: "",
			rvDryWeight: "",
			rvCaption: "",
			trailerMake: "",
			trailerModel: "",
			trailerYear: "",
			trailerIsNew: false,
			trailerStatus: "",
			trailerVin: "",
			trailerLicense: "",
			trailerAxle: "",
			trailerPrice: 0,
			height: "",
			length: "",
			tireSize: "",
			tireType: "",
			tireStatus: "",
			tireFee: 0,
			pullWeight: "",
			engine2Make: "",
			engine2Year: "",
			engine2Status: "",
			engine2Price: 0,
			engineBrake: 0,
			horsePower: "",
			vin2: "",
			diffRatio: "",
			tankCount: 0,
			tankCapacity: 0.0,
			axelFrontMake: "",
			axelRearMake: "",
			axelCount: 0,
			axelType: "",
			hiRoof: "",
			sleeper: 0,
			sleeperSize: "",
			sleeperBeds: "",
			cabStyle: "",
			permitNumber: "",
			permitExpires: "",
			permitIssuedOn: "",
			colorHex: "",
			intColorHex: "",
			isInspection: false,
			tempPlate: "",
			mpgHwy: "",
			mpgCity: "",
			reminders: "",
			onlineBuyNowPrice: "",
			wholesaleBook: 0,
			imageCount: 0,
			cargurusPrice: 0,
			warranty: 0,

			hasGPS: false,
			gps_ID: "",
			gpsCompany: "",
			gpsWebsite: "",
			gps_ID2: "",
			gpsCompany2: "",
			gpsWebsite2: "",
			hasGPS2: false,

			electronicFilingDate: "",
			shipTransportCompany: "",
			shipStateOfTitle: "",
			shipDelivered: false,
			shipDeliveredDate: "",
			shipBillOfLadingRec: 0,
			shipBillOfLadingDate: "",

			hours: 0,
			engineManufacturer: "",
			engineSerialNumber: "",
			engineDisplacement: "",
			transmissionManufacturer: "",
			transmissionType: "",
			numberOfSpeeds: 0,
			ratio: "",
			drive: "",
			suspension: "",
			frontAxleWeight: 0,
			rearAxleWeight: 0,
			wheels: "",
			wheelbase: "",
			fifthWheelType: "",
			brakeSystemType: "",
		}),
}));

export default useCurrentVehicleStore;
