import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";

const useSetAccount = () => {
	// Account store
	const { setAccountFields } = useCurrentAccountStore(
		(state) => ({
			setAccountFields: state.setAccountFields,
		}),
		shallow
	);
	const setAccount = (response) => {
		console.log(response.data.content);
		setAccountFields(response.data.content);
	};

	return setAccount;
};

export default useSetAccount;
