import React from "react";
import { Button } from "reactstrap";

import useBuildCustomer from "hooks/useBuildCustomer";
import useCurrentCustomerStore from "stores/CustomerStore";

import { saveFailAlert } from "../../../utils/alertUtils";
import { showApiError } from "../../../utils/errorRoutingUtils";
import { updateSoldCustomer } from "api/CustomerAPI";

import shallow from "zustand/shallow";

import { useFormContext } from "react-hook-form";

const CloneDealCustomerButton = ({
	editCustomer,
	editAddress,
	editMailAddress,
	editRecNum,
	toggle,
}) => {
	// Customer Details
	const customer = useBuildCustomer();

	const {
		addressHistory,
		employmentHistory,
		insurance,
		references,
		currentAddress,
		mailingAddress,
		credit700,
	} = useCurrentCustomerStore(
		(state) => ({
			addressHistory: state.addressHist,
			employmentHistory: state.empHist,
			insurance: state.insurance,
			references: state.references,
			currentAddress: state.currentAddress,
			mailingAddress: state.mailingAddress,
			credit700: state.credit700Hist,
		}),
		shallow
	);

	// Calls API to update the customer with this information as the most recent
	const cloneCustomerInfo = (data) => {
		// Customer current address object
		const updateCurrentAddress = {
			street: data.currentStreet,
			unit: data.currentUnit,
			city: data.currentCity,
			state: data.currentState,
			zip: data.currentZip,
			county: data.currentCounty,
			fromDate: data.currentFromDate,
			ownership: data.currentOwnership,
		};

		// Customer mailing address object
		const updateMailingAddress = {
			street: data.mailStreet,
			unit: data.mailUnit,
			city: data.mailCity,
			state: data.mailState,
			zip: data.mailZip,
			county: data.mailCounty,
		};

		const saveData = {
			customer,
			currentAddress: { ...currentAddress, ...updateCurrentAddress },
			mailingAddress: { ...mailingAddress, ...updateMailingAddress },
			addressHistory,
			employmentHistory,
			insurance,
			references,
			credit700,
		};

		updateSoldCustomer(saveData).then(
			(res) => updateDealStore(res),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Update deal store with the customer information
	const updateDealStore = (res) => {
		console.log(res);
		const customerInfo = res.data.content.customer;

		editRecNum(customerInfo.ID);
		editCustomer(customerInfo);
		editAddress(res.data.content.currentAddress);
		editMailAddress(res.data.content.mailingAddress);
		toggle();
	};

	// Get form context
	const methods = useFormContext();

	return (
		<div className="d-flex justify-content-center">
			<Button
				type="submit"
				color="primary"
				onClick={methods.handleSubmit(cloneCustomerInfo)}
			>
				Select Customer
			</Button>
		</div>
	);
};

export default CloneDealCustomerButton;
