import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import NumberFormat from "react-number-format";
import ReactTable from "components/tables/ReactTable";
import FilterColumn from "utils/filterColumn";

const CreditAppTable = ({ data, setMessageCustomers, onRowClick }) => {
	const Cell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.lastName} {props.row.original.firstName}
				</div>
				<div>{props.row.original.origin}</div>
				<div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
				<div>
					P:{" "}
					<NumberFormat
						value={props.row.original.phone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				<div>
					C:{" "}
					<NumberFormat
						value={props.row.original.cellPhone}
						format="+1 (###) ###-####"
						isNumericString={true}
						displayType="text"
					/>
				</div>
				<div>Status : {props.row.original.status}</div>
				{/* <div>ApplicationDate : {props.row.original.applicationDate}</div> */}
			</span>
		</div>
	);
	const vehicleCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				<div>
					{props.row.original.stockNo != null && (
						<div>Stock#: {props.row.original.stockNo}</div>
					)}
				</div>
				<div>
					{props.row.original.invVin != null && (
						<div>VIN: {props.row.original.invVin}</div>
					)}
				</div>
			</span>
		</div>
	);
	const statusCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>{props.row.original.status}</span>
		</div>
	);
	const dateCell = (props) => (
		<div>
			<span style={{ fontWeight: "bold" }}>
				{props.row.original.applicationDate}
			</span>
		</div>
	);

	return (
		<div>
			<Row>
				<Col md="12">
					<Card>
						<CardBody>
							<ReactTable
								className="-striped -highlight primary-pagination"
								checkboxColumn={setMessageCustomers != null}
								setSelectedRows={setMessageCustomers}
								onRowClick={onRowClick}
								data={data}
								columns={[
									{
										Header: "Photo",
										accessor: "thumbnail",
										disableFilters: true,
									},
									{
										id: "CreditAppDetails",
										Header: "Lead",
										accessor: "CreditAppDetails",
										style: { whiteSpace: "unset" },
										Cell: Cell,
									},

									{
										id: "vehicleDetails",
										Header: "Vehicle",
										accessor: "vehicleDetails",
										style: { whiteSpace: "unset" },

										Cell: vehicleCell,
									},
									{
										id: "applicationDate",
										Header: "Date",
										accessor: "applicationDate",

										Cell: dateCell,
									},
									{
										id: "status",
										Header: "status",
										accessor: "status",
										Filter: ({ column }) => (
											<FilterColumn
												column={column}
												customOptions={["Active", "Inactive"]}
											/>
										),
										filter: "includes",

										Cell: statusCell,
									},

									// {
									// 	Header: "LookingFor",
									// 	accessor: "lookingFor",
									// 	style: { whiteSpace: "unset" },
									// 	Cell: lookinCell,
									// },
									{
										Header: "Actions",
										accessor: "Actions",
										disableFilters: true,
									},
								]}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CreditAppTable;
