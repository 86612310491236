import React, { useEffect } from "react";
import {
	Row,
	Col,
	CardTitle,
	CardBody,
	CardHeader,
	Input,
	Label,
	Card,
	Button,
} from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import { failAlert } from "utils/alertUtils";
import { IntegerInput } from "features/forms/components/InputElement";

const BalloonModalBody = ({ toggle }) => {
	const {
		firstDueDate,
		balloonDate,
		balloon,
		payment,
		balloonTerm,
		amtFinanced,
		financeCharge,
		loan,
		editBalloonTerm,
		term,
		editBalloonDate,
		finalDueDate,
		hasBalloon,
		editHasBalloon,
		rollBack,
	} = useCurrentSaleStore((state) => state, shallow);

	const { type } = useCurrentDealStore((state) => state.deal, shallow);

	const CurrencyDisplay = ({ value, className = "" }) => (
		<NumberFormat
			readOnly={true}
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			customInput={Input}
			className={className}
		/>
	);

	const summaryRow1 = [
		{
			label: "First Due Date",
			value: (
				<Input
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Balloon Date",
			value: (
				<Input
					value={balloonDate ? dayjs(balloonDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (balloonTerm >= 1 ? balloonTerm : 0) + " Payments",
			value: <CurrencyDisplay value={payment} />,
		},
		{
			label: "Balloon Payment",
			value: <CurrencyDisplay value={balloon} className="text-success" />,
		},
	];

	const summaryRow2 = [
		{
			label: "Amount Financed",
			value: <CurrencyDisplay value={amtFinanced} />,
		},
		{
			label: "Finance Charge",
			value: <CurrencyDisplay value={financeCharge} />,
		},
	];

	const summaryRow3 = [
		{
			label: "Total of Payments",
			value: <CurrencyDisplay value={loan} />,
		},
	];

	useEffect(() => {
		console.log(hasBalloon);
		if (hasBalloon === false || hasBalloon === null) {
			editBalloonTerm(term);
			editBalloonDate(finalDueDate);
		}
		if (rollBack === 90) {
			failAlert("Cannot balloon deal rolled by term");
			toggle();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Balloon Summary</CardTitle>
			</CardHeader>
			<CardBody className="pb-3">
				<Row>
					<Col className="mb-2 col-6 col-xxl-6">
						<IntegerInput
							colSize=""
							label="Balloon Term"
							readOnly={type !== DealStatus.PENDING}
							value={balloonTerm}
							onChange={editBalloonTerm}
							disableValidation
						/>
					</Col>
				</Row>
				<Row>
					{summaryRow1.map((obj) => (
						<>
							<Col className="mb-2 col-6 col-xxl-6">
								<Label>{obj.label}</Label>
								{obj.value}
							</Col>
						</>
					))}
				</Row>
				<hr className="my-2" />
				{summaryRow2.map((obj) => (
					<div>
						<Row>
							<Col xl="6">
								<Label className="pt-2">{obj.label}</Label>
							</Col>
							<Col xl="6">{obj.value}</Col>
						</Row>
					</div>
				))}

				<hr className="my-2" />
				{summaryRow3.map((obj) => (
					<Row>
						<Col className="my-2 col-6 px-0 text-center">
							<h6>{obj.label}</h6>
						</Col>
						<Col className="my-2 col-6 px-0 text-center">{obj.value}</Col>
					</Row>
				))}
				<Col className="text-center">
					<Button
						readOnly={type !== DealStatus.PENDING}
						onClick={() => {
							editHasBalloon(true);
							toggle();
						}}
					>
						Accept
					</Button>
					<Button color="danger" onClick={toggle}>
						Cancel
					</Button>
				</Col>
			</CardBody>
		</Card>
	);
};

export default BalloonModalBody;
