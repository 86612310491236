import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import DownPaymentForm from "./DownPaymentForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "components/modals/ChildrenModal";
import DownPaymentTable from "./DownPaymentTable";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";

/**
 * DownPaymentModal component renders a modal for managing down payments, including a form for adding new payments
 * and a table displaying existing payments.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.modal - Controls the modal's visibility.
 * @param {Function} props.toggle - Function to toggle the modal visibility.
 * @returns {JSX.Element} The rendered DownPaymentModal component.
 */
const DownPaymentModal = ({ modal, toggle }) => {
	const { totalPickupPmts } = useCurrentSaleStore(
		(state) => ({
			totalPickupPmts: state.totalPickupPmts,
		}),
		shallow
	);

	const { pickupPmts, type } = useCurrentDealStore(
		(state) => ({
			pickupPmts: state.pickupPmts,
			type: state.deal.type,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(pickupPmts.length);
	const [open, setOpen] = useState(false);

	/**
	 * Toggles the visibility of the nested modal.
	 */
	const toggleOpen = () => setOpen(!open);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			modalTitle="Deferred Payments"
			size="lg"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<DownPaymentForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>
					<Col className="my-2">
						<Button
							className="btn btn-primary btn-md"
							readOnly={type !== DealStatus.PENDING}
							onClick={toggleOpen}
						>
							<i className="nc-icon nc-simple-add"></i> Add Deferred
						</Button>
					</Col>
					<DownPaymentTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
					<Card className="card-plain">
						<Row className="justify-content-center">
							<Col sm="6">
								<CardText tag="h3" className="text-center">
									Total Deferred
								</CardText>
							</Col>
							<hr className="mb-2 w-75 mx-auto" />
							<Col sm="6">
								<CardText tag="h3" className="text-center">
									<NumberFormat
										value={totalPickupPmts}
										thousandSeparator
										decimalScale={2}
										fixedDecimalScale
										prefix="$"
										isNumericString
										readOnly
										displayType="text"
									/>
								</CardText>
							</Col>
						</Row>
					</Card>
				</>
			}
		/>
	);
};

DownPaymentModal.propTypes = {
	modal: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default DownPaymentModal;
