import Dev from "components/layouts/Dev";
import Public from "components/layouts/Public";
import React from "react";

import { Route, Redirect } from "react-router-dom";
import { authenticated } from "utils/authUtils";

const PrivateRoute = (props, { children, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(prop) =>
				authenticated() ? (
					props.devRoute ? (
						<Dev {...prop} />
					) : (
						<Public {...prop} />
					)
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	);
};

export default PrivateRoute;
