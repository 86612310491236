import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { FTP_IMAGE_URL, OnlineLeadStatus } from "constants/Constants";
import Loading from "components/loadingSpinner/Loading";
import Camera from "assets/img/placeholder.png";
import LeadTable from "../../../../components/tables/LeadTable";
import { getLeadDetails } from "api/LeadApi";
import ChildrenModal from "components/modals/ChildrenModal";
import DealTemplateModal from "features/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import {
	getVehicleDetails,
	getVehicleDetailsByStockNo,
} from "api/InventoryAPI";
import { saveAsCustomer } from "api/LeadApi";
import TwilioSendMessage from "services/twilioService/TwilioSendMessage";
import OnlineLeadDetailsPage from "../onlineLeadDetailsPage/OnlineLeadDetailsPage";
import { Button, Col, Row } from "reactstrap";

const OnlineLeadListPage = ({ setMessageCustomers, leadSource }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// const { fromDate, toDate } = useDateRangeStore();
	//toggle template modal
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);
	const [vehicle, setVehicle] = useState({});

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [tmpLead, setTmpLead] = useState({});
	const [customer, setCustomer] = useState({});
	const [viewTextModal, setViewTextModal] = useState(false);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const [customerID, setCustomerID] = useState(null);

	// Text message modal
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);

	const toggle = () => {
		setShowModal(!showModal);
	};

	const handleView = (id) => {
		getLeadDetails(id).then(
			(res) => {
				setTmpLead(res.data.content);
				toggle();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Modify the handleRowClick to pass the lead ID to handleView
	const handleRowClick = (lead) => {
		const leadID = lead.leadID;

		if (leadID) {
			handleView(leadID);
		} else {
			console.error("Lead ID is undefined or missing in the lead object");
		}
	};

	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			content[key].thumbnail =
				FTP_IMAGE_URL +
				"/" +
				dealerID +
				"/" +
				locationID +
				"/" +
				content[key].vin +
				"-0.jpg";
			container.thumbnail = (
				<Row>
					<Col lg={6} className="mb-2 full-width-col">
						<img
							className="zoom mb-2"
							alt="vehicle"
							src={
								content[key].thumbnail
									? `${content[key].thumbnail}?lastmod=${Date.now()}`
									: Camera
							}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = Camera;
							}}
							style={{
								width: "100%",
								maxWidth: "200px",
								height: "auto",
							}}
						/>
					</Col>
					<Col lg={6} className="mb-2 full-width-col">
						<img
							className="zoom"
							alt="customer-profile"
							src={`https://Dealernetwork.com/images/PersonalImages/${dealerID}/${locationID}/${content[key].leadID}.jpg`}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = Camera;
							}}
							style={{
								width: "100%",
								maxWidth: "200px",
								height: "auto",
							}}
						/>
					</Col>
				</Row>
			);
			container.firstName = content[key].firstName;
			container.lastName = content[key].lastName;
			container.workPhone = content[key].workPhone;
			container.phone = content[key].phone;
			container.cellPhone = content[key].cellPhone;
			container.Website = content[key].Website;
			container.leadID = content[key].leadID;
			container.email = content[key].email;
			container.status = OnlineLeadStatus[content[key].leadStatus].label;
			container.origin = content[key].origin;
			container.leadDetails =
				container.firstName +
				" " +
				container.lastName +
				" " +
				container.email +
				" " +
				container.cellPhone +
				" " +
				container.phone +
				" " +
				container.origin +
				" " +
				container.status;

			container.unit = content[key].unit;
			container.address = content[key].address;
			container.city = content[key].city;
			container.state = content[key].state;
			container.zipCode = content[key].zipCode;
			container.country = content[key].country;
			container.dateGenerated = dayjs(content[key].dateGenerated).format(
				"MM/DD/YYYY HH:mm:ss"
			);
			container.dateRead = content[key].dateRead;
			container.leadStatus = content[key].leadStatus;
			container.leadOrigin = content[key].leadOrigin = null;

			container.message = content[key].message;
			container.dealerNotes = content[key].dealerNotes;
			container.priceRange = content[key].priceRange;

			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			container.trim = content[key].trim;
			container.color = content[key].color;
			container.lookingFor =
				container.make + " " + container.model + " " + container.year;
			container.inventoryID = content[key].inventoryID;

			if (content[key].inventoryID !== null) {
				container.vehicleDetails = `${content[key].vehicleMake} ${content[key].vehicleModel} ${content[key].vehicleYear}`;
			} else {
				container.vehicleDetails = "";
			}
			container.stockNumber = content[key].vehicleStockNo;
			container.vin = content[key].vin;

			container.customerID = content[key].customerID;
			container.salesPersonID = content[key].salesPersonID;
			container.salesPerson2ID = content[key].salesPerson2ID;
			container.source = content[key].source;
			container.lastSync = content[key].lastSync;
			container.insertedOn = content[key].insertedOn;
			container.UpdatedOn = content[key].UpdatedOn;
			container.Actions = (
				<Row
					style={{
						justifyContent: "center",
						gap: "6px",
					}}
				>
					<Button
						className="btn-sm btn-info"
						onClick={(e) => {
							e.stopPropagation();
							setTextHistoryNumber(
								content[key].cellPhone || content[key].phone
							);
							setCustomerID(content[key].leadID);
							toggleViewTextModal();
						}}
					>
						<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
						&nbsp;Chat
					</Button>
					<Button
						className="btn-sm btn-primary"
						onClick={(e) => {
							e.stopPropagation();
							handleView(content[key].leadID);
						}}
					>
						<i className="icon-big text-center nc-icon nc-paper text-default" />
						&nbsp;View
					</Button>
					<Button
						className="btn-sm btn-primary"
						onClick={(e) => {
							e.stopPropagation();
							setTmpLead(content[key]);
							handleDeal(content[key]);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24" />
						&nbsp;Deal
					</Button>
				</Row>
			);

			return container;
		});

		return container;
	};

	const handleDeal = async (lead) => {
		saveAsCustomer(lead.leadID).then(
			(res) => {
				setCustomer(res.data.content.Customer);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		if (lead.inventoryID > 0) {
			// Adds vehicle information to the deal store
			getVehicleDetails(lead.inventoryID).then(
				(res) => {
					const v = res.data.content;
					setVehicle(v);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			if (lead.stockNumber !== "" && lead.stockNumber !== null) {
				getVehicleDetailsByStockNo(lead.toLocationID, lead.stockNumber).then(
					(res) => {
						const v = res.data.content;
						setVehicle(v);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, retrieveFailAlert);
						}
					}
				);
			}
		}
	};

	// Gets a list of all the Leads for this dealer and location
	const getListData = () => {
		console.log(dayjs().format("HH:mm:ss"));

		setLoadingList(true);
		const result = dataFormat(leadSource);
		console.log(dayjs().format("HH:mm:ss"));
		setData(result);
		console.log(dayjs().format("HH:mm:ss"));

		setLoadingList(false);
	};

	// const LeadAddButton = () => {
	// 	return (
	// 		!showModal && (
	// 			<>
	// 				<div
	// 					className="btn btn-sm btn-primary float-button"
	// 					onClick={(e) => {
	// 						e.stopPropagation();
	// 						setTmpLead({});
	// 						toggle();
	// 					}}
	// 				>
	// 					<i className="nc-icon nc-check-2" /> Add Lead
	// 				</div>
	// 			</>
	// 		)
	// 	);
	// };

	useEffect(() => {
		getListData();

		// eslint-disable-next-line
	}, [leadSource]);

	return (
		<div style={{ minHeight: "1000px" }}>
			{loadingList && <Loading />}
			{!loadingList && (
				<LeadTable
					data={data}
					setMessageCustomers={setMessageCustomers}
					onRowClick={(lead) => handleRowClick(lead)}
				/>
			)}

			<ChildrenModal
				modalTitle="Lead"
				modal={showModal}
				toggle={toggle}
				onClosed={getListData}
			>
				<OnlineLeadDetailsPage lead={tmpLead} toggle={toggle} />
			</ChildrenModal>
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal customer={customer} newVehicle={vehicle} />
			</ChildrenModal>
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TwilioSendMessage
					sendToNumber={textHistoryNumber}
					customerID={customerID}
					personType="Lead"
				/>
			</ChildrenModal>
		</div>
	);
};

export default OnlineLeadListPage;
