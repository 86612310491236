import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import NetTradeModal from "../modals/NetTradeModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";

const NetTrade = () => {
	const [openTradeModal, setOpenTradeModal] = useState(false);
	const { totalNetTrade } = useCurrentSaleStore((state) => ({
		totalNetTrade: state.totalNetTrade,
	}));

	// Function to open the modal
	const toggle = () => {
		setOpenTradeModal(!openTradeModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			<NetTradeModal modal={openTradeModal} toggle={toggle} />
			<div className="col-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn-md  btn btn-primary px-0"
					label="Net Trade"
					toggle={toggle}
					modalTitle="Net Trade"
				/>
			</div>

			<div className="col-6 pr-0  moneyIn pl-2">
				<NumberFormat
					value={totalNetTrade}
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					prefix={"$"}
					isNumericString={true}
					readOnly={true}
					customInput={Input}
					className="text-danger bold"
				/>
			</div>
		</Row>
	);
};

export default NetTrade;
