import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl,
});

export const getTrades = async (dealID) => {
	return await instance.get(`/deal/trades/${dealID}`);
};

export const saveTrades = async (trade) => {
	return await instance.post(`/deal/trades/save`, trade);
};

export const deleteTrades = async (tradeID) => {
	return await instance.post(`/deal/trades/deleteTrade/${tradeID}`, null);
};
