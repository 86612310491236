import React, { useEffect, useState } from "react";
import Camera from "assets/img/placeholder.png";
import Loading from "components/loadingSpinner/Loading";
import DealTable from "components/tables/DealTable";
import useRoute from "hooks/useRoute";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useFromUser } from "stores/LocalStorageHelper";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import useDateRangeStore from "stores/DateRangeStore";
import ViewToggle from "components/ViewToggle";
import { getDealListByDateRange } from "api/DealAPI";
import { LoanPeriod, PATHS, SaleType } from "constants/Constants";
import dayjs from "dayjs";
import DealCardList from "./DealCardList";

/**
 * DealListData component to fetch and display a list of deals.
 *
 * @param {Object} props - Component props
 * @param {Array} props.statusList - The list of statuses to filter deals by
 * @returns {JSX.Element} The rendered component
 */
const DealListData = ({ statusList }) => {
	const history = useHistory();
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const homeRoute = useRoute(PATHS.DASHBOARD);
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);
	const { fromDate, toDate } = useDateRangeStore();
	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [view, setView] = useState("table");

	/**
	 * Handles the click event on a row.
	 *
	 * @param {Object} deal - The deal object
	 */
	const handleRowClick = (deal) => {
		getDealData(deal.dealID);
	};

	/**
	 * Formats the data for display.
	 *
	 * @param {Array} content - The content to format
	 * @returns {Array} The formatted data
	 */
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			container.dealID = content[key].dealID;
			container.thumbnail = (
				<div onClick={() => getDealData(content[key].dealID)}>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);

			container.hasTrade = content[key]?.hasTrade || false;
			container.buyerID = content[key].buyerID;
			container.coBuyerID = content[key].coBuyerID;
			container.inventoryID = content[key].inventoryID;
			container.status = content[key].status;
			container.phone = content[key].phone || "";
			container.fullName = content[key].name;
			container.email = content[key].email || "";
			container.customerDetails = `${container.fullName} ${container.email} ${
				container.phone
			} ${content[key]?.hasTrade ? "Has Trades" : ""} ${
				content[key].coBuyerID ? `coBuyer: ${content[key].cobuyer}` : ""
			}`;
			container.vehicle = content[key].vehicle;
			container.stock = content[key].stock;
			container.vin = content[key].vin;
			container.vehicleStatus = content[key].vehicleStatus;
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			if (content[key].inventoryID !== null) {
				container.vehicleDetails = `${container.make} ${container.model} ${container.year} ${container.stock} ${container.vin} ${container.vehicleStatus}`;
			} else {
				container.vehicleDetails = "";
			}
			container.loanDate = content[key].loanDate
				? content[key].loanDate.substr(0, 10)
				: null;
			container.saleType =
				SaleType[content[key].saleType]?.label || "Unknown type";
			container.dealNumber = content[key].dealNumber;
			container.term = content[key].term;
			container.payment = content[key].payment;
			container.loanPeriod = LoanPeriod[content[key].loanPeriod]?.label;
			container.deal = `${container.status} ${dayjs(container.loanDate).format(
				"MM/DD/YYYY"
			)} ${container.saleType} ${container.dealNumber} ${
				container.term
			} ${currencyFormatter.format(container.payment)} ${container.loanPeriod}`;
			container.Actions = (
				<div className="text-center">
					<span
						className="btn-sm btn btn-primary"
						onClick={() => getDealData(content[key].dealID)}
					>
						<i className="icon-big text-center nc-icon nc-paper text-default" />
						&nbsp;View
					</span>
				</div>
			);
			return container;
		});
		return container;
	};

	/**
	 * Fetches the list data based on status.
	 *
	 * @param {Array} status - The list of statuses
	 */
	const getListData = (status) => {
		if (dealerID && locationID) {
			getDealListByDateRange(locationID, status, fromDate, toDate)
				.then((response) => {
					const formattedData = dataFormat(response.data.content);
					setData(formattedData);
					setLoadingList(false);
				})
				.catch((error) => {
					showApiError(error, retrieveFailAlert);
					history.push(homeRoute);
				});
		}
	};

	/**
	 * Navigates to the deal detail page.
	 *
	 * @param {number} id - The deal ID
	 */
	const getDealData = (id) => {
		history.push(dealDetailPage, { ID: id });
	};

	useEffect(() => {
		setLoadingList(true);
		getListData(statusList);
		// eslint-disable-next-line
	}, [statusList, dealerID, locationID, fromDate, toDate]);

	return (
		<div style={{ minHeight: "1000px" }}>
			{loadingList ? (
				<Loading containerStyle={{ height: "80vh" }} />
			) : (
				<>
					{!isMobile && <ViewToggle view={view} onToggle={setView} />}
					{isMobile || view === "card" ? (
						<DealCardList data={data} onView={getDealData} focusSearch={true} />
					) : (
						<DealTable onRowClick={handleRowClick} data={data} />
					)}
				</>
			)}
		</div>
	);
};

export default DealListData;
