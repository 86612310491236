import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/vendors/",
});

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });

export const getVendorListByLocationID = async (locationID) => {
	return await instance.get(`/list/${locationID}`);
};

export const getVendorListByType = async (locationID, type) => {
	return await instance.get(`${locationID}/type/${type}`);
};
export const getVendorByID = async (ID) => {
	return await instance.get(`${ID}`);
};

export const saveVendor = async (body) => {
	return await instance.post(`save`, body);
};

export const deleteVendorByHidden = async (ID) => {
	return await instance.get(`deleteByHidden/${ID}`);
};
/**
 * Gets all vendors available to the dealer sorted into each vendor category
 * @param {*} locationID
 * @returns
 */
export const getAllVendorSortedByLocationID = async (locationID) => {
	return await instance.get(`/all/${locationID}`);
};
