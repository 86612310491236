import React from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import { Table, CardBody, Card, Button } from "reactstrap";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import {
	calculateFinalDueDate,
	calculatePayment,
	calculateFinalPayment,
	calculateFinanceCharge,
} from "features/deal/helperFunctions/SaleFunctions";
import { DealStatus } from "constants/Constants";

/**
 * PaymentOptionsModal component renders a modal containing payment options in a table format.
 *
 * @component
 * @param {Object} props - React props.
 * @param {Function} props.toggle - Function to toggle the modal.
 * @returns {JSX.Element}
 */
const PaymentOptionsModal = ({ toggle }) => {
	const {
		loanPeriod,
		apr,
		loanDate,
		firstDueDate,
		amtFinanced,
		editTerm,
		bankFee,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			apr: state.apr,
			loanDate: state.loanDate,
			firstDueDate: state.firstDueDate,
			amtFinanced: state.amtFinanced,
			editTerm: state.editTerm,
			bankFee: state.bankFee,
			deferredTax: state.deferredTax,
		}),
		shallow
	);

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	/**
	 * Generates the term value based on loan period and row number.
	 *
	 * @param {number} rowNum - The row number.
	 * @returns {number} The generated term.
	 */
	const generateTerm = (rowNum) => {
		let term = 0;
		switch (loanPeriod) {
			case 0: // Weekly - 52 times a year
				term += rowNum * 52;
				break;
			case 1: // Bi-Weekly - 26 times a year
				term += rowNum * 26;
				break;
			case 2: // Semi-Monthly - 24 times a year
				term += rowNum * 24;
				break;
			case 3: // Monthly - 12 times a year
				term += rowNum * 12;
				break;
			case 4: // Annually - 1 time a year
				term = rowNum;
				break;
			case 5: // Cash - N/A
				term = 1;
				break;
			case 6: // Bi-Annually - 2 times a year
				term += rowNum * 2;
				break;
			default:
				break;
		}
		return term;
	};

	/**
	 * Creates row data including term, payment, finance charge, and final due date.
	 *
	 * @param {number} rowNum - The row number.
	 * @returns {Object} The row data.
	 */
	const createRowData = (rowNum) => {
		const term = generateTerm(rowNum);
		const finalDueDate = dayjs(
			calculateFinalDueDate(loanPeriod, term, firstDueDate)
		).format("MM/DD/YYYY");
		const payment = calculatePayment(
			apr,
			loanPeriod,
			term,
			amtFinanced,
			loanDate,
			firstDueDate,
			bankFee
		);
		const finalPayment = calculateFinalPayment(apr, payment, term, amtFinanced);
		const financeCharge = calculateFinanceCharge(
			amtFinanced,
			payment,
			finalPayment,
			term
		);

		return { term, payment, financeCharge, finalDueDate };
	};

	// Memoized row data for performance optimization
	const data = React.useMemo(
		() => Array.from({ length: 9 }, (_, i) => createRowData(i + 1)),
		// eslint-disable-next-line
		[loanPeriod, apr, loanDate, firstDueDate, amtFinanced, bankFee]
	);

	// Memoized columns configuration for React Table
	const columns = React.useMemo(
		() => [
			{
				Header: "Term",
				accessor: "term",
			},
			{
				Header: "Payment",
				accessor: "payment",
				Cell: ({ value }) => (
					<NumberFormat
						value={value}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				),
			},
			{
				Header: "Finance Chg.",
				accessor: "financeCharge",
				Cell: ({ value }) => (
					<NumberFormat
						value={value}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						displayType="text"
					/>
				),
			},
			{
				Header: "Date",
				accessor: "finalDueDate",
			},
			{
				Header: "",
				accessor: "actions",
				Cell: ({ row }) => (
					<Button
						readOnly={type !== DealStatus.PENDING}
						size="sm"
						className="btn-primary"
						onClick={() => {
							editTerm(row.original.term);
							toggle();
						}}
					>
						<i className="nc-icon nc-tap-01" /> Select
					</Button>
				),
			},
		],
		[type, editTerm, toggle]
	);

	// React Table hook
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns, data });

	return (
		<div>
			<Card>
				<CardBody className="py-0">
					<Table responsive {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th {...column.getHeaderProps()}>
											{column.render("Header")}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</Table>
				</CardBody>
			</Card>
		</div>
	);
};

PaymentOptionsModal.propTypes = {
	toggle: PropTypes.func.isRequired,
};

export default PaymentOptionsModal;
