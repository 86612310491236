import React, { useState } from "react";
import shallow from "zustand/shallow";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import OpenModalButton from "features/deal/components/OpenModalButton";
import CustomerListPage from "features/crm/subFeatures/contactListPage/CustomerListPage";
import CustomerDealDetails from "../CustomerDealDetails";
import ContactAddPage from "features/crm/components/CustomerAddPage";

/**
 * BuyerModal component for managing buyer information in a deal.
 * @returns {React.ReactElement} The rendered BuyerModal component.
 */
const BuyerModal = () => {
	const [modalState, setModalState] = useState({
		add: false,
		edit: false,
		new: false,
	});

	const {
		buyerRecNum,
		type,
		editBuyer,
		editBuyerRecNum,
		editBuyerAddress,
		editBuyerMailAddress,
	} = useCurrentDealStore(
		(state) => ({
			buyerRecNum: state.deal.buyerRecNum,
			type: state.deal.type,
			editBuyer: state.editBuyer,
			editBuyerRecNum: state.editBuyerRecNum,
			editBuyerAddress: state.editBuyerAddress,
			editBuyerMailAddress: state.editBuyerMailAddress,
		}),
		shallow
	);

	/**
	 * Toggles the state of a specific modal.
	 * @param {string} modalType - The type of modal to toggle.
	 */
	const toggleModal = (modalType) => {
		setModalState((prev) => ({ ...prev, [modalType]: !prev[modalType] }));
	};

	/**
	 * Removes the current buyer from the deal.
	 */
	const removeBuyer = () => {
		editBuyerRecNum(null);
		editBuyer({});
	};

	/**
	 * Updates the deal with new customer information.
	 * @param {Object} data - The new customer data.
	 */
	const updateNewCustomer = (data) => {
		const { customer, currentAddress, mailingAddress } = data;
		editBuyerRecNum(customer.ID);
		editBuyer(customer);
		editBuyerAddress(currentAddress);
		editBuyerMailAddress(mailingAddress);
		toggleModal("new");
	};

	if (type === DealStatus.PENDING && buyerRecNum == null) {
		return (
			<div className="mt-3 mx-auto text-center" style={{ paddingTop: "4px" }}>
				<OpenModalButton
					buttonClass="btn-sm"
					buttonColor="link"
					onClick={() => toggleModal("new")}
					buttonText="Add New"
					iconClass="nc-icon nc-simple-add"
				/>
				<OpenModalButton
					buttonClass="btn-sm"
					buttonColor="primary"
					onClick={() => toggleModal("add")}
					buttonText="Select"
					icon={true}
					iconClass="nc-icon nc-tap-01"
				/>
				<ModalContent />
			</div>
		);
	}

	return (
		<div className="mt-3 mx-auto" style={{ paddingTop: "4px" }}>
			<OpenModalButton
				iconClass="nc-icon nc-paper"
				buttonColor="primary"
				buttonClass="btn-sm"
				onClick={() => toggleModal("edit")}
				buttonText="View"
			/>
			{type === DealStatus.PENDING && (
				<>
					<OpenModalButton
						buttonClass="btn btn-link-danger btn-sm"
						onClick={removeBuyer}
						iconClass="nc-icon nc-simple-remove"
						buttonText="Remove"
					/>
				</>
			)}
			<ModalContent />
		</div>
	);

	/**
	 * Renders the modal content.
	 * @returns {React.ReactElement} The modal content.
	 */
	function ModalContent() {
		return (
			<>
				<ChildrenModal modal={modalState.new} toggle={() => toggleModal("new")}>
					<ContactAddPage
						updateDeal={updateNewCustomer}
						isDeal={true}
						isBuyer={true}
					/>
				</ChildrenModal>
				<ChildrenModal
					modal={modalState.add}
					toggle={() => toggleModal("add")}
					modalTitle="Select Contact"
				>
					<CustomerListPage
						isDeal={true}
						toggle={() => toggleModal("add")}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
					/>
				</ChildrenModal>
				<ChildrenModal
					modal={modalState.edit}
					toggle={() => toggleModal("edit")}
					modalTitle="Edit Current Contact"
				>
					<CustomerDealDetails
						ID={buyerRecNum}
						editCustomer={editBuyer}
						editAddress={editBuyerAddress}
						editMailAddress={editBuyerMailAddress}
						editRecNum={editBuyerRecNum}
						toggle={() => toggleModal("edit")}
						cloneCustomer={false}
						isBuyer={true}
						type={type}
					/>
				</ChildrenModal>
			</>
		);
	}
};

export default BuyerModal;
