import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav } from "reactstrap";
import classnames from "classnames";
import NavTab from "./Tabs";

/**
 * NavTabsHolder component represents a container for managing multiple tabs and their content.
 *
 * @param {Object} props - The component's props
 * @param {string[]} props.tabs - An array of tab names
 * @param {React.Component[]} props.tabComponents - An array of React components corresponding to each tab
 * @param {number} [props.active=0] - The index of the initially active tab
 * @param {string} props.navClass - The CSS class for styling the navigation
 * @param {Function} props.getTabName - A callback function to get the name of the active tab
 */
function NavTabsHolder({
	tabs,
	tabComponents,
	active = 0,
	navClass,
	getTabName,
}) {
	const [activeTab, setActiveTab] = useState(active);

	// Toggles between different tabs
	const toggle = (tab, tabName) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
			if (getTabName) {
				getTabName(tabName);
			}
		}
	};

	// All the nav tabs
	const navTabs = tabs.map((tab, index) => (
		<NavTab
			id={index}
			key={index}
			tabName={tab}
			setActive={classnames({ active: activeTab === index })}
			onClick={() => toggle(index, tab)}
		/>
	));

	// Content for each tab
	const navTabContents = tabComponents.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));

	return (
		<div>
			<Nav className={navClass} pills role="tablist">
				<div className="nav-pill-bar px-3">{navTabs}</div>
			</Nav>
			<TabContent className="tab-space tab-subcategories" activeTab={activeTab}>
				{navTabContents}
			</TabContent>
		</div>
	);
}

// Define PropTypes for the NavTabsHolder component
NavTabsHolder.propTypes = {
	tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
	tabComponents: PropTypes.arrayOf(PropTypes.element).isRequired,
	active: PropTypes.number,
	navClass: PropTypes.string,
	getTabName: PropTypes.func,
};

export default NavTabsHolder;
