import React from "react";
import { useMultiImageData } from "./MultiImageContext";

// Component containing all the image files selected for upload
const MultiImageFiles = () => {
	// Multi image context
	const {
		imageKeys,
		imageSources,
		fileUploads,
		setImageKeys,
		setImageSources,
		setFileUploads,
	} = useMultiImageData();

	/**
	 * Removes an uploaded image from the container and updates appropriate states
	 * @param {string} fileName - The name of the file to be removed.
	 */
	const removeImage = (fileName) => {
		const modifiedImages = Object.keys(imageSources)
			.filter((key) => key !== fileName)
			.reduce((obj, key) => {
				obj[key] = imageSources[key];
				return obj;
			}, {});

		const modifiedFileUploads = Object.keys(fileUploads)
			.filter((key) => key !== fileName)
			.reduce((obj, key) => {
				obj[key] = fileUploads[key];
				return obj;
			}, {});

		const updatedImageKeys = imageKeys.filter((name) => name !== fileName);

		setImageSources(modifiedImages);
		setFileUploads(modifiedFileUploads);
		setImageKeys(updatedImageKeys);
	};

	/**
	 * Handler for deleting an image file from the container
	 * @param {string} fileName - The name of the file to be deleted.
	 */
	const handleDelete = (fileName) => {
		removeImage(fileName);
	};

	return (
		<>
			{Object.entries(imageSources).map((img, index) => (
				<div className="multi-image-container" key={img[0]}>
					<img src={img[1]} alt="fileUpload" />
					<div
						className="multi-image-delete"
						onClick={() => {
							handleDelete(img[0]);
						}}
					/>
				</div>
			))}
		</>
	);
};

export default MultiImageFiles;
