import React, { useState } from "react";
import { Input, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import SellerFeesModal from "../modals/sellerFees/SellerFeesModal";
import OpenTotalModal from "../OpenTotalModal";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const SellerFees = () => {
	const [openSellerModal, setOpenSellerModal] = useState(false);
	const { totalSellerFees } = useCurrentSaleStore(
		(state) => ({
			totalSellerFees: state.totalSellerFees,
		}),
		shallow
	);

	// Function to open the modal
	const toggle = () => {
		setOpenSellerModal(!openSellerModal);
	};

	// Returns the total field in the sale page with the modal to open up the fields to edit the total value
	return (
		<Row>
			{openSellerModal ? (
				<SellerFeesModal modal={openSellerModal} toggle={toggle} />
			) : null}
			<div className="col-6 px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Seller Fees"
					toggle={toggle}
				/>
			</div>
			<div className="col-6 pr-0  moneyIn pl-2">
				<span onClick={toggle}>
					<NumberFormat
						value={totalSellerFees}
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
						prefix={"$"}
						isNumericString={true}
						readOnly={true}
						customInput={Input}
						
					/>
				</span>
			</div>
		</Row>
	);
};

export default SellerFees;
