import React from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	Row,
	CardTitle,
	Form,
	CardText,
} from "reactstrap";
import { commissionSettingsEnum, DealStatus } from "constants/Constants";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import useCurrentDealStore from "stores/DealStore";
import { useFromUser } from "stores/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";

/**
 * CommissionSettings component to handle commission-related settings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.commissionSettings - Current commission settings.
 * @param {Function} props.editCommissionSettings - Function to edit commission settings.
 * @param {Function} props.editCommissionSettingsValue - Function to edit individual commission setting values.
 * @param {Boolean} props.isDeal - Flag indicating if it is a deal.
 * @returns {JSX.Element} The rendered component.
 */
const CommissionSettings = ({
	commissionSettings,
	editCommissionSettings,
	editCommissionSettingsValue,
	isDeal,
}) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Define commission settings options
	const commissionObj = [
		{
			name: "isDocFeeComm",
			dropdownValue: commissionSettings?.isDocFeeComm,
			dropdownLabel: "Doc Fee",
			onChange: (val) => editCommissionSettingsValue("isDocFeeComm", val),
		},
		{
			name: "isAftMktComm",
			dropdownValue: commissionSettings?.isAftMktComm,
			dropdownLabel: "Aftermarket",
			onChange: (val) => editCommissionSettingsValue("isAftMktComm", val),
		},
		{
			name: "isServConComm",
			dropdownValue: commissionSettings?.isServConComm,
			dropdownLabel: "Service Contract",
			onChange: (val) => editCommissionSettingsValue("isServConComm", val),
		},
		{
			name: "isGapComm",
			dropdownValue: commissionSettings?.isGapComm,
			dropdownLabel: "Gap",
			onChange: (val) => editCommissionSettingsValue("isGapComm", val),
		},
		{
			name: "isMiscComm",
			dropdownValue: commissionSettings?.isMiscComm,
			dropdownLabel: "Misc",
			onChange: (val) => editCommissionSettingsValue("isMiscComm", val),
		},
		{
			name: "isBankFeeComm",
			dropdownValue: commissionSettings?.isBankFeeComm,
			dropdownLabel: "Bank Fee",
			onChange: (val) => editCommissionSettingsValue("isBankFeeComm", val),
		},
		{
			name: "isReserveComm",
			dropdownValue: commissionSettings?.isReserveComm,
			dropdownLabel: "Reserve",
			onChange: (val) => editCommissionSettingsValue("isReserveComm", val),
		},
		{
			name: "isSmogComm",
			dropdownValue: commissionSettings?.isSmogComm,
			dropdownLabel: "Dealer Smog",
			onChange: (val) => editCommissionSettingsValue("isSmogComm", val),
		},
	];

	// Fetch default settings from the API
	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				editCommissionSettings(res.data.content.commissionSettings);
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Button to fetch and use default settings
	const UseDefaults = () => (
		<Button
			readOnly={type !== DealStatus.PENDING}
			color="primary"
			onClick={getData}
			size="sm"
		>
			<i className="nc-icon nc-check-2"></i> Use Defaults
		</Button>
	);

	// Generate commission settings components
	const commissionComponents = commissionObj.map((obj, index) => (
		<Card>
			<FormGroup key={index} className="col-12 pt-2">
				<CardTitle>
					<CardText tag="h5">{obj.dropdownLabel}</CardText>
				</CardTitle>
				<CardBody className="py-0">
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 0 || obj.dropdownValue > 3}
								onChange={() => obj.onChange(0)}
							/>
							{commissionSettingsEnum.NO_COMMISSION.label}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 1}
								onChange={() => obj.onChange(1)}
							/>
							{commissionSettingsEnum.FRONT_COMMISSION.label}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 2}
								onChange={() => obj.onChange(2)}
							/>
							{commissionSettingsEnum.BACK_COMMISSION.label}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 3}
								onChange={() => obj.onChange(3)}
							/>
							{commissionSettingsEnum.BOTH_COMMISION.label}
						</Label>
					</FormGroup>
				</CardBody>
			</FormGroup>
		</Card>
	));

	return (
		<div onKeyDown={formNavigationUtils}>
			<Form>
				<Row className="mx-0 pb-2">
					{isDeal && (
						<Col>
							<UseDefaults />
						</Col>
					)}
				</Row>
				<CardTitle tag="h3" className="text-center mb-3">
					Commission Settings
				</CardTitle>
				<Row className="mx-0 justify-content-center" style={{ gap: "12px" }}>
					{commissionComponents}
				</Row>
			</Form>
		</div>
	);
};

export default CommissionSettings;
