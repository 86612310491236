import React from "react";
import { Card, CardHeader, CardText, Col, Row } from "reactstrap";

/**
 * DetailPageHeader component to display a title and details in a card format.
 *
 * @param {Object} props - The component props.
 * @param {string} props.mainTitle - The main title to display.
 * @param {Array<Object>} props.subtitles - The array of subtitles to display. Each object should have `label` and `value` keys.
 * @returns {JSX.Element} The rendered component.
 */
const DetailPageHeader = ({ mainTitle, subtitles }) => (
	<Card className="card-plain">
		<CardHeader>
			{mainTitle && (
				<Row className="justify-content-center text-center">
					{/* <hr className="mx-auto w-75 noMobile" /> */}
					<Col xs="12">
						<CardText
							tag="h2"
							className="mb-0"
							// style={{ lineHeight: "1.4" }}
						>
							{mainTitle}
						</CardText>
					</Col>
				</Row>
			)}
			{subtitles &&
				subtitles.map(
					(subtitle, index) =>
						subtitle.value && (
							<Row className="justify-content-center text-center">
								<Col xs="12" key={index}>
									<CardText tag="h5" className="mt-0">
										<b>{subtitle.label}:</b> {subtitle.value}
									</CardText>
								</Col>
							</Row>
						)
				)}
		</CardHeader>
	</Card>
);

export default DetailPageHeader;
