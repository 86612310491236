import React from "react";
import Select from "react-select";
import {
	Label,
	Col,
	Row,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
} from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import LabelAndInput from "../../LabelAndInput";
import useCurrentSaleStore from "stores/SaleStore";
import InputElement, {
	IntegerInputElement,
	CurrencyInput,
} from "features/forms/components/InputElement";
import useCurrentRecapStore from "stores/RecapStore";
import NumberFormat from "react-number-format";

const CollisionForm = () => {
	// Sale Store
	const { collisionTotPremium } = useCurrentSaleStore(
		(state) => ({
			collisionTotPremium: state.collisionTotPremium,
			editCollisionTotPremium: state.editCollisionTotPremium,
		}),
		shallow
	);

	// Deal Store
	const {
		term,
		coRecNum,
		appNumber,
		type,
		bodyInjuryLimit,
		bodyInjuryPremium,
		collisionDeductible,
		collisionPremium,
		compDeductible,
		compPremium,
		medicalLimit,
		medicalPremium,
		otherLimit,
		otherPremium,
		propertyLimit,
		propertyPremium,
		editCoRecNum,
		editTerm,
		editAppNumber,
		editBodyInjuryLimit,
		editBodyInjuryPremium,
		editCollisionDeductible,
		editCollisionPremium,
		editCompDeductible,
		editCompPremium,
		editMedicalLimit,
		editMedicalPremium,
		editOtherLimit,
		editOtherPremium,
		editPropertyLimit,
		editPropertyPremium,
		insuranceCompanies,
	} = useCurrentDealStore(
		(state) => ({
			coRecNum: state?.collisionInsurance?.coRecNum,
			editCoRecNum: state?.editCoRecNum,
			appNumber: state?.collisionInsurance?.appNumber,
			type: state?.deal.type,
			bodyInjuryLimit: state?.collisionInsurance?.bodyInjuryLimit,
			bodyInjuryPremium: state?.collisionInsurance?.bodyInjuryPremium,
			collisionDeductible: state?.collisionInsurance?.collisionDeductible,
			collisionPremium: state?.collisionInsurance?.collisionPremium,
			compDeductible: state?.collisionInsurance?.compDeductible,
			compPremium: state?.collisionInsurance?.compPremium,
			medicalLimit: state?.collisionInsurance?.medicalLimit,
			medicalPremium: state?.collisionInsurance?.medicalPremium,
			otherLimit: state?.collisionInsurance?.otherLimit,
			otherPremium: state?.collisionInsurance?.otherPremium,
			propertyLimit: state?.collisionInsurance?.propertyLimit,
			propertyPremium: state?.collisionInsurance?.propertyPremium,
			term: state?.collisionInsurance?.term,
			editTerm: state?.editTerm,
			editAppNumber: state?.editAppNumber,
			editBodyInjuryLimit: state?.editBodyInjuryLimit,
			editBodyInjuryPremium: state?.editBodyInjuryPremium,
			editCollisionDeductible: state?.editCollisionDeductible,
			editCollisionPremium: state?.editCollisionPremium,
			editCompDeductible: state?.editCompDeductible,
			editCompPremium: state?.editCompPremium,
			editMedicalLimit: state?.editMedicalLimit,
			editMedicalPremium: state?.editMedicalPremium,
			editOtherLimit: state?.editOtherLimit,
			editOtherPremium: state?.editOtherPremium,
			editPropertyLimit: state?.editPropertyLimit,
			editPropertyPremium: state?.editPropertyPremium,
			insuranceCompanies: state.gapCompanies,
		}),
		shallow
	);

	const { collisionTotCost, editCollisionTotCost } = useCurrentRecapStore(
		(state) => state,
		shallow
	);

	const comList = insuranceCompanies.map((obj) => ({
		label: obj.name,
		value: obj.ID,
		gapCo: obj,
	}));

	const methods = useForm();

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Collision Insurance</CardTitle>
			</CardHeader>
			<CardBody>
				<FormProvider {...methods}>
					<Row className="align-items-center justify-content-center">
						<Col md={6}>
							<Label>Company</Label>
							<Select
								className="mb-3"
								isDisabled={type !== DealStatus.PENDING}
								value={{
									value: coRecNum,
									label: comList.find((e) => e.value === coRecNum)?.label,
								}}
								options={comList}
								onChange={(e) => {
									editCoRecNum(e.value);
								}}
							/>
						</Col>
						<Col md={6}>
							<InputElement
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={appNumber}
								onChange={(e) => {
									editAppNumber(e.target.value);
								}}
								name="appNumber"
								label="Application Number"
								type="text"
								wholeRow={true}
							/>
						</Col>
					</Row>
					<hr className="my-2 w-75 mx-auto" />
					<Row className="my-3">
						<Col
							md={6}
							className="d-flex flex-column mb-3"
							style={{ gap: "10px" }}
						>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={compPremium}
								onChange={editCompPremium}
								name="compPremium"
								label="Comp Premium"
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={collisionPremium}
								onChange={(e) => {
									editCollisionPremium(e);
								}}
								name="collisionPremium"
								label="Collision Premium"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={bodyInjuryPremium}
								onChange={(e) => {
									editBodyInjuryPremium(e);
								}}
								name="bodyInjuryPremium"
								label="Bodily Injury Premium"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={propertyPremium}
								onChange={(e) => {
									editPropertyPremium(e);
								}}
								name="propertyPremium"
								label="Property DMG Premium"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={medicalPremium}
								onChange={(e) => {
									editMedicalPremium(e);
								}}
								name="medicalPremium"
								label="Medical Premium"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={otherPremium}
								onChange={(e) => {
									editOtherPremium(e);
								}}
								name="otherPremium"
								label="Other Premium"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
						</Col>
						<Col md={6} className="d-flex flex-column" style={{ gap: "10px" }}>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={compDeductible}
								onChange={(e) => {
									editCompDeductible(e);
								}}
								name="compDeductible"
								label="Comp Deductible"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={collisionDeductible}
								onChange={(e) => {
									editCollisionDeductible(e);
								}}
								name="collisionDeductible"
								label="Collision Deductible"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={bodyInjuryLimit}
								onChange={(e) => {
									editBodyInjuryLimit(e);
								}}
								name="bodyInjuryLimit"
								label="Bodily Injury Limit"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={propertyLimit}
								onChange={(e) => {
									editPropertyLimit(e);
								}}
								name="propertyLimit"
								label="Property DMG Limit"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={medicalLimit}
								onChange={(e) => {
									editMedicalLimit(e);
								}}
								name="medicalLimit"
								label="Medical Limit"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
							<LabelAndInput
								{...methods}
								disabled={type !== DealStatus.PENDING}
								value={otherLimit}
								onChange={(e) => {
									editOtherLimit(e);
								}}
								name="otherLimit"
								label="Other Limit"
								type="text"
								wholeRow={true}
								buttonLabel={true}
								btnClass="btn btn-md btn-primary disabled w-100"
							/>
						</Col>
					</Row>
					<hr className="my-2 w-75 mx-auto" />
					<Row className="mt-3">
						<Col md={6}>
							<IntegerInputElement
								disabled={type !== DealStatus.PENDING}
								value={term}
								onChange={(e) => {
									editTerm(e);
								}}
								name="term"
								label="Term in Months"
								type="number"
								wholeRow={true}
							/>
						</Col>
						<Col md={6}>
							<CurrencyInput
								disabled={type !== DealStatus.PENDING}
								value={collisionTotCost}
								onChange={editCollisionTotCost}
								label="Cost"
								disableValidation
								wholeRow={true}
							/>
						</Col>
					</Row>
					<Row>
						<Col className="my-2 text-center">
							<h6>Total Collision Premium</h6>
							<hr className="my-2 w-75 mx-auto" />
							<h5 className="text-success">
								<NumberFormat
									value={collisionTotPremium}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									displayType="text"
								/>
							</h5>
						</Col>
					</Row>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default CollisionForm;
