import React from "react";
import { Form } from "reactstrap";

import useCurrentLoginStore from "../../../../../../stores/LoginStore";
import InputElement from "../../../../../forms/components/InputElement";
import InputWrapper from "../../../../../forms/components/InputWrapper";

import { stateOptions } from "../../../../../../constants/Constants";
import { PhoneInputElement } from "../../../../../forms/components/InputElement";
import { SelectorElement } from "../../../../../forms/components/InputElement";

import shallow from "zustand/shallow";
import dayjs from "dayjs";

const CompanyInformation = () => {
	const {
		companyName,
		dealerWebsite,
		locationPhone,
		locationCellphone,
		locationFax,
		locationAddress,
		locationUnit,
		locationCity,
		locationState,
		locationZipCode,
		locationCountry,
		locationEmail,
		locationExpirationDate,
		locationCloudUserCount,
		taxID,
		dealerNum,
		editCompanyName,
		editDealerWebsite,
		editLocationPhone,
		editLocationCellphone,
		editLocationFax,
		editLocationAddress,
		editLocationUnit,
		editLocationCity,
		editLocationState,
		editLocationZipCode,
		editLocationCountry,
		editLocationEmail,
		editLocationExpirationDate,
		editLocationCloudUserCount,
		editTaxID,
		editDealerNum,
	} = useCurrentLoginStore((state) => state, shallow);

	return (
		<>
			<Form>
				<InputWrapper
					formTitle="Dealership Info"
					inputComponents={[
						<InputElement
							colSize="col-sm-4"
							readOnly={true}
							value={companyName}
							onChange={(e) => editCompanyName(e.target.value)}
							label="Company"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-2"
							readOnly={true}
							value={dealerNum}
							onChange={(e) => editDealerNum(e.target.value)}
							label="Dealer #"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-2"
							readOnly={true}
							value={taxID}
							onChange={(e) => editTaxID(e.target.value)}
							label="Tax ID"
							disableValidation={true}
						/>,
						<InputElement
							type="date"
							readOnly={true}
							value={dayjs(locationExpirationDate).format("YYYY-MM-DD")}
							onChange={(e) => editLocationExpirationDate(e.target.value)}
							label="Expiration Date"
							disableValidation={true}
						/>,
						<PhoneInputElement
							readOnly={true}
							value={locationPhone}
							onChange={editLocationPhone}
							label="Business Phone"
							disableValidation={true}
						/>,
						<PhoneInputElement
							readOnly={true}
							value={locationCellphone}
							onChange={editLocationCellphone}
							label="Business Cell Phone"
							disableValidation={true}
						/>,
						<PhoneInputElement
							readOnly={true}
							value={locationFax}
							onChange={editLocationFax}
							label="Business Fax"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-4"
							readOnly={true}
							value={dealerWebsite}
							onChange={(e) => editDealerWebsite(e.target.value)}
							label="Website URL"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-4"
							readOnly={true}
							value={locationEmail}
							onChange={(e) => editLocationEmail(e.target.value)}
							label="Email"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-2"
							readOnly={true}
							value={locationCloudUserCount}
							onChange={(e) => editLocationCloudUserCount(e.target.value)}
							label="# of Users"
							disableValidation={true}
						/>,
						<div className="col-12">
							<br />
							<br />
						</div>,
						<InputElement
							readOnly={true}
							value={locationAddress}
							onChange={(e) => editLocationAddress(e.target.value)}
							label="Street Address"
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-2"
							readOnly={true}
							value={locationUnit}
							label="Unit/Suite/Bld"
							onChange={(e) => editLocationUnit(e.target.value)}
							disableValidation={true}
						/>,
						<InputElement
							readOnly={true}
							value={locationCity}
							onChange={(e) => editLocationCity(e.target.value)}
							label="City"
							disableValidation={true}
						/>,
						<SelectorElement
							colSize="col-sm-2"
							readOnly={true}
							value={locationState}
							label="State"
							onChange={(e) => editLocationState(e.target.value)}
							options={stateOptions}
							disableValidation={true}
						/>,
						<InputElement
							colSize="col-sm-2"
							readOnly={true}
							value={locationZipCode}
							onChange={(e) => {
								editLocationZipCode(e.target.value);
							}}
							label="Zip"
							disableValidation={true}
						/>,
						<InputElement
							readOnly={true}
							value={locationCountry}
							label="Country"
							onChange={(e) => editLocationCountry(e.target.value)}
							disableValidation={true}
						/>,
					]}
				/>
			</Form>
		</>
	);
};

export default CompanyInformation;
