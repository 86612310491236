import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	Label,
	Table,
	Row,
	Col,
} from "reactstrap";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "../../../utils/alertUtils";
import ChildrenModal from "../../../components/modals/ChildrenModal";
import formNavigationUtils from "utils/formNavigationUtils";
import InputElement from "./InputElement";
import { useFormContext } from "react-hook-form";
import InputWrapper from "./InputWrapper";
import {
	deleteDealerTempInfo,
	getDealerTempList,
	getDealershipInfo,
	saveDealerTempInfo,
} from "../../../api/SettingsAPI";

const MultiDealer = () => {
	const [list, setList] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);

	const [showAdd, setShowAdd] = useState(false);
	const toggleAdd = () => setShowAdd(!showAdd);
	//const [toggleList, setToggleList] = useState(false);

	// Get validation context
	const methods = useFormContext();

	const [recNum, setRecNum] = useState(null);
	const [city, setCity] = useState("");
	const [dealerNum, setDealerNum] = useState("");
	const [name, setName] = useState("");
	const [phone1, setPhone1] = useState("");
	const [phone2, setPhone2] = useState("");
	const [state, setState] = useState("");
	const [street1, setStreet1] = useState("");
	const [street2, setStreet2] = useState("");
	const [zip, setZip] = useState("");
	const [dealerID, setDealerID] = useState("3534");
	const [locationID, setLocationID] = useState("4757");

	const [dealerName, setDealerName] = useState("");

	const clearFields = () => {
		setRecNum(null);
		setCity("");
		setDealerNum("");
		setName("");
		setPhone1("");
		setPhone2("");
		setState("");
		setStreet1("");
		setStreet2("");
		setZip("");
		setDealerID(dealerID);
		setLocationID(locationID);
	};

	const headers = [
		"recNum",
		"name",
		"dealerNum",
		"state",
		"zip",
		"city",
		"street1",
		"street2",
		"phone1",
		"phone2",
	];
	const newForm = () => {
		clearFields();
		toggleAdd();
	};
	const selectForm = (item) => {
		setRecNum(item.recNum);
		setCity(item.city);
		setDealerNum(item.dealerNum);
		setName(item.name);
		setPhone1(item.phone1);
		setPhone2(item.phone2);
		setState(item.state);
		setStreet1(item.street1);
		setStreet2(item.street2);
		setZip(item.zip);
		setDealerID(item.dealerID);
		setLocationID(item.locationID);
		toggleAdd();
	};
	const saveData = async () => {
		const body = {
			recNum: recNum,
			city: city,
			dealerNum: dealerNum,
			name: name,
			phone1: phone1,
			phone2: phone2,
			state: state,
			street1: street1,
			street2: street2,
			zip: zip,
			dealerID: dealerID,
			locationID: locationID,
		};
		console.log(body);

		//		saveDealerTempInfo(body);

		try {
			await saveDealerTempInfo(body);
			// After the delete operation completes, refetch the list
			updateTheList();
		} catch (err) {
			// Handle error (e.g., show an error message)
			console.error("Failed to delete item or refresh list", err);
		}

		//setToggleList(true);
		toggleAdd();
	};
	// const deleteData = (item) => {
	// 	deleteDealerTempInfo(item.recNum);
	// 	setToggleList(true);
	// };
	const deleteData = async (item) => {
		try {
			await deleteDealerTempInfo(item.recNum);
			// After the delete operation completes, refetch the list
			const res = await getDealerTempList(locationID);
			setList(res.data); // Update the list with the new data
		} catch (err) {
			// Handle error (e.g., show an error message)
			console.error("Failed to delete item or refresh list", err);
		}
	};
	const getDealership = async () => {
		console.log(locationID);
		getDealershipInfo(locationID).then(
			(res) => {
				setDealerID(res.data.dealerID);
				setDealerName(res.data.name + " (" + res.data.dealerNum + ") ");
				updateTheList();
				//setToggleList(true);
			},
			(err) => {}
		);
	};

	const updateTheList = async () => {
		getDealerTempList(locationID).then(
			(res) => {
				console.log(res);
				setList(res.data);
				setIsLoaded(true);
			},
			(err) => {
				setIsLoaded(true);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	useEffect(() => {
		console.log(dealerID);
		console.log(locationID);

		getDealerTempList(locationID).then(
			(res) => {
				console.log(res);
				setList(res.data);
				setIsLoaded(true);
			},
			(err) => {
				setIsLoaded(true);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, []);

	return (
		isLoaded && (
			<div>
				<ChildrenModal
					modal={showAdd}
					toggle={toggleAdd}
					modalTitle={"Add/Edit Dealer"}
				>
					<Form onKeyDown={formNavigationUtils}>
						<InputWrapper
							formTitle={"Dealer Information"}
							inputComponents={[
								<InputElement
									{...methods}
									value={recNum} //{{ id } ? "New" : { id }}
									name="id"
									label="ID"
									type="text"
									disableValidation
									disabled
								/>,
								<InputElement
									{...methods}
									value={name}
									onChange={(e) => {
										setName(e.target.value);
									}}
									name="name"
									label="Company Name"
									type="text"
									disableValidation
								/>,
								<InputElement
									value={dealerNum}
									onChange={(e) => {
										setDealerNum(e.target.value);
									}}
									name="dealerNum"
									label="Dealer Number"
									type="text"
									disableValidation
								/>,
								// <ZipInputElement
								// 	value={zip}
								// 	onChange={setZip}
								// 	//									openZipModal={handleCurrentZipChange}
								// 	name="zipCode"
								// 	label="Zip Code"
								// 	disableValidation
								// />,

								<InputElement
									value={zip}
									onChange={(e) => {
										setZip(e.target.value);
									}}
									name="zipCode"
									label="Zip Code"
									type="text"
									disableValidation
								/>,

								<InputElement
									value={street1}
									onChange={(e) => {
										setStreet1(e.target.value);
									}}
									name="street1"
									label="Street Address"
									type="text"
									disableValidation
								/>,
								<InputElement
									value={street2}
									onChange={(e) => {
										setStreet2(e.target.value);
									}}
									name="street2"
									label="Apt/Unit/Bld"
									type="text"
									disableValidation
								/>,

								<InputElement
									value={state}
									onChange={(e) => {
										setState(e.target.value);
									}}
									name="state"
									label="State"
									type="text"
									disableValidation
								/>,
								<InputElement
									value={city}
									onChange={(e) => {
										setCity(e.target.value);
									}}
									name="city"
									label="City"
									type="text"
									disableValidation
								/>,

								<InputElement
									value={phone1}
									onChange={(e) => {
										setPhone1(e.target.value);
									}}
									name="phone1"
									label="Phone"
									type="text"
									disableValidation
								/>,
								<InputElement
									value={phone2}
									onChange={(e) => {
										setPhone2(e.target.value);
									}}
									name="phone2"
									label="Cell Phone"
									type="text"
									disableValidation
								/>,
							]}
							buttons={
								<div className="w-100 text-center">
									<Button
										color="primary"
										onClick={() => {
											saveData();
										}}
									>
										<i className="nc-icon nc-simple-add" /> Save
									</Button>
								</div>
							}
						/>
					</Form>
				</ChildrenModal>
				{/* <ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={addressData}
						setAddress={setAddressData}
						isDeal={false}
						isBuyer={false}
					/>
				</ChildrenModal> */}

				<Card>
					<CardHeader></CardHeader>
					<CardTitle tag="h3">Multi Delers list</CardTitle>

					<CardBody className="pb-4">
						<Col>
							<Table>
								<Row>
									<Col>
										<Label>Location ID</Label>
										<input
											type="text"
											className="form-control  "
											id="locationID"
											placeholder="Location ID"
											value={locationID}
											onChange={(e) => setLocationID(e.target.value)}
										/>
									</Col>
									<Col>
										<Label>Dealer ID</Label>
										<input
											type="text"
											className="form-control  "
											id="dealerID"
											placeholder="Dealer ID"
											value={dealerID}
											//onChange={(e) => setDealerID(e.target.value)}
											disabled
										/>
									</Col>
									<Col></Col>
									<Col></Col>
									<Col></Col>
									<Col></Col>
								</Row>
								<Row>
									<Col>
										<Button
											className="btn btn-primary btn-md"
											onClick={() => getDealership()}
										>
											Search
										</Button>
									</Col>
									<Col></Col>
									<Col>
										<Button
											className="btn btn-primary btn-md"
											onClick={() => newForm()}
										>
											<i className="nc-icon nc-simple-add" />
											Add Dealer
										</Button>
									</Col>
									<Col></Col>
								</Row>
								<Row>
									<Col>
										<Label> {dealerName}</Label>
									</Col>
								</Row>
							</Table>
						</Col>
					</CardBody>
				</Card>
				{list.length > 0 && (
					<Card>
						<CardHeader></CardHeader>
						<CardBody className="pb-4">
							<Table>
								<thead>
									<tr>
										{headers.map((header, i) => (
											<th key={i}>{header}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{list.map((item, index) => (
										<tr key={item.recNum}>
											<td>{item.recNum}</td>
											<td>{item.name}</td>
											<td>{item.dealerNum}</td>
											<td>{item.state}</td>
											<td>{item.zip}</td>
											<td>{item.city}</td>
											<td>{item.street1}</td>
											<td>{item.street2}</td>
											<td>{item.phone1}</td>
											<td>{item.phone2}</td>

											<td className="d-flex justify-content-center">
												<Button
													className="btn-md"
													onClick={() => selectForm(item)}
												>
													Edit
												</Button>
												<Button
													color="danger"
													onClick={() => {
														deleteData(item);
													}}
												>
													X Delete
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</CardBody>
					</Card>
				)}
			</div>
		)
	);
};

export default MultiDealer;
