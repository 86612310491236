import React, { useState, useEffect } from "react";
import { Button, Form } from "reactstrap";

import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import useCurrentCustomerStore from "../../../../../stores/CustomerStore";
import useCurrentDealStore from "../../../../../stores/DealStore";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import InputElement from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";
import ZipCityTable from "../../../../../components/tables/ZipCityTable";

import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { saveAddressHistory } from "../../../../../api/CustomerAPI";
import { stateOptions, DealStatus } from "../../../../../constants/Constants";
import { useFromUser } from "../../../../../stores/LocalStorageHelper";
import {
	SelectorElement,
	ZipInputElement,
} from "features/forms/components/InputElement";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../../../../utils/alertUtils";

import { useFormContext } from "react-hook-form";

import shallow from "zustand/shallow";

const AddressHistoryForm = ({
	formTitle,
	currentIndex,
	setCurrentIndex,
	toggle,
}) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Customer store
	const { custRecNum, addressHist, editAddressHist } = useCurrentCustomerStore(
		(state) => ({
			custRecNum: state.ID,
			addressHist: state.addressHist,
			editAddressHist: state.editAddressHist,
		}),
		shallow
	);

	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});
	const [street, setStreet] = useState("");
	const [unit, setUnit] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");
	const [county, setCounty] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (inputZip) => {
		setAddressData({
			zip: inputZip,
			address: {
				city,
				state,
				county,
			},
		});

		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setCity(address.city);
		setState(address.state);
		setCounty(address.county);
	};

	// Sets customer store address fields to empty
	const clearFields = () => {
		setStreet("");
		setUnit("");
		setCity("");
		setState("");
		setZip("");
		setCounty("");
		setFromDate("");
		setToDate("");
		setCurrentIndex(addressHist.length);
	};

	// Returns an updated store object with the new address
	const addToAddressList = (address, id) => {
		let obj = addressHist;
		address.ID = id;
		obj[currentIndex] = address;

		return obj;
	};

	// Address save object
	const newAddress = {
		ID: addressHist[currentIndex]?.ID || null,
		street,
		unit,
		city,
		state,
		zip,
		county,
		fromDate,
		toDate,
		custRecNum,
		isCurrent: false,
		isMailingAddress: false,
		dealerID,
		locationID,
	};

	// Calls api to add the address to database or update if it exists
	// If updating an sold customer's address, just add the new obj to the store address history list
	const addAddress = () => {
		saveAddressHistory(newAddress).then(
			(response) => {
				if (currentIndex != null) {
					editAddressHist(
						addToAddressList(newAddress, response.data.content.ID)
					);
				}
				clearFields();
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Get validation context
	const methods = useFormContext();

	useEffect(() => {
		console.log(currentIndex);
		setStreet(addressHist[currentIndex]?.street || "");
		setUnit(addressHist[currentIndex]?.unit || "");
		setCity(addressHist[currentIndex]?.city || "");
		setState(addressHist[currentIndex]?.state || "");
		setZip(addressHist[currentIndex]?.zip || "");
		setCounty(addressHist[currentIndex]?.county || "");
		setFromDate(addressHist[currentIndex]?.fromDate || "");
		setToDate(addressHist[currentIndex]?.toDate || "");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle={formTitle}
				inputComponents={[
					<InputElement
						{...methods}
						value={fromDate}
						onChange={(e) => setFromDate(e.target.value)}
						name="addHistoryFromDate"
						label="Start Date"
						type="date"
					/>,
					<InputElement
						{...methods}
						value={toDate}
						onChange={(e) => setToDate(e.target.value)}
						name="addHistoryToDate"
						label="End Date"
						type="date"
					/>,
					<InputElement
						{...methods}
						value={street}
						onChange={(e) => setStreet(e.target.value)}
						name="addHistoryStreet"
						label="Street Address"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={unit}
						onChange={(e) => setUnit(e.target.value)}
						name="addHistoryUnit"
						label="Apt/Unit/Bld"
						type="text"
					/>,
					<InputElement
						{...methods}
						value={city}
						onChange={(e) => setCity(e.target.value)}
						name="addHistoryCity"
						label="City"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						name="addHistoryState"
						label="State"
						value={state}
						onChange={(e) => setState(e.target.value)}
						options={stateOptions}
					/>,
					<ZipInputElement
						{...methods}
						value={zip}
						onChange={setZip}
						openZipModal={handleZipChange}
						name="addHistoryZip"
						label="Zip"
					/>,
					<InputElement
						{...methods}
						value={county}
						onChange={(e) => setCounty(e.target.value)}
						name="addHistoryCounty"
						label="County"
						type="text"
					/>,
				]}
				buttons={
					<div className="d-flex justify-content-center">
						<Button
							readOnly={type !== DealStatus.PENDING}
							className="mr-1"
							color="primary"
							onClick={methods.handleSubmit(addAddress)}
						>
							Save
						</Button>
					</div>
				}
			/>
			<ChildrenModal
				modal={cityModal}
				toggle={toggleCityModal}
				modalTitle="Cities"
			>
				<ZipCityTable
					data={addressData}
					toggle={toggleCityModal}
					address={{
						city,
						state,
						zip,
					}}
					setAddress={setAddressFields}
				/>
			</ChildrenModal>
		</Form>
	);
};

export default AddressHistoryForm;
