import React from "react";
import { Spinner } from "reactstrap";

const LoadingSpinner = () => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{ height: "50vh" }}
		>
			<Spinner
				style={{ width: "3rem", height: "3rem", marginTop: "2rem" }}
				color="primary"
			/>
		</div>
	);
};

export default LoadingSpinner;
