import React from "react";
import useCurrentVehicleStore from "stores/InventoryStore";
import useRoute from "hooks/useRoute";
import {
	checkValidateDelete,
	deleteVehicle,
	removeVehicleFromDeal,
} from "api/InventoryAPI";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/Constants";
import {
	successAlertCustom,
	confirmDeleteMessage,
	deleteFailAlertWithText,
	twoOptionsAlert,
} from "utils/alertUtils";
import shallow from "zustand/shallow";
import { Button } from "reactstrap";

export const InventoryDeleteButton = ({ redirect = false }) => {
	const history = useHistory();
	const newRoute = useRoute(PATHS.INVENTORY_LIST);

	// Vehicle store
	const { inventoryID } = useCurrentVehicleStore(
		(state) => ({
			inventoryID: state.inventoryID,
		}),
		shallow
	);
	/*
show an option message when vehicle is in pending deal or reconditioning 
*/
	const DeleteVehicle = () => {
		let answer = "Cancel";
		confirmDeleteMessage("Yes, Delete it").then((res) => {
			answer = res;
			if (answer === "Yes, Delete it") {
				checkValidateDelete(inventoryID).then(
					(res) => {
						console.log(res);
						const r = res.data.content;
						const dealID = r.dealId;
						if (dealID.length > 0) {
							// Handle case where vehicle is in a pending deal
							twoOptionsAlert(
								"This vehicle is associated with a pending deal. Do you still want to delete it?",
								"Yes, Delete it",
								"Cancel"
							).then((res) => {
								if (res === "Yes, Delete it") {
									removeVehicleFromDeal(inventoryID, dealID).then(
										(res) => {
											if (redirect) {
												history.push(newRoute);
											}
											successAlertCustom(
												"Your vehicle's status has been changed to deleted."
											);
										},
										(error) => {
											if (!error.isGeneralError) {
												console.log(error);
												deleteFailAlertWithText(error?.response?.data?.message);
											}
										}
									);
								}
							});
						} else if (r.isRecon) {
							// Handle case where vehicle has been reconditioned
							twoOptionsAlert(
								"This vehicle has been reconditioned. Do you still want to delete it?",
								"Yes, Delete it",
								"Cancel"
							).then((res) => {
								if (res === "Yes, Delete it") {
									deleteVehicle(inventoryID).then(
										(res) => {
											if (redirect) {
												history.push(newRoute);
											}
											successAlertCustom(
												"Your vehicle's status has been changed to deleted."
											);
										},
										(error) => {
											if (!error.isGeneralError) {
												console.log(error);
												deleteFailAlertWithText(error?.response?.data?.message);
											}
										}
									);
								}
							});
						} else {
							// Handle case where vehicle is neither in a pending deal nor reconditioned
							deleteVehicle(inventoryID).then(
								(response) => {
									if (redirect) {
										history.push(newRoute);
									}
									successAlertCustom(
										"Your vehicle's status has been changed to deleted."
									);
								},
								(error) => {
									if (!error.isGeneralError) {
										console.log(error);
										deleteFailAlertWithText(error?.response?.data?.message);
									}
								}
							);
						}
					},
					(error) => {
						if (!error.isGeneralError) {
							console.log(error);

							deleteFailAlertWithText(error?.response?.data?.message);
						}
					}
				);
			}
		});
	};

	return (
		<Button
			type="submit"
			className="btn-md btn btn-danger"
			onClick={() => DeleteVehicle()}
		>
			<i className="nc-icon nc-simple-remove"></i> Delete
		</Button>
	);
};

export default InventoryDeleteButton;
