import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import FileUploader from "components/fileUploader/FileUploader";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { uploadDealerAttachments } from "api/AttachmentsAPI";
import { ATTACHMENT_CATEGORY } from "constants/Constants";
import InputElement, {
	SelectorElement,
} from "features/forms/components/InputElement";
import { lazy, mixed, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";

/**
 * Uploads an attachment to the Ftp server
 *
 * @param {number} relatedID Required. ID of deal, inventory, or customer
 * @param {String} type Required. One of: deal, inventory, customer
 * @param {React.SetStateAction} setRefreshAttachments Required. Sets state to refresh table data
 * @param {Function} toggle Required. Toggles the upload modal to close
 */
const UploadAttachmentButton = ({
	relatedID,
	type,
	setRefreshAttachments,
	toggle,
}) => {
	// Dealer info from local storage
	const dealerInfo = JSON.parse(sessionStorage.getItem("user"));
	const dealerID = dealerInfo.dealerID;
	const locationID = dealerInfo.locationID;

	const acceptableFileTypes = [
		".png",
		".jpeg",
		".jpg",
		".txt",
		".csv",
		".pdf",
		".xlsx",
		".xls",
		".docx",
		".doc",
	];

	// Local states
	const [uploads, setUploads] = useState([]);
	const [category, setCategory] = useState(null);
	const [uploadErrors, setUploadErrors] = useState([]);

	// Calls api to upload every attachment
	// If at least one attachment was successfully uploaded, refresh the table and close modal
	// If at least one upload error was returned, refresh table but don't close modal
	const uploadAttachment = () => {
		const formData = new FormData();

		// Add each file to the form data and modify file info object
		const fileInfo = uploads.map((obj) => {
			formData.append("files", obj);
			return {
				fileName: obj.name,
				description: "",
				category,
				dealerID,
				locationID,
				type,
				relatedID,
			};
		});

		formData.append(
			"fileInfo",
			new Blob([JSON.stringify(fileInfo)], {
				type: "application/json",
			})
		);

		uploadDealerAttachments(
			dealerID,
			locationID,
			type,
			relatedID,
			formData
		).then(
			(res) => handleResponse(res.data.content, res.data.message),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Append the db attachments objects to the table, if any
	// If no errors exist for an upload, close modal. Else, leave
	// modal open and show errors
	const handleResponse = (data, msg) => {
		const errors = data.errors;

		setRefreshAttachments((prev) => !prev);
		setUploadErrors(errors);
		saveSuccessAlert(msg);

		if (errors.length < 1) {
			toggle();
		}
	};

	// Returns UI for users to add info about each file or remove a file from upload
	const fileInfo = () =>
		uploads.map((obj, i) => (
			<div key={obj.name}>
				<Row className="align-items-center mb-2 attachmentBtnCol">
					<InputElement
						{...methods}
						readOnly
						value={obj.name}
						name={`uploadFile_${i}`}
						labelClass="d-none"
						colSize="col-sm-auto p-0 col-auto mb-0"
					/>
					<i
						className="fa fa-close red-close-icon"
						onClick={() => {
							setUploads((prev) => {
								let modifiedUploads = [...prev];
								delete modifiedUploads[i];
								return modifiedUploads;
							});
						}}
					/>
				</Row>
			</div>
		));

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};

		Object.keys(obj).map((key) => {
			if (key.includes("uploadFile")) {
				return (schemaObj = {
					...schemaObj,
					[key]: string().matches(/^(?:[a-zA-Z\d]+[-_.\s]{0,1})+\.[a-zA-Z]+/, {
						message:
							"Invalid File name. Cannot contain more than 1 consecutive - _ . or space.",
					}),
				});
			} else if (key === "uploadCategory") {
				return (schemaObj = {
					...schemaObj,
					[key]: string().required("Required"),
				});
			}

			return (schemaObj = { ...schemaObj, [key]: mixed() });
		});

		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = lazy((obj) => object(getSchemaObj(obj)));

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<div className="d-flex flex-wrap justify-content-end align-items-start">
					<Col md="6" className="my-3">
						<SelectorElement
							{...methods}
							colSize="col-sm-auto col-md-6 m-0 float-right"
							value={category}
							labelClass="d-none"
							onChange={(e) => setCategory(e.target.value)}
							name={`uploadCategory`}
							options={ATTACHMENT_CATEGORY}
						/>
					</Col>
					<Col
						md="6"
						className="d-flex attachmentBtnCol align-items-center my-3"
					>
						<FileUploader
							maxFileSize={50}
							setUploads={setUploads}
							accept={acceptableFileTypes.join(",")}
							acceptArray={acceptableFileTypes}
						/>

						<Button
							readOnly={uploads.length === 0}
							onClick={methods.handleSubmit(uploadAttachment)}
							disabled={uploads.length === 0}
							color="primary"
						>
							<i className="fa fa-upload font-size-md" /> Upload
						</Button>
					</Col>
				</div>
				{uploads.length > 0 && (
					<Col>
						<hr className="my-2" />
						<Row className="my-2 w-100 attachmentBtnCol">{fileInfo()}</Row>
					</Col>
				)}
			</FormProvider>

			{uploadErrors.length > 0 && (
				<div className="col-12 file-uploader-list">
					<p className="file-uploader-list-title">Failed to save some files:</p>
					<ul>
						{uploadErrors.map((err, i) => (
							<li key={`${err[0]}_${i}`}>{err}</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default UploadAttachmentButton;
