import React from "react";
import { CardBody, Form, FormGroup, Label, Row } from "reactstrap";

import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentSaleStore from "stores/SaleStore";
import InputElement, {
	CheckBoxElement,
} from "features/forms/components/InputElement";

import { LoanPeriod } from "constants/Constants";
import { SimpleSelectorElement } from "features/forms/components/InputElement";
import shallow from "zustand/shallow";
import { useFormContext, FormProvider } from "react-hook-form";
import FinanceAPRInput from "features/deal/subFeatures/finance/FinanceAPRInput";
import FinanceDaysToPayInput from "features/deal/subFeatures/finance/FinanceDaysToPayInput";
import FinanceTermInput from "features/deal/subFeatures/finance/FinanceTermInput";

const TemplatePaymentOption = ({
	templateName,
	setTemplateName,
	activeTab,
}) => {
	const {
		loanPeriod,
		editLoanPeriod,
		saleType,
		editSaleType,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			editLoanPeriod: state.editLoanPeriod,
			saleType: state.saleType,
			editSaleType: state.editSaleType,
		}),
		shallow
	);

	//For now we only use 2 type of sales
	//later SaleType should import from constants
	const SaleType = [
		{ label: "RETAIL", value: 0 },
		{ label: "WHOLESALE", value: 1 },
		{ label: "BUSINESS", value: 2 },
	];

	// Get validation context
	const methods = useFormContext();

	return (
		<FormProvider {...methods}>
			<Form onKeyDown={formNavigationUtils}>
				<CardBody>
					{activeTab === "1" && (
						<Row className="ml-0" lg="12">
							<InputElement
								{...methods}
								colSize="col-md-6"
								name="templateName"
								value={templateName}
								type="text"
								onChange={(e) => setTemplateName(e.target.value)}
								onFocus={(e) => e.target.select()}
								label="Template Name"
							/>
							<SimpleSelectorElement
								name="templateSaleType"
								disable={true}
								colSize="col-md-6"
								label="Type of Deal"
								value={saleType}
								onChange={(e) => editSaleType(e.target.value)}
								options={SaleType}
							/>
							<SimpleSelectorElement
								name="templateLoanPeriod"
								disable={true}
								colSize="col-md-6"
								label="Loan Period"
								value={loanPeriod}
								onChange={(e) => editLoanPeriod(e.target.value)}
								options={LoanPeriod}
							/>
							<FormGroup className="col-md-3">
								<Label>Term</Label>
								<FinanceTermInput />
							</FormGroup>
							<FormGroup className="col-md-3">
								<FinanceAPRInput />
							</FormGroup>
							<FormGroup className="col-md-4">
								<Label>DaysToPay</Label>
								<FinanceDaysToPayInput />
							</FormGroup>
						</Row>
					)}
					{activeTab === "2" && (
						<Row className="ml-0" lg="12">
							<InputElement
								{...methods}
								colSize="col-md-6"
								name="templateName"
								value={templateName}
								type="text"
								onChange={(e) => setTemplateName(e.target.value)}
								onFocus={(e) => e.target.select()}
								label="Selling Plan Name"
							/>
						</Row>
					)}
				</CardBody>
			</Form>
		</FormProvider>
	);
};

export default TemplatePaymentOption;
