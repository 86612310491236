import React from "react";
import PropTypes from "prop-types";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import useSetDeal from "../hooks/useSetDeal";
import useBuildDeal from "../hooks/useBuildDeal";

/**
 * AutoSaveWrapper Component
 * @param {object} props - React props
 * @param {React.ReactNode} props.children - Child components
 */

const AutoSaveWrapper = ({ children }) => {
	const setDeal = useSetDeal();
	const getDeal = useBuildDeal();

	const save = (deal) => {
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				saveSuccessAlert();
			},
			(err) => {
				showApiError(
					err,
					saveFailAlert,
					"Deal failed to save... Accurate deal information will not be populated in text or email. Please add a buyer to save a valid deal."
				);
			}
		);
	};

	return (
		<div
			onClick={() => {
				const deal = getDeal();
				save(deal);
			}}
		>
			{children}
		</div>
	);
};

AutoSaveWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AutoSaveWrapper;
