import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody, CardHeader } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import ReviewRow from "./ReviewRow";

/**
 * ReviewCustomer component to display buyer and co-buyer information.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewCustomer component
 */
const ReviewCustomer = () => {
	const {
		buyerFirstName,
		buyerLastName,
		buyerStreet,
		buyerCity,
		buyerState,
		buyerZip,
		buyerPhone,
		coBuyerFirstName,
		coBuyerLastName,
		coBuyerPhone,
	} = useCurrentDealStore(
		(state) => ({
			buyerFirstName: state?.buyer?.firstName || "",
			buyerLastName: state?.buyer?.lastName || "",
			buyerStreet: state?.buyerAddress?.street || "",
			buyerCity: state?.buyerAddress?.city || "",
			buyerState: state?.buyerAddress?.state || "",
			buyerZip: state?.buyerAddress?.zip || "",
			buyerPhone: state?.buyer?.homePhone || "",
			coBuyerFirstName: state?.coBuyer?.firstName || "",
			coBuyerLastName: state?.coBuyer?.lastName || "",
			coBuyerPhone: state?.coBuyer?.homePhone || "",
		}),
		shallow
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Customer</CardTitle>
			</CardHeader>
			<CardBody>
				{buyerFirstName && buyerLastName && (
					<ReviewRow
						label="Buyer"
						right={false}
						value={`${buyerFirstName} ${buyerLastName}`}
					/>
				)}
				{buyerStreet && buyerCity && buyerState && buyerZip && (
					<ReviewRow
						label="Address"
						right={false}
						value={`${buyerStreet} ${buyerCity} ${buyerState} ${buyerZip}`}
					/>
				)}
				{buyerPhone && (
					<ReviewRow
						isPhone={true}
						label="Phone"
						right={false}
						value={buyerPhone}
					/>
				)}
				{coBuyerFirstName && coBuyerLastName && (
					<ReviewRow
						label="Cobuyer"
						right={false}
						value={`${coBuyerFirstName} ${coBuyerLastName}`}
					/>
				)}
				{coBuyerPhone && (
					<ReviewRow
						isPhone={true}
						label="Phone"
						right={false}
						value={coBuyerPhone}
					/>
				)}
			</CardBody>
		</Card>
	);
};

ReviewCustomer.propTypes = {
	buyerFirstName: PropTypes.string,
	buyerLastName: PropTypes.string,
	buyerStreet: PropTypes.string,
	buyerCity: PropTypes.string,
	buyerState: PropTypes.string,
	buyerZip: PropTypes.string,
	buyerPhone: PropTypes.string,
	coBuyerFirstName: PropTypes.string,
	coBuyerLastName: PropTypes.string,
	coBuyerPhone: PropTypes.string,
};

export default ReviewCustomer;
