import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import PepperpayProgressBar from "./PepperpayProgressBar";

import "assets/css/multi-step-form.css";
import OwnersInformation from "./OwnersInformation";
import MainInformation from "./MainInformation";
import BusinessInformation from "./BusinessInformation";
import ProfileInformation from "./ProfileInformation";
import Questionnaire from "./Questionnaire";
import Summary from "./Summary";
import { TabContent, TabPane } from "reactstrap";

const Pepperpay = ({ toggle }) => {
	const formComponents = {
		mainInfo: 0,
		business: 1,
		owners: 2,
		profile: 3,
		questionnaire: 4,
		summary: 5,
	};
	// Keeps track of the current form tab
	const [currentForm, setCurrentForm] = useState(formComponents.mainInfo);
	//// Main Info Form
	const [userEmail, setUserEmail] = useState("");
	const [userFullName, setUserFullName] = useState("");
	const [acceptAeo, setAcceptAeo] = useState(false);
	const [acceptEbt, setAcceptEbt] = useState(false);
	const [acceptEbtNumber, setAcceptEbtNumber] = useState(0);
	const [acceptPinDeb, setAcceptPinDeb] = useState(false);
	const [acceptPinlessDeb, setAcceptPinlessDeb] = useState(false);
	const [acceptVisa, setAcceptVisa] = useState(false);
	const [acceptingBankcards, setAcceptingBankcards] = useState(false);
	const [acceptingBankcardsExplain, setAcceptingBankcardsExplain] = useState(
		""
	); //": "explaination of bankcards",
	const [billingFrequency, setBillingFrequency] = useState(""); //daily
	const [isPciCompliant, setIsPciCompliant] = useState(false);
	const [locationAreaZoned, setLocationAreaZoned] = useState("");
	const [locationBuildingType, setLocationBuildingType] = useState("");
	const [locationMerchant, setLocationMerchant] = useState("");
	const [locationSquareFootage, setLocationSquareFootage] = useState("");
	////   "business": {
	const [contactName, setContactName] = useState("");
	const [contactPhone, setContactPhone] = useState("");
	const [mailingType, setMailingType] = useState(""); //DBA Address
	// "doingBusinessAs": {
	//"address": {
	const [buAddress1, setBuAddress1] = useState("");
	const [buAddress2, setBuAddress2] = useState("");
	const [buCity, setBuCity] = useState("");
	const [buState, setBuState] = useState("");
	const [buZip, setBuZip] = useState("");
	//},
	const [buEmail, setBuEmail] = useState("");
	const [buFax, setBuFax] = useState("");
	const [buName, setBuName] = useState("");
	const [buPhone, setBuPhone] = useState("");
	//"legal": {
	//"address": {
	const [leAddress1, setLeAddress1] = useState("");
	const [leAddress2, setLeAddress2] = useState("");
	const [leCity, setLeCity] = useState("");
	const [leState, setLeState] = useState("");
	const [leZip, setLeZip] = useState("");
	const [leEmail, setLeEmail] = useState("");
	const [leFax, setLeFax] = useState("");
	const [leName, setLeName] = useState("");
	const [lePhone, setLePhone] = useState("");

	//// owners Info Form
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [middleName, setMiddleName] = useState("");

	const [email, setEmail] = useState("");
	const [homePhone, setHomePhone] = useState("");
	const [mobilePhone, setMobilePhone] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [ssn, setSsn] = useState("");
	const [title, setTitle] = useState(""); //CEO
	const [isControlProng, setIsControlProng] = useState(false);
	const [isSigner, setIsSigner] = useState(false);
	const [ownershipPercentage, setOwnershipPercentage] = useState(100);
	//driverLicense
	const [dlExpiration, setDlExpiration] = useState(""); //Date
	const [dlNumber, setDlNumber] = useState(""); //-number
	const [dlState, setDlState] = useState("CA"); //-state

	//address
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zip, setZip] = useState("");

	//owner2

	const [o2FirstName, setO2FirstName] = useState("");
	const [o2LastName, setO2LastName] = useState("");
	const [o2MiddleName, setO2MiddleName] = useState("");
	const [o2Email, setO2Email] = useState("");
	const [o2HomePhone, setO2HomePhone] = useState("");
	const [o2MobilePhone, setO2MobilePhone] = useState("");
	const [o2DateOfBirth, setO2DateOfBirth] = useState("");
	const [o2Ssn, setO2Ssn] = useState("");
	const [o2Title, setO2Title] = useState("");
	const [o2IsControlProng, setO2IsControlProng] = useState(false);
	const [o2IsSigner, setO2IsSigner] = useState(false);
	const [o2OwnershipPercentage, setO2OwnershipPercentage] = useState(0);
	const [o2DlExpiration, setO2DlExpiration] = useState("");
	const [o2DlNumber, setO2DlNumber] = useState("");
	const [o2DlState, setO2DlState] = useState("");
	const [o2Address1, setO2Address1] = useState("");
	const [o2Address2, setO2Address2] = useState("");
	const [o2City, setO2City] = useState("");
	const [o2State, setO2State] = useState("");
	const [o2Zip, setO2Zip] = useState("");
	const [hasSecondOwner, setHasSecondOwner] = useState(false);
	//// profile Info Form
	const [
		averageAmexMonthlySalesVolume,
		setAverageAmexMonthlySalesVolume,
	] = useState(0);
	const [averageMonthlySalesVolume, setAverageMonthlySalesVolume] = useState(0);
	const [averageTicketSize, setAverageTicketSize] = useState(10);
	const [businessType, setBusinessType] = useState(""); //Retail
	const [federalTaxId, setFederalTaxId] = useState("");
	const [highestTicketSize, setHighestTicketSize] = useState(0);
	const [mccSicCode, setMccSicCode] = useState("5511");
	const [monthsInBusiness, setMonthsInBusiness] = useState(0);
	const [numberOfLocations, setNumberOfLocations] = useState(1);
	const [ownershipType, setOwnershipType] = useState(""); //Partnership
	const [typeOfGoodsServices, setTypeOfGoodsServices] = useState(""); //Retail
	const [yearInBusiness, setYearInBusiness] = useState(0);
	//creditBankingInformation
	const [creditAccountNumber, setCreditAccountNumber] = useState("");
	const [creditAccountOwnership, setCreditAccountOwnership] = useState(""); //Busines
	const [creditAccountType, setCreditAccountType] = useState(""); //Checking
	const [creditAuthorizedFirstName, setCreditAuthorizedFirstName] = useState(
		""
	);
	const [creditAuthorizedLastName, setCreditAuthorizedLastName] = useState("");
	const [creditRoutingNumber, setCreditRoutingNumber] = useState("");
	//debitBankingInformation"
	const [debitAccountNumber, setDebitAccountNumber] = useState("");
	const [debitAccountOwnership, setDebitAccountOwnership] = useState(""); //Busines
	const [debitAccountType, setDebitAccountType] = useState(""); //Checking
	const [debitAuthorizedFirstName, setDebitAuthorizedFirstName] = useState("");
	const [debitAuthorizedLastName, setDebitAuthorizedLastName] = useState("");
	const [debitRoutingNumber, setDebitRoutingNumber] = useState("");

	//ownersBackground
	const [bankruptcyEverTerminated, setBankruptcyEverTerminated] = useState(
		false
	);
	const [bankruptcyFiled, setBankruptcyFiled] = useState(""); //Never filed
	const [bankruptcyFiledYear, setBankruptcyFiledYear] = useState(0);
	//// questionnaire
	const [advertise, setAdvertise] = useState("");
	const [businessPercentageSell, setBusinessPercentageSell] = useState(0);
	const [currentCardBrandProcessor, setCurrentCardBrandProcessor] = useState(
		""
	);
	const [customerCharged, setCustomerCharged] = useState("");
	const [deliverMerchandiseCustomer, setDeliverMerchandiseCustomer] = useState(
		""
	);
	const [describeProductService, setDescribeProductService] = useState("");
	const [dollarAmountChargebacks, setDollarAmountChargebacks] = useState(0);
	const [fulfillmentHouse, setFulfillmentHouse] = useState(false);
	const [
		manyChargebacksPreviousYear,
		setManyChargebacksPreviousYear,
	] = useState(0);
	const [ownProductInventory, setOwnProductInventory] = useState(false);
	const [productStoreAboveAddress, setProductStoreAboveAddress] = useState(
		false
	);
	const [publicPercentageSell, setPublicPercentageSell] = useState(0);
	const [retailLocation, setRetailLocation] = useState(true);
	const [sell, setSell] = useState("");
	const [sellProductService, setSellProductService] = useState("");
	const [serverLocation, setServerLocation] = useState("");
	const [webSite, setWebSite] = useState(""); // "google.com"

	//addressProductHeld
	const [phState, setPhState] = useState("CA");
	const [phStreet, setPhStreet] = useState("");
	const [phZipCode, setPhZipCode] = useState("");
	const [phCity, setPhCity] = useState("");

	//fulfillmentHouseProvide
	const [fulfillAddress, setFulfillAddress] = useState("");
	const [fulfillCompanyName, setFulfillCompanyName] = useState("");
	const [fulfillTelephone, setFulfillTelephone] = useState("");
	//percentageSalesFrom
	const [cardPresent, setCardPresent] = useState(0);
	const [internet, setInternet] = useState(0);
	const [mail, setMail] = useState(0);
	const [telephone, setTelephone] = useState(0);
	//termsAndConditions
	const [
		corporationNameMatchApplicationName,
		setCorporationNameMatchApplicationName,
	] = useState(false);
	const [currencyDollar, setCurrencyDollar] = useState(false);
	const [dataShare, setDataShare] = useState(false);
	const [membershipAnyType, setMembershipAnyType] = useState(false);
	const [negativeOptionBilling, setNegativeOptionBilling] = useState(false);
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [purchasePage, setPurchasePage] = useState(false);
	const [refundPolicy, setRefundPolicy] = useState("");
	const [secureProcessing, setSecureProcessing] = useState(false);
	const [shippingPolicy, setShippingPolicy] = useState("");

	// Wrapper for input fields

	//// Main Info Form
	const mainInfo = {
		userEmail,
		userFullName,
		acceptAeo,
		acceptEbt,
		acceptEbtNumber,
		acceptPinDeb,
		acceptPinlessDeb,
		acceptVisa,
		acceptingBankcards,
		acceptingBankcardsExplain,
		billingFrequency,
		isPciCompliant,
		locationAreaZoned,
		locationBuildingType,
		locationMerchant,
		locationSquareFootage,
	};
	////   "business": {
	const business = {
		contactName,
		contactPhone,
		mailingType,
		// "doingBusinessAs": {
		//"address": {
		buAddress1,
		buAddress2,
		buCity,
		buState,
		buZip,
		buEmail,
		buFax,
		buName,
		buPhone,
	};
	//"legal": {
	//"address": {
	const legal = {
		leAddress1,
		leAddress2,
		leCity,
		leState,
		leZip,
		leEmail,
		leFax,
		leName,
		lePhone,
	};

	//// owners Info Form
	const ownersInfo = {
		firstName,
		lastName,
		middleName,
		email,
		homePhone,
		mobilePhone,
		dateOfBirth,
		ssn,
		title,
		isControlProng,
		isSigner,
		ownershipPercentage,
	};
	//driverLicense
	const driverLicenseInfo = {
		dlExpiration,
		dlNumber,
		dlState,
	};
	//address
	const addressInfo = {
		address1,
		address2,
		city,
		state,
		zip,
	};
	//// owner 2 Info
	const owner2 = {
		o2FirstName,
		o2LastName,
		o2MiddleName,
		o2Email,
		o2HomePhone,
		o2MobilePhone,
		o2DateOfBirth,
		o2Ssn,
		o2Title,
		o2IsControlProng,
		o2IsSigner,
		o2OwnershipPercentage,
		o2DlExpiration,
		o2DlNumber,
		o2DlState,
		o2Address1,
		o2Address2,
		o2City,
		o2State,
		o2Zip,
		hasSecondOwner,
		setO2FirstName,
		setO2LastName,
		setO2MiddleName,
		setO2Email,
		setO2HomePhone,
		setO2MobilePhone,
		setO2DateOfBirth,
		setO2Ssn,
		setO2Title,
		setO2IsControlProng,
		setO2IsSigner,
		setO2OwnershipPercentage,
		setO2DlExpiration,
		setO2DlNumber,
		setO2DlState,
		setO2Address1,
		setO2Address2,
		setO2City,
		setO2State,
		setO2Zip,
		setHasSecondOwner,
	};

	//// profile Bank Info Form
	const profileInfo = {
		averageAmexMonthlySalesVolume,
		averageMonthlySalesVolume,
		averageTicketSize,
		businessType,
		federalTaxId,
		highestTicketSize,
		mccSicCode,
		monthsInBusiness,
		numberOfLocations,
		ownershipType,
		typeOfGoodsServices,
		yearInBusiness,
	};
	//creditBankingInformation
	const creditAccountInfo = {
		creditAccountNumber,
		creditAccountOwnership,
		creditAccountType,
		creditAuthorizedFirstName,
		creditAuthorizedLastName,
		creditRoutingNumber,
	};
	//debitBankingInformation"
	const debitAccountInfo = {
		debitAccountNumber,
		debitAccountOwnership,
		debitAccountType,
		debitAuthorizedFirstName,
		debitAuthorizedLastName,
		debitRoutingNumber,
	};
	//ownersBackground
	const backgroundInfo = {
		bankruptcyEverTerminated,
		bankruptcyFiled,
		bankruptcyFiledYear,
	};
	//// questionnaire
	const questionnaire = {
		advertise,
		businessPercentageSell,
		currentCardBrandProcessor,
		customerCharged,
		deliverMerchandiseCustomer,
		describeProductService,
		dollarAmountChargebacks,
		fulfillmentHouse,
		manyChargebacksPreviousYear,
		ownProductInventory,
		productStoreAboveAddress,
		publicPercentageSell,
		retailLocation,
		sell,
		sellProductService,
		serverLocation,
		webSite,
	};

	//addressProductHeld
	const addressProductHeld = {
		phState,
		phStreet,
		phZipCode,
		phCity,
	};
	//fulfillmentHouseProvide
	const fulfillmentHouseProvide = {
		fulfillAddress,
		fulfillCompanyName,
		fulfillTelephone,
	};
	//percentageSalesFrom
	const percentageSalesFrom = {
		cardPresent,
		internet,
		mail,
		telephone,
	};

	//termsAndConditions
	const termsAndConditions = {
		corporationNameMatchApplicationName,
		currencyDollar,
		dataShare,
		membershipAnyType,
		negativeOptionBilling,
		privacyPolicy,
		purchasePage,
		refundPolicy,
		secureProcessing,
		shippingPolicy,
	};
	// Wrapper for input setters

	//// Main Info Form
	const setMainInfo = {
		setUserEmail,
		setUserFullName,
		setAcceptAeo,
		setAcceptEbt,
		setAcceptEbtNumber,
		setAcceptPinDeb,
		setAcceptPinlessDeb,
		setAcceptVisa,
		setAcceptingBankcards,
		setAcceptingBankcardsExplain,
		setBillingFrequency,
		setIsPciCompliant,
		setLocationAreaZoned,
		setLocationBuildingType,
		setLocationMerchant,
		setLocationSquareFootage,
	};
	////   "business": {
	const setBusiness = {
		setContactName,
		setContactPhone,
		setMailingType,
		setBuAddress1,
		setBuAddress2,
		setBuCity,
		setBuState,
		setBuZip,
		setBuEmail,
		setBuFax,
		setBuName,
		setBuPhone,
	};
	//"legal": {
	//"address": {
	const setLegal = {
		setLeAddress1,
		setLeAddress2,
		setLeCity,
		setLeState,
		setLeZip,
		setLeEmail,
		setLeFax,
		setLeName,
		setLePhone,
	};

	//// owners Info Form
	const setOwnersInfo = {
		setFirstName,
		setLastName,
		setMiddleName,
		setEmail,
		setHomePhone,
		setMobilePhone,
		setDateOfBirth,
		setSsn,
		setTitle,
		setIsControlProng,
		setIsSigner,
		setOwnershipPercentage,
	};
	//driverLicense
	const setDriverLicenseInfo = {
		setDlExpiration,
		setDlNumber,
		setDlState,
	};
	//address
	const setAddressInfo = {
		setAddress1,
		setAddress2,
		setCity,
		setState,
		setZip,
	};
	//// profile Bank Info Form
	const setProfileInfo = {
		setAverageAmexMonthlySalesVolume,
		setAverageMonthlySalesVolume,
		setAverageTicketSize,
		setBusinessType,
		setFederalTaxId,
		setHighestTicketSize,
		setMccSicCode,
		setMonthsInBusiness,
		setNumberOfLocations,
		setOwnershipType,
		setTypeOfGoodsServices,
		setYearInBusiness,
	};
	//creditBankingInformation
	const setCreditAccountInfo = {
		setCreditAccountNumber,
		setCreditAccountOwnership,
		setCreditAccountType,
		setCreditAuthorizedFirstName,
		setCreditAuthorizedLastName,
		setCreditRoutingNumber,
	};
	//debitBankingInformation"
	const setDebitAccountInfo = {
		setDebitAccountNumber,
		setDebitAccountOwnership,
		setDebitAccountType,
		setDebitAuthorizedFirstName,
		setDebitAuthorizedLastName,
		setDebitRoutingNumber,
	};
	//ownersBackground
	const setBackgroundInfo = {
		setBankruptcyEverTerminated,
		setBankruptcyFiled,
		setBankruptcyFiledYear,
	};
	//// questionnaire
	const setQuestionnaire = {
		setAdvertise,
		setBusinessPercentageSell,
		setCurrentCardBrandProcessor,
		setCustomerCharged,
		setDeliverMerchandiseCustomer,
		setDescribeProductService,
		setDollarAmountChargebacks,
		setFulfillmentHouse,
		setManyChargebacksPreviousYear,
		setOwnProductInventory,
		setProductStoreAboveAddress,
		setPublicPercentageSell,
		setRetailLocation,
		setSell,
		setSellProductService,
		setServerLocation,
		setWebSite,
	};

	//addressProductHeld
	const setAddressProductHeld = {
		setPhState,
		setPhStreet,
		setPhZipCode,
		setPhCity,
	};
	//fulfillmentHouseProvide
	const setFulfillmentHouseProvide = {
		setFulfillAddress,
		setFulfillCompanyName,
		setFulfillTelephone,
	};
	//percentageSalesFrom
	const setPercentageSalesFrom = {
		setCardPresent,
		setInternet,
		setMail,
		setTelephone,
	};
	//termsAndConditions
	const setTermsAndConditions = {
		setCorporationNameMatchApplicationName,
		setCurrencyDollar,
		setDataShare,
		setMembershipAnyType,
		setNegativeOptionBilling,
		setPrivacyPolicy,
		setPurchasePage,
		setRefundPolicy,
		setSecureProcessing,
		setShippingPolicy,
	};

	// Define form validation parameters
	const methods = useForm({
		mode: "onChange",
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		resolver: yupResolver(),
		shouldFocusError: true,
	});

	const tabComponents = [
		<MainInformation
			mainInfo={mainInfo}
			setMainInfo={setMainInfo}
			business={business}
			setBusiness={setBusiness}
			setCurrentForm={setCurrentForm}
			nextForm={formComponents.business}
			readOnly={false}
		/>,
		<BusinessInformation
			mainInfo={mainInfo}
			setMainInfo={setMainInfo}
			profileInfo={profileInfo}
			setProfileInfo={setProfileInfo}
			business={business}
			legal={legal}
			setBusiness={setBusiness}
			setLegal={setLegal}
			setCurrentForm={setCurrentForm}
			nextForm={formComponents.owners}
			previousForm={formComponents.mainInfo}
		/>,
		<OwnersInformation
			mainInfo={mainInfo}
			//owner1
			ownersInfo={ownersInfo}
			driverLicenseInfo={driverLicenseInfo}
			addressInfo={addressInfo}
			setMainInfo={setMainInfo}
			setOwnersInfo={setOwnersInfo}
			setDriverLicenseInfo={setDriverLicenseInfo}
			setAddressInfo={setAddressInfo}
			//owner2
			owner2={owner2}
			setCurrentForm={setCurrentForm}
			nextForm={formComponents.profile}
			previousForm={formComponents.business}
			readOnly={false}
		/>,
		<ProfileInformation
			profileInfo={profileInfo}
			creditAccountInfo={creditAccountInfo}
			debitAccountInfo={debitAccountInfo}
			backgroundInfo={backgroundInfo}
			setProfileInfo={setProfileInfo}
			setCreditAccountInfo={setCreditAccountInfo}
			setDebitAccountInfo={setDebitAccountInfo}
			setBackgroundInfo={setBackgroundInfo}
			setCurrentForm={setCurrentForm}
			nextForm={formComponents.questionnaire}
			previousForm={formComponents.owners}
		/>,
		<Questionnaire
			questionnaire={questionnaire}
			addressProductHeld={addressProductHeld}
			fulfillmentHouseProvide={fulfillmentHouseProvide}
			percentageSalesFrom={percentageSalesFrom}
			termsAndConditions={termsAndConditions}
			setQuestionnaire={setQuestionnaire}
			setAddressProductHeld={setAddressProductHeld}
			setFulfillmentHouseProvide={setFulfillmentHouseProvide}
			setPercentageSalesFrom={setPercentageSalesFrom}
			setTermsAndConditions={setTermsAndConditions}
			setCurrentForm={setCurrentForm}
			nextForm={formComponents.summary}
			previousForm={formComponents.profile}
		/>,
		<Summary
			setCurrentForm={setCurrentForm}
			previousForm={formComponents.questionnaire}
			mainInfo={mainInfo}
			business={business}
			legal={legal}
			ownersInfo={ownersInfo}
			driverLicenseInfo={driverLicenseInfo}
			addressInfo={addressInfo}
			profileInfo={profileInfo}
			creditAccountInfo={creditAccountInfo}
			debitAccountInfo={debitAccountInfo}
			backgroundInfo={backgroundInfo}
			questionnaire={questionnaire}
			addressProductHeld={addressProductHeld}
			fulfillmentHouseProvide={fulfillmentHouseProvide}
			percentageSalesFrom={percentageSalesFrom}
			termsAndConditions={termsAndConditions}
			owner2={owner2}
			toggle={toggle}
		/>,
	];
	// Content for each tab
	const navTabContents = tabComponents.map((component, index) => (
		<TabPane id={index} key={index} tabId={index}>
			{component}
		</TabPane>
	));
	return (
		<div className="multi-step-form">
			<div className="form-container">
				<FormProvider {...methods}>
					<PepperpayProgressBar
						currentForm={currentForm}
						setCurrentForm={setCurrentForm}
					/>
					<TabContent activeTab={currentForm}>{navTabContents}</TabContent>
				</FormProvider>
			</div>
		</div>
	);
};
export default Pepperpay;
