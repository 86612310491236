// Focuses on an element and selects all text in it, if applicable
const focusNextElement = (element) => {
	element.focus();

	if (element.tagName === "INPUT") {
		element.select();
	}
};

// Returns true if we should focus to the next element after enter press
const shouldTab = (tagName) => tagName !== "TEXTAREA";

// Tabs to the next form element on enter key press
const tabOnEnter = (event) => {
	if (event.keyCode === 13) {
		const childForms = document.querySelectorAll(`form`);
		const element = event.target;
		let focusableElements = [];

		// Get all focusable elements from the forms
		childForms.forEach((form) => {
			for (let i = 0; i < form.length; i++) {
				if (!form[i].disabled && !form[i].readOnly) {
					focusableElements.push(form[i]);
				}
			}
		});

		// Loop over to the next element
		for (let i = 0; i < focusableElements.length - 1; i++) {
			const currentElement = focusableElements[i];

			if (shouldTab(currentElement.tagName)) {
				const nextElement = focusableElements[i + 1];

				if (currentElement.name !== "" && element.name !== "") {
					if (currentElement.name === element.name) {
						if (nextElement.tagName === "TEXTAREA") {
							event.preventDefault();
						}

						focusNextElement(nextElement);

						break;
					}
				} else if (focusableElements.id !== "" && element.id !== "") {
					if (currentElement.id === element.id) {
						if (nextElement.tagName === "TEXTAREA") {
							event.preventDefault();
						}

						focusNextElement(nextElement);

						break;
					}
				}
			}
		}
	}
};

export default tabOnEnter;
