import React, { useEffect, useState } from "react";
import { Button, CardTitle, Col, Row } from "reactstrap";
import shallow from "zustand/shallow";
import * as yup from "yup";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import formNavigationUtils from "../../../utils/formNavigationUtils";
import useCurrentCustomerStore from "../../../stores/CustomerStore";
import useCurrentSaleStore from "../../../stores/SaleStore";
import BusinessDetails from "../subFeatures/contactTabs/detailsTab/BusinessDetails";
import ChildrenModal from "../../../components/modals/ChildrenModal";
import CurrentAddress from "../subFeatures/contactTabs/addressTab/CurrentAddress";
import CustomerDetails from "../subFeatures/contactTabs/detailsTab/CustomerDetails";
import SaveNewCustomerButton from "../helperFunctions/SaveNewCustomerButton";
import ZipCityTable from "../../../components/tables/ZipCityTable";

const ContactAddPage = ({ updateDeal, isDeal = false, isBuyer }) => {
	// Local states
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Opens and closes the city modal
	const toggleCityModal = () => setCityModal(!cityModal);

	// Customer store
	const {
		type,
		currentAddress,
		editCurrentAddress,
		editType,
		reset,
	} = useCurrentCustomerStore(
		(state) => ({
			type: state.type,
			currentAddress: state.currentAddress,
			editCurrentAddress: state.editCurrentAddress,
			editType: state.editType,
			reset: state.reset,
		}),
		shallow
	);

	//sale store
	const { saleType } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
		}),
		shallow
	);

	// Show modal containing cities with the 5 digit zip entered
	// Used to get the tax rate associated with a customer zip
	const handleZipChange = (zip, address) => {
		setAddressData({ zip, address });
		toggleCityModal();
	};

	useEffect(() => {
		reset();
		if (saleType === 1 || saleType === 2) {
			editType(1);
		}
		return () => {
			reset();
		};
		// eslint-disable-next-line
	}, []);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			matches: "Example Format: (123)456-7890",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		firstName: yup.string().max(100),
		lastName: yup.string().required().max(100),
		contact: yup.string().max(25),
		source: yup.string().max(9),
		currentStreet: yup.string().max(50),
		currentCity: yup.string().max(50),
		mailStreet: yup.string().max(50),
		mailCity: yup.string().max(50),
		ssn: yup.string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
			message: "Must be 9 digits or empty",
			excludeEmptyString: true,
		}),
		email: yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
			message: "Invalid Email",
			excludeEmptyString: true,
		}),
		cellPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		homePhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		extraPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		workPhone: yup
			.string()
			.matches(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/, {
				message: "Invalid Phone Number",
				excludeEmptyString: true,
			}),
		// currentZip: yup.string().required().max(5),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onChange",
		resolver: yupResolver(schema),
	});

	return (
		<div onKeyDown={formNavigationUtils}>
			<Col className="pb-2 pt-3 text-center mobileHeading">
				<CardTitle>
					<h2>Add Customer</h2>
				</CardTitle>
			</Col>

			<div className="col pb-3 pt-0">
				<div className="row mx-0 noDesktop">
					<div className="col text-center">
						<Button className="btn btn-primary btn-md">
							<i className="nc-icon nc-simple-add"></i> Scan License
						</Button>
					</div>
				</div>
				<div>
					<Row>
						{saleType !== 1 && saleType !== 2 ? (
							<div className="col">
								<div role="group" className="btn-group">
									{type === 0 ? (
										<button
											type="button"
											className="btn-round btn-sm btn btn-outline-secondary-active"
											onClick={() => {
												editType(0);
											}}
										>
											Personal
										</button>
									) : (
										<button
											type="button"
											class="btn-round btn-sm btn btn-outline-secondary"
											onClick={() => {
												editType(0);
											}}
										>
											Personal
										</button>
									)}
									{type === 1 ? (
										<button
											type="button"
											className="btn-round btn-sm btn btn-outline-secondary-active"
											onClick={() => {
												editType(1);
											}}
										>
											Business
										</button>
									) : (
										<button
											type="button"
											className="btn-round btn-sm btn btn-outline-secondary"
											onClick={() => {
												editType(1);
											}}
										>
											Business
										</button>
									)}
								</div>
							</div>
						) : null}

						{/* might need it in the future */}
						{/* {isDeal && isBuyer ? (
							<div className="col text-right">
								<OpenModalButton
									buttonClass="btn-md"
									buttonColor="secondary"
									onClick={toggleAdd}
									buttonText={
										saleType === 1
											? "Select Previous Company"
											: "Select Previous Customer"
									}
									icon={true}
								/>
							</div>
						) : null}
						<ChildrenModal
							modal={addModal}
							toggle={toggleAdd}
							children={
								<ContactListPage
									isDeal={true}
									toggle={toggleAdd}
									editCustomer={editBuyer}
									editAddress={editBuyerAddress}
									editMailAddress={editBuyerMailAddress}
									editRecNum={editBuyerRecNum}
								/>
							}
						/> */}
					</Row>
				</div>
			</div>
			<FormProvider {...methods}>
				<div className="col">
					{(type === 0 || type === null) && saleType !== 1 ? (
						<CustomerDetails />
					) : (
						<BusinessDetails />
					)}
				</div>
				<div className="col">
					<CurrentAddress getCities={handleZipChange} />
					<ChildrenModal
						modal={cityModal}
						toggle={toggleCityModal}
						modalTitle="Cities"
					>
						<ZipCityTable
							data={addressData}
							toggle={toggleCityModal}
							address={currentAddress}
							setAddress={editCurrentAddress}
						/>
					</ChildrenModal>
				</div>
				<div className={isDeal ? "" : "float-button"}>
					<SaveNewCustomerButton updateDeal={updateDeal} />
				</div>
			</FormProvider>
		</div>
	);
};

export default ContactAddPage;
