import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";

/**
 * FilterColumn Component
 *
 * @param {Object} props - Component properties
 * @param {Object} props.column - Column object
 * @param {Array} [props.customOptions] - Custom options for the select
 *
 * @returns {JSX.Element} The FilterColumn component
 */
const FilterColumn = ({
	column: { filterValue, setFilter, preFilteredRows, id },
	customOptions = null,
}) => {
	// Calculate the options for filtering
	const options = useMemo(() => {
		// If custom options are provided, use them
		if (customOptions) return customOptions;

		// Otherwise, calculate options based on preFilteredRows
		const optionsSet = new Set();
		preFilteredRows.forEach((row) => {
			if (row.values[id] !== " ") {
				optionsSet.add(row.values[id]);
			}
		});
		return [...optionsSet.values()];
	}, [id, preFilteredRows, customOptions]);

	return (
		<FormGroup>
			<Input
				type="select"
				value={filterValue}
				onChange={(e) => {
					setFilter(e.target.value || undefined);
				}}
			>
				<option value="">All</option>
				{options.map((option, i) => (
					<option key={i} value={option}>
						{option}
					</option>
				))}
			</Input>
		</FormGroup>
	);
};

export default FilterColumn;
