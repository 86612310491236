import React from "react";
import InventoryCard from "./InventoryCard";
import "assets/css/inventory-card-list.css";
import SortableCardList from "components/SortableCardList";

/**
 * Sort options for the InventoryCardList component.
 */
const SORT_OPTIONS = [
	{ key: "vehicle", order: "asc", label: "Name Ascending" },
	{ key: "vehicle", order: "desc", label: "Name Descending" },
	{ key: "vin", order: "asc", label: "VIN Ascending" },
	{ key: "vin", order: "desc", label: "VIN Descending" },
	{ key: "stockNo", order: "asc", label: "Stock Number Ascending" },
	{ key: "stockNo", order: "desc", label: "Stock Number Descending" },
	{ key: "askingPrice", order: "asc", label: "Price Low to High" },
	{ key: "askingPrice", order: "desc", label: "Price High to Low" },
];

/**
 * InventoryCardList Component
 * @param {Object} props - Component properties
 * @param {Array} props.data - The data to display in the card list
 * @param {Function} props.onEdit - Callback function when an inventory card is edited
 * @param {Function} props.onDeal - Callback function when an inventory card deal is viewed
 * @param {boolean} props.focusSearch - Whether to focus on the search input on mount
 */
const InventoryCardList = ({ data, onEdit, onDeal, focusSearch }) => {
	/**
	 * Renders an inventory card.
	 * @param {Object} vehicle - The vehicle object to render
	 * @returns {JSX.Element} The rendered inventory card
	 */
	const renderCard = (vehicle) => (
		<div key={vehicle.inventoryID} className="inv-card-wrapper">
			<InventoryCard
				vehicle={vehicle}
				onEdit={() => onEdit(vehicle.inventoryID, vehicle.thumbnailURL)}
				onDeal={() => onDeal(vehicle.inventoryID)}
			/>
		</div>
	);

	return (
		<SortableCardList
			data={data}
			sortOptions={SORT_OPTIONS}
			renderCard={renderCard}
			searchFields={["vehicle", "vin", "stockNo"]}
			focusSearch={focusSearch}
			containerClassName="inv-card-container"
		/>
	);
};

export default React.memo(InventoryCardList);
