import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { CurrencyInput } from "features/forms/components/InputElement";

/**
 * SummaryRow component for displaying and editing currency values.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.label - Label for the row
 * @param {number} props.value - Value of the currency input
 * @param {function} [props.onChange] - Callback function for value change
 * @param {boolean} props.disabled - Flag to disable the input
 * @returns {JSX.Element} The rendered component
 */

const SummaryRow = ({ label, value, onChange, disabled }) => (
	<Row className="p-0 align-items-center">
		<Col md="6">
			<p>{label}</p>
		</Col>
		<Col md="6">
			<CurrencyInput
				value={value}
				onChange={onChange}
				disabled={disabled}
				readOnly={disabled}
				disableValidation
				wholeRow
			/>
		</Col>
	</Row>
);

SummaryRow.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool.isRequired,
};

export default SummaryRow;
