// Checks that either all address fields needed to save an address
// are present or all empty for no addresses entered. Return true
// if all fields are empty, false otherwise
export const hasValidFields = (address) => {
	const hasEmptyReqFields =
		address.city === "" && address.state === "" && address.zip === "";
	//address.county === "";

	const hasNonEmptyFields =
		address.city !== "" && address.state !== "" && address.zip !== "";
	//address.county !== "";

	if (hasEmptyReqFields || hasNonEmptyFields) {
		return true;
	}

	return false;
};

// Builds current address object
export const buildCurrentAddress = (obj, dealerID, locationID, id = null) => {
	return {
		ID: id,
		street: obj.currentStreet,
		unit: obj.currentUnit,
		city: obj.currentCity,
		state: obj.currentState,
		zip: obj.currentZip,
		county: obj.currentCounty,
		fromDate: obj.currentFromDate,
		toDate: null,
		isCurrent: true,
		isMailingAddress: false,
		dealerID,
		locationID,
		ownership: obj.currentOwnership,
		months: obj.currentMonth,
		years: obj.currentYear,
		monthlyPayment: parseFloat(
			obj.currentMonthlyPayment.replace(/[^0-9.-]+/g, "")
		),
	};
};

// Builds mailing address object
export const buildMailingAddress = (obj, dealerID, locationID, id = null) => {
	return {
		ID: id,
		street: obj.mailStreet,
		unit: obj.mailUnit,
		city: obj.mailCity,
		state: obj.mailState,
		zip: obj.mailZip,
		county: obj.mailCounty,
		isCurrent: false,
		isMailingAddress: true,
		dealerID,
		locationID,
	};
};

//=================================
// Builds current address object
export const buildCurrentAddress2 = (obj, dealerID, locationID, id = null) => {
	console.log(obj);
	return {
		ID: id,
		street: obj.street,
		unit: obj.unit,
		city: obj.city,
		state: obj.state,
		zip: obj.zip,
		county: obj.county,
		fromDate: null,
		toDate: null,
		isCurrent: true,
		isMailingAddress: false,
		dealerID,
		locationID,
		ownership: obj.currentOwnership,
	};
};

// Builds mailing address object
export const buildMailingAddress2 = (obj, dealerID, locationID, id = null) => {
	return {
		ID: id,
		street: obj.street,
		unit: obj.unit,
		city: obj.city,
		state: obj.state,
		zip: obj.zip,
		county: obj.County,
		isCurrent: false,
		isMailingAddress: true,
		dealerID,
		locationID,
	};
};
