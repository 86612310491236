import React from "react";
import FormsReportsList from "../features/forms/components/FormsReportsList";

const AccountFormsTab = () => {
	return (
		<FormsReportsList
			listTitle="BHPH Forms"
			searchable={true}
			listName={"bhphForms"}
		/>
	);
};

export default AccountFormsTab;
