import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

/**
 * A reusable component to format and display currency values.
 *
 * @param {object} props - Component props
 * @param {number} props.value - The currency value to be formatted
 * @param {string} [props.prefix="$"] - The prefix to use for the currency value (default: "$")
 * @returns {JSX.Element} Formatted currency value
 */
const FormatCurrency = ({ value, prefix = "$" }) => (
  <NumberFormat
    
    style={{ border: "none" }}
    value={value}
    thousandSeparator={true}
    decimalScale={2}
    prefix={prefix}
    fixedDecimalScale={true}
    isNumericString={true}
    readOnly={true}
  />
);

FormatCurrency.propTypes = {
  /** The numeric value to be formatted as currency */
  value: PropTypes.number.isRequired,
  /** The prefix symbol for the currency (default: "$") */
  prefix: PropTypes.string
};

export default FormatCurrency;
