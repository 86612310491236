import React, { useState } from "react";
import PropTypes from "prop-types";

import Attachment from "./Attachment";
import ChildrenModal from "components/modals/ChildrenModal";

/**
 * AttachmentModal component provides a button to open a modal containing attachment functionality.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.type - Type of the related entity ('account', 'deal', 'inventory', or 'customer')
 * @param {number} props.relatedID - ID of the related deal, inventory, or customer
 * @returns {JSX.Element} The rendered component
 */
const AttachmentModal = ({ type, relatedID }) => {
	const [attachmentModal, setAttachmentModal] = useState(false);

	/**
	 * Toggles the attachment modal open and closed.
	 */
	const toggleAttachmentModal = () => setAttachmentModal(!attachmentModal);

	return (
		<>
			<button
				className="btn btn-primary btn-md"
				onClick={toggleAttachmentModal}
			>
				<i className="nc-icon nc-briefcase-24" />
				&nbsp;Attachments
			</button>
			<ChildrenModal
				modalTitle="Attachments"
				modal={attachmentModal}
				bodyClass="p-0"
				toggle={toggleAttachmentModal}
				size="xl"
			>
				<Attachment relatedID={relatedID} type={type} />
			</ChildrenModal>
		</>
	);
};

AttachmentModal.propTypes = {
	type: PropTypes.string.isRequired,
	relatedID: PropTypes.number.isRequired,
};

export default AttachmentModal;
