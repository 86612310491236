import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Label } from "reactstrap";
import Select from "react-select";
import { useForm, FormProvider } from "react-hook-form";
import { vehicleStatus, vendorTypesOBJ } from "constants/Constants";
import { saveVendor } from "api/VendorsAPI";
import AddVendorModal from "components/modals/AddVendorModal";
import { failAlert } from "utils/alertUtils";
import InputWrapper from "features/forms/components/InputWrapper";
import { CurrencyInputElement } from "features/forms/components/InputElement";

/**
 * TradeInfo component to display and edit trade-related information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.tradeLenderRecNum - The record number of the trade lender.
 * @param {function} props.editTradeLenderRecNum - Function to edit the trade lender record number.
 * @param {number} props.acv - The actual cash value of the trade.
 * @param {number} props.allowance - The allowance value of the trade.
 * @param {number} props.payoff - The payoff amount of the trade.
 * @param {function} props.editAcv - Function to edit the actual cash value.
 * @param {function} props.editAllowance - Function to edit the allowance value.
 * @param {function} props.editPayoff - Function to edit the payoff amount.
 * @param {Array} props.tradeVendors - List of trade vendors.
 * @param {function} props.editTradeVendors - Function to edit the trade vendors list.
 * @param {string} props.status - The status of the vehicle.
 * @returns {JSX.Element} The rendered component.
 */
const TradeInfo = ({
	tradeLenderRecNum,
	editTradeLenderRecNum,
	acv,
	allowance,
	payoff,
	editAcv,
	editAllowance,
	editPayoff,
	tradeVendors,
	editTradeVendors,
	status,
}) => {
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);
	const [lenderOption, setLenderOption] = useState(
		tradeVendors.find((element) => element.ID === tradeLenderRecNum) || {
			value: null,
			label: "None",
		}
	);

	// Function to open the modal
	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);

	// Saves the new lender and add it to the lender list in deal store
	const saveNewLender = (lender) => {
		if (lender != null) {
			const isHidden = false;
			const buildLender = { ...lender, isHidden };
			saveVendor(buildLender).then(
				(res) => {
					const newLender = {
						value: res.data.content.ID,
						label: res.data.content.name,
						lender: res.data.content,
					};
					editTradeVendors([...tradeVendors, newLender]);
					editTradeLenderRecNum(newLender.value);
				},
				(e) => {
					console.error(e);
					failAlert("Failed to save new lender");
				}
			);
		}
	};

	/**
	 * Handles the lender selection change.
	 *
	 * @param {Object} option - The selected lender option.
	 */
	const selectLender = (option) => {
		const value = option.value;
		if (value === "ADD") {
			toggleLenderModal();
		} else {
			editTradeLenderRecNum(value);
			setLenderOption(option);
		}
	};

	// Define form validation parameters
	const methods = useForm();

	// Map trade vendors to select options
	const tradeLendersListMap = tradeVendors.map((obj) => ({
		label: obj.name,
		value: obj.ID,
		vendor: obj,
	}));

	return (
		<div className="my-3">
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle="Trade Info"
						inputComponents={[
							<CurrencyInputElement
								key="allowance"
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={allowance}
								name="allowance"
								label="Allowance"
								type="number"
								onChange={editAllowance}
							/>,
							<CurrencyInputElement
								key="acv"
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={acv}
								name="acv"
								label="Acv"
								type="number"
								onChange={editAcv}
							/>,
							<CurrencyInputElement
								key="payoff"
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								value={payoff}
								name="payoff"
								label="Payoff"
								type="number"
								onChange={editPayoff}
							/>,
							<div key="lienHolder" className="col-sm-3">
								<Label>LienHolder</Label>
								<Select
									className="mb-3"
									readOnly={
										status === vehicleStatus.SOLD ||
										status === vehicleStatus.DELETED
									}
									noOptionsMessage={() => "No Lenders available"}
									value={lenderOption}
									options={[
										...tradeLendersListMap,
										{ value: "ADD", label: "Add new lender" },
									]}
									onChange={selectLender}
								/>
								<AddVendorModal
									title="Add Lender"
									vendorType={vendorTypesOBJ.LENDER.value}
									vendorLabel={vendorTypesOBJ.LENDER.label}
									addVendor={saveNewLender}
									toggle={toggleLenderModal}
									modal={openAddLenderModal}
								/>
							</div>,
						]}
					/>
				</Form>
			</FormProvider>
		</div>
	);
};

/**
 * PropTypes for the TradeInfo component.
 */
TradeInfo.propTypes = {
	tradeLenderRecNum: PropTypes.number,
	editTradeLenderRecNum: PropTypes.func.isRequired,
	acv: PropTypes.number,
	allowance: PropTypes.number,
	payoff: PropTypes.number,
	editAcv: PropTypes.func.isRequired,
	editAllowance: PropTypes.func.isRequired,
	editPayoff: PropTypes.func.isRequired,
	tradeVendors: PropTypes.arrayOf(PropTypes.object).isRequired,
	editTradeVendors: PropTypes.func.isRequired,
	status: PropTypes.oneOf(Object.values(vehicleStatus)).isRequired,
};

export default TradeInfo;
