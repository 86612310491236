import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

import useCurrentVehicleStore from "stores/InventoryStore";
import InventoryOptionalEquipment from "../../selector/InventoryOptionalEquipment";
import InventoryStandardEquipment from "../../selector/InventoryStandardEquipment";
import { vehicleStatus, vehicleTypes } from "constants/Constants";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { getVehicleSettings, getNewStockNo } from "api/InventoryAPI";
import InventorySpecificEquipment from "../../selector/InventorySpecificEquipment";
import { useFromUser } from "stores/LocalStorageHelper";
import dayjs from "dayjs";
import TradeInfo from "../../../../deal/subFeatures/dealTabs/tradeTab/TradeInfo";
import shallow from "zustand/shallow";
import InventoryGpsInformation from "./InventoryGpsInformation";
import InventoryCustomEquipment from "../../selector/InventoryCustomEquipment";
import GeneralInfo from "./GeneralInfo";
import PurchaseInfo from "./PurchaseInfo";
import Advertising from "./Advertising";
import VehicleDetails from "./VehicleDetails";
import TrailerInfo from "./TrailerInfo";
import EngineInfo from "./EngineInfo";
import AxelInfo from "./AxelInfo";
import SiriusXMButton from "features/inventory/helperFunctions/SiriusXMButton";
import SemiTruck from "./SemiTruck";

const InventoryDetailsTab = () => {
	const locationID = useFromUser("locationID");
	const [inventorySettings, setInventorySettings] = useState({});

	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const today = dayjs().format("YYYY-MM-DD");

	// Vehicle store
	const {
		//		inventoryID,
		stockNo,
		vin,
		make,
		type,
		customEquip,
		selectedEquip,
		optionalEquip,
		standardEquip,
		specificationEquip,
		subModel,
		styleList,
		status,
		tradeLenderRecNum,
		acv,
		allowance,
		payoff,
		tradeLenderList,
		isTradeIn,
		makeList,
		editMakeList,
		editModelList,
		editTradeLenderList,
		editStockNo,
		editCustomEquip,
		editSelectedEquip,
		editCreatedOn,
		editCreatedByID,
		editModifiedOn,
		editModifiedByID,
		editOpenedBy,
		editTradeLenderRecNum,
		editAcv,
		editAllowance,
		editPayoff,
		setDecodedFields,
	} = useCurrentVehicleStore((state) => state, shallow);

	const getVehicleDefaults = () => {
		getVehicleSettings(locationID).then(
			(res) => {
				console.log(res);
				setInventorySettings(res.data.content);
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	useEffect(() => {
		getVehicleDefaults();
		editMakeList(JSON.parse(sessionStorage.getItem("makes")));
		editModelList(JSON.parse(sessionStorage.getItem("models")));
		// eslint-disable-next-line
	}, []);

	// Use the decoded list of styles to update vehicle details on style selection
	const setNewStocNo = () => {
		console.log(inventorySettings.isRunningStockNo);
		if (inventorySettings.isRunningStockNo) {
			getNewStockNo(locationID).then(
				(res) => {
					console.log(res);
					editStockNo(res);
				},
				(err) => {
					console.log(err);
				}
			);
		} else {
			editStockNo(vin.substring(vin.length - 6, vin.length));
		}
	};

	const changeDetails = () => {
		console.log(stockNo, vin);
		if (stockNo == null && vin != null && vin.length > 5) {
			console.log(inventorySettings);
			setNewStocNo();
		}
		console.log(styleList);
		const newStyle = styleList.find(
			(style) => subModel === style.data.subModel
		);
		setDecodedFields(newStyle.data);
	};

	useEffect(() => {
		if (styleList != null && styleList.length !== 0) {
			changeDetails();
		}
		//edit more information
		editModifiedOn(today);
		editModifiedByID(dcLoginID);
		editOpenedBy(lastName + ", " + firstName);
		editCreatedByID(dcLoginID);
		editCreatedOn(today);
		// eslint-disable-next-line
	}, [subModel]);

	const setModelsByMake = () => {
		console.log("models changing");
		const sessionModels = JSON.parse(sessionStorage.getItem("models"));
		const makeID = makeList.find((obj) => obj.obj.make === make);
		if (makeID) {
			const models = sessionModels.filter(
				(obj) =>
					obj.obj.makeID === makeID.obj.id && obj.obj.vehicleType === type
			);
			editModelList(models);
		} else {
			editModelList([]);
		}
	};

	useEffect(() => {
		setModelsByMake();
		// eslint-disable-next-line
	}, [make]);

	useEffect(() => {
		const sessionModels = JSON.parse(sessionStorage.getItem("models"));
		const sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
		console.log({ type });
		console.log(vehicleTypes.includes(type?.toUpperCase()));
		if (type !== "Other") {
			// find all models with selected type
			const models = sessionModels.filter((m) => m.obj.vehicleType === type);
			// reduce the list to simple list of unique makeIDs
			const makeIDs = [...new Set(models.map((model) => model.obj.makeID))];
			// find all makes with matching makeID
			const filtered = sessionMakes.filter((make) =>
				makeIDs.includes(make.obj.id)
			);

			editMakeList(filtered);
			setModelsByMake();
		} else {
			setModelsByMake();
			editMakeList(sessionMakes);
		}
		// eslint-disable-next-line
	}, [type]);

	return (
		<Col>
			{isTradeIn === true && (
				<TradeInfo
					tradeLenderRecNum={tradeLenderRecNum}
					editTradeLenderRecNum={editTradeLenderRecNum}
					acv={acv}
					allowance={allowance}
					payoff={payoff}
					editAcv={editAcv}
					editAllowance={editAllowance}
					editPayoff={editPayoff}
					type={status}
					tradeVendors={tradeLenderList}
					editTradeVendors={editTradeLenderList}
					status={status}
				/>
			)}
			<GeneralInfo />
			<hr className="mx-auto my-4" />
			<SiriusXMButton />
			<hr className="mx-auto my-4" />
			{vehicleTypes.includes(type?.toUpperCase()) && <TrailerInfo />}
			{vehicleTypes.includes(type?.toUpperCase()) && <SemiTruck />}
			{vehicleTypes.includes(type?.toUpperCase()) && <EngineInfo />}
			{vehicleTypes.includes(type?.toUpperCase()) && <AxelInfo />}
			{vehicleTypes.includes(type?.toUpperCase()) && (
				<hr className="mx-auto my-4" />
			)}
			<PurchaseInfo />
			<hr className="mx-auto my-4" />
			<Advertising />
			<hr className="mx-auto my-4" />
			<VehicleDetails />
			<hr className="mx-auto my-4" />
			<InventoryGpsInformation />
			<hr className="mx-auto my-4" />
			<InventorySpecificEquipment specificationEquip={specificationEquip} />
			<br />
			<InventoryStandardEquipment standardEquip={standardEquip} />
			<br />
			<InventoryOptionalEquipment
				changeHandler={editSelectedEquip}
				optionalEquip={optionalEquip}
				selectedEquip={selectedEquip}
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
			/>
			<br />
			<InventoryCustomEquipment
				changeHandler={editCustomEquip}
				selectedEquip={customEquip}
				readOnly={
					status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
				}
			/>
		</Col>
	);
};

export default InventoryDetailsTab;
