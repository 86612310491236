import React, { useContext, useEffect } from "react";
import NavBar from "components/tabs/TabsHolder";
import InventoryKbbTab from "./InventoryKbbTab";
import MMRForm from "./MMRForm";
import { MmrContext } from "../../../../../context/MMRContext";
import { Col, Container, Row } from "reactstrap";

const InventoryValuesTab = ({ inventoryID, vehicleKbbObj, showVinDecoder }) => {
	const tabs = ["KBB", "MMR"];
	const { vehicleMMRValues, setDefaultMMR } = useContext(MmrContext);

	useEffect(() => {
		console.log(vehicleMMRValues);
		if (!vehicleMMRValues) {
			setDefaultMMR();
		}
	}, []);

	const tabComponents = [
		<Container>
			<Row className="justify-content-center">
				<Col md={12} sm={6}>
					{/* className="mb-3 align-items-center" */}
					<InventoryKbbTab
						inventoryID={inventoryID}
						vehicleKbbObj={vehicleKbbObj}
						showVinDecoder={showVinDecoder}
					/>
				</Col>
			</Row>
		</Container>,
		<Container>
			<Row className="justify-content-center">
				<Col md={12} sm={6}>
					<MMRForm />
				</Col>
			</Row>
		</Container>,
	];
	return (
		<div style={{ minHeight: "1000px" }}>
			<div className="col-md-12">
				<NavBar
					navClass="nav-pills-primary justify-content-center nav nav-pills"
					tabs={tabs}
					tabComponents={tabComponents}
				/>
			</div>
		</div>
	);
};
export default InventoryValuesTab;
