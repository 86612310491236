import React from "react";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
//import useCurrentSaleStore from "stores/SaleStore";
import DetailPageHeader from "components/DetailPageHeader";

const DealTitle = () => {
  const { buyer } = useCurrentDealStore(
    (state) => ({
      buyer: state.buyer,
      stock: state.vehicle?.stockNo,
    }),
    shallow
  );
  const { firstName = "", lastName = "" } = buyer || "";

  // const { dealNumber } = useCurrentSaleStore(
  //   (state) => ({
  //     dealNumber: state.dealNumber,
  //   }),
  //   shallow
  // );

  const mainTitle = `${firstName} ${lastName}`;
  // const subtitles = [
  //   { label: "#", value: dealNumber },
  //   { label: "STOCK", value: stock },
  // ];

  return <DetailPageHeader mainTitle={mainTitle} />;
};

export default DealTitle;
