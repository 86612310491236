import React, { useState } from "react";
import ChildrenModal from "components/modals/ChildrenModal";
import VenderCompanyForm from "../../../../settings/subFeatures/vendor/VenderCompanyForm";
import {
    purchaseType,
    vehicleStatus,
    vendorTypesOBJ,
} from "constants/Constants";
import useCurrentVehicleStore from "stores/InventoryStore";
import shallow from "zustand/shallow";
import { Form, FormGroup, Label } from "reactstrap";
import formNavigationUtils from "utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import InputElement, {
    CurrencyInputElement,
    SelectorElement,
} from "../../../../forms/components/InputElement";
import Select from "react-select";

const PurchaseInfo = () => {
    // Vehicle store
    const {
        inventoryID,
        odometerIn,
        dateIn,
        datePaid,
        vendorRecNum,
        vendorsList,
        status,
        buyer,
        odometerOut,
        cost,
        tradeLenderRecNum,
        acv,
        allowance,
        payoff,
        tradeLenderList,
        paymentMethod,

        editPaymentMethod,
        editTradeLenderList,
        editBuyer,
        editOdometerIn,
        editDateIn,
        editDatePaid,
        editVendorRecNum,
        editOdometerOut,
        editCost,
        editTradeLenderRecNum,
        editAcv,
        editAllowance,
        editPayoff,
        editVendorsList,
    } = useCurrentVehicleStore((state) => state, shallow);

    const vehicleBuyersList = useCurrentVehicleStore((state) => state.buyersList);
    const [openAddLenderModal, setOpenAddLenderModal] = useState(false);
    const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);
    const tradeLendersListMap = tradeLenderList.map((obj) => {
        return {
            label: obj.name,
            value: obj.ID,
            vendor: obj,
        };
    });

    const [lenderOption, setLenderOption] = useState(
        tradeLendersListMap.find(
            (element) => element.value === tradeLenderRecNum
        ) || { value: null, label: "None" }
    );
    const selectLender = (option) => {
        const value = option.value;

        console.log(option);
        if (value === "ADD") {
            toggleLenderModal();
            console.log(value);
        } else {
            editTradeLenderRecNum(value);
            setLenderOption(option);
        }
    };

    // Vendor handlers
    const toggleVendorModal = () => setOpenAddVendorModal(!openAddVendorModal);
    const [openAddVendorModal, setOpenAddVendorModal] = useState(false);
    const vendorsListMap = vendorsList.map((obj) => {
        return {
            label: obj.name,
            value: obj.ID,
            vendor: obj,
        };
    });
    const [vendorOption, setVendorOption] = useState(
        vendorsListMap.find((element) => element.value === vendorRecNum) || {
            value: null,
            label: "None",
        }
    );
    // Updates store with the vendor selected or opens modal to add new vendor
    const selectVendor = (option) => {
        const value = option.value;

        if (value === "ADD") {
            toggleVendorModal();
        } else {
            editVendorRecNum(value);
            setVendorOption(option);
        }
    };
    // Define custom error messages for different error types
    const methods = useFormContext();

    return (
        <Form onKeyDown={formNavigationUtils}>
            <InputWrapper
                formTitle="Purchase Info"
                inputComponents={[
                    <Controller
                        key="buyer"
                        control={methods.control}
                        name="BuyerList"
                        defaultValue={buyer === null || buyer === "" ? "0" : buyer}
                        render={({ field }) => (
                            <FormGroup className="col-md-2">
                                <Label>Buyer</Label>
                                <CreatableSelect
                                    {...field}
                                    isDisabled={
                                        status === vehicleStatus.SOLD ||
                                        status === vehicleStatus.DELETED
                                    }
                                    value={{ value: buyer, label: buyer }}
                                    options={vehicleBuyersList.map((value) => ({
                                        value: value,
                                        label: value,
                                    }))}
                                    onChange={(e) => {
                                        console.log(e);
                                        editBuyer(e.value);
                                    }}
                                />
                            </FormGroup>
                        )}
                    />,
                    <div className="col-sm-3" key="vendor">
                        <Label>Vendor/Purchase</Label>
                        <Select
                            className="mb-3"
                            style={{ fontSize: "1rem" }}
                            isDisabled={
                                status === vehicleStatus.SOLD ||
                                status === vehicleStatus.DELETED
                            }
                            noOptionsMessage={() => "No vendors available"}
                            value={vendorOption}
                            options={[
                                ...vendorsListMap,
                                { value: "ADD", label: "Add new vendor" },
                            ]}
                            onChange={(e) => selectVendor(e)}
                        />
                        <ChildrenModal
                            modal={openAddVendorModal}
                            toggle={toggleVendorModal}
                            children={
                                <VenderCompanyForm
                                    useType={vendorTypesOBJ.VENDOR.value}
                                    vendorType={vendorTypesOBJ.VENDOR.label}
                                    toggle={toggleVendorModal}
                                    setOption={setVendorOption}
                                    list={vendorsList}
                                    editList={editVendorsList}
                                    editRecNum={editVendorRecNum}
                                />
                            }
                        />
                    </div>,
                    <InputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        {...methods}
                        value={dateIn}
                        onChange={(e) => editDateIn(e.target.value)}
                        name="dateIn"
                        label="Date In"
                        type="date"
                    />,
                    <InputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        {...methods}
                        value={datePaid}
                        onChange={(e) => editDatePaid(e.target.value)}
                        name="datePaid"
                        label="Date Paid"
                        type="date"
                    />,
                    <SelectorElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        {...methods}
                        name="purchaseType"
                        label="Method of Purchase"
                        value={paymentMethod}
                        onChange={(e) => editPaymentMethod(e.target.value)}
                        options={purchaseType}
                    />,
                    inventoryID === null && (
                        <CurrencyInputElement
                            readOnly={
                                status === vehicleStatus.SOLD ||
                                status === vehicleStatus.DELETED
                            }
                            value={cost}
                            name="cost"
                            label="Vehicle Cost"
                            type="text"
                            onChange={(e) => {
                                console.log(e);
                                editCost(e);
                            }}
                        />
                    ),
                    <InputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        {...methods}
                        value={odometerIn}
                        name="odometerIn"
                        label="Odometer In"
                        type="number"
                        onChange={(e) => {
                            editOdometerIn(e.target.value);
                        }}
                    />,
                    <InputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        {...methods}
                        value={odometerOut}
                        name="odometerOut"
                        label="Odometer Out"
                        type="number"
                        onChange={(e) => {
                            editOdometerOut(e.target.value);
                        }}
                    />,
                    <CurrencyInputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        value={allowance}
                        name="allowance"
                        label="Allowance"
                        type="number"
                        onChange={editAllowance}
                    />,
                    <CurrencyInputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        value={acv}
                        name="acv"
                        label="Acv"
                        type="number"
                        onChange={editAcv}
                    />,
                    <CurrencyInputElement
                        readOnly={
                            status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
                        }
                        value={payoff}
                        name="payoff"
                        label="Payoff"
                        type="number"
                        onChange={editPayoff}
                    />,
                    <div className="col-sm-3" key="lender">
                        <Label>LienHolder</Label>
                        <Select
                            className="mb-3"
                            noOptionsMessage={() => "No Lenders available"}
                            isDisabled={
                                status === vehicleStatus.SOLD ||
                                status === vehicleStatus.DELETED
                            }
                            value={lenderOption}
                            options={[
                                ...tradeLendersListMap,
                                { value: "ADD", label: "Add new lender" },
                            ]}
                            onChange={(e) => selectLender(e)}
                        />
                        <ChildrenModal
                            modal={openAddLenderModal}
                            toggle={toggleLenderModal}
                            children={
                                <VenderCompanyForm
                                    useType={vendorTypesOBJ.LENDER.value}
                                    vendorType={vendorTypesOBJ.LENDER.label}
                                    toggle={toggleLenderModal}
                                    setOption={setLenderOption}
                                    list={tradeLenderList}
                                    editList={editTradeLenderList}
                                    editRecNum={editTradeLenderRecNum}
                                />
                            }
                        />
                    </div>,
                ]}
            />
        </Form>
    );
};

export default PurchaseInfo;
