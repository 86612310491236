import React from "react";
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import NumberFormat from "react-number-format";
import ChildrenModal from "components/modals/ChildrenModal";
import shallow from "zustand/shallow";

/**
 * NetTradeModal Component
 * Displays the net trade details for the current deal.
 * @param {Object} props - Component properties
 * @param {boolean} props.modal - Flag to show or hide the modal
 * @param {function} props.toggle - Function to toggle the modal visibility
 * @returns {JSX.Element} The rendered component
 */
const NetTradeModal = ({ modal, toggle }) => {
	// Store values
	const { totalNetTrade } = useCurrentSaleStore(
		(state) => ({ totalNetTrade: state.totalNetTrade }),
		shallow
	);

	const { trades } = useCurrentDealStore(
		(state) => ({ trades: state.trades }),
		shallow
	);

	const columns = [
		{ value: "acv", label: "ACV" },
		{ value: "allowance", label: "Allowance" },
		{ value: "payoff", label: "Payoff" },
	];

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			modalTitle="Net Trade"
			size="lg"
		>
			<div>
				{trades.map((trade, index) => (
					<Card key={index}>
						<CardBody>
							<CardTitle tag="h5">
								{`${trade?.car?.year} ${trade?.car?.make} ${trade?.car?.model}`}
							</CardTitle>
							<Row>
								{columns.map((column) => (
									<React.Fragment key={column.value}>
										<Col>
											<Label>{column.label}</Label>
										</Col>
										<Col>
											<NumberFormat
												value={trade[column.value]}
												thousandSeparator
												decimalScale={2}
												fixedDecimalScale
												prefix={"$"}
												isNumericString
												displayType="text"
											/>
										</Col>
									</React.Fragment>
								))}
								<Col>
									<Label>Net Trade</Label>
								</Col>
								<Col>
									<NumberFormat
										value={trade.allowance - trade.payoff}
										thousandSeparator
										decimalScale={2}
										fixedDecimalScale
										prefix={"$"}
										isNumericString
										displayType="text"
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				))}
				<br />
				<Card className="card-plain text-center">
					<CardBody className="py-0">
						<h3>Total Net Trade</h3>
						<hr className="mb-2 w-75 mx-auto" />
						<NumberFormat
							value={totalNetTrade}
							thousandSeparator
							decimalScale={2}
							fixedDecimalScale
							prefix={"$"}
							isNumericString
							displayType="text"
							className="h3"
						/>
					</CardBody>
				</Card>
			</div>
		</ChildrenModal>
	);
};

export default NetTradeModal;
