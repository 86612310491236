import React, { useState } from "react";
import { Col, Row, Table, Button } from "reactstrap";

import useCurrentDealStore from "stores/DealStore";
import {
	confirmDeleteAlert,
	deleteFailAlert,
	deleteSuccessAlert,
} from "utils/alertUtils";
import ChildrenModal from "components/modals/ChildrenModal";
import DMVFeeForms from "./DMVFeeForm";
import { deleteMiscSellerFee } from "api/DealAPI";
import { showApiError } from "utils/errorRoutingUtils";
import shallow from "zustand/shallow";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import useCurrentSaleStore from "stores/SaleStore";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const DMVFeesTable = () => {
	const { miscDMVFees, editMiscDMVFees, dmvFeeTypes, calculate } =
		useCurrentDealStore(
			(state) => ({
				miscDMVFees: state.miscDMVFees,
				editMiscDMVFees: state.editMiscDMVFees,
				dmvFeeTypes: state.dmvFeeTypes,
				calculate: state.calculate,
			}),
			shallow
		);
	const { totalDMVFees } = useCurrentSaleStore(
		(state) => ({
			totalDMVFees: state.totalDMVFees,
		}),
		shallow
	);

	// convert dmvFeeTypes into optionlist for selectorElement
	const optionList = [];
	for (const key in dmvFeeTypes) {
		optionList.push({
			label: dmvFeeTypes[key].description,
			value: dmvFeeTypes[key].type,
		});
	}

	const DMVFeesHead = [
		"#",
		"Type",
		"Amount",
		"Company",
		"Taxable",
		"Cost",
		"Actions",
	];

	const removeDMVFee = (ID, index) => {
		console.log("Removing misc dmv fee with ID: " + ID);
		if (ID) {
			deleteMiscSellerFee(ID).then(
				(response) => {
					console.log(response);
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
		let obj = [];
		if (ID !== null) {
			obj = miscDMVFees.filter((item) => item.ID !== ID);
		} else {
			obj = miscDMVFees.filter((item) => item.currentIndex !== index);
		}
		editMiscDMVFees(obj);
		setCurrentIndex(obj.length);
		calculate();
		deleteSuccessAlert();
	};

	const [currentIndex, setCurrentIndex] = useState(miscDMVFees.length);
	const [showAddDMVFee, setShowAddDMVFee] = useState(false);
	const toggleAddDMVFee = () => setShowAddDMVFee(!showAddDMVFee);

	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removeDMVFee, currentID, currentIndex);
	};

	const tableHead = DMVFeesHead.map((key) => <th>{key}</th>);

	const rows = miscDMVFees.map((key, index) => (
		<tr key={index}>
			<th>{index + 1}</th>
			<td>{optionList.filter((row) => row.value === key.type)[0]?.label}</td>
			<td>{<CurrencyDisplayElement val={key.amount} />}</td>
			<td>{key.company}</td>
			<td>{key.isTaxable ? "Yes" : "No"}</td>
			<td>{<CurrencyDisplayElement val={key.cost} />}</td>
			<td>
				<div className="d-flex justify-content-center">
					<div
						className="btn btn-md btn-primary"
						onClick={() => {
							setCurrentIndex(index);
							console.log(key.isTaxable);
							toggleAddDMVFee();
						}}
					>
						<i className="nc-icon nc-align-center"></i>
						<span className="noMobile">Edit</span>
					</div>
					<div
						className="btn btn-md btn-link btn-link-danger ml-2"
						onClick={() => {
							console.log(key);
							console.log(key.ID + " " + index);
							toggleModal(key.ID, key.currentIndex);
						}}
					>
						X
					</div>
				</div>
			</td>
		</tr>
	));
	return (
		<>
			<ChildrenModal
				modal={showAddDMVFee}
				toggle={toggleAddDMVFee}
				modalTitle="DMV Fee"
				size="lg"
			>
				<DMVFeeForms
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggleAddDMVFee}
					optionList={optionList}
				/>
			</ChildrenModal>
			{
				<>
					<Row className="my-3">
						<Col>
							<Button
								className="btn btn-primary btn-sm"
								onClick={toggleAddDMVFee}
							>
								<i className="nc-icon nc-simple-add" />
								Add DMV Fee
							</Button>
						</Col>
					</Row>
					{rows.length > 0 && (
						<Card className="my-3">
							<CardBody className="pb-0">
								<div style={{ overflowX: "auto" }}>
									<Table striped hover>
										<thead>
											<tr>{tableHead}</tr>
										</thead>
										<tbody>{rows}</tbody>
									</Table>
								</div>
							</CardBody>
						</Card>
					)}

					<Card className="card-plain">
						<Row className="mx-0 pb-2 justify-content-center">
							<Col sm="6" className="text-center">
								<h4 className="mt-0 mb-1">Total DMV Fees</h4>
							</Col>
							<div className="w-100">
								<hr className="mb-2 w-50 mx-auto" />
							</div>
							<Col sm="6" className="text-center">
								<h3 className="mt-0 mb-1">
									${parseFloat(totalDMVFees).toFixed(2)}
								</h3>
							</Col>
						</Row>
					</Card>
				</>
			}
		</>
	);
};
export default DMVFeesTable;
