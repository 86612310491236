import React, { useEffect, useRef } from "react";
import MultiImageErrors from "./MultiImageErrors";
import MultiImageFiles from "./MultiImageFiles";
import MultiImageUploadButton from "./MultiImageUploadButton";
import UploadImageIcon from "../UploadImageIcon";
import { failAlert } from "../../../../../utils/alertUtils";
import { useMultiImageData } from "./MultiImageContext";
import { actualFileType } from "../ImageUtils";
import "assets/css/multi-image.css";

const MultiImageUploader = ({
  totalFiles,
  maxFiles,
  handleUpload,
  hideUploadButton,
}) => {
  const {
    imageKeys,
    setImageKeys,
    setImageSources,
    fileUploads,
    setFileUploads,
    setUploadErrors,
  } = useMultiImageData();

  const fileInputRef = useRef(null);

  const updateInput = () => {
    setUploadErrors([]);
    fileInputRef.current.click();
  };

  // Called when an image is selected for upload
  const uploadImage = (e) => {
    e.preventDefault();

    const inputFiles = e.target.files;
    const allowedUploads = imageKeys.length + totalFiles + inputFiles.length;

    if (allowedUploads > maxFiles) {
      if (maxFiles - totalFiles - imageKeys.length <= 0) {
        failAlert(
          `A maximum of ${maxFiles} images are allowed. You cannot select any more images to upload.`
        );
      } else {
        failAlert(
          `A maximum of ${maxFiles} images are allowed. You are allowed to upload ${
            maxFiles - totalFiles - imageKeys.length
          } more image(s).`
        );
      }
      return;
    }

    Object.entries(inputFiles).forEach((arr) => {
      const reader = new FileReader();
      const currentFile = arr[1];

      if (currentFile != null) {
        reader.readAsArrayBuffer(currentFile);
        reader.onloadend = (load) => fileLoaded(load, currentFile);
        reader.onerror = (err) => fileError(err, currentFile);
      }
    });
  };

  // Callback for when a file has successfully been loaded into the input element.
  // Verifies that file is JPEG or PNG
  const fileLoaded = (e, upload) => {
    const arr = new Uint8Array(e.target.result).subarray(0, 4);
    let header = "";

    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }

    const acceptedFileType = actualFileType(header);

    if (acceptedFileType === upload.type) {
      const reader = new FileReader();

      reader.readAsDataURL(upload);
      reader.onload = (e) => fileAccepted(e, upload);
    } else {
      setUploadErrors((prev) => ({
        ...prev,
        [upload.name]: ["Only JPEG/JPG and PNG files are allowed"],
      }));
    }
  };

  // Handler for processing an accepted image file.
  const fileAccepted = (e, upload) => {
    setImageSources((prev) => ({
      ...prev,
      [upload.name]: URL.createObjectURL(upload),
    }));
    setFileUploads((prev) => ({
      ...prev,
      [upload.name]: upload,
    }));
  };

  // Called when there is an error in the file upload process
  const fileError = (e, upload) => {
    const errorMessage =
      e?.target?.error?.name === "NotReadableError"
        ? "File could not be read, please try again"
        : e?.target?.error?.name || "Unexpected error";

    setUploadErrors((prev) => ({
      ...prev,
      [upload.name]: [errorMessage],
    }));
  };

  // Update image keys with the uploaded files
  useEffect(() => {
    const keys = Object.keys(fileUploads);

    setImageKeys(keys);
    // eslint-disable-next-line
  }, [fileUploads]);

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="multi-image-wrapper">
          <MultiImageFiles />
          <div
            role="button"
            className="multi-image-file-upload d-flex justify-content-center align-items-center"
            onClick={updateInput}
          >
            <UploadImageIcon />
            <span>ADD IMAGE</span>
            <input
              ref={fileInputRef}
              type="file"
              onChange={uploadImage}
              multiple
              value=""
              accept=".png, .jpeg, .jpg, .webp"
            />
          </div>
          <MultiImageErrors />
          <MultiImageUploadButton
            handleUpload={handleUpload}
            hideUploadButton={hideUploadButton}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiImageUploader;
