// Constants for all the note components in the "/note" folder
export const ADD = "ADD";
export const SAVE = "SAVE";
export const ASC = "asc";
export const DSC = "dsc";
export const CREATED_ON = "createdOn";
export const CREATED_BY = "createdBy";
export const MODIFIED_ON = "modifiedOn";
export const MODIFIED_BY = "modifiedBy";
export const TIME_FORMAT = "MMM D, YYYY [at] h:mm:ss a";
