import React from "react";
import NewUserForm from "./NewUserForm";

import * as yup from "yup";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

const NewUser = () => {
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			// eslint-disable-next-line
			min: "Must be at least ${min} characters long",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		accessCode: yup.string().required(),
		dealerID: yup.string().required(),
		locationID: yup.string().required(),
		email: yup.string().required(),
		username: yup.string().required().min(3).max(50),
		password: yup
			.string()
			.required()
			.matches(
				/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_+=(){}[~`!@#$%^&*()\-_+=(){}[\]:;<>,.?\\/|'"]).{8,22}/g,
				{ message: "Password requirements not met" }
			),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), null], "Must be the same as password"),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<NewUserForm />
		</FormProvider>
	);
};

export default NewUser;
