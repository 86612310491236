import React, { memo, useMemo, useState } from "react";
import Select from "react-select";
import classnames from "classnames";
import matchSorter from "match-sorter";
import {
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table";
import { Button, FormGroup, Input } from "reactstrap";

/**
 * ReactTable component for rendering a table with filtering, sorting, and pagination.
 * @param {Object} props - Component props.
 * @param {Array} props.columns - Column configuration.
 * @param {Array} props.data - Data to display in the table.
 */
const MakeModelTable = memo(({ columns, data }) => {
	const [numberOfRows, setNumberOfRows] = useState({
		value: 100,
		label: "100",
	});

	const [pageSelect, setPageSelect] = useState({
		value: 0,
		label: "Page 1",
	});

	const fuzzyTextFilterFn = (rows, id, filterValue) => {
		return matchSorter(rows, filterValue, {
			keys: [(row) => row.values[id]],
		});
	};

	// Let the table remove the filter if the string is empty
	fuzzyTextFilterFn.autoRemove = (val) => !val;

	// Define a custom filter filter function!
	const filterGreaterThan = (rows, id, filterValue) => {
		return rows.filter((row) => {
			const rowValue = row.values[id];
			return rowValue >= filterValue;
		});
	};

	// This is an autoRemove method on the filter function that
	// when given the new filter value and returns true, the filter
	// will be automatically removed. Normally this is just an undefined
	// check, but here, we want to remove the filter if it's not a number
	filterGreaterThan.autoRemove = (val) => typeof val !== "number";

	//  Define a default UI for filtering
	const DefaultColumnFilter = ({
		column: { filterValue, preFilteredRows, setFilter },
	}) => {
		const count = preFilteredRows.length;

		return (
			<FormGroup>
				<Input
					value={filterValue || ""}
					placeholder={`Search ${count} records...`}
					type="text"
					onChange={(e) => {
						setFilter(e.target.value || undefined);
					}}
				/>
			</FormGroup>
		);
	};

	const filterTypes = useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// "startWith"
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id];

					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		nextPage,
		pageOptions,
		previousPage,
		canPreviousPage,
		canNextPage,
		setPageSize,
		gotoPage,
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: {
				pageSize: 3,
				pageIndex: 0,
			},
			getRowId: (row, relativeIndex) => {
				return row.id || relativeIndex;
			},
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect
	);

	// We don't want to render all of the rows for this example, so cap
	// it for this use case
	// const firstPageRows = rows.slice(0, 10);
	let pageSelectData = Array.apply(null, Array(pageOptions.length));
	const numberOfRowsData = [5, 10, 20, 25, 50, 100];

	return (
		<>
			{/* <div
				style={{ gap: "18px" }}
				className="d-flex justify-content-center px-2 mb-4"
			>
				<div>
					<Button
						onClick={() => previousPage()}
						readOnly={!canPreviousPage}
						className="btn-link btn-sm"
					>
						<i className="nc-icon nc-minimal-left text-center" />
					</Button>
				</div>
				<div style={{ gap: "18px" }} className="d-flex no-wrap ">
					<Select
						className="react-select primary d-block"
						classNamePrefix="react-select"
						name="pageSelect"
						value={pageSelect}
						onChange={(value) => {
							gotoPage(value.value);
							setPageSelect(value);
						}}
						options={pageSelectData.map((_prop, key) => {
							return {
								value: key,
								label: "Page " + (key + 1),
							};
						})}
						placeholder="Choose Page"
					/>
					<Select
						className="react-select primary"
						classNamePrefix="react-select"
						name="numberOfRows"
						value={numberOfRows}
						onChange={(value) => {
							setPageSize(value.value);
							setNumberOfRows(value);
						}}
						options={numberOfRowsData.map((prop) => {
							return {
								value: prop,
								label: prop,
							};
						})}
						placeholder="Choose Rows"
					/>
				</div>

				<div className="actions-right">
					<Button
						onClick={() => nextPage()}
						readOnly={!canNextPage}
						className="btn-link btn-sm"
					>
						<i className="nc-icon nc-minimal-right  text-center" />
					</Button>
				</div>
			</div>
 */}
			<div className="rt-resizable-header-content ReactTable -striped -highlight">
				<table {...getTableProps()} className="rt-table">
					<thead className="rt-thead -header">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
								{headerGroup.headers.map((column) => (
									<th
										key={column.id}
										className={classnames("rt-th rt-resizable-header", {
											"-cursor-pointer": column.canFilter,
											"-sort-asc": column.isSorted && !column.isSortedDesc,
											"-sort-desc": column.isSorted && column.isSortedDesc,
										})}
									>
										<div
											className="rt-resizable-header-content"
											{...(column.canFilter && {
												...column.getHeaderProps(column.getSortByToggleProps()),
											})}
										>
											{column.render("Header")}
										</div>
										{/* Render the columns filter UI */}
										{column.canFilter ? column.render("Filter") : null}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()} className="rt-tbody">
						{page.map((row, i) => {
							prepareRow(row);

							return (
								<tr
									className={classnames(
										"rt-tr",
										{ " -odd": i % 2 === 0 },
										{ " -even": i % 2 === 1 }
									)}
								>
									{row.cells.map((cell) => (
										<td
											{...cell.getCellProps([
												{
													style: cell.column.style,
													className: "rt-td singleCell",
													onClick: (e) => {
														// If this cell is interactive, stop propagation
														if (cell.column.id === "actions") {
															e.stopPropagation();
														}
													},
												},
											])}
										>
											{cell.render("Cell")}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div
				style={{ gap: "18px" }}
				className="justify-content-center d-flex mt-4"
			>
				<div>
					<Button
						onClick={() => previousPage()}
						readOnly={!canPreviousPage}
						className="btn-link btn-sm"
					>
						<i className="nc-icon nc-minimal-left text-center" />
					</Button>
				</div>
				<div style={{ gap: "18px" }} className="d-flex no-wrap">
					<Select
						className="react-select primary d-block"
						classNamePrefix="react-select"
						name="pageSelect"
						value={pageSelect}
						onChange={(value) => {
							gotoPage(value.value);
							setPageSelect(value);
						}}
						options={pageSelectData.map((_prop, key) => {
							return {
								value: key,
								label: "Page " + (key + 1),
							};
						})}
						placeholder="Choose Page"
					/>
					<Select
						className="react-select primary"
						classNamePrefix="react-select"
						name="numberOfRows"
						value={numberOfRows}
						onChange={(value) => {
							setPageSize(value.value);
							setNumberOfRows(value);
						}}
						options={numberOfRowsData.map((prop) => {
							return {
								value: prop,
								label: prop,
							};
						})}
						placeholder="Choose Rows"
					/>
				</div>

				<div className="">
					<Button
						onClick={() => nextPage()}
						readOnly={!canNextPage}
						className="btn-link btn-sm"
					>
						<i className="nc-icon nc-minimal-right  text-center" />
					</Button>
				</div>
			</div>
		</>
	);
});
export default MakeModelTable;
