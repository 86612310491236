import React, { useState, useEffect } from "react";
import {
	Card,
	CardBody,
	CardTitle,
	FormGroup,
	Label,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	CustomInput,
} from "reactstrap";

const ViewSettings = () => {
	const [fontSize, setFontSize] = useState("14px"); // Set default to 14px
	const [fontWeight, setFontWeight] = useState("bold"); // Set default to normal
	const [darkMode, setDarkMode] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [weightDropdownOpen, setWeightDropdownOpen] = useState(false);

	// Load preferences from localStorage on component mount
	useEffect(() => {
		const savedFontSize = localStorage.getItem("fontSize");
		const savedFontWeight = localStorage.getItem("fontWeight");
		const savedDarkMode = localStorage.getItem("darkMode") === "true";

		if (savedFontSize) {
			setFontSize(savedFontSize);
			document.documentElement.style.setProperty(
				"--base-font-size",
				savedFontSize
			);
		} else {
			document.documentElement.style.setProperty("--base-font-size", "14px");
		}

		if (savedFontWeight) {
			setFontWeight(savedFontWeight);
			document.documentElement.style.setProperty(
				"--base-font-weight",
				savedFontWeight
			);
		} else {
			document.documentElement.style.setProperty(
				"--base-font-weight",
				"normal"
			);
		}

		setDarkMode(savedDarkMode);
	}, []);

	const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
	const toggleWeightDropdown = () => setWeightDropdownOpen(!weightDropdownOpen);

	const changeFontSize = (size) => {
		setFontSize(size);
		localStorage.setItem("fontSize", size);
		document.documentElement.style.setProperty("--base-font-size", size);
	};

	const changeFontWeight = (weight) => {
		setFontWeight(weight);
		localStorage.setItem("fontWeight", weight);
		document.documentElement.style.setProperty("--base-font-weight", weight);
	};

	const toggleDarkMode = () => {
		const newDarkMode = !darkMode;
		setDarkMode(newDarkMode);
		localStorage.setItem("darkMode", newDarkMode);
	};

	return (
		<div style={{ minWidth: "600px" }}>
			<Card>
				<CardBody>
					<CardTitle tag="h3">
						<span role="img" aria-label="desktop computer">
							🖥️
						</span>{" "}
						Default View Settings
					</CardTitle>
					<FormGroup className="mt-2">
						<Label for="fontSizeSelect">Font Size</Label>
						<Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
							<DropdownToggle caret>{fontSize}</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => changeFontSize("12px")}>
									12px
								</DropdownItem>
								<DropdownItem onClick={() => changeFontSize("14px")}>
									14px
								</DropdownItem>
								<DropdownItem onClick={() => changeFontSize("16px")}>
									16px
								</DropdownItem>
								<DropdownItem onClick={() => changeFontSize("18px")}>
									18px
								</DropdownItem>
								<DropdownItem onClick={() => changeFontSize("20px")}>
									20px
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</FormGroup>
					<FormGroup>
						<Label for="fontWeightSelect">Font Weight</Label>
						<Dropdown isOpen={weightDropdownOpen} toggle={toggleWeightDropdown}>
							<DropdownToggle caret>{fontWeight}</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={() => changeFontWeight("400")}>
									Normal
								</DropdownItem>
								<DropdownItem onClick={() => changeFontWeight("900")}>
									Bold
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</FormGroup>
					<FormGroup>
						<Label for="darkModeToggle">Dark Mode</Label>
						<CustomInput
							type="switch"
							id="darkModeToggle"
							name="darkModeToggle"
							checked={darkMode}
							onChange={toggleDarkMode}
						/>
					</FormGroup>
				</CardBody>
			</Card>
		</div>
	);
};

export default ViewSettings;
