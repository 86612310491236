import React from "react";
import { FormProvider } from "react-hook-form";
import VendorSettings from "./VendorSettings";
import NavBar from "components/tabs/TabsHolder";

const VendorSettingTab = () => {
	const tabs = [
		"Vendor",
		"Warranty",
		"Lender",
		"Recon",
		"Aftermarket",
		"Flooring",
		"Gap/Insurance",
		"Shipping",
	];

	const tabComponents = [
		<VendorSettings useType={10} vendorType="Vendor" />,
		<VendorSettings useType={20} vendorType="Warranty" />,
		<VendorSettings useType={30} vendorType="Lender" />,
		<VendorSettings useType={50} vendorType="Recon" />,
		<VendorSettings useType={60} vendorType="Aftermarket" />,
		<VendorSettings useType={70} vendorType="Flooring" />,
		<VendorSettings useType={90} vendorType="Gap" />,
		<VendorSettings useType={40} vendorType="Shipping" />,
	];
	return (
		<div>
			<FormProvider>
				<NavBar
					navClass="nav-pills-primary justify-content-center nav nav-pills"
					tabs={tabs}
					tabComponents={tabComponents}
				/>
			</FormProvider>
		</div>
	);
};

export default VendorSettingTab;
