import React from "react";
import PropTypes from "prop-types";
import { Col, Button, Modal, ModalHeader, ModalBody, Row } from "reactstrap";

/**
 * ActionBar component to display a set of action buttons.
 * Adjusts layout based on screen size, displaying actions in a modal on mobile devices.
 *
 * @component
 * @param {object} props - The component props
 * @param {JSX.Element} props.children - The action buttons to display
 * @param {boolean} props.isMobile - Flag indicating if the view is on a mobile device
 * @param {function} props.toggleActionsModal - Function to toggle the actions modal
 * @param {boolean} props.actionsModal - Flag indicating if the actions modal is open
 * @param {string} props.modalTitle - The title of the actions modal
 * @returns {JSX.Element} The rendered ActionBar component
 */
const ActionBar = ({
	children,
	isMobile,
	toggleActionsModal,
	actionsModal,
	modalTitle,
}) => {
	return (
		<Col>
			{isMobile ? (
				<>
					<div
						className="d-flex justify-content-end pt-3"
						style={{ marginTop: "55px" }}
					>
						<Button
							className="btn btn-sm margin-right btn-link"
							onClick={toggleActionsModal}
						>
							<span aria-label="gear emoji" role="img">
								⚙️ &nbsp;
							</span>
							Actions
						</Button>
						<Modal
							size="md"
							className="d-flex align-items-center h-100"
							isOpen={actionsModal}
							toggle={toggleActionsModal}
						>
							<ModalHeader toggle={toggleActionsModal}>
								{modalTitle}
							</ModalHeader>
							<ModalBody className="d-flex flex-column" style={{ gap: "12px" }}>
								{children}
							</ModalBody>
						</Modal>
					</div>
					<hr className="w-100" />
				</>
			) : (
				<Row
					className="justify-content-center flex-row content"
					style={{ gap: "4px" }}
				>
					{children}
				</Row>
			)}
		</Col>
	);
};

ActionBar.propTypes = {
	children: PropTypes.node.isRequired,
	isMobile: PropTypes.bool.isRequired,
	toggleActionsModal: PropTypes.func.isRequired,
	actionsModal: PropTypes.bool.isRequired,
	modalTitle: PropTypes.string.isRequired,
};

export default ActionBar;
