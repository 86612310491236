import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { NavLink, NavItem } from "reactstrap";

/**
 * NavTab component represents a single tab in a navigation bar.
 * @param {Object} props - The component's props.
 * @param {string} props.tabName - The name of the tab to be displayed.
 * @param {Function} props.onClick - The function to be called when the tab is clicked.
 * @param {string} props.setActive - The CSS class to indicate whether the tab is active.
 */
function NavTab({ tabName, onClick, setActive }) {
	return (
		<NavItem>
			{/* Render the tab as a NavLink */}
			<NavLink
				href="#"
				data-toggle="tab"
				role="tablist"
				className={setActive}
				onClick={onClick}
				style={{ boxShadow: "none", border: "none" }}
			>
				{tabName}
			</NavLink>
		</NavItem>
	);
}

// Define PropTypes for the NavTab component
NavTab.propTypes = {
	tabName: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	setActive: PropTypes.string.isRequired,
};

export default NavTab;
