import React, { useState } from "react";
import { Card, Col, Form, Row, Input, CardBody } from "reactstrap";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import formNavigationUtils from "utils/formNavigationUtils";
import { CheckBoxElement } from "../../../forms/components/InputElement";
import OpenTotalModal from "../sale/OpenTotalModal";

/**
 * ContractInfo Component
 * Handles the display and input of contract information details.
 * @param {Object} props - Component properties
 * @param {boolean} props.modal - Flag to show or hide the modal
 * @param {function} props.toggle - Function to toggle the modal visibility
 * @returns {JSX.Element} The rendered component
 */
const ContractInfo = ({ modal, toggle }) => {
	const {
		contractInfo,
		cancellationAmt,
		cancellationAccepted,
		restockingFee,
		type,
		editContractInfo,
		editCancellationAccepted,
	} = useCurrentDealStore(
		(state) => ({
			editContractInfo: state.editContractInfo,
			contractInfo: state.contractInfo,
			cancellationAmt: state.contractInfo.cancellationAmt,
			cancellationAccepted: state.contractInfo.cancellationAccepted,
			restockingFee: state.contractInfo.restockingFee,
			type: state.deal.type,
			editCancellationAccepted: state.editCancellationAccepted,
		}),
		shallow
	);

	/**
	 * Handles editing the contract information.
	 * @param {string} field - The field to update
	 * @param {any} val - The new value for the field
	 */
	const editContractInfoObj = (field, val) => {
		let temp = { ...contractInfo };
		temp[field] = val;
		editContractInfo(temp);
	};

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Contract Info Details"
		>
			<Form onKeyDown={formNavigationUtils}>
				<Card>
					<CardBody className="px-0">
						<Col className="mb-3">
							<CheckBoxElement
								disableValidation
								checked={cancellationAccepted}
								onChange={() => editCancellationAccepted(!cancellationAccepted)}
								name="cancellationAccepted"
								label="Accepted"
								labelClass="pl-4"
							/>
						</Col>
						<Row>
							<ContractInfoField
								label="Cancellation Fee"
								name="cancellationAmt"
								value={cancellationAmt}
								readOnly={type === DealStatus.SOLD}
								onValueChange={(values) =>
									editContractInfoObj("cancellationAmt", values.floatValue)
								}
							/>
							<ContractInfoField
								label="Restocking Fee"
								name="restockingFee"
								value={restockingFee}
								readOnly={type === DealStatus.SOLD}
								onValueChange={(values) =>
									editContractInfoObj("restockingFee", values.floatValue)
								}
							/>
						</Row>
					</CardBody>
				</Card>
			</Form>
		</ChildrenModal>
	);
};

/**
 * ContractInfoField Component
 * A reusable field component for contract info details.
 * @param {Object} props - Component properties
 * @param {string} props.label - The label of the field
 * @param {string} props.name - The name of the field
 * @param {number} props.value - The value of the field
 * @param {boolean} props.readOnly - Flag to make the field read-only
 * @param {function} props.onValueChange - Function to handle value changes
 * @returns {JSX.Element} The rendered component
 */
const ContractInfoField = ({ label, name, value, readOnly, onValueChange }) => (
	<Col sm="6" className="mb-2 text-center">
		<div className="btn btn-md btn-primary disabled w-100">{label}</div>
		<NumberFormat
			name={name}
			readOnly={readOnly}
			value={value}
			decimalScale={2}
			prefix={"$"}
			className="text-center"
			fixedDecimalScale
			isNumericString
			customInput={Input}
			onFocus={(e) => e.target.select()}
			onValueChange={onValueChange}
		/>
	</Col>
);

/**
 * ContractInfoDetail Component
 * Displays contract info details and allows interaction with the contract info modal.
 * @returns {JSX.Element} The rendered component
 */
const ContractInfoDetail = () => {
	const [openModal, setOpenModal] = useState(false);

	const { cancellationAmt, cancellationAccepted } = useCurrentDealStore(
		(state) => ({
			cancellationAmt: state.contractInfo.cancellationAmt,
			cancellationAccepted: state.contractInfo.cancellationAccepted,
		}),
		shallow
	);

	const toggle = () => {
		setOpenModal(!openModal);
	};

	return (
		<Row className="align-items-center">
			<ContractInfo modal={openModal} toggle={toggle} />
			<Col size="6" className="px-0">
				<OpenTotalModal
					btnClass="w-100 btn-md btn btn-primary"
					label="Contract Info"
					toggle={toggle}
				/>
			</Col>
			<Col size="6" className="pr-0 pl-2">
				<NumberFormat
					value={cancellationAccepted ? cancellationAmt : 0}
					thousandSeparator
					decimalScale={2}
					fixedDecimalScale
					prefix={"$"}
					isNumericString
					readOnly
					customInput={Input}
				/>
			</Col>
		</Row>
	);
};

export default ContractInfoDetail;
