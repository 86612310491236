import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, Form, Col, Container } from "reactstrap";
import { lazy, mixed, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import mime from "mime";

import { uploadExternalCustomerAttachments } from "api/AttachmentsAPI";

import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { ATTACHMENT_CATEGORY } from "constants/Constants";
import InputElement, {
	SelectorElement,
} from "features/forms/components/InputElement";
import Swal from "sweetalert2";

const CustomerUploadForm = () => {
	// Local states
	const [uploads, setUploads] = useState([]);
	const location = useLocation();

	// Calls api to upload every attachment
	// If at least one attachment was successfully uploaded, refresh the table and close modal
	// If at least one upload error was returned, refresh table but don't close modal
	const uploadAttachment = () => {
		const params = new URLSearchParams(location.search);
		const customerID = params.get("customerID");
		const uploadCode = params.get("uploadCode");
		const formData = new FormData();

		// Add each file to the form data and modify file info object
		const fileInfo = uploads.map((obj) => {
			formData.append("files", obj);
			return {
				fileName: obj.name,
				description: "",
				category: obj.category,
				type: "customer",
				relatedID: customerID,
			};
		});
		formData.append(
			"fileInfo",
			new Blob([JSON.stringify(fileInfo)], {
				type: "application/json",
			})
		);
		uploading();
		uploadExternalCustomerAttachments(uploadCode, customerID, formData).then(
			(res) => {
				Swal.close();
				saveSuccessAlert(
					"Thank you, the dealership has been notified of your uploads!"
				);
				setUploads([]);
			},
			(err) => {
				Swal.close();
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	// Validation for each input field
	const getSchemaObj = (obj) => {
		let schemaObj = {};
		Object.keys(obj).map((key) => {
			if (key.includes("uploadFile")) {
				return (schemaObj = {
					...schemaObj,
					[key]: string().matches(/^([a-zA-Z0-9()\s._-]+)$/, {
						message:
							"Invalid File name. Cannot contain more than 1 consecutive - _ . or space.",
					}),
				});
			} else if (key === "uploadCategory") {
				return (schemaObj = {
					...schemaObj,
					[key]: string().required("Required"),
				});
			}

			return (schemaObj = { ...schemaObj, [key]: mixed() });
		});

		return schemaObj;
	};

	// Define the fields to put validations on
	const schema = lazy((obj) => object(getSchemaObj(obj)));

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<div className="login-page">
			<Container className="py-5">
				<Col className="vertical-align">
					<Card>
						<CardBody>
							<FormProvider {...methods}>
								<Form>
									<FileInfo uploads={uploads} setUploads={setUploads} />
									<FileUploader maxFileSize={10} setUploads={setUploads} />
									<div className="d-flex justify-content-center align-items-center mb-3">
										<Button
											readOnly={uploads.length === 0}
											onClick={methods.handleSubmit(uploadAttachment)}
										>
											Submit
										</Button>
									</div>
								</Form>
							</FormProvider>
						</CardBody>
					</Card>
				</Col>
			</Container>
		</div>
	);
};

export default CustomerUploadForm;

// Returns UI for users to add info about each file or remove a file from upload
const FileInfo = ({ uploads, setUploads }) => {
	const methods = useFormContext();
	return uploads.map((obj, i) => (
		<div className="d-flex justify-content-center align-items-center" key={i}>
			<SelectorElement
				{...methods}
				colSize="col-4"
				value={obj.category}
				onChange={(e) => {
					const value = e.target.value;
					setUploads((prev) => {
						console.log("updating category for " + obj.name);
						let modifiedUploads = [...prev];
						modifiedUploads[i].category = value;
						return modifiedUploads;
					});
				}}
				name={`uploadCategory`}
				label="Category"
				options={ATTACHMENT_CATEGORY}
			/>
			<InputElement
				{...methods}
				colSize="col-6"
				readOnly
				value={obj.name}
				name={`uploadFile_${i}`}
				label="Name"
			/>
			<div
				className="red-close-icon"
				onClick={() => {
					setUploads((prev) => {
						console.log("removing " + obj.name);
						let modifiedUploads = [...prev];
						delete modifiedUploads[i];
						return modifiedUploads;
					});
				}}
			>
				<i className="fa fa-close" />
			</div>
		</div>
	));
};

const uploading = () => {
	Swal.fire({
		title: "Uploading...",
		showConfirmButton: false,
		willOpen() {
			Swal.showLoading();
		},
		didClose() {
			Swal.hideLoading();
		},
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
	});
};

/**
 * Component used to upload file(s). All component props are required.
 *
 * @param {number} maxFileSize Max allowed file size of upload, in MBs
 * @param {React.SetStateAction} setUploads State setter for setting uploads
 */
const FileUploader = ({ maxFileSize, setUploads }) => {
	// Local states
	const fileInputRef = useRef(null);
	const [uploadErrors, setUploadErrors] = useState([]);

	const acceptArray = [
		"image/png",
		"image/jpeg",
		"text/plain",
		"text/csv",
		"application/pdf",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/msword",
	];

	// Calls the onClick function of the file input element
	const updateInput = () => {
		fileInputRef.current.click();
		setUploadErrors([]);
	};

	// Called when file(s) have been selected for upload
	const uploadFile = (e) => {
		e.preventDefault();
		console.log(e.target.files);
		Object.entries(e.target.files).forEach((arr) => {
			const reader = new FileReader();
			const currentFile = arr[1];

			if (currentFile != null) {
				reader.readAsDataURL(currentFile);
				reader.onloadend = () => fileLoaded(currentFile);
				reader.onerror = (err) => fileError(err, e);
			}
		});
	};

	// Verifies file passes constraint(s)
	const fileLoaded = (file) => {
		const fileName = file.name;
		// Verifies file is one of these extensions
		if (!acceptArray.includes(file.type)) {
			setUploadErrors((prev) => [
				...prev,
				`${fileName} must be one of these types: ` +
					acceptArray.map((val) => mime.getExtension(val)).join(", "),
			]);
		}
		// Verifies file is no more than this size
		else if (file.size / 1048576 > maxFileSize) {
			setUploadErrors((prev) => [
				...prev,
				`${fileName} cannot be bigger than ${maxFileSize}MB`,
			]);
		} else {
			console.log("loaded " + fileName);
			file.category = ATTACHMENT_CATEGORY[0].value;
			setUploads((prev) => [...prev, file]);
		}
	};

	// Called when there is an error in the file upload process
	const fileError = (e, upload) => {
		if (e?.target?.error?.name === "NotReadableError") {
			setUploadErrors((prev) => [
				...prev,
				`${upload.name} could not be read, please try again`,
			]);
		} else {
			setUploadErrors((prev) => [
				...prev,
				`${e?.target?.error?.name || "Unexpected error"}`,
			]);
		}
	};

	return (
		<div>
			<div className="col-12 d-flex justify-content-center">
				<div
					role="button"
					className="file-uploader-upload"
					onClick={updateInput}
				>
					<i className="fa fa-upload" />
					Add Files
					<input
						ref={fileInputRef}
						type="file"
						onChange={uploadFile}
						value=""
						multiple={true}
						accept={acceptArray.join(", ")}
					/>
				</div>
			</div>
			{uploadErrors.length > 0 && (
				<div className="col-12 file-uploader-list">
					<p className="file-uploader-list-title">
						File Could Not Be Uploaded:
					</p>
					<ul>
						{uploadErrors.map((err, i) => (
							<li key={`${err[0]}_${i}`}>{err}</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};
