import { useState, useCallback } from "react";
import axios from "axios"; // Import axios for making HTTP requests

export const useVinScanner = () => {
  // State to store the detected VIN
  const [vin, setVin] = useState("");
  console.log("Vin state changed:", vin);
  // State to indicate if the VIN scanning process is ongoing
  const [isLoading, setIsLoading] = useState(false);
  // State to store any errors during the process
  const [error, setError] = useState(null);

  /**
   * Handles the process of capturing the photo and extracting the VIN using the VINbarcode API.
   * @param {string} dataUri - The captured image in Data URI format.
   */
  const handleTakePhoto = useCallback((dataUri) => {
    setIsLoading(true);

    // Convert dataUri to Blob
    const byteString = atob(dataUri.split(',')[1]);
    const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    const formData = new FormData();
    formData.append('image', blob, 'vin_image.jpg');

    axios.post('https://www.recognition.ws/vinbarcode/v2?accesscode=1e388618-7f73-4e70-818e-2fa4edf22cad&vindecode=TRUE&format=JSON', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      const result = response.data;
      if (result.status === 'SUCCESS') {
        setVin(result.vin_captured);
      } else {
        setError('Failed to detect a valid VIN. Please try again.');
      }
    })
    .catch(error => {
      console.error("API Error:", error); // Logging the API error
      setError("Error processing the image. Please try again.");
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  return {
    vin,
    isLoading,
    error,
    handleTakePhoto,
  };
};
