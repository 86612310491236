import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
   CheckBoxElement,
   IntegerInputElement,
   InventoryEditableSelectorElement,
   SelectorElement,
} from "../../../../forms/components/InputElement";
import {
   newOrUsed,
   tireTypes,
   vehicleCategories,
   vehicleStatus,
   vehicleSubCategories,
} from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";
import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { failAlert, saveFailAlert } from "../../../../../utils/alertUtils";
import { useFromUser } from "../../../../../stores/LocalStorageHelper";
import { saveMake, saveModel } from "../../../../../api/InventoryAPI";


const TrailerInfo = () => {
   const sessionModels = JSON.parse(sessionStorage.getItem("models"));
   const sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
   // Vehicle store
   const vehicle = useCurrentVehicleStore((state) => state, shallow);
   const [trailerModelList, editTrailerModelList] = useState([]);
   const [trailerMakeList, editTrailerMakeList] = useState([]);
   const [yearList, setYearList] = useState([]);
   // Define custom error messages for different error types
   const methods = useFormContext();


   const dealerID = useFromUser("dealerID");
   const locationID = useFromUser("locationID");
   const createMake = (m) => {
       const request = { make: m, locationID, dealerID };
       saveMake(request).then(
           (response) => {
               let makes = JSON.parse(sessionStorage.getItem("makes"));
               const newMake = response.data.content;
               makes.push({ label: m, value: m, obj: newMake });
               vehicle.editMakeList(makes);
               sessionStorage.setItem("makes", JSON.stringify(makes));
               vehicle.editTrailerMake(m);
           },
           (err) => {
               if (!err.isGeneralError) {
                   showApiError(err, saveFailAlert);
               }
           }
       );
   };
   const createModel = (mo, ma) => {
       console.log(vehicle.make);
       console.log(ma);
       const makes = JSON.parse(sessionStorage.getItem("makes"));
       const makeID = makes.find((m) => m.obj.make === ma);
       if (!makeID) {
           failAlert("must select a make");
           return;
       }
       const request = {
           makeID: makeID.obj.id,
           model: mo,
           vehicleType: "Trailer",
           locationID,
           dealerID,
       };
       saveModel(request).then(
           (response) => {
               let models = JSON.parse(sessionStorage.getItem("models"));
               const newModel = response.data.content;
               models.push({ label: mo, value: mo, obj: newModel });
               vehicle.editModelList(models);
               sessionStorage.setItem("models", JSON.stringify(models));
               vehicle.editTrailerModel(mo);
           },
           (err) => {
               if (!err.isGeneralError) {
                   showApiError(err, saveFailAlert);
               }
           }
       );
   };


   const setModelsByMake = () => {
       console.log("models changing");
       const makeID = trailerMakeList.find(
           (obj) => obj.obj.make === vehicle.trailerMake
       );
       if (makeID) {
           const models = sessionModels.filter(
               (obj) =>
                   obj.obj.makeID === makeID.obj.id && obj.obj.vehicleType === "Trailer"
           );
           editTrailerModelList(models);
       } else {
           editTrailerModelList([]);
       }
   };


   const makeAndModelList = () => {
       // find all models with selected type
       const models = sessionModels.filter((m) => m.obj.vehicleType === "Trailer");
       // reduce the list to simple list of unique makeIDs
       const makeIDs = [...new Set(models.map((model) => model.obj.makeID))];
       // find all makes with matching makeID
       const filtered = sessionMakes.filter((make) =>
           makeIDs.includes(make.obj.id)
       );


       editTrailerMakeList(filtered);


       setModelsByMake();
   };
   useEffect(() => {
       setModelsByMake();
       // eslint-disable-next-line
   }, [vehicle.trailerMake]);


   useEffect(() => {
       makeAndModelList();
       const currentYear = new Date().getFullYear() + 1;
       const yearStart = 1900;
       const getYearList = [];
       for (let i = currentYear; i >= yearStart; i--) {
           getYearList.push({ label: i.toString(), value: i.toString() });
       }
       setYearList(getYearList);


       // eslint-disable-next-line
   }, []);


   return (
       <Form className="pt-2" onKeyDown={formNavigationUtils}>
           <InputWrapper
               formTitle="Trailer"
               inputComponents={[
                   <InventoryEditableSelectorElement
                       disable={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       label="Make"
                       value={vehicle.trailerMake}
                       onChange={(e) => {
                           console.log(e.value);
                           console.log(vehicle.editTrailerMake);
                           vehicle.editTrailerMake(e);
                       }}
                       options={trailerMakeList}
                       name="trailerMake"
                       key={vehicle.trailerMake}
                       defaultValue={vehicle.trailerMake}
                       onCreateOption={createMake}
                   />,
                   <InventoryEditableSelectorElement
                       disable={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       label="Model"
                       value={vehicle.trailerModel}
                       onChange={(e) => vehicle.editTrailerModel(e)}
                       options={trailerModelList}
                       name="trailerModel"
                       key={vehicle.trailerModel}
                       defaultValue={vehicle.trailerModel}
                       onCreateOption={(e) => createModel(e, vehicle.make)}
                   />,
                   <InventoryEditableSelectorElement
                       {...methods}
                       disable={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       value={vehicle.trailerYear}
                       name="trailerYear"
                       label="Year"
                       type="number"
                       onChange={(e) => {
                           vehicle.editTrailerYear(e);
                       }}
                       defaultValue={vehicle.trailerYear}
                       options={yearList}
                       key={vehicle.trailerYear}
                   />,


                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.trailerVin}
                       name="trailerVin"
                       label="SerialNo(VIN)"
                       type="text"
                       onChange={(e) => {
                           vehicle.editTrailerVin(e.target.value);
                       }}
                   />,
                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.trailerLicense}
                       name="license"
                       label="License Plate"
                       type="text"
                       onChange={(e) => {
                           vehicle.editTrailerLicense(e.target.value);
                       }}
                   />,
                   <IntegerInputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.trailerAxle}
                       name="axelCount"
                       label="Axel Count"
                       type="number"
                       onChange={(e) => {
                           vehicle.editTrailerAxle(e);
                       }}
                   />,
                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.height}
                       name="height"
                       label="Height"
                       type="text"
                       onChange={(e) => {
                           vehicle.editHeight(e.target.value);
                       }}
                   />,
                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.length}
                       name="length "
                       label="length "
                       type="text"
                       onChange={(e) => {
                           vehicle.editLength(e.target.value);
                       }}
                   />,
                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.tireSize}
                       name="tireSize"
                       label="Tire Size"
                       type="text"
                       onChange={(e) => {
                           vehicle.editTireSize(e.target.value);
                       }}
                   />,
                   <SelectorElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       name="type"
                       label="Tire Type"
                       value={vehicle.tireType}
                       onChange={(e) => vehicle.editTireType(e.target.value)}
                       options={tireTypes}
                   />,
                   <SelectorElement
                       {...methods}
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       name="tireStatus"
                       label="Tire Status"
                       value={vehicle.tireStatus}
                       onChange={(e) => vehicle.editTireStatus(e.target.value)}
                       options={newOrUsed}
                   />,


                   <InputElement
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       {...methods}
                       value={vehicle.pullWeight}
                       name="PullWeight"
                       label="Towing Capacity"
                       type="text"
                       onChange={(e) => {
                           vehicle.editPullWeight(e.target.value);
                       }}
                   />,
                   <SelectorElement
                       {...methods}
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       name="category"
                       label="Category"
                       value={vehicle.category}
                       onChange={(e) => vehicle.editCategory(e.target.value)}
                       options={vehicleCategories}
                   />,
                   <SelectorElement
                       {...methods}
                       readOnly={
                           vehicle.status === vehicleStatus.SOLD ||
                           vehicle.status === vehicleStatus.DELETED
                       }
                       name="subCategory"
                       label="Subcategory"
                       value={vehicle.subCategory}
                       onChange={(e) => vehicle.editSubCategory(e.target.value)}
                       options={vehicleSubCategories}
                   />,
                   <CheckBoxElement
                       disableValidation
                       checked={vehicle.isFetTaxable}
                       onChange={() => {
                           vehicle.editIsFetTaxable(!vehicle.isFetTaxable);
                       }}
                       name="isFetTaxable"
                       label="Has FET Tax"
                       //colSize="col-sm-3"
                       labelClass="pl-4"
                   />,


                   <CheckBoxElement
                       disableValidation
                       checked={vehicle.trailerIsNew}
                       onChange={() => {
                           vehicle.editTrailerIsNew(!vehicle.trailerIsNew);
                       }}
                       name="trailerIsNew"
                       label="TrailerIsNew"
                       //colSize="col-sm-3"
                       labelClass="pl-4"
                   />,
               ]}
           />
       </Form>
   );
};


export default TrailerInfo;