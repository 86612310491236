import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";
import useCurrentLoginStore from "stores/LoginStore";
import shallow from "zustand/shallow";

/**
 * DealerTempSelector component allows the user to select a dealer from a dropdown.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const DealerTempSelector = () => {
	const [selected, setSelected] = useState(""); // Initialize with an empty string
	const dealer = useFromUser("dealers");
	const location = useFromUser("locations");
	const { editDealerTempID } = useCurrentLoginStore((state) => state, shallow);

	// Ensure dealersList is an array before mapping over it
	const dealersList = JSON.parse(sessionStorage.getItem("dealersList")) || [];
	const options = dealersList.map((obj, index) => (
		<option key={index} value={obj.recNum}>
			{`${obj.name} - ${obj.dealerNum}`}
		</option>
	));

	return (
		<>
			{options.length > 0 && (
				<div className="d-flex justify-content-center mt-2 mb-4">
					<Input
						className="col-4"
						style={{ minWidth: "320px" }}
						onChange={(e) => {
							editDealerTempID(e.target.value);
							setSelected(e.target.value);
						}}
						type="select"
						value={selected} // This ensures the selected option matches the state
					>
						{/* Use an empty string as the value for the default option */}
						<option value="">
							{dealer ? dealer.companyName : "Select a dealer"} -{" "}
							{location ? location.dealerNum : ""}
						</option>
						{options}
					</Input>
				</div>
			)}
		</>
	);
};

DealerTempSelector.propTypes = {
	dealer: PropTypes.object,
	location: PropTypes.object,
	editDealerTempID: PropTypes.func,
};

export default DealerTempSelector;
