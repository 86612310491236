import React from "react";
import UpdatePasswordForm from "./UpdatePasswordForm";

import * as yup from "yup";
import { setLocale } from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

const UpdatePassword = () => {
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		oldPassword: yup.string().required(),
		newPassword: yup
			.string()
			.required()
			.matches(
				/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()\-_+=(){}[~`!@#$%^&*()\-_+=(){}[\]:;<>,.?\\/|'"]).{8,22}/g,
				{ message: "Password requirements not met" }
			),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("newPassword")], "Must be the same as password"),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<FormProvider {...methods}>
			<UpdatePasswordForm />
		</FormProvider>
	);
};

export default UpdatePassword;
