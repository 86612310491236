import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, CardTitle } from "reactstrap";
import useCurrentVehicleStore from "stores/InventoryStore";
import useCurrentPhotoStore from "stores/PhotoStore";
import UploadPhotos from "./UploadPhotos";
import Gallery from "react-photo-gallery";
import SelectedImage from "./SelectedImage";
import DeletePhotos from "./DeletePhotos";
import ReorderPhotos from "./ReorderPhotos";
import ImageCarousel from "./ImageCarousel";
import { getImageList } from "api/InventoryAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { createPhotosObj, getThumbnailURL } from "./helperFunctions";
import { failAlert } from "../../../../../utils/alertUtils";
import { MultiImageProvider } from "../../image/multi/MultiImageContext";

/**
 * Tab containing all the photo functionalities. Uploads, deletes, reordering.
 * The first image from the directory of all the images are used as the
 * thumbnail.
 */
const InventoryPhotosTab = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Local states
	const [enableReorder, setEnableReorder] = useState(false);
	const [showSlide, setShowSlide] = useState(false);
	const [slideIndex, setSlideIndex] = useState(0);

	// Vehicle store
	const { vin, editThumbnail } = useCurrentVehicleStore();

	// Photo store
	const {
		gallery,
		deletes,
		editGallery,
		editReorders,
		editDeletes,
	} = useCurrentPhotoStore();
	console.log(gallery);

	// Gets a list of images
	const getImages = useCallback(
		(dealerID, locationID, vin) => {
			if (dealerID != null && locationID != null && vin != null) {
				getImageList(dealerID, locationID, vin).then(
					(res) => {
						let imgArr = res.data.content;
						let arrObj = createPhotosObj(imgArr);
						console.log(arrObj);
						editGallery(arrObj);
						editReorders(arrObj);
						editThumbnail(getThumbnailURL(imgArr));
					},
					(error) => {
						console.log(error);
						failAlert(`Failed to retrieve images for ${vin}.`);
					}
				);
			} else {
				failAlert(`Could not find any images for ${vin}.`);
			}
		},
		[editThumbnail, editGallery, editReorders]
	);

	useEffect(() => {
		getImages(dealerID, locationID, vin);
		// eslint-disable-next-line
	}, [dealerID, locationID, getImages]);

	// If the index selected for delete exists in the object containing
	// the items to deletes sets the value for that index to the
	// opposite. If it doesn't exist in the object, add it and
	// initialize it to true.
	const clickedPhoto = useCallback(
		(index) => {
			if ([index] in deletes) {
				if (deletes[index] === true) {
					editDeletes({
						...deletes,
						[index]: false,
					});
				} else {
					editDeletes({
						...deletes,
						[index]: true,
					});
				}
			} else {
				editDeletes({ ...deletes, [index]: true });
			}
		},
		[deletes, editDeletes]
	);

	// Returns a Selected image component that has a check mark
	// indicating that image was selected, or removes one if
	// clicked on and already had a check mark.
	const selectedImage = useCallback(
		({ index, left, top, key, photo }) => (
			<SelectedImage
				key={key}
				margin={"2px"}
				index={index}
				photo={photo}
				left={left}
				top={top}
				setClickedPhoto={clickedPhoto}
				selected={deletes[index] === true ? true : false}
				slide={handleShowSlide}
			/>
		),
		// eslint-disable-next-line
		[deletes]
	);

	const handleShowSlide = (index) => {
		setSlideIndex(index);
		setShowSlide(true);
	};

	return (
		<>
			<div className="col-md-12">
				<Card>
					<CardBody tag="h5">
						<CardTitle>Number of Photos: {gallery.length}</CardTitle>
						{(enableReorder && gallery.length > 0 && (
							<ReorderPhotos showReorder={setEnableReorder} />
						)) ||
							(gallery.length > 0 && (
								<>
									<div className="col">
										<Gallery
											photos={gallery}
											onClick={clickedPhoto}
											renderImage={selectedImage}
										/>
									</div>
									<Col>
										<ImageCarousel
											startIndex={slideIndex}
											showSlide={showSlide}
											setShowSlide={setShowSlide}
										/>
									</Col>
								</>
							))}
					</CardBody>
				</Card>
				<MultiImageProvider>
					<UploadPhotos />
				</MultiImageProvider>
			</div>
			<div className="col">
				<div className="row mx-0 pb-3">
					<div className="col-md-6">
						<div
							className="btn btn-primary"
							onClick={() => setEnableReorder(true)}
						>
							<i className="nc-icon nc-refresh-69"></i> Reorder
						</div>
					</div>

					<div className="col-md-6 text-right">
						<div>
							<DeletePhotos />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default InventoryPhotosTab;
