import React, { useEffect, useState } from "react";
// For prop validation
import ChildrenModal from "../../../components/modals/ChildrenModal";
import OpenModalButton from "../../deal/components/OpenModalButton";
import MessageHistory from "./MessageHistory";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import "assets/css/recent-message-log.css";

// Extend dayjs with the "isBetween" plugin
dayjs.extend(isBetween);

const RecentMessages = () => {
	// State to control modal visibility
	const [newModal, setNewModal] = useState(false);

	// State to control button class (for blinking effect)
	const [blinking, setBlinking] = useState("btn-sm");

	// Retrieve user details from sessionStorage
	const user = JSON.parse(sessionStorage.getItem("user"));
	const locationID = user?.locationID;

	// Function to toggle the modal and update the button class
	const toggleNew = () => {
		setBlinking("btn-sm");
		setNewModal((prevState) => !prevState);
	};

	// Setup WebSocket connection on component mount
	useEffect(() => {
		const ws = new WebSocket(
			`wss://dealerclick.net/dev/integrations/ws/messages?userId=${locationID}`
		);
		console.log("Connected to WebSocket");

		ws.onopen = () => {
			console.log("WebSocket connection opened");
		};

		ws.onmessage = (event) => {
			console.log("WebSocket message received:", event.data);
			setBlinking("blinking-button");
		};

		ws.onclose = () => {
			console.log("WebSocket connection closed");
		};

		ws.onerror = (error) => {
			console.error("WebSocket error:", error);
		};

		// Cleanup WebSocket on component unmount
		return () => {
			if (ws) ws.close();
		};
		// Dependency array is empty because we only want to run this effect once
	}, [locationID]);

	return (
		<>
			<OpenModalButton
				buttonClass={blinking}
				buttonColor="link"
				onClick={toggleNew}
				buttonText="Message History"
				iconClass="nc-icon nc-simple-add"
			/>
			<ChildrenModal
				modal={newModal}
				toggle={toggleNew}
				modalTitle="Message History"
				children={<MessageHistory />}
			/>
		</>
	);
};

export default RecentMessages;
