import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	Row,
} from "reactstrap";
import shallow from "zustand/shallow";
import { DealStatus, rollOptions } from "constants/Constants";
import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import { failAlert } from "utils/alertUtils";
import {
	CurrencyInput,
	SimpleSelectorElement,
} from "features/forms/components/InputElement";
import {
	FindApr,
	rollByDown,
	rollByPrice,
} from "features/deal/helperFunctions/SaleFunctions";

const RollModalBody = ({ toggle }) => {
	// Sale store
	const {
		payment,
		term,
		amtFinanced,
		price,
		taxRate,
		licensePct,
		registrationPct,
		apr,
		loanPeriod,
		loanDate,
		firstDueDate,
		editRollPreValue,
		editRollBack,
		editPrice,
		desiredPmt,
		editDesiredPmt,
		editApr,
		down,
		editDown,
		hasBalloon,
	} = useCurrentSaleStore((state) => state, shallow);

	const [rollByOpt, setRollByOpt] = useState(rollOptions[0].value);

	// Deal store
	const { type, vitRate, businessTaxRate, fetTaxRate } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			vitRate: state?.deal?.dealSettings?.taxDefaults?.vitRate,
			businessTaxRate: state?.deal?.dealSettings?.taxDefaults?.businessTaxRate,
			fetTaxRate: state?.deal?.dealSettings?.taxDefaults?.fetTaxRate,
		}),
		shallow
	);

	const handleClick = () => {
		console.log(rollByOpt);
		switch (Number.parseInt(rollByOpt)) {
			// roll by price
			case 10: {
				const newPrice = rollByPrice(
					desiredPmt,
					term,
					amtFinanced,
					price,
					taxRate,
					vitRate,
					businessTaxRate,
					fetTaxRate,
					licensePct,
					registrationPct,
					apr,
					loanPeriod,
					loanDate,
					firstDueDate
				);
				editRollPreValue(price);
				editRollBack(Number.parseInt(rollByOpt));
				editPrice(newPrice);
				break;
			}
			// roll by down
			case 20: {
				const newDown = rollByDown(
					desiredPmt,
					term,
					amtFinanced,
					down,
					apr,
					loanPeriod,
					loanDate,
					firstDueDate
				);
				editRollPreValue(down);
				editRollBack(Number.parseInt(rollByOpt));
				editDown(newDown);
				break;
			}
			// roll by interest
			case 40: {
				const newApr = FindApr(
					apr,
					loanPeriod,
					term,
					amtFinanced,
					loanDate,
					firstDueDate,
					desiredPmt
				);
				console.log(newApr);
				editRollPreValue(apr);
				editRollBack(Number.parseInt(rollByOpt));
				editApr(newApr);
				break;
			}
			// roll by term
			case 90: {
				if (hasBalloon) {
					failAlert("Cannot roll by term with balloon payment");
				} else {
					editRollPreValue(term);
					editRollBack(Number.parseInt(rollByOpt));
				}
				break;
			}
			default:
				break;
		}
		toggle();
	};

	useEffect(() => {
		editDesiredPmt(payment);
		// eslint-disable-next-line
	}, []);

	return (
		<Form onKeyDown={formNavigationUtils}>
			<Card>
				<CardHeader>
					<CardTitle tag="h3">Manage Fees</CardTitle>
				</CardHeader>
				<CardBody>
					<Row>
						<SimpleSelectorElement
							readOnly={type !== DealStatus.PENDING}
							label="Roll By"
							value={rollByOpt}
							colSize="col-md-6"
							onChange={(e) => {
								setRollByOpt(e.target.value);
							}}
							options={rollOptions}
						/>
						<CurrencyInput
							label="Desired Payment"
							readOnly={type !== DealStatus.PENDING}
							value={desiredPmt}
							onChange={editDesiredPmt}
							name="desiredPmt"
							disableValidation
							wholeRow
						/>
					</Row>
					<div className="text-center">
						<Button
							readOnly={type !== DealStatus.PENDING}
							onClick={handleClick}
						>
							Calculate
						</Button>
					</div>
				</CardBody>
			</Card>
		</Form>
	);
};

export default RollModalBody;
