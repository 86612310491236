import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { DealStatus } from "constants/Constants";
import { saveDeal } from "api/DealAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";
import { failAlert, twoOptionsAlert } from "utils/alertUtils";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { TIME_FORMAT } from "features/notes/NoteConstants";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import useSetDeal from "features/deal/hooks/useSetDeal";

const VoidRepoForm = ({ toggleAdminForm, toggle }) => {
	const getDeal = useBuildDeal();
	const [detail, setDetail] = useState("");
	const today = dayjs().format("YYYY-MM-DD");
	const todayNote = dayjs().format(TIME_FORMAT);
	const ID = useFromUser("ID");
	const firstname = useFromUser("firstName");
	const lastname = useFromUser("lastName");
	const setDeal = useSetDeal();

	const { notes, editNotes } = useCurrentDealStore(
		(state) => ({
			notes: state.deal.notes,
			editNotes: state.editNotes,
		}),
		shallow
	);

	const handleVoid = async () => {
		const option = await twoOptionsAlert(
			"Are you sure you want to transfer deal to void? You won't be able to redo it.",
			"Yes",
			"Cancel"
		);

		if (option === "Cancel") {
			toggleAdminForm();
			return;
		}

		if (!detail.trim()) {
			return; // Prevent adding an empty or whitespace note
		}

		let createdOn = todayNote;
		let createdBy = `${lastname}, ${firstname}`;
		let modifiedBy = `${lastname}, ${firstname}`;

		const entry = {
			createdOn,
			createdBy,
			modifiedOn: today,
			modifiedBy,
			description: detail,
		};

		// Create a new array by spreading the existing notes and adding the new entry
		const newNotes = [...notes, entry];

		editNotes(newNotes); // Update notes immutably

		const deal = getDeal();
		let modifiedDeal = { ...deal };

		modifiedDeal.deal.type = DealStatus.VOID;
		modifiedDeal.deal.repoDate = today;
		modifiedDeal.deal.modifiedBy = `${lastname}, ${firstname}`;
		modifiedDeal.deal.modifiedById = ID;

		const modifiedNotes = newNotes.length > 0 ? JSON.stringify(newNotes) : "[]";
		modifiedDeal.deal.notes = modifiedNotes;

		// Persist the modified deal with updated notes
		saveDeal(modifiedDeal).then(
			(res) => {
				setDeal(res.data.content);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);

		toggleAdminForm();
		toggle();
	};

	return (
		<div>
			<FormGroup className="col-12">
				<Label>Reason</Label>
				<Input
					name="voidDetail"
					type="textarea"
					value={detail}
					onChange={(e) => setDetail(e.target.value)}
				/>
				<div className="text-center">
					<Button color="danger" onClick={handleVoid}>
						Void
					</Button>
				</div>
			</FormGroup>
		</div>
	);
};

export default VoidRepoForm;
