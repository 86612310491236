// Functions used in the note components within the "/note" folder

import { ASC, TIME_FORMAT } from "./NoteConstants";
import dayjs from "dayjs";

// Import the CustomParseFormat plugin from day.js
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

/**
 * Parses a string representation of a JSON to a JSON
 *
 * @param {String} notes - String representation of a JSON
 *
 * @returns - String as a JSON or empty array
 */
export const getNotesArray = (notes) => {
	console.log(notes);
	if (notes === null || notes === "" || notes === undefined) {
		return [];
	}
	try {
		return JSON.parse(notes);
	} catch (e) {
		console.log(e);
		return [notes];
	}
};

/**
 * Sorts an array of objects by their field that is of type
 * Date, created using day.js library
 *
 * @param {Array} notes - Array of objects to sort
 * @param {String} order - If sorting order should be ascending  or
 * descending
 * @param {String} field - Object property to do sorting on
 *
 * @returns - Array of objects sorted by the {@param order and
 * @param field}
 * */
export const sortDates = (notes, order, field) => {
	if (!notes || notes.length < 1) {
		return notes;
	}

	if (order === ASC) {
		return [...notes].sort((curr, next) =>
			sortByDate(curr[field], next[field])
		);
	}

	return [...notes].sort((curr, next) => sortByDate(next[field], curr[field]));
};

/**
 * Sorts an array of objects by their field that is of type
 * String
 *
 * @param {Array} notes - Array of objects to sort
 * @param {String} order - If sorting order should be ascending
 * or descending
 * @param {String} field - Object property to do sorting on
 *
 * @returns - Array of objects sorted by the {@param order and
 * @param field}
 */
export const sortNames = (notes, order, field) => {
	if (!notes || notes.length < 1) {
		return notes;
	}

	if (order === ASC) {
		return [...notes].sort((curr, next) =>
			sortByName(curr[field], next[field])
		);
	}

	return [...notes].sort((curr, next) => sortByName(next[field], curr[field]));
};

/**
 * Gets the difference in minutes for two Date objects which were
 * created using day.js library. Return {@param date1} if there
 * was an error.
 *
 * @param {Date} date1 - 1st date object
 * @param {Date} date2 - 2nd date object
 *
 * @returns - Difference between the dates as a number
 */
const sortByDate = (date1, date2) => {
	try {
		let currDate = dayjs(date1, TIME_FORMAT);
		let nextDate = dayjs(date2, TIME_FORMAT);

		return currDate.diff(nextDate, "second", true);
	} catch (e) {
		console.log(e);

		return date1;
	}
};

/**
 * Compares two strings. Return {@param name1} if there
 * was an error.
 *
 * @param {String} name1 - 1st name string
 * @param {String} name2 - 2nd name string
 *
 * @returns - 0 if those two elements are equal.
 * 			- > 0 if first argument is greater than second
 * 			- < 0 if first argument is less than second
 */
const sortByName = (name1, name2) => {
	try {
		return name1.localeCompare(name2);
	} catch (e) {
		console.log(e);

		return name1;
	}
};
