import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import shallow from "zustand/shallow";
import NumberFormat from "react-number-format";

import history from "../../../utils/createBrowserHistory";
import useCurrentDealStore from "../../../stores/DealStore";
import useRoute from "../../../hooks/useRoute";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import DealTable from "../../../components/tables/DealTable";
import { getDealDetails } from "../../../api/DealAPI";
import { getSettings } from "../../../api/SettingsAPI";
import { getVehicleDeals } from "../../../api/DealAPI";
import { showApiError } from "../../../utils/errorRoutingUtils";
import { retrieveFailAlert, failAlert } from "../../../utils/alertUtils";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import {
	PATHS,
	defaultPictureURL,
	SaleType,
} from "../../../constants/Constants";

import useCurrentVehicleStore from "stores/InventoryStore";
import Camera from "assets/img/placeholder.png";
import useSetDeal from "../../deal/hooks/useSetDeal";

const InventoryDealList = ({ inventoryID, toggle }) => {
	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const setDeal = useSetDeal();

	// Routing
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	// Vehicle Store
	const { thumbnail } = useCurrentVehicleStore((state) => ({
		thumbnail: state.thumbnail,
	}));

	// Deal store,
	const { editID, editIsLoading, updateSettings } = useCurrentDealStore(
		(state) => ({
			editID: state.editID,
			editIsLoading: state.editIsLoading,
			updateSettings: state.updateSettings,
		}),
		shallow
	);

	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			container.buyerID = content[key].buyerID;
			container.coBuyerID = content[key].coBuyerID;
			container.inventoryID = content[key].inventoryID;
			container.thumbnail = thumbnail; //use local storage for now, will change when the endpoint have thumbnail in it
			if (thumbnail !== defaultPictureURL) {
				console.log(content[key].thumbnail);
				container.thumbnail = (
					<img
						className="zoom"
						alt="vehicle"
						src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
					/>
				);
			}

			container.name = content[key].name;
			container.stock = content[key].stock;
			container.phone =
				content[key].phone != null ? (
					<a href={"tel:" + content[key].phone}>
						{" "}
						<NumberFormat
							value={content[key].phone}
							format="+1 (###) ###-####"
							isNumericString={true}
							displayType="text"
						/>
					</a>
				) : (
					""
				);
			container.status = content[key].status;
			container.vehicle = content[key].vehicle;
			container.Actions = (
				<div className="d-flex justify-content-center">
					<Button
						size="md"
						onClick={() => {
							Promise.all([getDealData(content[key].dealID)]).then(
								() => {
									console.log(content);
									editID(content[key].dealID);
									editIsLoading(false);
									toggle();
								},
								(err) => {
									console.log(err);

									if (!err.isGeneralError) {
										showApiError(err, retrieveFailAlert);
									}
								}
							);
						}}
					>
						<i className="nc-icon nc-paper" />
						&nbsp;View Deal
					</Button>
				</div>
			);
			return container;
		});
		return container;
	};

	// Gets a list of all the deals for this dealer and location
	const getListData = () => {
		getVehicleDeals(inventoryID).then(
			(response) => {
				const result = dataFormat(response.data.content.deals);

				console.log(response.data.content);
				setData(result);
				setLoadingList(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Gets the deal data and routes to detail page or legacy detail page
	const getDealData = (id) => {
		Promise.all([getSettings(dealerID, locationID), getDealDetails(id)]).then(
			(res) => {
				console.log("getData() has finished");
				console.log(res);
				console.log("Getting Deal details for: " + id);
				const defaultSettings = res[0].data.content;
				const settingsFromDeal = JSON.parse(
					res[1].data.content.deal.dealSettings
				);
				setDeal(res[1].data.content);
				updateSettings(defaultSettings, settingsFromDeal);

				const resSaleType = res[1].data.content.deal.sale.saleType;
				// only open retail and wholesale deals
				if (
					resSaleType === SaleType[0].value ||
					resSaleType === SaleType[1].value ||
					resSaleType === SaleType[2].value
				) {
					failAlert(
						"This type of deal is under construction. DealerClick is working on it right now."
					);
				}
				history.push(dealDetailPage, { ID: id });
				console.log("getData() has finished");
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	useEffect(() => {
		getListData();
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{loadingList && <LoadingSpinner />}
			{(!loadingList && data.length > 0 && <DealTable data={data} />) || (
				<h6 style={{ color: "red" }} className="d-flex justify-content-center">
					No deals found with this vehicle
				</h6>
			)}
		</div>
	);
};

export default InventoryDealList;
