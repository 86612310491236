import React from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const DownBreakdown = () => {
	const { down, manufacturerRebate, totalNetTrade } = useCurrentSaleStore(
		(state) => state,
		shallow
	);

	const { pickupPmts, downPayments } = useCurrentDealStore(
		(state) => state,
		shallow
	);

	const { totalDown } = useCurrentRecapStore((state) => state, shallow);

	const rows = [
		{ label: "Down Payment", value: down },
		{ label: "Mfr. Rebate", value: manufacturerRebate },
		{ label: "Net Trade", value: totalNetTrade },
	];

	const AllDown = () => {
		const fees = downPayments.map((key, i) => {
			return (
				<Row>
					<Col>
						<Label>Other {i + 1}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amtPaid} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	const AllDeferred = () => {
		const fees = pickupPmts.map((key, i) => {
			return (
				<Row>
					<Col>
						<Label>Deferred {i + 1}</Label>
					</Col>
					<Col>
						<CurrencyDisplayElement val={key.amount} />
					</Col>
				</Row>
			);
		});
		return fees;
	};

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Type</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
					</Row>
					<hr />
					<>
						{rows.map((obj) => (
							<Row>
								<Col>
									<Label>{obj.label}</Label>
								</Col>
								<Col>
									<CurrencyDisplayElement val={obj.value} />
								</Col>
							</Row>
						))}
					</>
					<hr />
					<AllDown />
					<hr />
					<AllDeferred />
					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={totalDown} />
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default DownBreakdown;
