import PropTypes from "prop-types";
import { Fragment } from "react";
import { Card, CardHeader, Row } from "reactstrap";

/**
 * InputWrapper component - standardizes the layout of various form sections.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.formTitle - Title of the form section.
 * @param {boolean} props.centerTitle - Whether to center-align the title.
 * @param {Array<ReactElement>} props.inputComponents - Child components to be displayed within the form section.
 * @param {ReactElement} props.buttons - Optional button components.
 * @param {ReactElement} props.otherComponents - Optional additional components.
 * @param {ReactElement} props.footer - Optional footer components.
 * @returns {ReactElement} Standardized layout of a form section.
 */
const InputWrapper = ({
	formTitle,
	centerTitle,
	inputComponents,
	buttons,
	otherComponents,
	footer,
	style,
	cardClass = "mt-0",
}) => {
	// Map over the inputComponents to wrap each within a React Fragment.
	// This enables better React performance and key management.
	const componentList = inputComponents.map((component, index) => (
		<Fragment key={index}>{component}</Fragment>
	));

	return (
		<>
			<Card style={style} className={cardClass}>
				<CardHeader>
					{/* Conditionally center-align the title if centerTitle is true */}
					<h3 className={centerTitle ? "d-flex justify-content-center" : ""}>
						{formTitle}
					</h3>
				</CardHeader>
				{/* Wrap the child components within a Row for layout consistency */}
				<Row>{componentList}</Row>
				{/* Optional button components */}
				{buttons}
				{/* Optional footer components */}
				{footer}

				{/* Optional additional components */}
				{otherComponents}
			</Card>
		</>
	);
};

// Prop validation for better developer experience and error checking
InputWrapper.propTypes = {
	formTitle: PropTypes.string,
	centerTitle: PropTypes.bool,
	inputComponents: PropTypes.array.isRequired,
	buttons: PropTypes.element,
	otherComponents: PropTypes.element,
	footer: PropTypes.element,
};

export default InputWrapper;
