import createAxiosInstance from "../utils/apiUtils";
// import { LOCAL } from "./Constants";

import { userBaseUrl } from "../constants/Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/twilio/",
	//	baseURL: LOCAL + "/twilio/",
});

// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/twilio/",
// });

// Sends a message from a Twilio number to any number(s)
export const sendTwilioMessage = async (message) => {
	return await instance.post(`message/send`, message);
};

// Sends a message from a Twilio number to any number(s)
export const sendTwilioBlastMessage = async (messages) => {
	return await instance.post(`message/blast/send`, messages);
};

// Gets the message history between a Twilio number and another number
export const getTwilioMessages = async (history) => {
	return await instance.post(`message/history`, history);
};

// Gets the recent messages of all numbers that a twilio number messaged
// or received a message that we stored in the DB
export const getTwilioRecentMessages = async (dealerID, locationID, number) => {
	return await instance.get(
		`message/history/log/${dealerID}/${locationID}/${number}`
	);
};

// Saves a Twilio message template
export const saveTwilioMessageTemplate = async (template) => {
	return await instance.post(`message/template/save`, template);
};

// Deletes a Twilio message template
export const deleteTwilioMessageTemplates = async (template) => {
	return await instance.post(`message/template/delete`, template);
};

// Gets a list of Twilio message templates
export const getTwilioMessageTemplates = async (locationID) => {
	return await instance.get(`message/templates/${locationID}`);
};

//Get msg body after replaceVarible
export const getTwilioMsgBody = async (msg) => {
	return await instance.post(`getMsgBody`, msg);
};
