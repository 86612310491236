import React, { useState } from "react";
import { Button } from "reactstrap";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

/**
 * ScanButton component to handle VIN scanning using the camera.
 *
 * @param {Object} props - Component props
 * @param {string} props.vin - The VIN to be scanned
 * @param {Function} props.handleTakePhoto - Function to handle the photo taken event
 * @param {Function} props.setShowModal - Function to toggle the modal visibility
 * @returns {JSX.Element} The rendered component
 */
const ScanButton = ({ vin, handleTakePhoto, setShowModal }) => {
    const [isCameraVisible, setCameraVisible] = useState(false);

    return (
        <div className="noDesktop">
            {isCameraVisible ? (
                <div className="camera-container">
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <Button
                            style={{ position: "absolute", top: "5px", left: "5px" }}
                            className="btn-sm btn-link"
                            onClick={() => setCameraVisible(false)}
                        >
                            Close
                        </Button>
                        <Camera
                            onTakePhoto={(dataUri) => {
                                setShowModal(true);  // Show modal when taking photo
                                handleTakePhoto(dataUri);
                                setCameraVisible(false);
                            }}
                            isFullscreen={false}
                            idealFacingMode="environment"
                        />
                    </div>
                </div>
            ) : (
                <Button
                    className="btn-md btn btn-primary ml-2"
                    onClick={() => setCameraVisible(true)}
                >
                    Scan VIN
                </Button>
            )}
            {vin && <p>Detected VIN: {vin}</p>}
        </div>
    );
};

export default ScanButton;
