import OpenModalButton from "features/deal/components/OpenModalButton";
import React, { useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Col,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from "reactstrap";
import {
	useFromUser,
	usePartnerSend,
	useUser,
} from "stores/LocalStorageHelper";
import { successAlertCustom } from "utils/alertUtils";
import { InventoryAdvertisingData } from "./InventoryAdvertisingData";
import InventoryAdvertisingModal from "./InventoryAdvertisingModal";

const InventoryAdvertisingPage = () => {
	const [addModal, setAddModal] = useState(false);
	const [feedName, setFeedName] = useState("");
	const [sendFeed, setSendFeed] = useState("");

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const locations = useFromUser("locations");
	const login = useUser();
	const partnerSend = usePartnerSend();

	const toggleAdd = () => {
		setAddModal(!addModal);
	};

	let formattedData = [...InventoryAdvertisingData];
	formattedData.forEach((data) => {
		// gets the value for the sendVariable received from endpoint
		if (data.partnerSendVariable !== "")
			data.sendValue = partnerSend[data.partnerSendVariable];
	});

	const requestSubscription = () => {
		successAlertCustom(
			"Please contact Dealerclick to activate your subscription."
		);
	};

	const requestSignUp = (signUpFor) => {
		console.log(signUpFor);
		if (signUpFor === "siriusXM") {
			const XMRadioSignup =
				"https://siriusxmdealertrial.com/enrollNowPost.aspx?dmstype=dealerclick" +
				"&dealerphone=" +
				locations.phone +
				"&dealerid=" +
				locations.dealerID +
				"&dealername=" +
				login.dealers.companyName +
				"&address1=" +
				locations.address +
				"&address2=''" +
				"&city=" +
				locations.city +
				"&state=" +
				locations.state +
				"&zip=" +
				locations.zipCode +
				"&authfname=" +
				login.firstName +
				"&authlname=" +
				login.lastName +
				"&authphone=" +
				login.phone +
				"&authemail=" +
				login.email;

			window.open(XMRadioSignup, "_blank", "noopener,noreferrer");
		} else {
			window.open(signUpFor, "_blank", "noopener,noreferrer");
		}
	};
	const requestSignIn = (signIn) => {
		if (signIn === "autovizo") {
			signIn =
				"https://www.autovizo.com/login?dealerID=" +
				dealerID +
				"&locationID=" +
				locationID;
		}
		window.open(signIn, "_blank", "noopener,noreferrer");
	};

	// Address row items
	const content = formattedData.map((data) => {
		console.log("Current Data Item: ", data);
		return (
			<>
				<div className="col-sm-3 pt-3">
					<Card>
						<CardBody>
							<div>
								<img className="adImage" alt={data.Name} src={data.Image} />
							</div>
							{data.sendValue > 0 && (
								<OpenModalButton
									readOnly={!data.sendValue}
									buttonClass="btn-round btn-bg"
									onClick={() => {
										toggleAdd();
										setSendFeed(data.sendVariable);
										setFeedName(data.Name);
									}}
									buttonColor="primary"
									buttonText="Manage"
								/>
							)}
							{data.sendValue === 0 && data.signUp !== "" && (
								<Button
									className="btn-round btn-bg"
									color="link"
									onClick={() => {
										requestSignUp(data.signUp);
									}}
								>
									SignUp
								</Button>
							)}
							{data.sendValue === 0 && (
								<Button
									className="btn-round btn-bg"
									color="link"
									readOnly={data.sendValue}
									onClick={requestSubscription}
								>
									Request
								</Button>
							)}
							{data.sendValue > 0 && data.signIn !== "" && (
								<Button
									className="btn-round btn-bg"
									color="link"
									onClick={() => {
										requestSignIn(data.signIn);
									}}
								>
									SignIn
								</Button>
							)}
						</CardBody>
					</Card>
				</div>
			</>
		);
	});
	return (
		<div>
			<Col className="text-center pt-3 pb-3 mobileHeading">
				<CardTitle>
					<h2>Advertising</h2>
				</CardTitle>
				<Row> {content}</Row>
			</Col>
			<Modal
				returnFocusAfterClose={false}
				isOpen={addModal}
				toggle={toggleAdd}
				size="xl"
			>
				<ModalHeader toggle={toggleAdd}>{feedName}</ModalHeader>
				<ModalBody>
					<InventoryAdvertisingModal
						toggle={toggleAdd}
						sendVariable={sendFeed}
					/>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default InventoryAdvertisingPage;
