import React from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import { PackRequestProvider } from "features/forms/context/PackContexts";
import {
	useFromPartnerLink,
	useFromPartnerSend,
} from "stores/LocalStorageHelper";
import UAC from "services/uacService/UAC";
import { credit700Lenders } from "constants/Constants";
import CudlLogo from "assets/img/cudl-logo.png";
import AppOneLogo from "assets/img/appone-logo.png";
import DealerTrackLogo from "assets/img/dealertrack-logo.png";
import Credit700 from "services/credit700Service/Credit700";
import RouteOne from "services/routeOneService/RouteOne";
import LenderPacket from "components/lenderPacket/LenderPacket";
import LoansTable from "features/loanManager/compontents/LoansTable";
//import DealerClickLogo from "assets/img/dealerclick-logo.png";
import useBuildDeal from "features/deal/hooks/useBuildDeal";

/**
 * Component displaying integrations with various lenders.
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const LenderIntegrations = () => {
	// Retrieve necessary data from local storage helpers
	const isCreditSignup = useFromPartnerLink("isCreditSignup");

	const useUACC = useFromPartnerSend("useUACC");
	const getDeal = useBuildDeal();
	const deal = getDeal();
	const dealId = deal.deal.ID;
	console.log("dealid: " + dealId);
	return (
		<>
			<Row className="px-3 justify-content-center" style={{ gap: "12px" }}>
				<div style={{ width: "300px" }} className="text-center">
					<PackRequestProvider>
						<LenderPacket />
					</PackRequestProvider>
				</div>
				{useUACC === 1 && (
					<div style={{ width: "300px" }} className="text-center">
						<UAC />
					</div>
				)}
				<div style={{ width: "300px" }} className="text-center">
					<RouteOne />
				</div>
				{[
					{ los: credit700Lenders.cudl, logo: CudlLogo },
					{ los: credit700Lenders.appOne, logo: AppOneLogo },
					{ los: credit700Lenders.dealerTrack, logo: DealerTrackLogo },
					// { los: credit700Lenders.dealerClick, logo: DealerClickLogo },
				].map((lender, index) => (
					<div style={{ width: "300px" }} key={index} className="text-center">
						<Credit700
							los={lender.los}
							logo={lender.logo}
							has700={isCreditSignup}
							isDealerClick={lender.los === credit700Lenders.dealerClick}
						/>
					</div>
				))}
			</Row>
			{/* <hr className="my-3 w-75 mx-auto" /> */}
			<LoansTable dealId={dealId} />
		</>
	);
};

LenderIntegrations.propTypes = {
	isCreditSignup: PropTypes.bool,
	useUACC: PropTypes.number,
};

export default LenderIntegrations;
