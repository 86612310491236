import {
	getDealerInsuranceInfo,
	saveDealerInsuranceInfo,
} from "../../../../../../api/DealerIntegrationApi";
import { showApiError } from "../../../../../../utils/errorRoutingUtils";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";
import { useFromUser } from "../../../../../../stores/LocalStorageHelper";
import InputElement, {
	CurrencyInput,
} from "../../../../../forms/components/InputElement";
import InputWrapper from "../../../../../forms/components/InputWrapper";
import { retrieveFailAlert } from "../../../../../../utils/alertUtils";
import { saveSuccessAlert } from "../../../../../../utils/alertUtils";

const DealerInsuranceInfo = () => {
	const locationID = useFromUser("locationID");

	//fields outside of dealerpartner link
	const [dbiProvider, setDbiProvider] = useState();
	const [dbiExpDate, setDbiExpDate] = useState();
	const [dbiAmount, setDbiAmount] = useState();
	const [dbiAnnualCost, setDbiAnnualCost] = useState();
	const [buiProvider, setBuiProvider] = useState();
	const [buiExpDate, setBuiExpDate] = useState();
	const [buiAmount, setBuiAmount] = useState();
	const [buiAnnualCost, setBuiAnnualCost] = useState();

	useEffect(() => {
		getDealerInsuranceInfo(locationID).then((res) => {
			const dlrInfo = res.data.content;
			setDbiProvider(dlrInfo.dbiProvider);
			setDbiExpDate(dlrInfo.dbiExpDate);
			setDbiAmount(dlrInfo.dbiAmount);
			setDbiAnnualCost(dlrInfo.dbiAnnualCost);
			setBuiProvider(dlrInfo.buiProvider);
			setBuiExpDate(dlrInfo.buiExpDate);
			setBuiAmount(dlrInfo.buiAmount);
			setBuiAnnualCost(dlrInfo.buiAnnualCost);
		});
		// eslint-disable-next-line
	}, []);

	const saveInfo = () => {
		const dealerInsuranceInfo = {
			locationID,
			dbiProvider,
			dbiExpDate,
			dbiAmount,
			dbiAnnualCost,
			buiProvider,
			buiExpDate,
			buiAmount,
			buiAnnualCost,
		};
		saveDealerInsuranceInfo(dealerInsuranceInfo).then(
			(res) => {
				console.log(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	return (
		<>
			<Form>
				<InputWrapper
					formTitle="Dealer Insurance Info"
					inputComponents={[
						<InputElement
							value={dbiProvider}
							onChange={(e) => setDbiProvider(e.target.value)}
							label="Dealer Bond Insurance Provider"
							disableValidation={true}
						/>,
						<InputElement
							value={dbiExpDate}
							type="date"
							onChange={(e) => setDbiExpDate(e.target.value)}
							label="Dealer Bond Expiration Date"
							disableValidation={true}
						/>,
						<CurrencyInput
							value={dbiAmount}
							onChange={setDbiAmount}
							label="Amount of Bond "
							type="number"
							disableValidation={true}
							name="dbiAmount"
						/>,
						<CurrencyInput
							value={dbiAnnualCost}
							onChange={setDbiAnnualCost}
							label="Annual Cost of the Bond"
							type="number"
							disableValidation={true}
							name="abiAnnualCost"
						/>,

						<InputElement
							value={buiProvider}
							onChange={(e) => setBuiProvider(e.target.value)}
							label=" Business insurance Provider"
							disableValidation={true}
						/>,
						<InputElement
							value={buiExpDate}
							type="date"
							onChange={(e) => setBuiExpDate(e.target.value)}
							label="Business Insurance Expiration Date"
							disableValidation={true}
						/>,
						<CurrencyInput
							value={buiAmount}
							onChange={setBuiAmount}
							label="Amount of Insurance "
							type="number"
							disableValidation={true}
							name="buiAmount"
						/>,
						<CurrencyInput
							value={buiAnnualCost}
							onChange={setBuiAnnualCost}
							label="Annual cost of the Business Insurance"
							type="number"
							disableValidation={true}
							name="buiAnnualCost"
						/>,
					]}
				/>
			</Form>
			<div className="btn btn-success float-button" onClick={saveInfo}>
				<i className="nc-icon nc-check-2" /> Save
			</div>
		</>
	);
};

export default DealerInsuranceInfo;
