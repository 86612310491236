import React, { useEffect, useState } from "react";

import { Button, Form } from "reactstrap";
import formNavigationUtils from "utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
	EditableSelectorElement,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import { vehicleTypeOptions } from "constants/Constants";
import NavBar from "components/tabs/TabsHolder";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	deleteCustomMake,
	deleteCustomModel,
	saveMake,
	saveModel,
} from "api/InventoryAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { failAlert, saveFailAlert } from "utils/alertUtils";
import useCurrentVehicleStore from "stores/InventoryStore";
import shallow from "zustand/shallow";
import MakeModelTable from "./MakeModelTable";

export const ManageMakeModel = () => {
	const tabs = ["Makes", "Models"];
	const tabComponents = [<MakeTab />, <ModelTab />];

	return (
		<>
			<NavBar
				navClass="nav-pills-primary justify-content-center nav nav-pills"
				tabs={tabs}
				tabComponents={tabComponents}
			/>
		</>
	);
};

const MakeTab = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [make, setMake] = useState("");
	const { editMakeList } = useCurrentVehicleStore((state) => state, shallow);
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	const dataFormat = (arr) => {
		if (!arr || !Array.isArray(arr)) {
			return []; // Return an empty array if arr is null, undefined, or not an array
		}

		const data = arr
			.map((obj) => {
				if (!obj || !obj.obj) {
					return null; // Skip this item if obj or obj.obj is null or undefined
				}

				let dataContainer = {};
				dataContainer.make = obj.obj.make;
				dataContainer.id = obj.obj.id;
				dataContainer.action = (
					<>
						<div className="text-center">
							<div
								className="btn-md btn btn-danger"
								onClick={() => {
									deleteMake(obj.obj.id);
								}}
							>
								Delete
							</div>
						</div>
					</>
				);
				return dataContainer;
			})
			.filter(Boolean); // Remove any null entries that were skipped

		return data;
	};

	useEffect(() => {
		const sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
		const makeList =
			sessionMakes &&
			sessionMakes.filter(
				(m) => m.obj.dealerID !== null && m.obj.locationID !== null
			);

		const d = dataFormat(makeList);
		setData(d);
		setLoadingList(false);
		// eslint-disable-next-line
	}, []);

	const save = () => {
		const request = { make, locationID, dealerID };
		setLoadingList(true);
		saveMake(request).then(
			(response) => {
				let makes = JSON.parse(sessionStorage.getItem("makes"));
				const newMake = response.data.content;
				makes.push({ label: make, value: make, obj: newMake });
				editMakeList(makes);
				const makeList =
					makes &&
					makes.filter(
						(m) => m.obj.dealerID !== null && m.obj.locationID !== null
					);

				const d = dataFormat(makeList);
				setData(d);
				setLoadingList(false);
				sessionStorage.setItem("makes", JSON.stringify(makes));
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
				setLoadingList(false);
			}
		);
	};

	const deleteMake = (id) => {
		setLoadingList(true);
		deleteCustomMake(id).then(
			(response) => {
				let makes = JSON.parse(sessionStorage.getItem("makes"));
				makes = makes.filter((m) => m.obj.id !== id);
				editMakeList(makes);
				const makeList =
					makes &&
					makes.filter(
						(m) => m.obj.dealerID !== null && m.obj.locationID !== null
					);
				const d = dataFormat(makeList);
				setData(d);
				setLoadingList(false);
				sessionStorage.setItem("makes", JSON.stringify(makes));
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
				setLoadingList(false);
			}
		);
	};
	return (
		<>
			{!loadingList && (
				<Form className="pt-2" onKeyDown={formNavigationUtils}>
					<InputWrapper
						formTitle="Custom Makes"
						inputComponents={[
							<InputElement
								value={make}
								name="make"
								label="Add new make"
								type="text"
								onChange={(e) => {
									setMake(e.target.value);
								}}
								disableValidation
							/>,
							<div className="text-center mt-4  mb-4 ">
								<Button onClick={save} className="btn-success btn btn-md">
									Save make
								</Button>
							</div>,
						]}
						footer={<MakeTable data={data} />}
					/>
				</Form>
			)}
		</>
	);
};

const MakeTable = ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: "Make",
				accessor: "make",
			},
			{
				Header: "Actions",
				accessor: "action",
				disableFilters: true,
			},
		],
		// eslint-disable-next-line
		[]
	);
	return (
		<div>
			<MakeModelTable data={data} columns={columns} />
		</div>
	);
};

const ModelTab = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [vehicleType, setVehicleType] = useState("Car");
	const [make, setMake] = useState("Acura");
	const [makeList, setMakeList] = useState([]);
	const [model, setModel] = useState("");
	const { editModelList } = useCurrentVehicleStore((state) => state, shallow);
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	const dataFormat = (arr) => {
		const sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
		const data = arr.map((obj) => {
			let dataContainer = {};
			dataContainer.make = sessionMakes.find(
				(sessionMake) => obj.obj.makeID === sessionMake.obj.id
			).obj.make;
			dataContainer.id = obj.obj.id;
			dataContainer.vehicleType = obj.obj.vehicleType;
			dataContainer.model = obj.obj.model;
			dataContainer.action = (
				<>
					<div className="text-center">
						<div
							className="btn-md btn btn-danger"
							onClick={() => {
								deleteModel(obj.obj.id);
							}}
						>
							Delete
						</div>
					</div>
				</>
			);
			return dataContainer;
		});
		return data;
	};

	useEffect(() => {
		const sessionModels = JSON.parse(sessionStorage.getItem("models"));
		const sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
		setMakeList(sessionMakes);
		const modelList = sessionModels.filter(
			(m) => m.obj.dealerID !== null && m.obj.locationID !== null
		);

		const d = dataFormat(modelList);
		setData(d);
		setLoadingList(false);
		// eslint-disable-next-line
	}, []);

	const save = () => {
		const makeID = makeList.find((m) => m.obj.make === make);
		if (!makeID) {
			failAlert("must select a make");
			return;
		}
		const request = {
			makeID: makeID.obj.id,
			model,
			vehicleType,
			locationID,
			dealerID,
		};
		setLoadingList(true);
		saveModel(request).then(
			(response) => {
				let models = JSON.parse(sessionStorage.getItem("models"));
				const newModel = response.data.content;
				models.push({ label: model, value: model, obj: newModel });
				editModelList(models);
				setLoadingList(false);
				sessionStorage.setItem("models", JSON.stringify(models));
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
				setLoadingList(false);
			}
		);
	};

	const deleteModel = (id) => {
		setLoadingList(true);
		deleteCustomModel(id).then(
			(response) => {
				let models = JSON.parse(sessionStorage.getItem("models"));
				models = models.filter((m) => m.obj.id !== id);
				editModelList(models);
				const modelList = models.filter(
					(m) => m.obj.dealerID !== null && m.obj.locationID !== null
				);
				const d = dataFormat(modelList);
				setData(d);
				setLoadingList(false);
				sessionStorage.setItem("models", JSON.stringify(models));
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
				setLoadingList(false);
			}
		);
	};

	return (
		<>
			{!loadingList && (
				<Form className="pt-2" onKeyDown={formNavigationUtils}>
					<InputWrapper
						formTitle="Custom Models"
						inputComponents={[
							<SelectorElement
								name="type"
								label="Vehicle Type"
								value={vehicleType}
								onChange={(e) => setVehicleType(e.target.value)}
								options={vehicleTypeOptions}
								disableValidation
							/>,
							<EditableSelectorElement
								name="make"
								label="Make"
								value={make}
								onChange={(e) => setMake(e)}
								options={makeList}
								disableValidation
							/>,
							<InputElement
								value={model}
								name="model"
								label="Add new model"
								type="text"
								onChange={(e) => {
									setModel(e.target.value);
								}}
								disableValidation
							/>,
							<div className="text-center mt-4  mb-4 ">
								<Button onClick={save} className="btn-success btn btn-md">
									Save model
								</Button>
							</div>,
						]}
						footer={<ModelTable data={data} />}
					/>
				</Form>
			)}
		</>
	);
};

const ModelTable = ({ data }) => {
	const columns = React.useMemo(
		() => [
			{
				Header: "Vehicle Type",
				accessor: "vehicleType",
			},
			{
				Header: "Make",
				accessor: "make",
			},
			{
				Header: "model",
				accessor: "model",
			},
			{
				Header: "Actions",
				accessor: "action",
				disableFilters: true,
			},
		],
		// eslint-disable-next-line
		[]
	);
	return (
		<div>
			<MakeModelTable data={data} columns={columns} />
		</div>
	);
};

export default ManageMakeModel;
