import React, { memo, useState } from "react";
import {
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from "reactstrap";

import { ErrorMessage } from "@hookform/error-message";

const NewUserPassword = memo(
	({
		register,
		errors,
		clearErrors,
		label,
		name,
		value,
		onChange,
		colSize = "col-lg-2",
	}) => {
		const [showPassword, setShowPassword] = useState(false);

		return (
			<FormGroup className={colSize}>
				<Label>{label}</Label>
				<InputGroup>
					<Input
						autoComplete="new-password"
						name={name}
						innerRef={register}
						onChange={(e) => {
							clearErrors(name);
							onChange(e);
						}}
						className="form-control"
						type={!showPassword ? "password" : "text"}
						value={value || ""}
						invalid={errors[name] ? true : false}
					/>
					<InputGroupAddon addonType="append">
						<InputGroupText>
							<i
								className="fa fa-eye"
								onClick={() => setShowPassword((prev) => !prev)}
							/>
						</InputGroupText>
					</InputGroupAddon>
				</InputGroup>
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			</FormGroup>
		);
	},
	(prevProps, nextProps) => prevProps.value === nextProps.value
);

export default NewUserPassword;
