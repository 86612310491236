import React, { useState, useEffect } from "react";
import { Button, Row } from "reactstrap";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { FTP_IMAGE_URL } from "constants/Constants";
import Loading from "components/loadingSpinner/Loading";
import Camera from "assets/img/placeholder.png";
import CreditAppTable from "../../components/CreditAppTable";
import { getCreditAppDetails } from "api/CreditAppApi";
import ChildrenModal from "components/modals/ChildrenModal";
import DealTemplateModal from "features/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import { saveAsCustomer } from "api/CreditAppApi";
import {
	getVehicleDetails,
	getVehicleDetailsByStockNo,
} from "api/InventoryAPI";
import TwilioSendMessage from "services/twilioService/TwilioSendMessage";
import dayjs from "dayjs";
import OnlineCreditAppDetailsPage from "../onlineCreditAppDetailPage/OnlineCreditAppDetailPage";

const OnlineCreditAppListPage = ({ setMessageCustomers, creditSource }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	//toggle template modal
	const [templateModal, setTemplateModal] = useState(false);
	const toggleTemplate = () => setTemplateModal(!templateModal);
	const [vehicle, setVehicle] = useState({});

	// Local state
	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [tmpCreditApp, setTmpCreditApp] = useState();
	const [customer, setCustomer] = useState({});
	const [coBuyer, setCoBuyer] = useState({});
	const [viewTextModal, setViewTextModal] = useState(false);
	const [textHistoryNumber, setTextHistoryNumber] = useState();
	const [customerID, setCustomerID] = useState(null);
	// Text message modal
	const toggleViewTextModal = () => setViewTextModal(!viewTextModal);

	const handleRowClick = (row) => {
		console.log("Row data:", row);
		if (!row) {
			console.error("Row data is undefined.");
			return;
		}

		// Convert 'ID' to a number
		const creditAppID = Number(row.ID);

		if (isNaN(creditAppID)) {
			console.error("creditAppID is not a valid number:", row.ID);
			return;
		}

		handleView(creditAppID);
	};

	const toggle = () => {
		setShowModal(!showModal);
	};

	const dataFormat = (content) => {
		console.log(content);

		const container = Object.keys(content).map((key) => {
			let container = {};
			console.log(content);
			//			content[key].thumbnail =				"https://www.dealernetwork.com/images/inventory/3534/4757/5N1AR2MN4EC617625-0.jpg";
			content[key].thumbnail =
				FTP_IMAGE_URL +
				"/" +
				dealerID +
				"/" +
				locationID +
				"/" +
				content[key].vin +
				"-0.jpg";

			container.thumbnail = (
				<div>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);

			container.ID = content[key].creditAppID;
			container.firstName = content[key].firstName;
			container.lastName = content[key].lastName;
			container.workPhone = content[key].workPhone;
			container.phone = content[key].homePhone;
			container.cellPhone = content[key].mobilePhone;
			container.Website = content[key].Website;
			container.email = content[key].contactEmail;
			container.origin = content[key].origin;

			container.invVin = content[key].invVin;
			container.stockNo = content[key].stockNo;
			container.vehicleDetails = container.invVin + " " + container.stockNo;

			container.address = content[key].homeAddress;
			container.city = content[key].homeCity;
			container.state = content[key].homeState;
			container.zipCode = content[key].homeZipCode;
			container.country = content[key].homeCountry;

			container.dateGenerated = content[key].dateGenerated;
			container.dateRead = content[key].dateRead;
			if (content[key].active === true || content[key].active === 1) {
				container.status = "Active";
			} else {
				container.status = "Inactive";
			}
			container.Origin = content[key].leadOrigin;
			container.applicationDate = dayjs(content[key].applicationDate).format(
				"MM/DD/YYYY HH:mm:ss"
			);

			container.stockNumber = content[key].vehicleStockNum;
			container.vin = content[key].vin;
			container.year = content[key].vehicleYear;
			container.make = content[key].vehicleMake;
			container.model = content[key].vehicleModel;

			container.CreditAppDetails =
				container.firstName +
				" " +
				container.lastName +
				" " +
				container.contactEmail +
				" " +
				container.cellPhone +
				" " +
				container.phone +
				" " +
				container.origin +
				" " +
				container.status;

			container.lookingFor =
				container.vehicleStockNum +
				" " +
				container.vin +
				" " +
				container.make +
				" " +
				container.model +
				" " +
				container.year;

			container.inventoryID = content[key].inventoryID;
			//container.customerID = content[key].customerID;

			container.source = content[key].source;
			container.lastSync = content[key].lastSync;

			container.Actions = (
				<Row style={{ gap: "6px" }}>
					<Button
						className="zoom btn-sm btn-info"
						onClick={(e) => {
							e.stopPropagation();
							setTextHistoryNumber(
								content[key].mobilePhone || content[key].phone
							);
							setCustomerID(content[key].ID);
							toggleViewTextModal();
						}}
					>
						<i className="icon-big text-center nc-icon nc-chat-33 text-default" />
						&nbsp;Chat
					</Button>
					<Button
						className="zoom btn-sm btn-primary"
						onClick={(e) => {
							e.stopPropagation();
							handleView(content[key].creditAppID);
						}}
					>
						<i className="icon-big text-center nc-icon nc-paper text-default" />
						&nbsp;View
					</Button>
					<Button
						className="zoom btn-sm btn-primary"
						onClick={(e) => {
							e.stopPropagation();
							setTmpCreditApp(content[key]);
							handleDeal(content[key]);
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
						&nbsp;Deal
					</Button>
				</Row>
			);

			return container;
		});

		return container;
	};

	const handleDeal = async (CreditApp) => {
		console.log(CreditApp.creditAppID);
		saveAsCustomer(CreditApp.creditAppID).then(
			(res) => {
				console.log(res);
				setCustomer(res.data.content.Customer);
				setCoBuyer(res.data.content.CoBuyer);
				toggleTemplate();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		console.log(customer);
		if (CreditApp.inventoryID > 0) {
			// Adds vehicle information to the deal store
			getVehicleDetails(CreditApp.inventoryID).then(
				(res) => {
					console.log(res);
					const v = res.data.content;
					setVehicle(v);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		} else {
			if (
				CreditApp.vehicleStockNum !== null &&
				CreditApp.vehicleStockNum !== ""
			) {
				getVehicleDetailsByStockNo(
					CreditApp.toLocationID,
					CreditApp.vehicleStockNum
				).then(
					(res) => {
						console.log(res);
						const v = res.data.content;
						setVehicle(v);
					},
					(err) => {
						if (!err.isGeneralError) {
							showApiError(err, retrieveFailAlert);
						}
					}
				);
			}
		}
		console.log(vehicle);
	};

	const handleView = (id) => {
		getCreditAppDetails(id).then(
			(res) => {
				setTmpCreditApp(res.data.content);
				toggle();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Gets a list of all the CreditApps for this dealer and location
	const getListData = () => {
		setLoadingList(true);
		const result = dataFormat(creditSource);
		setData(result);
		setLoadingList(false);
	};

	useEffect(() => {
		getListData();
		// eslint-disable-next-line
	}, [creditSource]);

	return (
		<div>
			{/* <div className="pt-3 pb-3 mobileHeading">
				<Col className=" text-center">
					<CardTitle>
						<h2>Online Credit App</h2>
					</CardTitle>
				</Col>
			</div> */}
			{loadingList && <Loading />}
			{!loadingList && (
				<CreditAppTable
					onRowClick={handleRowClick}
					data={data}
					setMessageCustomers={setMessageCustomers}
				/>
			)}
			<ChildrenModal
				modalTitle="CreditApp"
				modal={showModal}
				toggle={toggle}
				onClosed={getListData}
			>
				<OnlineCreditAppDetailsPage creditApp={tmpCreditApp} toggle={toggle} />
			</ChildrenModal>
			<ChildrenModal
				modalTitle="Deal Templates"
				modal={templateModal}
				toggle={toggleTemplate}
			>
				<DealTemplateModal
					customer={customer}
					coCustomer={coBuyer}
					newVehicle={vehicle}
				/>
			</ChildrenModal>
			<ChildrenModal
				size="lg"
				modal={viewTextModal}
				toggle={toggleViewTextModal}
				modalTitle="Send Message"
			>
				<TwilioSendMessage
					sendToNumber={textHistoryNumber}
					customerID={customerID}
					personType="CreditApp"
				/>
			</ChildrenModal>
		</div>
	);
};

export default OnlineCreditAppListPage;
