import React from "react";
import PropTypes from "prop-types";
import NavBar from "components/tabs/TabsHolder";
import FormsReportsList from "features/forms/components/FormsReportsList";
import PacksList from "features/forms/components/PacksList";
import { PackEditProvider } from "features/forms/context/PackContexts";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import DealerTempSelector from "features/forms/components/DealerTempSelector";

/**
 * Forms component handles the display of different types of forms in tabs.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const Forms = () => {
	const { carRecNum } = useCurrentDealStore(
		(state) => ({
			carRecNum: state.deal.carRecNum,
		}),
		shallow
	);

	const tabs = ["State", "Standard", "OKI", "Custom", "Packs"];

	const tabComponents = [
		<FormsReportsList
			key="stateForms"
			listTitle="State Forms"
			listName="stateForms"
			searchable
			eSign
		/>,
		<FormsReportsList
			key="standardForms"
			listTitle="Standard Forms"
			listName="standardForms"
			searchable
			eSign
		/>,
		<FormsReportsList
			key="okiForms"
			listTitle="OKI/Dot Matrix"
			listName={"okiForms"}
			searchable
			eSign
		/>,
		<FormsReportsList
			key="customForms"
			listTitle="Requested Forms"
			listName="customForms"
			searchable
			eSign
		/>,
		<PackEditProvider key="packsList">
			<PacksList />
		</PackEditProvider>,
	];

	const invTabs = ["State", "Standard", "Inventory", "OKI", "Custom", "Packs"];

	const invTabComponents = [
		<FormsReportsList
			key="stateForms"
			listTitle="State Forms"
			listName="stateForms"
			searchable
			eSign
		/>,
		<FormsReportsList
			key="standardForms"
			listTitle="Standard Forms"
			listName="standardForms"
			searchable
			eSign
		/>,
		<FormsReportsList
			key="inventoryForms"
			listTitle="Inventory Forms"
			listName="inventoryForms"
			searchable
			inventoryID={carRecNum}
			eSign
		/>,
		<FormsReportsList
			key="okiForms"
			listTitle="OKI/Dot Matrix"
			listName="okiForms"
			searchable
			eSign
		/>,
		<FormsReportsList
			key="customForms"
			listTitle="Custom Forms"
			listName="customForms"
			searchable
			eSign
		/>,
		<PackEditProvider key="packsList">
			<PacksList />
		</PackEditProvider>,
	];

	return (
		<div className="p-3 justify-content-center align-items-center forms-table">
			<DealerTempSelector />
			<NavBar
				navClass="nav-pills nav-pills-primary justify-content-center nav"
				tabs={carRecNum === null ? tabs : invTabs}
				tabComponents={carRecNum === null ? tabComponents : invTabComponents}
			/>
		</div>
	);
};

Forms.propTypes = {
	carRecNum: PropTypes.number,
};

export default Forms;
