import React from "react";
import { Button } from "reactstrap";

/**
 * Renders a <table> header <th> with a name and two button
 * icons underneath which are used to call the sorting functions
 * for this header's column.
 *
 * @param {String} header - Name of the header
 * @callback sortAsc - Call the sort ascending function
 * @callback sortDesc - Call the sort descending function
 */
const DigitzsPaymentTableHeader = ({ header, sortAsc, sortDesc }) => {
	return (
		<th>
			<div className="row-12 d-flex justify-content-center">
				{header}
				<Button className="btn-sm col-2 m-0" onClick={sortAsc} color="neutral">
					<i className="fa fa-angle-up" />
				</Button>
				<Button className="btn-sm col-2 m-0" onClick={sortDesc} color="neutral">
					<i className="fa fa-angle-down" />
				</Button>
			</div>
		</th>
	);
};

export default DigitzsPaymentTableHeader;
