import React from "react";

import { Label, Input } from "reactstrap";
import { LoanPeriod, SaleType } from "constants/Constants";
/**
 * @summary Selector used to build simple dropdowns with predefined values
 * @param string selectType used to generate the type of options to display
 * @param string selectLabel used to print the label for the selector
 * @param func handleChange for onChange
 * @param object selectedOption for state of selector
 */
/*
  Prop selectType will be passed in, conditionals below will determine what options should be generated
  selectType is imported from Constants, selectLabel will fill in the label for the selector
  We can add more if more selectors need to be made
*/
const NumberSelector = (props) => {
	let options;

	if (props.selectType === "loanPeriod") {
		options = LoanPeriod;
	} else if (props.selectType === "saleType") {
		options = SaleType;
	}

	if (props.default === true) {
		return (
			<Input
				name={props?.name}
				readOnly={props.readOnly}
				type="select"
				value={props.selectedOption}
				onChange={(event) => {
					props.handleChange(parseInt(event.target.value));
				}}
			>
				{options.map((option, index) => (
					<option key={index} value={option.value}>{option.label}</option>
				))}
			</Input>
		);
	} else {
		return (
			<div>
				<Label>{props.selectLabel}</Label>
				<Input
					name={props?.name}
					readOnly={props.disabled}
					type="select"
					value={props.selectedOption}
					
					onChange={(event) => {
						props.handleChange(parseInt(event.target.value));
					}}
				>
					{options.map((option, index) => (
						<option key={index} value={option.value}>{option.label}</option>
					))}
				</Input>
			</div>
		);
	}
};

export default NumberSelector;
