import React, { useState } from "react";
import { Button, Col } from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";
import useCurrentDealStore from "stores/DealStore";
import ChildrenModal from "components/modals/ChildrenModal";
import CustomerEmploymentHistoryForm from "./CustomerEmploymentHistoryForm";
import EmployeeHistoryTable from "./EmployeeHistoryTable";

import { DealStatus } from "constants/Constants";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import shallow from "zustand/shallow";

const EmployeeHistoryTab = ({ isDeal = false }) => {
	// Deal Store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);
	// Customer Store
	const { empHist } = useCurrentCustomerStore(
		(state) => ({
			empHist: state.empHist,
		}),
		shallow
	);

	// Local state
	const [currentIndex, setCurrentIndex] = useState(empHist.length);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		addEmploymentCompany: yup.string().required().max(50),
		addEmploymentPosition: yup.string().required().max(50),
		addEmploymentSalary: yup.string().required().max(50),
		addEmploymentSalaryType: yup.string().required().max(50),
		addEmploymentType: yup.string().required().max(50),
		addEmploymentPhone: yup
			.string()
			.required()
			.matches(
				/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}/,
				"ex: +1 (123) 123-1234"
			),
		addEmploymentContact: yup.string().required().max(50),
		addEmploymentStreet: yup.string().required().max(50),
		addEmploymentCity: yup.string().required().max(50),
		addEmploymentState: yup.string().required(),
		addEmploymentFromDate: yup
			.date()
			.required("Required")
			.nullable()
			.transform((curr, orig) => (orig?.length === 10 ? curr : null)),
		addEmploymentToDate: yup
			.date()
			.nullable()
			.transform((curr, orig) => (orig?.length === 10 ? curr : null))
			.min(
				yup.ref("addEmploymentFromDate"),
				"To date cannot be before from date"
			),
		addEmploymentEmail: yup
			.string()
			.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, {
				message: "Invalid Email",
				excludeEmptyString: true,
			}),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<Col>
				<Button
					className="btn btn-primary btn-sm mx-3 mb-2"
					readOnly={type !== DealStatus.PENDING}
					onClick={toggle}
				>
					<i className="nc-icon nc-simple-add"></i> Add Job
				</Button>
				<div className="col-md-6 text-right"></div>
			</Col>
			<FormProvider {...methods}>
				<div className="col">
					<ChildrenModal
						modal={modal}
						toggle={toggle}
						children={
							<CustomerEmploymentHistoryForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggle}
								isDeal={isDeal}
							/>
						}
					/>
					<EmployeeHistoryTable
						readOnly={type !== DealStatus.PENDING}
						setCurrentIndex={setCurrentIndex}
						toggle={toggle}
						isDeal={isDeal}
					/>
				</div>
			</FormProvider>
		</>
	);
};

export default EmployeeHistoryTab;
