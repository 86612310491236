import React from "react";
import NavBar from "components/tabs/TabsHolder";
import ProfitSettings from "../../../../settings/subFeatures/settingsTabs/dealDefault/ProfitSettings";
import CommissionSettings from "../../../../settings/subFeatures/settingsTabs/dealDefault/CommissionSetting";
import TaxDefaults from "../../../../settings/subFeatures/settingsTabs/dealDefault/TaxDefaults";
import shallow from "zustand/shallow";
import useCurrentSettingStore from "../../../../../stores/SettingStore";
import { saveSettings } from "../../../../../api/SettingsAPI";
import {
	saveFailAlert,
	saveSuccessAlert,
} from "../../../../../utils/alertUtils";
import { showApiError } from "../../../../../utils/errorRoutingUtils";

/**
 * Tax, profit, and commission settings for the settings page.
 * This tab modifies the settings for all deals created after modifying
 */
const DealDefaultSettings = () => {
	const tabs = ["Tax Settings", "Profit Settings", "Commission Settings"];

	const {
		profitSettings,
		taxDefaults,
		commissionSettings,
		editProfitSettings,
		editProfitSettingsValue,
		editCommissionSettings,
		editCommissionSettingsValue,
		editTaxDefaults,
		editTaxDefaultsValue,
	} = useCurrentSettingStore((state) => state, shallow);

	const save = () => {
		const settings = {
			taxDefaults,
			profitSettings,
			commissionSettings,
		};

		saveSettings(settings).then(
			(res) => {
				console.log(res);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const tabComponents = [
		<TaxDefaults
			isDeal={false}
			taxDefaults={taxDefaults}
			editTaxDefaults={editTaxDefaults}
			editTaxDefaultsValue={editTaxDefaultsValue}
		/>,
		<ProfitSettings
			isDeal={false}
			profitSettings={profitSettings}
			editProfitSettings={editProfitSettings}
			editProfitSettingsValue={editProfitSettingsValue}
		/>,
		<CommissionSettings
			isDeal={false}
			commissionSettings={commissionSettings}
			editCommissionSettings={editCommissionSettings}
			editCommissionSettingsValue={editCommissionSettingsValue}
		/>,
	];

	return (
		<div className="col">
			<NavBar
				navClass="nav-pills-secondary justify-content-center nav nav-pills"
				tabs={tabs}
				tabComponents={tabComponents}
			/>
			<div className="btn btn-success float-button" onClick={save}>
				<i className="nc-icon nc-check-2" /> Save
			</div>
		</div>
	);
};

export default DealDefaultSettings;
