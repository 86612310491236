import React from "react";
import { Form } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";

import {
	AccountOwnership,
	BankAccountType,
	BankruptcyFiled,
	typeOfGoodsServices,
} from "../../../../../../constants/Constants";

import { useFormContext } from "react-hook-form";
import InputElement, {
	SelectorElement,
	CheckBoxElement,
	CurrencyInputElement,
	IntegerInputElement,
} from "../../../../../forms/components/InputElement";

const ProfileInformation = ({
	profileInfo,
	creditAccountInfo,
	debitAccountInfo,
	backgroundInfo,
	setProfileInfo,
	setCreditAccountInfo,
	setDebitAccountInfo,
	setBackgroundInfo,
	setCurrentForm,
	nextForm,
	previousForm,
	disabled,
}) => {
	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}
		console.log(profileInfo);
		setCurrentForm(nextForm);
	};
	const methods = useFormContext();
	console.log(profileInfo);
	return (
		<div>
			<Form className="row" onKeyDown={formNavigationUtils}>
				<h3 className="col-12 section-title mb-3">Profile Information</h3>
				<CurrencyInputElement
					{...methods}
					disabled={disabled}
					value={profileInfo.averageAmexMonthlySalesVolume}
					onChange={(e) => setProfileInfo?.setAverageAmexMonthlySalesVolume(e)}
					name="profile.averageAmexMonthlySalesVolume"
					label="Average Amex Monthly Sales Volume"
					type="number"
					labelClass="required"
				/>
				<CurrencyInputElement
					{...methods}
					disabled={disabled}
					value={profileInfo.averageMonthlySalesVolume}
					name="profile.averageMonthlySalesVolume"
					label="Average Monthly Sales Volume"
					type="number"
					onChange={(e) => setProfileInfo?.setAverageMonthlySalesVolume(e)}
					labelClass="required"
				/>
				<IntegerInputElement
					{...methods}
					disabled={disabled}
					value={profileInfo.averageTicketSize}
					onChange={(e) => setProfileInfo?.setAverageTicketSize(e)}
					name="profile.averageTicketSize"
					label="Average Ticket Size"
					type="number"
					labelClass="required"
				/>
				{/* 
				Hard Codded this to "Retail"
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.businessType"
					label="Business Type"
					value={profileInfo.businessType}
					onChange={(e) => setProfileInfo?.setBusinessType(e.target.value)}
					options={SaleType}
					labelClass="required"
				/> */}
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={profileInfo.federalTaxId}
					onChange={(e) => setProfileInfo?.setFederalTaxId(e.target.value)}
					name="profile.federalTaxId"
					label="Federal Tax ID"
					type="text"
					labelClass="required"
				/>
				<IntegerInputElement
					{...methods}
					disabled={disabled}
					value={profileInfo.highestTicketSize}
					onChange={(e) => setProfileInfo?.setHighestTicketSize(e)}
					name="profile.highestTicketSize"
					label="Highest Ticket Size"
					type="number"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.typeOfGoodsServices"
					label="Type of Goods or Services"
					value={profileInfo.typeOfGoodsServices}
					onChange={(e) =>
						setProfileInfo?.setTypeOfGoodsServices(e.target.value)
					}
					options={typeOfGoodsServices}
					labelClass="required"
				/>

				<IntegerInputElement
					disabled={disabled}
					value={profileInfo.monthsInBusiness}
					onChange={(e) => setProfileInfo?.setMonthsInBusiness(e)}
					name="profile.monthsInBusiness"
					label="Months In Business"
					type="number"
					labelClass="required"
				/>
				<IntegerInputElement
					disabled={disabled}
					value={profileInfo.yearInBusiness}
					onChange={(e) => setProfileInfo?.setYearInBusiness(e)}
					name="profile.yearInBusiness"
					label="Years in Business"
					type="number"
					labelClass="required"
					colSize="col-sm-3"
				/>
				<h5 className="col-12  mb-3"> </h5>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.ownersBackground.bankruptcyFiled"
					label="Bankruptcy Filed"
					value={backgroundInfo.bankruptcyFiled}
					onChange={(e) => {
						setBackgroundInfo.setBankruptcyFiled(e.target.value);
						if (e.target.value === "Never Filed") {
							setBackgroundInfo.setBankruptcyEverTerminated(false);
							setBackgroundInfo.setBankruptcyFiledYear(0);
						}
					}}
					options={BankruptcyFiled}
					labelClass="required required"
				/>
				<IntegerInputElement
					readOnly={
						disabled || backgroundInfo.bankruptcyFiled === "Never Filed"
							? true
							: false
					}
					value={backgroundInfo.bankruptcyFiledYear}
					onChange={(e) => setBackgroundInfo?.setBankruptcyFiledYear(e)}
					name="profile.ownersBackground.bankruptcyFiledYear"
					label="Years in Bankruptcy"
					type="number"
					labelClass="required"
				/>
				<CheckBoxElement
					{...methods}
					readOnly={
						disabled || backgroundInfo.bankruptcyFiled === "Never Filed"
							? true
							: false
					}
					checked={backgroundInfo.bankruptcyEverTerminated}
					onChange={() =>
						setBackgroundInfo?.setBankruptcyEverTerminated(
							!backgroundInfo.bankruptcyEverTerminated
						)
					}
					name="profile.ownersBackground.bankruptcyEverTerminated"
					label="Bankruptcy Terminated"
					labelClass="pl-4 required"
				/>
				<h5 className="col-12 mb-3"> </h5>
				{/* <Label className="required">Reason for Bankruptcy</Label>
				<Input type="textarea" disabled={disabled}></Input> */}

				<h5 className="col-12 mb-3">Credit Banking Information</h5>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={creditAccountInfo.creditAccountNumber}
					onChange={(e) =>
						setCreditAccountInfo?.setCreditAccountNumber(e.target.value)
					}
					name="profile.creditBankingInformation.accountNumber"
					label="Account Number"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={creditAccountInfo.creditRoutingNumber}
					onChange={(e) =>
						setCreditAccountInfo?.setCreditRoutingNumber(e.target.value)
					}
					name="profile.debitBankingInformation.routingNumber"
					label="Routing Number"
					type="text"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.creditBankingInformation.accountOwnership"
					label="Account Ownership"
					value={creditAccountInfo.creditAccountOwnership}
					onChange={(e) =>
						setCreditAccountInfo?.setCreditAccountOwnership(e.target.value)
					}
					options={AccountOwnership}
					labelClass="required"
				/>

				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.creditBankingInformation.accountType"
					label="Account Type"
					value={creditAccountInfo.creditAccountType}
					onChange={(e) =>
						setCreditAccountInfo?.setCreditAccountType(e.target.value)
					}
					options={BankAccountType}
					labelClass="required"
				/>
				<h5 className="col-12 mb-3">Debit Banking Information</h5>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={debitAccountInfo.debitAccountNumber}
					onChange={(e) =>
						setDebitAccountInfo?.setDebitAccountNumber(e.target.value)
					}
					name="profile.debitBankingInformation.accountNumber"
					label="Account Number"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={debitAccountInfo.debitRoutingNumber}
					onChange={(e) =>
						setDebitAccountInfo?.setDebitRoutingNumber(e.target.value)
					}
					name="profile.debitBankingInformation.routingNumber"
					label="Routing Number"
					type="text"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.debitBankingInformation.accountOwnership"
					label="Account Ownership"
					value={debitAccountInfo.debitAccountOwnership}
					onChange={(e) =>
						setDebitAccountInfo?.setDebitAccountOwnership(e.target.value)
					}
					options={AccountOwnership}
					labelClass="required"
				/>

				<SelectorElement
					{...methods}
					disabled={disabled}
					name="profile.debitBankingInformation.accountType"
					label="Account Type"
					value={debitAccountInfo.debitAccountType}
					onChange={(e) =>
						setDebitAccountInfo?.setDebitAccountType(e.target.value)
					}
					options={BankAccountType}
					labelClass="required"
				/>
			</Form>
			{!disabled && (
				<div className="col-12">
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button className="next-with-previous" onClick={handleNext}>
						Next
					</button>
				</div>
			)}
		</div>
	);
};
export default ProfileInformation;
