import React from "react";
import { Form } from "reactstrap";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement, {
	CheckBoxElement,
} from "../../../../forms/components/InputElement";
import { vehicleStatus } from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";

const EngineInfo = () => {
	// Vehicle store
	const vehicle = useCurrentVehicleStore((state) => state, shallow);

	// Define custom error messages for different error types
	const methods = useFormContext();

	return (
		<Form className="pt-2" onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Engine"
				inputComponents={[
					<InputElement
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Make"
						value={vehicle.engine2Make}
						type="text"
						onChange={(e) => vehicle.editEngine2Make(e.target.value)}
						name="engine2Make"
					/>,
					<InputElement
						disable={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						label="Engine"
						value={vehicle.engine2}
						onChange={(e) => vehicle.editEngine2(e.target.value)}
						type="text"
						name="engine2"
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.engine2Year}
						name="engine2Year"
						label="Year"
						type="text"
						onChange={(e) => {
							vehicle.editEngine2Year(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.vin2}
						name="vin2"
						label="SerialNo(VIN)"
						type="text"
						onChange={(e) => {
							vehicle.editVin2(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.horsePower}
						name="horsePower"
						label="Horse Power"
						type="text"
						onChange={(e) => {
							vehicle.editHorsePower(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.diffRatio}
						name="diffRatio"
						label="Diff Ratio"
						type="text"
						onChange={(e) => {
							vehicle.editDiffRatio(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.tankCount}
						name="tankCount"
						label="Tank Count"
						type="number"
						onChange={(e) => {
							vehicle.editTankCount(e);
						}}
					/>,
					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.tankCapacity}
						name="tankCapacity"
						label="Tank Capacity"
						type="text"
						onChange={(e) => {
							vehicle.editTankCapacity(e.target.value);
						}}
					/>,
					<CheckBoxElement
						disableValidation
						checked={vehicle.engineBrake}
						onChange={() => {
							vehicle.editEngineBrake(!vehicle.engineBrake);
						}}
						name="engineBrake"
						label="Engine Brake"
						//colSize="col-sm-3"
						labelClass="pl-4"
					/>,
				]}
			/>
		</Form>
	);
};

export default EngineInfo;
