import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Button, Row } from "reactstrap";

import useCurrentLoginStore from "stores/LoginStore";
import InputWrapper from "features/forms/components/InputWrapper";
import NewUserPassword from "../new-user/NewUserPassword";
import PasswordChecklist from "../new-user/PasswordCheckList";

import { failAlert } from "utils/alertUtils";
import { updateUserPassword } from "api/LoginAPI";
import { PATHS } from "constants/Constants";

import { useFormContext } from "react-hook-form";
import { successAlertCustom } from "utils/alertUtils";
//import SingleImageUpload from "../../../inventory/subFeatures/image/SingleImageUpload";
//import useIsOwnerOrAdmin from "features/settings/subFeatures/settingsTabs/userManagement/useIsOwnerOrAdmin";
import shallow from "zustand/shallow";

const UpdatePasswordForm = () => {
	//	const isOwnerOrAdmin = useIsOwnerOrAdmin();
	const history = useHistory();

	const [oldPassword, setOldPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);

	// Local state
	// const [imageFile, setImageFile] = useState(null);

	//const loggedInUsername = useCurrentLoginStore((state) => state.username);
	// Login store
	// eslint-disable-next-line no-unused-vars
	const { profilePicture, editProfilePicture, username } = useCurrentLoginStore(
		(state) => state,
		shallow
	);

	// Updates the user password
	const updatePassword = () => {
		const data = {
			username: username,
			password: oldPassword,
			newPassword,
		};

		updateUserPassword(data).then(
			(res) => {
				if (!username) {
					history.push(PATHS.LOGIN);
				}

				successAlertCustom(res.data.message);
			},
			(e) =>
				failAlert(
					e?.response?.data?.message ||
						"Failed to update password, please try again later."
				)
		);
	};

	// Get validation context
	const methods = useFormContext();

	// form title formatted
	//eslint-disable-next-line
	const formTitle = "🔒 Update Password";

	return (
		<Row>
			{/* <Col className="mx-auto mr-auto content" lg="5" md="5">
				<SingleImageUpload
					containerWidth="w-200 mb-5"
					minWidth={150}
					minHeight={150}
					maxWidth={800}
					maxHeight={800}
					file={imageFile}
					setFile={setImageFile}
					disablePhotoChange={!isOwnerOrAdmin}
					picData={profilePicture}
					setPicData={editProfilePicture}
				/>
			</Col> */}
			<Form className="form" style={{ maxWidth: "600px" }}>
				<InputWrapper
					formTitle={formTitle}
					centerTitle
					inputComponents={[
						<NewUserPassword
							{...methods}
							colSize="col-lg-12"
							value={oldPassword}
							onChange={(e) => setOldPassword(e.target.value)}
							name="oldPassword"
							label="Old Password"
						/>,
						<NewUserPassword
							{...methods}
							colSize="col-lg-12"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							name="newPassword"
							label="New Password"
						/>,
						<PasswordChecklist password={newPassword} />,
						<NewUserPassword
							{...methods}
							colSize="col-lg-12"
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
							name="confirmPassword"
							label="Confirm Password"
						/>,
					]}
					buttons={
						<div className="d-flex justify-content-center mt-4">
							<Button
								className="btn btn-default"
								color="primary"
								onClick={methods.handleSubmit(updatePassword)}
							>
								Update Password
							</Button>
						</div>
					}
				/>
			</Form>
		</Row>
	);
};

export default UpdatePasswordForm;
