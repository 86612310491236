import React from "react";

const PepperpayProgressBar = ({ currentForm, setCurrentForm }) => {
	const tabNames = [
		"Main Information",
		"Business Information",
		"Owner Information",
		"Profile Information",
		"questionnaire",
		"Summary",
	];

	const handleClick = (tab) => {
		setCurrentForm(tab);
	};

	return (
		<ul>
			{tabNames.map((name, i) => (
				<li
					key={i}
					onClick={() => handleClick(i)}
					className={`${
						(currentForm === i && "active") || (currentForm > i && "complete")
					}`}
				>
					{name}
				</li>
			))}
		</ul>
	);
};

export default PepperpayProgressBar;
