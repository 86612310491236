import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody, CardHeader } from "reactstrap";
import dayjs from "dayjs";
import { LoanPeriod } from "constants/Constants";
import ReviewRow from "./ReviewRow";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentRecapStore from "stores/RecapStore";
import shallow from "zustand/shallow";

/**
 * ReviewFinance component to display financing details.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewFinance component
 */
const ReviewFinance = () => {
	const {
		amtFinanced,
		apr,
		financeCharge,
		loan,
		term,
		payment,
		finalPmt,
		loanDate,
		daysToPay,
		firstDueDate,
		finalDueDate,
		loanPeriod,
		onePay,
	} = useCurrentSaleStore(
		(state) => ({
			amtFinanced: state.amtFinanced,
			apr: state.apr,
			financeCharge: state.financeCharge,
			loan: state.loan,
			term: state.term,
			payment: state.payment,
			finalPmt: state.finalPmt,
			loanDate: state.loanDate,
			daysToPay: state.daysToPay,
			firstDueDate: state.firstDueDate,
			finalDueDate: state.finalDueDate,
			loanPeriod: state.loanPeriod,
			onePay: state.onePay,
		}),
		shallow
	);

	const { totalDown } = useCurrentRecapStore(
		(state) => ({
			totalDown: state.totalDown,
		}),
		shallow
	);

	/**
	 * Gets the loan period label based on the loan period value.
	 *
	 * @returns {string} The loan period label
	 */
	const getLoanPeriod = () => {
		const periodLabel = LoanPeriod.find(
			(element) => element.value === loanPeriod
		);
		return periodLabel?.label || "";
	};

	const periodString = getLoanPeriod();

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Financing</CardTitle>
			</CardHeader>
			<CardBody>
				<ReviewRow isCurrency={true} label="Total Price" value={onePay} />
				<h6 className="text-danger">
					<ReviewRow isCurrency={true} label="Total Down" value={totalDown} />
				</h6>
				<h6>
					<ReviewRow
						isCurrency={true}
						label="Amt Financed"
						value={amtFinanced}
					/>
				</h6>
				<ReviewRow />
				<ReviewRow isPercent={true} label="APR" value={apr} />
				<ReviewRow
					isCurrency={true}
					label="Finance Charge"
					value={financeCharge}
				/>
				<ReviewRow isCurrency={true} label="Total Payments" value={loan} />
				<ReviewRow
					isCurrency={true}
					label={`${term - 1} Payments of`}
					value={payment}
				/>
				<ReviewRow isCurrency={true} label="Final Payment" value={finalPmt} />
				<ReviewRow
					label="Sale Date"
					value={dayjs(loanDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow label="Days to pay" value={daysToPay} />
				<ReviewRow
					label="First Due Date"
					value={dayjs(firstDueDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow
					label="Final Due Date"
					value={dayjs(finalDueDate).format("MM/DD/YYYY")}
				/>
				<ReviewRow label="Period" value={periodString} />
			</CardBody>
		</Card>
	);
};

ReviewFinance.propTypes = {
	amtFinanced: PropTypes.number,
	apr: PropTypes.number,
	financeCharge: PropTypes.number,
	loan: PropTypes.number,
	term: PropTypes.number,
	payment: PropTypes.number,
	finalPmt: PropTypes.number,
	loanDate: PropTypes.string,
	daysToPay: PropTypes.number,
	firstDueDate: PropTypes.string,
	finalDueDate: PropTypes.string,
	loanPeriod: PropTypes.string,
	onePay: PropTypes.number,
	totalDown: PropTypes.number,
};

export default ReviewFinance;
