import React from "react";
import { useState, useEffect } from "react";

import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	getInventoryStatusByDateRenge,
	getNumberOfDealsByDateRange,
	//	getNumberOfCustomers,
} from "api/DealAPI";
import { getCreditAppListByDateRange } from "api/CreditAppApi";
import { getLeadListByDateRange } from "api/LeadApi";
import PageHeader from "components/header/PageHeader";
import { useHistory } from "react-router-dom";
import useDateRangeStore from "stores/DateRangeStore";

const DashboardHeader = () => {
	const { fromDate, toDate } = useDateRangeStore();
	const [availableVehicle, setAvailable] = useState(0);
	const [pendingVehicle, setPending] = useState(0);
	const [pendingDeal, setPendingDeal] = useState(0);
	const [status, setStatus] = useState("Leads");
	// eslint-disable-next-line
	const [invData, setInvData] = useState([]);
	//eslint-disable-next-line
	const [data, setData] = useState([]);
	const [totalDeal, setTotalDeal] = useState([]);
	const [totalInv, setTotalInv] = useState([]);
	// const [totalCustomer, setTotalCustomer] = useState([]);
	const [onlineLead, setOnlineLead] = useState(0);
	const [creditApp, setCreditApp] = useState(0);
	const locationID = useFromUser("locationID");

	const dataFormat = (content, setHook, tabBox) => {
		var total = 0;
		setPendingDeal(0);

		const container = Object.keys(content).map((key) => {
			total = total + content[key].numOfType;
			// if (content[key].typeDesc === "Leads") {
			// 	setLeads(content[key].numOfType);
			// }
			if ((content[key].typeDesc === "Pending") & (tabBox === "Deal")) {
				setPendingDeal(content[key].numOfType);
			}
			if ((content[key].typeDesc === "Pending") & (tabBox === "Vehicle")) {
				setPending(content[key].numOfType);
			}
			if ((content[key].typeDesc === "Available") & (tabBox === "Vehicle")) {
				setAvailable(content[key].numOfType);
			}

			return (
				<li key={key.toString()}>
					<div>
						{content[key].typeDesc} : {content[key].numOfType}
					</div>
				</li>
			);
		});
		setHook(total);
		return container;
	};

	const getListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getNumberOfDealsByDateRange(LocationID, fromDate, toDate).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(
						response.data.content,
						setTotalDeal,
						"Deal"
					);
					setData(result);
					console.log(totalDeal);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	const getInvListData = (LocationID) => {
		console.log(LocationID);
		if (locationID !== "") {
			getInventoryStatusByDateRenge(LocationID, fromDate, toDate).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(
						response.data.content,
						setTotalInv,
						"Vehicle"
					);
					setInvData(result);
					console.log(totalInv);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	// Function to handle card clicks
	const handleCardClick = (statusValue) => {
		setStatus(statusValue);
	};

	const history = useHistory();

	useEffect(() => {
		getListData(locationID);
		getInvListData(locationID);
		// NumberOfCustomers(locationID);

		// eslint-disable-next-line
	}, [fromDate, toDate]);

	useEffect(() => {
		//value 1 = active status
		getCreditAppListByDateRange(locationID, fromDate, toDate).then(
			(res) => {
				setCreditApp(res.data.content.NumOfRecord);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		getLeadListByDateRange(locationID, fromDate, toDate).then(
			(res) => {
				if (res.data.content.NumOfRecord !== undefined && res.data.content.NumOfRecord !== null) {
					setOnlineLead(res.data.content.NumOfRecord);
				} else {
					setOnlineLead(0); // or any default value you prefer
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
		// eslint-disable-next-line
	}, [creditApp, onlineLead, locationID, fromDate, toDate]);



	return (
		<PageHeader
			titlePrefix="Dashboard"
			title="Overview"
			cards={[
				{
					title: "Active Leads",
					count: onlineLead,
					routePath: "/lead-add",
					iconClass: "nc-icon nc-laptop text-info",
					iconText: " Add New",
					onCardClick: () => history.push("/customer-list"),
					isActive: status === false,
				},
				{
					title: "Contacts",
					count: creditApp,
					routePath: "/customer-add",
					iconClass: "nc-icon nc-single-02 text-info",
					iconText: " Add Contact",
					onCardClick: () => history.push("/customer-list"),
				},
				{
					title: "Pending Deals",
					count: pendingDeal,
					routePath: "/deal-add",
					iconClass: "nc-icon nc-briefcase-24 text-success",
					iconText: " Add Deal",
					onCardClick: () => history.push("/deal-list"),
				},
				{
					title: "Inventory",
					count: pendingVehicle + availableVehicle,
					routePath: "/inventory-add",
					iconClass: "nc-icon nc-delivery-fast text-warning",
					iconText: " Add Vehicle",
					onCardClick: () => history.push("/inventory-list"),
				},
			]}
			onCardClick={handleCardClick}
		/>
	);
};

export default DashboardHeader;
