import React, { useState } from "react";
import PropTypes from "prop-types";
import ChildrenModal from "components/modals/ChildrenModal";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import MoneyDownTable from "./MoneyDownTable";
import { MoneyDownForm } from "./MoneyDownForm";

/**
 * MoneyDownModal component renders a modal for managing down payments.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.modal - Controls the modal's visibility.
 * @param {Function} props.toggle - Function to toggle the modal visibility.
 * @returns {JSX.Element} The rendered MoneyDownModal component.
 */
const MoneyDownModal = ({ modal, toggle }) => {
	const { downPayments } = useCurrentDealStore(
		(state) => ({
			downPayments: state.downPayments,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(downPayments?.length);
	const [open, setOpen] = useState(false);

	/**
	 * Toggles the nested modal visibility.
	 */
	const toggleOpen = () => setOpen(!open);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			modalTitle="Down Payments"
			size="lg"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<MoneyDownForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>
					<MoneyDownTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
				</>
			}
		/>
	);
};

MoneyDownModal.propTypes = {
	modal: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default MoneyDownModal;
