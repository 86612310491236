import React, { useContext, useEffect, useState } from "react";
import { Form, Button, FormGroup, Label } from "reactstrap";
import Moment from "moment";
import { saveSuccessAlert, saveFailAlert, failAlert } from "utils/alertUtils";
import AddVendorModal from "components/modals/AddVendorModal";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { useFromUser } from "stores/LocalStorageHelper";
import { showApiError } from "utils/errorRoutingUtils";
import { saveReconditioning, saveVehicle } from "api/InventoryAPI";

import useCurrentVehicleStore from "stores/InventoryStore";
import InputElement, {
	CurrencyInputElement,
} from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";
import Selector from "../../selector/Selector";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider, Controller } from "react-hook-form";
import useBuildVehicle from "hooks/useBuildVehicle";
import { vendorTypesOBJ } from "constants/Constants";
import { saveVendor } from "api/VendorsAPI";
import formNavigationUtils from "utils/formNavigationUtils";
import shallow from "zustand/shallow";
import { KbbContext } from "../valuesTab/KbbContext";

const InventoryCostForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	// Local states
	const [openAddVendorModal, setOpenAddVendorModal] = useState(false);
	const [vendorList, setVendorList] = useState([]);

	const {
		editTotalRecon,
		editTotalCost,
		editTotalFlooring,
		editTotalOtherCost,
		inventoryID,
		costHist,
		editCostHist,
		costDescList,
		departmentsList,
		reconVendorsList,
		flooringVendorsList,
		editReconVendorsList,
		editFlooringVendorsList,
	} = useCurrentVehicleStore((state) => state, shallow);

	const getVehicle = useBuildVehicle();

	// Local storage
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Local states
	//import dayjs from "dayjs";
	//const today = dayjs().format("YYYY-MM-DD");
	const [costType, setCostType] = useState(0);
	const [vendorRecNum, setVendorRecNum] = useState(0);
	const [vendorName, setVendorName] = useState("");
	const [description, setDescription] = useState("");
	const [date, setDate] = useState(Moment().format("YYYY-MM-DD").toString());
	const [cost, setCost] = useState(0);
	const [labor, setLabor] = useState(0);
	const [parts, setParts] = useState(0);
	const [taxPaid, setTaxPaid] = useState(0);
	const [invoiceNumber, setInvoiceNumber] = useState("");
	const [checkNum, setCheckNum] = useState("");
	const [roNumber, setRoNumber] = useState(""); //Repair Order
	const [poNumber, setPoNumber] = useState(""); //Purchase Order
	const [department, setDepartment] = useState("");
	const [employee, setEmployee] = useState(""); //Assign To
	const [dateCompleted, setDateCompleted] = useState("");

	const [buttonText, setButtonText] = useState("Add Cost");

	const { setDcKbbObj, getDcKbbObj } = useContext(KbbContext);

	// Clears local states
	const clearFields = () => {
		setButtonText("Add Cost");
		setCostType(0);
		setVendorRecNum(0);
		setDescription("");
		setDate(Moment().format("YYYY-MM-DD"));
		setCost(0);
		setLabor(0);
		setParts(0);
		setTaxPaid(0);
		setInvoiceNumber("");
		setCheckNum("");
		setRoNumber(""); //Repair Order
		setPoNumber(""); //Purchase Order
		setDepartment("");
		setEmployee("");
		setDateCompleted("");
		setVendorName("");
	};

	// Toggles the add cost modal

	const addCost = () => {
		console.log(costHist);
		const getID = () => {
			if (costHist[currentIndex] === undefined) {
				return null;
			} else if (costHist[currentIndex].ID === undefined) {
				return null;
			} else {
				return costHist[currentIndex].ID;
			}
		};
		const ID = getID();
		const newCost = {
			ID,
			carRecNum: inventoryID,
			costType,
			vendorRecNum,
			description,
			date,
			cost: parseFloat(cost),
			labor: parseFloat(labor),
			parts: parseFloat(parts),
			taxPaid: parseFloat(taxPaid),
			invoiceNumber,
			checkNum,
			roNumber,
			poNumber,
			department,
			employee,
			dateCompleted,
			dealerID,
			locationID,
		};

		console.log(newCost);
		console.log("Current index is: " + currentIndex);

		saveReconditioning(newCost).then(
			(response) => {
				console.log(response);
				const CostList = () => {
					let obj = [...costHist];
					newCost.ID = response.data.content.ID;
					obj[currentIndex] = newCost;
					return obj;
				};
				const newCostList = CostList();
				editCostHist(newCostList);
				setCurrentIndex(newCostList.length);
				clearFields();
				saveSuccessAlert();
				toggle();
			},
			(err) => {
				console.log(err);

				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
		const inventoryVehicle = getVehicle();
		const kbb = getDcKbbObj();
		const request = {
			vehicle: inventoryVehicle,
			kbb: kbb,
		};
		saveVehicle(request).then(
			(response) => {
				console.log(response);
				editTotalRecon(response.data.content.vehicle.totalRecon);
				editTotalFlooring(response.data.content.vehicle.flooring);
				editTotalOtherCost(response.data.content.vehicle.totalOtherCost);
				editTotalCost(response.data.content.vehicle.totalCost);
				setDcKbbObj(response.data.content.kbb);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};
	// Function to open the modal
	const toggleVendorModal = () => setOpenAddVendorModal(!openAddVendorModal);

	// Updates local state with the vendor selected or opens modal to add new vendor
	const selectVendor = (option) => {
		const value = option.value;

		if (value === "ADD") {
			toggleVendorModal();
		} else {
			setVendorName(option.label);
			setVendorRecNum(value);
		}
	};

	// Saves the new vendor and adds it to the list
	const saveNewVendor = (vendor) => {
		if (vendor != null) {
			const isHidden = false;
			//in order to save add isHidden
			const buildVendor = { ...vendor, isHidden };
			saveVendor(buildVendor).then(
				(res) => {
					const newVendor = {
						value: res.data.content.ID,
						label: res.data.content.name,
						obj: res.data.content,
					};
					const newList = [...vendorList, newVendor];
					setVendorList(newList);
					setVendorName(newVendor.label);
					setVendorRecNum(newVendor.value);

					if (Number(costType) === 2) {
						editFlooringVendorsList([...flooringVendorsList, res.data.content]);
					} else {
						editReconVendorsList([...flooringVendorsList, res.data.content]);
					}
				},
				(e) => {
					console.log(e);
					failAlert("Failed to save new vendor");
				}
			);
		}
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});
	const schema = yup.object().shape({
		cost: yup.string().required(),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const calculateCost = (Labor, Part, Tax) => {
		let totalCost = parseFloat(Labor) + parseFloat(Part) + parseFloat(Tax);
		return totalCost;
	};

	const setVenderlistOBJ = (type = null) => {
		let vendorOptions = [];
		console.log(costType);
		let checkType = type === null ? costType : type;
		if (
			checkType === "0" ||
			checkType === 0 ||
			checkType === "9" ||
			checkType === 9
		) {
			console.log(reconVendorsList);
			vendorOptions = reconVendorsList.map((vendor) => {
				return { value: vendor.ID, label: vendor.name, obj: vendor };
			});
		}
		if (checkType === "2" || checkType === 2) {
			console.log(flooringVendorsList);
			vendorOptions = flooringVendorsList.map((vendor) => {
				return { value: vendor.ID, label: vendor.name, obj: vendor };
			});
		}
		console.log(vendorOptions);
		return vendorOptions;
	};

	useEffect(() => {
		const list = setVenderlistOBJ();
		setVendorList(list);
		if (costType !== costHist[currentIndex]?.costType) {
			setVendorName(list[0]?.label || "");
			setVendorRecNum(list[0]?.value || null);
		}
		// eslint-disable-next-line
	}, [costType]);

	useEffect(() => {
		let Cost = calculateCost(labor, parts, taxPaid);
		setCost(Cost);
		// eslint-disable-next-line
	}, [labor, parts, taxPaid]);

	useEffect(() => {
		setCostType(costHist[currentIndex]?.costType || 0);
		//		const list = setVenderlistOBJ(costHist[currentIndex]?.costType);
		const list = setVenderlistOBJ(costHist[currentIndex]?.costType);
		console.log(costHist[currentIndex]);
		if (currentIndex < costHist.length) {
			setVendorList(list);
			console.log(costHist[currentIndex]);
			setVendorRecNum(costHist[currentIndex]?.vendorRecNum || 0);
			setDescription(costHist[currentIndex]?.description || "");
			setDate(costHist[currentIndex]?.date || Moment().format("YYYY-MM-DD"));
			setCost(costHist[currentIndex]?.cost || 0);
			setLabor(costHist[currentIndex]?.labor || 0);
			setParts(costHist[currentIndex]?.parts || 0);
			setTaxPaid(costHist[currentIndex]?.taxPaid || 0);
			setInvoiceNumber(costHist[currentIndex]?.invoiceNumber || "");
			setCheckNum(costHist[currentIndex]?.checkNum || "");
			setRoNumber(costHist[currentIndex]?.roNumber || ""); //Repair Order
			setPoNumber(costHist[currentIndex]?.poNumber || ""); //Purchase Order
			setDepartment(costHist[currentIndex]?.department || "");
			setEmployee(costHist[currentIndex]?.employee || "");
			setDateCompleted(costHist[currentIndex]?.dateCompleted || "");
			console.log(list);
			const vendorName = list.find(
				(obj) => costHist[currentIndex]?.vendorRecNum === obj.value
			);
			console.log(vendorName);
			setVendorName(vendorName?.label || "");
			setButtonText("Save");
		} else {
			setVendorList(list);
		}

		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	return (
		<FormProvider {...methods}>
			<Form className="px-0" onKeyDown={formNavigationUtils}>
				<InputWrapper
					inputComponents={[
						<Controller
							render={({ field }) => (
								<FormGroup className="col-md-3">
									<Selector
										name="addCostTypeSelector"
										selectedOption={
											costType === null || costType === "" ? 0 : costType
										}
										handleChange={setCostType}
										selectType="costType"
										selectLabel="Cost Type"
										{...field}
									/>
								</FormGroup>
							)}
							name="costType"
							defaultValue={0}
						/>,
						<Controller
							render={({ field }) => (
								<FormGroup className="col-md-3">
									<Label>Vendor</Label>
									<Select
										className="mb-3"
										noOptionsMessage={() => "No vendors available"}
										value={{
											value: vendorRecNum,
											label: vendorName,
										}}
										options={[
											...vendorList,
											{ value: "ADD", label: "Add new vendor" },
										]}
										onChange={(e) => selectVendor(e)}
										{...field}
									/>
									<AddVendorModal
										title="Add Vendor"
										vendorType={
											Number(costType) === 2
												? vendorTypesOBJ.FLOORING_VENDOR.value
												: vendorTypesOBJ.RECON_SUPPLIER.value
										}
										vendorLabel={vendorTypesOBJ.VENDOR.label}
										addVendor={saveNewVendor}
										toggle={toggleVendorModal}
										modal={openAddVendorModal}
										{...field}
									/>
								</FormGroup>
							)}
							name="vendorList"
							defaultValue={vendorRecNum || "0"}
						/>,
						<Controller
							render={({ field }) => (
								<FormGroup className="col-md-3">
									<div>
										<Label>Description</Label>
										<CreatableSelect
											value={{
												value: description,
												label: description,
											}}
											options={costDescList.map((value) => ({
												value: value,
												label: value,
											}))}
											onChange={(e) => {
												console.log(e);
												setDescription(e.value);
											}}
											{...field}
										/>
									</div>
								</FormGroup>
							)}
							name="descriptionList"
							defaultValue={
								description === null || description === "" ? "0" : description
							}
						/>,
						<Controller
							render={({ field }) => (
								<FormGroup className="col-md-3">
									<Label>Dept.</Label>
									<CreatableSelect
										value={{ value: department, label: department }}
										options={departmentsList.map((value) => ({
											value: value,
											label: value,
										}))}
										onChange={(e) => {
											console.log(e);
											setDepartment(e.value);
										}}
										{...field}
									/>
								</FormGroup>
							)}
							name="departmentsList"
							defaultValue={
								department === null || department === "" ? "0" : department
							}
						/>,
						<InputElement
							{...methods}
							value={date}
							onChange={(e) => {
								setDate(e.target.value);
							}}
							name="date"
							label="Date"
							type="date"
						/>,

						<CurrencyInputElement
							value={labor}
							onChange={setLabor}
							name="labor"
							label="Labor Cost"
							type="Currency"
							readOnly={costType !== 0 && costType !== "0" ? true : false}
						/>,

						<CurrencyInputElement
							value={parts}
							onChange={setParts}
							name="parts"
							label="Part Cost"
							type="Currency"
							readOnly={costType !== 0 && costType !== "0" ? true : false}
						/>,
						<CurrencyInputElement
							value={taxPaid}
							onChange={setTaxPaid}
							name="tax"
							label="Tax"
							type="Currency"
						/>,
						<CurrencyInputElement
							value={cost}
							onChange={setCost}
							name="cost"
							label="Total Cost"
							type="Currency"
						/>,
						<InputElement
							{...methods}
							value={invoiceNumber}
							onChange={(e) => {
								setInvoiceNumber(e.target.value);
							}}
							name="InvoiceNumber"
							label="Invoice Number"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={checkNum}
							onChange={(e) => {
								setCheckNum(e.target.value);
							}}
							name="checkNum"
							label="Check #"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={roNumber}
							onChange={(e) => {
								setRoNumber(e.target.value);
							}}
							name="roNumber"
							label="Repair Order"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={poNumber}
							onChange={(e) => {
								setPoNumber(e.target.value);
							}}
							name="poNumber"
							label="Purchase Order"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={employee}
							onChange={(e) => {
								setEmployee(e.target.value);
							}}
							name="employee"
							label="Assign To"
							type="text"
						/>,
						<InputElement
							{...methods}
							value={dateCompleted}
							onChange={(e) => {
								setDateCompleted(e.target.value);
							}}
							name="dateCompleted"
							label="Date Completed"
							type="date"
						/>,
					]}
					buttons={
						<div className="d-flex justify-content-center mt-2">
							<Button
								color="primary"
								className="btn-sm"
								onClick={methods.handleSubmit(addCost)}
							>
								<i className="nc-icon nc-simple-add"></i> {buttonText}
							</Button>
							<div className="btn btn-link btn-sm" onClick={clearFields}>
								Reset
							</div>
						</div>
					}
				/>
			</Form>
		</FormProvider>
	);
};

export default InventoryCostForm;
