import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	//baseURL: LOCAL + "/manheim/",
	baseURL: userBaseUrl + "/integrations/manheim/",
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/kbb/",
// });
export const getMMRValues = async (vin, options) => {
	try {
		// Build the query parameters from the options object
		const params = {
			...options,
		};

		// Make the GET request to your backend API
		const response = await instance.get(`getMMRValues/${vin}`, { params });

		// Return the data from the response
		return response.data;
	} catch (error) {
		// Handle the error
		console.error("Error fetching MMR values:", error);
		throw error;
	}
};
