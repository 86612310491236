import ChildrenModal from "components/modals/ChildrenModal";
import { DealStatus } from "constants/Constants";
import React, { useState } from "react";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import VoidRepoAdminForm from "./VoidRepoAdminForm";

const VoidButton = () => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			editType: state.editType,
			editIsLoading: state.editIsLoading,
		}),
		shallow
	);

	return (
		<>
			<div
				className="btn btn-danger btn-md onMobileScreen"
				onClick={() => {
					toggle();
				}}
				readOnly={type !== DealStatus.FUNDED}
			>
				Void<i className=" nc-icon nc-minimal-right"></i>
			</div>
			<ChildrenModal
				modal={open}
				toggle={toggle}
				size="s"
				modalTitle="Administrator Required"
				children={<VoidRepoAdminForm toggleAdminForm={toggle} />}
			/>
		</>
	);
};

export default VoidButton;
