import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody, CardHeader } from "reactstrap";
import ReviewRow from "./ReviewRow";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import shallow from "zustand/shallow";

/**
 * ReviewDown component to display the breakdown of the total down payment.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewDown component
 */
const ReviewDown = () => {
	const {
		totalTradeAllowance,
		totalTradePayoff,
		totalDown,
	} = useCurrentRecapStore(
		(state) => ({
			totalTradeAllowance: state.totalTradeAllowance,
			totalTradePayoff: state.totalTradePayoff,
			totalDown: state.totalDown,
		}),
		shallow
	);

	const {
		totalNetTrade,
		totalPickupPmts,
		manufacturerRebate,
		down,
	} = useCurrentSaleStore(
		(state) => ({
			totalNetTrade: state.totalNetTrade,
			totalPickupPmts: state.totalPickupPmts,
			manufacturerRebate: state.manufacturerRebate,
			down: state.down,
		}),
		shallow
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Total Down</CardTitle>
			</CardHeader>
			<CardBody>
				<ReviewRow
					isCurrency={true}
					label="Trade"
					value={totalTradeAllowance}
				/>
				<ReviewRow isCurrency={true} label="Pay-Off" value={totalTradePayoff} />
				<ReviewRow isCurrency={true} label="Net Trade" value={totalNetTrade} />
				<ReviewRow
					isCurrency={true}
					label="Total Pickups"
					value={totalPickupPmts}
				/>
				<ReviewRow
					isCurrency={true}
					label="Rebate"
					value={manufacturerRebate}
				/>
				<ReviewRow isCurrency={true} label="Money Down" value={down} />
				<hr className="my-2" />
				<h6>
					<ReviewRow isCurrency={true} label="Total Down" value={totalDown} />
				</h6>
			</CardBody>
		</Card>
	);
};

ReviewDown.propTypes = {
	totalTradeAllowance: PropTypes.number,
	totalTradePayoff: PropTypes.number,
	totalDown: PropTypes.number,
	totalNetTrade: PropTypes.number,
	totalPickupPmts: PropTypes.number,
	manufacturerRebate: PropTypes.number,
	down: PropTypes.number,
};

export default ReviewDown;
