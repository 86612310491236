import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Button, CardHeader } from "reactstrap";
import { FaTimes } from "react-icons/fa";

/**
 * Announcements component displays announcements and version information.
 * @returns {JSX.Element} The Announcements component.
 */
const Announcements = () => {
	const [show, toggleShow] = useState(true);

	useEffect(() => {
		const announcementsShown = sessionStorage.getItem("announcementsShown");
		if (announcementsShown) {
			toggleShow(false);
		}
	}, []);

	const handleClose = () => {
		toggleShow(false);
		sessionStorage.setItem("announcementsShown", "true");
	};

	return (
		<>
			{show && (
				<Col className="px-4 pb-4">
					<Card>
						<Button
							className="position-absolute btn-sm btn-link"
							style={{ top: "10px", right: "10px" }}
							onClick={handleClose}
						>
							<FaTimes />
						</Button>
						<CardHeader>
							<h3 className="mb-3">
								<span aria-label="party emoji" role="img">
									🎉{" "}
								</span>{" "}
								Announcements
							</h3>
						</CardHeader>
						<CardBody className="pt-0">
							<Row>
								<Col md="6" className="mb-2">
									<h6 style={{ fontWeight: "400" }}>version: </h6>
									<ul>
										<li>
											<span aria-label="rocket gear" role="img">
												⚙️
											</span>{" "}
											Accessibility Default Settings
										</li>
										<li>
											<span aria-label="rocket emoji" role="img">
												🚀
											</span>{" "}
											UI Improvements
										</li>
										<li>
											<span aria-label="mobile phone emoji" role="img">
												📱
											</span>{" "}
											Mobile Improvements
										</li>
										<li>
											<span aria-label="bug emoji" role="img">
												🐞
											</span>{" "}
											Bug Fixes
										</li>
										<li>
											<span aria-label="high voltage emoji" role="img">
												⚡
											</span>{" "}
											Performance Improvements
										</li>
										<li>
											<span aria-label="lock emoji" role="img">
												🔒
											</span>{" "}
											Security Enhancements
										</li>
									</ul>
								</Col>
								<hr className="my-3 mx-auto w-100 noDesktop d-block" />
								<Col md="6" className="d-flex align-items-center mb-2">
									<div>
										<span
											style={{
												fontSize: "6em",
												margin: "auto",
												width: "100%",
												display: "inline",
											}}
											aria-label="books emoji"
											role="img"
										>
											📚
										</span>
									</div>
									<div>
										<h5 className="mt-2 mb-0">Learn DealerClick!</h5>
										<p className="text-muted mb-1">
											Your all-in-one guide to getting the most out of
											DealerClick DMS.
										</p>
										<Button
											color="primary"
											disabled
											className="btn-sm"
											onClick={() =>
												window.open("https://dealerclick.com/docs", "_blank")
											}
										>
											Coming Soon...
										</Button>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			)}
		</>
	);
};

export default Announcements;
