import React, { useState } from "react";
import InventoryHeader from "./InventoryHeader";
import InventoryListData from "./InventoryListData";
import PageLayout from "components/layouts/PageLayout";

const InventoryListPage = () => {
	const [statusList, setStatusList] = useState("Available,Pending");

	return (
		<PageLayout>
			<InventoryHeader statusList={statusList} setStatusList={setStatusList} />
			<InventoryListData statusList={statusList} />
		</PageLayout>
	);
};
export default InventoryListPage;
