import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col } from "reactstrap";
import useCurrentDealStore from "stores/DealStore";
import LenderModal from "components/modals/LenderModal";
import UACApplicationStatus from "./UACApplicationStatus";
import GenericLenderForm from "components/genericLenderForm/GenericLenderForm";
import UAClogo from "assets/img/uac-logo.png";
import { checkUACSubmitted, submitUACApp } from "api/DealerIntegrationApi";
import shallow from "zustand/shallow";

/**
 * UAC component handles the display and submission of UACC applications.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const UAC = () => {
	// Modal toggles
	const [toggleApplication, setToggleApplication] = useState(false);
	const [toggleStatus, setToggleStatus] = useState(false);

	// Local states
	const [isAppSubmitted, setIsAppSubmitted] = useState(false);

	// Deal store
	const { ID } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
		}),
		shallow
	);

	// Toggles the application modal open or close
	const toggleApplicationModal = () => setToggleApplication(!toggleApplication);

	// Toggles the status modal open or close
	const toggleStatusModal = () => setToggleStatus(!toggleStatus);

	// Button to open the application modal
	const applicationButton = (resubmit = false) => (
		<Button
			size="md"
			className="w-100 btn-primary"
			onClick={toggleApplicationModal}
		>
			{resubmit ? "Resubmit" : "Send App"}
		</Button>
	);

	// Button to open the status modal
	const statusButton = () => (
		<Button size="md" className="w-100 btn-primary" onClick={toggleStatusModal}>
			Status
		</Button>
	);

	useEffect(() => {
		if (ID != null) {
			checkUACSubmitted(ID).then(
				(res) => setIsAppSubmitted(res.data.content),
				(err) => console.error(err)
			);
		}
		// eslint-disable-next-line
	}, [ID]);

	return (
		<Card>
			<CardBody>
				<img src={UAClogo} alt="UAC Logo" className="rounded lenderImg" />
				<Col>
					{!isAppSubmitted ? (
						<LenderModal
							ButtonComponent={applicationButton}
							modalSize="xl"
							modalTitle="UACC Application"
							toggle={toggleApplication}
							toggleModal={toggleApplicationModal}
						>
							<GenericLenderForm
								toggleApplicationModal={toggleApplicationModal}
								setIsAppSubmitted={setIsAppSubmitted}
								submitToLender={submitUACApp}
							/>
						</LenderModal>
					) : (
						<div className="d-flex justify-content-space-between">
							<LenderModal
								ButtonComponent={statusButton}
								modalSize="lg"
								modalTitle="UACC Application Status"
								toggle={toggleStatus}
								toggleModal={toggleStatusModal}
							>
								<UACApplicationStatus />
							</LenderModal>
							<LenderModal
								ButtonComponent={() => applicationButton(true)}
								modalSize="xl"
								modalTitle="UACC Application"
								toggle={toggleApplication}
								toggleModal={toggleApplicationModal}
							>
								<GenericLenderForm
									toggleApplicationModal={toggleApplicationModal}
									setIsAppSubmitted={setIsAppSubmitted}
									submitToLender={submitUACApp}
								/>
							</LenderModal>
						</div>
					)}
				</Col>
			</CardBody>
		</Card>
	);
};

UAC.propTypes = {
	ID: PropTypes.number,
};

export default UAC;
