import React from "react";
import PropTypes from "prop-types";
import ChildrenModal from "components/modals/ChildrenModal";
import Credit700PreScreen from "features/crm/components/Credit700PreScreen";

/**
 * PreScreenModals component to display different modal windows.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.xpnToggle - Function to toggle the XPN modal.
 * @param {boolean} props.xpnModal - State of the XPN modal.
 * @param {string} props.Credit - Source of the Credit image.
 * @param {Object} props.lead - Lead information.
 * @param {boolean} props.tuModal - State of the TU modal.
 * @param {Function} props.tuToggle - Function to toggle the TU modal.
 * @param {boolean} props.efxModal - State of the EFX modal.
 * @param {Function} props.efxToggle - Function to toggle the EFX modal.
 * @returns {ReactElement} Modal components.
 */
const PreScreenModals = ({
	xpnToggle,
	xpnModal,
	Credit,
	lead,
	tuModal,
	efxModal,
	tuToggle,
	efxToggle,
}) => {
	const modalConfigs = [
		{ modal: xpnModal, toggle: xpnToggle, bureau: "XPN" },
		{ modal: tuModal, toggle: tuToggle, bureau: "TU" },
		{ modal: efxModal, toggle: efxToggle, bureau: "EFX" },
	];

	return (
		<>
			{modalConfigs.map((config, index) => (
				<ChildrenModal
					key={index}
					modal={config.modal}
					toggle={config.toggle}
					modalTitle={
						<img src={Credit} alt="700credit" className="rounded mb-3 pt-3" />
					}
					children={
						<Credit700PreScreen
							bureau={config.bureau}
							lead={lead}
							toggle={config.toggle}
						/>
					}
				/>
			))}
		</>
	);
};

PreScreenModals.propTypes = {
	xpnToggle: PropTypes.func.isRequired,
	xpnModal: PropTypes.bool.isRequired,
	Credit: PropTypes.string.isRequired,
	lead: PropTypes.object.isRequired,
	tuModal: PropTypes.bool.isRequired,
	efxModal: PropTypes.bool.isRequired,
	tuToggle: PropTypes.func.isRequired,
	efxToggle: PropTypes.func.isRequired,
};

export default PreScreenModals;
