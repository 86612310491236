export const login = (data) => {
    sessionStorage.setItem("user", data);
};

export const logout = () => {
    sessionStorage.clear(); // Clear all session storage
    localStorage.removeItem("photo-storage");
};

export const authenticated = () => {
    console.log("Current User: ");
    console.log(JSON.parse(sessionStorage.getItem("user")));
    return sessionStorage.getItem("user") !== null;
};

export const getAuthHeader = () => {
    try {
        const user = sessionStorage.getItem("user");
        const jwt = JSON.parse(user)["jws"];
        return { headers: { Authorization: jwt } };
    } catch {
        console.log("Missing jwt");
        //Maybe redirect?
    }
};

// Returns local storage Jwt token
export const getJwtToken = () => {
    try {
        const userString = sessionStorage.getItem("user");
        const user = JSON.parse(userString);
        return user.jws;
    } catch {
        // implement same logic as update token
    }
};

// Updates local storage Jwt token
export const updateJwtToken = (token) => {
    try {
        const userString = sessionStorage.getItem("user");
        const user = JSON.parse(userString);
        user.jws = token;
        sessionStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
        console.log(error);
        // something went wrong?
    }
};
