import React from "react";
import { Form } from "reactstrap";

import InputElement from "features/forms/components/InputElement";

import { newOrUsed } from "constants/Constants";
import { IntegerInputElement } from "features/forms/components/InputElement";
import { SelectorElement } from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";
import InputWrapper from "../../features/forms/components/InputWrapper";

const UACVehicle = ({ fields, setters, cardId, cardTitle }) => {
	// Get validation context
	const methods = useFormContext();

	return (
		<Form id={cardId}>
			<InputWrapper
				formTitle={cardTitle}
				inputComponents={[
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.vin}
						onChange={(e) => setters.setVin(e.target.value)}
						name="vehicleVin"
						label="VIN"
						type="text"
					/>,
					<IntegerInputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.odometerIn}
						onChange={setters.setOdometerIn}
						name="vehicleOdometer"
						label="Odometer"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.year}
						onChange={(e) => setters.setYear(e.target.value)}
						name="vehicleYear"
						label="Vehicle Year"
						type="number"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.make}
						onChange={(e) => setters.setMake(e.target.value)}
						name="vehicleMake"
						label="Vehicle Make"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.model}
						onChange={(e) => setters.setModel(e.target.value)}
						name="vehicleModel"
						label="Vehicle Model"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.trim}
						onChange={(e) => setters.setTrim(e.target.value)}
						name="vehicleTrim"
						label="Vehicle Trim"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						name="vehicleStatus"
						label="Vehicle Status"
						value={fields.newUsed}
						onChange={(e) => setters.setNewUsed(e.target.value)}
						options={newOrUsed}
					/>,
				]}
			/>
		</Form>
	);
};

export default UACVehicle;
