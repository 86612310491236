import React, { useState } from "react";
import { Button, Col } from "reactstrap";
import shallow from "zustand/shallow";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";

import useCurrentVehicleStore from "stores/InventoryStore";
import InventoryCostsTable from "./InventoryCostsTable";
import { DealStatus } from "constants/Constants";
import ChildrenModal from "components/modals/ChildrenModal";
import InventoryCostForm from "./InventoryCostForm";
import InventoryTotalCostDisplay from "./InventoryTotalCostDisplay";
import InventoryFlooringDetail from "../flooringTab/InventoryFlooringDetail";

//functional component to only handle props and change values onchange => save changes to api (not done yet)
const InventoryCostsTab = () => {
	// Vehicle store
	const { costHist, status } = useCurrentVehicleStore(
		(state) => ({
			costHist: state.costHist,
			status: state.status,
		}),
		shallow
	);

	const [showAddCost, setShowAddCost] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(costHist.length);

	// Toggles the add cost modal
	const toggleAddCost = () => setShowAddCost((prev) => !prev);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
	});

	const schema = yup.object().shape({
		cost: yup.string().required(),
	});
	const schema2 = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const methods2 = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema2),
	});
	//	const descriptionlist = getDescriptionlist(locationID);
	return (
		<Col>
			<FormProvider {...methods2}>
				<InventoryTotalCostDisplay />
				<Button
					className="btn btn-primary btn-sm mt-4 mx-3"
					onClick={toggleAddCost}
					readOnly={status === DealStatus.SOLD || status === DealStatus.DELETED}
				>
					<i className="nc-icon nc-simple-add" /> Add Cost
				</Button>
				<InventoryCostsTable
					setCurrentIndex={setCurrentIndex}
					toggle={toggleAddCost}
				/>
			</FormProvider>
			<InventoryFlooringDetail />
			<FormProvider {...methods}>
				<ChildrenModal
					modal={showAddCost}
					toggle={toggleAddCost}
					modalTitle="Vehicle Cost"
				>
					<InventoryCostForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						toggle={toggleAddCost}
					/>
				</ChildrenModal>
			</FormProvider>
		</Col>
	);
};
export default InventoryCostsTab;
