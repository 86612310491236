import React from "react";
import { Button } from "reactstrap";

const OpenModalButton = ({
	buttonText,
	onClick,
	buttonClass,
	buttonColor,
	disabled,
	iconClass,
}) => {
	return (
		<Button
			onClick={onClick}
			className={buttonClass}
			color={buttonColor}
			disabled={disabled}
		>
			<i className={iconClass}></i>&nbsp;{buttonText}
		</Button>
	);
};

export default OpenModalButton;
