import { getInventoryModelList } from "api/InventoryAPI";
import { getInventoryMakeList } from "api/InventoryAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";

export const getMakeList = ({ editMakeList }) => {
	const makes = [];
	getInventoryMakeList().then(
		(res) => {
			const resp = res.data.content;
			for (const key in resp) {
				makes.push({ label: resp[key], value: resp[key] });
			}
			editMakeList(makes);
		},
		(err) => {
			if (!err.isGeneralError) {
				showApiError(err, retrieveFailAlert);
			}
		}
	);
};

export const getYearList = ({ editYearList }) => {
	const currentYear = new Date().getFullYear() + 1;
	const yearStart = 1900;
	const yearList = [];
	for (let i = currentYear; i >= yearStart; i--) {
		yearList.push({ label: i.toString(), value: i.toString() });
	}
	editYearList(yearList);
};

export const getModelList = ({ editModelList }) => {
	const models = [];
	getInventoryModelList().then(
		(res) => {
			const resp = res.data.content;
			for (const key in resp) {
				models.push({ label: resp[key], value: resp[key] });
			}
		},
		(err) => {
			if (!err.isGeneralError) {
				showApiError(err, retrieveFailAlert);
			}
		}
	);
	editModelList(models);
};
