import React, { useState } from "react";
import ChildrenModal from "../components/modals/ChildrenModal";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import AccountPaymentsTable from "./AccountPaymentsTable";
import AccountPaymentForm from "./AccountPaymentForm";

const AccountPayments = () => {
	const { payments } = useCurrentAccountStore(
		(state) => ({
			payments: state.payments,
		}),
		shallow
	);
	const [currentIndex, setCurrentIndex] = useState(payments?.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);
	console.log(payments);
	return (
		<>
			<ChildrenModal
				modal={open}
				toggle={toggleOpen}
				size="md"
				children={
					<AccountPaymentForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
				}
			/>
			<AccountPaymentsTable
				setCurrentIndex={setCurrentIndex}
				toggle={toggleOpen}
			/>
		</>
	);
};

export default AccountPayments;
