import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { logout } from "utils/authUtils";

import logo from "assets/img/logo.svg";
import { useState, useEffect } from "react";
import { SelectorElement } from "../../features/forms/components/InputElement";
import { dateRange } from "../../constants/Constants";
import useDateRangeStore from "../../stores/DateRangeStore";
import { calculateDateRange } from "../../utils/GlobalFunctions";

const Sidebar = (props) => {
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/features/forms/RegularForms.js - route /admin/regular-forms
	const getCollapseInitialState = (routes) => {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
				return true;
			} else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
				return true;
			}
		}
		return false;
	};

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	const getCollapseStates = (routes) => {
		let initialState = {};
		routes.map((prop) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: getCollapseInitialState(prop.views),
					...getCollapseStates(prop.views),
					...initialState,
				};
			}
			return null;
		});
		return initialState;
	};

	const [state, setState] = useState(getCollapseStates(props.routes));

	const handleMiniClick = () => {
		document.documentElement.classList.remove("nav-open");
	};

	// this function creates the links and collapses that appear in the sidebar (left menu)
	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.invisible) return null;
			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !state[prop.state];

				return (
					<li
						className={getCollapseInitialState(prop.views) ? "active" : ""}
						key={key}
					>
						<a
							href="#1"
							data-toggle="collapse"
							aria-expanded={state[prop.state]}
							onClick={(e) => {
								e.preventDefault();
								setState(st);
							}}
						>
							{prop.icon !== undefined ? (
								<>
									<i className={prop.icon} />
									<p>
										{prop.name}
										<b className="caret" />
									</p>
								</>
							) : (
								<>
									<span className="sidebar-mini-icon">{prop.mini}</span>
									<span className="sidebar-normal">
										{prop.name}
										<b className="caret" />
									</span>
								</>
							)}
						</a>
						<Collapse isOpen={state[prop.state]}>
							<ul className="nav">{createLinks(prop.views)}</ul>
						</Collapse>
					</li>
				);
			}
			return (
				<li className={activeRoute(prop.layout + prop.path)} key={key}>
					<NavLink
						onClick={handleMiniClick}
						to={prop.layout + prop.path}
						activeClassName=""
					>
						{prop.icon !== undefined ? (
							<>
								<i className={prop.icon} />
								<p>{prop.name}</p>
							</>
						) : (
							<>
								<span className="sidebar-mini-icon">{prop.mini}</span>
								<span className="sidebar-normal">{prop.name}</span>
							</>
						)}
					</NavLink>
				</li>
			);
		});
	};

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	};

	useEffect(() => {
		//updateDateRange("This Year");
		// if you are using a Windows Machine, the scrollbars will have a Mac look
		if (navigator.platform.indexOf("Win") > -1) {
			new PerfectScrollbar(".sidebar-wrapper", {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
		// eslint-disable-next-line
	}, []);
	const dRStore = useDateRangeStore();
	useEffect(() => {
		// eslint-disable-next-line
	}, [dRStore.rangeType]);
	const updateDateRange = (e) => {
		dRStore.editRangeType(e);
		const [fromDate, toDate] = calculateDateRange(e);
		dRStore.editFromDate(fromDate);
		dRStore.editToDate(toDate);
	};

	return (
		<>
			<div className="sidebar" data-color="sidebar" data-active-color="primary">
				<div className="logo">
					<a href="/dashboard">
						<img className="pr-2" src={logo} alt="react-logo" />
					</a>
				</div>
				<div className="sidebar-wrapper">
					<Nav>{createLinks(props.routes)}</Nav>
					<hr />
					<div className="p-2">
						<a
							className="btn btn-danger w-100 btn-md"
							href="/"
							onClick={(e) => logout()}
						>
							<i className="nc-icon nc-button-power" />{" "}
							<div className="sidebarText">Logout</div>
						</a>
					</div>
					{/* <div> THIS MANUAL IS WORTHLESS UNTIL IT GETS SOME MORE CONTENT
						<a
							href="https://learn.dealerclick.com/"
							className="btn-primary w-100 btn-md center"
						>
							<div className="text-center font-italic">Training manual</div>
						</a>
					</div> */}
					<div>
						<SelectorElement
							disableValidation
							name="dateRange"
							label="Date Range"
							value={dRStore.rangeType}
							onChange={(e) => updateDateRange(e.target.value)}
							options={dateRange}
							colSize="col-lg-12"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
