import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Credit700Table from "./Credit700Table";
import { submitCreditCheck, submitPrescreen } from "api/DealerIntegrationApi";
import useCurrentCustomerStore from "stores/CustomerStore";
import {
	failAlert,
	successAlertCustom,
	retrieveFailAlert,
} from "utils/alertUtils";
import { credit700Bureaus, credit700Products } from "constants/Constants";
import shallow from "zustand/shallow";
import { getAllCustomerInfo } from "api/CustomerAPI";
import { showApiError } from "utils/errorRoutingUtils";
import Experian from "assets/img/experianLogo.png";
import Equifax from "assets/img/equifaxLogo.png";
import Transunion from "assets/img/transUnionLogo.png";
import Credit from "assets/img/700CreditLogo.png";

/**
 * Customer700CreditTab component
 * Displays credit report options and history for a customer.
 *
 * @param {Object} props - The component props
 * @param {string} props.bureau - The selected credit bureau
 * @param {string} props.id - The customer ID
 * @returns {JSX.Element} The Customer700CreditTab component.
 */
const Customer700CreditTab = ({ bureau, id }) => {
	const CreditListHeader = [
		"#",
		"BUREAU",
		"Type",
		"Score",
		"Date",
		"Description",
	];

	const [selectedOption, setSelectedOption] = useState(bureau);

	const {
		credit700Hist,
		editCredit700Hist,
		setCustomerFields,
		firstName,
		lastName,
	} = useCurrentCustomerStore(
		(state) => ({
			credit700Hist: state.credit700Hist,
			editCredit700Hist: state.editCredit700Hist,
			setCustomerFields: state.setCustomerFields,
			firstName: state.firstName,
			lastName: state.lastName,
		}),
		shallow
	);

	const commitData = (productType) => {
		const apiCall =
			productType === credit700Products.credit
				? submitCreditCheck
				: submitPrescreen;

		apiCall(id, selectedOption).then(
			(response) => {
				const arr = [...credit700Hist, response.data.content];
				editCredit700Hist(arr);
				successAlertCustom(response.data.message);
			},
			(error) => {
				failAlert(error.response.data.message);
			}
		);
	};

	const updateStore = (data) => {
		setCustomerFields(data.customer);
		editCredit700Hist(data.credit700);
	};

	const getData = (currentID) => {
		if (currentID != null) {
			getAllCustomerInfo(currentID).then(
				(res) => updateStore(res.data.content),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		getData(id);
		// eslint-disable-next-line
	}, [id]);

	return (
		<Card>
			<CardHeader className="text-center mb-2">
				<h3>
					{firstName} {lastName}
				</h3>
			</CardHeader>
			<CardBody>
				<form>
					<div className="radio pb-2 pt-3">
						<Row className="px-0 text-center">
							{[
								{
									value: credit700Bureaus.transUnion,
									img: Transunion,
									alt: "TransUnion",
								},
								{
									value: credit700Bureaus.experian,
									img: Experian,
									alt: "Experian",
								},
								{
									value: credit700Bureaus.equifax,
									img: Equifax,
									alt: "Equifax",
								},
							].map((bureau) => (
								<Col key={bureau.value}>
									<label>
										<input
											type="radio"
											value={bureau.value}
											checked={selectedOption === bureau.value}
											onChange={(e) => setSelectedOption(e.target.value)}
										/>
										<img src={bureau.img} alt={bureau.alt} />
									</label>
								</Col>
							))}
						</Row>
					</div>
					<Row
						className="px-0 pt-2 text-center my-3 justify-content-center"
						style={{ gap: "12px" }}
					>
						{[
							{
								label: "Prescreen",
								type: credit700Products.prescreen,
								text: "Soft-pull",
							},
							{
								label: "Credit Report",
								type: credit700Products.credit,
								text: "Hard-pull",
							},
						].map((action) => (
							<div
								key={action.type}
								className="d-flex align-items-center flex-column justify-content-center"
							>
								<Button
									className="pull-right btn-md mb-1"
									color="primary"
									onClick={() => commitData(action.type)}
								>
									{action.label}
								</Button>
								({action.text})
							</div>
						))}
					</Row>
					<Credit700Table head={CreditListHeader} />
				</form>
			</CardBody>
			<div className="d-flex align-items-center justify-content-center mt-3">
				Powered by:&nbsp;
				<img
					src={Credit}
					style={{ maxWidth: "150px" }}
					alt="700credit"
					className="rounded mb-3 pt-3"
				/>
			</div>
		</Card>
	);
};

Customer700CreditTab.propTypes = {
	bureau: PropTypes.string,
	id: PropTypes.number.isRequired,
};

export default Customer700CreditTab;
