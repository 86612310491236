import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";
import { DealStatus } from "constants/Constants";
import {
	CurrencyInput,
	PercentInput,
} from "features/forms/components/InputElement";
import { useFromLocations } from "stores/LocalStorageHelper";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";

const DMVPriceSection = () => {
	const { type, isTemplate } = useCurrentDealStore(
		(state) => ({ type: state.deal.type, isTemplate: state.deal.isTemplate }),
		shallow
	);

	const { price, editPrice } = useCurrentSaleStore(
		(state) => ({ price: state.price, editPrice: state.editPrice }),
		shallow
	);

	const [url, setUrl] = useState(null);
	const state = useFromLocations("state");

	useEffect(() => {
		const dmvUrls = {
			CA: "https://www.dmv.ca.gov/FeeCalculatorWeb/usedVehicleForm.do",
			WA: "https://secure.dol.wa.gov/home/",
		};
		setUrl(dmvUrls[state] || null);
	}, [state]);

	if (isTemplate) return null;

	return (
		<div>
			<Row className="mx-auto my-2 align-items-center">
				<PriceInput
					price={price}
					editPrice={editPrice}
					readOnly={type !== DealStatus.PENDING}
				/>
				{url && <DMVCalculatorButton url={url} />}
			</Row>
			<hr className="my-3 mx-3" />
			<DMVFeeSection type="license" />
			<hr className="my-3 mx-3" />
			<DMVFeeSection type="registration" />
		</div>
	);
};

const PriceInput = ({ price, editPrice, readOnly }) => (
	<div className="col-md-6 d-flex justify-content-center align-items-center form-group mb-0">
		<Label className="text-center">Price:&nbsp;</Label>
		<NumberFormat
			name="dmvPrice"
			readOnly={readOnly}
			value={price}
			decimalScale={2}
			prefix="$"
			fixedDecimalScale
			isNumericString
			customInput={Input}
			onFocus={(e) => e.target.select()}
			onValueChange={(values) => editPrice(values.value)}
		/>
	</div>
);

const DMVCalculatorButton = ({ url }) => (
	<Col color="md-6" style={{ textAlign: "center" }}>
		<hr className="my-3 noDesktop" />
		<a
			className="btn btn-md btn-primary"
			href={url}
			target="_blank"
			rel="noopener noreferrer"
		>
			<i className="nc-icon nc-support-17" /> Online DMV Calculator
		</a>
	</Col>
);

const DMVFeeSection = ({ type }) => {
	const { deal } = useCurrentDealStore();
	const {
		[type]: fee,
		[`${type}Fee`]: fixedFee,
		[`${type}Pct`]: percentFee,
		[`edit${type.charAt(0).toUpperCase() + type.slice(1)}Fee`]: editFixedFee,
		[`edit${type.charAt(0).toUpperCase() + type.slice(1)}Pct`]: editPercentFee,
	} = useCurrentSaleStore(
		(state) => ({
			[type]: state[type],
			[`${type}Fee`]: state[`${type}Fee`],
			[`${type}Pct`]: state[`${type}Pct`],
			[`edit${type.charAt(0).toUpperCase() + type.slice(1)}Fee`]: state[
				`edit${type.charAt(0).toUpperCase() + type.slice(1)}Fee`
			],
			[`edit${type.charAt(0).toUpperCase() + type.slice(1)}Pct`]: state[
				`edit${type.charAt(0).toUpperCase() + type.slice(1)}Pct`
			],
		}),
		shallow
	);

	const readOnly = deal.type !== DealStatus.PENDING;

	return (
		<Row className="mt-4">
			<Col md="5" className="form-group mb-0 d-flex align-items-center w-100">
				<Label>{type.charAt(0).toUpperCase() + type.slice(1)} Fee:&nbsp;</Label>
				<NumberFormat
					name={`dmv${type.charAt(0).toUpperCase() + type.slice(1)}`}
					disabled
					value={fee}
					decimalScale={2}
					prefix="$"
					customInput={Input}
					fixedDecimalScale
					isNumericString
				/>
			</Col>
			<Col
				md="2"
				className="text-center w-100 d-flex align-items-center justify-content-center p-0"
				style={{ minHeight: "50px" }}
			>
				=
			</Col>
			<Col md="5" className="row">
				<Col className="p-0">
					<Label>Fixed Amount</Label>
					<CurrencyInput
						readOnly={readOnly}
						value={fixedFee}
						onChange={editFixedFee}
						name={`dmv${type.charAt(0).toUpperCase() + type.slice(1)}Fee`}
						disableValidation
						wholeRow
					/>
				</Col>
				<Col className="p-0 ml-mobile">
					<Label>Percent of Price</Label>
					<PercentInput
						readOnly={readOnly}
						value={percentFee}
						onChange={editPercentFee}
						name={`dmv${type.charAt(0).toUpperCase() + type.slice(1)}Pct`}
						disableValidation
						wholeRow
					/>
				</Col>
			</Col>
		</Row>
	);
};

export default DMVPriceSection;
