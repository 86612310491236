import create from "zustand";
import dayjs from "dayjs";
const inputDate = dayjs().format("MM/DD/YYYY");
const format = "MM/DD/YYYY";
const useDateRangeStore = create((set) => ({
	rangeType: "Last Two Years",
	fromDate: dayjs(inputDate).subtract(1, "year").startOf("year").format(format),
	toDate: dayjs(inputDate).endOf("year").format(format),
	editFromDate: (val) => set(() => ({ fromDate: val })),
	editToDate: (val) => set(() => ({ toDate: val })),
	editRangeType: (val) => set(() => ({ rangeType: val })),
}));

export default useDateRangeStore;
