import React, { memo } from "react";
import { Input, FormGroup, Label } from "reactstrap";

import NumberFormat from "react-number-format";

import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";

const FormattedMaskInput = memo(
	({
		format,
		mask,
		placeholder,
		disabled = false,
		label,
		name,
		colSize = "col-lg-2",
		value,
		onChange,
		wholeRow = false,
		readOnly = false,
	}) => {
		const methods = useFormContext();

		return (
			<FormGroup className={wholeRow ? "col" : colSize}>
				<Label>{label}</Label>
				<NumberFormat
					format={format}
					mask={mask}
					placeholder={placeholder}
					disabled={disabled}
					value={value}
					name={name}
					className="form-control  "
					innerRef={methods.register}
					invalid={methods.errors[name] ? true : false}
					readOnly={readOnly}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					customInput={Input}
					onValueChange={(values) => onChange(values.value)}
				/>
				<ErrorMessage
					name={name}
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
			</FormGroup>
		);
	},

	(prevProps, nextProps) => prevProps.value === nextProps.value
);

export default FormattedMaskInput;
