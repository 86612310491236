import React from "react";
import { Form } from "reactstrap";
import formNavigationUtils from "../../../../../utils/formNavigationUtils";
import InputWrapper from "../../../../forms/components/InputWrapper";
import InputElement from "../../../../forms/components/InputElement";
import { vehicleStatus } from "../../../../../constants/Constants";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";

const AxelInfo = () => {
	// Vehicle store
	const vehicle = useCurrentVehicleStore((state) => state, shallow);

	// Define custom error messages for different error types
	const methods = useFormContext();

	return (
		<Form className="pt-2" onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Axel"
				inputComponents={[
					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.axelFrontMake}
						name="axelFrontMake"
						label="Axel Front Make"
						type="text"
						onChange={(e) => {
							vehicle.editAxelFrontMake(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.axelRearMake}
						name="axelRearMake"
						label="Axel Rear Make"
						type="text"
						onChange={(e) => {
							vehicle.editAxelRearMake(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.axelCount}
						name="axelCount"
						label="Axel Count"
						type="number"
						onChange={(e) => {
							vehicle.editAxelCount(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							vehicle.status === vehicleStatus.SOLD ||
							vehicle.status === vehicleStatus.DELETED
						}
						{...methods}
						value={vehicle.axelType}
						name="axelType"
						label="Axel Type"
						type="text"
						onChange={(e) => {
							vehicle.editAxelType(e.target.value);
						}}
					/>,
				]}
			/>
		</Form>
	);
};

export default AxelInfo;
