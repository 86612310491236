import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import swal from "sweetalert2";
import PrintReview from "./PrintReview";
import {
	getPdf,
	createFileAndDownload,
	displayPdf,
	displayError,
	formLoading,
} from "api/FormsAPI";
import { useFromUser } from "stores/LocalStorageHelper";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert, saveSuccessAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import useSetDeal from "features/deal/hooks/useSetDeal";
import useBuildDeal from "features/deal/hooks/useBuildDeal";

/**
 * ReviewWrapper component to handle the review and printing of the deal.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.toggle - Function to toggle the review modal.
 * @returns {JSX.Element} The rendered component.
 */
const ReviewWrapper = ({ toggle }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	/**
	 * Handles the print action by saving the deal and generating the PDF.
	 */
	const handlePrint = () => {
		const deal = getDeal();
		const dealID = deal.deal.ID;
		const body = {
			dealID: dealID,
			inventoryID: null,
			dcReportID: 225, // 225 is the ID for deal review in dcReport
			params: { dealerID: dealerID, locationID: locationID },
		};
		formLoading("PDF");
		saveDeal(deal).then(
			(res) => {
				setDeal(res.data.content);
				saveSuccessAlert();
				body.dealID = res.data.content.deal.ID;
				getPdf(body).then(
					(response) => {
						const fileName = `ST_DEAL_REVIEW_${dealID}.pdf`;
						createFileAndDownload(response, fileName);
						displayPdf(response);
						swal.close();
					},
					(err) => {
						displayError(err);
					}
				);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	return (
		<>
			<PrintReview />
			<div className="w-100 d-flex justify-content-center mt-3">
				<Button
					color="primary"
					onClick={() => {
						handlePrint();
						toggle();
					}}
				>
					<i className="nc-icon nc-single-copy-04" /> Print
				</Button>
			</div>
		</>
	);
};

ReviewWrapper.propTypes = {
	toggle: PropTypes.func.isRequired,
};

export default ReviewWrapper;
