import React, { useState } from "react";
import { Form, Row } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import ZipCityTable from "../../../../../../components/tables/ZipCityTable";

import {
	LocationAreaZoned,
	LocationMerchant,
	LocationSquareFootage,
	MailingType,
	OwnershipTypePeperpay,
	stateOptions,
} from "../../../../../../constants/Constants";

import { useFormContext } from "react-hook-form";
import InputElement, {
	PhoneInputElement,
	SelectorElement,
	ZipInputElement,
	CheckBoxElement,
	IntegerInputElement,
	//TextAreaInput,
} from "../../../../../forms/components/InputElement";
import {
	BillingFrequency,
	locationBuildingType,
} from "../../../../../../constants/Constants";

const BusinessInformation = ({
	mainInfo,
	setMainInfo,
	profileInfo,
	setProfileInfo,
	business,
	legal,
	setBusiness,
	setLegal,
	setCurrentForm,
	nextForm,
	previousForm,
	disabled,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [cityModalLegal, setCityModalLegal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);
	const toggleCityModalLegal = () => setCityModalLegal(!cityModalLegal);
	console.log(setBusiness);
	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: business.buCity,
				state: business.buState,
			},
		});
		toggleCityModal();
	};
	const handleZipChangeLegal = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: legal.leCity,
				state: legal.leState,
			},
		});
		toggleCityModalLegal();
	};
	const setAddressFields = (address) => {
		setBusiness.setBuCity(address.city);
		setBusiness.setBuState(address.state);
		setBusiness.setBuZip(address.zip);
	};
	const setAddressFieldsLegal = (address) => {
		setLegal.setLeCity(address.city);
		setLegal.setLeState(address.state);
		setLegal.setLeZip(address.zip);
	};

	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = () => {
		setCurrentForm(nextForm);
	};
	const methods = useFormContext();

	return (
		<div>
			<Form className="row" onKeyDown={formNavigationUtils}>
				{/* <h3 className="col-12  mb-3">Business Information</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.contactName}
					onChange={(e) => setBusiness.setContactName(e.target.value)}
					name="contactName"
					label="Contact Name"
					type="text"
					labelClass="required"
				/> */}
				<h5 className="col-12 mb-3">Doing Business As</h5>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.buName}
					onChange={(e) => setBusiness.setBuName(e.target.value)}
					name="business.doingBusinessAs.name"
					label="DBA or Trade Name"
					type="text"
					labelClass="required"
				/>

				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={business.buAddress1}
					onChange={(e) => setBusiness.setBuAddress1(e.target.value)}
					name="business.doingBusinessAs.address.address1"
					label="Address"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={business.buAddress2}
					onChange={(e) => setBusiness.setBuAddress2(e.target.value)}
					name="business.doingBusinessAs.address.address2"
					label="Suite / Floor / etc."
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.buCity}
					onChange={(e) => setBusiness.setBuCity(e.target.value)}
					name="business.doingBusinessAs.address.city"
					label="City"
					labelClass="required"
				/>
				<ZipInputElement
					{...methods}
					disabled={disabled}
					value={business.buZip}
					onChange={(val) => setBusiness.setBuZip(val)}
					openZipModal={handleZipChange}
					name="business.doingBusinessAs.address.zip"
					label="Zip"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="business.doingBusinessAs.address.state"
					label="State"
					value={business.buState}
					onChange={(e) => setBusiness.setBuState(e.target.value)}
					options={stateOptions}
					labelClass="required"
				/>

				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.buEmail}
					onChange={(e) => setBusiness.setBuEmail(e.target.value)}
					name="business.doingBusinessAs.email"
					label="Email"
					type="text"
					labelClass="required"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.buFax}
					onChange={(e) => setBusiness?.setBuFax(e)}
					name="business.doingBusinessAs.fax"
					label="Fax"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={business.buPhone}
					onChange={(e) => setBusiness?.setBuPhone(e)}
					name="business.doingBusinessAs.phone"
					label="Phone"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="business.mailingType"
					label="Preferred Mailing Address"
					value={business.mailingType}
					onChange={(e) => setBusiness.setMailingType(e.target.value)}
					options={MailingType}
					labelClass="required"
				/>

				<h5 className="col-12 mb-3">Legal Business Info</h5>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={legal.leName}
					onChange={(e) => setLegal.setLeName(e.target.value)}
					name="business.legal.name"
					label="Company Name"
					type="text"
					labelClass="required"
				/>

				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={legal.leAddress1}
					onChange={(e) => setLegal.setLeAddress1(e.target.value)}
					name="business.legal.address.address1"
					label="Address"
					type="text"
					labelClass="required"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={legal.leAddress2}
					onChange={(e) => setLegal.setLeAddress2(e.target.value)}
					name="business.legal.address.address2"
					label="Legal Suite / Floor / etc."
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={legal.leCity}
					onChange={(e) => setLegal.setLeCity(e.target.value)}
					name="business.legal.address.city"
					label="City"
					type="text"
					labelClass="required"
				/>
				<ZipInputElement
					{...methods}
					disabled={disabled}
					value={legal.leZip}
					onChange={(val) => setLegal.setLeZip(val)}
					openZipModal={handleZipChangeLegal}
					name="business.legal.address.zip"
					label="Zip"
					labelClass="required"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="business.legal.address.state"
					label="State"
					value={legal.leState}
					onChange={(e) => setLegal.setLeState(e.target.value)}
					options={stateOptions}
					labelClass="required"
				/>

				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={legal.leEmail}
					onChange={(e) => setLegal.setLeEmail(e.target.value)}
					name="business.legal.email"
					label="Email"
					type="text"
					labelClass="required"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={legal.leFax}
					onChange={(e) => setLegal?.setLeFax(e)}
					name="business.legal.fax"
					label="Fax"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={legal.lePhone}
					onChange={(e) => setLegal?.setLePhone(e)}
					name="business.legal.phone"
					label="Phone"
					labelClass="required"
				/>
				<Row>
					<SelectorElement
						{...methods}
						disabled={disabled}
						name="billingFrequency"
						label="Billing Frequency"
						value={mainInfo.billingFrequency}
						onChange={(e) => setMainInfo.setBillingFrequency(e.target.value)}
						options={BillingFrequency}
						labelClass="required"
					/>
					<SelectorElement
						{...methods}
						disabled={disabled}
						name="locationAreaZoned"
						label="Location Area Zoned"
						value={mainInfo.locationAreaZoned}
						onChange={(e) => setMainInfo.setLocationAreaZoned(e.target.value)}
						options={LocationAreaZoned}
						labelClass="required"
					/>

					<SelectorElement
						{...methods}
						disabled={disabled}
						name="locationBuildingType"
						label="Location Building Type"
						value={mainInfo.locationBuildingType}
						onChange={(e) =>
							setMainInfo.setLocationBuildingType(e.target.value)
						}
						options={locationBuildingType}
						labelClass="required"
					/>

					<SelectorElement
						{...methods}
						disabled={disabled}
						name="locationMerchant"
						label="Location Merchant"
						value={mainInfo.locationMerchant}
						onChange={(e) => setMainInfo.setLocationMerchant(e.target.value)}
						options={LocationMerchant}
						labelClass="required"
					/>
					<SelectorElement
						{...methods}
						disabled={disabled}
						value={mainInfo.locationSquareFootage}
						onChange={(e) =>
							setMainInfo?.setLocationSquareFootage(e.target.value)
						}
						options={LocationSquareFootage}
						name="locationSquareFootage"
						label="Location Square Footage"
						labelClass="required"
					/>
					<IntegerInputElement
						disabled={disabled}
						value={profileInfo.numberOfLocations}
						onChange={(e) => {
							setProfileInfo.setNumberOfLocations(e);
						}}
						name="profile.numberOfLocations"
						label="Number Of Locations"
						type="number"
						labelClass="required"
					/>
					<SelectorElement
						{...methods}
						disabled={disabled}
						name="profile.ownershipType"
						label="Ownership Type"
						value={profileInfo.ownershipType}
						onChange={(e) => setProfileInfo.setOwnershipType(e.target.value)}
						options={OwnershipTypePeperpay}
						labelClass="required"
					/>
				</Row>
				<h4 className="col-12 mb-4">
					Which payments would you like to accept?
				</h4>

				<Row>
					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptVisa}
						onChange={() => {
							setMainInfo.setAcceptVisa(!mainInfo.acceptVisa);
						}}
						name="acceptVisa"
						label="Visa, Mastercard, Discover"
						labelClass="pl-4 required"
					/>
					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptAeo}
						onChange={() => {
							setMainInfo.setAcceptAeo(!mainInfo.acceptAeo);
						}}
						name="acceptAeo"
						label="American Express OptBlue"
						labelClass="pl-4 required"
					/>
					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptPinDeb}
						onChange={() => {
							setMainInfo.setAcceptPinDeb(!mainInfo.acceptPinDeb);
						}}
						name="acceptPinDeb"
						label="Pin Debit"
						labelClass="pl-4 required"
					/>

					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptPinlessDeb}
						onChange={() => {
							setMainInfo.setAcceptPinlessDeb(!mainInfo.acceptPinlessDeb);
						}}
						name="acceptPinlessDeb"
						label="Pinless Debit"
						labelClass="pl-4 required"
					/>
					<h4 className="col-12 mb-1"> </h4>

					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptEbt}
						onChange={() => {
							setMainInfo.setAcceptEbt(!mainInfo.acceptEbt);
						}}
						name="acceptEbt"
						label="EBT-EBT FNS"
						labelClass="pl-4 required"
					/>
					<IntegerInputElement
						readOnly={!mainInfo.acceptEbt}
						value={mainInfo.acceptEbtNumber}
						onChange={(e) => setMainInfo?.setAcceptEbtNumber(e)}
						name="acceptEbtNumber"
						label="EBT Number"
						type="number"
						labelClass="required"
					/>
				</Row>

				{/* <h4 className="col-12 mb-1">
					Have Merchant or Owner / Principals ever been terminated from
					accepting bankcards for this business or any other businesses ?
				</h4> */}

				<Row>
					<SelectorElement
						{...methods}
						disabled={disabled}
						name="acceptingBankcards"
						label="Have Merchant or Owner / Principals ever been terminated from accepting bankcards for this business or any other businesses?"
						value={mainInfo.acceptingBankcards}
						onChange={(e) =>
							setMainInfo.setAcceptingBankcards(!mainInfo.acceptingBankcards)
						}
						options={[
							{ value: false, label: "No" },
							{ value: true, label: "Yes" },
						]}
						labelClass="required"
					/>

					{/* <CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.acceptingBankcards}
						onChange={() => {
							setMainInfo.setAcceptingBankcards(!mainInfo.acceptingBankcards);
						}}
						name="acceptingBankcards"
						label="Accepting Bankcards"
						labelClass="pl-4 required"
					/> */}
					{/* <TextAreaInput */}
					<InputElement
						{...methods}
						colSize="col-sm-12"
						disabled={disabled}
						value={mainInfo.acceptingBankcardsExplain}
						onChange={(e) =>
							setMainInfo.setAcceptingBankcardsExplain(e.target.value)
						}
						name="acceptingBankcardsExplain"
						label="Explanation of Termination"
						type="text"
					/>

					<CheckBoxElement
						{...methods}
						disabled={disabled}
						checked={mainInfo.isPciCompliant}
						onChange={() => {
							setMainInfo.setIsPciCompliant(!mainInfo.isPciCompliant);
						}}
						name="isPciCompliant"
						label="Is your business PCI Compliant?"
						labelClass="pl-4 required"
					/>
				</Row>

				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: business.buCity,
							state: business.buState,
							zip: business.buZip,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
				<ChildrenModal
					modal={cityModalLegal}
					toggle={toggleCityModalLegal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModalLegal}
						address={{
							city: legal.leCity,
							state: legal.leState,
							zip: legal.leZip,
						}}
						setAddress={setAddressFieldsLegal}
					/>
				</ChildrenModal>

				{/* , mailingType, buAddress1, buAddress2, buCity, buState, buZip, buEmail,
				buFax, buName, buPhone, leAddress1, leAddress2, leCity, leState, leZip,
				leEmail, leFax, leName, lePhone, */}
			</Form>
			{!disabled && (
				<div className="col-12">
					<button
						className="previous-button"
						onClick={() => setCurrentForm(previousForm)}
					>
						Previous
					</button>
					<button className="next-with-previous" onClick={handleNext}>
						Next
					</button>
				</div>
			)}
		</div>
	);
};
export default BusinessInformation;
