import React from "react";
import { Row } from "reactstrap";
import IDPhoto from "assets/img/placeholder.png";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";
import CoBuyerModal from "./CoBuyerModal";

const CoBuyerHeader = () => {
	// Deal store
	const { coBuyer } = useCurrentDealStore(
		(state) => ({
			coBuyer: state.coBuyer,
		}),
		shallow
	);

	const { ID, firstName, lastName } = coBuyer || "";

	return (
		<>
			{(ID == null && (
				<Row className="justify-content-center mt-3">
					<CoBuyerModal />
				</Row>
			)) || (
				<Row>
					<div className="mx-auto w-100">
						<h4 className="text-center mb-3 mt-1">
							{firstName + " " + lastName}
						</h4>
						<Row
							className="justify-content-center mb-3"
							style={{ gap: "10px" }}
						>
							<img
								className="customer-profile"
								src={IDPhoto}
								alt="customer-profile"
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = IDPhoto;
								}}
							/>
						</Row>
						<CoBuyerModal />
					</div>
				</Row>
			)}
		</>
	);
};

export default CoBuyerHeader;
