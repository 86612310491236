import React from "react";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";

export const DateDisplayElement = ({ val }) => {
	const formattedDate = dayjs(val).format("MM/DD/YYYY");

	return <span>{formattedDate}</span>;
};

export const CurrencyDisplayElement = ({ val }) => {
	return (
		<NumberFormat
			value={val}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			displayType="text"
		/>
	);
};

export const PercentDisplayElement = ({ val }) => {
	return (
		<NumberFormat
			value={val * 100}
			decimalScale={2}
			fixedDecimalScale={true}
			suffix={"%"}
			isNumericString={true}
			displayType="text"
		/>
	);
};
export const PhoneDisplayElement = ({ val }) => {
	return (
		<NumberFormat
			value={val}
			format="+1 (###) ###-####"
			allowEmptyFormatting
			mask="_"
			isNumericString={true}
			displayType="text"
		/>
	);
};
