import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import history from "utils/createBrowserHistory";
import DevLogin from "features/userManagement/components/DevLogin.js";
import Login from "./features/userManagement/components/Login.js";
import NewUser from "./features/userManagement/subFeatures/new-user/NewUser.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/paper-dashboard.css";
import "assets/css/custom.css";
import "assets/css/dark.css";
import "assets/css/error.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ForgottenPassword from "features/userManagement/subFeatures/forgettenPassword/ForgottenPassword";
import BackDoorLoginForm from "features/userManagement/components/BackDoorLoginForm.js";
import CustomerUploadForm from "./features/userManagement/components/CustomerUploadForm.js";
import FormGen from "features/forms/components/FormGen";
// Importing Sentry for error tracking
import * as Sentry from "@sentry/browser";

import Public from "components/layouts/Public.js";
import Dev from "components/layouts/Dev.js";
import PrivateRoute from "routes/PrivateRoute.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import { Helmet } from "react-helmet";
import MultiDealer from "./features/forms/components/MultiDealer.js";
//import FeedbackButton from "components/FeedbackButton.js";

const usePageTracking = () => {
	const location = useLocation();

	useEffect(() => {
		const gtag = window.gtag;
		if (gtag) {
			gtag("config", "G-8EZD85BQQY", {
				page_path: location.pathname,
			});
		}
	}, [location]);
};

// Disable the console logs statements in production
if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

Sentry.init({
	dsn: "http://c7bfe0d3d910e8e3c1858200063732bf@5.78.73.4:9000/2",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [
		"localhost",
		/^https:\/\/app.dealerclick\.com\/api/,
	],
	// Session Replay
	replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
	// react helmet to add to page headers for ga page tracking

	// track pages in google analytics
	return (
		<>
			<Helmet>
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-8EZD85BQQY"
				></script>
				<script>
					{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-8EZD85BQQY');
          `}
				</script>
			</Helmet>
			{/* <FeedbackButton /> */}
			<Router history={history}>
				<PageTracker>
					<Switch>
						<Redirect exact strict from="/" to="/login" />
						<Route
							exact
							path="/dev/login"
							render={(props) => <DevLogin {...props} />}
						/>
						<PrivateRoute
							devRoute={true}
							path="/dev"
							render={(props) => <Dev {...props} />}
						/>
						<Route
							exact
							path="/login"
							render={(props) => <Login {...props} />}
						/>
						<Route
							exact
							path="/new-user"
							render={(props) => <NewUser {...props} />}
						/>
						<Route
							exact
							path="/forgotten-password"
							render={(props) => <ForgottenPassword {...props} />}
						/>
						<Route
							exact
							path="/backdoor_login"
							render={(props) => <BackDoorLoginForm {...props} />}
						/>
						<Route
							exact
							path="/formgen"
							render={(props) => <FormGen {...props} />}
						/>
						<Route
							exact
							path="/multiDealer"
							render={(props) => <MultiDealer {...props} />}
						/>
						<Route
							exact
							path="/external-upload"
							render={(props) => <CustomerUploadForm {...props} />}
						/>
						<PrivateRoute
							devRoute={false}
							path="/"
							render={(props) => <Public {...props} />}
						/>
					</Switch>
				</PageTracker>
			</Router>
		</>
	);
};

export default App;

const PageTracker = ({ children }) => {
	usePageTracking(); // Now it's within the Router context
	return <>{children}</>;
};
