import React from "react";
// import CompanyFeature from "./CompanyFeature";
import ThirdPartyCredentials from "./ThirdPartyCredentials";
import CompanyInformation from "./CompanyInformation";
import DealerInsuranceInfo from "./DealerInsuransInfo";

const CompanyInfoTab = () => {
	return (
		<div className="col">
			<CompanyInformation />
			{/* <CompanyFeature /> */}
			<ThirdPartyCredentials />
			<DealerInsuranceInfo />
		</div>
	);
};

export default CompanyInfoTab;
