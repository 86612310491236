import React from "react";
import DashboardHeader from "./DashboardHeader";
import Marketing from "./Marketing";
import Announcements from "./Announcements";

/**
 * Dashboard component - the main component for the dashboard page
 * @returns {JSX.Element} - Rendered Dashboard component
 */
const Dashboard = () => {
	return (
		<div className="content">
			<DashboardHeader />
			<Announcements />
			<Marketing />
		</div>
	);
};

export default Dashboard;
