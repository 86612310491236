import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const DigitzsTransactionInputField = ({
	type = "text",
	colSize = "col-lg-3",
	label,
	value,
}) => {
	return (
		<FormGroup className={colSize}>
			<Label>{label}</Label>
			<Input readOnly={true} value={value} type={type} />
		</FormGroup>
	);
};

export default DigitzsTransactionInputField;
