import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Table } from "reactstrap";

import CardTooltip from "../../../components/Tooltip";
import { FormattedText } from "features/forms/components/InputElement";
import { KbbContext } from "./KbbContext";

import dayjs from "dayjs";
import { kbbConditionOptions } from "constants/Constants";
import { SelectorElement } from "features/forms/components/InputElement";
import { useFormContext } from "react-hook-form";

const AllValues = ({ children }) => {
	// KBB context
	const kbbContext = useContext(KbbContext);
	const { dcTableKbbValues, condition, setCondition } = kbbContext;

	const methods = useFormContext();

	// Local state
	const [listJSX, setListJSX] = useState();

	const getValuesList = () => {
		//let arr = getKbbValuesObj(dcTableKbbValues);
		let list;
		if (dcTableKbbValues != null) {
			const arr = dcTableKbbValues.filter(
				(obj) => obj.condition === condition || obj.condition === ""
			);
			list = arr.map((obj, idx) => {
				console.log(obj);
				let valuationDate = dayjs(obj.valuationDate).format("MMM D, YYYY");

				if (obj.condition == null || String(obj.condition).length < 1) {
					return (
						<tr key={String(idx * 2)}>
							<th scope="row">{obj.priceTypeDisplay}</th>
							<td>N/A</td>
							<td>
								<FormattedText
									value={obj.configuredValue}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.configuredColor}
								/>
							</td>
							<td>$0.00</td>
							<td>$0.00</td>
							<td>
								<FormattedText
									value={obj.optionAdjustment}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.optionColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.mileageAdjustment}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.mileageColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.typicalMileage}
									fixedDecScale={false}
								/>
							</td>
							<td>
								{dayjs(valuationDate).isValid()
									? valuationDate
									: obj.valuationDate}
							</td>
						</tr>
					);
				} else {
					return (
						<tr key={String(idx * 2)}>
							<th scope="row">
								{String(obj.priceTypeDisplay).split(obj.condition)[0]}
							</th>
							<td>{obj.condition}</td>
							<td>
								<FormattedText
									value={obj.configuredValue}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.configuredColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.rangeLow}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.rangeLowColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.rangeHigh}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.rangeHighColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.optionAdjustment}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.optionColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.mileageAdjustment}
									fixedDecScale={true}
									prefix={"$"}
									color={obj.mileageColor}
								/>
							</td>
							<td>
								<FormattedText
									value={obj.typicalMileage}
									fixedDecScale={false}
								/>
							</td>
							<td>
								{dayjs(valuationDate).isValid()
									? valuationDate
									: obj.valuationDate}
							</td>
						</tr>
					);
				}
			});
		}

		setListJSX(list);
	};

	useEffect(() => {
		getValuesList();
		// eslint-disable-next-line
	}, [condition]);

	return (
		<Card>
			<CardBody>
				<SelectorElement
					{...methods}
					name="kbbCondition"
					label="Condition"
					colSize="col-lg-3"
					value={condition}
					onChange={(e) => setCondition(e.target.value)}
					options={kbbConditionOptions}
				/>
				<div style={{ overflowX: "auto" }}>
					<Table hover>
						<thead>
							<tr>
								<th>Price Type</th>
								<th>Condition</th>
								<th>Value</th>
								<th>Low</th>
								<th>High</th>
								<th>Opt Adj</th>
								<th>Mile Adj</th>
								<th>Typical Mileage</th>
								<th>Validated</th>
							</tr>
						</thead>
						<tbody>{listJSX}</tbody>
					</Table>
				</div>
			</CardBody>
			<CardTooltip
				iconId="kbbAllValuesTooltip"
				header="What are All Values?"
				content="These are different value types and prices for the vehicle 
				based on its condition. It shows the breakdown of the values suggested by KBB."
			/>
			{children}
		</Card>
	);
};

export default AllValues;
