import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import shallow from "zustand/shallow";
import useBuildVehicle from "hooks/useBuildVehicle";
import useCurrentVehicleStore from "stores/InventoryStore";
import AttachmentModal from "../../attachments/AttachmentModal";
import ChildrenModal from "components/modals/ChildrenModal";
import DealTemplateModal from "../../settings/subFeatures/settingsTabs/dealTemplates/DealTemplateModal";
import InventoryDealList from "./InventoryDealList";
import NoteButton from "../../notes/NoteButton";
import { saveFailAlert } from "utils/alertUtils";
import { saveVehicle } from "api/InventoryAPI";
import { showApiError } from "utils/errorRoutingUtils";
import { KbbContext } from "../subFeatures/inventoryTabs/valuesTab/KbbContext";
import VINDecoderForm from "components/vinDecoderForm/VINDecoderForm";
import InventoryDeleteButton from "../helperFunctions/InventoryDeleteButton";
import ActionBar from "components/ActionBar";
import InventorySaveButton from "../helperFunctions/InventorySaveButton";

/**
 * InventoryActionBar Component to manage inventory-related actions.
 *
 * @component
 * @param {object} props - Component props
 * @param {boolean} props.isDeal - Indicates if the action bar is for a deal
 * @param {boolean} props.hideDelete - Flag to hide the delete button
 * @returns {JSX.Element} The rendered InventoryActionBar component
 */
const InventoryActionBar = ({ isDeal = false, hideDelete = false }) => {
	const { inventoryID, notes, editNotes, status } = useCurrentVehicleStore(
		(state) => ({
			inventoryID: state.inventoryID,
			notes: state.notes,
			editNotes: state.editNotes,
			status: state.status,
		}),
		shallow
	);

	const [dealListModal, setDealListModal] = useState(false);
	const [templateModal, setTemplateModal] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [actionsModal, setActionsModal] = useState(false);

	const getVehicle = useBuildVehicle();
	const { setDcKbbObj, getDcKbbObj } = useContext(KbbContext);

	// Toggles
	const toggleDealListModal = () => setDealListModal(!dealListModal);
	const toggleTemplate = () => setTemplateModal(!templateModal);
	const toggleActionsModal = () => setActionsModal(!actionsModal);

	/**
	 * Saves the current notes of the vehicle.
	 */
	const saveNotes = () => {
		if (inventoryID != null) {
			const vehicle = getVehicle();
			let modifiedVehicle = { ...vehicle };
			const modifiedNotes = notes.length > 0 ? JSON.stringify(notes) : "";
			modifiedVehicle.notes = modifiedNotes;
			const kbb = getDcKbbObj();
			const request = {
				vehicle: modifiedVehicle,
				kbb: kbb,
			};
			saveVehicle(request).then(
				(response) => setDcKbbObj(response.data.content.kbb),
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1000);
		};
		window.addEventListener("resize", handleResize);
		handleResize(); // Initial check
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<>
			<ActionBar
				isMobile={isMobile}
				toggleActionsModal={toggleActionsModal}
				actionsModal={actionsModal}
				modalTitle="Inventory Actions"
			>
				<VinDecoderModal title="Re-Decode VIN" />
				<div
					outline="true"
					onClick={toggleTemplate}
					readOnly={status !== "Pending" && status !== "Available"}
					className="btn btn-primary btn-md"
				>
					<i className="nc-icon nc-briefcase-24" />
					&nbsp;Start Deal
				</div>
				<AttachmentModal relatedID={inventoryID} type="inventory" />
				<div
					onClick={toggleDealListModal}
					className="btn-primary-dark btn btn-md"
				>
					<i className="nc-icon nc-paper" />
					&nbsp;View Deals
				</div>
				<NoteButton notes={notes} editNotes={editNotes} saveNotes={saveNotes} />
				<ChildrenModal
					modalTitle="Vehicle Deals"
					modal={dealListModal}
					toggle={toggleDealListModal}
					size="md"
				>
					<InventoryDealList
						toggle={toggleDealListModal}
						inventoryID={inventoryID}
					/>
				</ChildrenModal>
				<ChildrenModal
					modalTitle="Deal Templates"
					modal={templateModal}
					toggle={toggleTemplate}
					size="lg"
				>
					<DealTemplateModal />
				</ChildrenModal>
				{!hideDelete && (
					<InventoryDeleteButton isDeal={isDeal} redirect={true} />
				)}
			</ActionBar>
			<InventorySaveButton />
		</>
	);
};

InventoryActionBar.propTypes = {
	isDeal: PropTypes.bool,
	hideDelete: PropTypes.bool,
};

/**
 * VinDecoderModal component to handle VIN decoding.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.title - The title of the modal
 * @returns {JSX.Element} The rendered VinDecoderModal component
 */
const VinDecoderModal = ({ title }) => {
	const [toggle, setToggle] = useState(false);

	const setIsToggled = () => {
		setToggle((prevState) => !prevState);
	};

	return (
		<>
			<div
				onClick={setIsToggled}
				style={{ marginLeft: "5px" }}
				className="btn-info btn btn-md"
			>
				<i className="icon-big text-center nc-icon nc-refresh-69 text-default"></i>
				&nbsp;
				{title}
			</div>
			<ChildrenModal
				modal={toggle}
				toggle={setIsToggled}
				size="md"
				modalTitle={title}
			>
				<VINDecoderForm
					header=""
					useGlobalVin={true}
					updateStore={false}
					textInputSize="modal"
					styleSelectorSize="modalStyle"
					toggle={setIsToggled}
				/>
			</ChildrenModal>
		</>
	);
};

VinDecoderModal.propTypes = {
	title: PropTypes.string.isRequired,
};

export default InventoryActionBar;
