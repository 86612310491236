import React from "react";
import {
	Col,
	Row,
	Card,
	CardBody,
	CardTitle,
	CardText,
	Button,
} from "reactstrap";
import AutoCheckButton from "../../../helperFunctions/AutocheckButton";
import CarfaxReportButton from "../../../helperFunctions/CarfaxReportButton";
import logo from "assets/img/autodatadirect.jpg";

const InventoryHistoryTab = () => {
	return (
		<Row className="justify-content-center">
			<Col md={6} sm={6}>
				<div className="text-center">
					<Row>
						<Col md={4} sm={6} className="mb-3">
							<Card className="h-100">
								<CardBody className="d-flex flex-column">
									{/* <CardTitle tag="h5">Carfax Report</CardTitle> */}
									<CarfaxReportButton />
								</CardBody>
							</Card>
						</Col>
						<Col md={4} sm={6} className="mb-3">
							<Card className="h-100">
								<CardBody className="d-flex flex-column">
									{/* <CardTitle tag="h5">AutoCheck Reports</CardTitle> */}
									<AutoCheckButton />
								</CardBody>
							</Card>
						</Col>
						<Col md={4} sm={6} className="mb-3">
							<Card className="h-100">
								<CardBody className="d-flex flex-column mb-0">
									{/* <CardTitle tag="h5">AutoData Direct</CardTitle> */}
									<img src={logo} alt="AutoData" className="mb-3" />
									{/* <CardText>
										Get vehicle history reports from AutoData Direct, including
										title checks, registration history, and more.
									</CardText> */}
									<div>
										<Button
											onClick={() => {
												window.open(
													"https://login.add123.com/#/?bypass_when_logged_in=true"
												);
											}}
											className="historyButtons zoom btn-primary"
										>
											Visit Website
										</Button>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</Col>
		</Row>
	);
};

export default InventoryHistoryTab;
