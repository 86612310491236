import React, { useRef, useState } from "react";
import { Button } from "reactstrap";

import { getDealersAttachment } from "../../api/AttachmentsAPI";
import { retrieveFailAlert } from "../../utils/alertUtils";
import { showApiError } from "../../utils/errorRoutingUtils";

/**
 * Retrieves the file from the server and download's it to the user's computer.
 * If applicable, user's can also view the file in a new window after downloading
 * by hitting the view button.
 *
 * @param {number} dcAttachmentId id for the dcAttachment record
 * @param {String} fileName Required. Name of the file to download, including the
 * 							extension part as well
 * @param {String} fileExtension Extension of the file or null
 */
const DownloadAttachmentButton = ({
	dcAttachmentId,
	fileName,
	fileExtension,
}) => {
	// Local states
	const aTagRef = useRef(null);
	const [dataUrl, setDataUrl] = useState(null);

	// Returns the appropriate Blob type based on the extension
	// test doc, png pdf
	const getBlobType = (extension) => {
		let modifiedExtension = extension;

		if (extension == null && fileName.lastIndexOf(".") !== -1) {
			modifiedExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
		}

		if (modifiedExtension === "pdf") {
			return "application/pdf";
		} else if (modifiedExtension === "jpg" || modifiedExtension === "jpeg") {
			return "image/jpg";
		} else if (modifiedExtension === "png") {
			return "image/png";
		} else if (modifiedExtension === "xls" || modifiedExtension === "xlsx") {
			return "application/vnd.ms-excel";
		} else if (modifiedExtension === "csv") {
			return "text/csv";
		} else if (modifiedExtension === "txt") {
			return "text/plain";
		} else if (modifiedExtension === "doc" || modifiedExtension === "docx") {
			return "application/msword";
		}
	};

	/**
	 * Converts the array buffer to a Blob and then creates it's object URL so
	 * that it can be set to the <a/> tag and downloaded (and viewed, if applicable).
	 *
	 * @param {ArrayBuffer} fileArr  File as an array of bytes
	 */
	const createFileAndDownload = (fileArr) => {
		const blob = new Blob([fileArr], { type: getBlobType(fileExtension) });
		const blobUrl = URL.createObjectURL(blob);

		setDataUrl(blobUrl);

		aTagRef.current.click();
	};

	// Calls API to get the file
	const downloadFile = () => {
		getDealersAttachment(dcAttachmentId).then(
			(res) => createFileAndDownload(res.data),
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	return (
		<>
			<Button size="sm" block color="success" onClick={downloadFile}>
				Download
			</Button>
			<a
				className="d-none"
				ref={aTagRef}
				href={dataUrl}
				download={fileName}
				hidden
			>
				{fileName}
			</a>
		</>
	);
};

export default DownloadAttachmentButton;
