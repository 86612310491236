import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import DealVINDecoderForm from "./DealVINDecoderForm";
import NavBar from "components/tabs/TabsHolder";
import { TradeContext } from "./TradeContext";
import TradeForm from "./TradeForm";
import TradeInventoryList from "./TradeInventoryList";
import formNavigationUtils from "utils/formNavigationUtils";
import Loading from "components/loadingSpinner/Loading";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import OpenModalButton from "features/deal/components/OpenModalButton";
import ChildrenModal from "components/modals/ChildrenModal";
import FormsReportsList from "features/forms/components/FormsReportsList";
import InventoryKbbTab from "features/inventory/subFeatures/inventoryTabs/valuesTab/InventoryKbbTab";
import { KbbContext } from "features/inventory/subFeatures/inventoryTabs/valuesTab/KbbContext";

const isAppraisalPage = window.location.pathname === "/inventory-appraisal";

/**
 * TradeModal component handles the display and management of trade-related data.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const TradeModal = () => {
	const [add, setAdd] = useState(false);

	const toggleAdd = () => {
		setAdd(!add);
		setCurrentIndex(trades.length);
	};

	// Retrieve trades from the deal store
	const { trades } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
		}),
		shallow
	);

	// Retrieve trade-related context
	const {
		vin,
		stockNo,
		inventoryID,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		isLoading,
		currentIndex,
		setVehicleFields,
		editVin,
		editYear,
		editMake,
		editModel,
		setCurrentIndex,
		reset: resetTrade,
	} = useContext(TradeContext);

	const { setDcKbbObj, reset: resetKbb } = useContext(KbbContext);

	const vehicleKbbObj = {
		stockNo,
		vin,
		year,
		make,
		model,
		odometerIn,
		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	};

	const tabs = ["Trade Details", "Trade KBB", "Forms"];

	const tabComponents = [
		<>
			<h3 className="text-center my-4">Vehicle Details</h3>
			<Row
				className="align-items-center justify-content-center mb-2"
				style={{ gap: "12px" }}
			>
				<DealVINDecoderForm />
				<OpenModalButton
					buttonClass="btn-md btn btn-link"
					buttonText="Select"
					modalTitle="Select Vehicle"
					onClick={toggleAdd}
					icon={true}
					iconClass="nc-icon nc-tap-01"
				/>
			</Row>
			<TradeForm />
		</>,
		<InventoryKbbTab
			containerClassName="px-0"
			inventoryID={inventoryID}
			vehicleKbbObj={vehicleKbbObj}
			showVinDecoder={isAppraisalPage}
		/>,
		<TradeFormsTab tradeInventoryID={inventoryID} />,
	];

	useEffect(() => {
		setVehicleFields(trades?.[currentIndex]);
		if (trades.length !== currentIndex) {
			setDcKbbObj(trades?.[currentIndex]?.kbb);
		}
		return () => {
			resetTrade();
			resetKbb();
		};
		// eslint-disable-next-line
	}, [currentIndex]);

	if (isLoading) {
		return <Loading />;
	} else {
		return (
			<>
				<div onKeyDown={formNavigationUtils}>
					<NavBar
						navClass="nav-pills-primary justify-content-center nav nav-pills"
						tabs={tabs}
						tabComponents={tabComponents}
					/>
				</div>
				<ChildrenModal
					modalTitle="Select Vehicle"
					modal={add}
					toggle={toggleAdd}
					children={<TradeInventoryList toggle={toggleAdd} />}
				/>
			</>
		);
	}
};

export default TradeModal;

/**
 * TradeFormsTab component renders the forms related to trades.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.tradeInventoryID - The ID of the trade inventory.
 * @returns {JSX.Element} The rendered component.
 */
const TradeFormsTab = ({ tradeInventoryID }) => {
	return (
		<FormsReportsList
			listTitle="Trade Forms"
			listName={"tradeForms"}
			searchable={true}
			inventoryID={tradeInventoryID}
			eSign={true}
		/>
	);
};

TradeFormsTab.propTypes = {
	tradeInventoryID: PropTypes.number.isRequired,
};
