/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)
 
* Coded by DealerClick Software ))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import dayjs from "dayjs";
import React from "react";
import {
	Col,
	Table,
	Button,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
} from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";

import { deleteEmploymentHistory } from "api/CustomerAPI";
import { showApiError } from "../../../../../utils/errorRoutingUtils";
import { EmployerType } from "constants/Constants";
import {
	deleteSuccessAlert,
	deleteFailAlert,
	confirmDeleteAlert,
} from "../../../../../utils/alertUtils";

import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";

/**
 * @description This table generates a list of employment address
 * @param setCurrentIndex Used to index the store employment history array
 */
const EmployeeHistoryTable = ({
	disabled,
	setCurrentIndex,
	toggle,
	isDeal,
}) => {
	// Header
	const headers = [
		"#",
		"Company",
		"Position",
		"Salary",
		"Employment Type",
		"From Date",
		"To Date",
		"Actions",
	];

	// Customer Store
	const { empHist, editEmpHist } = useCurrentCustomerStore(
		(state) => ({
			empHist: state.empHist,
			editEmpHist: state.editEmpHist,
		}),
		shallow
	);

	const { editBuyerEmploymentHistory } = useCurrentDealStore(
		(state) => ({
			editBuyerEmploymentHistory: state.editBuyerEmploymentHistory,
		}),
		shallow
	);

	const toggleDelete = (currentID) => {
		confirmDeleteAlert(removeEmpHistory, currentID);
	};

	const removeEmpHistory = (ID) => {
		if (ID != null) {
			deleteEmploymentHistory(ID).then(
				() => {
					let obj = empHist.filter((item) => item.ID !== ID);
					editEmpHist(obj);
					if (isDeal) {
						editBuyerEmploymentHistory(obj);
					}
					setCurrentIndex(obj.length);
					deleteSuccessAlert();
				},
				(err) => {
					console.error(err);

					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Table Header
	const head = headers.map((key) => <th key={key}>{key}</th>);

	// Table Row
	const rows = empHist.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.company}</td>
			<td>{key.position}</td>
			<td>{key.salary}</td>
			<td>
				{
					EmployerType.filter((row) => row.value === key.employmentStatus)[0]
						?.label
				}
			</td>

			<td>{dayjs(key.fromDate).format("MM/DD/YYYY")}</td>
			<td>{key.toDate}</td>
			<td className="text-right">
				<Button
					disabled={disabled}
					color="success"
					className="btn-sm"
					onClick={() => {
						setCurrentIndex(index);
						toggle();
					}}
				>
					Edit
				</Button>{" "}
				<Button
					disabled={disabled}
					className="btn-sm"
					color="danger"
					onClick={() => {
						toggleDelete(key.ID);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));

	return (
		<>
			{rows && rows.length > 0 && (
				<Card>
					<CardHeader>
						<CardTitle>
							<h3>Employment History</h3>
						</CardTitle>
					</CardHeader>
					<CardBody></CardBody>
					<Col>
						<Table striped hover>
							<thead>
								<tr>{head}</tr>
							</thead>
							<tbody>{rows}</tbody>
						</Table>
					</Col>
				</Card>
			)}
			{rows && rows.length === 0 && (
				<div className="text-center">
					<CardBody>
						<Col>
							<h3>No Employment History Found</h3>
						</Col>
					</CardBody>
				</div>
			)}
		</>
	);
};
export default EmployeeHistoryTable;
