import React, { useState } from "react";
import { Card, CardText, Col, Row, Button } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import AftermarketForm from "./AftermarketForm";
import NumberFormat from "react-number-format";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import AftermarketTable from "./AftermarketTable";
import useCurrentDealStore from "stores/DealStore";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";

const AftermarketModal = ({ modal, toggle }) => {
	// Store values
	const { aftmkt, aftmktCost } = useCurrentSaleStore(
		(state) => ({
			aftmkt: state.aftmkt,
			aftmktCost: state.aftmktCost,
		}),
		shallow
	);
	const { aftermarket, type } = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			type: state.deal.type,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(aftermarket.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	return (
		<ChildrenModal
			modal={modal}
			toggle={toggle}
			size="lg"
			modalTitle="Aftermarket Items"
			children={
				<>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						modalTitle="Add Aftermarket Items"
						size="lg"
						children={
							<AftermarketForm
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>
					<AftermarketTable
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
					<div className="col mt-2">
						<Button
							className="btn btn-primary btn-sm"
							readOnly={type !== DealStatus.PENDING}
							onClick={toggleOpen}
						>
							<i className="nc-icon nc-simple-add"></i> Add Aftermarket
						</Button>
					</div>
					<Row className="mt-1">
						<Col md="6" className="p-1">
							<Card className="py-2">
								<CardText tag="h3" className="text-center">
									Total Aftermarket
								</CardText>
								<hr className="mb-2 w-75 mx-auto" />
								<CardText tag="h5" className="text-center mt-0">
									<NumberFormat
										value={aftmkt}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										isNumericString={true}
										displayType="text"
									/>
								</CardText>
							</Card>
						</Col>
						<Col md="6" className="p-1">
							<Card className="py-2">
								<CardText tag="h3" className="text-center">
									Total Cost
								</CardText>
								<hr className="mb-2 w-75 mx-auto" />
								<CardText tag="h5" className="text-center mt-0">
									<NumberFormat
										value={aftmktCost}
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										prefix={"$"}
										isNumericString={true}
										displayType="text"
									/>
								</CardText>
							</Card>
						</Col>
					</Row>
				</>
			}
		/>
	);
};

export default AftermarketModal;
