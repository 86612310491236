/*!
 
=========================================================
* DealerClick 2.0 - v1.0
=========================================================
 
* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)
 
* Coded by DealerClick Software *Peyman*))
 
=========================================================
 
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React from "react";
import dayjs from "dayjs";

import { Col, Table, Button } from "reactstrap";

import useCurrentCustomerStore from "stores/CustomerStore";
import shallow from "zustand/shallow";

/** @description This table generates a list of addresses
 *
 * @param {string[]} head - a list of strings that will be the headers of the collumns
 * @param {function} editFields() - the hooks used to edit the fields in the address form
 *
 */
const Credit700Table = (props) => {
	const { credit700Hist } = useCurrentCustomerStore((state) => state, shallow);

	const head = props.head.map((key) => <th key={key}>{key}</th>);
	const rows = credit700Hist.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{key.bureau}</td>
			<td>{key.reportType}</td>
			<td>{key.score}</td>
			<td data-type="date">{dayjs(key.repDate).format("MM/DD/YYYY")}</td>
			<td>{key.description}</td>
			<td className="text-right">
				<Button
					className="btn-sm"
					color="secondary"
					onClick={() => {
						window.open(key.url, "_blank", "noopener,noreferrer");
						console.log("URL for Credit700 at index " + index + ": " + key.url);
					}}
				>
					show
				</Button>
			</td>
		</tr>
	));

	return (
		rows.length > 0 && (
			<Col>
				<Table striped hover>
					<thead>
						<tr>{head}</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			</Col>
		)
	);
};
export default Credit700Table;
