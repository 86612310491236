import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
//import { LOCAL } from "./Constants";

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations/fairfax/",
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });
export const submitFairfax = async (locationID, ID) => {
	return await instance.get(
		`submitApplication/${locationID}/${ID}?isVersion2=true`
	);
};

export const getFairfax = async (ID) => {
	return await instance.get(`${ID}`);
};
