import React, { useMemo } from "react";
import { Card, CardHeader, CardTitle, Col } from "reactstrap";
import useCurrentRecapStore from "stores/RecapStore";
import shallow from "zustand/shallow";
import TotalCommissionsTable from "./TotalCommissionsTable";
import CommissionTable from "./CommissionTable";

/**
 * Main Commission component containing both the Total Commissions and Commission Table sections.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const Commission = () => {
	const {
		grossProfit,
		salesGross,
		fiGross,
		totalGross,
		profitComm,
		salesComm,
		fiComm,
		totalComm,
	} = useCurrentRecapStore(
		(state) => ({
			grossProfit: state.grossProfit,
			salesGross: state.salesGross,
			fiGross: state.fiGross,
			totalGross: state.totalGross,
			profitComm: state.profitComm,
			salesComm: state.salesComm,
			fiComm: state.fiComm,
			totalComm: state.totalComm,
		}),
		shallow
	);

	// Prepare commission data for the table
	const commissionData = useMemo(
		() => [
			{
				name: "Commissionable Gross",
				sale: salesGross,
				fi: fiGross,
				commissionables: totalGross,
				dealProfit: grossProfit,
			},
			{
				name: "Commission",
				sale: salesComm,
				fi: fiComm,
				commissionables: totalComm,
				dealProfit: profitComm,
			},
		],
		[
			salesGross,
			fiGross,
			totalGross,
			grossProfit,
			salesComm,
			fiComm,
			totalComm,
			profitComm,
		]
	);

	return (
		<Col>
			<Card>
				<CardHeader>
					<CardTitle tag="h3">Total Commissions</CardTitle>
				</CardHeader>
				<TotalCommissionsTable data={commissionData} />
			</Card>
			<CommissionTable />
		</Col>
	);
};

export default Commission;
