import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL + "/lead/",
// });

const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/customer/lead/",
	//baseURL: LOCAL + "/lead/",
});

// Get Lead
export const getLeadDetails = async (ID) => {
	return await instance.get(`${ID}`);
};

// Get Lead Notes
export const getLeadDealerNotes = async (ID) => {
	return await instance.get(`leadNotes/${ID}`);
};

// Get Lead list
export const getLeadList = async (locationID) => {
	return await instance.get(`list/${locationID}`);
};
// Get Lead list by status
export const getLeadListByStatus = async (locationID, status) => {
	return await instance.get(`list/${locationID}/${status}`);
};
export const getLeadListByDateRange = async (locationID, fromDate, toDate) => {
	return await instance.get(
		`listRange/${locationID}?fromDate=${fromDate}&toDate=${toDate}`
	);
};

// Get Lead list by filter

export const getLeadListBySearch = async (locationID, filter) => {
	return await instance.post(`ListBySearch/${locationID}`, { filter });
};

// Delete Lead list
export const deleteLeads = async (IDs) => {
	return await instance.get(`delete?IDs=${IDs}`);
};
// Update Lead status
export const updateLeadStatus = async (ID, status) => {
	return await instance.get(`updateStatus/${ID}?status=${status}`);
};
// Update Lead status
export const updateLeadCustomerID = async (leadID, customerID) => {
	return await instance.get(
		`updateCustomerID?leadID=${leadID},customerID=${customerID}`
	);
};
// updateCustomerID
export const updateCustomerID = async (leadID, customerID) => {
	return await instance.get(
		`updateCustomerID?leadID=${leadID}&customerID=${customerID}`
	);
};
// save As Customers
export const saveAsCustomer = async (ID) => {
	return await instance.get(`saveAsCustomer/${ID}`);
};
// save Lead
export const saveLead = async (lead) => {
	return await instance.post(`save`, lead);
};
