import React from "react";
import PropTypes from "prop-types";
import { version } from "../../version";

/**
 * Footer component
 * Displays the footer information including company name, dealer and location IDs, and version.
 *
 * @param {Object} props - The component props
 * @param {number} props.dealerID - The dealer ID
 * @param {number} props.locationID - The location ID
 * @param {string} props.companyName - The company name
 * @param {boolean} props.default - Flag to apply default styling
 * @returns {JSX.Element} The Footer component.
 */
const Footer = ({ dealerID, locationID, companyName, default: isDefault }) => {
	const currentYear = new Date().getFullYear();

	return (
		<footer className={`footer${isDefault ? " footer-default" : ""}`}>
			<nav className="footer-nav w-100 d-flex justify-content-center">
				<ul className="noMobile">
					{companyName}&nbsp;|&nbsp;
					{dealerID ? `${dealerID}-` : ""}
					{locationID || ""}&nbsp;| Version {version}&nbsp;|&nbsp;
				</ul>
				<ul>
					<li>
						<a href="https://dealerclick.com">DealerClick</a>
					</li>
					<li>
						<a href="https://dealerclick.com/blog">Blog</a>
					</li>
					<li>
						<a
							href="https://dealerclick.com/LicenseAgreement.txt"
							target="_blank"
							rel="noopener noreferrer"
						>
							Licenses
						</a>
					</li>
					<li>
						<a
							href="https://dealerclick.com/privacy-policy/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Privacy Notice
						</a>
					</li>
				</ul>
			</nav>
			<hr className="mb-2" />
			<div className="text-center mt-2">
				<span>
					&copy; {currentYear}, made with <i className="fa fa-heart heart" /> by
					DealerClick
				</span>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	dealerID: PropTypes.number,
	locationID: PropTypes.number,
	companyName: PropTypes.string.isRequired,
	default: PropTypes.bool,
};

Footer.defaultProps = {
	dealerID: "",
	locationID: "",
	default: false,
};

export default Footer;
