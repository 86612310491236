import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle, CardBody, CardHeader } from "reactstrap";
import ReviewRow from "./ReviewRow";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";

/**
 * ReviewVehicle component to display vehicle details.
 * Utilizes Reactstrap for layout and styling.
 *
 * @component
 * @returns {JSX.Element} The rendered ReviewVehicle component
 */
const ReviewVehicle = () => {
	const {
		stockNo,
		year,
		make,
		model,
		vin,
		license,
		odometer,
		numCylin,
	} = useCurrentDealStore(
		(state) => ({
			stockNo: state?.vehicle?.stockNo || "",
			year: state?.vehicle?.year || "",
			make: state?.vehicle?.make || "",
			model: state?.vehicle?.model || "",
			vin: state?.vehicle?.vin || "",
			license: state?.vehicle?.license || "",
			odometer: state?.vehicle?.odometerOut || "",
			numCylin: state?.vehicle?.numCylinders || "",
		}),
		shallow
	);

	return (
		<Card>
			<CardHeader>
				<CardTitle tag="h3">Vehicle</CardTitle>
			</CardHeader>
			<CardBody>
				<ReviewRow label="Stock No" value={stockNo} />
				<ReviewRow label="Vehicle" colSize="4" value={`${year} ${make}`} />
				<ReviewRow label="" value={model} />
				<ReviewRow label="VIN" colSize="3" value={vin} />
				{license && <ReviewRow label="License" value={license} />}
				{odometer && <ReviewRow label="Odometer" value={odometer} />}
				{numCylin && <ReviewRow label="# of Cylinders" value={numCylin} />}
			</CardBody>
		</Card>
	);
};

ReviewVehicle.propTypes = {
	stockNo: PropTypes.string,
	year: PropTypes.string,
	make: PropTypes.string,
	model: PropTypes.string,
	vin: PropTypes.string,
	license: PropTypes.string,
	odometer: PropTypes.string,
	numCylin: PropTypes.string,
};

export default ReviewVehicle;
