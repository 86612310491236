import React from "react";
import PropTypes from "prop-types";
import { CardText, Row, Col, Input } from "reactstrap";
import NumberFormat from "react-number-format";

/**
 * FinanceCurrency component for displaying and editing currency values.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.label - Label for the currency input
 * @param {number} props.value - Value of the currency input
 * @param {function} props.onChange - Callback function for value change
 * @param {boolean} props.disabled - Flag to disable the input
 * @returns {JSX.Element} The rendered component
 */

const FinanceCurrency = ({ label, value, onChange, disabled }) => {
	return (
		<Row className="mb-2 align-items-center">
			<Col md="6">
				<CardText>{label}</CardText>
			</Col>
			<Col md="6">
				<NumberFormat
					value={value}
					thousandSeparator={true}
					decimalScale={2}
					prefix={"$"}
					fixedDecimalScale={true}
					onFocus={(e) => e.target.select()}
					isNumericString={true}
					disabled={disabled}
					readOnly={disabled}
					customInput={Input}
					onValueChange={(values) => {
						if (!disabled) {
							if (values.value === "") {
								onChange(0);
							} else {
								onChange(values.value);
							}
						}
					}}
				/>
			</Col>
		</Row>
	);
};

export default FinanceCurrency;

FinanceCurrency.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};
