// deleteApplication.js
import { supabase } from "../../../supabaseClient";
import { confirmDeleteMessage } from "utils/alertUtils";

/**
 * Deletes an application from the database.
 * @param {number} rowId - The ID of the application to delete.
 * @returns {Promise<boolean>} - A promise that resolves to true if the deletion was successful, false otherwise.
 */
const deleteRowFromDB = async (rowId) => {
	try {
		const { error } = await supabase
			.from("submitted_apps")
			.delete()
			.eq("id", rowId);

		if (error) throw error;
		return true;
	} catch (error) {
		console.error("Error deleting row:", error);
		return false;
	}
};

/**
 * Confirms and executes the deletion of an application.
 * @param {number} rowId - The ID of the application to delete.
 * @param {function} onSuccess - Callback function to be called after successful deletion.
 */
export const confirmAndDeleteApplication = (rowId, onSuccess) => {
	confirmDeleteMessage("Yes, Delete it").then((res) => {
		if (res === "Yes, Delete it") {
			deleteRowFromDB(rowId).then((success) => {
				if (success) {
					onSuccess(rowId);
				}
			});
		}
	});
};
