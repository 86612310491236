import React, { useState, useEffect } from "react";
import { Form } from "reactstrap";

import InputElement from "features/forms/components/InputElement";

import {
	StateCodeByStateName,
	canadaStateOption,
	driverLicenseOption,
	CountryCodes,
} from "constants/Constants";
import { PhoneInputElement } from "features/forms/components/InputElement";
import { SelectorElement } from "features/forms/components/InputElement";
import { SsnInputElement } from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";
import InputWrapper from "../../features/forms/components/InputWrapper";

const UACApplicant = ({ fields, setters, cardTitle, formId, prefix }) => {
	const [countryState, setCountryState] = useState(StateCodeByStateName);
	// Get validation context
	const methods = useFormContext();
	useEffect(() => {
		if (fields.licCountry === "USA") setCountryState(StateCodeByStateName);
		else if (fields.licCountry === "CAN") setCountryState(canadaStateOption);
		else setCountryState([]);
	}, [fields.licCountry]);

	return (
		<Form id={formId}>
			{/* <LenderWrapper cardId={formId} cardTitle={cardTitle}> */}
			<InputWrapper
				formTitle={cardTitle}
				inputComponents={[
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.firstName}
						onChange={(e) => setters.setFirstName(e.target.value)}
						name={`${prefix}_firstName`}
						label="First Name"
						type="text"
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.lastName}
						onChange={(e) => setters.setLastName(e.target.value)}
						name={`${prefix}_lastName`}
						label="Last Name"
						type="text"
					/>,
					<SsnInputElement
						{...methods}
						colSize="col-lg-3"
						name={`${prefix}_ssn`}
						label="Social Security Number"
						value={fields.SSN}
						onChange={setters.setSsn}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.birthDate}
						onChange={(e) => setters.setDob(e.target.value)}
						name={`${prefix}_dob`}
						label="Birth Date"
						type="date"
					/>,
					<PhoneInputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.homePhone}
						onChange={setters.setHomePhone}
						name={`${prefix}_homePhone`}
						label="Primary Phone"
					/>,
					<PhoneInputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.cellPhone}
						onChange={setters.setCellPhone}
						name={`${prefix}_cellPhone`}
						label="Cell Phone"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						value={fields.licType}
						onChange={(e) => setters.setLicenseType(e.target.value)}
						name={`${prefix}_licType`}
						label="ID Type"
						options={driverLicenseOption}
					/>,
					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.license}
						onChange={(e) => setters.setLicense(e.target.value)}
						name={`${prefix}_license`}
						label="ID Number"
						type="text"
					/>,
					<SelectorElement
						{...methods}
						colSize="col-lg-3"
						value={fields.licCountry}
						onChange={(e) => setters.setLicenseCountry(e.target.value)}
						name={`${prefix}_licCountry`}
						label="ID Country"
						options={CountryCodes}
					/>,
					<SelectorElement
						{...methods}
						readOnly={
							fields.licCountry !== "USA" && fields.licCountry !== "CAN"
						}
						colSize="col-lg-3"
						name={`${prefix}_licState`}
						label="ID State"
						value={fields.licState}
						onChange={(e) => setters.setLicenseState(e.target.value)}
						options={countryState}
					/>,

					<InputElement
						{...methods}
						colSize="col-lg-3"
						value={fields.licExpires}
						onChange={(e) => setters.setLicenseExpire(e.target.value)}
						name={`${prefix}_licExpires`}
						label="ID Expiration"
						type="date"
					/>,
				]}
			/>
		</Form>
	);
};

export default UACApplicant;
