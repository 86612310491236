import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col } from "reactstrap";
import TradeTable from "./TradeTable";
import { FormProvider } from "react-hook-form";
import TradeModal from "./TradeModal";
import TradeProvider, { TradeContext } from "./TradeContext";
import useCurrentDealStore from "stores/DealStore";
import shallow from "zustand/shallow";
import {
	KbbContext,
	KbbValuesProvider,
} from "../../../../inventory/subFeatures/inventoryTabs/valuesTab/KbbContext";
import ChildrenModal from "components/modals/ChildrenModal";

/**
 * TradeTab component provides the context and layout for the trade section.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const TradeTab = () => {
	return (
		<FormProvider>
			<TradeProvider>
				<KbbValuesProvider>
					<Col>
						<TradeComponentWrapper />
					</Col>
				</KbbValuesProvider>
			</TradeProvider>
		</FormProvider>
	);
};

export default TradeTab;

/**
 * TradeComponentWrapper component initializes and handles the trade-related functionalities.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const TradeComponentWrapper = () => {
	// Retrieve trades from the deal store
	const { trades } = useCurrentDealStore(
		(state) => ({
			trades: state.trades,
		}),
		shallow
	);

	// Retrieve and manage trade-related context
	const { modalActive, toggleTradeModal, setCurrentIndex, reset } = useContext(
		TradeContext
	);
	const { reset: resetKbb } = useContext(KbbContext);

	useEffect(() => {
		setCurrentIndex(trades.length);
		// eslint-disable-next-line
	}, [trades.length]);

	/**
	 * Handles the addition of a new trade by setting the current index and toggling the modal.
	 */
	const add = () => {
		setCurrentIndex(trades.length);
		toggleTradeModal();
	};

	return (
		<>
			<Col>
				<Button className="mb-2 btn btn-primary btn-sm" onClick={add}>
					<i className="nc-icon nc-simple-add"></i> Add Trade
				</Button>
			</Col>
			<ChildrenModal
				modal={modalActive}
				toggle={toggleTradeModal}
				modalTitle={"Add Trade"}
				children={<TradeModal />}
				onClosed={() => {
					reset();
					resetKbb();
				}}
			/>
			<TradeTable />
		</>
	);
};

/**
 * PropTypes for the TradeComponentWrapper component.
 */
TradeComponentWrapper.propTypes = {
	trades: PropTypes.arrayOf(PropTypes.object),
	modalActive: PropTypes.bool,
	toggleTradeModal: PropTypes.func,
	setCurrentIndex: PropTypes.func,
	reset: PropTypes.func,
	resetKbb: PropTypes.func,
};
