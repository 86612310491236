import React from "react";
import {
	Row,
	Col,
	CardTitle,
	CardBody,
	CardHeader,
	Input,
	CardText,
} from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

/**
 * Component for displaying the deal summary with various financial details.
 *
 * @returns {JSX.Element} The rendered component.
 */
const Summary = () => {
	const {
		firstDueDate,
		finalDueDate,
		payment,
		finalPmt,
		term,
		amtFinanced,
		financeCharge,
		loan,
		balloonDate,
		balloonTerm,
		balloon,
		hasBalloon,
		onePay,
		monthlyTax,
		deferredTax,
		taxType,
	} = useCurrentSaleStore((state) => state, shallow);

	/**
	 * Component for displaying a currency value.
	 *
	 * @param {Object} props - The component props.
	 * @param {number} props.value - The currency value.
	 * @param {string} [props.className="bold"] - The CSS class for the input.
	 * @returns {JSX.Element} The rendered component.
	 */
	const CurrencyDisplay = ({ value, className = "bold" }) => (
		<NumberFormat
			readOnly
			value={value}
			thousandSeparator
			decimalScale={2}
			fixedDecimalScale
			prefix={"$"}
			isNumericString
			customInput={Input}
			className={className}
		/>
	);

	const summaryRow1 = [
		{
			CardText: "First Due Date",
			value: (
				<Input
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			CardText: "Final Due Date",
			value: (
				<Input
					value={finalDueDate ? dayjs(finalDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			CardText: (term > 1 ? term - 1 : 1) + " Payments of  ",
			value: (
				<CurrencyDisplay value={payment} className="regular-payment bold" />
			),
		},
		taxType === 1
			? {
					CardText: "With monthly Tax ",
					value: (
						<CurrencyDisplay
							value={monthlyTax}
							className="regular-payment bold"
						/>
					),
			  }
			: null,
		{
			CardText: "Final Payment",
			value: (
				<CurrencyDisplay value={finalPmt} className="final-payment bold" />
			),
		},
	].filter(Boolean);

	const balloonSummaryRow = [
		{
			CardText: "First Due Date",
			value: (
				<Input
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			CardText: "Balloon Date",
			value: (
				<Input
					value={balloonDate ? dayjs(balloonDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			CardText: (balloonTerm >= 1 ? balloonTerm : 0) + " Payments",
			value: <CurrencyDisplay value={payment} className="text-success bold" />,
		},
		{
			CardText: "Balloon Payment",
			value: <CurrencyDisplay value={balloon} />,
		},
	];

	const summaryRow2 = [
		{
			CardText: "Out the Door Price",
			value: <CurrencyDisplay value={onePay} />,
		},
		{
			CardText: "Amount Financed",
			value: <CurrencyDisplay value={amtFinanced} />,
		},
		{
			CardText: "Finance Charge",
			value: <CurrencyDisplay value={financeCharge} />,
		},
		taxType === 1
			? {
					CardText: "Tax Deferred",
					value: <CurrencyDisplay value={deferredTax} />,
			  }
			: null,
	].filter(Boolean);

	const summaryRow3 = [
		{
			CardText: "Total of Payments",
			value: <CurrencyDisplay value={loan} />,
		},
	];

	const row1 = hasBalloon ? balloonSummaryRow : summaryRow1;

	return (
		<div className="deal-summary">
			<CardHeader>
				<CardTitle tag="h3">Deal Summary</CardTitle>
			</CardHeader>
			<CardBody>
				<Row>
					{row1.map((obj, index) => (
						<React.Fragment key={index}>
							<Col className="d-flex align-items-center col-6 text-left">
								<CardText className="m-0">{obj.CardText}</CardText>
							</Col>
							<Col className="d-flex align-items-center col-6 text-right justify-content-end">
								{obj.value}
							</Col>
						</React.Fragment>
					))}
				</Row>
				<hr className="my-2" />
				{summaryRow2.map((obj, index) => (
					<React.Fragment key={index}>
						<Row>
							<Col className="d-flex align-items-center col-6 text-left">
								<CardText className="m-0">{obj.CardText}</CardText>
							</Col>
							<Col className="d-flex align-items-center col-6 text-right justify-content-end">
								{obj.value}
							</Col>
						</Row>
					</React.Fragment>
				))}
				<hr className="my-2" />
				{summaryRow3.map((obj, index) => (
					<React.Fragment key={index}>
						<Row>
							<Col className="d-flex align-items-center col-6 text-left">
								<h6 className="m-0">{obj.CardText}</h6>
							</Col>
							<Col className="d-flex align-items-center col-6 text-right justify-content-end deal-total">
								{obj.value}
							</Col>
						</Row>
					</React.Fragment>
				))}
			</CardBody>
		</div>
	);
};

export default Summary;
