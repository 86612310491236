import React, { useState, useEffect } from "react";
import { Button, Form, Label, FormGroup } from "reactstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { useFromUser } from "stores/LocalStorageHelper";
import useCurrentDealStore from "stores/DealStore";
import InputElement, {
	CurrencyInputElement,
	IntegerInputElement,
	SelectorElement,
} from "../../../../../forms/components/InputElement";
import InputWrapper from "../../../../../forms/components/InputWrapper";
import {
	DealStatus,
	isTaxableOption,
	vendorTypesOBJ,
} from "constants/Constants";
import { getVendorListByType } from "api/VendorsAPI";
import Select from "react-select";
import shallow from "zustand/shallow";
import PropTypes from "prop-types";

/**
 * AftermarketForm component to handle adding and editing aftermarket items.
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentIndex - The current index of the aftermarket item being edited.
 * @param {function} props.setCurrentIndex - Function to set the current index.
 * @param {function} props.toggle - Function to toggle the form visibility.
 */
export const AftermarketForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	const {
		aftermarket,
		editAftermarket,
		dealRecNum,
		type,
		calculate,
	} = useCurrentDealStore(
		(state) => ({
			aftermarket: state.aftermarket,
			editAftermarket: state.editAftermarket,
			dealRecNum: state.deal.ID,
			type: state.deal.type,
			calculate: state.calculate,
		}),
		shallow
	);

	const [vendorList, setVendorList] = useState([]);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState(0);
	const [qty, setQty] = useState(1);
	const [vendor, setVendor] = useState("");
	const [recNum, setRecNum] = useState(null);
	const [isTaxable, setIsTaxable] = useState(false);
	const [cost, setCost] = useState(0);
	const [totalCost, setTotalCost] = useState(cost * qty);
	const [totalPrice, setTotalPrice] = useState(price * qty);

	const getVendors = () => {
		getVendorListByType(locationID, vendorTypesOBJ.AFTMKT_SUPPLIER.value).then(
			(res) => {
				setVendorList(res.data.content);
				console.log(res.data.content);
			},
			(error) => {
				console.log(error);
			}
		);
	};

	const clearFields = () => {
		setDescription("");
		setPrice(0);
		setQty(1);
		setVendor("");
		setIsTaxable(false);
		setCost(0);
		setTotalCost(0);
		setTotalPrice(0);
		setCurrentIndex(aftermarket.length);
	};

	const addAftermarket = () => {
		const getID = () => {
			if (aftermarket[currentIndex] === undefined) {
				return null;
			} else if (aftermarket[currentIndex].ID === undefined) {
				return null;
			} else {
				return aftermarket[currentIndex].ID;
			}
		};
		const ID = getID();
		const newAftermarket = {
			description,
			price: parseFloat(price),
			qty: parseInt(qty),
			vendor,
			isTaxable: isTaxable || false,
			cost: parseFloat(cost),
			currentIndex,
			dealerID,
			locationID,
			ID,
			dealRecNum,
		};
		console.log(newAftermarket);
		console.log("Current index is: " + currentIndex);

		const aftermarketList = () => {
			let obj = [...aftermarket]; // Create a copy of aftermarket
			obj[currentIndex] = newAftermarket;
			return obj;
		};
		const newAftermarketObj = aftermarketList();
		editAftermarket(newAftermarketObj);
		calculate();
		clearFields();
		toggle();
	};

	const taxableHandler = (value) => {
		setIsTaxable(value === "true");
	};

	useEffect(() => {
		getVendors();
		if (currentIndex < aftermarket.length) {
			setDescription(aftermarket[currentIndex]?.description || "");
			setPrice(aftermarket[currentIndex]?.price || 0);
			setQty(aftermarket[currentIndex]?.qty || 1);
			setVendor(aftermarket[currentIndex]?.vendor || "");
			setIsTaxable(aftermarket[currentIndex]?.isTaxable || false);
			setCost(aftermarket[currentIndex]?.cost || 0);
			setTotalCost(aftermarket[currentIndex]?.totalCost || 0);
			setTotalPrice(aftermarket[currentIndex]?.totalPrice || 0);
		}
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex, aftermarket]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		aftermarketDescription: yup.string().required().max(50),
		aftermarketPrice: yup.string().required().max(50),
		aftermarketVendor: yup.string().max(50),
		aftermarketCost: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		setTotalCost(cost * qty);
		setTotalPrice(price * qty);
	}, [cost, qty, price]);

	return (
		<>
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle="Aftermarket Item"
						inputComponents={[
							<InputElement
								key="aftermarketDescription"
								readOnly={type !== DealStatus.PENDING}
								{...methods}
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								name="aftermarketDescription"
								label="Description"
								type="text"
								colSize="col-sm-12"
							/>,
							<FormGroup key="aftermarketVendor" className="col-md-9 col">
								<Label>Vendor</Label>
								<Select
									className="mb-3"
									isDisabled={type !== DealStatus.PENDING}
									value={{ value: recNum, label: vendor }}
									options={vendorList.map((value) => ({
										value: value.ID,
										label: value.name,
									}))}
									onChange={(e) => {
										setVendor(e.label);
										setRecNum(e.value);
									}}
								/>
							</FormGroup>,
							<IntegerInputElement
								key="aftermarketQty"
								readOnly={type !== DealStatus.PENDING}
								value={qty}
								onChange={setQty}
								name="aftermarketQty"
								label="Quantity"
								type="number"
							/>,
							<CurrencyInputElement
								key="aftermarketCost"
								readOnly={type !== DealStatus.PENDING}
								value={cost}
								onChange={setCost}
								name="aftermarketCost"
								label="Unit Cost"
								type="number"
							/>,
							<CurrencyInputElement
								key="aftermarketPrice"
								readOnly={type !== DealStatus.PENDING}
								value={price}
								onChange={setPrice}
								name="aftermarketPrice"
								label="Unit Price"
								type="number"
							/>,
							<CurrencyInputElement
								key="totalCost"
								readOnly={true}
								value={totalCost}
								name="totalCost"
								label="Total Cost"
								type="number"
								onChange={setTotalCost}
							/>,
							<CurrencyInputElement
								key="totalPrice"
								readOnly={true}
								value={totalPrice}
								name="totalPrice"
								label="Total Price"
								type="number"
								onChange={setTotalPrice}
							/>,
							<SelectorElement
								key="aftermarketTaxable"
								{...methods}
								readOnly={type !== DealStatus.PENDING}
								name="aftermarketTaxable"
								label="Taxable"
								value={isTaxable}
								onChange={(e) => taxableHandler(e.target.value)}
								options={isTaxableOption}
							/>,
						]}
						buttons={
							<div className="text-center">
								<Button
									readOnly={type !== DealStatus.PENDING}
									color="success"
									onClick={methods.handleSubmit(addAftermarket)}
								>
									<i></i> Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

AftermarketForm.propTypes = {
	currentIndex: PropTypes.number.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default AftermarketForm;
