// MMRForm.js
import manheimLogo from "assets/img/Inventory-Advertising/manheimLG.png";
import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Col, Row } from "reactstrap";

import InputWrapper from "features/forms/components/InputWrapper";
import InputElement, {
	CurrencyInput,
	IntegerInputElement,
} from "features/forms/components/InputElement";
import { IntegerInput } from "features/forms/components/InputElement";
import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentVehicleStore from "../../../../../stores/InventoryStore";
import shallow from "zustand/shallow";
import { yupResolver } from "@hookform/resolvers";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
	useFromPartnerSend,
	useFromUser,
} from "../../../../../stores/LocalStorageHelper";
import { getMMRValues } from "../../../../../api/MMR_API";
import { MmrContext } from "../../../../../context/MMRContext";

const MMRForm = () => {
	const useManheim = useFromPartnerSend("useManheim");

	const { vehicleMMRValues, setVehicleMMRValues } = useContext(MmrContext);
	console.log(vehicleMMRValues);
	const locations = useFromUser("locations");

	const {
		inventoryID,
		vin,
		year,
		make,
		model,
		odometerIn,
		intColor,
		color,

		editOdometerIn,
		editVin,
		editYear,
		editMake,
		editModel,
	} = useCurrentVehicleStore((state) => state, shallow);

	const schema = yup.object().shape({
		enteredVin: yup.string().required().max(50),
		//		MMROdometer: yup.string().required().max(50),
	});

	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const updateMMRValues = async () => {
		const options = {
			odometer: odometerIn,
			zipCode: locations.zipCode,
		};
		const mmrValues = {
			id: vehicleMMRValues.id,
			inventoryID: inventoryID,
			dealerID: locations.dealerID,
			locationID: locations.ID,
			adjWhAbv: 0,
			adjWhAvg: 0,
			adjWhBlw: 0,
			adjRtAbv: 0,
			adjRtAvg: 0,
			adjRtBlw: 0,
			whAbv: 0,
			whAvg: 0,
			whBlw: 0,
			rtAbv: 0,
			rtAvg: 0,
			rtBlw: 0,
			avgOdometer: 0,
			avgGrade: 0,
			reqDate: null,
			retDate: null,
		};
		try {
			const mmrValue = await getMMRValues(vin, options);
			console.log("MMR Values:", mmrValue);
			if (mmrValue.status === "OK") {
				const valuation = mmrValue.content; // Adjust based on your actual response structure
				console.log("Valuation:", valuation);
				//setMMRValues
				mmrValues.inventoryID = inventoryID;
				mmrValues.dealerID = locations.dealerID;
				mmrValues.locationID = locations.id;

				mmrValues.adjWhAbv = valuation.adjustedPricing.wholesale?.above;
				mmrValues.adjWhAvg = valuation.adjustedPricing.wholesale?.average;
				mmrValues.adjWhBlw = valuation.adjustedPricing.wholesale?.below;

				mmrValues.adjRtAbv = valuation.adjustedPricing.retail?.above;
				mmrValues.adjRtAvg = valuation.adjustedPricing.retail?.average;
				mmrValues.adjRtBlw = valuation.adjustedPricing.retail?.below;

				mmrValues.whAbv = valuation.wholesale?.above;
				mmrValues.whAvg = valuation.wholesale?.average;
				mmrValues.whBlw = valuation.wholesale?.below;

				mmrValues.rtAbv = valuation.retail?.above;
				mmrValues.rtAvg = valuation.retail?.average;
				mmrValues.rtBlw = valuation.retail?.below;

				mmrValues.avgOdometer = valuation.averageOdometer;
				mmrValues.avgGrade = valuation.averageGrade;

				mmrValues.reqDate = valuation.requestedDate
					? new Date(valuation.requestedDate)
					: null;
				mmrValues.retDate = valuation.returnedDate
					? new Date(valuation.returnedDate)
					: null;

				mmrValues.href = mmrValue.content?.href;
				mmrValues.itemHref = valuation.href;
			} else {
				console.error("Error:", mmrValue.message);
			}
			setVehicleMMRValues(mmrValues);
		} catch (error) {
			console.error("Failed to fetch MMR values:", error);
		}
	};

	useEffect(() => {
		console.log(vehicleMMRValues);
	}, []);

	if (useManheim === 1) {
		return (
			<FormProvider {...methods}>
				<Form
					onKeyDown={formNavigationUtils}
					className="d-flex justify-content-center"
				>
					<Col>
						<Row className="align-items-center"></Row>
						<Row>
							<InputWrapper
								formTitle="MMR Vehicle Details"
								inputComponents={[
									<InputElement
										key="vin-input"
										{...methods}
										colSize="col-lg-3"
										value={vin || ""}
										name="enteredVin"
										label="VIN"
										type="text"
										readOnly
										//onChange={(e) => editVin(e.target.value)}
									/>,
									<IntegerInputElement
										key="odometer-input"
										{...methods}
										colSize="col-lg-3"
										value={odometerIn}
										name="MMROdometer"
										label="Odometer"
										type="text"
										onChange={editOdometerIn}
									/>,
									<Col
										key="get-values-button"
										className="pt-1 d-flex align-items-center justify-content-start"
									>
										<Button
											className="btn-primary btn-sm"
											color="success"
											onClick={methods.handleSubmit(updateMMRValues)}
										>
											Get Values
										</Button>
									</Col>,
									<div key="spacer" className="noDesktop pb-3 w-100"></div>,
									<Col
										key="empty-col"
										md="12"
										className="d-flex justify-content-center mb-1.5"
									/>,
									<InputElement
										key="year-input"
										{...methods}
										colSize="col-lg-3"
										value={year}
										name="MMRYear"
										label="Year"
										type="text"
										onChange={(e) => editYear(e.target.value)}
										readOnly
									/>,
									<InputElement
										key="make-input"
										{...methods}
										colSize="col-lg-3"
										value={make}
										name="MMRMake"
										label="Make"
										type="text"
										onChange={(e) => editMake(e.target.value)}
										readOnly
									/>,
									<InputElement
										key="model-input"
										{...methods}
										colSize="col-lg-3"
										value={model}
										name="MMRModel"
										label="Model"
										type="text"
										onChange={(e) => editModel(e.target.value)}
										readOnly
									/>,
									<img src={manheimLogo} alt="Manheim" className="mb-3" />,
								]}
							/>
						</Row>
						<div key="spacer" className="noDesktop pb-3 w-100"></div>
						{vehicleMMRValues !== null && (
							<Row>
								<InputWrapper
									formTitle="MMR Vehicle Values"
									inputComponents={[
										<Col key="empty-col" md="12" className="d-flex  mb-1.5">
											<div key="spacer" className="noDesktop pb-3 w-100"></div>
										</Col>,

										<Col
											key="empty-col"
											md="12"
											className="d-flex justify-content-center mb-1.5"
										>
											<h5>Adjusted Pricing</h5>
										</Col>,
										<Col key="empty-col" md="3" className="d-flex  mb-1.5">
											<h6>Wholesale</h6>
										</Col>,

										<CurrencyInput
											key="adjustedPricingWholesaleAbove"
											{...methods}
											colSize="col-lg-3"
											inputClass="form-control"
											value={vehicleMMRValues.adjWhAbv}
											name="adjustedPricingWholesaleAbove"
											label="Wholesale Above"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="adjustedPricingWholesaleAverage"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.adjWhAvg}
											name="adjustedPricingWholesaleAverage"
											label=" Wholesale Average"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="adjustedPricingWholesaleBelow"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.adjWhBlw}
											name="adjustedPricingWholesaleBelow"
											label="Wholesale Below"
											type="Currency"
											readOnly
										/>,
										<div key="spacer" className="noDesktop pb-3 w-100"></div>,
										<Col key="empty-col" md="3" className="d-flex  mb-1.5">
											<h6>Retail</h6>
										</Col>,
										<CurrencyInput
											key="adjustedPricingRetailAbove"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.adjRtAbv}
											name="adjustedPricingRetailAbove"
											label="Retail Above"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="adjustedPricingRetailAverage"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.adjRtAvg}
											name="adjustedPricingRetailAverage"
											label="Retail Average"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="adjustedPricingRetailBelow"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.adjRtBlw}
											name="adjustedPricingRetailBelow"
											label="Retail Below"
											type="Currency"
											readOnly
										/>,
										<div key="spacer" className="noDesktop pb-3 w-100"></div>,
										<Col
											key="empty-col"
											md="12"
											className="d-flex justify-content-center mb-1.5"
										>
											<h5>Pricing</h5>
										</Col>,
										<Col key="empty-col" md="3" className="d-flex  mb-1.5">
											<h6>Wholesale</h6>
										</Col>,
										<CurrencyInput
											key="wholesaleAbove"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.whAbv}
											name="wholesaleAbove"
											label="Above"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="wholesaleAverage"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.whAvg}
											name="wholesaleAverage"
											label="Average"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="wholesaleBelow"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.whBlw}
											name="wholesaleBelow"
											label="Below"
											type="Currency"
											readOnly
										/>,
										<div key="spacer" className="noDesktop pb-3 w-100"></div>,
										<Col key="empty-col" md="3" className="d-flex  mb-1.5">
											<h6>Retail</h6>
										</Col>,
										<CurrencyInput
											key="retailAbove"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.rtAbv}
											name="retailAbove"
											label="Above"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="retailAverage"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.rtAvg}
											name="retailAverage"
											label="Average"
											type="Currency"
											readOnly
										/>,
										<CurrencyInput
											key="retailBelow"
											{...methods}
											colSize="col-lg-3"
											value={vehicleMMRValues.rtBlw}
											name="retailBelow"
											label="Below"
											type="Currency"
											readOnly
										/>,
										// Additional InputElements can go here as needed
									]}
								/>
							</Row>
						)}
					</Col>
				</Form>
			</FormProvider>
		);
	} else {
		return (
			<>
				<img src={manheimLogo} alt="Manheim" className="mb-3" />
				<Form>
					<InputWrapper
						formTitle=""
						inputComponents={[
							<Col
								key="empty-col"
								md="12"
								className="d-flex flex-column align-items-center"
							>
								<h5>This feature is not been Activated.</h5>
								<h6>Please contact Dealerclick to Activate it.</h6>
							</Col>,
						]}
					></InputWrapper>
				</Form>
			</>
		);
	}
};

export default MMRForm;
{
	/* <Row>
					<InputWrapper
						formTitle="MMR Vehicle Values"
						inputComponents={[
							<Col key="empty-col" md="12" className="d-flex  mb-1.5">
								==================================================
							</Col>,

							<Col key="empty-col" md="12" className="d-flex  mb-1.5">
								<h5>Adjusted Pricing Wholesale</h5>
							</Col>,
							<CurrencyInput
								key="adjustedPricingWholesaleAbove"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.wholesale?.above || ""}
								name="adjustedPricingWholesaleAbove"
								label=" Wholesale Above"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="adjustedPricingWholesaleAverage"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.wholesale?.average || ""}
								name="adjustedPricingWholesaleAverage"
								label=" Wholesale Average"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="adjustedPricingWholesaleBelow"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.wholesale?.below || ""}
								name="adjustedPricingWholesaleBelow"
								label="Wholesale Below"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<div key="spacer" className="noDesktop pb-3 w-100"></div>,
							<Col key="empty-col" md="12" className="d-flex  mb-1.5">
								<h5>Adjusted Pricing Retail</h5>
							</Col>,
							<CurrencyInput
								key="adjustedPricingRetailAbove"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.retail?.above || ""}
								name="adjustedPricingRetailAbove"
								label="Retail Above"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="adjustedPricingRetailAverage"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.retail?.average || ""}
								name="adjustedPricingRetailAverage"
								label="Retail Average"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="adjustedPricingRetailBelow"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.adjustedPricing?.retail?.below || ""}
								name="adjustedPricingRetailBelow"
								label="Retail Below"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<div key="spacer" className="noDesktop pb-3 w-100"></div>,
							<Col key="empty-col" md="12" className="d-flex  mb-1.5">
								<h5>Wholesale</h5>
							</Col>,
							<CurrencyInput
								key="wholesaleAbove"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.wholesale?.above || ""}
								name="wholesaleAbove"
								label="Above"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="wholesaleAverage"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.wholesale?.average || ""}
								name="wholesaleAverage"
								label="Average"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="wholesaleBelow"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.wholesale?.below || ""}
								name="wholesaleBelow"
								label="Below"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<div key="spacer" className="noDesktop pb-3 w-100"></div>,
							<Col key="empty-col" md="12" className="d-flex  mb-1.5">
								<h5>Retail</h5>
							</Col>,
							<CurrencyInput
								key="retailAbove"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.retail?.above || ""}
								name="retailAbove"
								label="Above"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="retailAverage"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.retail?.average || ""}
								name="retailAverage"
								label="Average"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							<CurrencyInput
								key="retailBelow"
								{...methods}
								colSize="col-lg-3"
								value={mmrValues?.retail?.below || ""}
								name="retailBelow"
								label="Below"
								type="Currency"
								labelClass="size='sm'"
								readOnly
							/>,
							// You can add more InputElement components for other fields as needed
						]}
					/>
				</Row> */
}
