import React from "react";
import useCurrentVehicleStore from "stores/InventoryStore";
import useRoute from "hooks/useRoute";
import { useHistory } from "react-router-dom";
import { PATHS } from "constants/Constants";
import {
	successAlertCustom,
	confirmDeleteMessage,
	deleteFailAlertWithText,
	twoOptionsAlert,
	confirmDeleteAcctMessage,
} from "utils/alertUtils";
import shallow from "zustand/shallow";
import { Button } from "reactstrap";
import { deleteAccount } from "./AccountAPI";
import useCurrentAccountStore from "./AccountStore";

export const AccountDeleteButton = () => {
	const history = useHistory();
	const newRoute = useRoute(PATHS.ACCT_LIST);
	const { account, payments } = useCurrentAccountStore(
		(state) => ({
			account: state.account,
			payments: state.payments,
		}),
		shallow
	);

	const DeleteAccount = async () => {
		let answer = "Cancel";
		console.log("account", account);
		console.log("payments", payments);
		if (payments.length > 0) {
			answer = await confirmDeleteAcctMessage(
				"Are you sure? This account has Payments.",
				"Yes, Delete it"
			);
		} else {
			answer = await confirmDeleteMessage("Yes, Delete it");
		}
		console.log(answer);
		if (answer === "Yes, Delete it") {
			deleteAccount(account.id).then(
				(res) => {
					history.push(newRoute);
					successAlertCustom("Your account has been  deleted.");
				},
				(error) => {
					if (!error.isGeneralError) {
						console.log(error);
						deleteFailAlertWithText(error?.response?.data?.message);
					}
				}
			);
		}
	};

	return (
		<Button className="btn-md btn btn-danger" onClick={() => DeleteAccount()}>
			<i className="nc-icon nc-simple-remove"></i> Delete
		</Button>
	);
};

export default AccountDeleteButton;
