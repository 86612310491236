import React from "react";
import { Col } from "reactstrap";

import arrayMove from "array-move";
import useCurrentDealStore from "stores/DealStore";
import useCurrentVehicleStore from "stores/InventoryStore";
import useCurrentPhotoStore from "stores/PhotoStore";
import Gallery from "react-photo-gallery";
import DraggablePhoto from "./DraggablePhoto";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useFromUser } from "stores/LocalStorageHelper";
import { reorderImages } from "api/InventoryAPI";
import {
	createPhotosObj,
	getReorders,
	getThumbnailURL,
	isReordered,
} from "./helperFunctions";
import { saveSuccessAlert, failAlert } from "../../../../../utils/alertUtils";

import shallow from "zustand/shallow";

// React HOC components, used for sorting and needs to be outside on
// component or screen flickers on drag and drop
const SortablePhoto = SortableElement((photos) => (
	<DraggablePhoto {...photos} />
));

const SortableGallery = SortableContainer(({ photos }) => (
	<Gallery
		photos={photos}
		renderImage={(props) => <SortablePhoto {...props} />}
	/>
));

/**
 * Reorders the images. Sends an array containing the reordered index
 * to the API.
 *
 * @param showReorder Parent hook set function to hide/show the reorder button
 */
const ReorderPhotos = ({ showReorder }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Vehicle store
	const { vin, editThumbnail } = useCurrentVehicleStore(
		(state) => ({
			vin: state.vin,
			editThumbnail: state.editThumbnail,
		}),
		shallow
	);

	// Photo store
	const { reorders, editReorders, editGallery } = useCurrentPhotoStore(
		(state) => ({
			reorders: state.reorders,
			editReorders: state.editReorders,
			editGallery: state.editGallery,
		}),
		shallow
	);

	// Deal store
	const { vehicle, editVehicle } = useCurrentDealStore(
		(state) => ({
			vehicle: state.vehicle,
			editVehicle: state.editVehicle,
		}),
		shallow
	);

	// Saves the reordering of images
	const saveReorder = () => {
		const reorderedIndexes = getReorders(reorders);

		if (isReordered(reorderedIndexes)) {
			reorderImages(dealerID, locationID, vin, reorderedIndexes).then(
				(res) => {
					const imgArr = res.data.content;
					const arrObj = createPhotosObj(imgArr);
					const thumbnailUrl = getThumbnailURL(imgArr);

					editThumbnail(thumbnailUrl);
					editReorders(arrObj);
					editGallery(arrObj);
					showReorder(false);

					if (vehicle != null) {
						editVehicle({ ...vehicle, thumbnail: thumbnailUrl });
					}
				},

				(error) => {
					console.log(error);
					failAlert(`Failed to reorder photos for ${vin}.`);
				}
			);
			saveSuccessAlert();
		} else {
			failAlert(`No images selected to reorder for ${vin}.`);
		}
	};

	// Moves the array element from the old index to a new one
	const onSortEnd = ({ oldIndex, newIndex }) => {
		editReorders(arrayMove(reorders, oldIndex, newIndex));
	};

	return (
		<>
			<div
				className="btn btn-lg btn-success float-button"
				onClick={saveReorder}
			>
				<i className="nc-icon nc-check-2" /> Save
			</div>

			<Col className="pb-3">
				<Col>
					<div
						className="btn btn-md btn-danger"
						onClick={() => showReorder(false)}
					>
						<i className="nc-icon nc-simple-remove"></i> Cancel
					</div>
				</Col>
			</Col>
			<Col md="12">
				<SortableGallery photos={reorders} onSortEnd={onSortEnd} axis={"xy"} />
			</Col>
		</>
	);
};

export default ReorderPhotos;
