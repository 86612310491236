import useSetAccount from "./useSetAccount";
import { saveAccount } from "./AccountAPI";
import useBuildAccount from "./useBuildAccount";
import { progressLoading, saveSuccessAlert } from "../utils/alertUtils";
import Swal from "sweetalert2";

const AccountHook = () => {
	const setAccount = useSetAccount();
	const getAcct = useBuildAccount();

	const save = () => {
		const acctObj = getAcct();
		console.log(acctObj);
		saveAccount(acctObj).then(
			(res) => {
				console.log(res);
				progressLoading("Saving Account");
				setAccount(res);
				Swal.close();
				saveSuccessAlert();
			},
			(err) => {}
		);
	};

	return save;
};

export default AccountHook;
