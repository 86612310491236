import React from "react";
import { Form, FormGroup, Label, Row, Card, CardBody, Col } from "reactstrap";
import useCurrentSaleStore from "stores/SaleStore";
import useCurrentDealStore from "stores/DealStore";
import Select from "react-select";
import { useForm, FormProvider } from "react-hook-form";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import { INS_TYPE, INS_TYPE_AH } from "../../../../../../constants/Constants";
import useCurrentRecapStore from "stores/RecapStore";
import {
	SelectorElement,
	CurrencyInputElement,
} from "features/forms/components/InputElement";

const CreditInsurance = () => {
	// Sale Store
	const {
		creditLifeFlag,
		disabilityFlag,
		lifePremium,
		disabilityPremium,
		editCreditLifeFlag,
		editDisabilityFlag,
		editLifePremium,
		editDisabilityPremium,
	} = useCurrentSaleStore(
		(state) => ({
			creditLifeFlag: state.creditLifeFlag,
			disabilityFlag: state.disabilityFlag,
			lifePremium: state.lifePremium,
			disabilityPremium: state.disabilityPremium,
			editCreditLifeFlag: state.editCreditLifeFlag,
			editDisabilityFlag: state.editDisabilityFlag,
			editLifePremium: state.editLifePremium,
			editDisabilityPremium: state.editDisabilityPremium,
		}),
		shallow
	);

	// Deal Store
	const {
		insCreditLifeRecNum,
		type,
		editInsCreditLifeRecNum,
		gapCompanies,
	} = useCurrentDealStore(
		(state) => ({
			insCreditLifeRecNum: state.deal.insCreditLifeRecNum,
			type: state.deal.type,
			editInsCreditLifeRecNum: state.editInsCreditLifeRecNum,
			gapCompanies: state.gapCompanies,
		}),
		shallow
	);

	// Recap Store
	const {
		creditLifeCost,
		disabCost,
		editCreditLifeCost,
		editDisabCost,
	} = useCurrentRecapStore(
		(state) => ({
			creditLifeCost: state.creditLifeCost,
			disabCost: state.disabCost,
			editCreditLifeCost: state.editCreditLifeCost,
			editDisabCost: state.editDisabCost,
		}),
		shallow
	);

	const gapComList = gapCompanies.map((obj) => ({
		label: obj.name,
		value: obj.ID,
		gapCo: obj,
	}));

	// Define form validation parameters
	const methods = useForm();

	return (
		<Card>
			<CardBody>
				<FormProvider {...methods}>
					<Form>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Label>Company</Label>
									<Select
										isDisabled={type !== DealStatus.PENDING}
										value={
											gapComList.find((e) => e.value === insCreditLifeRecNum) ||
											{}
										}
										options={gapComList}
										onChange={(e) => editInsCreditLifeRecNum(e.value)}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<SelectorElement
									{...methods}
									name="life"
									label="Life"
									value={creditLifeFlag}
									onChange={(e) => editCreditLifeFlag(e.target.value)}
									options={INS_TYPE}
									disabled={type !== DealStatus.PENDING}
								/>
							</Col>
							<Col md={4}>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={creditLifeCost}
									onChange={editCreditLifeCost}
									name="creditLifeCost"
									label="Cost"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Col>
							<Col md={4}>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={lifePremium}
									onChange={editLifePremium}
									name="lifePremium"
									label="Amount"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<SelectorElement
									{...methods}
									name="disability"
									label="Disability"
									value={disabilityFlag}
									onChange={(e) => editDisabilityFlag(e.target.value)}
									options={INS_TYPE_AH}
									disabled={type !== DealStatus.PENDING}
								/>
							</Col>
							<Col md={4}>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={disabCost}
									onChange={editDisabCost}
									name="disabCost"
									label="Cost"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Col>
							<Col md={4}>
								<CurrencyInputElement
									readOnly={type !== DealStatus.PENDING}
									value={disabilityPremium}
									onChange={editDisabilityPremium}
									name="disabilityPremium"
									label="Amount"
									type="number"
									wholeRow={true}
									disableValidation
								/>
							</Col>
						</Row>
					</Form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default CreditInsurance;
