import React, { createContext, useContext, useState } from "react";

// Checks that a component higher up in the component is wrapped with
// the DataProvider before using the context. Takes care of having
// to do undefined checks for context data
export const createDataContext = () => {
	const context = createContext(undefined);

	const useDataContext = () => {
		const c = useContext(context);

		if (!c) {
			throw new Error(
				"useDataContext is not inside a Provider with a value. Check that a component " +
					"is wrapped with the DataProvider."
			);
		}

		return c;
	};

	return [useDataContext, context.Provider];
};

// Get the context and provider
const [useCompanyEmployeeData, CtxProvider] = createDataContext();

// Custom hook to retrieve context states
export { useCompanyEmployeeData };

// Data provider to children components
export const CompanyEmployeesProvider = ({ children }) => {
	// Used for the privileges selector
	const privilegeSelectorOptions = [
		{ label: "Unwind Deal", value: "unwindDeal", checked: false },
		{ label: "Delete Deal", value: "deleteDeal", checked: false },
		{ label: "Save Deal", value: "saveDeal", checked: false },
		{ label: "Deal Financing Tab", value: "dealFinancingTab", checked: false },
		{ label: "Deal Forms Tab", value: "dealFormsTab", checked: false },
		{
			label: "Deal Commissions Tab",
			value: "dealCommissionsTab",
			checked: false,
		},
		{ label: "Create New Deal", value: "dealNew", checked: false },
		{ label: "Recap", value: "recap", checked: false },
		{ label: "Transfer Deal To Sold", value: "transferToSold", checked: false },
		{ label: "Pull Customer Credit", value: "pullCredit", checked: false },
		{ label: "Add New Customer", value: "customerNew", checked: false },
		{ label: "Save Customer", value: "customerSave", checked: false },
		{ label: "Add New Inventory", value: "inventoryNew", checked: false },
		{ label: "Save Inventory", value: "inventorySave", checked: false },
		{
			label: "Inventory Costs Tab",
			value: "inventoryCostsTab",
			checked: false,
		},
		{
			label: "Inventory Advertising",
			value: "inventoryAdvertising",
			checked: false,
		},
	];

	// Used for saving a privilege object
	const privileges = {
		unwindDeal: false,
		deleteDeal: false,
		saveDeal: false,
		recap: false,
		transferToSold: false,
		dealFinancingTab: false,
		dealFormsTab: false,
		dealCommissionsTab: false,
		dealNew: false,
		pullCredit: false,
		customerNew: false,
		customerSave: false,
		inventoryNew: false,
		inventorySave: false,
		inventoryCostsTab: false,
		inventoryAdvertising: false,
	};

	// States
	const [imageFile, setImageFile] = useState(null);
	const [imageData, setImageData] = useState(null);
	const [userID, setUserID] = useState(null);
	const [newUserUsername, setNewUserUsername] = useState(null);
	const [newUserPassword, setNewUserPassword] = useState(null);
	const [newUserConfirmPass, setNewUserConfirmPass] = useState(null);
	const [users, setUsers] = useState([]);
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [title, setTitle] = useState("");
	const [driversLicense, setDriversLicense] = useState("");
	const [salesLicense, setSalesLicense] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [cellPhone, setCellPhone] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [zip, setZip] = useState("");
	const [state, setState] = useState("");
	const [commissionPercent, setCommissionPercent] = useState(0);
	const [commissionMinimum, setCommissionMinimum] = useState(0);
	const [commissionFixedAmount, setCommissionFixedAmount] = useState(0);
	const [commissionType, setCommissionType] = useState(null);
	const [personnelType, setPersonnelType] = useState(null);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedPrivileges, setSelectedPrivileges] = useState(privileges);
	const [privilegeOptions, setPrivilegeOptions] = useState(
		privilegeSelectorOptions
	);

	// Returns the employee account object to save to dcLogin
	const getNewUserFields = () => ({
		ID: userID,
		firstName,
		middleName,
		lastName,
		email,
		title,
		commissionPercent,
		commissionFixedAmount,
		commissionType,
		commissionMinimum,
		personnelType,
		driversLicense,
		cellPhone,
		phone,
		salesLicense,
		street,
		city,
		zip,
		state,
		active: false,
		username: newUserUsername,
		password: newUserPassword,
		userPrivileges: JSON.stringify({
			role: selectedRole,
			privileges: selectedPrivileges,
		}),
	});

	// Sets the current state values with the passed in user
	const setEditUserFields = (user) => {
		const privilege = JSON.parse(user.userPrivileges);

		setUserID(user.ID);
		setNewUserUsername(user.username);
		setFirstName(user.firstName);
		setMiddleName(user.middleName);
		setLastName(user.lastName);
		setTitle(user.title);
		setDriversLicense(user.driversLicense);
		setSalesLicense(user.salesLicense);
		setEmail(user.email);
		setPhone(user.phone);
		setCellPhone(user.cellPhone);
		setStreet(user.street);
		setCity(user.city);
		setZip(user.zip);
		setState(user.state);
		setCommissionPercent(user.commissionPercent);
		setCommissionMinimum(user.commissionMinimum);
		setCommissionFixedAmount(user.commissionFixedAmount);
		setCommissionType(user.commissionType);
		setPersonnelType(user.personnelType);
		setSelectedRole(privilege?.role || null);
		setSelectedPrivileges(privilege?.privileges || { ...privileges });

		const updatedPrivilegeOptions = [...privilegeSelectorOptions];

		updatedPrivilegeOptions.map(
			(option) =>
				(option.checked = privilege?.privileges?.[option.value] || false)
		);

		setPrivilegeOptions(updatedPrivilegeOptions);
	};

	// Resets employee tab form fields to initial
	const resetFields = () => {
		setImageFile(null);
		setImageData(null);
		setUserID(null);
		setNewUserUsername(null);
		setNewUserPassword(null);
		setNewUserConfirmPass(null);
		setFirstName("");
		setMiddleName("");
		setLastName("");
		setTitle("");
		setDriversLicense("");
		setSalesLicense("");
		setEmail("");
		setPhone("");
		setCellPhone("");
		setStreet("");
		setCity("");
		setZip("");
		setState("");
		setCommissionPercent(0);
		setCommissionMinimum(0);
		setCommissionFixedAmount(0);
		setCommissionType(null);
		setPersonnelType(null);
		setSelectedRole(null);
		setSelectedPrivileges({ ...privileges });
		setPrivilegeOptions([...privilegeSelectorOptions]);
	};

	return (
		<CtxProvider
			value={{
				imageFile,
				setImageFile,
				imageData,
				setImageData,
				userID,
				setUserID,
				newUserUsername,
				setNewUserUsername,
				newUserPassword,
				setNewUserPassword,
				newUserConfirmPass,
				setNewUserConfirmPass,
				users,
				setUsers,
				firstName,
				setFirstName,
				middleName,
				setMiddleName,
				lastName,
				setLastName,
				title,
				setTitle,
				driversLicense,
				setDriversLicense,
				salesLicense,
				setSalesLicense,
				email,
				setEmail,
				phone,
				setPhone,
				cellPhone,
				setCellPhone,
				street,
				setStreet,
				city,
				setCity,
				zip,
				setZip,
				state,
				setState,
				commissionPercent,
				setCommissionPercent,
				commissionMinimum,
				setCommissionMinimum,
				commissionFixedAmount,
				setCommissionFixedAmount,
				commissionType,
				setCommissionType,
				personnelType,
				setPersonnelType,
				selectedRole,
				setSelectedRole,
				selectedPrivileges,
				setSelectedPrivileges,
				privilegeOptions,
				setPrivilegeOptions,
				getNewUserFields,
				setEditUserFields,
				resetFields,
			}}
		>
			{children}
		</CtxProvider>
	);
};
