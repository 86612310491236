import React, { useState } from "react";

import digitzsValidationSchema from "./digitzsValidationSchema";
import DigitzsAgreement from "./DigitzsAgreement";
import DigitzsBankInformation from "./DigitzsBankInformation";
import DigitzsBusinessInformation from "./DigitzsBusinessInformation";
import DigitzsPersonalInformation from "./DigitzsPersonalInformation";
import DigitzsProgressBar from "./DigitzsProgressBar";
import DigitzsSummary from "./DigitzsSummary";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import "assets/css/multi-step-form.css";

const Digitzs = ({ toggle }) => {
	const formComponents = {
		personal: 0,
		business: 1,
		bank: 2,
		agreement: 3,
		summary: 4,
	};

	// Input fields
	// Personal Info Form
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [dayPhone, setDayPhone] = useState("");
	const [eveningPhone, setEveningPhone] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [socialSecurity, setSocialSecurity] = useState("");
	const [street, setStreet] = useState("");
	const [unit, setUnit] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("USA");
	const [zip, setZip] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [ein, setEin] = useState("");

	// Business Info Form
	const [businessPhone, setBusinessPhone] = useState("");
	const [businessEmail, setBusinessEmail] = useState("");
	const [businessWebsite, setBusinessWebsite] = useState("");
	const [businessStreet, setBusinessStreet] = useState("");
	const [businessUnit, setBusinessUnit] = useState("");
	const [businessCity, setBusinessCity] = useState("");
	const [businessState, setBusinessState] = useState("");
	const [businessZip, setBusinessZip] = useState("");
	const [businessCountry, setBusinessCountry] = useState("USA");

	// Bank Info Form
	const [bankName, setBankName] = useState("");
	const [accountOwnership, setAccountOwnership] = useState("");
	const [accountType, setAccountType] = useState("");
	const [accountName, setAccountName] = useState("");
	const [accountNumber, setAccountNumber] = useState("");
	const [routingNumber, setRoutingNumber] = useState("");

	// Agreement Form
	const [signature, setSignature] = useState("");
	const [checked, setChecked] = useState(false);

	// Keeps track of the current form tab
	const [currentForm, setCurrentForm] = useState(formComponents.personal);

	// Wrapper for input fields
	const personalInfo = {
		firstName,
		lastName,
		email,
		dayPhone,
		eveningPhone,
		birthDate,
		socialSecurity,
	};

	const personalAddress = {
		line1: street,
		line2: unit,
		city,
		state,
		zip,
		country,
	};

	const businessInfo = {
		businessName,
		ein,
		phone: businessPhone,
		email: businessEmail,
		url: businessWebsite,
	};

	const businessAddress = {
		line1: businessStreet,
		line2: businessUnit,
		city: businessCity,
		state: businessState,
		zip: businessZip,
		country: businessCountry,
	};

	const bankInfo = {
		bankName,
		accountOwnership,
		accountType,
		accountName,
		accountNumber,
		routingNumber,
	};

	const agreementInfo = {
		signature,
		termsAccepted: checked,
		emailVerified: true,
	};

	// Wrapper for input setters
	const setPersonalInfo = {
		setFirstName,
		setLastName,
		setSocialSecurity,
		setEmail,
		setBirthDate,
		setDayPhone,
		setEveningPhone,
	};

	const setPersonalAddress = {
		setStreet,
		setUnit,
		setCity,
		setState,
		setZip,
		setCountry,
	};

	const setBusinessInfo = {
		setBusinessName,
		setEin,
		setBusinessWebsite,
		setBusinessEmail,
		setBusinessPhone,
	};

	const setBusinessAddress = {
		setBusinessStreet,
		setBusinessUnit,
		setBusinessCity,
		setBusinessState,
		setBusinessZip,
		setBusinessCountry,
	};

	const setBankInfo = {
		setBankName,
		setAccountType,
		setAccountOwnership,
		setAccountName,
		setAccountNumber,
		setRoutingNumber,
	};

	const setAgreementInfo = {
		setSignature,
		setChecked,
	};

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onChange",
		resolver: yupResolver(digitzsValidationSchema),
		shouldFocusError: true,
	});

	return (
		<div className="multi-step-form">
			<div className="form-container">
				<FormProvider {...methods}>
					<DigitzsProgressBar
						currentForm={currentForm}
						setCurrentForm={setCurrentForm}
					/>
					{currentForm === formComponents.personal && (
						<DigitzsPersonalInformation
							personalInfo={personalInfo}
							personalAddress={personalAddress}
							setPersonalInfo={setPersonalInfo}
							setPersonalAddress={setPersonalAddress}
							setCurrentForm={setCurrentForm}
							nextForm={formComponents.business}
						/>
					)}
					{currentForm === formComponents.business && (
						<DigitzsBankInformation
							bankInfo={bankInfo}
							setBankInfo={setBankInfo}
							setCurrentForm={setCurrentForm}
							nextForm={formComponents.bank}
							previousForm={formComponents.personal}
						/>
					)}
					{currentForm === formComponents.bank && (
						<DigitzsBusinessInformation
							readOnly={accountOwnership === "personal" ? true : false}
							businessInfo={businessInfo}
							businessAddress={businessAddress}
							setBusinessInfo={setBusinessInfo}
							setBusinessAddress={setBusinessAddress}
							setCurrentForm={setCurrentForm}
							nextForm={formComponents.agreement}
							previousForm={formComponents.business}
						/>
					)}
					{currentForm === formComponents.agreement && (
						<DigitzsAgreement
							personalInfo={personalInfo}
							check={checked}
							agreementInfo={agreementInfo}
							setAgreementInfo={setAgreementInfo}
							setCurrentForm={setCurrentForm}
							nextForm={formComponents.summary}
							previousForm={formComponents.bank}
						/>
					)}
					{currentForm === formComponents.summary && (
						<DigitzsSummary
							setCurrentForm={setCurrentForm}
							previousForm={formComponents.agreement}
							data={{
								personalInfo,
								personalAddress,
								businessInfo,
								businessAddress,
								bankInfo,
								agreementInfo,
							}}
							toggle={toggle}
						>
							<div className="form-section">
								<DigitzsPersonalInformation
									personalInfo={personalInfo}
									personalAddress={personalAddress}
									setPersonalInfo={setPersonalInfo}
									setPersonalAddress={setPersonalAddress}
									readOnly={true}
								/>
							</div>
							<div className="form-section">
								<DigitzsBusinessInformation
									businessInfo={businessInfo}
									businessAddress={businessAddress}
									setBusinessInfo={setBusinessInfo}
									setBusinessAddress={setBusinessAddress}
									readOnly={true}
								/>
							</div>
						</DigitzsSummary>
					)}
				</FormProvider>
			</div>
		</div>
	);
};

export default Digitzs;
