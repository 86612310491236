import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

import ChildrenModal from "../../components/modals/ChildrenModal";
import MessageTemplateDetails from "./MessageTemplateDetails";
import MessageTemplateTable from "./MessageTemplateTable";

import { showApiError } from "../../utils/errorRoutingUtils";
import {
	deleteTwilioMessageTemplates,
	getTwilioMessageTemplates,
	saveTwilioMessageTemplate,
} from "../../api/TwilioApi";
import {
	failAlert,
	saveSuccessAlert,
	twoOptionsAlert,
} from "../../utils/alertUtils";

import "assets/css/message-template.css";

const MessageTemplates = () => {
	const locationID = JSON.parse(sessionStorage.getItem("user")).locationID;

	const [template, setTemplate] = useState({});
	const [templateList, setTemplateList] = useState([]);
	const [templateModal, setTemplateModal] = useState(false);

	// Toggles the modal
	const toggleTemplateModal = () => setTemplateModal(!templateModal);

	// Saves a new/existing message template and updates table
	const saveTemplate = (templateToSave) => {
		templateToSave.locationID = locationID;

		saveTwilioMessageTemplate(templateToSave).then(
			(res) => {
				const savedTemplate = res.data.content;
				const updatedTemplates = templateList.map((temp) => {
					if (temp.ID === savedTemplate.ID) {
						return savedTemplate;
					}

					return temp;
				});

				// For adding new templates to list
				if (templateToSave.ID == null) {
					updatedTemplates.push(savedTemplate);
				}

				setTemplate({});
				setTemplateList(updatedTemplates);
				saveSuccessAlert(res.data.message);
				toggleTemplateModal();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, failAlert);
				}
			}
		);
	};

	// Deletes a message template after getting confirmation from user
	// Then updates list to remove deleted template
	const deleteTemplate = async (deleteTemplate) => {
		const option = await twoOptionsAlert(
			"Are you sure you want to delete this template?",
			"Yes",
			"No"
		);

		if (option === "Yes") {
			deleteTwilioMessageTemplates(deleteTemplate).then((res) => {
				const updatedTemplates = templateList.filter(
					(temp) => temp.ID !== deleteTemplate.ID
				);

				setTemplate({});
				setTemplateList(updatedTemplates);
				saveSuccessAlert(res.data.message);
			});
		}
	};

	useEffect(() => {
		getTwilioMessageTemplates(locationID).then(
			(res) => setTemplateList(res.data.content),
			(err) => console.log(err)
		);
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Button
				className="btn btn-primary btn-md mb-3"
				onClick={toggleTemplateModal}
			>
				<i className="nc-icon nc-simple-add" /> Add Template
			</Button>
			<ChildrenModal
				size="lg"
				modal={templateModal}
				toggle={toggleTemplateModal}
			>
				<MessageTemplateDetails
					template={template}
					setTemplate={setTemplate}
					saveTemplate={saveTemplate}
				/>
			</ChildrenModal>
			{templateList.length > 0 && (
				<MessageTemplateTable
					templates={templateList}
					setTemplate={setTemplate}
					deleteTemplate={deleteTemplate}
					toggle={toggleTemplateModal}
				/>
			)}
		</div>
	);
};

export default MessageTemplates;
