import React from "react";
import InputElement from "features/forms/components/InputElement";
import { WorkPhoneInputElement } from "features/forms/components/InputElement";
import { PhoneInputElement } from "features/forms/components/InputElement";
import OFAC from "../../helperFunctions/OFAC";

/**
 * The PersonalData component.
 *
 * @param {object} props - The props.
 * @param {object} props.currentLead - The current lead.
 * @param {function} props.setCurrentLead - The setter for the current lead.
 * @returns {JSX.Element} The rendered component.
 */

const PersonalData = ({
	currentLead,
	setCurrentLead,
	methods,
	handleChange,
}) => {
	return (
		<>
			<InputElement
				disableValidation={true}
				value={currentLead.lastName}
				name="lastName"
				label="Last Name"
				type="text"
				onChange={(e) => handleChange("lastName", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.middleName}
				name="middleName"
				label="Middle Name"
				type="text"
				onChange={(e) => handleChange("middleName", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.firstName}
				name="firstName"
				label="First Name"
				type="text"
				onChange={(e) => handleChange("firstName", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.gender}
				name="gender"
				label="Gender"
				type="text"
				onChange={(e) => handleChange("gender", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.dob}
				name="dob"
				label="DateOfBirth"
				type="date"
				onChange={(e) => handleChange("dob", e.target.value)}
			/>
			<PhoneInputElement
				{...methods}
				disableValidation={true}
				value={currentLead?.cellPhone}
				name="cellPhone"
				label="Cell Phone"
				onChange={(e) => handleChange("cellPhone", e)}
			/>
			<PhoneInputElement
				{...methods}
				disableValidation={true}
				value={currentLead.phone}
				name="phone"
				label="Primary Phone"
				onChange={(e) => handleChange("phone", e)}
			/>
			<WorkPhoneInputElement
				disableValidation={true}
				value={
					currentLead.phone +
					(currentLead.workPhoneExt === null ? "" : currentLead.workPhoneExt)
				}
				name="workPhone"
				label="Work Phone"
				onChange={(e) => handleChange("workPhone", e)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.email}
				name="email"
				label="Email"
				type="text"
				onChange={(e) => handleChange("email", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.driverLicense}
				name="driverLicense"
				label="Driver License"
				type="text"
				onChange={(e) => handleChange("driverLicense", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.driverLicExpDate}
				name="driverLicExpDate"
				label="DL ExpDate"
				type="date"
				onChange={(e) => handleChange("driverLicExpDate", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.address}
				name="address"
				label="Street"
				type="text"
				onChange={(e) => handleChange("address", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.unit}
				name="unit"
				label="Apt/Unit/Bld"
				type="text"
				onChange={(e) => handleChange("unit", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.city}
				name="city"
				label="City"
				type="text"
				onChange={(e) => handleChange("city", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.state}
				name="state"
				label="State"
				onChange={(e) => handleChange("state", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				value={currentLead.zipCode}
				name="zip"
				label="Zip"
				onChange={(e) => handleChange("zipCode", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				label="Lead Origin"
				name="leadOriginName"
				value={currentLead.leadOriginName}
				onChange={(e) => handleChange("leadOriginName", e.target.value)}
			/>
			<InputElement
				disableValidation={true}
				label="CreatedBy"
				name="createdBy"
				value={currentLead.createdBy}
				onChange={(e) => handleChange("createdBy", e.target.value)}
			/>
			<OFAC />
		</>
	);
};

export default PersonalData;
