import React from "react";
import PropTypes from "prop-types";
import { CardText, Row, Col, Input } from "reactstrap";
import NumberFormat from "react-number-format";

/**
 * FinancePercent component for displaying and editing percentage values.
 *
 * @component
 * @param {object} props - Component props
 * @param {string} props.label - Label for the percentage input
 * @param {number} props.value - Value of the percentage input
 * @param {function} props.onChange - Callback function for value change
 * @param {boolean} props.disabled - Flag to disable the input
 * @returns {JSX.Element} The rendered component
 */
const FinancePercent = ({ label, value, onChange, disabled }) => {
	return (
		<Row className="mb-2 align-items-center">
			<Col md="6">
				<CardText>{label}</CardText>
			</Col>
			<Col md="6">
				<NumberFormat
					value={value * 100}
					decimalScale={2}
					fixedDecimalScale
					onFocus={(e) => e.target.select()}
					isNumericString
					disabled={disabled}
					readOnly={disabled}
					customInput={Input}
					onValueChange={(values) => {
						if (!disabled) {
							onChange(values.value === "" ? 0 : values.floatValue / 100);
						}
					}}
				/>
			</Col>
		</Row>
	);
};

FinancePercent.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default FinancePercent;
