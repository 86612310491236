import React, { useState } from "react";
import { Input, Row, Col } from "reactstrap";
import ServiceContractModal from "../modals/service/ServiceContractModal";
import OpenTotalModal from "../OpenTotalModal";
import useCurrentSaleStore from "stores/SaleStore";
import NumberFormat from "react-number-format";
import shallow from "zustand/shallow";

const ServiceContract = () => {
	const [openWarrantyModal, setOpenWarrantyModal] = useState(false);

	const { warranty } = useCurrentSaleStore(
		(state) => ({
			warranty: state.warranty,
		}),
		shallow
	);

	// Function to toggle the modal
	const toggleModal = () => {
		setOpenWarrantyModal(!openWarrantyModal);
	};

	return (
		<Row>
			{openWarrantyModal && (
				<ServiceContractModal modal={openWarrantyModal} toggle={toggleModal} />
			)}
			<Col xs={6} className="px-0">
				<OpenTotalModal
					btnClass="w-100 btn btn-primary btn-md px-0"
					label="Warranty"
					toggle={toggleModal}
				/>
			</Col>
			<Col xs={6} className="pr-0 pl-2">
				<NumberFormat
					value={warranty}
					thousandSeparator
					decimalScale={2}
					fixedDecimalScale
					prefix="$"
					displayType="input"
					customInput={Input}
					readOnly
				/>
			</Col>
		</Row>
	);
};

export default ServiceContract;
