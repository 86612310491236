import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Input, Button, Row, Col, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { setLocale } from "yup";
import useCurrentDealStore from "stores/DealStore";
import StyleSelector from "../../../../inventory/subFeatures/selector/StyleSelector";
import { decodeVINv2 } from "api/VINDecoderAPI";
import { retrieveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import { TradeContext } from "./TradeContext";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import { ErrorMessage } from "@hookform/error-message";

/**
 * Component to take a user-entered VIN and decode it.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 */
const DealVINDecoderForm = () => {
	// Retrieve type from deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Retrieve functions and state from TradeContext
	const { editStockNo, vin, setDecodedFields } = useContext(TradeContext);

	// Local states
	const [enteredVin, setEnteredVin] = useState(vin || "");
	const [isVinEntered, setIsVinEntered] = useState(false);
	const [styleNames, setStyleNames] = useState([]);
	const [resultContents, setContents] = useState([]);

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			length: "VIN must contain a combination of 17 letters and numbers",
			matches: "Only number and letters allowed",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		vin: yup
			.string()
			.required()
			.length(17)
			.matches(/^[a-zA-Z0-9]*$/),
	});

	// Define form validation parameters
	const { register, handleSubmit, errors } = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	/**
	 * API call to decode the VIN and update the state with the response.
	 */
	const getData = () => {
		decodeVINv2(enteredVin).then(
			(res) => {
				const contents = res.data.content;
				const subModels = contents.map((content) => content.subModel);

				setStyleNames(subModels);
				setIsVinEntered(true);
				setContents(contents);
				setStore(contents[0]);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	/**
	 * Sets the vehicle store with the selected style.
	 *
	 * @param {Object} style - The selected vehicle style.
	 */
	const setStore = (style) => {
		setDecodedFields(style);
		editStockNo(style.vin.substring(style.vin.length - 6));
	};

	/**
	 * Handles input change for the VIN field.
	 *
	 * @param {Object} e - The input change event.
	 */
	const handleInputChange = (e) => {
		setEnteredVin(e.target.value.toUpperCase());
		setIsVinEntered(false);
	};

	/**
	 * Handles the selection change in the style selector.
	 *
	 * @param {Object} e - The selection change event.
	 */
	const handleSelectorChange = (e) => {
		const subModel = e.target.value;
		const selectedStyle = resultContents.find(
			(item) => item.subModel === subModel
		);
		setStore(selectedStyle);
	};

	return (
		<Form
			className="d-flex align-items-center"
			onSubmit={(e) => e.preventDefault()}
		>
			<Row
				id="vinDecoder"
				className="align-items-center"
				style={{ flexWrap: "nowrap" }}
			>
				<Input
					maxLength="17"
					innerRef={register}
					placeholder="Enter VIN"
					value={enteredVin}
					onChange={handleInputChange}
					invalid={!!errors.vin}
					name="vin"
				/>
				<ErrorMessage
					errors={errors}
					name="vin"
					render={({ message }) => <p className="error_text">{message}</p>}
				/>
				<Button
					readOnly={type !== DealStatus.PENDING}
					type="submit"
					onClick={handleSubmit(getData)}
					className="btn btn-md btn-primary ml-2"
				>
					Decode
				</Button>
			</Row>
			{isVinEntered && (
				<Col>
					<StyleSelector
						options={styleNames}
						label="Select a style"
						getInput={handleSelectorChange}
					/>
				</Col>
			)}
		</Form>
	);
};

/**
 * PropTypes for the DealVINDecoderForm component.
 */
DealVINDecoderForm.propTypes = {
	type: PropTypes.oneOf(Object.values(DealStatus)),
	editStockNo: PropTypes.func.isRequired,
	vin: PropTypes.string,
	setDecodedFields: PropTypes.func.isRequired,
};

export default DealVINDecoderForm;
