import React, { useEffect, useState } from "react";
import useCurrentDealStore from "stores/DealStore";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import { saveDeal } from "api/DealAPI";
import { saveFailAlert } from "utils/alertUtils";
import { showApiError } from "utils/errorRoutingUtils";
import NoteButton from "features/notes/NoteButton";
import { DealStatus } from "constants/Constants";
import shallow from "zustand/shallow";
import useSetDeal from "features/deal/hooks/useSetDeal";

const NotesButton = () => {
	const { ID, type, notes, editNotes } = useCurrentDealStore(
		(state) => ({
			ID: state.deal.ID,
			type: state.deal.type,
			notes: state.deal.notes,
			editNotes: state.editNotes,
		}),
		shallow
	);
	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();
	const [shouldSaveNotes, setShouldSaveNotes] = useState(false); // Add a flag to control saving

	// Log notes state every time it updates for debugging purposes
	useEffect(() => {
		console.log("Current Notes State:", notes);
	}, [notes]);

	const saveNotes = () => {
		if (ID != null) {
			const deal = getDeal();
			let modifiedDeal = { ...deal };

			console.log("Latest notes before saving:", notes);

			const modifiedNotes = notes.length > 0 ? JSON.stringify(notes) : "[]";
			modifiedDeal.deal.notes = modifiedNotes;

			console.log("Modified deal being sent to API:", modifiedDeal);

			saveDeal(modifiedDeal)
				.then((response) => {
					console.log("Response from API:", response.data.content);
					setDeal(response.data.content);
				})
				.catch((err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				});
		}
	};

	// useEffect to trigger saveNotes when the notes state updates
	useEffect(() => {
		if (shouldSaveNotes) {
			saveNotes();
			setShouldSaveNotes(false); // Reset the flag after saving
		}
	}, [notes, shouldSaveNotes]); // Only run saveNotes if the flag is set

	const handleSaveNotes = () => {
		setShouldSaveNotes(true); // Set the flag to trigger saving after state updates
		// Perform any other necessary logic before saving
	};

	return (
		<NoteButton
			disableAddAndSave={type !== DealStatus.PENDING}
			notes={notes}
			editNotes={editNotes}
			saveNotes={handleSaveNotes} // Use handleSaveNotes to control saving
		/>
	);
};

export default NotesButton;
