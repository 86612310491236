import React, { useState } from "react";
import { Button, Col } from "reactstrap";

import formNavigationUtils from "utils/formNavigationUtils";
import useCurrentCustomerStore from "stores/CustomerStore";
import useCurrentDealStore from "stores/DealStore";
import AddressHistoryTable from "./AddressHistoryTable";
import AddressHistoryForm from "./AddressHistoryForm";
import ChildrenModal from "components/modals/ChildrenModal";
import CurrentAddress from "./CurrentAddress";
import CustomerLandlordForm from "../detailsTab/CustomerLandLordForm";
import ZipCityTable from "components/tables/ZipCityTable";

import { DealStatus } from "constants/Constants";

import shallow from "zustand/shallow";

const CustomerAddressHistoryTab = ({
	isDeal = false,
	isBuyer = false,
	children,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Customer store
	const { currentAddress, editCurrentAddress } = useCurrentCustomerStore(
		(state) => ({
			currentAddress: state.currentAddress,
			editCurrentAddress: state.editCurrentAddress,
		}),
		shallow
	);

	// Opens and closes the current zip city modal
	const toggleCityModal = () => setCityModal(!cityModal);

	// Show modal containing cities with the 5 digit zip entered for current address
	const handleZipChange = (zip, address) => {
		setAddressData({ zip, address });
		toggleCityModal();
	};

	// Deal store
	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Customer store
	const { addressHist } = useCurrentCustomerStore(
		(state) => ({
			addressHist: state.addressHist,
		}),
		shallow
	);

	// Local state
	const [currentIndex, setCurrentIndex] = useState(addressHist.length);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<>
			<div className="col" onKeyDown={formNavigationUtils}>
				<div id="currentAddress">
					<CurrentAddress getCities={handleZipChange} />
					<ChildrenModal
						modal={cityModal}
						toggle={toggleCityModal}
						modalTitle="Cities"
					>
						<ZipCityTable
							data={addressData}
							toggle={toggleCityModal}
							address={currentAddress}
							setAddress={editCurrentAddress}
							isDeal={isDeal}
							isBuyer={isBuyer}
						/>
					</ChildrenModal>
				</div>
				<br />
				<CustomerLandlordForm />
				<ChildrenModal
					modal={modal}
					toggle={toggle}
					modalTitle={"Add Address History"}
					children={
						<AddressHistoryForm
							currentIndex={currentIndex}
							setCurrentIndex={setCurrentIndex}
							toggle={toggle}
						/>
					}
				/>
				<Col className="mb-2 mt-4">
					<Button
						className="btn btn-primary btn-sm"
						readOnly={type !== DealStatus.PENDING}
						onClick={toggle}
					>
						<i className="nc-icon nc-simple-add"></i> Add Address
					</Button>
					<div className="col-md-6 text-right">{children}</div>
				</Col>
				<AddressHistoryTable
					readOnly={type !== DealStatus.PENDING}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</div>
		</>
	);
};
export default CustomerAddressHistoryTab;
