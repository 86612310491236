import React from "react";
import { Card, CardBody, CardText, Row, Col, Label } from "reactstrap";
import useCurrentRecapStore from "stores/RecapStore";
import useCurrentSaleStore from "stores/SaleStore";
import shallow from "zustand/shallow";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";

const GapInsuranceBreakdown = () => {
	const {
		collisionTotPremium,
		gap,
		lifePremium,
		disabilityPremium,
	} = useCurrentSaleStore((state) => state, shallow);
	const {
		gapInsCost,
		collisionTotCost,
		creditLifeCost,
		disabCost,
	} = useCurrentRecapStore((state) => state, shallow);

	return (
		<Card>
			<CardBody>
				<CardText>
					<Row>
						<Col>
							<Label className="h6">Fee</Label>
						</Col>
						<Col>
							<Label className="h6">Amount</Label>
						</Col>
						<Col>
							<Label className="h6">Cost</Label>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Gap</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={gap} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={gapInsCost} />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Collision</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={collisionTotPremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={collisionTotCost} />
						</Col>
					</Row>
					<hr />

					<Row>
						<Col>
							<Label>Credit Life</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={lifePremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={creditLifeCost} />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<Label>Disability</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement val={disabilityPremium} />
						</Col>
						<Col>
							<CurrencyDisplayElement val={disabCost} />
						</Col>
					</Row>
					<hr />

					<Row>
						<Col>
							<Label className="h6">Total</Label>
						</Col>
						<Col>
							<CurrencyDisplayElement
								val={
									gap + collisionTotPremium + lifePremium + disabilityPremium
								}
							/>
						</Col>
						<Col>
							<CurrencyDisplayElement
								val={gapInsCost + collisionTotCost + creditLifeCost + disabCost}
							/>
						</Col>
					</Row>
				</CardText>
			</CardBody>
		</Card>
	);
};

export default GapInsuranceBreakdown;
