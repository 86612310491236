import React, { useContext } from "react";
import { Col, Row } from "reactstrap";

import KbbFooter from "../KbbFooter";

import { FormattedText } from "../../../../../../forms/components/InputElement";
import { KbbContext } from "../../KbbContext";

import dayjs from "dayjs";
import NumberFormat from "react-number-format";
import { failAlert } from "../../../../../../../utils/alertUtils";

const AllValuesContent = () => {
	const borderBottom = "30px solid transparent";

	// KBB context
	const kbbContext = useContext(KbbContext);
	const { dcTableKbbValues, dcTableKbb } = kbbContext;
	if (!dcTableKbb?.kbbVehicleId) {
		failAlert("Decode KBB and select a condition, then update values.");
		return false;
	}

	// Valid date
	const valuationDate = dayjs(dcTableKbb.bookDate) || dayjs();
	const startDate = valuationDate.format("MM/DD/YYYY");
	const endDate = valuationDate.add(7, "day").format("MM/DD/YYYY");

	const values = dcTableKbbValues.map((obj) => {
		return (
			<tr
				className="all-values-body"
				key={obj.priceTypeID}
				style={{
					borderBottom: borderBottom,
				}}
			>
				<th scope="row">{obj.priceTypeDisplay}&emsp;</th>
				<td>{obj.condition || "N/A"}&emsp;</td>
				<td>
					<KbbFormattedText value={obj.configuredValue} prefix={"$"} />
					&emsp;
				</td>
				<td>
					<FormattedText
						value={obj.rangeHigh}
						fixedDecScale={false}
						prefix={"$"}
					/>
					&emsp;
				</td>
				<td>
					<FormattedText
						value={obj.rangeLow}
						fixedDecScale={false}
						prefix={"$"}
					/>
					&emsp;
				</td>
				<td>
					<FormattedText
						value={obj.optionAdjustment}
						fixedDecScale={false}
						prefix={"$"}
					/>
					&emsp;
				</td>
				<td>
					<FormattedText
						value={obj.mileageAdjustment}
						fixedDecScale={false}
						prefix={"$"}
					/>
					&emsp;
				</td>
				<td>
					<FormattedText value={obj.typicalMileage} fixedDecScale={false} />
					&emsp;
				</td>
				<td>{obj.valuationDate}&emsp;</td>
			</tr>
		);
	});

	return (
		<div>
			<Row>
				<Col md="12" className="d-flex justify-content-center">
					<p className="all-values-header">All Values</p>
				</Col>
				<Col md="12" className="d-flex justify-content-center">
					<p className="book-sheet-header">{`Valid: ${startDate} - ${endDate}`}</p>
				</Col>
			</Row>
			<table className="all-values-border-spacing">
				<thead>
					<tr className="all-values-body">
						<th>Price Type&emsp;</th>
						<th>Condition&emsp;</th>
						<th>Value&emsp;</th>
						<th>Low&emsp;</th>
						<th>High&emsp;</th>
						<th>Opt Adj&emsp;</th>
						<th>Mile Adj&emsp;</th>
						<th>Typical Mileage&emsp;</th>
						<th>Validated&emsp;</th>
					</tr>
					<tr className="all-values-border">
						<td colSpan="100%" />
					</tr>
					<tr style={{ borderBottom }}>
						<td colSpan="100%" />
					</tr>
				</thead>
				<tbody>{values}</tbody>
			</table>
			<div className="d-flex justify-content-center book-sheet-header-2 all-values-footer">
				<KbbFooter />
			</div>
		</div>
	);
};

export default AllValuesContent;

const KbbFormattedText = ({ value, prefix }) => {
	return (
		<NumberFormat
			value={value}
			thousandSeparator={true}
			decimalScale={0}
			prefix={prefix}
			displayType="text"
		/>
	);
};
