import React, { useState } from "react";
import { Form } from "reactstrap";

import formNavigationUtils from "../../../../../../utils/formNavigationUtils";
import ChildrenModal from "../../../../../../components/modals/ChildrenModal";
import ZipCityTable from "../../../../../../components/tables/ZipCityTable";

import {
	stateOptions,
	CountryCodes,
} from "../../../../../../constants/Constants";
import InputElement, {
	PhoneInputElement,
	SelectorElement,
	SsnInputElement,
	ZipInputElement,
} from "features/forms/components/InputElement";

import { useFormContext } from "react-hook-form";

const DigitzsPersonalInformation = ({
	personalInfo,
	personalAddress,
	setPersonalInfo,
	setPersonalAddress,
	setCurrentForm,
	nextForm,
	disabled,
}) => {
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const handleZipChange = (fieldZip) => {
		setAddressData({
			zip: fieldZip,
			address: {
				city: personalAddress.city,
				state: personalAddress.state,
			},
		});
		toggleCityModal();
	};

	const setAddressFields = (address) => {
		setPersonalAddress.setCity(address.city);
		setPersonalAddress.setState(address.state);
	};

	// Handler for the next button click. Sets the fields to the personal information
	// objects and shows the next form tab
	const handleNext = async () => {
		const isValid = await methods.trigger();

		if (!isValid) {
			return;
		}

		setCurrentForm(nextForm);
	};

	const methods = useFormContext();

	return (
		<div>
			<Form className="row" onKeyDown={formNavigationUtils}>
				<h3 className="col-12 section-title mb-3">Personal Information</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.firstName}
					onChange={(e) => setPersonalInfo.setFirstName(e.target.value)}
					name="firstNameDigitzs"
					label="First Name"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.lastName}
					onChange={(e) => setPersonalInfo.setLastName(e.target.value)}
					name="lastNameDigitzs"
					label="Last Name"
					type="text"
				/>
				<SsnInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					name="ssnDigitzs"
					label="Social Security Number"
					value={personalInfo.socialSecurity}
					onChange={setPersonalInfo.setSocialSecurity}
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.email}
					onChange={(e) => setPersonalInfo.setEmail(e.target.value)}
					name="emailDigitzs"
					label="Email"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.birthDate}
					onChange={(e) => setPersonalInfo.setBirthDate(e.target.value)}
					name="dobDigitzs"
					label="Date of Birth"
					type="date"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.dayPhone}
					onChange={setPersonalInfo.setDayPhone}
					name="dayPhoneDigitzs"
					label="Daytime Contact Number"
				/>
				<PhoneInputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalInfo.eveningPhone}
					onChange={setPersonalInfo.setEveningPhone}
					name="eveningPhoneDigitzs"
					label="Evening Contact Number"
				/>
				<h3 className="col-12 form-section section-title my-3">
					Personal Address
				</h3>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-4"
					value={personalAddress.line1}
					onChange={(e) => setPersonalAddress.setStreet(e.target.value)}
					name="streetDigitzs"
					label="Street"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					value={personalAddress.line2}
					onChange={(e) => setPersonalAddress.setUnit(e.target.value)}
					name="unitDigitzs"
					label="Apt/Unit/Bld/Suite"
					type="text"
				/>
				<InputElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					value={personalAddress.city}
					onChange={(e) => setPersonalAddress.setCity(e.target.value)}
					name="cityDigitzs"
					label="City"
					type="text"
				/>
				<ZipInputElement
					{...methods}
					disabled={disabled}
					value={personalAddress.zip}
					onChange={(val) => setPersonalAddress.setZip(val)}
					openZipModal={handleZipChange}
					name="zipDigitzs"
					label="Zip"
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					name="stateDigitzs"
					label="State"
					value={personalAddress.state}
					onChange={(e) => setPersonalAddress.setState(e.target.value)}
					options={stateOptions}
				/>
				<SelectorElement
					{...methods}
					disabled={disabled}
					colSize="col-lg-3"
					name="countryDigitzs"
					label="Country"
					value={personalAddress.country}
					onChange={(e) => setPersonalAddress.setCountry(e.target.value)}
					options={CountryCodes}
				/>
				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={{
							city: personalAddress.city,
							state: personalAddress.state,
							zip: personalAddress.zip,
						}}
						setAddress={setAddressFields}
					/>
				</ChildrenModal>
			</Form>
			{!disabled && (
				<div className="col-12">
					<button
						className="next-button"
						onClick={(e) => {
							e.preventDefault();
							handleNext(e);
						}}
					>
						Next
					</button>
				</div>
			)}
		</div>
	);
};

export default DigitzsPersonalInformation;
