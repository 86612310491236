import React, { useState } from "react";
import {
	Card,
	CardHeader,
	CardBody,
	Form,
	InputGroup,
	CardTitle,
} from "reactstrap";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { showApiError } from "utils/errorRoutingUtils";
import useRoute from "hooks/useRoute";
import { PATHS } from "constants/Constants";
import { resetPassword } from "api/LoginAPI";
import { successAlertCustom, retrieveFailAlert } from "utils/alertUtils";
import InputElement from "features/forms/components/InputElement";

/**
 * Form component for handling forgotten password requests.
 *
 * @returns {React.ReactElement} The rendered component.
 */
const ForgottenPasswordForm = () => {
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const history = useHistory();

	// Routing
	const loginPage = useRoute(PATHS.LOGIN);

	/**
	 * Handles the submission of the form to reset the password.
	 */
	const checkUser = () => {
		const data = {
			username,
			email,
		};

		resetPassword(data)
			.then((res) => {
				successAlertCustom(
					"Successfully reset your password. Please check your email."
				);
				history.push(loginPage);
			})
			.catch((err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			});
	};

	// Get validation context from useFormContext
	const methods = useFormContext();

	return (
		<div className="login-page">
			<Form className="form mt-10">
				<Card className="card-login">
					<CardHeader>
						<CardTitle>
							<h3 className="text-center">Password Reset!</h3>
						</CardTitle>
					</CardHeader>
					<CardBody className="px-4">
						<InputElement
							{...methods}
							colSize="col"
							type="text"
							name="username"
							label="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>

						<CardTitle>
							<h5 className="text-center mt-4">and</h5>
						</CardTitle>
						<InputGroup>
							<InputElement
								{...methods}
								colSize="col"
								type="email"
								label="Email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</InputGroup>
						<div className="text-center mt-2">
							<button
								type="submit"
								className="btn btn-primary mx-auto"
								onClick={methods.handleSubmit(checkUser)}
							>
								Submit
							</button>
						</div>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default ForgottenPasswordForm;
