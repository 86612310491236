import React, { useState } from "react";

import useCurrentCustomerStore from "stores/CustomerStore";
import ChildrenModal from "../../../../../components/modals/ChildrenModal";
import CustomerDetails from "./CustomerDetails";
import UpdateCustomerButton from "../../../helperFunctions/UpdateCustomerButton";
import shallow from "zustand/shallow";
import { Row } from "reactstrap";
import DeleteCustomerButton from "../../../helperFunctions/DeleteCustomerButton";
import { CustomerStartDealButton } from "../../../helperFunctions/CustomerStartDealButton";
import CustomerSaveNoteButton from "../../../helperFunctions/CustomerSaveNoteButton";
import formNavigationUtils from "utils/formNavigationUtils";
import CustomerDealList from "features/crm/subFeatures/contactListPage/CustomerDealList";

const CustomerDetailsTab = () => {
	// Local states

	const [customerListModal, setCustomerListModal] = useState(false);

	// Opens or closes the deal list modal
	const toggleCustomerListModal = () =>
		setCustomerListModal(!customerListModal);
	// Customer store
	const { originalID } = useCurrentCustomerStore(
		(state) => ({
			originalID: state.originalID,
		}),
		shallow
	);

	return (
		<div style={{ minHeight: "800px" }}>
			<div onKeyDown={formNavigationUtils} className="col">
				<CustomerDetails />
			</div>
			<div className="col">
				<UpdateCustomerButton />
			</div>
			<div className="col pb-3">
				<div className="row mx-0 hide">
					<div className="col-md-6">
						<Row className="px-0">
							<CustomerStartDealButton />

							<div
								className="btn btn-primary btn-md"
								onClick={toggleCustomerListModal}
							>
								<i className="icon-big text-center nc-icon nc-briefcase-24"></i>{" "}
								View<span className="noMobile"> Deals</span>
							</div>
						</Row>
					</div>
					<div className="col-md-6 text-right">
						<CustomerSaveNoteButton />
					</div>
				</div>
			</div>

			<DeleteCustomerButton redirect={true} />
			<ChildrenModal
				modal={customerListModal}
				toggle={toggleCustomerListModal}
				modalTitle="Customer Deals"
				children={
					<CustomerDealList
						toggle={toggleCustomerListModal}
						originalID={originalID}
					/>
				}
			/>
		</div>
	);
};

export default CustomerDetailsTab;
