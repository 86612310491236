import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardTitle, CardBody, Collapse } from "reactstrap";

/**
 * LenderWrapper component is a collapsible card container for content.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to be rendered inside the card
 * @param {string} props.cardTitle - Title of the card
 * @param {string} props.cardId - Identifier for the card, used for handling collapse functionality
 */
const LenderWrapper = ({ children, cardTitle, cardId }) => {
	const [open, setOpen] = useState(true);

	const handleToggle = (e) => {
		e.preventDefault();
		setOpen(!open);
	};

	return (
		<div
			aria-multiselectable={true}
			className="card-collapse"
			id="accordion"
			role="tablist"
		>
			<Card className="card-plain">
				<CardHeader role="tab">
					<a
						aria-expanded={open}
						data-parent="#accordion"
						data-toggle="collapse"
						href={cardId}
						onClick={handleToggle}
					>
						<CardTitle tag="h4">
							{cardTitle}
							<i className="nc-icon nc-minimal-down" />
						</CardTitle>
					</a>
				</CardHeader>
				<Collapse role="tabpanel" isOpen={open}>
					<CardBody>
						<div>{children}</div>
					</CardBody>
				</Collapse>
			</Card>
		</div>
	);
};

LenderWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	cardTitle: PropTypes.string.isRequired,
	cardId: PropTypes.string.isRequired,
};

export default LenderWrapper;
