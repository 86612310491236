import dayjs from "dayjs";
import React from "react";
import NumberFormat from "react-number-format";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactTable from "./ReactTable";

/**
 * DealTable component for displaying deal information in a table format.
 *
 * @param {Object} props - Component props
 * @param {Array} props.data - The data to display in the table
 * @param {Function} props.onRowClick - The function to call when a row is clicked
 * @returns {JSX.Element} The rendered component
 */
const DealTable = ({ data, onRowClick }) => {
    const customerColumn = "customerDetails";
    const vehicleColumn = "vehicle";
    const vehicleHiddenColumns = ["Year", "Make", "Model", "Stock"];
    const customerHiddenColumns = ["Name", "Email", "Phone"];
    const hiddenColumns = ["Name", "Email", "Phone", "Stock", "Year", "Make", "Model"];

    /**
     * Cell component for displaying customer information.
     *
     * @param {Object} props - Component props
     * @returns {JSX.Element} The rendered component
     */
    const Cell = (props) => (
        <div>
            <span>
                <div>{props.row.original.fullName}</div>
                <div style={{ wordBreak: "break-all" }}>{props.row.original.email}</div>
                <NumberFormat
                    value={props.row.original.phone}
                    format="+1 (###) ###-####"
                    isNumericString={true}
                    displayType="text"
                />
                {props.row.original.hasTrade && <div>Has Trades</div>}
                {props.row.original.coBuyerID > 0 && <div>Has CoBuyer</div>}
            </span>
        </div>
    );

    /**
     * Cell component for displaying vehicle information.
     *
     * @param {Object} props - Component props
     * @returns {JSX.Element} The rendered component
     */
    const vehicleCell = (props) => (
        <div>
            <span>
                <div>
                    {props.row.original.vehicle !== "null null null" && (
                        <div>{props.row.original.vehicle}</div>
                    )}
                </div>
                <div>
                    {props.row.original.stock != null && (
                        <div>Stock#: {props.row.original.stock}</div>
                    )}
                </div>
                <div>
                    {props.row.original.vin != null && (
                        <div>VIN: {props.row.original.vin}</div>
                    )}
                </div>
                <div>
                    {props.row.original.vehicleStatus != null && (
                        <div>Status: {props.row.original.vehicleStatus}</div>
                    )}
                </div>
            </span>
        </div>
    );

    /**
     * Cell component for displaying deal information.
     *
     * @param {Object} props - Component props
     * @returns {JSX.Element} The rendered component
     */
    const dealCell = (props) => (
        <div>
            <span>
                <div>
                    {props.row.original.status} - {props.row.original.saleType}
                </div>
                <div>
                    {props.row.original.loanDate != null && (
                        <div>{dayjs(props.row.original.loanDate).format("MM/DD/YYYY")}</div>
                    )}
                </div>
                <div>Deal # {props.row.original.dealNumber}</div>
                <div>
                    {props.row.original.term} payments of{" "}
                    <NumberFormat
                        value={props.row.original.payment}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                    />{" "}
                    {props.row.original.loanPeriod}
                </div>
            </span>
        </div>
    );

    return (
        <div>
            <Row>
                <Col md="12">
                    <Card className="mt-0">
                        <CardBody>
                            <ReactTable
                                className="-striped -highlight primary-pagination"
                                getTrProps={(state, rowInfo) => {
                                    return {
                                        onClick: () => {
                                            if (rowInfo) {
                                                onRowClick(rowInfo.original);
                                            }
                                        },
                                    };
                                }}
                                customerColumn={customerColumn}
                                vehicleColumn={vehicleColumn}
                                hiddenColumns={hiddenColumns}
                                vehicleHiddenColumns={vehicleHiddenColumns}
                                customerHiddenColumns={customerHiddenColumns}
                                data={data}
                                isDealTable={true}
                                columns={[
                                    {
                                        Header: "Photo",
                                        accessor: "thumbnail",
                                        disableFilters: true,
                                        className: "vehicle-photo",
                                    },
                                    {
                                        id: "customerDetails",
                                        Header: "Customer",
                                        accessor: "customerDetails",
                                        style: { whiteSpace: "unset" },
                                        Cell: Cell,
                                        customerSort: false,
                                    },
                                    {
                                        id: "Name",
                                        Header: "Name",
                                        accessor: "fullName",
                                        style: { whiteSpace: "unset" },
                                        Cell: Cell,
                                        customerSort: false,
                                    },
                                    {
                                        id: "Phone",
                                        Header: "Phone",
                                        accessor: "phone",
                                        style: { whiteSpace: "unset" },
                                        Cell: Cell,
                                        customerSort: false,
                                    },
                                    {
                                        id: "Email",
                                        Header: "Email",
                                        accessor: "email",
                                        style: { whiteSpace: "unset" },
                                        Cell: Cell,
                                        customerSort: false,
                                    },
                                    {
                                        id: "vehicle",
                                        Header: "Vehicle",
                                        accessor: "vehicleDetails",
                                        style: { whiteSpace: "unset" },
                                        vehicleSort: false,
                                        Cell: vehicleCell,
                                    },
                                    {
                                        id: "Year",
                                        Header: "Year",
                                        accessor: "year",
                                        style: { whiteSpace: "unset" },
                                        vehicleSort: false,
                                        Cell: vehicleCell,
                                    },
                                    {
                                        id: "Make",
                                        Header: "Make",
                                        accessor: "make",
                                        style: { whiteSpace: "unset" },
                                        vehicleSort: false,
                                        Cell: vehicleCell,
                                    },
                                    {
                                        id: "Model",
                                        Header: "Model",
                                        accessor: "model",
                                        style: { whiteSpace: "unset" },
                                        vehicleSort: false,
                                        Cell: vehicleCell,
                                    },
                                    {
                                        id: "Stock",
                                        Header: "Stock",
                                        accessor: "stock",
                                        style: { whiteSpace: "unset" },
                                        vehicleSort: false,
                                        Cell: vehicleCell,
                                    },
                                    {
                                        Header: "Deal",
                                        accessor: "deal",
                                        style: { whiteSpace: "unset" },
                                        Cell: dealCell,
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "Actions",
                                        disableFilters: true,
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DealTable;
