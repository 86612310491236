import React from "react";
import { Row, Col, Container } from "reactstrap";
import ReviewVehicle from "./ReviewVehicle";
import ReviewCustomer from "./ReviewCustomer";
import ReviewCash from "./ReviewCash";
import ReviewDown from "./ReviewDown";
import ReviewFinance from "./ReviewFinance";

const PrintReview = React.forwardRef((_props, ref) => {
	return (
		<div ref={ref}>
			<Row className="justify-content-center">
				<Col md={6} sm={6}>
					<Row>
						<Col>
							<ReviewCustomer />
						</Col>
					</Row>
					<div key="spacer" className="noDesktop pb-3 w-100"></div>,
					<Row>
						<Col>
							<ReviewCash />
						</Col>
					</Row>
					<div key="spacer" className="noDesktop pb-3 w-100"></div>,
					<Row>
						<Col>
							<ReviewDown />
						</Col>
					</Row>
				</Col>
				<Col md={6} sm={6}>
					<Row>
						<Col>
							<ReviewVehicle />
						</Col>
					</Row>
					<div key="spacer" className="noDesktop pb-3 w-100"></div>,
					<Row>
						<Col>
							<ReviewFinance />
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
});

export default PrintReview;
