import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	FormGroup,
	Input,
	Row,
} from "reactstrap";
import Loading from "components/loadingSpinner/Loading";
import shallow from "zustand/shallow";
import history from "utils/createBrowserHistory";
import { retrieveFailAlert } from "utils/alertUtils";
import dayjs from "dayjs";
import Camera from "assets/img/placeholder.png";
import { getDealDetails, getDealListBySearch } from "../../../api/DealAPI";
import { getSettings } from "../../../api/SettingsAPI";
import { LoanPeriod, PATHS, SaleType } from "../../../constants/Constants";
import InputElement from "../../forms/components/InputElement";
import PageLayout from "../../../components/layouts/PageLayout";
import DealTable from "../../../components/tables/DealTable";
import useRoute from "../../../hooks/useRoute";
import useDateRangeStore from "../../../stores/DateRangeStore";
import useCurrentDealStore from "../../../stores/DealStore";
import { useFromUser } from "../../../stores/LocalStorageHelper";
import { showApiError } from "../../../utils/errorRoutingUtils";
import useSetDeal from "../hooks/useSetDeal";

const DealSearchList = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	const dcLoginID = useFromUser("ID");
	const { fromDate, toDate } = useDateRangeStore();

	const initialFromDate = dayjs(fromDate).format("YYYY-MM-DD");
	const initialToDate = dayjs(toDate).format("YYYY-MM-DD");
	console.log("initialFromDate: " + initialFromDate);
	console.log("initialToDate: " + initialToDate);

	const [selectedOption, setSelectedOption] = useState("dateRange");

	const [fromDT, setFromDT] = useState(initialFromDate);
	const [toDT, setToDT] = useState(initialToDate);

	const [stockNo, setStockNo] = useState("");
	const [vin, setVin] = useState("");
	const [customer, setCustomer] = useState("");
	const [tradeStockNo, setTradeStockNo] = useState("");
	const [tradeVin, setTradeVin] = useState("");

	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);

	const currencyFormatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};

	//Deal Store
	const {
		updateSettings,
		editModifiedBy,
		editModifiedById,
	} = useCurrentDealStore(
		(state) => ({
			updateSettings: state.updateSettings,
			editModifiedBy: state.editModifiedBy,
			editModifiedById: state.editModifiedById,
		}),
		shallow
	);

	const setDeal = useSetDeal();
	const [filter, setFilter] = useState("");

	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(false);
	const homeRoute = useRoute(PATHS.DASHBOARD);

	// Handles row click and fetches deal data
	const handleRowClick = (deal) => {
		getDealData(deal.dealID);
	};
	// Fetches the list of deals
	const getListData = (filter) => {
		if (dealerID && locationID) {
			getDealListBySearch(locationID, filter)
				.then((response) => {
					const formattedData = dataFormat(response.data.content);
					setData(formattedData);
					setLoadingList(false);
				})
				.catch((error) => {
					showApiError(error, retrieveFailAlert);
					history.push(homeRoute);
				});
		}
	};

	// Fetches deal details and navigates to detail page
	const getDealData = (id) => {
		Promise.all([getSettings(dealerID, locationID), getDealDetails(id)])
			.then((res) => {
				handleDealDataResponse(res);
				history.push(dealDetailPage, { ID: id });
			})
			.catch((error) => showApiError(error, retrieveFailAlert));
	};
	// Handles the response for deal data
	const handleDealDataResponse = (res) => {
		const defaultSettings = res[0].data.content;
		const settingsFromDeal = JSON.parse(res[1].data.content.deal.dealSettings);
		setDeal(res[1].data.content);
		updateSettings(defaultSettings, settingsFromDeal);
		editModifiedBy(`${lastName}, ${firstName}`);
		editModifiedById(dcLoginID);
		// Additional logic for handling deal data...
	};

	const updateSearch = () => {
		if (selectedOption === "dateRange")
			setFilter(
				` ((s.[Loan Date]  >= CONVERT(DATETIME, '${fromDT}', 102)) AND  (s.[Loan Date]  <= CONVERT(DATETIME, '${toDT}', 102))  ) `
			);
		if (selectedOption === "stockNo")
			setFilter(` v.stockNo like '%${stockNo}%' `);

		if (selectedOption === "vin") setFilter(` v.vin like '%${vin}%' `);
		if (selectedOption === "customer")
			setFilter(` (c.firstName + ' ' + c.lastName) like '%${customer}%' `);

		if (selectedOption === "tradeStockNo") {
			setFilter(` {"tradeStockNo": "${tradeStockNo}"} `);
		}
		if (selectedOption === "tradeVin") {
			setFilter(` {"tradeVin": "${tradeVin}"} `);
		}

		setLoadingList(true);
	};

	// Formats the JSON response object
	const dataFormat = (content) => {
		console.log(content);
		const container = Object.keys(content).map((key) => {
			let container = {};
			container.dealID = content[key].dealID;
			container.thumbnail = (
				<div
					onClick={() => {
						getDealData(content[key].dealID);
					}}
				>
					<img
						className="zoom"
						alt="vehicle"
						src={
							content[key].thumbnail
								? `${content[key].thumbnail}?lastmod=${Date.now()}`
								: Camera
						}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						width="250"
						height="150"
					/>
				</div>
			);

			container.hasTrade = content[key]?.hasTrade ? true : false;
			container.buyerID = content[key].buyerID;
			container.coBuyerID = content[key].coBuyerID;
			container.inventoryID = content[key].inventoryID;
			container.status = content[key].status;
			container.phone = content[key].phone != null ? content[key].phone : "";
			container.fullName = content[key].name;
			container.email = content[key].email;
			container.customerDetails =
				container.fullName +
				" " +
				container.email +
				" " +
				container.phone +
				" " +
				(content[key]?.hasTrade ? "Has Trades" : "") +
				" " +
				(content[key].coBuyerID ? "coBuyer: " + content[key].cobuyer : "");
			container.vehicle = content[key].vehicle;
			container.stock = content[key].stock;
			container.vin = content[key].vin;
			container.vehicleStatus = content[key].vehicleStatus;
			console.log(content[key].vehicle);
			console.log(content[key].inventoryID);
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			if (content[key].inventoryID !== null) {
				container.vehicleDetails =
					container.make +
					" " +
					container.model +
					" " +
					container.year +
					" " +
					container.stock +
					" " +
					container.vin +
					" " +
					container.vehicleStatus;
			} else {
				container.vehicleDetails = "";
			}
			console.log(container.vehicleDetails);

			container.loanDate = content[key].loanDate
				? content[key].loanDate.substr(0, 10)
				: (container.loanDate = null);

			container.saleType =
				SaleType[content[key].saleType]?.label || "Unknown type";
			container.dealNumber = content[key].dealNumber;
			container.term = content[key].term;
			container.payment = content[key].payment;
			container.loanPeriod = LoanPeriod[content[key].loanPeriod]?.label;
			container.deal =
				container.status +
				" " +
				dayjs(container.loanDate).format("MM/DD/YYYY") +
				" " +
				container.saleType +
				" " +
				container.dealNumber +
				" " +
				container.term +
				" " +
				currencyFormatter.format(container.payment) +
				" " +
				container.loanPeriod;
			container.Actions = (
				<>
					<div className="text-center">
						<div
							className="btn-md btn btn-primary"
							onClick={() => {
								getDealData(content[key].dealID);
							}}
						>
							<i className="icon-big text-center nc-icon nc-paper text-default" />
							&nbsp;View
						</div>
					</div>
				</>
			);
			return container;
		});
		return container;
	};
	const handleFromDateBlur = () => {
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (diffDays > 730 || diffDays < 0) {
			toDate.setFullYear(fromDate.getFullYear() + 2); // Set toDate to 2 years after fromDate
			setToDT(toDate.toISOString().split("T")[0]);
		}
	};

	const handleToDateBlur = (e) => {
		const fromDate = new Date(fromDT);
		const toDate = new Date(toDT);
		const diffTime = Math.abs(toDate - fromDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		if (diffDays > 730 || diffDays < 0) {
			fromDate.setFullYear(toDate.getFullYear() - 2); // Set fromDate to 2 years before toDate
			setFromDT(fromDate.toISOString().split("T")[0]);
		}
	};

	useEffect(() => {
		if (loadingList) getListData(filter);
		// eslint-disable-next-line
	}, [loadingList]);

	return (
		<PageLayout>
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<CardTitle tag="h4">Quick Deal Search</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="dateRange"
													value="dateRange"
													checked={selectedOption === "dateRange"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={fromDT}
													onBlur={handleFromDateBlur}
													onChange={(e) => setFromDT(e.target.value)}
													name="fromDate"
													label="From Date"
													type="date"
													colSize="col-sm-1.5"
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={toDT}
													onBlur={handleToDateBlur}
													onChange={(e) => setToDT(e.target.value)}
													name="toDate"
													label="To Date"
													type="date"
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
										<div style={{ color: "green" }}>
											The date range cannot exceed 2 years.
										</div>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="stockNo"
													value="stockNo"
													checked={selectedOption === "stockNo"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={stockNo}
													name="stockNo"
													label="Stock Number"
													type="text"
													onChange={(e) => setStockNo(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="vin"
													value="vin"
													checked={selectedOption === "vin"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={vin}
													name="vin"
													label="Vin Number"
													type="text"
													onChange={(e) => setVin(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="customer"
													value="customer"
													checked={selectedOption === "customer"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={customer}
													name="customer"
													label="Customer"
													type="text"
													onChange={(e) => setCustomer(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="tradeStockNo"
													value="tradeStockNo"
													checked={selectedOption === "tradeStockNo"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={tradeStockNo}
													name="tradeStockNo"
													label="Trade Stock Number"
													type="text"
													onChange={(e) => setTradeStockNo(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
								<Col xs="12" sm="6" md="3">
									<FormGroup>
										<Row>
											<Col xs="auto">
												<Input
													type="radio"
													name="tradeVin"
													value="tradeVin"
													checked={selectedOption === "tradeVin"}
													onChange={handleOptionChange}
												/>
											</Col>
											<Col>
												<InputElement
													disableValidation={true}
													value={tradeVin}
													name="tradeVin"
													label="Trade Vin Number"
													type="text"
													onChange={(e) => setTradeVin(e.target.value)}
													colSize="col-sm-1.5"
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
							</Row>
							<div className="text-center mt-3">
								<Button color="primary" onClick={updateSearch}>
									Search
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<div>
				{loadingList && <Loading />}
				{!loadingList && <DealTable data={data} onRowClick={handleRowClick} />}
			</div>
		</PageLayout>
	);
};

export default DealSearchList;
