import React from "react";
import {
	Button,
	FormGroup,
	Label,
	Input,
	Card,
	Col,
	CardBody,
	Row,
	CardTitle,
	Form,
	CardText,
} from "reactstrap";
import { profitSettingsEnum, DealStatus } from "constants/Constants";
import { showApiError } from "utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import { getSettings } from "api/SettingsAPI";
import formNavigationUtils from "utils/formNavigationUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import shallow from "zustand/shallow";
import useCurrentDealStore from "stores/DealStore";

/**
 * ProfitSettings component to handle profit-related settings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.profitSettings - Current profit settings.
 * @param {Function} props.editProfitSettings - Function to edit profit settings.
 * @param {Function} props.editProfitSettingsValue - Function to edit individual profit setting values.
 * @param {Boolean} props.isDeal - Flag indicating if it is a deal.
 * @returns {JSX.Element} The rendered component.
 */
const ProfitSettings = ({
	profitSettings,
	editProfitSettings,
	editProfitSettingsValue,
	isDeal,
}) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const { type } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
		}),
		shallow
	);

	// Define profit settings options
	const profitsObj = [
		{
			name: "isDocFeeProfit",
			dropdownValue: profitSettings?.isDocFeeProfit,
			dropdownLabel: "Doc Fee",
			onChange: (val) => editProfitSettingsValue("isDocFeeProfit", val),
		},
		{
			name: "isAftMktProfit",
			dropdownValue: profitSettings?.isAftMktProfit,
			dropdownLabel: "Aftermarket",
			onChange: (val) => editProfitSettingsValue("isAftMktProfit", val),
		},
		{
			name: "isServConProfit",
			dropdownValue: profitSettings?.isServConProfit,
			dropdownLabel: "Service Contract",
			onChange: (val) => editProfitSettingsValue("isServConProfit", val),
		},
		{
			name: "isGapProfit",
			dropdownValue: profitSettings?.isGapProfit,
			dropdownLabel: "Gap",
			onChange: (val) => editProfitSettingsValue("isGapProfit", val),
		},
		{
			name: "isMiscProfit",
			dropdownValue: profitSettings?.isMiscProfit,
			dropdownLabel: "Misc",
			onChange: (val) => editProfitSettingsValue("isMiscProfit", val),
		},
		{
			name: "isBankFeeProfit",
			dropdownValue: profitSettings?.isBankFeeProfit,
			dropdownLabel: "Bank Fee",
			onChange: (val) => editProfitSettingsValue("isBankFeeProfit", val),
		},
		{
			name: "isDealerSmogProfit",
			dropdownValue: profitSettings?.isDealerSmogProfit,
			dropdownLabel: "Dealer Smog",
			onChange: (val) => editProfitSettingsValue("isDealerSmogProfit", val),
		},
	];

	// Fetch default settings from the API
	const getData = () => {
		getSettings(dealerID, locationID).then(
			(res) => {
				editProfitSettings(res.data.content.profitSettings);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	// Button to fetch and use default settings
	const UseDefaults = () => (
		<Button
			readOnly={type !== DealStatus.PENDING}
			color="primary"
			onClick={getData}
			size="sm"
		>
			<i className="nc-icon nc-check-2"></i> Use Defaults
		</Button>
	);

	// Generate profit settings components
	const profitComponents = profitsObj.map((obj, index) => (
		<Card>
			<FormGroup key={index} className="col-12 pt-2">
				<CardTitle>
					<CardText tag="h5">{obj.dropdownLabel}</CardText>
				</CardTitle>
				<CardBody className="py-0">
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 0 || obj.dropdownValue > 2}
								onChange={() => obj.onChange(0)}
							/>
							{profitSettingsEnum.NOT_PROFIT.label}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 1}
								onChange={() => obj.onChange(1)}
							/>
							{profitSettingsEnum.FRONT.label}
						</Label>
					</FormGroup>
					<FormGroup check inline>
						<Label check>
							<Input
								type="radio"
								name={obj.name}
								checked={obj.dropdownValue === 2}
								onChange={() => obj.onChange(2)}
							/>
							{profitSettingsEnum.BACK.label}
						</Label>
					</FormGroup>
				</CardBody>
			</FormGroup>
		</Card>
	));

	return (
		<div onKeyDown={formNavigationUtils}>
			<Form>
				<Row className="mx-0 pb-2">
					{isDeal && (
						<Col>
							<UseDefaults />
						</Col>
					)}
				</Row>
				<CardTitle tag="h3" className="text-center mb-3">
					Profit Settings
				</CardTitle>
				<Row className="mx-0 justify-content-center" style={{ gap: "12px" }}>
					{profitComponents}
				</Row>
			</Form>
		</div>
	);
};

export default ProfitSettings;
