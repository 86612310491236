import * as yup from "yup";

// Define custom error messages for different error types
yup.setLocale({
	mixed: {
		required: "Required",
	},
	string: {
		// eslint-disable-next-line
		max: "Must be ${max} characters or less",

		// eslint-disable-next-line
		length: "Must be exactly ${length} numbers",
	},
});

// Validation schema for Digitzs
const digitzsValidationSchema = yup.object().shape({
	firstNameDigitzs: yup.string().required().max(70),
	lastNameDigitzs: yup.string().required().max(70),
	emailDigitzs: yup.string().required().max(70),
	dobDigitzs: yup.string().required("Invalid Date"),
	dayPhoneDigitzs: yup
		.string()
		.matches(/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, {
			message: "Must be a 10 digit phone number",
		}),
	eveningPhoneDigitzs: yup
		.string()
		.matches(/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, {
			message: "Must be a 10 digit phone number",
		}),
	streetDigitzs: yup.string().required().max(70),
	cityDigitzs: yup.string().required().max(70),
	stateDigitzs: yup.string().required(),
	countryDigitzs: yup.string().required(),
	businessName: yup.string().required().max(70),
	businessWebsite: yup.string().required().max(70),
	businessEmail: yup.string().required().max(70),
	businessPhone: yup
		.string()
		.matches(/^\+1\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/, {
			message: "Must be a 10 digit phone number",
		}),
	businessStreet: yup.string().required().max(70),
	businessCity: yup.string().required().max(70),
	businessState: yup.string().required(),
	businessCountry: yup.string().required(),
	bankName: yup.string().required().max(70),
	accountType: yup.string().required(),
	accountOwnership: yup.string().required(),
	accountName: yup.string().required().max(32),
	accountNumber: yup.string().required().max(70),
	routingNumber: yup.string().required().length(9),
	signature: yup.string().required(),
	agreementBox: yup
		.boolean()
		.oneOf([true], "You must read and accept the Digitzs Agreement."),
	ein: yup.string().matches(/^[0-9]{9}$/, {
		message: "Must be 9 digits or empty",
		excludeEmptyString: true,
	}),
	ssnDigitzs: yup.string().matches(/^[0-9]{3}-[0-9]{2}-[0-9]{4}/, {
		message: "Must be 9 digits",
	}),
});

export default digitzsValidationSchema;
