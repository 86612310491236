import createAxiosInstance from "../utils/apiUtils";

import { userBaseUrl } from "../constants/Constants";
// import { LOCAL } from "./Constants";
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });
const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/integrations",
});

export const decodeVIN = async (vin) => {
	return await instance.get(`/decode/${vin}`);
};

export const decodeVINv2 = async (vin) => {
	return await instance.get(`/decode/v2/${vin}`);
};
